import React from 'react';
import {
    CircularProgress,
    Grid,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'horizontallyCenteredSpinner';

const classes = {
    loadingWrapper: `${classesPrefix}-loadingWrapper`,
    loadingText: `${classesPrefix}-loadingText`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.loadingWrapper}`]: {
            minHeight: '150px',
            height: '100%',
            textAlign: 'center'
        },
        [`& .${classes.loadingText}`]: {
            fontSize: '10px',
            fontWeight: 'lighter',
            color: theme.palette.text.primary,
            textTransform: 'uppercase'
        }
    };
});

const HorizontallyCenteredSpinner = ({
    loadingText
}: {
    /** Optional loading text to show with the spinner. */
    loadingText?: string;
}): JSX.Element => {
    return (
        <StyledGrid container alignItems='center' alignContent='center' className={classes.loadingWrapper}>
            <Grid item xs={12}>
                <CircularProgress thickness={5} disableShrink />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='caption' className={classes.loadingText}>{loadingText || 'Loading'}</Typography>
            </Grid>
        </StyledGrid>
    );
};

export default HorizontallyCenteredSpinner;
