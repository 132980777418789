import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, DimensionNames } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { GenericApiResponse } from '../../interfaces/services';
import { UserSettingAttribute } from '../../interfaces/services/organization';
import { Filter } from '../../interfaces/services/shipment';
import { DimensionSummary, SearchDimension } from '../../interfaces/services/shipmentDimensionSummary';
import { CounterTabItem } from '../../interfaces/componentInterfaces';

const DIMENSION_SUMMARY_COUNTS_REQUEST = 'DIMENSION_SUMMARY_COUNTS_REQUEST';
const DIMENSION_SUMMARY_COUNTS_SUCCESS = 'DIMENSION_SUMMARY_COUNTS_SUCCESS';
const DIMENSION_SUMMARY_COUNTS_FAILURE = 'DIMENSION_SUMMARY_COUNTS_FAILURE';

const SHIPMENT_MAP_LEGEND_REQUEST = 'SHIPMENT_MAP_LEGEND_REQUEST';
const SHIPMENT_MAP_LEGEND_SUCCESS = 'SHIPMENT_MAP_LEGEND_SUCCESS';
const SHIPMENT_MAP_LEGEND_FAILURE = 'SHIPMENT_MAP_LEGEND_FAILURE';

const SHIPMENT_MAP_LEGEND_FILTERED_REQUEST = 'SHIPMENT_MAP_LEGEND_FILTERED_REQUEST';
const SHIPMENT_MAP_LEGEND_FILTERED_SUCCESS = 'SHIPMENT_MAP_LEGEND_FILTERED_SUCCESS';
const SHIPMENT_MAP_LEGEND_FILTERED_FAILURE = 'SHIPMENT_MAP_LEGEND_FILTERED_FAILURE';

const ORDER_MAP_LEGEND_REQUEST = 'ORDER_MAP_LEGEND_REQUEST';
const ORDER_MAP_LEGEND_SUCCESS = 'ORDER_MAP_LEGEND_SUCCESS';
const ORDER_MAP_LEGEND_FAILURE = 'ORDER_MAP_LEGEND_FAILURE';

const ORDER_MAP_LEGEND_FILTERED_REQUEST = 'ORDER_MAP_LEGEND_FILTERED_REQUEST';
const ORDER_MAP_LEGEND_FILTERED_SUCCESS = 'ORDER_MAP_LEGEND_FILTERED_SUCCESS';
const ORDER_MAP_LEGEND_FILTERED_FAILURE = 'ORDER_MAP_LEGEND_FILTERED_FAILURE';

interface DimensionRequest {
    dimensionName: DimensionNames;
    dimensionLimit: number;
}

interface DimensionSummaryServiceRequest {
    filters: Filter[];
    activeShipments: boolean;
    dimensions: DimensionRequest[];
}

interface OrdersDimensionSummaryServiceRequest {
    filters: Filter[];
    activeOrders: boolean;
    dimensions: DimensionRequest[];
}

interface DimensionsData {
    summaryCountsStatus: ApiStatus;
    summaryCounts: CounterTabItem[];
    summaryCountsTotal: number;
    shipmentMapLegendStatus: ApiStatus;
    shipmentMapLegend: SearchDimension[];
    shipmentMapLegendFilteredStatus: ApiStatus;
    shipmentMapLegendFiltered: SearchDimension[];
    orderMapLegendStatus: ApiStatus;
    orderMapLegend: SearchDimension[];
    orderMapLegendFilteredStatus: ApiStatus;
    orderMapLegendFiltered: SearchDimension[];
}

const requestDimensionSummaryCounts = (): Action<typeof DIMENSION_SUMMARY_COUNTS_REQUEST> => {
    return {
        type: DIMENSION_SUMMARY_COUNTS_REQUEST
    };
};

const receiveDimensionSummaryCounts = (requestedCounters: UserSettingAttribute[], json: DimensionSummary): GenericAction<typeof DIMENSION_SUMMARY_COUNTS_SUCCESS,
    {
        requestedCounters: UserSettingAttribute[];
        response: DimensionSummary;
    }> => {
    return {
        type: DIMENSION_SUMMARY_COUNTS_SUCCESS,
        payload: {
            requestedCounters,
            response: json
        }
    };
};

const requestDimensionSummaryCountsFailed = (): Action<typeof DIMENSION_SUMMARY_COUNTS_FAILURE> => {
    return {
        type: DIMENSION_SUMMARY_COUNTS_FAILURE
    };
};

const requestShipmentMapLegend = (): Action<typeof SHIPMENT_MAP_LEGEND_REQUEST> => {
    return {
        type: SHIPMENT_MAP_LEGEND_REQUEST
    };
};

const receiveShipmentMapLegend = (json: SearchDimension[]): GenericAction<typeof SHIPMENT_MAP_LEGEND_SUCCESS, SearchDimension[]> => {
    return {
        type: SHIPMENT_MAP_LEGEND_SUCCESS,
        payload: json
    };
};

const requestShipmentMapLegendFailed = (): Action<typeof SHIPMENT_MAP_LEGEND_FAILURE> => {
    return {
        type: SHIPMENT_MAP_LEGEND_FAILURE
    };
};

const requestShipmentMapLegendFiltered = (): Action<typeof SHIPMENT_MAP_LEGEND_FILTERED_REQUEST> => {
    return {
        type: SHIPMENT_MAP_LEGEND_FILTERED_REQUEST
    };
};

const receiveShipmentMapLegendFiltered = (json: SearchDimension[]): GenericAction<typeof SHIPMENT_MAP_LEGEND_FILTERED_SUCCESS, SearchDimension[]> => {
    return {
        type: SHIPMENT_MAP_LEGEND_FILTERED_SUCCESS,
        payload: json
    };
};

const requestShipmentMapLegendFilteredFailed = (): Action<typeof SHIPMENT_MAP_LEGEND_FILTERED_FAILURE> => {
    return {
        type: SHIPMENT_MAP_LEGEND_FILTERED_FAILURE
    };
};

const requestOrderMapLegend = (): Action<typeof ORDER_MAP_LEGEND_REQUEST> => {
    return {
        type: ORDER_MAP_LEGEND_REQUEST
    };
};

const receiveOrderMapLegend = (json: SearchDimension[]): GenericAction<typeof ORDER_MAP_LEGEND_SUCCESS, SearchDimension[]> => {
    return {
        type: ORDER_MAP_LEGEND_SUCCESS,
        payload: json
    };
};

const requestOrderMapLegendFailed = (): Action<typeof ORDER_MAP_LEGEND_FAILURE> => {
    return {
        type: ORDER_MAP_LEGEND_FAILURE
    };
};

const requestOrderMapLegendFiltered = (): Action<typeof ORDER_MAP_LEGEND_FILTERED_REQUEST> => {
    return {
        type: ORDER_MAP_LEGEND_FILTERED_REQUEST
    };
};

const receiveOrderMapLegendFiltered = (json: SearchDimension[]): GenericAction<typeof ORDER_MAP_LEGEND_FILTERED_SUCCESS, SearchDimension[]> => {
    return {
        type: ORDER_MAP_LEGEND_FILTERED_SUCCESS,
        payload: json
    };
};

const requestOrderMapLegendFilteredFailed = (): Action<typeof ORDER_MAP_LEGEND_FILTERED_FAILURE> => {
    return {
        type: ORDER_MAP_LEGEND_FILTERED_FAILURE
    };
};

export const fetchDimensionSummaryCounts = ({
    userSettingsCounters,
    activeShipments,
    filters,
    isAutoRefreshRequest = false
}: {
    userSettingsCounters: UserSettingAttribute[];
    activeShipments: boolean;
    filters: Filter[];
    isAutoRefreshRequest?: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        // do not dispatch requestDimensionSummaryCounts when doing an auto refresh
        if (isAutoRefreshRequest === false) {
            dispatch(requestDimensionSummaryCounts());
        }

        const uniqueFieldNames = [...Array.from(new Set(userSettingsCounters.map((item): string => {
            return item.fieldName;
        })))];

        // Only call the API if we have fieldNames
        let dimensions: DimensionRequest[];
        if (uniqueFieldNames.length > 0) {
            dimensions = uniqueFieldNames.map((fieldName): {
                dimensionName: DimensionNames;
                dimensionLimit: number;
            } => {
                return {
                    dimensionName: fieldName as DimensionNames,
                    dimensionLimit: 10
                };
            });
        } else {
            // if there are no chosen dimensions, we need to make a "fake" api call so we can get a total count
            dimensions = [{
                dimensionName: DimensionNames.DeliveryStatus,
                dimensionLimit: 10
            }];
        }

        const requestBody: DimensionSummaryServiceRequest = {
            activeShipments,
            filters,
            dimensions
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.dimensionSummary}`,
                body: requestBody
            }) as GenericApiResponse<DimensionSummary>;

            const { data } = json;
            dispatch(receiveDimensionSummaryCounts(userSettingsCounters, data[0]));

            if (isAutoRefreshRequest) {
                toast.success('Counters successfully auto-refreshed.', {
                    toastId: 'countersRefreshSuccess',
                    autoClose: 5000,
                    hideProgressBar: true,
                    pauseOnFocusLoss: false,
                    closeOnClick: true
                });
            }
        } catch (error) {
            dispatch(requestDimensionSummaryCountsFailed());
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    toast.error(errorMessage);
                });
            } else {
                toast.error('Error occurred while fetching dimension summary counts.');
            }
        }
    };
};

export const fetchShipmentMapLegend = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentMapLegend());

        const requestBody: DimensionSummaryServiceRequest = {
            filters: [],
            activeShipments: true,
            dimensions: [
                {
                    dimensionName: DimensionNames.DeliveryStatus,
                    dimensionLimit: 10
                }
            ]
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.dimensionSummary}`,
                body: requestBody
            }) as GenericApiResponse<DimensionSummary>;

            const { data } = json;
            if (data[0]?.searchDimensions?.DeliveryStatus !== undefined) {
                dispatch(receiveShipmentMapLegend(data[0].searchDimensions.DeliveryStatus));
            } else {
                dispatch(requestShipmentMapLegendFailed());
            }
        } catch (err) {
            dispatch(requestShipmentMapLegendFailed());
            toast.error('Error occurred while fetching delivery status counts.');
        }
    };
};

export const fetchShipmentMapLegendFiltered = (filters: Filter[]): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        // if filters exist, also make a call with the filters for the map legend
        if (filters.length > 0) {
            dispatch(requestShipmentMapLegendFiltered());

            const requestBody: DimensionSummaryServiceRequest = {
                filters,
                activeShipments: true,
                dimensions: [
                    {
                        dimensionName: DimensionNames.DeliveryStatus,
                        dimensionLimit: 10
                    }
                ]
            };

            try {
                const json = await ApiService.post({
                    url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.dimensionSummary}`,
                    body: requestBody
                }) as GenericApiResponse<DimensionSummary>;

                const { data } = json;
                if (data[0]?.searchDimensions?.DeliveryStatus !== undefined) {
                    dispatch(receiveShipmentMapLegendFiltered(data[0].searchDimensions.DeliveryStatus));
                } else {
                    dispatch(requestShipmentMapLegendFilteredFailed());
                }
            } catch (err) {
                dispatch(requestShipmentMapLegendFilteredFailed());
                toast.error('Error occurred while fetching filtered delivery status counts.');
            }
        } else {
            // if there are no filters, we need to set the redux store to have no filtered list, but also get out of the initial "idle" status
            dispatch(receiveShipmentMapLegendFiltered([]));
        }
    };
};

export const fetchOrderMapLegend = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestOrderMapLegend());

        const requestBody: OrdersDimensionSummaryServiceRequest = {
            filters: [],
            activeOrders: true,
            dimensions: [
                {
                    dimensionName: DimensionNames.DeliveryStatus,
                    dimensionLimit: 10
                }
            ]
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.orderDimensionSummary}`,
                body: requestBody
            }) as GenericApiResponse<DimensionSummary>;

            const { data } = json;
            if (data[0]?.searchDimensions?.DeliveryStatus !== undefined) {
                dispatch(receiveOrderMapLegend(data[0].searchDimensions.DeliveryStatus));
            } else {
                dispatch(requestOrderMapLegendFailed());
            }
        } catch (err) {
            dispatch(requestOrderMapLegendFailed());
            toast.error('Error occurred while fetching delivery status counts.');
        }
    };
};

export const fetchOrderMapLegendFiltered = (filters: Filter[]): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        // if filters exist, also make a call with the filters for the map legend
        if (filters.length > 0) {
            dispatch(requestOrderMapLegendFiltered());

            const requestBody: OrdersDimensionSummaryServiceRequest = {
                filters,
                activeOrders: true,
                dimensions: [
                    {
                        dimensionName: DimensionNames.DeliveryStatus,
                        dimensionLimit: 10
                    }
                ]
            };

            try {
                const json = await ApiService.post({
                    url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.orderDimensionSummary}`,
                    body: requestBody
                }) as GenericApiResponse<DimensionSummary>;

                const { data } = json;
                if (data[0]?.searchDimensions?.DeliveryStatus !== undefined) {
                    dispatch(receiveOrderMapLegendFiltered(data[0].searchDimensions.DeliveryStatus));
                } else {
                    dispatch(requestOrderMapLegendFilteredFailed());
                }
            } catch (err) {
                dispatch(requestOrderMapLegendFilteredFailed());
                toast.error('Error occurred while fetching filtered delivery status counts.');
            }
        } else {
            // if there are no filters, we need to set the redux store to have no filtered list, but also get out of the initial "idle" status
            dispatch(receiveOrderMapLegendFiltered([]));
        }
    };
};

type DimensionsActionTypes =
    ReturnType<typeof requestDimensionSummaryCounts> | ReturnType<typeof receiveDimensionSummaryCounts> | ReturnType<typeof requestDimensionSummaryCountsFailed> |
    ReturnType<typeof requestShipmentMapLegend> | ReturnType<typeof receiveShipmentMapLegend> | ReturnType<typeof requestShipmentMapLegendFailed> |
    ReturnType<typeof requestShipmentMapLegendFiltered> | ReturnType<typeof receiveShipmentMapLegendFiltered> | ReturnType<typeof requestShipmentMapLegendFilteredFailed> |
    ReturnType<typeof requestOrderMapLegend> | ReturnType<typeof receiveOrderMapLegend> | ReturnType<typeof requestOrderMapLegendFailed> |
    ReturnType<typeof requestOrderMapLegendFiltered> | ReturnType<typeof receiveOrderMapLegendFiltered> | ReturnType<typeof requestOrderMapLegendFilteredFailed>;

export const dimensionsReducer = (dimensions: DimensionsData = {
    summaryCountsStatus: ApiStatus.Idle,
    summaryCounts: [],
    summaryCountsTotal: 0,
    shipmentMapLegendStatus: ApiStatus.Idle,
    shipmentMapLegend: [],
    shipmentMapLegendFilteredStatus: ApiStatus.Idle,
    shipmentMapLegendFiltered: [],
    orderMapLegendStatus: ApiStatus.Idle,
    orderMapLegend: [],
    orderMapLegendFilteredStatus: ApiStatus.Idle,
    orderMapLegendFiltered: []
}, action: DimensionsActionTypes): DimensionsData => {
    switch (action.type) {
        case DIMENSION_SUMMARY_COUNTS_REQUEST: {
            return {
                ...dimensions,
                summaryCountsStatus: ApiStatus.Loading
            };
        }
        case DIMENSION_SUMMARY_COUNTS_SUCCESS: {
            const summaryCounts = action.payload.requestedCounters.map((counter): CounterTabItem => {
                const dimensionCounts: SearchDimension[] = (action.payload.response.searchDimensions as any)[counter.fieldName];

                const total = dimensionCounts.reduce((accumulator: number, currentValue: SearchDimension): number => {
                    const currentCounter = counter.fieldValue?.some((fieldValue): boolean => {
                        return fieldValue === currentValue.value;
                    });

                    return currentCounter ? accumulator + currentValue.count : accumulator;
                }, 0);

                return {
                    key: counter.key,
                    label: counter.displayName,
                    count: total
                };
            });

            return {
                ...dimensions,
                summaryCountsStatus: ApiStatus.Success,
                summaryCounts,
                summaryCountsTotal: action.payload.response.totalCount
            };
        }
        case DIMENSION_SUMMARY_COUNTS_FAILURE: {
            return {
                ...dimensions,
                summaryCountsStatus: ApiStatus.Failure,
                summaryCounts: [],
                summaryCountsTotal: 0
            };
        }
        case SHIPMENT_MAP_LEGEND_REQUEST: {
            return {
                ...dimensions,
                shipmentMapLegendStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_MAP_LEGEND_SUCCESS: {
            return {
                ...dimensions,
                shipmentMapLegendStatus: ApiStatus.Success,
                shipmentMapLegend: action.payload
            };
        }
        case SHIPMENT_MAP_LEGEND_FAILURE: {
            return {
                ...dimensions,
                shipmentMapLegendStatus: ApiStatus.Failure,
                shipmentMapLegend: []
            };
        }
        case SHIPMENT_MAP_LEGEND_FILTERED_REQUEST: {
            return {
                ...dimensions,
                shipmentMapLegendFilteredStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_MAP_LEGEND_FILTERED_SUCCESS: {
            return {
                ...dimensions,
                shipmentMapLegendFilteredStatus: ApiStatus.Success,
                shipmentMapLegendFiltered: action.payload
            };
        }
        case SHIPMENT_MAP_LEGEND_FILTERED_FAILURE: {
            return {
                ...dimensions,
                shipmentMapLegendFilteredStatus: ApiStatus.Failure,
                shipmentMapLegendFiltered: []
            };
        }
        case ORDER_MAP_LEGEND_REQUEST: {
            return {
                ...dimensions,
                orderMapLegendStatus: ApiStatus.Loading
            };
        }
        case ORDER_MAP_LEGEND_SUCCESS: {
            return {
                ...dimensions,
                orderMapLegendStatus: ApiStatus.Success,
                orderMapLegend: action.payload
            };
        }
        case ORDER_MAP_LEGEND_FAILURE: {
            return {
                ...dimensions,
                orderMapLegendStatus: ApiStatus.Failure,
                orderMapLegend: []
            };
        }
        case ORDER_MAP_LEGEND_FILTERED_REQUEST: {
            return {
                ...dimensions,
                orderMapLegendFilteredStatus: ApiStatus.Loading
            };
        }
        case ORDER_MAP_LEGEND_FILTERED_SUCCESS: {
            return {
                ...dimensions,
                orderMapLegendFilteredStatus: ApiStatus.Success,
                orderMapLegendFiltered: action.payload
            };
        }
        case ORDER_MAP_LEGEND_FILTERED_FAILURE: {
            return {
                ...dimensions,
                orderMapLegendFilteredStatus: ApiStatus.Failure,
                orderMapLegendFiltered: []
            };
        }
        default:
            return dimensions;
    }
};
