import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiCellphoneSound } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchShipments } from '../../redux/shipments';
import { privateRoutes } from '../../routes/appRouteList';
import { ShipmentListData } from '../../interfaces/services/shipment';
import { CellParams, ColDef, OptionalColDef, Order } from '../../interfaces/muiTableInterfaces';
import { UserSettingAttribute } from '../../interfaces/services/organization';
import { ApiStatus, FreightHaulerIdentifierTypeName, LinearUnitNumeric, ShareType, SortDirection } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter, formatPhoneNumber } from '../../helpers/dataUtils';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import ShipmentListActions from '../actionBars/shipmentListActions';
import ShipmentListHistoryActions from '../actionBars/shipmentListHistoryActions';
import TableColumnHeader from './tableColumnHeader';
import DataListCell from './dataListCell';
import DataListLoading from '../loaders/lists/dataListLoading';
import DataListNoResults from '../errors/dataListNoResults';
import ShipmentDetailsLabelLink from '../links/shipmentDetailsLabelLink';
import TripDetailsLabelLink from '../links/tripDetailsLabelLink';
import AddReferenceNumbersDialog from '../dialogs/addReferenceNumbersDialog';
import DocumentsDialog from '../dialogs/documentsDialog';
import EditTimesDialog from '../dialogs/editTimesDialog';
import ManageDriverDialog from '../dialogs/manageDriverDialog';
import NotesDialog from '../dialogs/notesDialog';
import ShipmentStatusDialog from '../dialogs/shipmentStatusDialog';

interface StyleProps {
    filtersName: string;
    shareType: ShareType | null;
}

const classesPrefix = 'shipmentListTable';

const classes = {
    shipmentsTableWrapper: `${classesPrefix}-shipmentsTableWrapper`,
    tableHeader: `${classesPrefix}-tableHeader`,
    tableRow: `${classesPrefix}-tableRow`,
    tableCell: `${classesPrefix}-tableCell`,
    tableCellSpan: `${classesPrefix}-tableCellSpan`,
    phoneNumber: `${classesPrefix}-phoneNumber`
};

const StyledTableContainer = styled(
    TableContainer,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'filtersName' && prop !== 'shareType'; }
    }
)<StyleProps>(({ filtersName, shareType, theme }) => {
    let subtractHeight = 261;
    if (shareType === ShareType.View) {
        subtractHeight = 158;
    }
    if (filtersName) {
        subtractHeight += 48;
    }
    return {
        [`&.${classes.shipmentsTableWrapper}`]: {
            width: '100%',
            height: shareType === ShareType.View ? 'calc(100vh - 164px)' : 'calc(100vh - 232px)',
            borderBottom: `2px solid ${theme.palette.divider}`,
            overflow: 'scroll'
        },
        [`& .${classes.tableHeader}`]: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            display: 'inline-block'
        },
        [`& .${classes.tableRow}`]: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover
            }
        },
        [`& .${classes.tableCell}`]: {
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        [`& .${classes.tableCellSpan}`]: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
        },
        [`& .${classes.phoneNumber}`]: {
            fontSize: '12px',
            verticalAlign: 'top',
            paddingLeft: '4px'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.shipmentsTableWrapper}`]: {
                height: `calc(100vh - ${subtractHeight}px)`
            }
        }
    };
});

const ShipmentListTable = (): JSX.Element => {
    const location = useLocation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });
    const userSettingsUpdateStatus = useTypedSelector((state) => { return state.user.userSettingsUpdateStatus; });
    const userSettingsStatus = useTypedSelector((state) => { return state.user.userSettingsStatus; });
    const userSettingsColumns = useTypedSelector((state) => { return state.user.userSettings.userSettingsColumns; });

    const shipmentListStatus = useTypedSelector((state) => { return state.shipments.shipmentListStatus; });
    const shipmentList = useTypedSelector((state) => { return state.shipments.shipmentList; });
    const activeShipments = useTypedSelector((state) => { return state.shipments.activeShipments; });
    const skip = useTypedSelector((state) => { return state.shipments.skip; });
    const take = useTypedSelector((state) => { return state.shipments.take; });
    const searchTerm = useTypedSelector((state) => { return state.shipments.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.shipments.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.shipments.sortDirection; });
    const filters = useTypedSelector((state) => { return state.shipments.filters; });
    const filtersName = useTypedSelector((state) => { return state.shipments.filtersName; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const [columnData, setColumnData] = useState<ColDef<ShipmentListData>[]>([]);
    const [order, setOrder] = useState<Order>(sortDirection === SortDirection.Ascending ? 'asc' : 'desc');
    const [orderBy, setOrderBy] = useState<keyof ShipmentListData>(sortColumn);

    const [activeRow, setActiveRow] = useState<ShipmentListData | null>(null);
    const [addReferenceNumbersDialogIsOpen, setAddReferenceNumbersDialogIsOpen] = useState(false);
    const [documentsDialogIsOpen, setDocumentsDialogIsOpen] = useState(false);
    const [editTimesDialogIsOpen, setEditTimesDialogIsOpen] = useState<{ isOpen: boolean; stopSequence: number; }>({ isOpen: false, stopSequence: 0 });
    const [manageDriverDialogIsOpen, setManageDriverDialogIsOpen] = useState(false);
    const [notesDialogIsOpen, setNotesDialogIsOpen] = useState(false);
    const [shipmentStatusDialogIsOpen, setShipmentStatusDialogIsOpen] = useState(false);

    const staticLoadingColumns = [{ field: 'Actions', width: location.pathname === privateRoutes.shipmentsHistory ? 72 : 48 }];

    useEffect((): void => {
        if (userSettingsStatus === ApiStatus.Idle || userSettingsStatus === ApiStatus.Loading || userSettingsUpdateStatus === ApiStatus.Loading) {
            setColumnData([]);
        }

        /* eslint-disable react/display-name */
        const columnLookup = (fieldName: keyof ShipmentListData): OptionalColDef<ShipmentListData> => {
            switch (fieldName) {
                case 'freightProviderReferenceNumber':
                case 'shipmentUniqueName': {
                    return {
                        width: 130,
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return (
                                <span className={classes.tableCellSpan} style={{ width: params.width }}>
                                    <ShipmentDetailsLabelLink
                                        label=''
                                        noWrap
                                        shipmentUniqueName={params.data.shipmentUniqueName}
                                        freightProviderReferenceNumber={params.data.freightProviderReferenceNumber}
                                        modeType={params.data.modeType}
                                        displayField={fieldName}
                                    />
                                </span>
                            );
                        }
                    };
                }
                case 'tripId': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            if (params.value) {
                                return (
                                    <TripDetailsLabelLink
                                        label=''
                                        tripId={params.value as string}
                                        noWrap
                                    />
                                );
                            }

                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>--</span>;
                        }
                    };
                }
                // Columns that are enums and need to have a space added
                case 'shipmentStatus':
                case 'deliveryStatus': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{addSpaceBeforeUppercaseCharacter(params.value as string)}</span>;
                        },
                        ...fieldName === 'shipmentStatus' && {
                            onClick: (params: CellParams<ShipmentListData>): void => {
                                setActiveRow(params.data);
                                setShipmentStatusDialogIsOpen(true);
                            }
                        }
                    };
                }
                // Columns that are dates
                case 'expirationDate':
                case 'currentPositionDateTime':
                case 'originScheduleStartDateTime':
                case 'originScheduleEndDateTime':
                case 'originGeofenceArrivalDateTime':
                case 'originGeofenceDepartureDateTime':
                case 'destinationScheduleStartDateTime':
                case 'destinationScheduleEndDateTime':
                case 'destinationGeofenceArrivalDateTime':
                case 'destinationGeofenceDepartureDateTime': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{zonedDateTimeToDisplay(params.value as string | null) || '--'}</span>;
                        },
                        width: 150
                    };
                }
                // Columns that are clickable dates
                case 'originAppointmentStartDateTime':
                case 'originAppointmentEndDateTime':
                case 'originCarrierArrivalDateTime':
                case 'originCarrierDepartureDateTime':
                case 'destinationAppointmentStartDateTime':
                case 'destinationAppointmentEndDateTime':
                case 'destinationCarrierArrivalDateTime':
                case 'destinationCarrierDepartureDateTime': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{zonedDateTimeToDisplay(params.value as string | null) || '--'}</span>;
                        },
                        width: 150,
                        onClick: (params: CellParams<ShipmentListData>): void => {
                            setActiveRow(params.data);
                            const stopSequence = fieldName.startsWith('destination') ? params.data.numberOfStops - 1 : 0;
                            setEditTimesDialogIsOpen({ isOpen: true, stopSequence });
                        }
                    };
                }
                // columns that are ETAs and could be hidden.
                case 'activeStopETA':
                case 'originStopETA':
                case 'destinationStopETA': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            if (organizationPreferences?.showEta === false) {
                                return <Tooltip title='ETA hidden by Preference Setting'><span className={classes.tableCellSpan} style={{ width: params.width }}>--</span></Tooltip>;
                            }
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{zonedDateTimeToDisplay(params.value as string | null) || '--'}</span>;
                        },
                        width: 150,
                        onClick: (params: CellParams<ShipmentListData>): void => {
                            setActiveRow(params.data);
                            const stopSequence = fieldName.startsWith('destination') ? params.data.numberOfStops - 1 : 0;
                            setEditTimesDialogIsOpen({ isOpen: true, stopSequence });
                        }
                    };
                }

                // Columns that have a default value of '--'
                case 'tractorReferenceNumber':
                case 'trailerReferenceNumber':
                case 'freightHaulerIdentifierName': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{params.value as string || '--'}</span>;
                        },
                        ...(fieldName === 'tractorReferenceNumber' || fieldName === 'trailerReferenceNumber') && {
                            onClick: (params: CellParams<ShipmentListData>): void => {
                                setActiveRow(params.data);
                                setAddReferenceNumbersDialogIsOpen(true);
                            }
                        }
                    };
                }
                // Columns that are booleans
                case 'isPriorityShipment':
                case 'hasTemperatureExceededBoundsFlag': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{params.value === true ? 'Yes' : 'No'}</span>;
                        }
                    };
                }
                case 'hasShipmentNotes': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{params.value === true ? 'Yes' : 'No'}</span>;
                        },
                        onClick: (params: CellParams<ShipmentListData>): void => {
                            setActiveRow(params.data);
                            setNotesDialogIsOpen(true);
                        }
                    };
                }
                case 'hasDriverNotes': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{params.value === true ? 'Yes' : 'No'}</span>;
                        },
                        onClick: (params: CellParams<ShipmentListData>): void => {
                            setActiveRow(params.data);
                            setManageDriverDialogIsOpen(true);
                        }
                    };
                }
                case 'hasShipmentDocuments': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>{params.value === true ? 'Yes' : 'No'}</span>;
                        },
                        onClick: (params: CellParams<ShipmentListData>): void => {
                            setActiveRow(params.data);
                            setDocumentsDialogIsOpen(true);
                        }
                    };
                }
                case 'freightHaulerIdentifierTypeName': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            if (params.value === FreightHaulerIdentifierTypeName.MCNumber) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>MC</span>;
                            }
                            if (params.value === FreightHaulerIdentifierTypeName.USDotNumber) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>DOT</span>;
                            }
                            if (params.value === FreightHaulerIdentifierTypeName.SCACCode) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>SCAC</span>;
                            }
                            if (params.value === FreightHaulerIdentifierTypeName.PNetCID) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>PNET</span>;
                            }
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>--</span>;
                        }
                    };
                }
                case 'haulLinearUnitType': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            if (params.value === LinearUnitNumeric.Miles) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>Miles</span>;
                            }
                            if (params.value === LinearUnitNumeric.Feet) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>Feet</span>;
                            }
                            if (params.value === LinearUnitNumeric.Kilometers) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>Kilometers</span>;
                            }
                            if (params.value === LinearUnitNumeric.Meters) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>Meters</span>;
                            }
                            if (params.value === LinearUnitNumeric.Inches) {
                                return <span className={classes.tableCellSpan} style={{ width: params.width }}>Inches</span>;
                            }
                            return <span className={classes.tableCellSpan} style={{ width: params.width }}>--</span>;
                        }
                    };
                }
                case 'mobileTrackingPhoneNumber': {
                    return {
                        renderCell: (params: CellParams<ShipmentListData>): JSX.Element => {
                            const phoneNumber = formatPhoneNumber(params.value as string);
                            return (
                                <Typography noWrap>
                                    {
                                        params.data.mobileTrackingStatus.toLowerCase() === 'mobile' &&
                                        <Tooltip title='Tracking Accepted' placement='bottom'>
                                            <Icon
                                                path={mdiCellphoneSound}
                                                size={0.75}
                                                color={theme.palette.text.primary}
                                                data-qa='trackingAccepted-icon'
                                            />
                                        </Tooltip>
                                    }
                                    <span className={classes.phoneNumber}>{phoneNumber || '--'}</span>
                                </Typography>
                            );
                        },
                        onClick: (params: CellParams<ShipmentListData>): void => {
                            setActiveRow(params.data);
                            setManageDriverDialogIsOpen(true);
                        }
                    };
                }
                // by default return no optional fields for the column and the column will be rendered with the defaults
                default: {
                    return {};
                }
            }
        };
        /* eslint-enable react/display-name */

        const columnList = userSettingsColumns.reduce((list: ColDef<ShipmentListData>[], item: UserSettingAttribute): ColDef<ShipmentListData>[] => {
            const fieldName = item.fieldName as keyof ShipmentListData;
            const columnDef = columnLookup(fieldName);

            if (item.visible) {
                list.push({
                    headerName: item.displayName,
                    field: fieldName,
                    ...columnDef
                });
            }

            return list;
        }, []);

        setColumnData(columnList);
    }, [userSettingsStatus, userSettingsUpdateStatus, userSettingsColumns, theme.palette.text.primary, organizationPreferences?.showEta]);

    useEffect((): void => {
        setOrder(sortDirection === SortDirection.Ascending ? 'asc' : 'desc');
        setOrderBy(sortColumn);
    }, [sortDirection, sortColumn]);

    const handleWidthChange = (columnId: keyof ShipmentListData, width: number): void => {
        const newColumns = columnData.map((column): ColDef<ShipmentListData> => {
            if (column.field === columnId) {
                return {
                    ...column,
                    width
                };
            }
            return column;
        });
        setColumnData(newColumns);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, newSortColumn: keyof ShipmentListData): void => {
        const isAsc = orderBy === newSortColumn && order === 'asc';
        const newSortDirection = isAsc ? SortDirection.Descending : SortDirection.Ascending;
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(newSortColumn);

        dispatch(fetchShipments({
            activeShipments,
            skip,
            take,
            searchTerm,
            sortColumn: newSortColumn,
            sortDirection: newSortDirection,
            filters
        }));
    };

    // Creates a memoized list of the table rows which will only adjust when the shipmentList changes.
    // This helps fix some performance issues that are causing this entire table to re-render unnecessarily.
    const memoizedTableRows = useMemo((): JSX.Element[] => {
        return shipmentList.map((row): JSX.Element => {
            return (
                <TableRow key={row.shipmentUniqueName} classes={{ root: classes.tableRow }} data-qa={`shipmentList-row-${row.shipmentUniqueName}`}>
                    <TableCell className={classes.tableCell} data-qa='actions-container'>
                        {
                            location.pathname === privateRoutes.shipmentsActive &&
                            <ShipmentListActions shipment={row} iconColor={theme.palette.text.primary} />
                        }
                        {
                            location.pathname === privateRoutes.shipmentsHistory &&
                            <ShipmentListHistoryActions
                                modeType={row.modeType}
                                shipmentUniqueName={row.shipmentUniqueName}
                                singleShipmentSharingToken={row.singleShipmentSharingToken}
                            />
                        }
                    </TableCell>
                    {
                        columnData.map((column): JSX.Element => {
                            return (
                                <DataListCell
                                    key={`${row.shipmentUniqueName}${column.field}`}
                                    column={column}
                                    row={row}
                                />
                            );
                        })
                    }
                </TableRow>
            );
        });
    }, [shipmentList, location.pathname, columnData, theme.palette.text.primary]);

    const renderTableBody = (): JSX.Element | JSX.Element[] => {
        // if just the shipment api is running, render only a loading table body
        if (shipmentListStatus === ApiStatus.Idle || shipmentListStatus === ApiStatus.Loading) {
            return <DataListLoading columnData={columnData} staticColumns={staticLoadingColumns} />;
        }

        if (shipmentList.length === 0) {
            return (
                <TableBody>
                    <DataListNoResults
                        message={`No ${location.pathname === privateRoutes.shipmentsHistory ? 'Historical' : 'Active'} Shipments Found`}
                        colSpan={columnData.length + 1}
                    />
                </TableBody>
            );
        }

        return (
            <TableBody>
                {memoizedTableRows}
            </TableBody>
        );
    };

    const renderTable = (): JSX.Element => {
        // if the user settings api is running, render a full table with fake headers
        if (userSettingsStatus === ApiStatus.Idle || userSettingsStatus === ApiStatus.Loading || userSettingsUpdateStatus === ApiStatus.Loading) {
            return <DataListLoading columnData={[]} staticColumns={staticLoadingColumns} />;
        }

        return (
            <Fragment>
                <TableHead>
                    <TableRow>
                        <TableCell width={location.pathname === privateRoutes.shipmentsHistory ? 82 : 72} data-qa='actions-column-header'>
                            <span className={classes.tableHeader}>
                                Actions
                            </span>
                        </TableCell>
                        {
                            columnData.map((column): JSX.Element => {
                                return (
                                    <TableColumnHeader
                                        key={column.field}
                                        column={column}
                                        order={order}
                                        orderBy={orderBy}
                                        handleWidthChange={handleWidthChange}
                                        handleRequestSort={handleRequestSort}
                                    />
                                );
                            })
                        }
                    </TableRow>
                </TableHead>
                {renderTableBody()}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <StyledTableContainer filtersName={filtersName} shareType={shareType} className={classes.shipmentsTableWrapper}>
                <Table
                    stickyHeader
                    aria-labelledby='tableTitle'
                    size='small'
                    aria-label='shipment table'
                    data-qa='shipmentList-table'
                >
                    {renderTable()}
                </Table>
            </StyledTableContainer>

            {
                addReferenceNumbersDialogIsOpen && activeRow &&
                <AddReferenceNumbersDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    initialTractorId={activeRow.tractorReferenceNumber}
                    initialTrailerId={activeRow.trailerReferenceNumber}
                    isOpen={addReferenceNumbersDialogIsOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setAddReferenceNumbersDialogIsOpen(false);
                    }}
                />
            }

            {
                documentsDialogIsOpen && activeRow &&
                <DocumentsDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    isOpen={documentsDialogIsOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setDocumentsDialogIsOpen(false);
                    }}
                />
            }

            {
                editTimesDialogIsOpen.isOpen && activeRow &&
                <EditTimesDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    initialStopSequence={editTimesDialogIsOpen.stopSequence}
                    isOpen={editTimesDialogIsOpen.isOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setEditTimesDialogIsOpen({ isOpen: false, stopSequence: 0 });
                    }}
                />
            }

            {
                manageDriverDialogIsOpen && activeRow &&
                <ManageDriverDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    freightHaulerIdentifierTypeName={activeRow.freightHaulerIdentifierTypeName}
                    freightHaulerIdentifierName={activeRow.freightHaulerIdentifierName}
                    initialPhoneNumber={activeRow.mobileTrackingPhoneNumber}
                    isOpen={manageDriverDialogIsOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setManageDriverDialogIsOpen(false);
                    }}
                />
            }

            {
                notesDialogIsOpen && activeRow &&
                <NotesDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    isOpen={notesDialogIsOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setNotesDialogIsOpen(false);
                    }}
                />
            }

            {
                shipmentStatusDialogIsOpen && activeRow &&
                <ShipmentStatusDialog
                    shipmentUniqueName={activeRow.shipmentUniqueName}
                    freightHaulerIdentifierTypeName={activeRow.freightHaulerIdentifierTypeName}
                    freightHaulerIdentifierName={activeRow.freightHaulerIdentifierName}
                    shipmentStatus={activeRow.shipmentStatus}
                    isOpen={shipmentStatusDialogIsOpen}
                    closeDialog={(): void => {
                        setActiveRow(null);
                        setShipmentStatusDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentListTable;
