import { Position, Properties } from '@turf/turf';
import { GeoJsonTypes, Point, Polygon, Feature } from 'geojson';

import { MapSources, LocationGeofenceFeatureTypes } from '../enums';
import { getGeofenceFeaturePointsCircle, getGeofenceRadiusInMiles } from '../mapUtils';

/** Interface used for the properties object on the Location Geofence Map Source. */
interface LocationGeofenceMapSourceProperties {
    featureType: LocationGeofenceFeatureTypes;
}

/** Interface used to define the Location Geofence Map Source. */
interface LocationGeofenceMapSource {
    type: 'geojson';
    data: {
        type: GeoJsonTypes;
        features: Feature<Point | Polygon, LocationGeofenceMapSourceProperties>[];
    };
}

/**
 * Using the location, it will add a point feature and using the geofence polygon or radius it will add a polygon feature.
 * Returns a geojson source to be added to the map.
 * @param location The location coordinates to show on the map
 * @param polygon The polygon that will be shown on the map if it exists
 * @param radiusInFeet The radius that will show as a circle on the map if it exists
 */
const getLocationGeofenceSource = (location: Position, polygon: Feature<Polygon, Properties> | null, radiusInFeet: number | null): LocationGeofenceMapSource => {
    const locationFeatures: Feature<Point | Polygon, LocationGeofenceMapSourceProperties>[] = [];

    locationFeatures.push({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: location
        },
        properties: {
            featureType: LocationGeofenceFeatureTypes.Location
        }
    });

    const geofenceFeatureProperties: LocationGeofenceMapSourceProperties = {
        featureType: LocationGeofenceFeatureTypes.Geofence
    };

    // if the polygon exists, use it for the geofence
    // otherwise, use the radius if it exists for the geofence
    // if neither exists, no geofence will show on the map.
    if (polygon) {
        locationFeatures.push({
            ...polygon,
            properties: geofenceFeatureProperties
        });
    } else if (radiusInFeet) {
        const radius = getGeofenceRadiusInMiles(radiusInFeet);
        const geofenceFeaturePoints = getGeofenceFeaturePointsCircle(location, radius, 'miles', geofenceFeatureProperties);
        const geofenceFeature = geofenceFeaturePoints as Feature<Polygon, LocationGeofenceMapSourceProperties>;

        locationFeatures.push(geofenceFeature);
    }

    const source: LocationGeofenceMapSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: locationFeatures
        }
    };

    return source;
};

/**
* Adds the location geofence source to the map if it doesn't already exist
 * @param map Instance of your map
 * @param location The location coordinates to show on the map
 * @param polygon The polygon that will be shown on the map if it exists
 * @param radiusInFeet The radius that will show as a circle on the map if it exists
 * @returns The source that was added to the map
*/
export const addLocationGeofenceSource = (map: any, location: Position, polygon: Feature<Polygon, Properties> | null, radiusInFeet: number | null): LocationGeofenceMapSource => {
    const source = getLocationGeofenceSource(location, polygon, radiusInFeet);

    if (map.getSource(MapSources.locationGeofenceSource) === undefined) {
        map.addSource(MapSources.locationGeofenceSource, source);
    } else {
        map.getSource(MapSources.locationGeofenceSource).setData(source.data);
    }

    return source;
};
