import React from 'react';
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Order } from '../../interfaces/services/shipmentDetails';
import StopOrdersTableRow from './stopOrdersTableRow';

const classesPrefix = 'stopOrdersTable';

const classes = {
    caption: `${classesPrefix}-caption`,
    tableHeader: `${classesPrefix}-tableHeader`
};

const StyledTableContainer = styled(TableContainer)(() => {
    return {
        [`& .${classes.caption}`]: {
            fontWeight: 600
        },
        [`& .${classes.tableHeader}`]: {
            fontWeight: 600
        }
    };
});

const StopOrdersTable = ({
    isFetchingData,
    stopReference,
    orders
}: {
    isFetchingData: boolean;
    stopReference: string;
    orders: Order[];
}): JSX.Element => {
    const renderTableBody = (): JSX.Element | JSX.Element[] => {
        if (isFetchingData) {
            return Array.from(new Array(3)).map((item, index): JSX.Element => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                        <TableCell><Skeleton variant='circular' width='24px' height='24px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='80px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                    </TableRow>
                );
            });
        }

        if (orders.length === 0) {
            return (
                <TableRow>
                    <TableCell align='center' colSpan={5} data-qa='orders-noResults'>No Orders Found</TableCell>
                </TableRow>
            );
        }

        return orders.map((order): JSX.Element => {
            return (
                <StopOrdersTableRow key={order.orderNumber} stopReference={stopReference} order={order} />
            );
        });
    };

    return (
        <StyledTableContainer>
            <Table size='small' aria-label='Orders' data-qa='orders-table'>
                <caption>
                    <Typography className={classes.caption} variant='h6' data-qa='orders-header'>Orders</Typography>
                </caption>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='orderId-column-header'>Order ID</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='stopAction-column-header'>Stop Action</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='customerReference-column-header'>Customer Reference</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='orderDate-column-header'>Order Date</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='orderType-column-header'>Order Type</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='serviceLevel-column-header'>Service Level</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='orderReference-column-header'>Order Reference</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='customerName-column-header'>Customer Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-qa='orders-content'>
                    {renderTableBody()}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default StopOrdersTable;
