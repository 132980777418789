import React, {
    Fragment, useState, useEffect, useReducer
} from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import {
    Button,
    Grid,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
    useMediaQuery,
    CircularProgress,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { GenericAction, useTypedSelector } from '../../redux';
import { updateFilters } from '../../redux/shipments';
import { addView } from '../../redux/dialogUpdates';
import { Filter } from '../../interfaces/services/shipment';
import {
    INITIALIZE_FILTERS,
    UPDATE_FILTER,
    FilterState,
    initialState
} from '../../interfaces/filterInterfaces';
import { AllowedFilterPropertyName, ApiStatus, SavedViewType, ShareType } from '../../helpers/enums';
import {
    deliveryStatusOptions,
    shipmentStatusOptions,
    modeTypeOptions,
    weatherSeverityOptions,
    hotShipmentOptions,
    ltlParcelMilestoneOptions
} from '../../helpers/hardcodedOptionLists';
import Endpoints from '../../services/endpoints';
import AsyncCreatable from '../selects/asyncCreatable';
import MultiSelect from '../selects/multiSelect';
import FilterDateRangePicker from '../pickers/filterDateRangePicker';
import AsyncMultiSelect from '../selects/asyncMultiSelect';

const classesPrefix = 'filters';

const classes = {
    filtersContainer: `${classesPrefix}-filtersContainer`,
    actionsContainer: `${classesPrefix}-actionsContainer`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.filtersContainer}`]: {
            flexGrow: 1,
            overflowY: 'auto',
            padding: '8px 8px 8px'
        }
    };
});

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.actionsContainer}`]: {
            padding: '8px 16px',
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper
        }
    };
});

const Filters = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = useState('');
    const [isDefault, setIsDefault] = useState(false);

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const MasterData = useTypedSelector((state) => { return state.availableServices.endpoints.MasterData; });

    const filters = useTypedSelector((state) => { return state.shipments.filters; });

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    const skip = useTypedSelector((state) => { return state.views.skip; });
    const take = useTypedSelector((state) => { return state.views.take; });
    const searchTerm = useTypedSelector((state) => { return state.views.searchTerm; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const sortDirection = useTypedSelector((state) => { return state.views.sortDirection; });
    const sortColumn = useTypedSelector((state) => { return state.views.sortColumn; });
    const viewFilters = useTypedSelector((state) => { return state.views.viewFilters; });

    const reducer = (
        state: FilterState,
        action: Action<typeof INITIALIZE_FILTERS> | GenericAction<typeof UPDATE_FILTER,
            {
                filterId: AllowedFilterPropertyName;
                filterValues: string[];
            }>
    ): FilterState => {
        switch (action.type) {
            case UPDATE_FILTER: {
                return {
                    ...state,
                    [action.payload.filterId]: action.payload.filterValues
                };
            }
            case INITIALIZE_FILTERS: {
                return initialState;
            }
            default: {
                return state;
            }
        }
    };

    const [filterState, localDispatch] = useReducer(reducer, initialState);

    const updateFilterState = (filterId: string, filterValues: string[]): void => {
        localDispatch({
            type: UPDATE_FILTER,
            payload: {
                filterId: filterId as AllowedFilterPropertyName,
                filterValues
            }
        });
    };

    useEffect((): void => {
        // if the redux filters are empty, empty the local filter state
        if (filters.length === 0) {
            localDispatch({
                type: INITIALIZE_FILTERS
            });
        } else {
            filters.forEach((filter): void => {
                updateFilterState(filter.propertyName, filter.propertyValues);
            });
        }
    }, [filters]);

    // Validate form fields and determine if the view can be saved
    useEffect((): void => {
        let isNameFieldValid = true;

        if (!name) {
            isNameFieldValid = false;
        }

        const isFiltersValid = Object.entries(filterState).some(([, filterValues]): boolean => {
            return filterValues.length > 0;
        });

        setCanSubmitForm(isNameFieldValid && isFiltersValid);
    }, [name, filterState]);

    const createFilterList = (): Filter[] => {
        const filterList = Object.entries(filterState).reduce((accumulator: Filter[], [filterName, filterValues]): Filter[] => {
            if (filterValues.length > 0) {
                accumulator.push({
                    propertyName: filterName as AllowedFilterPropertyName,
                    propertyValues: filterValues
                });
            }
            return accumulator;
        }, []);

        return filterList;
    };

    const handleResetClick = (): void => {
        localDispatch({
            type: INITIALIZE_FILTERS
        });
    };

    const handleSaveClick = (): void => {
        const newFilters = createFilterList();

        dispatch(addView({
            name,
            savedViewType: SavedViewType.UserView,
            isDefault,
            filters: newFilters,
            assignedViewUsers: [],
            // below params are needed to refetch the views list once the view has been added
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection,
            viewFilters
        }));
    };

    const handleApplyClick = (): void => {
        const newFilters = createFilterList();
        const filtersName = newFilters.length > 0 ? 'Filtered' : '';
        dispatch(updateFilters(newFilters, filtersName));
    };

    return (
        <Fragment>
            <StyledDiv className={classes.filtersContainer} data-qa='filters-container'>
                {
                    shareType !== ShareType.OperatorReadOnly && shareType !== ShareType.CustomerReadOnly &&
                    <Fragment>
                        <TextField
                            label='View Name'
                            value={name}
                            margin='none'
                            variant='filled'
                            fullWidth
                            onChange={(event): void => {
                                setName(event.currentTarget.value);
                            }}
                            inputProps={{
                                autoComplete: 'off',
                                'data-qa': 'saveViewName-input'
                            }}
                        />
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isDefault}
                                        onChange={(event): void => {
                                            setIsDefault(event.target.checked);
                                        }}
                                        color='primary'
                                    />
                                }
                                label='Set as Default View'
                                data-qa='setAsDefault-switch'
                            />
                        </FormGroup>
                    </Fragment>
                }
                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>{errorMessage}</Typography>
                    </Grid>
                }

                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.OriginScheduleStartDateTime}
                    label={isMobile ? 'Origin Sched. Start' : 'Origin Scheduled Start'}
                    currentValue={filterState[AllowedFilterPropertyName.OriginScheduleStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.OriginScheduleEndDateTime}
                    label={isMobile ? 'Origin Sched. End' : 'Origin Scheduled End'}
                    currentValue={filterState[AllowedFilterPropertyName.OriginScheduleEndDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.OriginAppointmentStartDateTime}
                    label={isMobile ? 'Origin Appt. Start' : 'Origin Appointment Start'}
                    currentValue={filterState[AllowedFilterPropertyName.OriginAppointmentStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.OriginAppointmentEndDateTime}
                    label={isMobile ? 'Origin Appt. End' : 'Origin Appointment End'}
                    currentValue={filterState[AllowedFilterPropertyName.OriginAppointmentEndDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DestinationScheduleStartDateTime}
                    label={isMobile ? 'Destination Sched. Start' : 'Destination Scheduled Start'}
                    currentValue={filterState[AllowedFilterPropertyName.DestinationScheduleStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DestinationScheduleEndDateTime}
                    label={isMobile ? 'Destination Sched. End' : 'Destination Scheduled End'}
                    currentValue={filterState[AllowedFilterPropertyName.DestinationScheduleEndDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DestinationAppointmentStartDateTime}
                    label={isMobile ? 'Destination Appt. Start' : 'Destination Appointment Start'}
                    currentValue={filterState[AllowedFilterPropertyName.DestinationAppointmentStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DestinationAppointmentEndDateTime}
                    label={isMobile ? 'Destination Appt. End' : 'Destination Appointment End'}
                    currentValue={filterState[AllowedFilterPropertyName.DestinationAppointmentEndDateTime]}
                    handleChange={updateFilterState}
                />

                <AsyncCreatable
                    id={AllowedFilterPropertyName.Customers}
                    label='Customers'
                    filterName='ProductCustomer'
                    values={filterState[AllowedFilterPropertyName.Customers]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Partners}
                    label='Carrier'
                    filterName='CarrierName'
                    values={filterState[AllowedFilterPropertyName.Partners]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Products}
                    label='Products'
                    filterName='ProductLine'
                    values={filterState[AllowedFilterPropertyName.Products]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Brand}
                    label='Brand/Business Unit'
                    filterName='ProductBrand'
                    values={filterState[AllowedFilterPropertyName.Brand]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Sku}
                    label='SKU/Commodity'
                    filterName='Sku'
                    values={filterState[AllowedFilterPropertyName.Sku]}
                    handleChange={updateFilterState}
                />
                <AsyncMultiSelect
                    id={AllowedFilterPropertyName.EquipmentType}
                    values={filterState[AllowedFilterPropertyName.EquipmentType]}
                    handleChange={updateFilterState}
                    url={`${MasterData}${Endpoints.masterDataApi.equipmentType}`}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Origin}
                    label='Origin'
                    filterName='OriginDisplayfreightprovider'
                    values={filterState[AllowedFilterPropertyName.Origin]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Destination}
                    label='Destination'
                    filterName='DestinationDisplayfreightprovider'
                    values={filterState[AllowedFilterPropertyName.Destination]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.OutboundLocation}
                    label='Outbound Location'
                    filterName='OutboundLocation'
                    values={filterState[AllowedFilterPropertyName.OutboundLocation]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.InboundLocation}
                    label='Inbound Location'
                    filterName='InboundLocation'
                    values={filterState[AllowedFilterPropertyName.InboundLocation]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.OriginState}
                    label='From State'
                    filterName='OriginStateCodefreightprovider'
                    values={filterState[AllowedFilterPropertyName.OriginState]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.DestinationState}
                    label='To State'
                    filterName='DestinationStateCodefreightprovider'
                    values={filterState[AllowedFilterPropertyName.DestinationState]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.OriginCountryCode}
                    label='From Country'
                    filterName='OriginCountryCode'
                    values={filterState[AllowedFilterPropertyName.OriginCountryCode]}
                    handleChange={updateFilterState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.DestinationCountryCode}
                    label='To Country'
                    filterName='DestinationCountryCode'
                    values={filterState[AllowedFilterPropertyName.DestinationCountryCode]}
                    handleChange={updateFilterState}
                />

                <MultiSelect
                    id={AllowedFilterPropertyName.DeliveryStatus}
                    label='Delivery Status'
                    values={filterState[AllowedFilterPropertyName.DeliveryStatus]}
                    availableOptions={deliveryStatusOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.ShipmentStatus}
                    label='Shipment Status'
                    values={filterState[AllowedFilterPropertyName.ShipmentStatus]}
                    availableOptions={shipmentStatusOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.CurrentMilestoneType}
                    label='LTL/Parcel Milestone Status'
                    values={filterState[AllowedFilterPropertyName.CurrentMilestoneType]}
                    availableOptions={ltlParcelMilestoneOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
                <AsyncMultiSelect
                    id={AllowedFilterPropertyName.OrderExceptions}
                    values={filterState[AllowedFilterPropertyName.OrderExceptions]}
                    handleChange={updateFilterState}
                    url={`${MasterData}${Endpoints.masterDataApi.orderExceptions}`}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.ModeType}
                    label='Mode Type'
                    values={filterState[AllowedFilterPropertyName.ModeType]}
                    availableOptions={modeTypeOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.WeatherSeverity}
                    label='Weather Severity'
                    values={filterState[AllowedFilterPropertyName.WeatherSeverity]}
                    availableOptions={weatherSeverityOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.IsPriorityShipment}
                    label='Hot Shipments'
                    values={filterState[AllowedFilterPropertyName.IsPriorityShipment]}
                    availableOptions={hotShipmentOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
            </StyledDiv>

            <StyledGrid
                container
                justifyContent='space-between'
                className={classes.actionsContainer}
            >
                <Grid item>
                    <Button size='small' onClick={handleResetClick} data-qa='resetFilters-button'>Reset</Button>
                </Grid>
                {
                    shareType !== ShareType.OperatorReadOnly && shareType !== ShareType.CustomerReadOnly &&
                    <Grid item>
                        <Button
                            size='small'
                            variant='outlined'
                            disabled={status === ApiStatus.Loading || !canSubmitForm}
                            onClick={handleSaveClick}
                            startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                            data-qa='saveFilters-button'
                        >
                            {
                                status === ApiStatus.Loading ? 'Saving' : 'Save'
                            }
                        </Button>
                    </Grid>
                }
                <Grid item>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        onClick={handleApplyClick}
                        data-qa='applyFilters-button'
                    >
                        Apply
                    </Button>
                </Grid>
            </StyledGrid>
        </Fragment>
    );
};

export default Filters;
