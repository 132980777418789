import { formatPhoneNumberIntl } from 'react-phone-number-input';

/**
 * Group an array of objects by a property within the objects, returns a new object with unique keys based on unique property values
 * @param objectArray Original array of objects
 * @param property value within the orignial object used to create keys for the new object
 */
export const groupBy = (objectArray: any[], property: string): any => {
    return objectArray.reduce(((acc, obj): any => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }), {});
};

/**
 * Uses the defaultObj to group an array of objects by a property, returns a new object defined by defaultObj keys
 * Only properties that exist in the defaultObj will be added to the returned object
 * @param objectArray Original array of objects
 * @param property value within the orignial object used to create keys for the new object
 * @param defaultObj the default object to add values
 */
export const groupByWithDefaultObject = (objectArray: any[], property: string, defaultObj: {}): any => {
    return objectArray.reduce((acc, obj): any => {
        const key = obj[property];
        if (acc[key]) {
            acc[key].push(obj);
        }
        return acc;
    }, defaultObj);
};

/**
 * Adds a space before each uppercase character in the string passed in
 * @param value Incoming string to format
 * @returns Formatted string with a space before each uppercase character
 */
export const addSpaceBeforeUppercaseCharacter = (value: string): string => {
    return value.replace(/([A-Z])/g, ' $1').trim();
};

/**
 * Capitalizes the first character in the string passed in
 * @param value Incoming string to format
 * @returns The string with the first character capitalized.
 */
export const capitalizeFirstLetter = (value: string): string => {
    return `${value.charAt(0).toUpperCase()}${value.substring(1)}`;
};

/**
 * Lowercases the first character in the string passed in
 * @param value Incoming string to format
 * @returns The string with the first character lowercased.
 */
export const lowerCaseFirstLetter = (value: string): string => {
    return `${value.charAt(0).toLowerCase()}${value.substring(1)}`;
};

/**
 * Determines the hours and minutes from the given minutes
 * @param inputMinutes The given minutes to calculate hours and minutes
 * @returns Object containing the hours and minutes
 */
export const calculateHoursAndMinutes = (inputMinutes: number): { hours: number; minutes: number; } => {
    const hours = inputMinutes / 60;
    const realHours = Math.floor(hours);
    const minutes = (hours - realHours) * 60;
    const realMinutes = Math.round(minutes);
    return { hours: realHours, minutes: realMinutes };
};

/**
 * Determines the total minutes given the hours and minutes.
 * @param inputHours The total hours
 * @param inputMinutes The total minutes
 * @returns The total minutes
 */
export const calculateMinutes = (inputHours: number, inputMinutes: number): number => {
    const hoursAsMinutes = inputHours * 60;
    // circumvent type coercion by making values positive integers when adding:
    const totalMinutes = +hoursAsMinutes + +inputMinutes;
    return totalMinutes;
};

/**
 * Validates the given value is a positive integer
 * @param value Given value to validate
 * @returns Boolean indicating whether the given value is a positve integer
 */
export const isPositiveInt = (value: number): boolean => {
    const isInt = Number.isInteger(value);
    if (isInt) {
        return value >= 0;
    }
    return false;
};

/**
 * Validate the given hours to be an integer, above 0, and less than the max allowed
 * @param inputHours Input hours to validate
 * @param maxAllowedTotalMinutes Max total minutes allowed
 * @returns Boolean indicating whether the hours given is valid.
 */
const validateHours = (inputHours: number, maxAllowedTotalMinutes: number): boolean => {
    const maxHours = maxAllowedTotalMinutes / 60;

    if (!Number.isInteger(inputHours) || inputHours < 0 || inputHours > maxHours) {
        return false;
    }
    return true;
};

/**
 * Validates the given minutes to be an integer, above 0, and less than 59
 * @param inputMinutes Input minutes to validate
 * @returns Boolean indicating whether the minutes given is valid.
 */
const validateMinutes = (inputMinutes: number): boolean => {
    if (!Number.isInteger(inputMinutes) || inputMinutes < 0 || inputMinutes > 59) {
        return false;
    }
    return true;
};

/**
 * Validates the given minutes to be an integer, above 0, and less than the max total minutes
 * @param inputTotalMinutes Input total minutes to validate
 * @param maxAllowedTotalMinutes Max total minutes allowed
 * @returns Boolean indicating whether the total minutes given is valid.
 */
export const validateHoursAndMinutes = (inputTotalMinutes: number, maxAllowedTotalMinutes: number): boolean => {
    if (!Number.isInteger(inputTotalMinutes)) {
        return false;
    }

    if (inputTotalMinutes < 0 || inputTotalMinutes > maxAllowedTotalMinutes) {
        return false;
    }

    const hoursAndMinutes = calculateHoursAndMinutes(inputTotalMinutes);
    const isHoursValid = validateHours(hoursAndMinutes.hours, maxAllowedTotalMinutes);
    const isMinutesValid = validateMinutes(hoursAndMinutes.minutes);

    return isHoursValid && isMinutesValid;
};

/**
 * Formats the given phone number in an international format or if that can't be done, returns the phone number as is.
 * @param inputPhoneNumber Raw phone number to be formatted
 */
export const formatPhoneNumber = (inputPhoneNumber: string): string => {
    const formattedPhoneNumber = formatPhoneNumberIntl(inputPhoneNumber) ? formatPhoneNumberIntl(inputPhoneNumber) : inputPhoneNumber;
    return formattedPhoneNumber;
};
