import { NotificationRecipientType, TimePriorityType } from '../../helpers/enums';
import { NotificationDetails } from '../../interfaces/services/notifications';

export const notificationDetailsInitialState: NotificationDetails = {
    notificationGuid: '',
    notificationName: '',
    notificationRecipientType: NotificationRecipientType.shipmentLevel,
    notificationTypeLabel: null,
    notifyStopContactsByEmail: false,
    notifyStopContactsBySms: false,
    notificationMetaData: null,
    notificationTypes: [],
    emailList: [],
    smsList: [],
    filters: [],
    minutes: null,
    timePriorityType: TimePriorityType.Appointment,
    lastUpdateDate: '',
    lastUpdateUserName: ''
};
