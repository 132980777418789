import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiMenu, mdiAccountCircle } from '@mdi/js';

import { useTypedSelector } from '../../../redux';
import { editPreferencesColors } from '../../../redux/organization';
import { PreferencesThemeColors, SideSheetType } from '../../../helpers/enums';
import { createApplicationTheme } from '../../../assets/theme';
import PreferenceCard from '../preferenceCard';
import ColorPicker from '../../pickers/colorPicker';
import Hidden from '../../layouts/hidden';
import SideSheetButtons from '../../actionBars/sideSheetButtons';
import HeaderSearchInput from '../../inputs/headerSearchInput';

import trimbleLogo from '../../../assets/images/trimble_logo_white.png';

const classesPrefix = 'applicationColorsCard';

const classes = {
    wrapper: `${classesPrefix}-wrapper`,
    toolbar: `${classesPrefix}-toolbar`,
    appTitle: `${classesPrefix}-appTitle`,
    appLogoWrapper: `${classesPrefix}-appLogoWrapper`,
    companyLogo: `${classesPrefix}-companyLogo`,
    headerWrapper: `${classesPrefix}-headerWrapper`,
    navigationButtonWrapper: `${classesPrefix}-navigationButtonWrapper`,
    navigationButton: `${classesPrefix}-navigationButton`
};

const StyledDiv = styled(
    'div',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'customLogoToken'; }
    }
)<{ customLogoToken: string | File | null; }>(({ customLogoToken, theme }) => {
    const navContrastText = theme.palette.getContrastText(theme.appColors.primaryNavBackground);
    return {
        [`&.${classes.wrapper}`]: {
            marginTop: '16px'
        },
        [`& .${classes.toolbar}`]: {
            color: navContrastText
        },
        [`& .${classes.appTitle}`]: {
            flexGrow: 1,
            borderLeft: `1px solid ${navContrastText}`,
            marginLeft: '16px',
            paddingLeft: '16px',
            fontSize: '16px'
        },
        [`& .${classes.companyLogo}`]: {
            ...customLogoToken && { backgroundColor: theme.palette.common.white },
            maxHeight: '40px',
            maxWidth: '325px'
        },
        [`& .${classes.headerWrapper}`]: {
            backgroundColor: theme.palette.primary.main,
            minHeight: '68px',
            padding: '0 24px'
        },
        [`& .${classes.navigationButtonWrapper}`]: {
            flexGrow: 1
        },
        [`& .${classes.navigationButton}`]: {
            marginLeft: '16px',
            color: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                border: `1px solid ${theme.palette.primary.contrastText}`,
                opacity: 0.9
            },
            '&.Mui-disabled': {
                color: theme.palette.primary.contrastText,
                borderColor: 'transparent'
            }
        },
        [theme.breakpoints.only('md')]: {
            [`& .${classes.headerWrapper}`]: {
                justifyContent: 'space-between'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`& .${classes.headerWrapper}`]: {
                padding: '16px 16px 0 16px'
            }
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.appLogoWrapper}`]: {
                flexGrow: 1
            },
            [`& .${classes.companyLogo}`]: {
                maxWidth: '100px'
            }
        }
    };
});

const ApplicationColorsCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const logoRef = useRef<HTMLImageElement>(null);

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });
    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const pageViewList = [
        { isDisabled: true, label: 'Cards', resourceKey: '1' },
        { isDisabled: false, label: 'List', resourceKey: '2' },
        { isDisabled: false, label: 'Calendar', resourceKey: '3' }
    ];

    const newTheme = useMemo(() => {
        return createApplicationTheme({
            paletteMode,
            primaryNavBackground: organizationEditablePreferences.freightPortalColorPreference.primaryNavBackground,
            primaryMain: organizationEditablePreferences.freightPortalColorPreference.primaryMain,
            secondaryMain: organizationEditablePreferences.freightPortalColorPreference.secondaryMain
        });
    }, [paletteMode, organizationEditablePreferences.freightPortalColorPreference]);

    // sets the logo source based on where it is coming from and what type (string, File, null)
    useEffect(() => {
        if (logoRef.current) {
            if (organizationEditablePreferences.customLogoToken) {
                // If customLogoToken is a string, it's a url and can be used as the image source
                if (typeof organizationEditablePreferences.customLogoToken === 'string') {
                    logoRef.current.src = organizationEditablePreferences.customLogoToken;
                } else {
                    // Otherwise, customLogoToken is a File and needs to be created as a URL before being used as the image source
                    logoRef.current.src = URL.createObjectURL(organizationEditablePreferences.customLogoToken);
                }
            } else {
                // The customLogoToken is null, so we will set the image source to the default trimble logo
                logoRef.current.src = trimbleLogo;
            }
        }
    }, [logoRef, organizationEditablePreferences.customLogoToken]);

    const handlePrimaryNavBackgroundColorChange = (newColor: string | null): void => {
        dispatch(editPreferencesColors(PreferencesThemeColors.PrimaryNavBackground, newColor));
    };

    const handlePrimaryMainColorChange = (newColor: string | null): void => {
        dispatch(editPreferencesColors(PreferencesThemeColors.PrimaryMain, newColor));
    };

    const handleSecondaryMainColorChange = (newColor: string | null): void => {
        dispatch(editPreferencesColors(PreferencesThemeColors.SecondaryMain, newColor));
    };

    return (
        <PreferenceCard header='Application Colors'>
            <Typography component='p' variant='body2' gutterBottom>
                How do you want your application to be themed?
            </Typography>

            <Grid container spacing={2}>
                <Grid item>
                    <ColorPicker
                        label='Nav Background'
                        color={organizationEditablePreferences.freightPortalColorPreference.primaryNavBackground}
                        onChange={handlePrimaryNavBackgroundColorChange}
                        dataQa='navBackground-color'
                    />
                </Grid>
                <Grid item>
                    <ColorPicker
                        label='Primary'
                        color={organizationEditablePreferences.freightPortalColorPreference.primaryMain}
                        onChange={handlePrimaryMainColorChange}
                        dataQa='primaryMain-color'
                    />
                </Grid>
                <Grid item>
                    <ColorPicker
                        label='Secondary'
                        color={organizationEditablePreferences.freightPortalColorPreference.secondaryMain}
                        onChange={handleSecondaryMainColorChange}
                        dataQa='secondaryMain-color'
                    />
                </Grid>
            </Grid>

            <ThemeProvider theme={newTheme}>
                <StyledDiv customLogoToken={organizationEditablePreferences.customLogoToken} className={classes.wrapper}>
                    <Toolbar variant='dense' className={classes.toolbar}>
                        <IconButton edge='start' color='inherit'>
                            <Icon path={mdiMenu} size={1} />
                        </IconButton>
                        <div className={classes.appLogoWrapper}>
                            <img ref={logoRef} className={classes.companyLogo} alt='company logo' />
                        </div>
                        <Hidden breakpoint='xs'>
                            <Typography variant='h6' color='inherit' className={classes.appTitle}>
                                Visibility
                            </Typography>
                        </Hidden>
                        <IconButton color='inherit'>
                            <Icon path={mdiAccountCircle} size={1} />
                        </IconButton>
                    </Toolbar>
                    <Grid container alignItems='center' className={classes.headerWrapper}>
                        <HeaderSearchInput
                            id='shipmentSearch'
                            inputLabel='Search Shipments'
                            searchTerm=''
                            handleSearchTermSubmit={(): void => { }}
                        />

                        <Hidden breakpoint='lg' direction='down'>
                            <Grid item md='auto' className={classes.navigationButtonWrapper}>
                                <Grid container>
                                    {
                                        pageViewList.map((app): JSX.Element => {
                                            return (
                                                <Grid item key={app.resourceKey}>
                                                    <Button
                                                        classes={{ root: classes.navigationButton }}
                                                        aria-label={app.label}
                                                        variant='outlined'
                                                        disabled={app.isDisabled}
                                                        color='primary'
                                                        size='medium'
                                                    >
                                                        {app.label}
                                                    </Button>
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Hidden>

                        <SideSheetButtons
                            currentSideSheet={SideSheetType.Filters}
                            handleCurrentSideSheetChange={(): void => { }}
                        />
                    </Grid>
                </StyledDiv>
            </ThemeProvider>
        </PreferenceCard>
    );
};

export default ApplicationColorsCard;
