import React, { ElementType, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Link,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';

import { ApplicationResource } from '../../interfaces/services/organization';
import { useAccessTokenUrl } from '../../hooks/useAccessTokenUrl';

const classesPrefix = 'appHeaderMenuItem';

const classes = {
    listItem: `${classesPrefix}-listItem`,
    link: `${classesPrefix}-link`,
    listItemIcon: `${classesPrefix}-listItemIcon`
};

const StyledListItemButton = styled(ListItemButton)(() => {
    return {
        [`&.${classes.listItem}`]: {
            padding: 0
        },
        [`& .${classes.link}`]: {
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'center',
            padding: '8px 24px 8px 16px'
        },
        [`& .${classes.listItemIcon}`]: {
            paddingTop: '4px'
        }
    };
});

const AppHeaderMenuItem = ({
    application,
    mdiIcon,
    linkComponent,
    handleMenuItemClick
}: {
    application: ApplicationResource;
    mdiIcon: string | null;
    linkComponent?: ElementType<any>;
    handleMenuItemClick: (id: string) => void;
}): JSX.Element => {
    const location = useLocation();
    const url = useAccessTokenUrl(application.resourcePath);

    if (application.isAllowed === false) {
        return <Fragment />;
    }

    return (
        <StyledListItemButton
            classes={{
                root: classes.listItem
            }}
            selected={location.pathname === application.resourcePath}
        >
            <Link
                id={application.resourceKey}
                className={classes.link}
                component={linkComponent || 'a'}
                to={linkComponent ? url : undefined}
                href={linkComponent ? undefined : application.resourcePath}
                underline='none'
                onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
                    if (handleMenuItemClick) {
                        // pass the id of the item clicked to the parent
                        handleMenuItemClick(event.currentTarget.id);
                    }
                }}
                data-qa={`appMenu-${application.resourceKey}-menuItem`}
            >
                {
                    mdiIcon &&
                    <ListItemIcon className={classes.listItemIcon}>
                        <Icon
                            path={mdiIcon}
                            title={application.resourceName || 'page icon'}
                            size={1}
                        />
                    </ListItemIcon>
                }
                <ListItemText primary={application.resourceName} data-qa={`accountMenu-${application.resourceKey}-description`} />
            </Link>
        </StyledListItemButton>
    );
};

export default AppHeaderMenuItem;
