import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'errorPage';

const classes = {
    errorWrapper: `${classesPrefix}-errorWrapper`,
    errorMesssage: `${classesPrefix}-errorMesssage`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.errorWrapper}`]: {
            margin: '16px',
            textAlign: 'center'
        },
        [`& .${classes.errorMesssage}`]: {
            paddingTop: '16px',
            paddingBottom: '16px'
        }
    };
});

const ErrorPage = ({
    errorHeaderText,
    children
}: {
    /** Header Text to show on the error page. */
    errorHeaderText: string;
    /** The child components to show on the error page. */
    children: React.ReactNode;
}): JSX.Element => {
    return (
        <StyledDiv className={classes.errorWrapper} data-qa='error-container'>
            <Typography variant='h2' component='h2' data-qa='error-header'>{errorHeaderText}</Typography>
            <div className={classes.errorMesssage} data-qa='errorMessage-container'>
                {children}
            </div>
        </StyledDiv>
    );
};

export default ErrorPage;
