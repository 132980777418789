import React from 'react';
import { Typography, Button } from '@mui/material';
import { useTypedSelector } from '../../redux';
import AuthService from '../../services/authService';
import ErrorPage from './errorPage';

const LoginError = (): JSX.Element => {
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    if (shareType) {
        return (
            <ErrorPage errorHeaderText='Invalid Access Token'>
                <Typography data-qa='accessTokenError'>
                    Please check your link and try again.
                </Typography>
            </ErrorPage>
        );
    }

    return (
        <ErrorPage errorHeaderText='Login Error'>
            <Typography data-qa='loginError'>
                A failure occurred while attemping to login. Please try again.
            </Typography>
            <Button
                variant='outlined'
                color='primary'
                onClick={(): void => {
                    AuthService.logout();
                }}
                data-qa='error-action-logout'
            >
                Log Out
            </Button>
        </ErrorPage>
    );
};

export default LoginError;
