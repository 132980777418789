import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import { DeliveryStatus } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';

interface StyleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    deliveryStatus: DeliveryStatus;
}

const classesPrefix = 'deliveryStatusIndicator';

const classes = {
    rectangle: `${classesPrefix}-rectangle`,
    deliveryStatusText: `${classesPrefix}-deliveryStatusText`
};

const StyledDiv = styled(
    'div',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'deliveryStatus'; }
    }
)<StyleProps>(({ deliveryStatus, theme }) => {
    return {
        [`&.${classes.rectangle}`]: {
            display: 'inline-block',
            minWidth: '65px',
            height: '30px',
            backgroundColor: getDeliveryStatusColor(deliveryStatus),
            color: theme.palette.common.white,
            padding: '0 16px'
        },
        [`& .${classes.deliveryStatusText}`]: {
            fontSize: '16px',
            fontWeight: 600,
            textAlign: 'center',
            paddingTop: '3px',
            textTransform: 'uppercase'
        }
    };
});

const DeliveryStatusIndicator = ({
    deliveryStatus
}: {
    deliveryStatus: DeliveryStatus;
}): JSX.Element => {
    return (
        <StyledDiv className={classes.rectangle} deliveryStatus={deliveryStatus}>
            <Typography className={classes.deliveryStatusText} data-qa='deliveryStatus'>{addSpaceBeforeUppercaseCharacter(deliveryStatus)}</Typography>
        </StyledDiv>
    );
};

export default DeliveryStatusIndicator;
