import React from 'react';
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'progressHistoryTableLoading';

const classes = {
    caption: `${classesPrefix}-caption`,
    tableHeader: `${classesPrefix}-tableHeader`
};

const StyledTableContainer = styled(TableContainer)(() => {
    return {
        [`& .${classes.caption}`]: {
            fontWeight: 600
        },
        [`& .${classes.tableHeader}`]: {
            fontWeight: 600
        }
    };
});

const ProgressHistoryTableLoading = ({
    numberOfRows
}: {
    /** The number of loading rows to show in the table */
    numberOfRows: number;
}): JSX.Element => {
    return (
        <StyledTableContainer>
            <Table size='small' aria-label='Progress History' data-qa='progressHistory-table'>
                <caption>
                    <Typography className={classes.caption} variant='h6' data-qa='progressHistory-header'>Progress History</Typography>
                </caption>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='milestone-column-header'>Milestone</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='location-column-header'>Location</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='time-column-header'>Time</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='detail-column-header'>Detail</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-qa='progressHistory-content'>
                    {
                        Array.from(new Array(numberOfRows)).map((item, index): JSX.Element => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableRow key={index}>
                                    <TableCell><Skeleton variant='text' width='60px' /></TableCell>
                                    <TableCell><Skeleton variant='text' width='160px' /></TableCell>
                                    <TableCell><Skeleton variant='text' width='120px' /></TableCell>
                                    <TableCell><Skeleton variant='text' width='160px' /></TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default ProgressHistoryTableLoading;
