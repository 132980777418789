import { isVisibleUtil } from '../mapUtils';
import { MapLayers, MapLayerTypes } from '../enums';
import { MapLayer } from './interfaces';

const trafficZoom = { min: 8, max: 18 };

/**
 * Retrieves the traffic source for Europe from Trimble Maps.
 * @param apiKey Trimble Maps API Key.
 * @param isVisible Indicator for if the layer should be visible.
 */
const getTrafficEuropeLayer = (apiKey: string, isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const europeTrafficSource = {
        type: 'raster',
        tiles: [`https://pcmiler.alk.com/APIs/REST/v1.0/service.svc/trafficTile?REGION=EU&x={x}&y={y}&z={z}&AUTHTOKEN=${apiKey}&WIDTH=256&HEIGHT=256&STROKEWIDTH=4&FRCS=7`],
        tileSize: 256,
        bounds: [-32, -48, 179, 72],
        minzoom: trafficZoom.min,
        maxzoom: trafficZoom.max
    };

    const europeTrafficLayer: MapLayer = {
        id: MapLayers.europeTraffic,
        type: MapLayerTypes.Raster,
        source: europeTrafficSource,
        layout: {
            visibility
        },
        minzoom: trafficZoom.min,
        maxzoom: trafficZoom.max
    };

    return europeTrafficLayer;
};

/**
 * Retrieves the traffic source for North America from Trimble Maps.
 * @param apiKey Trimble Maps API Key.
 * @param isVisible Indicator for if the layer should be visible.
 */
const getTrafficNorthAmericaLayer = (apiKey: string, isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const northAmericaTrafficSource = {
        type: 'raster',
        tiles: [`https://contentsvc-trafficimg.alk.com/ContentService.svc/xml/traffic/tile?x={x}&y={y}&z={z}&AUTHTOKEN=${apiKey}&PENWIDTH=4&FEDERALROADCLASSES=7&region=NA`],
        tileSize: 256,
        bounds: [-161, 19, -28, 72],
        minzoom: trafficZoom.min,
        maxzoom: trafficZoom.max
    };

    const northAmericaTrafficLayer: MapLayer = {
        id: MapLayers.northAmericaTraffic,
        type: MapLayerTypes.Raster,
        source: northAmericaTrafficSource,
        layout: {
            visibility
        },
        minzoom: trafficZoom.min,
        maxzoom: trafficZoom.max
    };

    return northAmericaTrafficLayer;
};

/**
 * Adds the Europe and North America traffic layers to the map if they don't already exist
 * and will automatically show the layer based on the isVisible boolean.
 * @param map Instance of your map
 * @param apiKey Trimble Maps API key
 * @param isTrafficActive Indicator whether the traffic layers are visibile
 */
export const addTrafficLayers = (map: any, apiKey: string, isTrafficActive: boolean): void => {
    if (map.getLayer(MapLayers.europeTraffic) === undefined) {
        const trafficEuropeLayer = getTrafficEuropeLayer(apiKey, isTrafficActive);
        map.addLayer(trafficEuropeLayer);
    }

    if (map.getLayer(MapLayers.northAmericaTraffic) === undefined) {
        const trafficNorthAmericaLayer = getTrafficNorthAmericaLayer(apiKey, isTrafficActive);
        map.addLayer(trafficNorthAmericaLayer);
    }
};
