import React, { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ShipmentData } from '../../interfaces/services/shipmentDetails';
import { StopType } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import { isShipmentLtl } from '../../helpers/shipmentUtils';
import Hidden from '../layouts/hidden';
import LabelValuePair from '../labels/labelValuePair';
import ShipmentDetailsLabelLink from '../links/shipmentDetailsLabelLink';
import StopAddress from '../labels/stopAddress';
import StatusActionBar from '../actionBars/statusActionBar';
import DeliveryStatusIndicator from '../labels/deliveryStatusIndicator';
import StopDateLabel from '../labels/stopDateLabel';
import DeliveryStatusTooltip from '../tooltips/deliveryStatusTooltip';

const classesPrefix = 'shipmentDetailsCard';

const classes = {
    statusLabel: `${classesPrefix}-statusLabel`,
    addressContainer: `${classesPrefix}-addressContainer`,
    stopTitle: `${classesPrefix}-stopTitle`,
    deliveryStatusContainer: `${classesPrefix}-deliveryStatusContainer`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.statusLabel}`]: {
            margin: '8px 0',
            fontSize: '16px',
            fontWeight: 600
        },
        [`& .${classes.addressContainer}`]: {
            marginBottom: '4px'
        },
        [`& .${classes.stopTitle}`]: {
            fontWeight: 600,
            fontSize: '14px',
            paddingBottom: '4px'
        },
        [`& .${classes.deliveryStatusContainer}`]: {
            flexGrow: 1
        }
    };
});

const ShipmentDetailsCard = ({
    shipment,
    shipmentCardIcon,
    showShipmentDetailsNavigation = false,
    actionContainer,
    handleWeatherSeverityClick,
    handleTemperatureExceededBoundsClick,
    handleShipmentNotesClick,
    handleShipmentDocumentsClick,
    handleShipmentPriorityClick,
    handleShipmentExceptionsClick
}: {
    /** The shipment information available for display. */
    shipment: ShipmentData;
    /** Optionally shows an icon in upper left of card */
    shipmentCardIcon?: React.ReactNode;
    /** Optionally used to navigate to the shipment details page. */
    showShipmentDetailsNavigation?: boolean;
    /** Optional container for actions in that will display in the upper right corner */
    actionContainer?: React.ReactNode;
    /** Handle the click of the temperature bounds breach icon */
    handleWeatherSeverityClick: () => void;
    /** Handle the click of the temperature bounds breach icon */
    handleTemperatureExceededBoundsClick: () => void;
    /** Handle the click of the shipment notes icon */
    handleShipmentNotesClick: () => void;
    /** Handle the click of the shipment documents icon */
    handleShipmentDocumentsClick: () => void;
    /** Handle the click of the shipment priority icon */
    handleShipmentPriorityClick: () => void;
    /** Handle the click of the shipment exceptions icon */
    handleShipmentExceptionsClick: () => void;
}): JSX.Element => {
    const originStop = shipment.stops.find((stop): boolean => {
        return stop.stopType === StopType.Origin;
    });
    const destinationStop = shipment.stops.find((stop): boolean => {
        return stop.stopType === StopType.Destination;
    });

    return (
        <StyledGrid container data-qa='shipmentDetailsCard-container'>
            <Grid item xs={12}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid container alignItems='center'>
                            {
                                shipmentCardIcon &&
                                <Grid item xs={1} sm='auto' data-qa='shipmentCardIcon-container'>
                                    {shipmentCardIcon}
                                </Grid>
                            }
                            <Grid item xs={shipmentCardIcon ? 11 : 12} sm='auto'>
                                <Typography className={classes.statusLabel} data-qa='shipmentStatus'>
                                    {
                                        addSpaceBeforeUppercaseCharacter(
                                            isShipmentLtl(shipment.modeType)
                                                ? shipment.currentMilestoneProgress.furthest
                                                : shipment.shipmentStatus
                                        )
                                    }
                                </Typography>
                            </Grid>
                            {
                                showShipmentDetailsNavigation &&
                                <Grid item xs={12} sm='auto'>
                                    <ShipmentDetailsLabelLink
                                        label=''
                                        shipmentUniqueName={shipment.shipmentUniqueName}
                                        freightProviderReferenceNumber={shipment.freightProviderReferenceNumber}
                                        modeType={shipment.modeType}
                                        fontSize='16px'
                                        alwaysOpenInNewTab
                                    />
                                </Grid>
                            }
                            <StatusActionBar
                                isPriorityShipment={shipment.isPriorityShipment}
                                handleShipmentPriorityClick={(): void => {
                                    handleShipmentPriorityClick();
                                }}
                                weatherSeverity={shipment.weatherSeverity}
                                handleWeatherSeverityClick={(): void => {
                                    handleWeatherSeverityClick();
                                }}
                                totalShipmentExceptions={shipment.totalShipmentAndOrderExceptions}
                                handleShipmentExceptionsClick={(): void => {
                                    handleShipmentExceptionsClick();
                                }}
                                hasTemperatureExceededBounds={shipment.hasTemperatureExceededBoundsFlag}
                                handleTemperatureExceededBoundsClick={(): void => {
                                    handleTemperatureExceededBoundsClick();
                                }}
                                hasShipmentNotes={shipment.hasShipmentNotes}
                                handleShipmentNotesClick={(): void => {
                                    handleShipmentNotesClick();
                                }}
                                hasShipmentDocuments={shipment.hasShipmentDocuments}
                                handleShipmentDocumentsClick={(): void => {
                                    handleShipmentDocumentsClick();
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.deliveryStatusContainer}>
                        <DeliveryStatusIndicator
                            deliveryStatus={shipment.deliveryStatus}
                        />
                        {
                            shipment.currentPosition?.positionEventDateTime &&
                            <DeliveryStatusTooltip
                                timestamp={shipment.currentPosition.positionEventDateTime}
                                currentCity={shipment.currentPosition.cityName}
                                currentState={shipment.currentPosition.stateCode}
                            />
                        }
                    </Grid>
                    {
                        actionContainer &&
                        <Grid item>
                            {actionContainer}
                        </Grid>
                    }
                </Grid>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <LabelValuePair label='Mode' value={shipment.modeType} fontSize='16px' data-qa='mode' />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <LabelValuePair label='Carrier' value={shipment.freightHauler.name} fontSize='16px' data-qa='carrier' />
                    </Grid>
                </Grid>

                <Grid container className={classes.addressContainer} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.stopTitle} data-qa='origin-label'>Origin</Typography>
                        <StopAddress
                            description={originStop?.description || ''}
                            location={originStop?.fixedLocationCode || ''}
                            name={originStop?.name || ''}
                            street1={originStop?.address.streetAddress1 || ''}
                            street2={originStop?.address.streetAddress2 || ''}
                            city={originStop?.address.city || ''}
                            region={originStop?.address.state || ''}
                            postal={originStop?.address.postalCode || ''}
                            country={originStop?.address.countryCode || ''}
                            modeType={shipment.modeType}
                        />
                        <Hidden breakpoint='md' direction='up'>
                            <StopDateLabel stop={originStop} />
                        </Hidden>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {
                            destinationStop &&
                            <Fragment>
                                <Typography className={classes.stopTitle} data-qa='destination-label'>Destination</Typography>
                                <StopAddress
                                    description={destinationStop?.description || ''}
                                    location={destinationStop?.fixedLocationCode || ''}
                                    name={destinationStop?.name || ''}
                                    street1={destinationStop?.address.streetAddress1 || ''}
                                    street2={destinationStop?.address.streetAddress2 || ''}
                                    city={destinationStop?.address.city || ''}
                                    region={destinationStop?.address.state || ''}
                                    postal={destinationStop?.address.postalCode || ''}
                                    country={destinationStop?.address.countryCode || ''}
                                    modeType={shipment.modeType}
                                />
                                <Hidden breakpoint='md' direction='up'>
                                    <StopDateLabel stop={destinationStop} />
                                </Hidden>
                            </Fragment>
                        }
                    </Grid>
                </Grid>
                <Hidden breakpoint='md' direction='down'>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <StopDateLabel stop={originStop} />
                        </Grid>
                        <Grid item xs={6}>
                            <StopDateLabel stop={destinationStop} />
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </StyledGrid>
    );
};

export default ShipmentDetailsCard;
