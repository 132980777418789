import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';

const classesPrefix = 'commonDialog';

const classes = {
    dialogTitle: `${classesPrefix}-dialogTitle`,
    svgIcon: `${classesPrefix}-svgIcon`
};

const StyledDialog = styled(Dialog)(({ theme }) => {
    return {
        [`& .${classes.dialogTitle}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        [`& .${classes.svgIcon}`]: {
            verticalAlign: 'middle'
        }
    };
});

const CommonDialog = ({
    headerText,
    open,
    onClose,
    fullWidth,
    maxWidth,
    headerIcon,
    content,
    actions
}: {
    /** Optional text to show in the dialog header. */
    headerText?: string;
    /** Indicator for if the dialog is open. */
    open: boolean;
    /** Function to handle the close of the dialog. */
    onClose: () => void;
    /**
     * Indicator for the dialog to stretch to the maxWidth.
     * @default false
     */
    fullWidth?: boolean;
    /**
     * Determine the max-width of the dialog. Set to false to disable maxWidth.
     * @default sm
     */
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    /** Optional mdiIcon for the header. */
    headerIcon?: string;
    /** The elements to show in the Dialog Content */
    content?: React.ReactNode;
    /** The elements to show in the Dialog Actions  */
    actions?: React.ReactNode;
}): JSX.Element => {
    const theme = useTheme();

    return (
        <StyledDialog
            open={open}
            onClose={(): void => { onClose(); }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            aria-labelledby={headerText}
            data-qa='dialog-container'
        >
            {
                headerText &&
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item xs='auto'>
                            {
                                headerIcon &&
                                <Icon
                                    className={classes.svgIcon}
                                    path={headerIcon}
                                    size={1}
                                    color={theme.palette.secondary.main}
                                />
                            }
                        </Grid>
                        <Grid item xs='auto' data-qa='dialog-header'>
                            {headerText}
                        </Grid>
                    </Grid>
                </DialogTitle>
            }
            {
                content &&
                <DialogContent dividers data-qa='dialog-content'>
                    {content}
                </DialogContent>
            }
            {
                actions &&
                <DialogActions>
                    {actions}
                </DialogActions>
            }
        </StyledDialog>
    );
};

export default CommonDialog;
