import { DeliveryStatus } from '../enums';

export const trackingStatuses = [
    DeliveryStatus.Early,
    DeliveryStatus.OnTime,
    DeliveryStatus.InJeopardy,
    DeliveryStatus.Late
];

export const notTrackingStatuses = [
    DeliveryStatus.TrackingLost,
    DeliveryStatus.TenderAccepted
];

export enum MapSources {
    // Custom added sources
    shipmentListSource = 'shipment-list-source',
    shipmentListClusterSource = 'shipment-list-cluster-source',

    orderListSource = 'order-list-source',
    orderListClusterSource = 'order-list-cluster-source',

    shipmentDetailsSource = 'shipment-details-source',
    shipmentDetailsWeatherSource = 'shipment-details-weather-source',

    tripDetailsSource = 'trip-details-source',

    orderDetailsSource = 'order-details-source',

    locationGeofenceSource = 'location-geofence-source'
}

/** An enum containing the list of additional map layers used. */
export enum MapLayers {
    // Trimble Maps built-in layers
    europeTraffic = 'europe_traffic',
    northAmericaTraffic = 'north_america_traffic',
    trafficIncidents = 'trafficincidents',

    // Custom added layers
    shipmentListLayer = 'shipment-list-layer',
    shipmentListClusterLayer = 'shipment-list-cluster-layer',

    orderListLayer = 'order-list-layer',
    orderListClusterLayer = 'order-list-cluster-layer',

    shipmentDetailsStopsLayer = 'shipment-details-stops-layer',
    shipmentDetailsGeofenceFillLayer = 'shipment-details-geofence-fill-layer',
    shipmentDetailsGeofenceOutlineLayer = 'shipment-details-geofence-outline-layer',
    shipmentDetailsRouteLayer = 'shipment-details-route-layer',
    shipmentDetailsCurrentPositionLayer = 'shipment-details-current-position-layer',
    shipmentDetailsCurrentPositionPulseLayer = 'shipment-details-current-position-pulse-layer',
    shipmentDetailsPastPositionsLayer = 'shipment-details-past-positions-layer',
    shipmentDetailsWeatherLayer = 'shipment-details-weather-layer',
    shipmentDetailsWeatherSelectedLayer = 'shipment-details-weather-selected-layer',

    orderDetailsStopsLayer = 'order-details-stops-layer',
    orderDetailsGeofenceFillLayer = 'order-details-geofence-fill-layer',
    orderDetailsGeofenceOutlineLayer = 'order-details-geofence-outline-layer',
    orderDetailsCurrentPositionLayer = 'order-details-current-position-layer',
    orderDetailsCurrentPositionPulseLayer = 'order-details-current-position-pulse-layer',

    locationPointLayer = 'location-point-layer',
    locationGeofenceFillLayer = 'location-geofence-fill-layer',
    locationGeofenceOutlineLayer = 'location-geofence-outline-layer',
}

/** Contains list of allowed types for a map layer. */
export enum MapLayerTypes {
    Raster = 'raster',
    Circle = 'circle',
    Symbol = 'symbol',
    Line = 'line',
    Fill = 'fill'
}

export enum MapImages {
    originDestinationMapMarker = 'originDestinationMapMarker',
    stopMapMarker = 'stopMapMarker',
    locationMapMarker = 'locationMapMarker',
    currentLocationMapMarker = 'currentLocationMapMarker'
}

export enum ShipmentDetailsFeatureTypes {
    ShipmentStop = 'stop',
    Geofence = 'geofence',
    PastPosition = 'pastPosition',
    CurrentPosition = 'currentPosition',
    RouteLine = 'routeLine'
}

export enum OrderDetailsFeatureTypes {
    OrderStop = 'stop',
    Geofence = 'geofence',
    CurrentPosition = 'currentPosition'
}

export enum LocationGeofenceFeatureTypes {
    Location = 'location',
    Geofence = 'geofence'
}
