import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    InputLabel,
    FormControl,
    Link,
    MenuItem,
    Select,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Typography,
    TextField,
    RadioGroup,
    Radio
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
    notificationDeliveryStatusChangeOptions,
    notificationEtaChangeOptions,
    notificationShipmentStatusChangeOptions,
    notificationTypeLabelOptions
} from '../../helpers/hardcodedOptionLists';
import { NotificationRecipientType, NotificationTypeLabel, NotificationTypes, ShareType, TimePriorityType } from '../../helpers/enums';
import { NotificationDetails } from '../../interfaces/services/notifications';
import { privateRoutes } from '../../routes/appRouteList';
import { useTypedSelector } from '../../redux';

const classesPrefix = 'notificationTypeOptions';

const classes = {
    notificationTypeSelect: `${classesPrefix}-notificationTypeSelect`,
    etaChangedOptions: `${classesPrefix}-etaChangedOptions`,
    etaMenuItem: `${classesPrefix}-etaMenuItem`,
    checkboxList: `${classesPrefix}-checkboxList`,
    helperText: `${classesPrefix}-helperText`,
    list: `${classesPrefix}-list`,
    optionsText: `${classesPrefix}-optionsText`,
    minuteInput: `${classesPrefix}-minuteInput`,
    link: `${classesPrefix}-link`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.notificationTypeSelect}`]: {
            maxWidth: '300px'
        },
        [`& .${classes.etaChangedOptions}`]: {
            maxWidth: '400px',
            marginTop: '16px'
        },
        [`& .${classes.checkboxList}`]: {
            marginLeft: '8px'
        },
        [`& .${classes.helperText}`]: {
            margin: '24px 24px 4px 0'
        },
        [`& .${classes.list}`]: {
            paddingInlineStart: '16px'
        },
        [`& .${classes.optionsText}`]: {
            display: 'list-item',
            listStyle: 'disc',
            margin: '16px 0 0 16px'
        },
        [`& .${classes.minuteInput}`]: {
            maxWidth: '120px',
            marginTop: '16px'
        },
        [`& .${classes.link}`]: {
            paddingLeft: '4px'
        }
    };
});

const StyledMenuItem = styled(MenuItem)(() => {
    return {
        [`&.${classes.etaMenuItem}`]: {
            whiteSpace: 'break-spaces'
        }
    };
});

enum MinutesSign {
    positive = 'positive',
    negative = 'negative'
}

const NotificationTypeOptions = ({
    draftNotificationDetails,
    handleUpdateNotificationTypeLabel,
    handleToggleNotificationType,
    handleClearNotificationTypes,
    handleUpdateEtaMinutesAway,
    handleUpdateMinutesFromTime,
    handleUpdateTimePriorityType,
    isFormDirty
}: {
    draftNotificationDetails: NotificationDetails;
    handleUpdateNotificationTypeLabel: (notificationTypeLabel: NotificationTypeLabel) => void;
    handleToggleNotificationType: (notification: NotificationTypes) => void;
    handleClearNotificationTypes: () => void;
    handleUpdateEtaMinutesAway: (minutes: number) => void;
    handleUpdateMinutesFromTime: (minutesFrom: number) => void;
    handleUpdateTimePriorityType: (priorityType: TimePriorityType) => void;
    isFormDirty: boolean;
}): JSX.Element => {
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const handleNotificationTypeLabelChange = (typeLabel: NotificationTypeLabel): void => {
        handleUpdateNotificationTypeLabel(typeLabel);

        // Set the notificationsType for these labels, since they don't have required user inputs
        switch (typeLabel) {
            case NotificationTypeLabel.orderExceptionAdded: {
                handleToggleNotificationType(NotificationTypes.OrderException);
                break;
            }
            case NotificationTypeLabel.temperatureOutOfRange: {
                handleToggleNotificationType(NotificationTypes.TemperatureOutOfRange);
                break;
            }
            case NotificationTypeLabel.dwellTime: {
                handleToggleNotificationType(NotificationTypes.ExtendedDwell);
                break;
            }
            default: // Do nothing;
                break;
        }
    };

    const hasNotificationTypesError = draftNotificationDetails.notificationTypes.length === 0 &&
        (draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.deliveryStatusChange ||
            draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.shipmentStatusChange);

    const renderChangeOptionsCheckbox = (option: {
        value: NotificationTypes;
        label: string;
    }): JSX.Element => {
        return (
            <FormControlLabel
                label={option.label}
                key={option.value}
                disabled={shareType === ShareType.OperatorReadOnly}
                control={
                    <Checkbox
                        name={`notificationTypeOptions-${option.value}-checkbox`}
                        value={option.value}
                        color='primary'
                        required={hasNotificationTypesError}
                        checked={draftNotificationDetails.notificationTypes.includes(option.value)}
                        onChange={(event): void => {
                            handleToggleNotificationType(event.target.value as NotificationTypes);
                        }}
                        data-qa={`notificationTypeOptions-${option.value}-checkbox`}
                    />
                }
            />
        );
    };

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <FormControl
                    fullWidth
                    variant='outlined'
                    className={classes.notificationTypeSelect}
                    disabled={shareType === ShareType.OperatorReadOnly}
                >
                    <InputLabel id='notification-type-options-select-label'>Notification Type</InputLabel>
                    <Select
                        labelId='notification-type-options-select-label'
                        id='notification-type-options-select'
                        fullWidth
                        value={draftNotificationDetails.notificationTypeLabel ?? ''}
                        required={draftNotificationDetails.notificationTypeLabel === null}
                        onChange={(event): void => { handleNotificationTypeLabelChange(event.target.value as NotificationTypeLabel); }}
                        label='Notification Type'
                        data-qa='notificationTypeOptions-select'
                    >
                        {
                            notificationTypeLabelOptions
                                .filter((option) => {
                                    return draftNotificationDetails.notificationRecipientType === NotificationRecipientType.stopLevel
                                        ? option.displayOnStopLevel
                                        : option;
                                })
                                .map((filteredOption) => {
                                    return (
                                        <MenuItem key={filteredOption.value} value={filteredOption.value}>
                                            {filteredOption.label}
                                        </MenuItem>
                                    );
                                })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {
                    // SHIPMENT STATUS CHANGE OPTIONS
                    draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.shipmentStatusChange &&
                    <FormControl
                        component='fieldset'
                        className={classes.checkboxList}
                        disabled={shareType === ShareType.OperatorReadOnly}
                    >
                        <FormGroup>
                            {
                                notificationShipmentStatusChangeOptions
                                    .filter((option) => {
                                        return draftNotificationDetails.notificationRecipientType === NotificationRecipientType.stopLevel
                                            ? option.displayOnStopLevel
                                            : option;
                                    })
                                    .map((filteredOption) => {
                                        return renderChangeOptionsCheckbox(filteredOption);
                                    })
                            }
                        </FormGroup>
                    </FormControl>
                }
                {
                    // DELIVERY STATUS CHANGE OPTIONS
                    draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.deliveryStatusChange &&
                    <FormControl
                        component='fieldset'
                        className={classes.checkboxList}
                        disabled={shareType === ShareType.OperatorReadOnly}
                    >
                        <FormGroup>
                            {
                                notificationDeliveryStatusChangeOptions.map((option) => {
                                    return renderChangeOptionsCheckbox(option);
                                })
                            }
                        </FormGroup>
                    </FormControl>
                }
                {
                    // ETA CHANGE OPTIONS
                    draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.etaChanged &&
                    <FormControl
                        fullWidth
                        variant='outlined'
                        className={classes.etaChangedOptions}
                        disabled={shareType === ShareType.OperatorReadOnly}
                    >
                        <InputLabel id='notification-type-options-eta-select-label'>When would you like to be notified?</InputLabel>
                        <Select
                            labelId='notification-type-options-eta-select-label'
                            id='notification-type-options-eta-select'
                            value={draftNotificationDetails.notificationTypes[0] || ''}
                            required={!draftNotificationDetails.notificationTypes[0]}
                            onChange={(event): void => {
                                handleClearNotificationTypes();
                                handleToggleNotificationType(event.target.value as NotificationTypes);
                            }}
                            label='When would you like to be notified?'
                            data-qa='notificationTypeOptions-eta-select'
                        >
                            {
                                notificationEtaChangeOptions.map((option) => {
                                    return <StyledMenuItem className={classes.etaMenuItem} key={option.value} value={option.value}>{option.label}</StyledMenuItem>;
                                })
                            }
                        </Select>
                        {
                            // ETA SET NUMBER OF MINUTES AWAY
                            draftNotificationDetails.notificationTypes[0] === NotificationTypes.EtaIsSetNumberOfMinutesAway &&
                            <TextField
                                id='eta-minutes-away-input'
                                label='Minutes'
                                type='number'
                                variant='outlined'
                                className={classes.minuteInput}
                                disabled={shareType === ShareType.OperatorReadOnly}
                                value={draftNotificationDetails.notificationMetaData || ''}
                                error={!draftNotificationDetails.notificationMetaData}
                                required={!draftNotificationDetails.notificationMetaData}
                                onChange={(event): void => { handleUpdateEtaMinutesAway(parseInt(event.target.value, 10)); }}
                                inputProps={{ min: 1, max: 1440 }}
                                fullWidth
                                data-qa='notificationsTypeOptions-eta-minutes-away-input'
                            />
                        }
                        {
                            // ETA BEFORE OR AFTER SHIPMENT TIME
                            // 'Before' or 'After' notification timing is determined by a positive or negative `minutes` value
                            draftNotificationDetails.notificationTypes[0] === NotificationTypes.EtaIsSetNumberOfMinutesFromTime &&
                            <Fragment>
                                <TextField
                                    id='eta-minutes-from-input'
                                    label='Minutes'
                                    type='number'
                                    variant='outlined'
                                    className={classes.minuteInput}
                                    disabled={shareType === ShareType.OperatorReadOnly}
                                    value={draftNotificationDetails.minutes ? Math.abs(draftNotificationDetails.minutes) : ''}
                                    error={!draftNotificationDetails.minutes}
                                    required={!draftNotificationDetails.minutes}
                                    onChange={(event): void => {
                                        // Keep positive or negative `minutes` value on change
                                        const minuteInput = parseInt(event.target.value, 10);
                                        const newMinutesFrom = Math.sign(draftNotificationDetails.minutes || 1) < 0
                                            ? -Math.abs(minuteInput)
                                            : Math.abs(minuteInput);
                                        handleUpdateMinutesFromTime(newMinutesFrom);
                                    }}
                                    inputProps={{ min: 1, max: 1440 }}
                                    fullWidth
                                    data-qa='notificationsTypeOptions-eta-minutes-from-input'
                                />

                                <Grid container direction='row' spacing={4}>
                                    <Grid item>
                                        <FormHelperText className={classes.helperText}>Timing</FormHelperText>
                                        <FormControl
                                            component='fieldset'
                                            disabled={shareType === ShareType.OperatorReadOnly}
                                        >
                                            <RadioGroup
                                                aria-label='etaShipmentTiming'
                                                name='etaShipmentTiming'
                                                value={
                                                    // Determine if `minutes` value is positive or negative
                                                    Math.sign(draftNotificationDetails.minutes || 1) < 0
                                                        ? MinutesSign.negative
                                                        : MinutesSign.positive
                                                }
                                                onChange={(event): void => {
                                                    const newMinutesFrom = event.target.value === MinutesSign.negative
                                                        ? -Math.abs(draftNotificationDetails.minutes || 0)
                                                        : Math.abs(draftNotificationDetails.minutes || 0);
                                                    handleUpdateMinutesFromTime(newMinutesFrom);
                                                }}
                                            >
                                                <FormControlLabel label='Before' value={MinutesSign.negative} control={<Radio color='primary' />} data-qa='notificationTypeOptions-before' />
                                                <FormControlLabel label='After' value={MinutesSign.positive} control={<Radio color='primary' />} data-qa='notificationTypeOptions-after' />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item>
                                        <FormHelperText className={classes.helperText}>Preferred Shipment Times</FormHelperText>
                                        <FormControl
                                            component='fieldset'
                                            disabled={shareType === ShareType.OperatorReadOnly}
                                        >
                                            <RadioGroup
                                                aria-label='etaShipmentPreferredTimes'
                                                name='etaShipmentPreferredTimes'
                                                value={draftNotificationDetails.timePriorityType}
                                                onChange={(event): void => { handleUpdateTimePriorityType(event.target.value as TimePriorityType); }}
                                            >
                                                <FormControlLabel label='Appointment' value={TimePriorityType.Appointment} control={<Radio color='primary' />} data-qa='notificationTypeOptions-preferred-appointment' />
                                                <FormControlLabel label='Scheduled' value={TimePriorityType.Scheduled} control={<Radio color='primary' />} data-qa='notificationTypeOptions-preferred-scheduled' />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        }
                    </FormControl>
                }
                {
                    // ORDER EXCEPTION ADDED
                    draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.orderExceptionAdded &&
                    <Typography className={classes.optionsText} variant='body2'>A notification will be sent when Order Exceptions are added.</Typography>
                }
                {
                    // TEMPERATURE OUT OF RANGE
                    draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.temperatureOutOfRange &&
                    <Typography className={classes.optionsText} variant='body2'>
                        A notification will be sent when the reefer temperature is outside of your
                        <Link component={RouterLink} to={privateRoutes.preferences} className={classes.link}>Preferences</Link>
                        .
                    </Typography>
                }
                {
                    // DWELL TIME ALERT
                    draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.dwellTime &&
                    <ul className={classes.list}>
                        <li>
                            <Typography variant='body2'>
                                Notify me when the Dwell Time on my shipment Stop is equal or greater than the Anticipated Dwell Time on my Stop.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body2'>
                                Location dwell threshold is considered when triggering these notifications.
                            </Typography>
                        </li>
                    </ul>
                }
                {
                    hasNotificationTypesError && isFormDirty &&
                    <FormHelperText error> Please pick at least one notification type </FormHelperText>
                }
            </Grid>
        </StyledGrid>
    );
};

export default NotificationTypeOptions;
