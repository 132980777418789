import React from 'react';
import { CircularProgress, IconButton, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiExport } from '@mdi/js';

import { useTypedSelector } from '../../redux';

const classesPrefix = 'exportButton';

const classes = {
    loadingIcon: `${classesPrefix}-loadingIcon`,
    exportingIcon: `${classesPrefix}-exportingIcon`
};

const StyledSkeleton = styled(Skeleton)(() => {
    return {
        [`&.${classes.loadingIcon}`]: {
            verticalAlign: 'middle',
            margin: '12px'
        }
    };
});

const StyledCircularProgress = styled(CircularProgress)(() => {
    return {
        [`&.${classes.exportingIcon}`]: {
            verticalAlign: 'middle',
            margin: '12px'
        }
    };
});

const ExportButton = ({
    isFetchingData,
    handleExportClick
}: {
    isFetchingData: boolean;
    handleExportClick: () => void;
}): JSX.Element => {
    const isExporting = useTypedSelector((state) => { return state.shipments.isExporting; });

    if (isFetchingData) {
        return <StyledSkeleton className={classes.loadingIcon} variant='circular' width='24px' height='24px' />;
    }
    if (isExporting) {
        return <StyledCircularProgress className={classes.exportingIcon} color='inherit' size={24} />;
    }
    return (
        <IconButton
            onClick={handleExportClick}
            data-qa='export-button'
        >
            <Icon path={mdiExport} size={1} />
        </IconButton>
    );
};

export default ExportButton;
