import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, LocationsSortColumns, SortDirection } from '../../helpers/enums';
import ApiService from '../../services/apiService';
// import Endpoints from '../../services/endpoints';
import { GenericApiResponse } from '../../interfaces/services';

const LOCATIONS_LIST_REQUEST = 'LOCATIONS_LIST_REQUEST';
const LOCATIONS_LIST_SUCCESS = 'LOCATIONS_LIST_SUCCESS';
const LOCATIONS_LIST_FAILURE = 'LOCATIONS_LIST_FAILURE';

const LOCATION_DETAILS_REQUEST = 'LOCATION_DETAILS_REQUEST';
const LOCATION_DETAILS_SUCCESS = 'LOCATION_DETAILS_SUCCESS';
const LOCATION_DETAILS_FAILURE = 'LOCATION_DETAILS_FAILURE';

const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';

const RESET_LOCATION_DETAILS = 'RESET_LOCATION_DETAILS';

interface LocationsListRequestPayload {
    skip: number;
    take: number;
    searchTerm: string;
    sortColumn: LocationsSortColumns;
    sortDirection: SortDirection;
}

interface LocationsData extends LocationsListRequestPayload {
    locationsListStatus: ApiStatus;
    // FIXME: should be a real type
    locationsList: any[];
    locationDetailsStatus: ApiStatus;
    locationDetailsUpdateStatus: ApiStatus;
    // FIXME: should be a real type
    locationDetails: any;
    totalPossibleCount: number;
}

// FIXME: needs type
const requestLocationsList = (json: any): GenericAction<typeof LOCATIONS_LIST_REQUEST, any> => {
    return {
        type: LOCATIONS_LIST_REQUEST,
        payload: json
    };
};

// FIXME: needs type
const receiveLocationsList = (json: GenericApiResponse<any>): GenericAction<typeof LOCATIONS_LIST_SUCCESS, GenericApiResponse<any>> => {
    return {
        type: LOCATIONS_LIST_SUCCESS,
        payload: json
    };
};

const requestLocationsListFailed = (): Action<typeof LOCATIONS_LIST_FAILURE> => {
    return {
        type: LOCATIONS_LIST_FAILURE
    };
};

export const fetchLocationsList = ({
    skip = 0,
    take = 100,
    searchTerm = '',
    sortColumn = LocationsSortColumns.LastUpdateDate,
    sortDirection = SortDirection.Descending
}: {
    skip?: number;
    take?: number;
    searchTerm?: string;
    // FIXME: needs type
    sortColumn?: any;
    sortDirection?: SortDirection;
}): AppThunk => {
    return async (dispatch): Promise<void> => {
        const requestBody = {
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection
        };
        dispatch(requestLocationsList(requestBody));

        try {
            const queryString = new URLSearchParams({
                skip: skip.toString(),
                take: take.toString(),
                searchTerm,
                sortColumn,
                sortDirection
            });
            // FIXME: need to get real api endpoint from service locator
            const json = await ApiService.get({
                url: `https://develop.locationmanagement.services.trimblevisibility.tech/v1/fixedlocation?${queryString}`
            }) as GenericApiResponse<any>; // FIXME: Should be a real type
            dispatch(receiveLocationsList(json));

        } catch (error) {
            dispatch(requestLocationsListFailed());
            toast.error('Error occurred while fetching locations.');
        }
    };
};

const requestLocationDetails = (): Action<typeof LOCATION_DETAILS_REQUEST> => {
    return {
        type: LOCATION_DETAILS_REQUEST
    };
};

// FIXME: needs type
const receiveLocationDetails = (json: any): GenericAction<typeof LOCATION_DETAILS_SUCCESS, any> => {
    return {
        type: LOCATION_DETAILS_SUCCESS,
        payload: json
    };
};

const requestLocationDetailsFailed = (): Action<typeof LOCATION_DETAILS_FAILURE> => {
    return {
        type: LOCATION_DETAILS_FAILURE
    };
};

export const fetchLocationDetails = (fixedLocationCode: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(requestLocationDetails());

        try {
            // FIXME: need to get real api endpoint from service locator
            const json = await ApiService.get({
                url: `https://develop.locationmanagement.services.trimblevisibility.tech/v1/fixedlocation/${fixedLocationCode}`
            }) as GenericApiResponse<any>; // FIXME: Should be a real type
            dispatch(receiveLocationDetails(json.data[0]));

        } catch (error) {
            dispatch(requestLocationDetailsFailed());
            toast.error('Error occurred while fetching location details.');
        }
    };
};

const requestDeleteLocation = (): Action<typeof DELETE_LOCATION_REQUEST> => {
    return {
        type: DELETE_LOCATION_REQUEST
    };
};

const requestDeleteLocationFailed = (): Action<typeof DELETE_LOCATION_FAILURE> => {
    return {
        type: DELETE_LOCATION_FAILURE
    };
};

export const deleteLocation = ({
    fixedLocationCode,
    skip,
    searchTerm,
    sortColumn,
    sortDirection
}: {
    fixedLocationCode: string;
    skip: number;
    searchTerm: string;
    sortColumn: LocationsSortColumns;
    sortDirection: SortDirection;
}): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(requestDeleteLocation());

        try {
            // FIXME: need to get real api endpoint from service locator
            await ApiService.delete({
                url: `https://develop.locationmanagement.services.trimblevisibility.tech/v1/fixedlocation/${fixedLocationCode}`
            });
            dispatch(fetchLocationsList({
                skip,
                searchTerm,
                sortColumn,
                sortDirection
            }));
            toast.success('Location was successfully deleted.');

        } catch (error) {
            dispatch(requestDeleteLocationFailed());
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    toast.error(errorMessage);
                });
            } else {
                toast.error('Error occurred while deleting location.');
            }
        }
    };
};

export const resetLocationDetails = (): Action<typeof RESET_LOCATION_DETAILS> => {
    return {
        type: RESET_LOCATION_DETAILS
    };
};

type LocationActionTypes =
    ReturnType<typeof requestLocationsList> | ReturnType<typeof receiveLocationsList> | ReturnType<typeof requestLocationsListFailed> |
    ReturnType<typeof requestLocationDetails> | ReturnType<typeof receiveLocationDetails> | ReturnType<typeof requestLocationDetailsFailed> |
    // ReturnType<typeof requestUpdateLocationDetails> | ReturnType<typeof receiveLocationDetailsUpdate> | ReturnType<typeof requestLocationDetailsUpdateFailed> |
    // ReturnType<typeof requestCreateLocation> | ReturnType<typeof requestCreateLocationSuccess> | ReturnType<typeof requestCreateLocationFailed> |
    ReturnType<typeof requestDeleteLocation> | ReturnType<typeof requestDeleteLocationFailed> |
    ReturnType<typeof resetLocationDetails>

export const locationReducer = (locationData: LocationsData = {
    locationsListStatus: ApiStatus.Idle,
    locationsList: [],
    locationDetailsStatus: ApiStatus.Idle,
    locationDetailsUpdateStatus: ApiStatus.Idle,
    // FIXME: This should be the initialState
    locationDetails: null,
    skip: 0,
    take: 100,
    searchTerm: '',
    sortColumn: LocationsSortColumns.LastUpdateDate,
    sortDirection: SortDirection.Descending,
    totalPossibleCount: 0
}, action: LocationActionTypes): LocationsData => {
    switch (action.type) {
        case LOCATIONS_LIST_REQUEST: {
            return {
                ...locationData,
                locationsListStatus: ApiStatus.Loading,
                skip: action.payload.skip,
                searchTerm: action.payload.searchTerm,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection,
                totalPossibleCount: 0
            };
        }
        case LOCATIONS_LIST_SUCCESS: {
            return {
                ...locationData,
                locationsListStatus: ApiStatus.Success,
                locationsList: action.payload.data,
                totalPossibleCount: action.payload.totalPossibleCount
            };
        }
        case LOCATIONS_LIST_FAILURE: {
            return {
                ...locationData,
                locationsListStatus: ApiStatus.Failure,
                locationsList: [],
                totalPossibleCount: 0
            };
        }
        case LOCATION_DETAILS_REQUEST: {
            return {
                ...locationData,
                locationDetailsStatus: ApiStatus.Loading
            };
        }
        case LOCATION_DETAILS_SUCCESS: {
            return {
                ...locationData,
                locationDetailsStatus: ApiStatus.Success,
                locationDetails: action.payload
            };
        }
        case LOCATION_DETAILS_FAILURE: {
            return {
                ...locationData,
                locationDetailsStatus: ApiStatus.Failure,
                // FIXME: This should be the initialState
                locationDetails: null
            };
        }
        // case UPDATE_LOCATION_DETAILS_REQUEST: {
        //     return {
        //         ...locationData,
        //         locationDetailsUpdateStatus: ApiStatus.Loading
        //     };
        // }
        // case UPDATE_LOCATION_DETAILS_SUCCESS: {
        //     return {
        //         ...locationData,
        //         locationDetailsUpdateStatus: ApiStatus.Success,
        //         locationDetails: locationDetailsInitialState
        //     };
        // }
        // case UPDATE_LOCATION_DETAILS_FAILURE: {
        //     return {
        //         ...locationData,
        //         locationDetailsUpdateStatus: ApiStatus.Failure
        //     };
        // }
        // case CREATE_LOCATION_REQUEST: {
        //     return {
        //         ...locationData,
        //         locationDetailsUpdateStatus: ApiStatus.Loading
        //     };
        // }
        // case CREATE_LOCATION_SUCCESS: {
        //     return {
        //         ...locationData,
        //         locationDetailsUpdateStatus: ApiStatus.Success
        //     };
        // }
        // case CREATE_LOCATION_FAILURE: {
        //     return {
        //         ...locationData,
        //         locationDetailsUpdateStatus: ApiStatus.Failure
        //     };
        // }
        case DELETE_LOCATION_REQUEST: {
            return {
                ...locationData,
                locationDetails: null,
                locationsListStatus: ApiStatus.Idle
            };
        }
        case DELETE_LOCATION_FAILURE: {
            // If delete fails a snackbar is shown but the loader state needs to end to redisplay the shipment list
            return {
                ...locationData,
                locationsListStatus: ApiStatus.Success
            };
        }
        case RESET_LOCATION_DETAILS: {
            return {
                ...locationData,
                locationDetailsStatus: ApiStatus.Idle,
                // FIXME: This should be the initialState, probably 'locationDetailsInitialState'
                locationDetails: null
            };
        }
        default:
            return locationData;
    }
};
