import { MapLayerTypes, MapSources, MapLayers } from '../enums';
import { isVisibleUtil } from '../mapUtils';
import { MapLayer } from './interfaces';

/**
 * Creates a layer to display circles on the map for each Point in the orderListSource.
 */
const getMapOrderListLayerGeo = (isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const layer: MapLayer = {
        id: MapLayers.orderListLayer,
        type: MapLayerTypes.Circle,
        source: MapSources.orderListSource,
        layout: {
            visibility
        },
        paint: {
            'circle-radius': {
                base: 8,
                stops: [
                    // zoom is 10 -> circle radius will be 10px
                    [10, 10],
                    [12.5, 15],
                    [15, 20],
                    [18, 25],
                    [19, 30],
                    [20, 40],
                    [21, 50],
                    [22, 60]
                ]
            },
            'circle-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
        },
        filter: ['==', '$type', 'Point']
    };

    return layer;
};

/**
 * Adds the order list layer to the map if it doesn't already exist
 * @param map Instance of your map
 */
export const addOrderListLayer = (map: any, isVisible: boolean): void => {
    if (map.getLayer(MapLayers.orderListLayer) === undefined) {
        const orderListLayer = getMapOrderListLayerGeo(isVisible);
        map.addLayer(orderListLayer);
    }
};

/**
 * Creates a layer to display circles on the map for each Point in the orderListSource that isn't inside of a cluster.
 */
const getMapOrderListClusterLayerGeo = (isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const layer: MapLayer = {
        id: MapLayers.orderListClusterLayer,
        type: MapLayerTypes.Circle,
        source: MapSources.orderListClusterSource,
        layout: {
            visibility
        },
        paint: {
            'circle-radius': {
                base: 8,
                stops: [
                    // zoom is 10 -> circle radius will be 10px
                    [10, 10],
                    [12.5, 15],
                    [15, 20],
                    [18, 25],
                    [19, 30],
                    [20, 40],
                    [21, 50],
                    [22, 60]
                ]
            },
            'circle-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['!=', 'cluster', true]
        ]
    };

    return layer;
};

/**
 * Adds the order list clustered layer to the map if it doesn't already exist
 * @param map Instance of your map
 */
export const addOrderListClusterLayer = (map: any, isVisible: boolean): void => {
    if (map.getLayer(MapLayers.orderListClusterLayer) === undefined) {
        const orderListClusterLayer = getMapOrderListClusterLayerGeo(isVisible);
        map.addLayer(orderListClusterLayer);
    }
};
