import React from 'react';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';
import { useAccessTokenUrl } from '../../hooks/useAccessTokenUrl';

const ShipmentDetailsMenuItem = ({
    shipmentUniqueName,
    iconColor,
    handleClick
}: {
    /** unique identifier for the shipment */
    shipmentUniqueName: string;
    /** the color of the icon */
    iconColor: string;
    /** function to handle menu item click */
    handleClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}): JSX.Element => {
    const url = useAccessTokenUrl(`/shipmentDetails/${shipmentUniqueName}`);

    return (
        <MenuItem
            component='a'
            target='_blank'
            rel='noopener noreferrer'
            href={url}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
                handleClick(event);
            }}
            data-qa='shipmentDetails-action'
        >
            <ListItemIcon>
                <Icon
                    path={mdiOpenInNew}
                    size={1}
                    color={iconColor}
                />
            </ListItemIcon>
            <Typography variant='inherit'>Shipment Details</Typography>
        </MenuItem>
    );
};

export default ShipmentDetailsMenuItem;
