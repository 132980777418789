import React, { Fragment } from 'react';

import AuthService from '../../../services/authService';

const Login = (): JSX.Element => {
    AuthService.login();
    return <Fragment />;
};

export default Login;
