import React, { useEffect, useState, useMemo } from 'react';
import { TextField, CircularProgress, Typography, Grid } from '@mui/material';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Icon from '@mdi/react';
import { mdiPhone } from '@mdi/js';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import { useTypedSelector } from '../../redux';
import Endpoints from '../../services/endpoints';
import ApiService from '../../services/apiService';
import { GenericApiResponse } from '../../interfaces/services';
import { DriverDetails } from '../../interfaces/services/drivers';
import { formatPhoneNumber } from '../../helpers/dataUtils';

const DriverLookupAutocomplete = ({
    draftDriverDetails,
    handleSelectDriver
}: {
    draftDriverDetails: DriverDetails | null;
    handleSelectDriver: (newDriver: DriverDetails | null) => void;
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<DriverDetails[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const endpoints = useTypedSelector((state) => { return state.availableServices.endpoints; });

    // Ensure the same instance of the debounced callback between re-renderings
    const fetchDriverDetails = useMemo(() => {
        return debounce((queryString: string): void => {
            const { OrganizationsApi } = endpoints;

            ApiService.get(
                { url: `${OrganizationsApi}${Endpoints.organizationApi.driverDetails}?name=${queryString}` }
            ).then((response): void => {
                const { data } = response as GenericApiResponse<DriverDetails>;
                setOptions(data);
            }).catch((): void => {
                toast.error('An error occurred fetching driver names');
            }).finally((): void => {
                setIsFetching(false);
            });
        }, 200);
    }, [endpoints]);

    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
        return (): void => {
            fetchDriverDetails.cancel();
        };
    }, [fetchDriverDetails]);

    const handleInputChange = (query: string): void => {
        const queryString = query.trim();
        if (queryString !== '') {
            setIsFetching(true);
            fetchDriverDetails(queryString);
        } else {
            setIsOpen(false);
            setOptions([]);
        }
    };

    const handleRenderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
        return (
            <TextField
                {...params}
                label='Driver Name'
                placeholder='Search Drivers...'
                variant='outlined'
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    )
                }}
            />
        );
    };

    const handleRenderOptions = (option: DriverDetails): JSX.Element => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <span>
                        {`${option.firstName} ${option.lastName}`}
                    </span>
                </Grid>
                <Grid item xs={1} alignItems='center'>
                    <Icon
                        path={mdiPhone}
                        size={0.65}
                    />
                </Grid>
                <Grid item>
                    <Typography variant='body2' color='textSecondary'>
                        {formatPhoneNumber(option.phoneNumber)}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Autocomplete
            id='driver-lookup-autocomplete'
            options={options}
            noOptionsText='No drivers found'
            loading={isFetching}
            loadingText='Searching for drivers...'
            open={isOpen}
            onOpen={(event): void => {
                // Prevent opening on first click or with only spaces entered
                setIsOpen((event.target as HTMLTextAreaElement).value.trim() !== '');
            }}
            onClose={(): void => { setIsOpen(false); }}
            onChange={(event, value, reason): void => {
                if (reason === 'clear') {
                    handleSelectDriver(null);
                } else if (value) {
                    handleSelectDriver(value);
                }
                setIsFetching(false);
            }}
            value={draftDriverDetails}
            onInputChange={(event, query, reason): void => {
                if (reason !== 'reset') {
                    handleInputChange(query);
                }
            }}
            renderInput={(params): JSX.Element => { return handleRenderInput(params); }}
            getOptionLabel={(option): string => { return `${option.firstName} ${option.lastName}`; }}
            renderOption={(props, option): JSX.Element => {
                return (
                    <li {...props}>
                        {handleRenderOptions(option)}
                    </li>
                );
            }}
            isOptionEqualToValue={(option, testValue): boolean => {
                // test if the option is selected
                return testValue.driverGuid === option.driverGuid;
            }}
            forcePopupIcon={false}
            fullWidth
            clearOnBlur
            clearOnEscape
            data-qa='driverLookupAutocomplete-input'
        />
    );
};

export default DriverLookupAutocomplete;
