import React, { useEffect, useState } from 'react';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiMagnify, mdiClose } from '@mdi/js';

const classesPrefix = 'searchInput';

const classes = {
    searchInput: `${classesPrefix}-searchInput`
};

const StyledTextField = styled(TextField)(() => {
    return {
        [`&.${classes.searchInput}`]: {
            marginBottom: '8px'
        }
    };
});

const SearchInput = ({
    id,
    inputLabel,
    searchTerm,
    handleSearchTermSubmit
}: {
    id: string;
    /** Label to display in the input. */
    inputLabel: string;
    /** Incoming search term. */
    searchTerm: string;
    /** Function to handle the search term submit. */
    handleSearchTermSubmit: (searchTerm: string) => void;
}): JSX.Element => {
    const [draftSearchTerm, setDraftSearchTerm] = useState('');

    useEffect(() => {
        setDraftSearchTerm(searchTerm);
    }, [searchTerm]);

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        handleSearchTermSubmit(draftSearchTerm.trim());
    };

    return (
        <form onSubmit={handleSubmit} autoComplete='off' data-qa={`${id}-form`}>
            <StyledTextField
                className={classes.searchInput}
                label={inputLabel}
                margin='none'
                variant='outlined'
                size='small'
                fullWidth
                value={draftSearchTerm}
                onChange={(event): void => {
                    setDraftSearchTerm(event.currentTarget.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            {
                                draftSearchTerm.length > 0 &&
                                <IconButton
                                    aria-label='clear search term'
                                    onClick={(): void => {
                                        setDraftSearchTerm('');
                                        handleSearchTermSubmit('');
                                    }}
                                    data-qa={`${id}--action-clear`}
                                >
                                    <Icon path={mdiClose} size={1} />
                                </IconButton>
                            }
                            <IconButton
                                aria-label='start search'
                                onClick={handleSubmit}
                                data-qa={`${id}--action-search`}
                            >
                                <Icon path={mdiMagnify} size={1} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    inputProps: {
                        autoComplete: 'off',
                        'data-qa': `${id}-input`
                    }
                }}
            />
        </form>
    );
};

export default SearchInput;
