import React, { Fragment } from 'react';
import { Paper, Slide, PaletteMode } from '@mui/material';
import { styled } from '@mui/material/styles';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Feature, Point } from 'geojson';
import { bbox, Polygon } from '@turf/turf';

import { ShipmentDetailsMapWeatherAlert, WeatherAlert } from '../../interfaces/services/shipmentWeather';
import { ShipmentData } from '../../interfaces/services/shipmentDetails';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import {
    addShipmentDetailsSource,
    ShipmentDetailsMapSourceProperties
} from '../../helpers/maps/sources/shipmentDetails';
import {
    addShipmentDetailsWeatherSource,
    ShipmentDetailsMapWeatherSourceProperties
} from '../../helpers/maps/sources/weatherAlerts';
import {
    addShipmentDetailsStopsLayer,
    addShipmentDetailsGeofenceLayers,
    addShipmentDetailsRouteLayer,
    addShipmentDetailsPastPositionsLayer,
    addShipmentDetailsCurrentPositionLayer,
    addShipmentDetailsCurrentPositionPulseLayer
} from '../../helpers/maps/layers/shipmentDetails';
import { addTrafficLayers } from '../../helpers/maps/layers/traffic';
import { addShipmentDetailsWeatherLayer } from '../../helpers/maps/layers/weatherAlerts';
import {
    toggleLayerVisibility,
    fitMapToBounds,
    trimbleMapsPopup,
    handleMouseEnter,
    handleMouseLeave,
    handlePopupMouseEnter,
    handlePopupMouseLeave
} from '../../helpers/maps/mapUtils';
import {
    MapSources,
    MapLayers,
    MapImages
} from '../../helpers/maps/enums';
import MapSpinner from '../loaders/mapSpinner';
import MapControlBar from '../actionBars/mapControlBar';
import MapLegendWrapper from '../mapLegends/mapLegendWrapper';
import Hidden from '../layouts/hidden';
import WeatherAlertsList from '../lists/weatherAlertsList';

import '../../assets/CSS/trimbleMaps.css';

const classesPrefix = 'shipmentDetailsMap';

const classes = {
    map: `${classesPrefix}-map`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.map}`]: {
            height: 'calc(100vh - 485px)',
            minHeight: '380px'
        }
    };
});

interface ShipmentDetailsMapProps {
    /** Indicator to show loading spinner while data is fetching. */
    isFetchingData: boolean;
    /** Indicator to show or hide the route line when first loading the map. */
    isRouteLineVisible: boolean;
    /** Selected Shipment to show on the map. */
    shipment: ShipmentData;
    /** List of all weather alerts. */
    weatherAlerts: WeatherAlert[];
    /** Weather alert polygons to show on map with the alerts. */
    weatherPolygons: ShipmentDetailsMapWeatherAlert[];
    /** Indicator to show or hide the weather alerts when first loading the map. */
    isWeatherAlertsVisible: boolean;
    /** Function to handle the click of the weather alert button. */
    handleWeatherAlertsClick: () => void;
    /** Image to show on the map for the origin and destination stops. */
    originAndDestinationImage: string;
    /** Image to show on the map for the intermediate stops. */
    intermediateStopImage: string;
    /** Palette Mode used to determine map style. */
    paletteMode: PaletteMode;
    /** Indicator for if the screen is a mobile size. */
    isMobile: boolean;
    /** Indicator to trigger a map resize function. */
    resize?: boolean;
    /** Indicator to show map control bar */
    showMapControlBar: boolean;
}

interface ShipmentDetailsMapState {
    isWeatherRadarActive: boolean;
    isWeatherRoadSurfaceActive: boolean;
    isTrafficActive: boolean;
    isTrafficIncidentActive: boolean;
    is3dBuildingsLayerDisabled: boolean;
    is3dBuildingsActive: boolean;
    isRouteLineActive: boolean;
    isMapScrollZoomActive: boolean;
    mapTileStyle: string;
    mapStyleLoaded: boolean;
    flyToOn: boolean;
    flyToLocation: string | null;
    zoomMap: boolean;
    hoveredPolygonId: string;
    selectedFipsCodes: string[];
    showAllAlerts: boolean;
    currentCountyName: string;
    currentWeatherAlerts: WeatherAlert[];
}

let map: any = null;
const mapContainer = React.createRef<HTMLDivElement>();

class ShipmentDetailsMap extends React.Component<ShipmentDetailsMapProps, ShipmentDetailsMapState> {

    constructor(props: ShipmentDetailsMapProps) {
        super(props);

        TrimbleMaps.APIKey = 'A413EAA4767EC44E94A2360AE03B8689';

        this.state = {
            isWeatherRadarActive: false,
            isWeatherRoadSurfaceActive: false,
            isTrafficActive: false,
            isTrafficIncidentActive: false,
            is3dBuildingsLayerDisabled: false,
            is3dBuildingsActive: false,
            isRouteLineActive: this.props.isRouteLineVisible,
            isMapScrollZoomActive: false,
            mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
            mapStyleLoaded: false,
            flyToOn: false,
            flyToLocation: null,
            zoomMap: true,
            hoveredPolygonId: '',
            selectedFipsCodes: [],
            showAllAlerts: true,
            currentCountyName: '',
            currentWeatherAlerts: []
        };
    }

    componentDidMount(): void {
        // get current location to use for map intialization, default to center of USA
        let latitude = 39.828347;
        let longitude = -98.579488;
        if (this.props.shipment.currentPosition !== null) {
            latitude = this.props.shipment.currentPosition.latitude;
            longitude = this.props.shipment.currentPosition.longitude;
        }

        map = new TrimbleMaps.Map({
            container: mapContainer.current, // Ties to an HTML DOM element
            center: [longitude, latitude], // Sets initial center of map to the current location
            style: this.state.mapTileStyle, // Sets initial map style
            minZoom: 1.55, // To stop the zoom from showing multiple worlds
            zoom: 4, // Sets initial zoom level
            attributionControl: false, // Removes default attribution control, so we can customize it below
            scrollZoom: false, // disables scrollZoom interaction by default
            dragPan: false // disables dragPan interaction by default
        }).addControl(
            new TrimbleMaps.AttributionControl({
                compact: true // Minimizes the attribution to a compact version
            })
        ).addControl(
            // Adds the zoom in/out and compass buttons
            new TrimbleMaps.NavigationControl({
                showCompass: true,
                showZoom: true
            }),
            'top-right'
        ).addControl(
            new TrimbleMaps.FullscreenControl()
        );

        map.setRegion(TrimbleMaps.Common.Region.WW);

        // README: The event `style.load` is no longer a public event of mapbox. We will need to use some combination of using `styledata` and `map.isStyleLoaded()` at some point.
        // However, these do not work the same and there are several open issues with mapbox that have yet to be resolved, such as this one:
        // https://github.com/mapbox/mapbox-gl-js/issues/9779
        // Once a map style is loaded, add any layers that were on back to the map
        map.on('style.load', (): void => {
            // The style.load event can fire after the component has been unmounted.
            if (map === null) {
                return;
            }

            this.removeWeatherAlertEventHandlers();
            this.removeShipmentDetailsEventHandlers();

            this.addWeatherLayer();
            this.addShipmentDetailsToMap();
            addTrafficLayers(map, TrimbleMaps.APIKey, this.state.isTrafficActive);
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
            toggleLayerVisibility(map, MapLayers.shipmentDetailsWeatherLayer, this.props.isWeatherAlertsVisible);
            toggleLayerVisibility(map, MapLayers.shipmentDetailsWeatherSelectedLayer, this.props.isWeatherAlertsVisible);
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);
            toggleLayerVisibility(map, MapLayers.shipmentDetailsRouteLayer, this.state.isRouteLineActive);

            // HACK: trigger a resize function to make map take up full width
            map.resize();
            this.setState({ mapStyleLoaded: true });
        });

        map.on('load', () => {
            const trafficIncident = new TrimbleMaps.TrafficIncident();
            trafficIncident.addTo(map);

            const handleTrafficIncidentClick = new TrimbleMaps.TrafficIncidentClickControl();
            map.addControl(handleTrafficIncidentClick);
        });

        map.on('trafficincident', () => {
            toggleLayerVisibility(map, MapLayers.trafficIncidents, this.state.isTrafficIncidentActive);
        });
    }

    componentDidUpdate(prevProps: ShipmentDetailsMapProps, prevState: ShipmentDetailsMapState): void {
        // if the map is null, hold any CDU updates
        if (map === null) {
            return;
        }

        // if the paletteMode prop has changed, set the map style in state accordingly to trigger a re-render and the next CDU will update the map style to match.
        if (prevProps.paletteMode !== this.props.paletteMode) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
                mapStyleLoaded: false,
                zoomMap: false
            });
        }

        // if the map style state var has changed and the map style hasn't been loaded, set the style on the map itself
        if (prevState.mapTileStyle !== this.state.mapTileStyle && this.state.mapStyleLoaded === false) {
            map.setStyle(this.state.mapTileStyle);
        }

        if (this.state.mapStyleLoaded === false) {
            return;
        }

        // toggle weather radar
        if (prevState.isWeatherRadarActive !== this.state.isWeatherRadarActive) {
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
        }

        // toggle weather alerts
        if (prevProps.isWeatherAlertsVisible !== this.props.isWeatherAlertsVisible) {
            toggleLayerVisibility(map, MapLayers.shipmentDetailsWeatherLayer, this.props.isWeatherAlertsVisible);
            toggleLayerVisibility(map, MapLayers.shipmentDetailsWeatherSelectedLayer, this.props.isWeatherAlertsVisible);
            const mapBounds = this.determineMapBounds();
            if (Object.keys(mapBounds.bounds).length > 0) {
                fitMapToBounds({
                    map,
                    bounds: mapBounds.bounds,
                    zoomMap: this.state.zoomMap,
                    ...mapBounds.padding && { padding: mapBounds.padding }
                });
            }
        }

        // toggle weather road surface and traffic
        if (prevState.isWeatherRoadSurfaceActive !== this.state.isWeatherRoadSurfaceActive || prevState.isTrafficActive !== this.state.isTrafficActive) {
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            toggleLayerVisibility(map, MapLayers.europeTraffic, this.state.isTrafficActive);
            toggleLayerVisibility(map, MapLayers.northAmericaTraffic, this.state.isTrafficActive);
        }

        // toggle traffic incidents
        if (prevState.isTrafficIncidentActive !== this.state.isTrafficIncidentActive && map.getLayer(MapLayers.trafficIncidents) !== undefined) {
            toggleLayerVisibility(map, MapLayers.trafficIncidents, this.state.isTrafficIncidentActive);
        }

        // toggle 3D buildings
        if (prevState.is3dBuildingsActive !== this.state.is3dBuildingsActive) {
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);
        }

        // toggle route line
        if (prevState.isRouteLineActive !== this.state.isRouteLineActive) {
            toggleLayerVisibility(map, MapLayers.shipmentDetailsRouteLayer, this.state.isRouteLineActive);
        }

        // toggle scroll zoom
        if (prevState.isMapScrollZoomActive !== this.state.isMapScrollZoomActive) {
            if (this.state.isMapScrollZoomActive) {
                map.scrollZoom.enable();
            } else {
                map.scrollZoom.disable();
            }
        }

        // toggle drag pan
        if (this.props.isMobile || prevState.isMapScrollZoomActive !== this.state.isMapScrollZoomActive) {
            if (this.state.isMapScrollZoomActive) {
                map.dragPan.enable();
            } else {
                map.dragPan.disable();
            }
        } else if (!this.props.isMobile) {
            map.dragPan.enable();
        }

        // add shipment details to map if props have updated
        if (prevProps.shipment !== this.props.shipment) {
            this.removeShipmentDetailsEventHandlers();
            this.addShipmentDetailsToMap();
        }

        // add weather alerts to map if props have updated
        if (prevProps.weatherPolygons !== this.props.weatherPolygons) {
            this.removeWeatherAlertEventHandlers();
            this.addWeatherLayer();
        }

        // if the hoveredPolygonId has changed, set the right feature state on the source
        if (prevState.hoveredPolygonId !== this.state.hoveredPolygonId) {
            if (prevState.hoveredPolygonId !== '') {
                map.setFeatureState(
                    { source: MapSources.shipmentDetailsWeatherSource, id: prevState.hoveredPolygonId },
                    { hover: false }
                );
            }

            if (this.state.hoveredPolygonId !== '') {
                map.setFeatureState(
                    { source: MapSources.shipmentDetailsWeatherSource, id: this.state.hoveredPolygonId },
                    { hover: true }
                );
            }
        }

        // if the selectedFipsCodes has changed, set the correct filter on the map layer
        if (prevState.selectedFipsCodes !== this.state.selectedFipsCodes) {
            if (this.state.selectedFipsCodes.length > 0) {
                map.setFilter(MapLayers.shipmentDetailsWeatherSelectedLayer, ['in', 'fipsCode', ...this.state.selectedFipsCodes]);
            } else {
                map.setFilter(MapLayers.shipmentDetailsWeatherSelectedLayer, ['in', 'fipsCode', '']);
            }
        }

        // if resize prop is passed and is true force map to resize.
        if (this.props.resize === true) {
            map.resize();
        }
    }

    componentWillUnmount(): void {
        this.removeWeatherAlertEventHandlers();
        this.removeShipmentDetailsEventHandlers();
    }

    determineMapBounds = (): {
        bounds: any;
        padding: {
            top: number;
            bottom: number;
            left: number;
            right: number;
        } | null;
    } => {
        const bounds = new TrimbleMaps.LngLatBounds();
        let padding = null;

        const detailsSource = map.getSource(MapSources.shipmentDetailsSource);
        if (detailsSource !== undefined) {
            const { _data: data } = detailsSource;
            if (data.features.length > 0) {
                const detailsBoundingBox = bbox(data);
                const detailsBounds = new TrimbleMaps.LngLatBounds(detailsBoundingBox);
                bounds.extend(detailsBounds);
            }
        }

        const alertsSource = map.getSource(MapSources.shipmentDetailsWeatherSource);
        if (alertsSource !== undefined && this.props.isWeatherAlertsVisible) {
            const { _data: data } = alertsSource;
            if (data.features.length > 0) {
                const alertsBoundingBox = bbox(data);
                const alertsBounds = new TrimbleMaps.LngLatBounds(alertsBoundingBox);
                bounds.extend(alertsBounds);
                padding = {
                    top: 45, bottom: 45, left: 350, right: 80
                };
            }
        }

        return { bounds, padding };
    };

    handlePointClick = (featureId: string, featureBounds: any, maxZoom?: number): void => {
        const mapBounds = this.determineMapBounds();
        // Center and zoom in the map to the coordinates of any clicked symbol from the layer.
        if (this.state.flyToOn === true && this.state.flyToLocation === featureId && Object.keys(mapBounds.bounds).length > 0) {
            fitMapToBounds({
                map,
                bounds: mapBounds.bounds,
                zoomMap: this.state.zoomMap,
                ...mapBounds.padding && { padding: mapBounds.padding }
            });

            this.setState({
                flyToOn: false,
                flyToLocation: null
            });
        } else {
            fitMapToBounds({
                map,
                bounds: featureBounds,
                zoomMap: this.state.zoomMap,
                ...mapBounds.padding && { padding: mapBounds.padding },
                maxZoom
            });

            this.setState({
                flyToOn: true,
                flyToLocation: featureId
            });
        }
    };

    handleShipmentDetailsPastPositionClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.shipmentDetailsPastPositionsLayer) {
            const { geometry, properties }: { geometry: Point; properties: ShipmentDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);
            this.handlePointClick(properties.id, bounds, 13);
        }
    };

    handleShipmentDetailsCurrentLocationClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.shipmentDetailsCurrentPositionLayer || currentFeature.layer.id === MapLayers.shipmentDetailsCurrentPositionPulseLayer) {
            const { geometry, properties }: { geometry: Point; properties: ShipmentDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);
            this.handlePointClick(properties.id, bounds, 13);
        }
    };

    handleShipmentDetailsStopClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.shipmentDetailsStopsLayer) {
            let maxZoom: number | undefined;
            const { geometry, properties }: { geometry: Point; properties: ShipmentDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);

            const { geofenceFeaturePoints } = properties;
            if (geofenceFeaturePoints) {
                // because the geojson properties get converted to strings by mapbox, we first must re-type this var as unknown
                const unknownGeofence = geofenceFeaturePoints as unknown;
                // then we can properly re-type it as a string, which can then be JSON parsed back to the original type of a Polygon Feature
                const geofenceFeature: Feature<Polygon, ShipmentDetailsMapSourceProperties> = JSON.parse(unknownGeofence as string);

                geofenceFeature.geometry.coordinates.forEach((segment: number[][]): void => {
                    segment.forEach((coord: number[]): void => {
                        bounds.extend(coord);
                    });
                });
            } else {
                // if there is no geofence on the stop, we want to stop the map from trying to zoom way too far into the point, so we set a max zoom here only.
                maxZoom = 13;
            }
            this.handlePointClick(properties.id, bounds, maxZoom);
        }
    };

    handleShipmentDetailsWeatherAlertClick = (e: any): void => {
        // Use the first found feature.
        const feature = e.features[0];
        const { properties }: { properties: ShipmentDetailsMapWeatherSourceProperties; } = feature;

        // because the geojson properties get converted to strings by mapbox, we first must re-type this var as unknown
        const unknownAlerts = properties.weatherAlerts as unknown;
        // then we can properly re-type it as a string, which can then be JSON parsed back to the original type of a WeatherAlert array
        const currentWeatherAlerts: WeatherAlert[] = JSON.parse(unknownAlerts as string);

        this.setState({
            showAllAlerts: false,
            selectedFipsCodes: [properties.fipsCode],
            currentCountyName: properties.countyName,
            currentWeatherAlerts
        });
    };

    handleShipmentDetailsWeatherAlertMouseMove = (e: any): void => {
        handleMouseEnter(map);

        // Determine if the feature is being hovered or not
        if (e.features.length > 0) {
            this.setState({ hoveredPolygonId: e.features[0].id });
        }
    };

    handleShipmentDetailsWeatherAlertMouseLeave = (): void => {
        handleMouseLeave(map);
        this.setState({ hoveredPolygonId: '' });
    };

    addWeatherLayer = (): void => {
        // add map weather source
        addShipmentDetailsWeatherSource(map, this.props.weatherPolygons);

        // add map weather layers
        addShipmentDetailsWeatherLayer(map, MapSources.shipmentDetailsWeatherSource, false);

        map.on('click', MapLayers.shipmentDetailsWeatherLayer, this.handleShipmentDetailsWeatherAlertClick);
        map.on('mousemove', MapLayers.shipmentDetailsWeatherLayer, this.handleShipmentDetailsWeatherAlertMouseMove);
        map.on('mouseleave', MapLayers.shipmentDetailsWeatherLayer, this.handleShipmentDetailsWeatherAlertMouseLeave);
    };

    addShipmentDetailsToMap = (): void => {
        // add images to map for stops
        map.loadImage(this.props.originAndDestinationImage, (error: unknown, originDestinationImage: any): void => {
            if (map.hasImage(MapImages.originDestinationMapMarker) === false) {
                map.addImage(MapImages.originDestinationMapMarker, originDestinationImage);
            }
        });

        map.loadImage(this.props.intermediateStopImage, (error: unknown, stopImage: any): void => {
            if (map.hasImage(MapImages.stopMapMarker) === false) {
                map.addImage(MapImages.stopMapMarker, stopImage);
            }
        });

        // add map source
        addShipmentDetailsSource(map, this.props.shipment);

        // add geofence layers
        addShipmentDetailsGeofenceLayers(map, MapSources.shipmentDetailsSource);

        // add route
        addShipmentDetailsRouteLayer(map, MapSources.shipmentDetailsSource, true);

        // add past positions and mouse events
        addShipmentDetailsPastPositionsLayer(map, MapSources.shipmentDetailsSource);
        map.on('click', MapLayers.shipmentDetailsPastPositionsLayer, this.handleShipmentDetailsPastPositionClick);
        map.on('mouseenter', MapLayers.shipmentDetailsPastPositionsLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.shipmentDetailsPastPositionsLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        const size = 120;
        const pulseColor = getDeliveryStatusColor(this.props.shipment.deliveryStatus);

        // This implements `StyleImageInterface` to draw a pulsing dot icon on the map.
        const pulsingDot: {
            width: number;
            height: number;
            context: CanvasRenderingContext2D | null;
            data: Uint8ClampedArray | Uint8Array;
            onAdd: () => void;
            onRemove: () => void;
            render: () => boolean;
        } = {
            width: size,
            height: size,
            context: null,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map, get the rendering context for the map canvas.
            onAdd(): void {
                const canvas = document.createElement('canvas');
                canvas.width = pulsingDot.width;
                canvas.height = pulsingDot.height;
                pulsingDot.context = canvas.getContext('2d');
            },

            onRemove(): void {
                pulsingDot.context = null;
            },

            // Call once before every frame where the icon will be used.
            render(): boolean {
                const duration = 2000;
                const time = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * time + radius;

                if (pulsingDot.context !== null) {
                    // Draw the outer circle.
                    pulsingDot.context.clearRect(0, 0, pulsingDot.width, pulsingDot.height);
                    pulsingDot.context.beginPath();
                    pulsingDot.context.arc(
                        pulsingDot.width / 2,
                        pulsingDot.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    pulsingDot.context.globalAlpha = 1 - time;
                    pulsingDot.context.fillStyle = pulseColor;
                    pulsingDot.context.fill();

                    // Draw the inner circle.
                    pulsingDot.context.beginPath();
                    pulsingDot.context.arc(
                        pulsingDot.width / 2,
                        pulsingDot.height / 2,
                        radius,
                        0,
                        Math.PI * 2
                    );
                    pulsingDot.context.fillStyle = pulseColor;
                    pulsingDot.context.globalAlpha = 1;
                    pulsingDot.context.strokeStyle = '#fff';
                    pulsingDot.context.lineWidth = 4;
                    pulsingDot.context.fill();
                    pulsingDot.context.stroke();

                    // Update this image's data with data from the canvas.
                    pulsingDot.data = pulsingDot.context.getImageData(
                        0,
                        0,
                        pulsingDot.width,
                        pulsingDot.height
                    ).data;
                }

                // Continuously repaint the map, resulting in the smooth animation of the dot.
                map.triggerRepaint();

                // Return `true` to let the map know that the image was updated.
                return true;
            }
        };

        // add pulsing current location image
        if (map.hasImage(MapImages.currentLocationMapMarker) === false) {
            map.addImage(MapImages.currentLocationMapMarker, pulsingDot, { pixelRatio: 2 });
        }

        // add current position pulse and mouse events
        addShipmentDetailsCurrentPositionPulseLayer(map, MapSources.shipmentDetailsSource);
        map.on('click', MapLayers.shipmentDetailsCurrentPositionPulseLayer, this.handleShipmentDetailsCurrentLocationClick);
        map.on('mouseenter', MapLayers.shipmentDetailsCurrentPositionPulseLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.shipmentDetailsCurrentPositionPulseLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        // add current position and mouse events
        addShipmentDetailsCurrentPositionLayer(map, MapSources.shipmentDetailsSource);
        map.on('click', MapLayers.shipmentDetailsCurrentPositionLayer, this.handleShipmentDetailsCurrentLocationClick);
        map.on('mouseenter', MapLayers.shipmentDetailsCurrentPositionLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.shipmentDetailsCurrentPositionLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        // add stops and mouse events
        addShipmentDetailsStopsLayer(map, MapSources.shipmentDetailsSource);
        map.on('click', MapLayers.shipmentDetailsStopsLayer, this.handleShipmentDetailsStopClick);
        map.on('mouseenter', MapLayers.shipmentDetailsStopsLayer, (): void => { handleMouseEnter(map); });
        map.on('mouseleave', MapLayers.shipmentDetailsStopsLayer, (): void => { handleMouseLeave(map); });

        const mapBounds = this.determineMapBounds();
        if (Object.keys(mapBounds.bounds).length > 0) {
            fitMapToBounds({
                map,
                bounds: mapBounds.bounds,
                zoomMap: this.state.zoomMap,
                ...mapBounds.padding && { padding: mapBounds.padding }
            });
        }
    };

    removeWeatherAlertEventHandlers = (): void => {
        if (map.getLayer(MapLayers.shipmentDetailsWeatherLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsWeatherLayer, this.handleShipmentDetailsWeatherAlertClick);
            map.off('mousemove', MapLayers.shipmentDetailsWeatherLayer, this.handleShipmentDetailsWeatherAlertMouseMove);
            map.off('mouseleave', MapLayers.shipmentDetailsWeatherLayer, this.handleShipmentDetailsWeatherAlertMouseLeave);
        }
    };

    removeShipmentDetailsEventHandlers = (): void => {
        if (map.getLayer(MapLayers.shipmentDetailsPastPositionsLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsPastPositionsLayer, this.handleShipmentDetailsPastPositionClick);
            map.off('mouseenter', MapLayers.shipmentDetailsPastPositionsLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsPastPositionsLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.shipmentDetailsCurrentPositionPulseLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsCurrentPositionPulseLayer, this.handleShipmentDetailsCurrentLocationClick);
            map.off('mouseenter', MapLayers.shipmentDetailsCurrentPositionPulseLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsCurrentPositionPulseLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.shipmentDetailsCurrentPositionLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsCurrentPositionLayer, this.handleShipmentDetailsCurrentLocationClick);
            map.off('mouseenter', MapLayers.shipmentDetailsCurrentPositionLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsCurrentPositionLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.shipmentDetailsStopsLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsStopsLayer, this.handleShipmentDetailsStopClick);
            map.off('mouseenter', MapLayers.shipmentDetailsStopsLayer, handleMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsStopsLayer, handleMouseLeave);
        }

        if (map.hasImage(MapImages.currentLocationMapMarker) === true) {
            map.removeImage(MapImages.currentLocationMapMarker);
        }
    };

    renderWeatherAlertList(): JSX.Element {
        return (
            <WeatherAlertsList
                showAllAlerts={this.state.showAllAlerts}
                allWeatherAlerts={this.props.weatherAlerts}
                selectedCountyName={this.state.currentCountyName}
                selectedWeatherAlerts={this.state.currentWeatherAlerts}
                handleShowAllAlertsClick={(): void => {
                    this.setState((state) => {
                        return {
                            showAllAlerts: !state.showAllAlerts,
                            selectedFipsCodes: []
                        };
                    });
                }}
                handleAlertExpansionChange={(selectedAlert: WeatherAlert, isExpanded: boolean): void => {
                    if (isExpanded) {
                        this.setState({
                            selectedFipsCodes: selectedAlert.fipsCodes
                        });
                    } else {
                        this.setState({
                            selectedFipsCodes: []
                        });
                    }
                }}
            />
        );
    }

    render(): JSX.Element {
        return (
            <Fragment>
                <StyledDiv
                    id='trimble-map-container'
                    ref={mapContainer}
                    className={classes.map}
                    data-qa='shipmentDetailsMap-container'
                >
                    {
                        this.props.isFetchingData &&
                        <MapSpinner />
                    }
                    {
                        this.props.showMapControlBar &&
                        <MapControlBar
                            isWeatherRadarActive={this.state.isWeatherRadarActive}
                            handleWeatherRadarClick={(): void => {
                                this.setState((state) => {
                                    return {
                                        isWeatherRadarActive: !state.isWeatherRadarActive
                                    };
                                });
                            }}
                            isWeatherAlertsDisabled={this.props.weatherAlerts.length === 0}
                            isWeatherAlertsActive={this.props.isWeatherAlertsVisible}
                            handleWeatherAlertsClick={(): void => {
                                // if the prop is currently turned on, it means we are going to toggle it off in the next render, so we need to reset some state
                                if (this.props.isWeatherAlertsVisible) {
                                    this.setState({
                                        hoveredPolygonId: '',
                                        selectedFipsCodes: [],
                                        showAllAlerts: true,
                                        currentCountyName: '',
                                        currentWeatherAlerts: []
                                    });
                                }
                                this.props.handleWeatherAlertsClick();
                            }}
                            isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                            handleWeatherRoadSurfaceClick={(): void => {
                                this.setState((state) => {
                                    return {
                                        isWeatherRoadSurfaceActive: !state.isWeatherRoadSurfaceActive,
                                        isTrafficActive: !state.isWeatherRoadSurfaceActive === true ? false : state.isTrafficActive
                                    };
                                });
                            }}
                            isTrafficActive={this.state.isTrafficActive}
                            handleTrafficClick={(): void => {
                                this.setState((state) => {
                                    return {
                                        isTrafficActive: !state.isTrafficActive,
                                        isWeatherRoadSurfaceActive: !state.isTrafficActive === true ? false : state.isWeatherRoadSurfaceActive
                                    };
                                });
                            }}
                            isTrafficIncidentActive={this.state.isTrafficIncidentActive}
                            handleTrafficIncidentClick={(): void => {
                                this.setState((state) => {
                                    return {
                                        isTrafficIncidentActive: !state.isTrafficIncidentActive
                                    };
                                });
                            }}
                            is3dBuildingsLayerDisabled={this.state.is3dBuildingsLayerDisabled}
                            is3dBuildingsActive={this.state.is3dBuildingsActive}
                            handle3dBuildingClick={(): void => {
                                this.setState((state) => {
                                    return {
                                        is3dBuildingsActive: !state.is3dBuildingsActive
                                    };
                                });
                            }}
                            isRouteLineActive={this.state.isRouteLineActive}
                            handleRouteLineClick={(): void => {
                                this.setState((state) => {
                                    return {
                                        isRouteLineActive: !state.isRouteLineActive
                                    };
                                });
                            }}
                            mapTileStyle={this.state.mapTileStyle}
                            handleMapStyleMenuItemClick={(mapStyle: string): void => {
                                let is3dBuildingsLayerDisabled = false;
                                let { is3dBuildingsActive } = this.state;
                                if (mapStyle === TrimbleMaps.Common.Style.SATELLITE) {
                                    is3dBuildingsLayerDisabled = true; // disable 3d buildings button when in satellite mode
                                    is3dBuildingsActive = false; // turn 3d building layer off when in satellite mode
                                }

                                this.setState({
                                    is3dBuildingsLayerDisabled,
                                    is3dBuildingsActive,
                                    mapTileStyle: mapStyle,
                                    mapStyleLoaded: false,
                                    zoomMap: false
                                });
                            }}
                            isMapScrollZoomActive={!this.state.isMapScrollZoomActive}
                            handleMapScrollZoomClick={(): void => {
                                this.setState((state) => {
                                    return {
                                        isMapScrollZoomActive: !state.isMapScrollZoomActive
                                    };
                                });
                            }}
                        />
                    }

                    <MapLegendWrapper
                        isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                        isMapLegendEnabled={false}
                        mapLegendType={null}
                    />

                    <Hidden breakpoint='md' direction='down'>
                        <Slide direction='right' in={this.props.isWeatherAlertsVisible} mountOnEnter unmountOnExit>
                            <div id='weatherAlertList' className='sidebar flex-center'>
                                <Paper
                                    className='sidebar-content'
                                    elevation={9}
                                    data-qa='alertList-container'
                                >
                                    {
                                        this.renderWeatherAlertList()
                                    }
                                </Paper>
                            </div>
                        </Slide>
                    </Hidden>
                </StyledDiv>

                {
                    this.props.isWeatherAlertsVisible &&
                    <Hidden breakpoint='md' direction='up'>
                        <div className='mobile-sidebar'>
                            <Paper data-qa='alertList-container'>
                                {
                                    this.renderWeatherAlertList()
                                }
                            </Paper>
                        </div>
                    </Hidden>
                }
            </Fragment>
        );
    }

}

export default ShipmentDetailsMap;
