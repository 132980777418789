import React from 'react';
import {
    Card,
    CardContent,
    Grid
} from '@mui/material';

import LabelValuePairLoading from '../../loaders/labels/labelValuePairLoading';
import OrderCommoditiesTable from '../../tables/orderCommoditiesTable';

const OrderDetailsCardLoading = (): JSX.Element => {
    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <LabelValuePairLoading label='Order ID' width='120px' />
                        <LabelValuePairLoading label='Customer Reference' width='120px' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LabelValuePairLoading label='Customer Name' width='120px' />
                        <LabelValuePairLoading label='Order Date' width='120px' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LabelValuePairLoading label='Order Type' width='120px' />
                        <LabelValuePairLoading label='Service Level' width='120px' />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <LabelValuePairLoading label='Order Reference' width='120px' />
                    </Grid>
                </Grid>
                <OrderCommoditiesTable isFetching={true} />
            </CardContent>
        </Card>
    );
};

export default OrderDetailsCardLoading;
