import React, { Fragment, useMemo } from 'react';
import { Typography } from '@mui/material';

import { useTypedSelector } from '../../redux';
import { ShipmentListData } from '../../interfaces/services/shipment';
import { ApiStatus } from '../../helpers/enums';
import ErrorPage from '../errors/errorPage';
import ShipmentCardLoading from '../loaders/cards/shipmentCardLoading';
import ShipmentCard from '../cards/shipmentCard';

const ShipmentCardList = (): JSX.Element => {
    const shipmentListStatus = useTypedSelector((state) => { return state.shipments.shipmentListStatus; });
    const shipmentList = useTypedSelector((state) => { return state.shipments.shipmentList; });

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });

    const isFetchingData =
        shipmentListStatus === ApiStatus.Idle || shipmentListStatus === ApiStatus.Loading ||
        organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading;

    // Creates a memoized list of the shipment cards which will only adjust when the shipmentList changes.
    // This helps fix some performance issues that are causing this entire list to re-render unnecessarily.
    const memoizedShipmentCardList = useMemo((): JSX.Element[] => {
        return shipmentList.map((shipment: ShipmentListData): JSX.Element => {
            return (
                <ShipmentCard
                    key={shipment.shipmentUniqueName}
                    shipment={shipment}
                />
            );
        });
    }, [shipmentList]);

    if (isFetchingData) {
        return (
            <Fragment>
                {
                    Array.from(new Array(5)).map((item, index): JSX.Element => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <ShipmentCardLoading key={index} />
                        );
                    })
                }
            </Fragment>
        );
    }

    if (shipmentList.length === 0 || shipmentListStatus === ApiStatus.Failure) {
        return (
            <ErrorPage errorHeaderText=''>
                <Typography variant='body1' data-qa='noShipments'>No shipments available.</Typography>
            </ErrorPage>
        );
    }

    return (
        <Fragment>
            {memoizedShipmentCardList}
        </Fragment>
    );
};

export default ShipmentCardList;
