import React, { Fragment } from 'react';
import Button from '@mui/material/Button';

import CommonDialog from './common/commonDialog';

const DeleteConfirmationDialog = ({
    isOpen,
    handleClose,
    handleContinue
}: {
    isOpen: boolean;
    handleClose: () => void;
    handleContinue: () => void;
}): JSX.Element => {

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            content='Are you sure you want to delete?'
            actions={(
                <Fragment>
                    <Button
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={handleContinue}
                        data-qa='continue-button'
                    >
                        Delete
                    </Button>
                </Fragment>
            )}

        />
    );
};

export default DeleteConfirmationDialog;
