import React, { Fragment, useReducer, useEffect } from 'react';
import { Action } from 'redux';
import { Grid, Button } from '@mui/material';

import { GenericAction, useTypedSelector } from '../../redux';
import { AllowedNotificationFilterPropertyName, NotificationTypeLabel, ShareType } from '../../helpers/enums';
import {
    INITIALIZE_FILTERS,
    UPDATE_FILTER,
    NotificationFilterState,
    initialNotificationState
} from '../../interfaces/filterInterfaces';
import {
    deliveryStatusOptions,
    notificationShipmentStatusOptions,
    notificationModeTypeOptions,
    notificationHotShipmentOptions
} from '../../helpers/hardcodedOptionLists';
import Endpoints from '../../services/endpoints';
import CommonDialog from './common/commonDialog';
import AsyncCreatable from '../selects/asyncCreatable';
import MultiSelect from '../selects/multiSelect';
import AsyncMultiSelect from '../selects/asyncMultiSelect';

const NotificationFilterDialog = ({
    isOpen,
    closeDialog,
    handleSave,
    selectedFilters,
    notificationTypeLabel
}: {
    isOpen: boolean;
    closeDialog: () => void;
    handleSave: (allFilters: NotificationFilterState) => void;
    selectedFilters: { [k in AllowedNotificationFilterPropertyName]?: string[] };
    notificationTypeLabel: NotificationTypeLabel | null;
}): JSX.Element => {
    const MasterData = useTypedSelector((state) => { return state.availableServices.endpoints.MasterData; });
    const OrganizationApi = useTypedSelector((state) => { return state.availableServices.endpoints.OrganizationsApi; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const reducer = (
        state: NotificationFilterState,
        action: Action<typeof INITIALIZE_FILTERS> |
            GenericAction<typeof UPDATE_FILTER,
                {
                    filterId: AllowedNotificationFilterPropertyName;
                    filterValues: string[];
                }>
    ): NotificationFilterState => {
        switch (action.type) {
            case UPDATE_FILTER: {
                return {
                    ...state,
                    [action.payload.filterId]: action.payload.filterValues
                };
            }
            case INITIALIZE_FILTERS: {
                return initialNotificationState;
            }
            default: {
                return state;
            }
        }
    };

    const [filterState, localDispatch] = useReducer(reducer, initialNotificationState);

    const updateFilterState = (filterId: string, filterValues: string[]): void => {
        localDispatch({
            type: UPDATE_FILTER,
            payload: {
                filterId: filterId as AllowedNotificationFilterPropertyName,
                filterValues
            }
        });
    };

    useEffect((): void => {
        // if the selectedFilters are empty, empty the local filter state
        if (Object.keys(selectedFilters).length === 0) {
            localDispatch({
                type: INITIALIZE_FILTERS
            });
        } else {
            // Add the selectedFilters values to the reducers
            Object.keys(selectedFilters).forEach((filterKey: unknown) => {
                const key = filterKey as AllowedNotificationFilterPropertyName;
                updateFilterState(key, selectedFilters[key] || []);
            });
        }
    }, [selectedFilters]);

    const renderDialogContent = (): JSX.Element => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.Customers}
                        label='Customers'
                        filterName='ProductCustomer'
                        values={filterState[AllowedNotificationFilterPropertyName.Customers]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.Partners}
                        label='Carrier'
                        filterName='CarrierName'
                        values={filterState[AllowedNotificationFilterPropertyName.Partners]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.Products}
                        label='Products'
                        filterName='ProductLine'
                        values={filterState[AllowedNotificationFilterPropertyName.Products]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.Brand}
                        label='Brand/Business Unit'
                        filterName='ProductBrand'
                        values={filterState[AllowedNotificationFilterPropertyName.Brand]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.Sku}
                        label='SKU/Commodity'
                        filterName='Sku'
                        values={filterState[AllowedNotificationFilterPropertyName.Sku]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncMultiSelect
                        id={AllowedNotificationFilterPropertyName.EquipmentType}
                        values={filterState[AllowedNotificationFilterPropertyName.EquipmentType]}
                        handleChange={updateFilterState}
                        url={`${MasterData}${Endpoints.masterDataApi.equipmentType}`}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.Origin}
                        label='Origin'
                        filterName='OriginDisplayfreightprovider'
                        values={filterState[AllowedNotificationFilterPropertyName.Origin]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.Destination}
                        label='Destination'
                        filterName='DestinationDisplayfreightprovider'
                        values={filterState[AllowedNotificationFilterPropertyName.Destination]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.InboundLocation}
                        label='Inbound Location'
                        filterName='InboundLocation'
                        values={filterState[AllowedNotificationFilterPropertyName.InboundLocation]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.OutboundLocation}
                        label='Outbound Location'
                        filterName='OutboundLocation'
                        values={filterState[AllowedNotificationFilterPropertyName.OutboundLocation]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.DestinationState}
                        label='To State'
                        filterName='DestinationStateCodefreightprovider'
                        values={filterState[AllowedNotificationFilterPropertyName.DestinationState]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncCreatable
                        id={AllowedNotificationFilterPropertyName.OriginState}
                        label='From State'
                        filterName='OriginStateCodefreightprovider'
                        values={filterState[AllowedNotificationFilterPropertyName.OriginState]}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MultiSelect
                        id={AllowedNotificationFilterPropertyName.DeliveryStatus}
                        label='Delivery Status'
                        values={filterState[AllowedNotificationFilterPropertyName.DeliveryStatus]}
                        availableOptions={deliveryStatusOptions}
                        isLoading={false}
                        handleChange={updateFilterState}
                    />
                </Grid>
                {/* Shipment status filter only applies notifications types that are not Shipment Status Change */
                    notificationTypeLabel !== NotificationTypeLabel.shipmentStatusChange &&
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedNotificationFilterPropertyName.ShipmentStatus}
                            label='Shipment Status'
                            values={filterState[AllowedNotificationFilterPropertyName.ShipmentStatus]}
                            availableOptions={notificationShipmentStatusOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <AsyncMultiSelect
                        id={AllowedNotificationFilterPropertyName.OrderExceptions}
                        values={filterState[AllowedNotificationFilterPropertyName.OrderExceptions]}
                        handleChange={updateFilterState}
                        url={`${OrganizationApi}${Endpoints.organizationApi.orderExceptionList}`}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MultiSelect
                        id={AllowedNotificationFilterPropertyName.ModeType}
                        label='Mode Type'
                        values={filterState[AllowedNotificationFilterPropertyName.ModeType]}
                        availableOptions={notificationModeTypeOptions}
                        isLoading={false}
                        handleChange={updateFilterState}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MultiSelect
                        id={AllowedNotificationFilterPropertyName.HotShipments}
                        label='Hot Shipments'
                        values={filterState[AllowedNotificationFilterPropertyName.HotShipments]}
                        availableOptions={notificationHotShipmentOptions}
                        isLoading={false}
                        handleChange={updateFilterState}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={closeDialog}
            fullWidth
            maxWidth='md'
            headerText='Filters'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        onClick={closeDialog}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={shareType === ShareType.OperatorReadOnly}
                        onClick={(): void => {
                            closeDialog();
                            handleSave(filterState);
                        }}
                        data-qa='add-button'
                    >
                        Add
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default NotificationFilterDialog;
