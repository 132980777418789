import React from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { StopType } from '../../helpers/enums';
import { Stop } from '../../interfaces/services/shipmentDetails';
import { formatCityRegionPostalCountry } from '../../helpers/addressUtils';

const ShipmentStopSelect = ({
    isDisabled,
    currentStop,
    stops,
    handleChange
}: {
    isDisabled: boolean;
    currentStop: Stop | null;
    stops: Stop[];
    handleChange: (newValue: Stop | null) => void;
}): JSX.Element => {
    return (
        <Autocomplete
            size='small'
            disabled={isDisabled}
            options={stops}
            getOptionLabel={(stop): string => {
                let label = stop.stopType === StopType.Origin || stop.stopType === StopType.Destination ? stop.stopType : `Stop ${stop.stopSequence - 1}`;
                const stopName = stop.name || stop.description || '';

                if (stopName) {
                    label += ` - ${stopName}`;
                } else if (stop.address) {
                    label += ` - ${formatCityRegionPostalCountry({ city: stop.address.city, region: stop.address.state })}`;
                }

                return label;
            }}
            isOptionEqualToValue={(stop): boolean => {
                return stop.stopSequence === currentStop?.stopSequence;
            }}
            onChange={(event, value): void => {
                handleChange(value);
            }}
            value={currentStop}
            renderInput={(params): JSX.Element => {
                return (
                    <TextField
                        {...params}
                        label='Stop'
                        fullWidth={true}
                        margin='normal'
                        variant='filled'
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            'data-qa': 'stop-input'
                        }}
                    />
                );
            }}
            disableClearable={false}
            data-qa='stop-select'
        />
    );

};

export default ShipmentStopSelect;
