import React, { Fragment } from 'react';

import { useTypedSelector } from '../../redux';
import { Stop } from '../../interfaces/services/shipmentDetails';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { determineDisplayDates } from '../../helpers/shipmentUtils';
import LabelValuePair from '../labels/labelValuePair';

const StopDateLabel = ({
    stop
}: {
    stop?: Stop;
}): JSX.Element => {
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    if (!stop) {
        return <Fragment />;
    }

    const displayDateList = organizationPreferences ?
        determineDisplayDates(
            organizationPreferences.preferredStopTimeSourceType,
            organizationPreferences.showEta,
            stop.geofenceDepartureDateTime,
            stop.carrierDepartureDateTime,
            stop.geofenceArrivalDateTime,
            stop.carrierArrivalDateTime,
            stop.estimatedDeliveryDateTime
        ) : [];

    const displayDates: { [label: string]: string | null; } = {
        'Scheduled Start': zonedDateTimeToDisplay(stop.scheduledStartDateTime),
        'Scheduled End': zonedDateTimeToDisplay(stop.scheduledEndDateTime),
        'Appointment Start': stop.appointment ? zonedDateTimeToDisplay(stop.appointment.startTime) : null,
        'Appointment End': stop.appointment ? zonedDateTimeToDisplay(stop.appointment.endTime) : null
    };

    return (
        <Fragment>
            {
                Object.keys(displayDates).map((dateLabel): JSX.Element => {
                    const alphanumericLabel = dateLabel.replace(/[^A-Z0-9]+/ig, '');
                    if (displayDates[dateLabel]) {
                        return <LabelValuePair key={alphanumericLabel} label={dateLabel} value={displayDates[dateLabel] || ''} fontSize='12px' data-qa={`${stop.stopType.toLowerCase()}${alphanumericLabel}`} />;
                    }
                    return <Fragment key={alphanumericLabel} />;
                })
            }
            {
                displayDateList.map((dateObject): JSX.Element => {
                    const alphanumericLabel = dateObject.label.replace(/[^A-Z0-9]+/ig, '');
                    if (dateObject.value) {
                        return <LabelValuePair key={alphanumericLabel} label={dateObject.label} value={dateObject.value} fontSize='12px' data-qa={`${stop.stopType.toLowerCase()}${alphanumericLabel}`} />;
                    }
                    return <Fragment key={alphanumericLabel} />;
                })
            }
        </Fragment>
    );
};

export default StopDateLabel;
