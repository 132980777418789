import {
    CalendarDateType,
    CalendarStopType,
    DeliveryStatus,
    EquipmentType,
    LocationsSortColumns,
    MilestoneType,
    ModeType,
    NotificationTypeLabel,
    NotificationTypes,
    NotificationsSortColumns,
    ShipmentStatus,
    SortDirection,
    WeatherAlertSeverity
} from './enums';
import { LocationsSortOption, NotificationsSortOption, OrdersSortOption, ShipmentSortOption, ViewsSortOption } from '../interfaces/componentInterfaces';

export const autoRefreshOptions = [
    { value: 1, label: 'Every Minute' },
    { value: 2, label: 'Every 2 Minutes' },
    { value: 5, label: 'Every 5 Minutes' },
    { value: 10, label: 'Every 10 Minutes' },
    { value: 15, label: 'Every 15 Minutes' },
    { value: 30, label: 'Every 30 Minutes' },
    { value: 60, label: 'Every Hour' }
];

export const calendarDateTypeOptions = [
    { filterValue: CalendarDateType.Scheduled, label: 'Scheduled Date' },
    { filterValue: CalendarDateType.Appointment, label: 'Appointment Date' }
];

export const calendarStopTypeOptions = [
    { filterValue: CalendarStopType.Delivery, label: 'Delivery' },
    { filterValue: CalendarStopType.Pickup, label: 'Pickup' }
];

export const deliveryStatusOptions = [
    { filterValue: DeliveryStatus.Early, label: 'Early' },
    { filterValue: DeliveryStatus.OnTime, label: 'On Time' },
    { filterValue: DeliveryStatus.InJeopardy, label: 'In Jeopardy' },
    { filterValue: DeliveryStatus.Late, label: 'Late' },
    { filterValue: DeliveryStatus.TrackingLost, label: 'Tracking Lost' },
    { filterValue: DeliveryStatus.TenderAccepted, label: 'Tender Accepted' }
];

export const documentTypeOptions = [
    { value: '1', label: 'Rate Confirmation' },
    { value: '2', label: 'Proof of Delivery' },
    { value: '3', label: 'Bill of Lading' },
    { value: '4', label: 'Invoice' },
    { value: '5', label: 'Freight Damage Documentation' },
    { value: '6', label: 'Truck/Trailer Damage Documentation' },
    { value: '7', label: 'Lumper Receipt' },
    { value: '8', label: 'Delivery Ticket / Receipt / Order' },
    { value: '9', label: 'Insurance' },
    { value: '10', label: 'Weight (Scale) Certificate' },
    { value: '11', label: 'Fine / Ticket' },
    { value: '12', label: 'DOT Inspection' },
    { value: '13', label: 'O/D Permit' },
    { value: '14', label: 'Directions' },
    { value: '15', label: 'Packing Slip' },
    { value: '16', label: 'Check' },
    { value: '17', label: 'Freight Bill' },
    { value: '18', label: 'Log' },
    { value: '19', label: 'Fuel Receipt' },
    { value: '20', label: 'Scale Receipt' },
    { value: '21', label: 'Trip Sheet' },
    { value: '22', label: 'Proof of Performance' },
    { value: '23', label: 'Match' },
    { value: '24', label: 'Other' },
    { value: '37', label: 'Signature Capture' }
];

export const equipmentTypeOptions = [
    { filterValue: EquipmentType.Conestoga, label: 'Conestoga' },
    { filterValue: EquipmentType.Container, label: 'Container' },
    { filterValue: EquipmentType.DryBulk, label: 'Dry Bulk' },
    { filterValue: EquipmentType.Flatbed, label: 'Flatbed' },
    { filterValue: EquipmentType.Other, label: 'Other' },
    { filterValue: EquipmentType.Reefer, label: 'Reefer' },
    { filterValue: EquipmentType.Tanker, label: 'Tanker' },
    { filterValue: EquipmentType.Undeclared, label: 'Undeclared' },
    { filterValue: EquipmentType.Van, label: 'Van' }
];

export const etaIncreasesOrDecreasesOptions = [
    { value: 0, label: 'ETA increases or decreases by' },
    { value: 1, label: 'ETA increases by' }
];

export const hotShipmentOptions = [
    { filterValue: 'True', label: 'Yes' }
];

// FIXME: notifications api uses different values then the headers api
export const notificationHotShipmentOptions = [
    { filterValue: 'Yes', label: 'Yes' },
    { filterValue: 'No', label: 'No' }
];

export const locationSortOptions: LocationsSortOption[] = [
    { label: 'City Name - A to Z', sortColumn: LocationsSortColumns.City, sortDirection: SortDirection.Ascending },
    { label: 'City Name - Z to A', sortColumn: LocationsSortColumns.City, sortDirection: SortDirection.Descending },
    { label: 'Last Update - Newest to Oldest', sortColumn: LocationsSortColumns.LastUpdateDate, sortDirection: SortDirection.Descending },
    { label: 'Last Update - Oldest to Newest', sortColumn: LocationsSortColumns.LastUpdateDate, sortDirection: SortDirection.Ascending },
    { label: 'Location Name - A to Z', sortColumn: LocationsSortColumns.LocationName, sortDirection: SortDirection.Ascending },
    { label: 'Location Name - Z to A', sortColumn: LocationsSortColumns.LocationName, sortDirection: SortDirection.Descending },
    { label: 'Location Code - A to Z', sortColumn: LocationsSortColumns.LocationCode, sortDirection: SortDirection.Ascending },
    { label: 'Location Code - Z to A', sortColumn: LocationsSortColumns.LocationCode, sortDirection: SortDirection.Descending }
];

export const ltlParcelMilestoneOptions = [
    { filterValue: MilestoneType.Delivered, label: 'Delivered' },
    { filterValue: MilestoneType.InTransit, label: 'In Transit' },
    { filterValue: MilestoneType.OutForDelivery, label: 'Out for Delivery' },
    { filterValue: MilestoneType.Pending, label: 'Pending' },
    { filterValue: MilestoneType.PickedUp, label: 'Picked Up' }
];

export const modeTypeOptions = [
    { filterValue: ModeType.Truckload, label: 'Truckload' },
    { filterValue: ModeType.Rail, label: 'Rail / Intermodal' },
    { filterValue: ModeType.LTL, label: 'Less Than Truckload' },
    { filterValue: ModeType.Ocean, label: 'Ocean' },
    { filterValue: ModeType.Parcel, label: 'Parcel' }
];

export const notificationDeliveryStatusChangeOptions = [
    { value: NotificationTypes.DeliveryStatusEarly, label: 'to Early' },
    { value: NotificationTypes.DeliveryStatusInJeopardy, label: 'to In Jeopardy' },
    { value: NotificationTypes.DeliveryStatusLate, label: 'to Late' },
    { value: NotificationTypes.DeliveryStatusLost, label: 'to Tracking Lost' }
];

export const notificationEtaChangeOptions = [
    { value: NotificationTypes.EtaHasChanged, label: 'ETA has changed' },
    { value: NotificationTypes.EtaBaselineHasChanged, label: 'ETA baseline has changed' },
    { value: NotificationTypes.EtaIsSetNumberOfMinutesAway, label: 'My shipment is a set number of minutes away' },
    { value: NotificationTypes.EtaIsSetNumberOfMinutesFromTime, label: 'ETA is before or after shipment times' }
];

// FIXME: notifications api uses different values then the headers api
export const notificationModeTypeOptions = [
    { filterValue: 'TruckLoad', label: 'Truckload' },
    { filterValue: ModeType.Rail, label: 'Rail / Intermodal' },
    { filterValue: ModeType.LTL, label: 'Less Than Truckload' },
    { filterValue: ModeType.Ocean, label: 'Ocean' },
    { filterValue: ModeType.Parcel, label: 'Parcel' }
];

export const notificationShipmentStatusChangeOptions = [
    { value: NotificationTypes.ShipmentStatusEnRoute, label: 'to En Route', displayOnStopLevel: true },
    { value: NotificationTypes.ShipmentStatusAtShipper, label: 'to At Shipper', displayOnStopLevel: true },
    { value: NotificationTypes.ShipmentStatusInTransit, label: 'to In Transit', displayOnStopLevel: true },
    { value: NotificationTypes.ShipmentStatusAtConsignee, label: 'to At Consigneee', displayOnStopLevel: false },
    { value: NotificationTypes.ShipmentStatusDelivered, label: 'to Delivered', displayOnStopLevel: false }
];

export const notificationShipmentStatusOptions = [
    { filterValue: 'Pending', label: 'Pending' },
    { filterValue: 'Dispatched', label: 'Dispatched' },
    { filterValue: 'En Route', label: 'En Route' },
    { filterValue: 'At Shipper', label: 'At Shipper' },
    { filterValue: 'In Transit', label: 'In Transit' },
    { filterValue: 'At Consignee', label: 'At Consignee' },
    { filterValue: 'Delivered', label: 'Delivered' },
    { filterValue: 'Completed', label: 'Completed' },
    { filterValue: 'Cancelled', label: 'Cancelled' },
    { filterValue: 'On Hold', label: 'On Hold' }
];

export const notificationSortOptions: NotificationsSortOption[] = [
    { label: 'Name - A to Z', sortColumn: NotificationsSortColumns.notificationName, sortDirection: SortDirection.Ascending },
    { label: 'Name - Z to A', sortColumn: NotificationsSortColumns.notificationName, sortDirection: SortDirection.Descending },
    { label: 'Last Update - Newest to Oldest', sortColumn: NotificationsSortColumns.lastUpdateDate, sortDirection: SortDirection.Descending },
    { label: 'Last Update - Oldest to Newest', sortColumn: NotificationsSortColumns.lastUpdateDate, sortDirection: SortDirection.Ascending },
    { label: 'Recipient Type - A to Z', sortColumn: NotificationsSortColumns.notificationRecipientType, sortDirection: SortDirection.Ascending },
    { label: 'Recipient Type - Z to A', sortColumn: NotificationsSortColumns.notificationRecipientType, sortDirection: SortDirection.Descending },
    { label: 'Type - A to Z', sortColumn: NotificationsSortColumns.notificationTypeLabel, sortDirection: SortDirection.Ascending },
    { label: 'Type - Z to A', sortColumn: NotificationsSortColumns.notificationTypeLabel, sortDirection: SortDirection.Descending }
];

export const notificationTypeLabelOptions = [
    { value: NotificationTypeLabel.shipmentStatusChange, label: 'Shipment Status Change', displayOnStopLevel: true },
    { value: NotificationTypeLabel.deliveryStatusChange, label: 'Delivery Status Change', displayOnStopLevel: true },
    { value: NotificationTypeLabel.etaChanged, label: 'ETA Change', displayOnStopLevel: true },
    { value: NotificationTypeLabel.orderExceptionAdded, label: 'Order Exception Added', displayOnStopLevel: false },
    { value: NotificationTypeLabel.temperatureOutOfRange, label: 'Temperature Out of Range', displayOnStopLevel: false },
    { value: NotificationTypeLabel.dwellTime, label: 'Dwell Time Alert', displayOnStopLevel: true }
];

export const orderSortOrderList: OrdersSortOption[] = [
    { label: 'Pickup Date - Earliest to Latest', sortColumn: 'pickupScheduleStartDateTime', sortDirection: SortDirection.Ascending },
    { label: 'Pickup Date - Latest to Earliest', sortColumn: 'pickupScheduleStartDateTime', sortDirection: SortDirection.Descending },
    { label: 'Delivery Date - Earliest to Latest', sortColumn: 'deliveryScheduleStartDateTime', sortDirection: SortDirection.Ascending },
    { label: 'Delivery Date - Latest to Earliest', sortColumn: 'deliveryScheduleStartDateTime', sortDirection: SortDirection.Descending },
    { label: 'Delivery Status - On Time to Late', sortColumn: 'deliveryDeliveryStatus', sortDirection: SortDirection.Ascending },
    { label: 'Delivery Status - Late to On Time', sortColumn: 'deliveryDeliveryStatus', sortDirection: SortDirection.Descending },
    { label: 'Shipment Status - Pending to Delivered', sortColumn: 'shipmentStatus', sortDirection: SortDirection.Ascending },
    { label: 'Shipment Status - Delivered to Pending', sortColumn: 'shipmentStatus', sortDirection: SortDirection.Descending },
    { label: 'Last Status Update - Oldest to Newest', sortColumn: 'currentPositionDateTime', sortDirection: SortDirection.Ascending },
    { label: 'Last Status Update - Newest to Oldest', sortColumn: 'currentPositionDateTime', sortDirection: SortDirection.Descending },
    { label: 'Origin City - A to Z', sortColumn: 'pickupCity', sortDirection: SortDirection.Ascending },
    { label: 'Origin City - Z to A', sortColumn: 'pickupCity', sortDirection: SortDirection.Descending },
    { label: 'Delivery City - A to Z', sortColumn: 'deliveryCity', sortDirection: SortDirection.Ascending },
    { label: 'Delivery City - Z to A', sortColumn: 'deliveryCity', sortDirection: SortDirection.Descending }
];

export const shipmentExpirationTimeOptions = [
    { value: 24, label: '1' },
    { value: 36, label: '2' },
    { value: 72, label: '3' },
    { value: 96, label: '4' },
    { value: 120, label: '5' },
    { value: 144, label: '6' },
    { value: 168, label: '7' },
    { value: 192, label: '8' },
    { value: 216, label: '9' },
    { value: 240, label: '10' },
    { value: 264, label: '11' },
    { value: 288, label: '12' },
    { value: 312, label: '13' },
    { value: 336, label: '14' },
    { value: 360, label: '15' },
    { value: 384, label: '16' },
    { value: 408, label: '17' },
    { value: 432, label: '18' },
    { value: 456, label: '19' },
    { value: 480, label: '20' },
    { value: 504, label: '21' }
];

export const shipmentsToHistoryPageTimeOptions = [
    { value: 0, label: 'As soon as it delivers' },
    { value: 12, label: '0.5 day after' },
    { value: 24, label: '1 day after' },
    { value: 36, label: '1.5 days after' },
    { value: 48, label: '2 days after' },
    { value: 72, label: '3 days after' },
    { value: 96, label: '4 days after' },
    { value: 120, label: '5 days after' },
    { value: 144, label: '6 days after' },
    { value: 168, label: '7 days after' },
    { value: 192, label: '8 days after' },
    { value: 216, label: '9 days after' },
    { value: 240, label: '10 days after' },
    { value: 264, label: '11 days after' },
    { value: 288, label: '12 days after' },
    { value: 312, label: '13 days after' },
    { value: 336, label: '14 days after' },
    { value: 360, label: '15 days after' },
    { value: 384, label: '16 days after' },
    { value: 408, label: '17 days after' },
    { value: 432, label: '18 days after' },
    { value: 456, label: '19 days after' },
    { value: 480, label: '20 days after' },
    { value: 504, label: '21 days after' }
];

export const shipmentStatusOptions = [
    { filterValue: ShipmentStatus.Pending, label: 'Pending' },
    { filterValue: ShipmentStatus.Dispatched, label: 'Dispatched' },
    { filterValue: ShipmentStatus.EnRoute, label: 'En Route' },
    { filterValue: ShipmentStatus.AtShipper, label: 'At Shipper' },
    { filterValue: ShipmentStatus.InTransit, label: 'In Transit' },
    { filterValue: ShipmentStatus.AtConsignee, label: 'At Consignee' },
    { filterValue: ShipmentStatus.Delivered, label: 'Delivered' },
    { filterValue: ShipmentStatus.Completed, label: 'Completed' },
    { filterValue: ShipmentStatus.Cancelled, label: 'Cancelled' }
];

export const shipmentSortOrderList: ShipmentSortOption[] = [
    { value: 1, label: 'Pickup Date - Earliest to Latest', sortColumn: 'originScheduleStartDateTime', sortDirection: SortDirection.Ascending },
    { value: 2, label: 'Pickup Date - Latest to Earliest', sortColumn: 'originScheduleStartDateTime', sortDirection: SortDirection.Descending },
    { value: 3, label: 'Delivery Date - Earliest to Latest', sortColumn: 'destinationScheduleStartDateTime', sortDirection: SortDirection.Ascending },
    { value: 4, label: 'Delivery Date - Latest to Earliest', sortColumn: 'destinationScheduleStartDateTime', sortDirection: SortDirection.Descending },
    { value: 5, label: 'Delivery Status - On Time to Late', sortColumn: 'deliveryStatus', sortDirection: SortDirection.Ascending },
    { value: 6, label: 'Delivery Status - Late to On Time', sortColumn: 'deliveryStatus', sortDirection: SortDirection.Descending },
    { value: 7, label: 'Shipment Status - Pending to Delivered', sortColumn: 'shipmentStatus', sortDirection: SortDirection.Ascending },
    { value: 8, label: 'Shipment Status - Delivered to Pending', sortColumn: 'shipmentStatus', sortDirection: SortDirection.Descending },
    { value: 9, label: 'Last Status Update - Oldest to Newest', sortColumn: 'lastStatusUpdate', sortDirection: SortDirection.Ascending },
    { value: 10, label: 'Last Status Update - Newest to Oldest', sortColumn: 'lastStatusUpdate', sortDirection: SortDirection.Descending },
    { value: 11, label: 'Origin City - A to Z', sortColumn: 'originCity', sortDirection: SortDirection.Ascending },
    { value: 12, label: 'Origin City - Z to A', sortColumn: 'originCity', sortDirection: SortDirection.Descending },
    { value: 13, label: 'Delivery City - A to Z', sortColumn: 'destinationCity', sortDirection: SortDirection.Ascending },
    { value: 14, label: 'Delivery City - Z to A', sortColumn: 'destinationCity', sortDirection: SortDirection.Descending },
    { value: 15, label: 'Priority - High to Normal', sortColumn: 'isPriorityShipment', sortDirection: SortDirection.Ascending },
    { value: 16, label: 'Priority - Normal to High', sortColumn: 'isPriorityShipment', sortDirection: SortDirection.Descending },
    { value: 17, label: 'Weather - Minor to Severe', sortColumn: 'weatherSeverity', sortDirection: SortDirection.Ascending },
    { value: 18, label: 'Weather - Severe to Minor', sortColumn: 'weatherSeverity', sortDirection: SortDirection.Descending },
    { value: 19, label: 'Current Position - Oldest to Newest', sortColumn: 'currentPositionDateTime', sortDirection: SortDirection.Ascending },
    { value: 20, label: 'Current Position - Newest to Oldest', sortColumn: 'currentPositionDateTime', sortDirection: SortDirection.Descending }
];

export const viewSortOptions: ViewsSortOption[] = [
    { label: 'View Name - A to Z', sortColumn: 'customViewName', sortDirection: SortDirection.Ascending },
    { label: 'View Name - Z to A', sortColumn: 'customViewName', sortDirection: SortDirection.Descending },
    { label: 'Updated Date - Oldest to Newest', sortColumn: 'lastUpdatedBy', sortDirection: SortDirection.Ascending },
    { label: 'Updated Date - Newest to Oldest', sortColumn: 'lastUpdatedBy', sortDirection: SortDirection.Descending },
    { label: 'Updated By - A to Z', sortColumn: 'lastUpdatedDate', sortDirection: SortDirection.Ascending },
    { label: 'Updated By - Z to A', sortColumn: 'lastUpdatedDate', sortDirection: SortDirection.Descending }
];

export const weatherSeverityOptions = [
    { filterValue: WeatherAlertSeverity.Minor, label: 'Minor' },
    { filterValue: WeatherAlertSeverity.Moderate, label: 'Moderate' },
    { filterValue: WeatherAlertSeverity.Severe, label: 'Severe' }
];
