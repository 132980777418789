import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Card,
    CardContent,
    Grid,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import {
    mdiCircleOutline,
    mdiMapMarker,
    mdiCancel,
    mdiCheckCircleOutline,
    mdiShareVariant
} from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchTripInformationAndRoute, fetchTripHistory } from '../../redux/tripDetails';
import { TripShipmentData } from '../../interfaces/services/trip';
import {
    ApiStatus,
    MilestoneType,
    ShipmentStatus
} from '../../helpers/enums';
import { isShipmentLtl } from '../../helpers/shipmentUtils';
import { handleShareUrlCopy } from '../../helpers/navigationUtils';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import OriginAndDestinationImage from '../../assets/images/maps/markers_origindestination.png';
import IntermediateStopImage from '../../assets/images/maps/markers_intermittentstops.png';
import Hidden from '../layouts/hidden';
import NoTripDetails from '../errors/noTripDetails';
import TripDetailsCard from '../cards/tripDetailsCard';
import TripDetailsCardLoading from '../loaders/cards/tripDetailsCardLoading';
import ShipmentDetailsCard from '../cards/shipmentDetailsCard';
import ShipmentDetailsCardLoading from '../loaders/cards/shipmentDetailsCardLoading';
import MilestoneProgressBar from '../progressBars/milestoneProgressBar';
import MilestoneProgressBarLoading from '../loaders/progressBars/milestoneProgressBarLoading';
import ProgressHistoryTable from '../tables/progressHistoryTable';
import ProgressHistoryTableLoading from '../loaders/tables/progressHistoryTableLoading';
import TripDetailsMap from '../maps/tripDetailsMap';
import ExpansionPanel from '../expansionPanel/expansionPanel';
import ModeBasedProgressBar from '../progressBars/modeBasedProgressBar';
import ShipmentHistoryTabs from '../tabs/shipmentHistoryTabs';
import WeatherAlertsDialog from '../dialogs/weatherAlertsDialog';
import TemperatureHistoryDialog from '../dialogs/temperatureHistoryDialog';
import NotesDialog from '../dialogs/notesDialog';
import DocumentsDialog from '../dialogs/documentsDialog';
import ShipmentPriorityDialog from '../dialogs/shipmentPriorityDialog';
import OrderExceptionsDialog from '../dialogs/orderExceptionsDialog';
import ShipmentActionButton from '../buttons/shipmentActionButton';

interface SimpleDialogState {
    isOpen: boolean;
    shipmentUniqueName: string;
}

interface PriorityDialogState extends SimpleDialogState {
    freightProviderReferenceNumber: string;
}

const classesPrefix = 'tripDetails';

const classes = {
    gridContainer: `${classesPrefix}-gridContainer`,
    fullPageCardWrapper: `${classesPrefix}-fullPageCardWrapper`,
    mapWrapper: `${classesPrefix}-mapWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.gridContainer}`]: {
            marginTop: 0,
            marginBottom: '16px'
        },
        [`& .${classes.fullPageCardWrapper}`]: {
            height: 'calc(100vh - 144px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        [`& .${classes.mapWrapper}`]: {
            height: 'calc(100vh - 485px)',
            minHeight: '380px'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.gridContainer}`]: {
                marginLeft: 0,
                marginRight: '8px',
                maxWidth: 'calc(100vw - 16px)'
            }
        }
    };
});

const TripDetails = ({
    tripIdentifier
}: {
    /** unique identifier for the trip */
    tripIdentifier: string;
}): JSX.Element => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const initialSimpleDialogValues: SimpleDialogState = {
        isOpen: false,
        shipmentUniqueName: ''
    };

    const initialPriorityDialogState: PriorityDialogState = {
        ...initialSimpleDialogValues,
        freightProviderReferenceNumber: ''
    };

    const [weatherAlertsDialogMetadata, setWeatherAlertsDialogMetadata] = useState<SimpleDialogState>(initialSimpleDialogValues);
    const [temperatureExceededBoundsDialogMetadata, setTemperatureExceededBoundsDialogMetadata] = useState<SimpleDialogState>(initialSimpleDialogValues);
    const [notesDialogMetadata, setNotesDialogMetadata] = useState<SimpleDialogState>(initialSimpleDialogValues);
    const [documentsDialogMetadata, setDocumentsDialogMetadata] = useState<SimpleDialogState>(initialSimpleDialogValues);
    const [shipmentPriorityMetadata, setShipmentPriorityMetadata] = useState<PriorityDialogState>(initialPriorityDialogState);
    const [orderExceptionsDialogMetadata, setOrderExceptionsDialogMetadata] = useState<SimpleDialogState>(initialSimpleDialogValues);

    const UrlShortenerApi = useTypedSelector((state) => { return state.availableServices.endpoints.UrlShortenerApi; });
    const shortUrlExpiryDays = useTypedSelector((state) => { return state.organization.organizationPreferences?.shortUrlExpiryDays; });

    const tripInformationStatus = useTypedSelector((state) => { return state.tripDetails.tripInformationStatus; });
    const tripInformation = useTypedSelector((state) => { return state.tripDetails.tripInformation; });

    const tripHistoryStatus = useTypedSelector((state) => { return state.tripDetails.tripHistoryStatus; });
    const tripHistory = useTypedSelector((state) => { return state.tripDetails.tripHistory; });

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const {
        tripId,
        activeShipment,
        tripStatus,
        shipments
    } = tripInformation;

    useEffect((): void => {
        dispatch(fetchTripInformationAndRoute(tripIdentifier));
        dispatch(fetchTripHistory(tripIdentifier));
    }, [dispatch, tripIdentifier]);

    const isFetchingTrip =
        organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading ||
        tripInformationStatus === ApiStatus.Idle || tripInformationStatus === ApiStatus.Loading;

    if ((!isFetchingTrip && tripId !== tripIdentifier) || tripInformationStatus === ApiStatus.Failure) {
        return (
            <StyledGrid container justifyContent='center' alignItems='center' spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={10}>
                    <Card className={classes.fullPageCardWrapper}>
                        <CardContent>
                            <NoTripDetails />
                        </CardContent>
                    </Card>
                </Grid>
            </StyledGrid>
        );
    }

    const renderShipmentCardIcon = (shipment: TripShipmentData): JSX.Element => {
        let cardIcon = mdiCircleOutline;
        if (shipment) {
            if (isShipmentLtl(shipment.modeType)) {
                const { furthest } = shipment.currentMilestoneProgress;
                if (furthest === MilestoneType.PickedUp || furthest === MilestoneType.Delivered) {
                    cardIcon = mdiCheckCircleOutline;
                } else if (furthest === MilestoneType.InTransit || furthest === MilestoneType.OutForDelivery) {
                    cardIcon = mdiMapMarker;
                }
            } else {
                const { shipmentStatus } = shipment;
                if (
                    shipmentStatus === ShipmentStatus.Accepted ||
                    shipmentStatus === ShipmentStatus.Completed ||
                    shipmentStatus === ShipmentStatus.Delivered
                ) {
                    cardIcon = mdiCheckCircleOutline;
                } else if (
                    shipmentStatus === ShipmentStatus.AtConsignee ||
                    shipmentStatus === ShipmentStatus.AtShipper ||
                    shipmentStatus === ShipmentStatus.EnRoute ||
                    shipmentStatus === ShipmentStatus.InTransit
                ) {
                    cardIcon = mdiMapMarker;
                } else if (
                    shipmentStatus === ShipmentStatus.OnHold ||
                    shipmentStatus === ShipmentStatus.Cancelled
                ) {
                    cardIcon = mdiCancel;
                }
            }
        }

        return (
            <Icon
                path={cardIcon}
                size={1}
                color={theme.palette.text.secondary}
            />
        );
    };

    const renderHistoryTable = (shipment: TripShipmentData): JSX.Element => {
        if (isShipmentLtl(shipment.modeType)) {
            return <ProgressHistoryTable shipmentMilestones={shipment.shipmentMilestones} />;
        }

        const shipmentHistory = tripHistory.find((tripHistoryItem) => {
            return tripHistoryItem.shipmentUniqueName === shipment.shipmentUniqueName;
        });

        return (
            <ShipmentHistoryTabs
                isFetching={tripHistoryStatus === ApiStatus.Idle || tripHistoryStatus === ApiStatus.Loading}
                history={shipmentHistory || {
                    organizationPreferredTimeZone: '',
                    changeHistory: [],
                    trackingAndStatusHistory: []
                }}
            />
        );
    };

    const renderShipmentDetailsCards = (): JSX.Element | JSX.Element[] => {
        if (isFetchingTrip) {
            return Array.from(new Array(2)).map((item, index): JSX.Element => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid key={index} item xs={12} md={10} data-qa='shipmentCard-container'>
                        <Card>
                            <CardContent>
                                <ShipmentDetailsCardLoading
                                    showShipmentCardIcon={true}
                                    showShipmentDetailsNavigation={true}
                                />
                                <ModeBasedProgressBar shipment={null} />
                                <ExpansionPanel>
                                    <ProgressHistoryTableLoading numberOfRows={3} />
                                </ExpansionPanel>
                            </CardContent>
                        </Card>
                    </Grid>
                );
            });
        }

        return shipments.map((shipment): JSX.Element => {
            return (
                <Grid key={shipment.shipmentUniqueName} item xs={12} md={10} data-qa='shipmentCard-container'>
                    <Card>
                        <CardContent>
                            <ShipmentDetailsCard
                                shipment={shipment}
                                shipmentCardIcon={renderShipmentCardIcon(shipment)}
                                showShipmentDetailsNavigation
                                actionContainer={
                                    <ShipmentActionButton
                                        title='Share Shipment'
                                        handleClick={(): void => {
                                            handleShareUrlCopy({
                                                urlShortenerApi: UrlShortenerApi,
                                                pageUrl: `/shipmentDetails/${shipment.shipmentUniqueName}`,
                                                token: shipment.singleShipmentSharingToken,
                                                successMessage: 'Shipment share url copied to clipboard.',
                                                shortenedUrlName: `Shipment ${shipment.shipmentUniqueName}`,
                                                expiryDays: shortUrlExpiryDays
                                            });
                                        }}
                                        data-qa='shareShipment-action-open'
                                        mdiIcon={mdiShareVariant}
                                        iconColor={theme.palette.text.secondary}
                                    />
                                }
                                handleWeatherSeverityClick={(): void => {
                                    setWeatherAlertsDialogMetadata({ isOpen: true, shipmentUniqueName: shipment.shipmentUniqueName });
                                }}
                                handleTemperatureExceededBoundsClick={(): void => {
                                    setTemperatureExceededBoundsDialogMetadata({ isOpen: true, shipmentUniqueName: shipment.shipmentUniqueName });
                                }}
                                handleShipmentNotesClick={(): void => {
                                    setNotesDialogMetadata({ isOpen: true, shipmentUniqueName: shipment.shipmentUniqueName });
                                }}
                                handleShipmentDocumentsClick={(): void => {
                                    setDocumentsDialogMetadata({ isOpen: true, shipmentUniqueName: shipment.shipmentUniqueName });
                                }}
                                handleShipmentPriorityClick={(): void => {
                                    setShipmentPriorityMetadata({ isOpen: true, shipmentUniqueName: shipment.shipmentUniqueName, freightProviderReferenceNumber: shipment.freightProviderReferenceNumber });
                                }}
                                handleShipmentExceptionsClick={(): void => {
                                    setOrderExceptionsDialogMetadata({
                                        isOpen: true,
                                        shipmentUniqueName: shipment.shipmentUniqueName
                                    });
                                }}
                            />
                            <ModeBasedProgressBar shipment={shipment} />
                            <ExpansionPanel>
                                {renderHistoryTable(shipment)}
                            </ExpansionPanel>
                        </CardContent>
                    </Card>
                </Grid>
            );
        });
    };

    return (
        <Fragment>
            <StyledGrid container justifyContent='center' spacing={2} className={classes.gridContainer} data-qa='tripDetails-content'>
                <Grid item xs={12} md={10} data-qa='detailsCard-container'>
                    <Card>
                        <CardContent>
                            {
                                isFetchingTrip
                                    ? (
                                        <TripDetailsCardLoading />
                                    ) : (
                                        <TripDetailsCard />
                                    )
                            }
                            <Hidden breakpoint='sm' direction='down'>
                                {
                                    isFetchingTrip
                                        ? (
                                            <MilestoneProgressBarLoading />
                                        ) : (
                                            <MilestoneProgressBar
                                                milestoneProgress={{
                                                    furthest: tripStatus,
                                                    receivedDate: ''
                                                }}
                                                color={getDeliveryStatusColor(activeShipment.deliveryStatus)}
                                            />
                                        )
                                }
                            </Hidden>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={10} data-qa='mapCard-container'>
                    <Card>
                        <CardContent className={classes.mapWrapper}>
                            <TripDetailsMap
                                isFetchingData={isFetchingTrip}
                                isRouteLineVisible={organizationPreferences ? organizationPreferences.showPCMilerRouteByDefaultFlag : true}
                                activeShipment={activeShipment}
                                shipments={shipments}
                                originAndDestinationImage={OriginAndDestinationImage}
                                intermediateStopImage={IntermediateStopImage}
                                paletteMode={paletteMode}
                                isMobile={isMobile}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {renderShipmentDetailsCards()}
            </StyledGrid>

            {
                weatherAlertsDialogMetadata.isOpen && weatherAlertsDialogMetadata.shipmentUniqueName &&
                <WeatherAlertsDialog
                    shipmentUniqueName={weatherAlertsDialogMetadata.shipmentUniqueName}
                    isOpen={weatherAlertsDialogMetadata.isOpen}
                    closeDialog={(): void => {
                        setWeatherAlertsDialogMetadata(initialSimpleDialogValues);
                    }}
                />
            }

            {
                temperatureExceededBoundsDialogMetadata.isOpen && temperatureExceededBoundsDialogMetadata.shipmentUniqueName &&
                <TemperatureHistoryDialog
                    shipmentUniqueName={temperatureExceededBoundsDialogMetadata.shipmentUniqueName}
                    isOpen={temperatureExceededBoundsDialogMetadata.isOpen}
                    closeDialog={(): void => {
                        setTemperatureExceededBoundsDialogMetadata(initialSimpleDialogValues);
                    }}
                />
            }

            {
                notesDialogMetadata.isOpen && notesDialogMetadata.shipmentUniqueName &&
                <NotesDialog
                    shipmentUniqueName={notesDialogMetadata.shipmentUniqueName}
                    isOpen={notesDialogMetadata.isOpen}
                    closeDialog={(): void => {
                        setNotesDialogMetadata(initialSimpleDialogValues);
                    }}
                />
            }

            {
                documentsDialogMetadata.isOpen && documentsDialogMetadata.shipmentUniqueName &&
                <DocumentsDialog
                    shipmentUniqueName={documentsDialogMetadata.shipmentUniqueName}
                    isOpen={documentsDialogMetadata.isOpen}
                    closeDialog={(): void => {
                        setDocumentsDialogMetadata(initialSimpleDialogValues);
                    }}
                />
            }

            {
                shipmentPriorityMetadata.isOpen && shipmentPriorityMetadata.shipmentUniqueName && shipmentPriorityMetadata.freightProviderReferenceNumber &&
                <ShipmentPriorityDialog
                    freightProviderReferenceNumber={shipmentPriorityMetadata.freightProviderReferenceNumber}
                    shipmentUniqueName={shipmentPriorityMetadata.shipmentUniqueName}
                    isOpen={shipmentPriorityMetadata.isOpen}
                    closeDialog={(): void => {
                        setShipmentPriorityMetadata(initialPriorityDialogState);
                    }}
                />
            }

            {
                orderExceptionsDialogMetadata.isOpen && orderExceptionsDialogMetadata.shipmentUniqueName &&
                <OrderExceptionsDialog
                    shipmentUniqueName={orderExceptionsDialogMetadata.shipmentUniqueName}
                    isOpen={orderExceptionsDialogMetadata.isOpen}
                    closeDialog={(): void => {
                        setOrderExceptionsDialogMetadata(initialSimpleDialogValues);
                    }}

                />
            }
        </Fragment>
    );
};

export default TripDetails;
