import {
    GeoJsonTypes,
    Point,
    Feature
} from 'geojson';

import { OrderListData } from '../../../interfaces/services/orders';
import { DeliveryStatus, FreightHaulerIdentifierTypeName } from '../../enums';
import { formatCityRegionPostalCountry } from '../../addressUtils';
import { addSpaceBeforeUppercaseCharacter } from '../../dataUtils';
import { zonedDateTimeToDisplay } from '../../dateUtils';
import { getDeliveryStatusColor } from '../../styleHelpers';
import { MapSources } from '../enums';

/** Interface used for the properties object on the Order List Map Source. */
export interface OrderListMapSourceProperties {
    description: string;
    freightOrderGuid: string;
    deliveryStatus: DeliveryStatus;
    color: string;
}

/** Interface used to define the Order List Map Source. */
export interface OrderListMapSource {
    type: 'geojson';
    data: {
        type: GeoJsonTypes;
        features: Feature<Point, OrderListMapSourceProperties>[];
    };
}

/** Interface used to define the Order List Cluster Map Source. */
export interface OrderListClusterMapSource extends OrderListMapSource {
    cluster: boolean;
    clusterMaxZoom: number;
    clusterRadius: number;
    clusterProperties: {
        [K in DeliveryStatus]: [string, (string | number | (string | string[])[])[]];
    };
}

/**
 * Using a list of orders, it will loop through them and create a geojson feature for each one
 * that contains the HTML for the popup, current location, and the color based on the delivery status.
 * @param orderList List of orders
 * @returns The geojson features for each point on the map
 */
const getOrderCurrentLocationPoints = (orderList: OrderListData[]): Feature<Point, OrderListMapSourceProperties>[] => {
    const orderCurrentLocationPoints = orderList.map((order): Feature<Point, OrderListMapSourceProperties> => {
        let currentLocationLongLat = [0, 0];
        if (order.currentPositionLongitude && order.currentPositionLatitude) {
            currentLocationLongLat = [order.currentPositionLongitude, order.currentPositionLatitude];
        } else if (order.pickupLongitude && order.pickupLatitude) {
            currentLocationLongLat = [order.pickupLongitude, order.pickupLatitude];
        }

        let tractorRow = '';
        if (order.tractorReferenceNumber) {
            tractorRow = `
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Tractor Reference #:</div>
                    <div class='mapTooltipCell'>${order.tractorReferenceNumber}</div>
                </div>
            `;
        }

        let trailerRow = '';
        if (order.trailerReferenceNumber) {
            trailerRow = `
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Trailer Reference #:</div>
                    <div class='mapTooltipCell'>${order.trailerReferenceNumber}</div>
                </div>
            `;
        }

        let scacRow = '';
        if (order.freightHaulerIdentifierTypeName === FreightHaulerIdentifierTypeName.SCACCode && order.freightHaulerIdentifierName !== null) {
            scacRow = `
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>SCAC #:</div>
                    <div class='mapTooltipCell'>${order.freightHaulerIdentifierName}</div>
                </div>
            `;
        }

        const tooltipHtml = `
            <div class='mapTooltip'>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'><strong>Order Number:</strong></div>
                    <div class='mapTooltipCell'><strong>${order.orderNumber}</strong></div>
                </div>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Status:</div>
                    <div class='mapTooltipCell'>
                        ${addSpaceBeforeUppercaseCharacter(order.deliveryDeliveryStatus)}
                    </div>
                </div>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Current Location:</div>
                    <div class='mapTooltipCell'>
                        ${formatCityRegionPostalCountry({ city: order.currentPositionCity, region: order.currentPositionState })}
                    </div>
                </div>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Date/Time:</div>
                    <div class='mapTooltipCell'>${zonedDateTimeToDisplay(order.currentPositionDateTime)}</div>
                </div>
                ${tractorRow}
                ${trailerRow}
                ${scacRow}
            </div>
        `;

        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: currentLocationLongLat
            },
            properties: {
                description: tooltipHtml,
                freightOrderGuid: order.freightOrderGuid,
                deliveryStatus: order.deliveryDeliveryStatus,
                color: getDeliveryStatusColor(order.deliveryDeliveryStatus)
            }
        };
    });

    return orderCurrentLocationPoints;
};

/**
 * Creates the geojson source using the feature points for the order list map.
 * @param orderList List of orders
 * @returns A geojson source to be added to the map
 */
const getMapOrderListSource = (orderList: OrderListData[]): OrderListMapSource => {
    const orderCurrentLocationPoints = getOrderCurrentLocationPoints(orderList);

    const orderListSource: OrderListMapSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: orderCurrentLocationPoints
        }
    };

    return orderListSource;
};

/**
* Adds the order list source to the map if it doesn't already exist
* @param map Instance of your map
* @param orderList List of orders to use in the map source
*/
export const addOrderListSource = (map: any, orderList: OrderListData[]): OrderListMapSource => {
    const orderListSource = getMapOrderListSource(orderList);

    if (map.getSource(MapSources.orderListSource) === undefined) {
        map.addSource(MapSources.orderListSource, orderListSource);
    } else {
        map.getSource(MapSources.orderListSource).setData(orderListSource.data);
    }

    return orderListSource;
};

/**
 * Creates the clustered geojson source using the feature points for the order list map.
 * @param orderList List of orders
 * @returns A geojson source to be added to the map
 */
const getMapOrderListClusterSource = (orderList: OrderListData[]): OrderListClusterMapSource => {
    const orderCurrentLocationPoints = getOrderCurrentLocationPoints(orderList);

    // filters for classifying orders into six categories based on delivery status
    const tenderAccepted = ['==', ['get', 'deliveryStatus'], DeliveryStatus.TenderAccepted];
    const early = ['==', ['get', 'deliveryStatus'], DeliveryStatus.Early];
    const onTime = ['==', ['get', 'deliveryStatus'], DeliveryStatus.OnTime];
    const inJeopardy = ['==', ['get', 'deliveryStatus'], DeliveryStatus.InJeopardy];
    const late = ['==', ['get', 'deliveryStatus'], DeliveryStatus.Late];
    const trackingLost = ['==', ['get', 'deliveryStatus'], DeliveryStatus.TrackingLost];

    const orderListClusterSource: OrderListClusterMapSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: orderCurrentLocationPoints
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 80, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
            // keep separate counts for each delivery status in a cluster
            [DeliveryStatus.TenderAccepted]: ['+', ['case', tenderAccepted, 1, 0]],
            [DeliveryStatus.Early]: ['+', ['case', early, 1, 0]],
            [DeliveryStatus.OnTime]: ['+', ['case', onTime, 1, 0]],
            [DeliveryStatus.InJeopardy]: ['+', ['case', inJeopardy, 1, 0]],
            [DeliveryStatus.Late]: ['+', ['case', late, 1, 0]],
            [DeliveryStatus.TrackingLost]: ['+', ['case', trackingLost, 1, 0]]
        }
    };

    return orderListClusterSource;
};

/**
* Adds the order list cluster source to the map if it doesn't already exist
* @param map Instance of your map
* @param orderList List of orders to use in the map source
*/
export const addOrderListClusterSource = (map: any, orderList: OrderListData[]): OrderListClusterMapSource => {
    const orderListClusterSource = getMapOrderListClusterSource(orderList);

    if (map.getSource(MapSources.orderListClusterSource) === undefined) {
        map.addSource(MapSources.orderListClusterSource, orderListClusterSource);
    } else {
        map.getSource(MapSources.orderListClusterSource).setData(orderListClusterSource.data);
    }

    return orderListClusterSource;
};
