import React, {
    Fragment, useState, useEffect, useCallback
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Grid,
    Paper,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import Endpoints from '../../services/endpoints';
import AuthService from '../../services/authService';
import { useTypedSelector } from '../../redux';
import { fetchShipments, setShipmentListExportStatus } from '../../redux/shipments';
import { fetchUserSettings } from '../../redux/user';
import { fetchDimensionSummaryCounts, fetchShipmentMapLegend, fetchShipmentMapLegendFiltered } from '../../redux/dimensions';
import { updatePageReset } from '../../redux/dialogUpdates';
import { privateRoutes } from '../../routes/appRouteList';
import { AllowedFilterPropertyName, ApiStatus, ShareType, SideSheetType } from '../../helpers/enums';
import { Filter } from '../../interfaces/services/shipment';
import { ShipmentSortOption } from '../../interfaces/componentInterfaces';
import { shipmentSortOrderList } from '../../helpers/hardcodedOptionLists';
import Hidden from '../layouts/hidden';
import ShipmentHeader from '../headers/shipmentHeader';
import CounterTabs from '../tabs/counterTabs';
import SortSelect from '../selects/sortSelect';
import ShipmentCardList from '../lists/shipmentCardList';
import ExportButton from '../buttons/exportButton';
import ListPagination from '../paginations/listPagination';
import SideSheet from '../sideSheets/sideSheet';
import Filters from '../sideSheets/filters';
import UserSettings from '../sideSheets/userSettings';
import Views from '../sideSheets/views';
import ShipmentsMapWrapper from '../maps/shipmentsMapWrapper';
import ShipmentListTable from '../tables/shipmentListTable';

interface StyleProps {
    filtersName: string;
    shareType: ShareType | null;
}

const classesPrefix = 'shipmentsPage';

const classes = {
    wrapper: `${classesPrefix}-wrapper`,
    sortSelectWrapper: `${classesPrefix}-sortSelectWrapper`,
    cardListBody: `${classesPrefix}-cardListBody`,
    paginationWrapper: `${classesPrefix}-paginationWrapper`
};

const StyledPaper = styled(
    Paper,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'filtersName' && prop !== 'shareType'; }
    }
)<StyleProps>(({ filtersName, shareType, theme }) => {
    let subtractHeight = 318;
    if (shareType === ShareType.View) {
        subtractHeight = 251;
    }
    if (filtersName) {
        subtractHeight += 48;
    }

    return {
        [`&.${classes.wrapper}`]: {
            transition: 'width 400ms ease-in-out'
        },
        [`& .${classes.sortSelectWrapper}`]: {
            padding: '8px',
            maxWidth: '400px'
        },
        [`& .${classes.cardListBody}`]: {
            overflow: 'hidden auto',
            height: shareType === ShareType.View ? 'calc(100vh - 220px)' : 'calc(100vh - 291px)',
            borderTop: `2px solid ${theme.palette.divider}`,
            borderBottom: `2px solid ${theme.palette.divider}`
        },
        [`& .${classes.paginationWrapper}`]: {
            flexGrow: 1
        },
        [theme.breakpoints.only('md')]: {
            [`&.${classes.wrapper}`]: {
                height: 'calc(100vh - 165px)'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.wrapper}`]: {
                height: '100%'
            },
            [`& .${classes.cardListBody}`]: {
                height: `calc(100vh - ${subtractHeight}px)`
            }
        }
    };
});

const ShipmentsPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmallDevice = useMediaQuery(theme.breakpoints.only('xs'));
    const isMediumDevice = useMediaQuery(theme.breakpoints.only('md'));

    const [currentSideSheet, setCurrentSideSheet] = useState<SideSheetType | null>(null);
    const [currentCounterTab, setCurrentCounterTab] = useState('AllShipments');
    const [selectedSortOption, setSelectedSortOption] = useState<ShipmentSortOption | null>(null);
    const [transitionHasEnded, setTransitionHasEnded] = useState(false);

    const ShipmentsApiUri = useTypedSelector((state) => { return state.availableServices.endpoints.ShipmentsApi; });

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const shipmentListStatus = useTypedSelector((state) => { return state.shipments.shipmentListStatus; });
    const shipmentList = useTypedSelector((state) => { return state.shipments.shipmentList; });
    const totalRecords = useTypedSelector((state) => { return state.shipments.totalRecords; });
    const activeShipments = useTypedSelector((state) => { return state.shipments.activeShipments; });
    const skip = useTypedSelector((state) => { return state.shipments.skip; });
    const take = useTypedSelector((state) => { return state.shipments.take; });
    const searchTerm = useTypedSelector((state) => { return state.shipments.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.shipments.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.shipments.sortDirection; });
    const filters = useTypedSelector((state) => { return state.shipments.filters; });
    const filtersName = useTypedSelector((state) => { return state.shipments.filtersName; });

    const userSettingsStatus = useTypedSelector((state) => { return state.user.userSettingsStatus; });
    const userSettingsUpdateStatus = useTypedSelector((state) => { return state.user.userSettingsUpdateStatus; });
    const userSettingsCounters = useTypedSelector((state) => { return state.user.userSettings.userSettingsCounters; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    // This effect should run when this page is first rendered (provided it's not a shared view)
    useEffect((): void => {
        if (userSettingsStatus === ApiStatus.Idle) {
            dispatch(fetchUserSettings(shareType));
        }
    }, [shareType, dispatch, userSettingsStatus]);

    // This effect will run when the organization preferences API finishes and will update our local state for the selected sort option
    useEffect((): void => {
        if (organizationPreferencesStatus !== ApiStatus.Idle && organizationPreferencesStatus !== ApiStatus.Loading) {
            const selectedSort = shipmentSortOrderList.find((sortItem): boolean => {
                return sortItem.value === organizationPreferences?.sortOptionFieldType;
            });

            if (selectedSort) {
                setSelectedSortOption(selectedSort);
            } else {
                // if the org preferences don't exist and the service is finished running, then set the local state var for sort to be a default option.
                setSelectedSortOption(shipmentSortOrderList[9]);
            }
        }
    }, [organizationPreferencesStatus, organizationPreferences]);

    // only fetch map legend data if on the shipment overview page when map is present
    useEffect((): void => {
        if (!isMobile && location.pathname === privateRoutes.shipmentsOverview) {
            dispatch(fetchShipmentMapLegend());
            dispatch(fetchShipmentMapLegendFiltered(filters));
        }
    }, [isMobile, location, filters, dispatch]);

    // This effect will dispatch the shipments API call if we are on the card view and will wait on the organization preferences to finish
    // so that the sort preference can be used in redux.
    // This will also wait on the user settings api to finish so that it can use the default view that is set in the filters - provided that we are not on a shared view
    // because shared views won't call the user settings api. If it is a shared view, we don't care about the user settings api status.
    useEffect((): void => {
        if (location.pathname === privateRoutes.shipmentsOverview) {
            if (organizationPreferencesStatus !== ApiStatus.Idle && organizationPreferencesStatus !== ApiStatus.Loading &&
                ((shareType !== ShareType.View && userSettingsStatus !== ApiStatus.Idle && userSettingsStatus !== ApiStatus.Loading) ||
                    shareType === ShareType.View)) {
                dispatch(fetchShipments({ activeShipments: true, filters }));
            }
        }
        setCurrentCounterTab('AllShipments'); // reset the counter tab when this effect runs
    }, [location, organizationPreferencesStatus, userSettingsStatus, shareType, filters, dispatch]);

    // This effect will dispatch the shipments API call if we are on the active or history pages
    useEffect((): void => {
        if (location.pathname === privateRoutes.shipmentsActive || location.pathname === privateRoutes.shipmentsHistory) {
            const isActive = location.pathname !== privateRoutes.shipmentsHistory;
            dispatch(fetchShipments({ activeShipments: isActive, filters }));
        }
        setCurrentCounterTab('AllShipments'); // reset the counter tab when this effect runs
    }, [location, filters, dispatch]);

    // when the redux search term changes, reset the counter tab
    useEffect(() => {
        setCurrentCounterTab('AllShipments'); // reset the counter tab when this effect runs
    }, [searchTerm]);

    // This effect will dispatch the fetchDimensionSummaryCounts API call once the User Settings API has completed.
    useEffect((): void => {
        if (userSettingsStatus !== ApiStatus.Idle && userSettingsStatus !== ApiStatus.Loading && userSettingsUpdateStatus !== ApiStatus.Loading) {
            const selectedCounters = userSettingsCounters.filter((counter): boolean => {
                return counter.visible === true;
            });

            const isActive = location.pathname !== privateRoutes.shipmentsHistory;
            dispatch(fetchDimensionSummaryCounts({
                userSettingsCounters: selectedCounters,
                activeShipments: isActive,
                filters
            }));
        }
    }, [
        location,
        userSettingsStatus,
        userSettingsUpdateStatus,
        userSettingsCounters,
        filters,
        currentCounterTab, // extraneous dependency needed to update the counter counts when this changes because we will call the shipment api again
        sortColumn, // extraneous dependency needed to update the counter counts when this changes because we will call the shipment api again
        sortDirection, // extraneous dependency needed to update the counter counts when this changes because we will call the shipment api again
        skip, // extraneous dependency needed to update the counter counts when this changes because we will call the shipment api again
        dispatch
    ]);

    const combineCountersAndFilters = useCallback((counterTab: string): Filter[] => {
        const combinedFilters: Filter[] = [];

        // find the counter attributes for the selected tab
        const counterAttributes = userSettingsCounters.find((counter): boolean => {
            return counter.key === counterTab;
        });

        // if the counter attributes exist, determine what the new filters should be
        if (counterAttributes) {
            const isFilterSelected = filters.some((filter): boolean => {
                return filter.propertyName.toLowerCase() === counterAttributes.fieldName.toLowerCase();
            });

            if (isFilterSelected) {
                filters.forEach((filter): void => {
                    // if the filter already exists, replace the propertyValues with the select counter attributes
                    if (filter.propertyName.toLowerCase() === counterAttributes.fieldName.toLowerCase()) {
                        combinedFilters.push({
                            propertyName: counterAttributes.fieldName as AllowedFilterPropertyName,
                            propertyValues: counterAttributes.fieldValue || []
                        });
                    } else {
                        // otherwise, push the filter as is
                        combinedFilters.push(filter);
                    }
                });
            } else {
                // if the filter didn't already exist in the list, push the selected counter attributes as a filter
                combinedFilters.push({
                    propertyName: counterAttributes.fieldName as AllowedFilterPropertyName,
                    propertyValues: counterAttributes.fieldValue || []
                });
                // and push the selected filters
                combinedFilters.push(...filters);
            }
        } else {
            // the tab selected was "AllShipments", so just push the filters as is for the API call
            combinedFilters.push(...filters);
        }

        return combinedFilters;
    }, [filters, userSettingsCounters]);

    // This effect will run after the shipments, org preferences, and user settings requests complete and will set a rough timer to refresh the shipments list
    // based on the organization preferences
    useEffect((): () => void => {
        let interval: ReturnType<typeof setInterval> | null = null;
        if (organizationPreferencesStatus !== ApiStatus.Idle && organizationPreferencesStatus !== ApiStatus.Loading &&
            shipmentListStatus !== ApiStatus.Idle && shipmentListStatus !== ApiStatus.Loading &&
            userSettingsStatus !== ApiStatus.Idle && userSettingsStatus !== ApiStatus.Loading && userSettingsUpdateStatus !== ApiStatus.Loading) {
            if (organizationPreferences !== null && organizationPreferences.autoRefreshMinutes > 0) {
                const selectedCounters = userSettingsCounters.filter((counter): boolean => {
                    return counter.visible === true;
                });
                const isActive = location.pathname !== privateRoutes.shipmentsHistory;
                interval = setInterval(() => {
                    // only fetch map legend data if on the shipment overview page when map is present
                    if (!isMobile && location.pathname === privateRoutes.shipmentsOverview) {
                        dispatch(fetchShipmentMapLegend());
                        dispatch(fetchShipmentMapLegendFiltered(filters));
                    }
                    dispatch(fetchDimensionSummaryCounts({
                        userSettingsCounters: selectedCounters,
                        activeShipments: isActive,
                        filters,
                        isAutoRefreshRequest: true
                    }));
                    dispatch(fetchShipments({
                        activeShipments: isActive,
                        filters: combineCountersAndFilters(currentCounterTab),
                        take,
                        searchTerm,
                        sortColumn,
                        sortDirection,
                        skip,
                        isAutoRefreshRequest: true
                    }));
                }, organizationPreferences.autoRefreshMinutes * 60000);
            }
        }
        return (): void => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };
    }, [
        isMobile,
        location,
        organizationPreferences,
        userSettingsCounters,
        userSettingsStatus,
        userSettingsUpdateStatus,
        organizationPreferencesStatus,
        shipmentListStatus,
        filters,
        skip,
        take,
        searchTerm,
        sortColumn,
        sortDirection,
        currentCounterTab,
        combineCountersAndFilters,
        dispatch
    ]);

    // any time the transition ends toggle the state to update child components that may be dependent on the transition ending such as the <ShipmentsMap /> resize
    const handleTransitionEnd = (event: any): void => {
        // we only care about the shipmentsPage transition
        if (event.target.id !== 'shipmentsPage') {
            return;
        }

        setTransitionHasEnded(true);
    };

    const handleExportClick = async (): Promise<void> => {
        try {
            // use a web worker to do the export in a separate thread
            const exportWorker = new Worker(`${window.location.origin}/exportWorker.js`);
            dispatch(setShipmentListExportStatus(true));
            // user can navigate anywhere within TUI and the webworker will still work. Leaving TUI kills the web worker however
            toast.info('Download in progress - Please do not navigate to Shipments Legacy or Locations until download completes.');

            // Check that the user exists prior to making the request
            const user = await AuthService.getUser();
            const token = user?.access_token || AuthService.sharingToken;

            const exportWorkerMetadata = {
                body: {
                    skip: 0,
                    take: 500,
                    activeShipments,
                    sortColumn,
                    sortDirection,
                    searchTerm,
                    filters: combineCountersAndFilters(currentCounterTab)
                },
                maxRows: 10_000,
                url: `${ShipmentsApiUri}${Endpoints.shipmentApi.headers}`,
                token
            };

            // post message to web worker
            exportWorker.postMessage(exportWorkerMetadata);

            // message from web worker
            exportWorker.onmessage = (event: any): void => {
                dispatch(setShipmentListExportStatus(false));

                const downloadDocument = (): void => {
                    const currentDate = format(new Date(), 'MM_dd_yy_HHmmss');
                    const fileNamePrefix = location.pathname === privateRoutes.shipmentsHistory ? 'Historic' : 'Active';
                    const blob = new Blob([event.data.csv as string], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    link.setAttribute('href', URL.createObjectURL(blob));
                    link.setAttribute('download', `${fileNamePrefix}Shipments_${currentDate}.csv`);
                    link.style.display = 'none';
                    document.body.append(link);
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(link.href);
                };

                if (event.data.error) {
                    if (event.data.csv.length === 0) {
                        toast.error('Failed to export shipments.');
                    } else if (event.data.error.toString().includes('limitReachedError')) {
                        if (event.data.totalPossibleRecords >= exportWorkerMetadata.maxRows) {
                            toast.warning(`Only the first ${Number(exportWorkerMetadata.maxRows).toLocaleString()} rows have been fetched - either restrict data further using filters, or contact support for additional options.`);
                        }
                        downloadDocument();
                    } else {
                        toast.warning('Export encountered an error. Shipment data may be incomplete.');
                        downloadDocument();
                    }
                } else {
                    downloadDocument();
                }
            };

            // handle worker error
            exportWorker.onerror = (): void => {
                dispatch(setShipmentListExportStatus(false));
                toast.error('Shipments export failed.');
            };
        } catch (error) {
            console.error('worker failed', error);
            toast.error('Unable to export shipments.');
        }
    };

    const renderPagination = (): JSX.Element => {
        return (
            <Grid
                container
                alignItems='center'
                justifyContent='space-between'
            >
                {
                    !isMobile &&
                    <Grid item>
                        <ExportButton
                            isFetchingData={shipmentListStatus === ApiStatus.Idle || shipmentListStatus === ApiStatus.Loading}
                            handleExportClick={handleExportClick}
                        />
                    </Grid>
                }
                <Grid item className={classes.paginationWrapper}>
                    <ListPagination
                        isFetchingData={shipmentListStatus === ApiStatus.Idle || shipmentListStatus === ApiStatus.Loading}
                        skip={skip}
                        take={take}
                        loadedRecordsCount={shipmentList.length}
                        totalRecordsCount={totalRecords}
                        showPaginationTotals={!isExtraSmallDevice && !isMediumDevice}
                        showFirstButton={!isMobile}
                        showLastButton={!isMobile}
                        handlePaginationChange={(newSkip: number): void => {
                            dispatch(fetchShipments({
                                activeShipments,
                                skip: newSkip,
                                take,
                                searchTerm,
                                sortColumn,
                                sortDirection,
                                filters: combineCountersAndFilters(currentCounterTab)
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const renderShipmentsPageBody = (): JSX.Element => {
        if (location.pathname === privateRoutes.shipmentsActive || location.pathname === privateRoutes.shipmentsHistory) {
            return (
                <Fragment>
                    <ShipmentListTable />
                    {renderPagination()}
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Grid item xs={12} md={6} xl={5}>
                    <div className={classes.sortSelectWrapper} data-qa='shipmentSort-container'>
                        <SortSelect
                            id='shipmentSort'
                            isDisabled={organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading}
                            selectedValue={selectedSortOption || shipmentSortOrderList[9]}
                            availableOptions={shipmentSortOrderList}
                            handleChange={(newValue): void => {
                                setSelectedSortOption(newValue);

                                dispatch(fetchShipments({
                                    activeShipments,
                                    searchTerm,
                                    sortColumn: newValue.sortColumn,
                                    sortDirection: newValue.sortDirection,
                                    filters: combineCountersAndFilters(currentCounterTab)
                                }));
                            }}
                        />
                    </div>
                    <div className={classes.cardListBody} data-qa='shipmentCards-container'>
                        <ShipmentCardList />
                    </div>
                    {renderPagination()}
                </Grid>

                <Hidden breakpoint='md' direction='down'>
                    <Grid item xs={6} xl={7}>
                        <ShipmentsMapWrapper resize={transitionHasEnded} />
                    </Grid>
                </Hidden>
            </Fragment>
        );
    };

    const sideSheetIsOpen = currentSideSheet !== null;
    let paperStyle: React.CSSProperties = { width: 'auto' };
    if (isMobile) {
        paperStyle = { width: 'auto' };
    } else if (sideSheetIsOpen) {
        paperStyle = { width: 'calc(100% - 331px)' };
    }

    return (
        <Fragment>
            <ShipmentHeader
                currentSideSheet={currentSideSheet}
                handleCurrentSideSheetChange={(newSideSheet: SideSheetType): void => {
                    setTransitionHasEnded(false);
                    if (currentSideSheet === SideSheetType.Filters) {
                        // since saving a view triggers a "dialog" update, reset the status here when the side sheet is closed.
                        dispatch(updatePageReset());
                    }
                    if (newSideSheet === currentSideSheet) {
                        setCurrentSideSheet(null);
                    } else {
                        setCurrentSideSheet(newSideSheet);
                    }
                }}
            />
            <StyledPaper
                id='shipmentsPage'
                square
                filtersName={filtersName}
                shareType={shareType}
                className={classes.wrapper}
                style={paperStyle}
                onTransitionEnd={handleTransitionEnd}
                data-qa='shipmentsPage-container'
            >
                <Grid container>
                    {
                        shareType !== ShareType.View &&
                        <Grid item xs={12} data-qa='counters-container'>
                            <CounterTabs
                                currentTab={currentCounterTab}
                                handleTabChange={(newTab): void => {
                                    setCurrentCounterTab(newTab);

                                    dispatch(fetchShipments({
                                        activeShipments,
                                        searchTerm,
                                        sortColumn,
                                        sortDirection,
                                        filters: combineCountersAndFilters(newTab)
                                    }));
                                }}
                            />
                        </Grid>
                    }

                    {renderShipmentsPageBody()}
                </Grid>
            </StyledPaper>

            {
                currentSideSheet === SideSheetType.Filters &&
                <SideSheet
                    open={currentSideSheet === SideSheetType.Filters}
                    closeSideSheet={(): void => {
                        setCurrentSideSheet(null);
                        // since saving a view triggers a "dialog" update, reset the status here when the side sheet is closed.
                        dispatch(updatePageReset());
                    }}
                >
                    <Filters />
                </SideSheet>
            }

            {
                currentSideSheet === SideSheetType.Views &&
                <SideSheet
                    open={currentSideSheet === SideSheetType.Views}
                    closeSideSheet={(): void => {
                        setCurrentSideSheet(null);
                    }}
                >
                    <Views />
                </SideSheet>
            }

            {
                currentSideSheet === SideSheetType.UserSettings &&
                <SideSheet
                    open={currentSideSheet === SideSheetType.UserSettings}
                    closeSideSheet={(): void => {
                        setCurrentSideSheet(null);
                    }}
                >
                    <UserSettings />
                </SideSheet>
            }
        </Fragment>
    );
};

export default ShipmentsPage;
