import React from 'react';
import { styled } from '@mui/material/styles';

interface StyleProps {
    percentage: number;
    color: string;
}

const classesPrefix = 'progressBar';

const classes = {
    progressBar: `${classesPrefix}-progressBar`,
    progressBarFiller: `${classesPrefix}-progressBarFiller`,
    progressBarText: `${classesPrefix}-progressBarText`
};

const StyledDiv = styled(
    'div',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => {
            return prop !== 'percentage' && prop !== 'color';
        }
    }
)<StyleProps>(({ percentage, color, theme }) => {
    return {
        [`&.${classes.progressBar}`]: {
            position: 'relative',
            height: '30px',
            width: '100%',
            borderRadius: '4px',
            border: `1px solid ${theme.appColors.progressDefaultBackground}`,
            backgroundColor: color === 'transparent' ? color : theme.appColors.progressDefaultBackground
        },
        [`& .${classes.progressBarFiller}`]: {
            borderRadius: '4px',
            height: '100%',
            position: 'relative',
            width: `${percentage}%`,
            backgroundColor: color
        },
        [`& .${classes.progressBarText}`]: {
            lineHeight: '30px',
            position: 'absolute',
            color: theme.palette.common.white,
            margin: 0,
            ...percentage > 10 ? { right: '10px' } : { left: '5px' }
        }
    };
});

const ProgressBar = ({
    percentage,
    color,
    title
}: {
    percentage: number;
    color: string;
    title: string;
}): JSX.Element => {
    return (
        <StyledDiv percentage={percentage} color={color} className={classes.progressBar} title={title}>
            <div className={classes.progressBarFiller}>
                {
                    percentage > 0 &&
                    <p className={classes.progressBarText}>{`${Math.floor(percentage)}%`}</p>
                }
            </div>
        </StyledDiv>
    );
};

export default ProgressBar;
