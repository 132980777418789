import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { fetchOrdersList, updateOrdersFilters } from '../../redux/orders';
import { ApplicationResourceNames, SideSheetType } from '../../helpers/enums';
import HeaderSearchInput from '../inputs/headerSearchInput';
import PageViewButtons from '../actionBars/pageViewButtons';
import SideSheetButtons from '../actionBars/sideSheetButtons';
import FilterNameChip from '../chips/filterNameChip';

const classesPrefix = 'orderHeader';

const classes = {
    wrapper: `${classesPrefix}-wrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.wrapper}`]: {
            backgroundColor: theme.palette.primary.main,
            minHeight: '68px',
            padding: '0 24px'
        },
        [theme.breakpoints.only('md')]: {
            [`&.${classes.wrapper}`]: {
                justifyContent: 'space-between'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.wrapper}`]: {
                padding: '16px 16px 0 16px'
            }
        }
    };
});

const OrderHeader = ({
    currentSideSheet,
    handleCurrentSideSheetChange
}: {
    /** Current selected side sheet */
    currentSideSheet: SideSheetType | null;
    /** Function to handle the side sheet change. */
    handleCurrentSideSheetChange: (currentSideSheet: SideSheetType) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const activeOrders = useTypedSelector((state) => { return state.orders.activeOrders; });
    const sortColumn = useTypedSelector((state) => { return state.orders.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.orders.sortDirection; });
    const filters = useTypedSelector((state) => { return state.orders.filters; });
    const filtersName = useTypedSelector((state) => { return state.orders.filtersName; });

    const userInformation = useTypedSelector((state) => { return state.user.userInformation; });

    const pageViewList = userInformation.userApplications.transportationUi.filter((app) => {
        return app.resourceKey === ApplicationResourceNames.OrdersOverview ||
            app.resourceKey === ApplicationResourceNames.OrdersListActive;
    });

    return (
        <StyledGrid container alignItems='center' className={classes.wrapper} data-qa='orderHeader-container'>
            <HeaderSearchInput
                id='orderSearch'
                inputLabel='Search Orders'
                searchTerm=''
                handleSearchTermSubmit={(submittedSearchTerm: string): void => {
                    dispatch(fetchOrdersList({
                        activeOrders,
                        searchTerm: submittedSearchTerm,
                        sortColumn,
                        sortDirection,
                        filters
                    }));
                }}
            />

            <PageViewButtons pageViewList={pageViewList} />

            {
                filtersName &&
                <FilterNameChip
                    filterName={filtersName}
                    handleChipDelete={(): void => {
                        // reset the filters in redux
                        dispatch(updateOrdersFilters([]));
                    }}
                />
            }

            <SideSheetButtons
                currentSideSheet={currentSideSheet}
                handleCurrentSideSheetChange={handleCurrentSideSheetChange}
            />
        </StyledGrid>
    );
};

export default OrderHeader;
