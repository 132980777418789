import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { mdiWeatherLightningRainy } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchShipmentDetailsAndRoute, fetchShipmentWeather } from '../../redux/shipmentDetails';
import { ApiStatus } from '../../helpers/enums';
import OriginAndDestinationImage from '../../assets/images/maps/markers_origindestination.png';
import IntermediateStopImage from '../../assets/images/maps/markers_intermittentstops.png';
import CommonDialog from './common/commonDialog';
import ShipmentDetailsMap from '../maps/shipmentDetailsMap';

const classesPrefix = 'weatherAlertsDialog';

const classes = {
    mapWrapper: `${classesPrefix}-mapWrapper`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.mapWrapper}`]: {
            maxWidth: '100%'
        }
    };
});

const WeatherAlertsDialog = ({
    shipmentUniqueName,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showWeatherAlerts, setShowWeatherAlerts] = useState(true);

    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });

    const weatherAlerts = useTypedSelector((state) => { return state.shipmentDetails.weatherAlerts; });
    const weatherPolygons = useTypedSelector((state) => { return state.shipmentDetails.weatherPolygons; });

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    useEffect((): void => {
        // only fetch if we don't already have the details in redux
        if (shipmentUniqueName !== shipment.shipmentUniqueName) {
            dispatch(fetchShipmentDetailsAndRoute(shipmentUniqueName));
            dispatch(fetchShipmentWeather(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipment.shipmentUniqueName, dispatch]);

    useEffect((): void => {
        // only fetch if we refreshed and cleared our shipment status back to idle
        if (shipmentStatus === ApiStatus.Idle) {
            dispatch(fetchShipmentDetailsAndRoute(shipmentUniqueName));
            dispatch(fetchShipmentWeather(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipmentStatus, dispatch]);

    const isFetchingShipment =
        organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading ||
        shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading;

    // Don't allow the dialog to close if the API's are still running
    const handleClose = (): void => {
        if (!isFetchingShipment) {
            closeDialog();
        }
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <StyledDiv className={classes.mapWrapper}>
                <ShipmentDetailsMap
                    showMapControlBar={true}
                    isFetchingData={isFetchingShipment}
                    isRouteLineVisible={organizationPreferences ? organizationPreferences.showPCMilerRouteByDefaultFlag : true}
                    shipment={shipment}
                    isWeatherAlertsVisible={showWeatherAlerts}
                    weatherAlerts={weatherAlerts}
                    weatherPolygons={weatherPolygons}
                    handleWeatherAlertsClick={(): void => {
                        setShowWeatherAlerts(!showWeatherAlerts);
                    }}
                    originAndDestinationImage={OriginAndDestinationImage}
                    intermediateStopImage={IntermediateStopImage}
                    paletteMode={paletteMode}
                    isMobile={isMobile}
                />
            </StyledDiv>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='xl'
            headerIcon={mdiWeatherLightningRainy}
            headerText='Weather Alerts'
            content={renderDialogContent()}
            actions={(
                <Button
                    disabled={isFetchingShipment}
                    onClick={handleClose}
                    data-qa='close-button'
                >
                    Close
                </Button>
            )}
        />
    );
};

export default WeatherAlertsDialog;
