import React, { Fragment, useState } from 'react';
import {
    Grid,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { OrderListData } from '../../interfaces/services/orders';
import { determineDisplayDates } from '../../helpers/shipmentUtils';
import Hidden from '../layouts/hidden';
import LabelValuePair from '../labels/labelValuePair';
import DeliveryStatusIndicator from '../labels/deliveryStatusIndicator';
import StopAddress from '../labels/stopAddress';
import StopContactInfo from '../buttons/stopContactInfo';
import DeliveryStatusTooltip from '../tooltips/deliveryStatusTooltip';
import OrderDetailsDialog from '../dialogs/orderDetailsDialog';
import OrderDetailsLabelLink from '../links/orderDetailsLabelLink';

const classesPrefix = 'orderCard';

const classes = {
    cardContainer: `${classesPrefix}-cardContainer`,
    cardWrapper: `${classesPrefix}-cardWrapper`,
    statusLabel: `${classesPrefix}-statusLabel`,
    addressContainer: `${classesPrefix}-addressContainer`,
    stopTitle: `${classesPrefix}-stopTitle`,
    stopTypeContainer: `${classesPrefix}-stopTypeContainer`,
    deliveryStatusWrapper: `${classesPrefix}-deliveryStatusWrapper`,
    sectionsContainer: `${classesPrefix}-sectionsContainer`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.cardContainer}`]: {
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:first-of-type': {
                borderTop: 0
            },
            '&:last-of-type': {
                borderBottom: 0
            }
        },
        [`& .${classes.cardWrapper}`]: {
            padding: '8px 16px'
        },
        [`& .${classes.statusLabel}`]: {
            marginTop: '8px',
            fontSize: '14px',
            fontWeight: 600
        },
        [`& .${classes.addressContainer}`]: {
            margin: '4px 0'
        },
        [`& .${classes.stopTitle}`]: {
            fontWeight: 600,
            fontSize: '12px'
        },
        [`& .${classes.stopTypeContainer}`]: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '8px'
        },
        [`& .${classes.deliveryStatusWrapper}`]: {
            marginTop: '8px',
            alignItems: 'center'
        },
        [`& .${classes.sectionsContainer}`]: {
            marginTop: '8px'
        }
    };
});

const OrderCard = ({
    order
}: {
    order: OrderListData;
}): JSX.Element => {
    const [orderDetailsDialogIsOpen, setOrderDetailsDialogIsOpen] = useState(false);
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const pickupDisplayDateList = organizationPreferences ?
        determineDisplayDates(
            organizationPreferences.preferredStopTimeSourceType,
            organizationPreferences.showEta,
            order.pickupGeofenceDepartureDateTime,
            order.pickupCarrierDepartureDateTime,
            order.pickupGeofenceArrivalDateTime,
            order.pickupCarrierArrivalDateTime,
            order.pickupStopEta
        ) : [];

    const deliveryDisplayDateList = organizationPreferences ?
        determineDisplayDates(
            organizationPreferences.preferredStopTimeSourceType,
            organizationPreferences.showEta,
            order.deliveryGeofenceDepartureDateTime,
            order.deliveryCarrierDepartureDateTime,
            order.deliveryGeofenceArrivalDateTime,
            order.deliveryCarrierArrivalDateTime,
            order.deliveryStopEta
        ) : [];

    const renderStopDates = (orderType: 'Pickup' | 'Delivery', dateList: { label: string; value: string | null; }[]): JSX.Element[] => {
        return dateList.map((dateObject): JSX.Element => {
            const alphanumericLabel = dateObject.label.replace(/[^A-Z0-9]+/ig, '');
            if (dateObject.value) {
                return <LabelValuePair key={alphanumericLabel} label={dateObject.label} value={dateObject.value} fontSize='12px' data-qa={`${orderType.toLowerCase()}${alphanumericLabel}`} />;
            }
            return <Fragment key={alphanumericLabel} />;
        });
    };

    return (
        <Fragment>
            <StyledGrid
                container
                className={classes.cardContainer}
                data-qa='orderCard-container'
            >
                <Grid item xs={12} className={classes.cardWrapper}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <OrderDetailsLabelLink
                                label='Order Number'
                                freightOrderGuid={order.freightOrderGuid}
                                orderNumber={order.orderNumber}
                                noWrap
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValuePair
                                noWrap
                                label='Carrier'
                                value={order.carrierName}
                                data-qa='carrierName'
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems='center' className={classes.sectionsContainer}>
                        <Grid item xs={12} sm={6}>
                            <LabelValuePair label='Mode' value={order.modeType} data-qa='mode' />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.deliveryStatusWrapper}>
                            <DeliveryStatusIndicator
                                deliveryStatus={order.deliveryDeliveryStatus}
                            />
                            {
                                order.currentPositionDateTime &&
                                <DeliveryStatusTooltip
                                    timestamp={order.currentPositionDateTime}
                                    currentCity={order.currentPositionCity}
                                    currentState={order.currentPositionState}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Typography className={classes.statusLabel} data-qa='orderStatus'>
                        {order.shipmentStatus}
                    </Typography>

                    <Grid container className={classes.addressContainer}>
                        <Grid item xs={12} sm={6} data-qa='pickup-container'>
                            <div className={classes.stopTypeContainer}>
                                <Typography className={classes.stopTitle} data-qa='pickup-label'>Pickup</Typography>
                                {
                                    (order.pickupContactName || order.pickupContactPhoneNumber || order.pickupContactEmailAddress) &&
                                    <StopContactInfo
                                        contactName={order.pickupContactName}
                                        contactPhone={order.pickupContactPhoneNumber}
                                        contactEmail={order.pickupContactEmailAddress}
                                    />
                                }
                            </div>

                            <StopAddress
                                description={null}
                                location={order.pickupFixedLocationCode}
                                name={order.pickupStopName}
                                street1={order.pickupStreet1}
                                street2={order.pickupStreet2}
                                city={order.pickupCity}
                                region={order.pickupState}
                                postal={order.pickupZip}
                                country={null}
                                modeType={order.modeType}
                            />
                            <Hidden breakpoint='sm' direction='up'>
                                {renderStopDates('Pickup', pickupDisplayDateList)}
                            </Hidden>
                        </Grid>

                        <Grid item xs={12} sm={6} data-qa='delivery-container'>
                            <div className={classes.stopTypeContainer}>
                                <Typography className={classes.stopTitle} data-qa='delivery-label'>Delivery</Typography>
                                {
                                    (order.deliveryContactName || order.deliveryContactPhoneNumber || order.deliveryContactEmailAddress) &&
                                    <StopContactInfo
                                        contactName={order.deliveryContactName}
                                        contactPhone={order.deliveryContactPhoneNumber}
                                        contactEmail={order.deliveryContactEmailAddress}
                                    />
                                }
                            </div>

                            <StopAddress
                                description={null}
                                location={order.deliveryFixedLocationCode}
                                name={order.deliveryStopName}
                                street1={order.deliveryStreet1}
                                street2={order.deliveryStreet2}
                                city={order.deliveryCity}
                                region={order.deliveryState}
                                postal={order.deliveryZip}
                                country={null}
                                modeType={order.modeType}
                            />
                            <Hidden breakpoint='sm' direction='up'>
                                {renderStopDates('Delivery', deliveryDisplayDateList)}
                            </Hidden>
                        </Grid>
                    </Grid>

                    <Hidden breakpoint='xs'>
                        <Grid container className={classes.sectionsContainer}>
                            <Grid item xs={6}>
                                {renderStopDates('Pickup', pickupDisplayDateList)}
                            </Grid>
                            <Grid item xs={6}>
                                {renderStopDates('Delivery', deliveryDisplayDateList)}
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </StyledGrid>
            {
                orderDetailsDialogIsOpen &&
                <OrderDetailsDialog
                    orderGuid={order.freightOrderGuid}
                    handleDialogClose={(): void => {
                        setOrderDetailsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default OrderCard;
