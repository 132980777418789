import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Tooltip,
    IconButton,
    Menu
} from '@mui/material';
import Icon from '@mdi/react';
import {
    mdiCog as settingsIcon,
    mdiAt as supportIcon,
    mdiHelpCircleOutline as faqIcon,
    mdiLogoutVariant as logoutIcon,
    mdiAccountCircle
} from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { setUserLoginStatus } from '../../redux/user';
import { ApiStatus, ApplicationResourceNames } from '../../helpers/enums';
import AuthService from '../../services/authService';
import AppHeaderMenuItemLoading from '../loaders/headers/appHeaderMenuItemLoading';
import AppHeaderMenuItem from './appHeaderMenuItem';
import LogOutButton from '../buttons/logOutButton';

const AppHeaderAccountMenu = (): JSX.Element => {
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const userInformationStatus = useTypedSelector((state) => { return state.user.userInformationStatus; });
    const userInformation = useTypedSelector((state) => { return state.user.userInformation; });

    const renderMenuItems = (): JSX.Element[] => {
        if (userInformationStatus === ApiStatus.Idle || userInformationStatus === ApiStatus.Loading) {
            return Array.from(new Array(4)).map((item, index): JSX.Element => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <AppHeaderMenuItemLoading key={index} />
                );
            });
        }

        return userInformation.userApplications.account.map((app): JSX.Element => {
            if (app.isAllowed === false) {
                return <Fragment key={app.resourceKey} />;
            }

            const mdiIcons: { [k in ApplicationResourceNames]?: string; } = {
                [ApplicationResourceNames.Settings]: settingsIcon,
                [ApplicationResourceNames.ContactSupport]: supportIcon,
                [ApplicationResourceNames.FAQs]: faqIcon,
                [ApplicationResourceNames.LogOut]: logoutIcon
            };

            const mdiIcon = mdiIcons[app.resourceKey] || null;

            if (mdiIcon) {
                return (
                    <AppHeaderMenuItem
                        key={app.resourceKey}
                        application={app}
                        mdiIcon={mdiIcon}
                        linkComponent={app.resourceKey === ApplicationResourceNames.LogOut ? LogOutButton : undefined}
                        handleMenuItemClick={(id: string): void => {
                            setAnchorEl(null);
                            if (id === ApplicationResourceNames.LogOut) {
                                dispatch(setUserLoginStatus(ApiStatus.Loading));
                                AuthService.logout();
                            }
                        }}
                    />
                );
            }
            return <Fragment key={app.resourceKey} />;
        });
    };

    return (
        <Fragment>
            <Tooltip title='Account'>
                <IconButton
                    color='inherit'
                    aria-controls='menu-appbar-account'
                    aria-haspopup='true'
                    onClick={(event): void => {
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    <Icon path={mdiAccountCircle} size={1} />
                </IconButton>
            </Tooltip>
            <Menu
                id='menu-appbar-account'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                variant='menu'
                open={Boolean(anchorEl)}
                onClose={(): void => {
                    setAnchorEl(null);
                }}
                data-qa='accountMenu-container'
            >
                {renderMenuItems()}
            </Menu>
        </Fragment>
    );
};

export default AppHeaderAccountMenu;
