import React from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton, Grid, Tooltip } from '@mui/material';
import { styled, useTheme, getContrastRatio } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiFilterVariant, mdiPencilBoxMultiple, mdiEye } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { ShareType, SideSheetType } from '../../helpers/enums';
import { privateRoutes } from '../../routes/appRouteList';

const classesPrefix = 'sideSheetButtons';

const classes = {
    sideSheetButtonWrapper: `${classesPrefix}-sideSheetButtonWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [theme.breakpoints.down('md')]: {
            [`& .${classes.sideSheetButtonWrapper}`]: {
                display: 'flex',
                justifyContent: 'space-around'
            }
        }
    };
});

const SideSheetButtons = ({
    currentSideSheet,
    handleCurrentSideSheetChange
}: {
    /** Current selected side sheet */
    currentSideSheet: SideSheetType | null;
    /** Function to handle the side sheet change. */
    handleCurrentSideSheetChange: (currentSideSheet: SideSheetType) => void;
}): JSX.Element => {

    const theme = useTheme();
    const location = useLocation();

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const determineSideSheetIconColor = (sideSheetName: SideSheetType, isDisabled: boolean): string => {
        if (isDisabled) {
            // This is cloned from MUI's createPalette and will compare the background of this component to the text color
            // and will determine the appropriate disabled icon color
            return getContrastRatio(theme.palette.primary.main, 'rgba(255, 255, 255, 0.3)') >= theme.palette.contrastThreshold
                ? 'rgba(255, 255, 255, 0.3)' // dark mode default for theme.palette.action.disabled - should not be changed
                : 'rgba(0, 0, 0, 0.26)'; // light mode default for theme.palette.action.disabled - should not be changed
        }

        if (currentSideSheet === sideSheetName) {
            return theme.palette.secondary.main;
        }

        return theme.palette.primary.contrastText;
    };

    const isUserSettingsDisabled = location.pathname === privateRoutes.shipmentsCalendar ||
        location.pathname === privateRoutes.ordersOverview || location.pathname === privateRoutes.ordersActive;

    return (
        <Grid item xs={12} md='auto'>
            <StyledGrid container>
                {
                    shareType !== ShareType.View &&
                    <Grid item xs={4} className={classes.sideSheetButtonWrapper} data-qa='sideSheet-actions-container'>
                        <Tooltip title='Filters'>
                            <span>
                                <IconButton
                                    onClick={(): void => { handleCurrentSideSheetChange(SideSheetType.Filters); }}
                                    data-qa='filters-button'
                                >
                                    <Icon
                                        path={mdiFilterVariant}
                                        size={1}
                                        color={determineSideSheetIconColor(SideSheetType.Filters, false)}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                }
                {
                    shareType !== ShareType.View &&
                    <Grid item xs={4} className={classes.sideSheetButtonWrapper}>
                        <Tooltip title='Views'>
                            <span>
                                <IconButton
                                    onClick={(): void => { handleCurrentSideSheetChange(SideSheetType.Views); }}
                                    data-qa='views-button'
                                >
                                    <Icon
                                        path={mdiEye}
                                        size={1}
                                        color={determineSideSheetIconColor(SideSheetType.Views, false)}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                }
                {
                    shareType !== ShareType.View &&
                    <Grid item xs={4} className={classes.sideSheetButtonWrapper}>
                        <Tooltip title='Counters and Columns'>
                            <span>
                                <IconButton
                                    disabled={isUserSettingsDisabled}
                                    onClick={(): void => { handleCurrentSideSheetChange(SideSheetType.UserSettings); }}
                                    data-qa='userSettings-button'
                                >
                                    <Icon
                                        path={mdiPencilBoxMultiple}
                                        size={1}
                                        color={determineSideSheetIconColor(SideSheetType.UserSettings, isUserSettingsDisabled)}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                }
            </StyledGrid>
        </Grid>
    );
};

export default SideSheetButtons;
