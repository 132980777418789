import React, { Fragment, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    CircularProgress,
    Grid,
    Typography,
    Dialog,
    Toolbar,
    Skeleton,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { resetLocationDetails } from '../../redux/locations';
import { ApiStatus } from '../../helpers/enums';
import UnsavedChangesDialog from '../dialogs/unsavedChangesDialog';
import LabelValuePair from '../labels/labelValuePair';

const classesPrefix = 'locationsForm';

const classes = {
    formWrapper: `${classesPrefix}-formWrapper`,
    form: `${classesPrefix}-form`,
    actionsContainer: `${classesPrefix}-actionsContainer`,
    button: `${classesPrefix}-button`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.formWrapper}`]: {
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        [`& .${classes.form}`]: {
            padding: '16px',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        [`& .${classes.actionsContainer}`]: {
            padding: '6px 16px',
            borderTop: `1px solid ${theme.palette.divider}`
        },
        [`& .${classes.button}`]: {
            marginRight: '8px'
        }
    };
});

const LocationsForm = ({
    // FIXME: eslint-disable can be removed once we have a form to dirty
    // eslint-disable-next-line
    handleFormDirtyChange,
    isFormDirty,
    handleCancelClick,
    isDialogOpen
}: {
    handleFormDirtyChange: (isDirty: boolean) => void;
    isFormDirty: boolean;
    handleCancelClick: () => void;
    isDialogOpen: boolean;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [unsavedChangesDialogIsOpen, setUnsavedChangesDialogIsOpen] = useState(false);
    // Used to reference our <form> for validity checks
    const formControlWrapper = useRef<HTMLFormElement>(null);

    const locationDetailsStatus = useTypedSelector((state) => { return state.locations.locationDetailsStatus; });
    const locationDetailsUpdateStatus = useTypedSelector((state) => { return state.locations.locationDetailsUpdateStatus; });
    const locationDetails = useTypedSelector((state) => { return state.locations.locationDetails; });
    // const skip = useTypedSelector((state) => { return state.locations.skip; });
    // const searchTerm = useTypedSelector((state) => { return state.locations.searchTerm; });
    // const sortColumn = useTypedSelector((state) => { return state.locations.sortColumn; });
    // const sortDirection = useTypedSelector((state) => { return state.locations.sortDirection; });

    const handleCancel = (): void => {
        if (isFormDirty) {
            setUnsavedChangesDialogIsOpen(true);
        } else {
            dispatch(resetLocationDetails());
            handleCancelClick();
        }
    };

    const handleSubmitClick = (): void => {
        console.log('I submitted a location!');
        // FIXME: implement code similar as the code below to either create or update a location
        // if (locationCode !== locationDetails?.fixedLocationCode) {
        //     dispatch(createLocation({
        //         locationDetails,
        //         skip,
        //         searchTerm,
        //         sortColumn,
        //         sortDirection
        //     }));
        // } else {
        //     dispatch(updateLocationDetails({
        //         locationDetails,
        //         skip,
        //         searchTerm,
        //         sortColumn,
        //         sortDirection
        //     }));
        // }
    };

    // FIXME: throw away code
    const renderQuickAndDirtyLocationDetails = (): JSX.Element => {
        // @ts-ignore
        return Object.keys(locationDetails).map((key: string) => {
            if (typeof locationDetails[key] === 'string') {
                return (
                    <Grid item key={key} xs={4}>
                        <LabelValuePair label={key} value={locationDetails[key]} data-qa={key} />
                    </Grid>
                );
            }
            return <Fragment key={key} />;
        });
    };

    const renderLocationsForm = (): JSX.Element => {
        // TODO: make real loader
        if (locationDetailsStatus === ApiStatus.Loading) {
            return (
                <StyledDiv>
                    <Grid container className={classes.form}>
                        {
                            isMobile === false &&
                            <Grid item xs={12}>
                                <Typography variant='h6' gutterBottom>Edit Location</Typography>
                            </Grid>
                        }
                        {
                            Array.from(new Array(3)).map((item, index): JSX.Element => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Fragment key={index}>
                                        <Grid item xs={4}>
                                            <Skeleton height='60px' />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Skeleton height='60px' />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Skeleton height='60px' />
                                        </Grid>
                                    </Fragment>
                                );
                            })
                        }

                    </Grid>
                </StyledDiv>
            );
        }

        return (
            <StyledDiv className={classes.formWrapper}>
                <form
                    ref={formControlWrapper}
                    className={classes.form}
                    onSubmit={(event): void => { event.preventDefault(); }}
                >
                    {
                        isMobile === false &&
                        <Typography variant='h6' gutterBottom>
                            {
                                locationDetails?.fixedLocationCode ? 'Edit Location' : 'Create Location'
                            }
                        </Typography>
                    }
                    <Grid container alignItems='center'>
                        {/* FIXME: This is a quick and dirty way to display the details */}
                        {
                            locationDetails?.fixedLocationCode &&
                            renderQuickAndDirtyLocationDetails()
                        }
                    </Grid>
                </form>

                <Grid container justifyContent='center' className={classes.actionsContainer}>
                    <Grid item>
                        <Button
                            className={classes.button}
                            disabled={locationDetailsUpdateStatus === ApiStatus.Loading}
                            onClick={handleCancel}
                            data-qa='locationsForm-cancel'
                        >
                            Cancel
                        </Button>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={handleSubmitClick}
                            // FIXME: enable once the form is ready to submit
                            disabled={true}
                            startIcon={locationDetailsUpdateStatus === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                            data-qa='locationsForm-save'
                        >
                            {
                                locationDetailsUpdateStatus === ApiStatus.Loading ? 'Saving' : 'Save'
                            }
                        </Button>
                    </Grid>
                </Grid>
                {
                    unsavedChangesDialogIsOpen &&
                    <UnsavedChangesDialog
                        isOpen={unsavedChangesDialogIsOpen}
                        handleClose={(): void => {
                            setUnsavedChangesDialogIsOpen(false);
                        }}
                        handleContinue={(): void => {
                            dispatch(resetLocationDetails());
                            setUnsavedChangesDialogIsOpen(false);
                            handleCancelClick();
                        }}
                    />
                }
            </StyledDiv>
        );
    };

    if (isMobile) {
        return (
            <Dialog fullScreen open={isDialogOpen}>
                <Toolbar>
                    <Typography variant='h6'>
                        {locationDetails?.fixedLocationCode ? 'Edit Location' : 'Create Location'}
                    </Typography>
                </Toolbar>
                {renderLocationsForm()}
            </Dialog>
        );
    }

    return renderLocationsForm();
};

export default LocationsForm;
