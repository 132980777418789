import React, {
    Fragment,
    useEffect,
    useState
} from 'react';
import { Route, Redirect } from 'react-router-dom';

import { routeComparator } from '../helpers/navigationUtils';
import { privateRoutes, protectedRoutes, publicRoutes } from './appRouteList';
import AuthService from '../services/authService';
import ErrorBoundary from '../components/errors/errorBoundary';
import NotAuthorized from '../components/errors/notAuthorized';

const AuthenticatedRoute = ({
    component: Component,
    ...rest
}: {
    component: React.ComponentType<{}>;
    [propName: string]: {};
}): JSX.Element => {
    const [hasUser, setHasUser] = useState(false);
    const [recievedUserData, setRecievedUserData] = useState(false);

    useEffect((): void => {
        (async (): Promise<void> => {
            try {
                const authenticatedUser = await AuthService.getUser();
                if (authenticatedUser?.access_token || AuthService.sharingToken) {
                    setHasUser(true);
                }
            } finally {
                setRecievedUserData(true);
            }
        })();
    }, [Component]);

    if (recievedUserData && hasUser) {
        // Check if it's a valid private route
        const isAllowedPrivateRoute = routeComparator(Object.values(privateRoutes), rest.path as string);
        // Then also check if it's a valid 'protected' route
        const isAllowedProtectedRoute = routeComparator(Object.values(protectedRoutes), rest.path as string);

        // If it's an allowed private route OR it's one of the protected routes, render the component
        if (isAllowedPrivateRoute || isAllowedProtectedRoute) {
            return (
                <Route {...rest}>
                    <ErrorBoundary>
                        <Component />
                    </ErrorBoundary>
                </Route>
            );
        }

        // Otherwise, we will just render the NotAuthorized component
        return (
            <Route {...rest}>
                <ErrorBoundary>
                    <NotAuthorized />
                </ErrorBoundary>
            </Route>
        );
    }

    if (recievedUserData) {
        return (
            <Redirect
                to={publicRoutes.root}
            />
        );
    }

    return <Fragment />;
};

export default AuthenticatedRoute;
