import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthService from '../../../services/authService';
import { emptyReduxStore } from '../../../redux';
import HorizontallyCenteredSpinner from '../../loaders/horizontallyCenteredSpinner';

const Revoke = (): JSX.Element => {
    const dispatch = useDispatch();
    useEffect((): void => {
        dispatch(emptyReduxStore());
        AuthService.completeLogout();
    }, [dispatch]);

    return <HorizontallyCenteredSpinner />;
};

export default Revoke;
