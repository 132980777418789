import React, { Fragment } from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DeliveryStatus } from '../../../helpers/enums';
import { trackingStatuses, notTrackingStatuses } from '../../../helpers/maps/enums';

const classesPrefix = 'deliveryStatusLegendLoading';

const classes = {
    totalRowWrapper: `${classesPrefix}-totalRowWrapper`,
    totalTitle: `${classesPrefix}-totalTitle`,
    notTrackingTitle: `${classesPrefix}-notTrackingTitle`,
    statusWrapper: `${classesPrefix}-statusWrapper`,
    statusCircle: `${classesPrefix}-statusCircle`,
    countRow: `${classesPrefix}-countRow`,
    text: `${classesPrefix}-text`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.totalRowWrapper}`]: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: '4px'
        },
        [`&.${classes.notTrackingTitle}`]: {
            marginTop: '4px'
        },
        [`& .${classes.totalTitle}`]: {
            fontSize: '12px',
            fontWeight: 600
        },
        [`& .${classes.statusWrapper}`]: {
            display: 'inline-flex'
        },
        [`& .${classes.statusCircle}`]: {
            marginRight: '4px'
        },
        [`& .${classes.countRow}`]: {
            margin: '4px 0',
            height: '18px'
        },
        [`& .${classes.text}`]: {
            transformOrigin: '0 20%'
        }
    };
});

const DeliveryStatusLegendLoading = (): JSX.Element => {
    const renderTotalRow = (title: string): JSX.Element => {
        return (
            <Fragment>
                <Grid item xs={11}>
                    <Typography className={classes.totalTitle}>{title}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Skeleton variant='text' width='20px' classes={{ text: classes.text }} />
                </Grid>
            </Fragment>
        );
    };

    const renderCountRow = (currentDeliveryStatus: DeliveryStatus): JSX.Element => {
        return (
            <Grid key={currentDeliveryStatus} item xs={12} className={classes.countRow}>
                <Grid container>
                    <Grid item xs={11} className={classes.statusWrapper}>
                        <Skeleton variant='circular' width='16px' height='16px' className={classes.statusCircle} />
                        <Skeleton variant='text' width='75px' classes={{ text: classes.text }} />
                    </Grid>
                    <Grid item xs={1}>
                        <Skeleton variant='text' width='20px' classes={{ text: classes.text }} />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Fragment>
            <StyledGrid container justifyContent='flex-start' alignItems='center' className={classes.totalRowWrapper}>
                {renderTotalRow('Tracking')}
            </StyledGrid>
            <StyledGrid container justifyContent='center' alignItems='center'>
                {
                    trackingStatuses.map((deliveryStatus: DeliveryStatus): JSX.Element => {
                        return renderCountRow(deliveryStatus);
                    })
                }
            </StyledGrid>
            <StyledGrid container justifyContent='flex-start' alignItems='center' className={`${classes.totalRowWrapper} ${classes.notTrackingTitle}`}>
                {renderTotalRow('Not Tracking')}
            </StyledGrid>
            <StyledGrid container justifyContent='center' alignItems='center'>
                {
                    notTrackingStatuses.map((deliveryStatus: DeliveryStatus): JSX.Element => {
                        return renderCountRow(deliveryStatus);
                    })
                }
            </StyledGrid>
        </Fragment>
    );
};

export default DeliveryStatusLegendLoading;
