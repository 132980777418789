import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, IconButton, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Tooltip, Typography, Skeleton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiEye, mdiShareVariant, mdiDelete } from '@mdi/js';
import { format, toDate } from 'date-fns-tz';

import { useTypedSelector } from '../../redux';
import { privateRoutes } from '../../routes/appRouteList';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { updateView } from '../../redux/dialogUpdates';
import { updateFilters } from '../../redux/shipments';
import { updateOrdersFilters } from '../../redux/orders';
import { updateCalendarFilters } from '../../redux/calendar';
import { deleteView } from '../../redux/views';
import { GenericApiResponse } from '../../interfaces/services';
import { Filter } from '../../interfaces/services/shipment';
import { ViewOverview, View } from '../../interfaces/services/views';
import { addSpaceBeforeUppercaseCharacter, capitalizeFirstLetter } from '../../helpers/dataUtils';
import { simpleInternationalizedDateFormat, displayDateTimeFormat } from '../../helpers/dateUtils';
import { handleShareUrlCopy } from '../../helpers/navigationUtils';
import { AllowedFilterPropertyName, SavedViewType, ShareType } from '../../helpers/enums';
import LabelValuePair from '../labels/labelValuePair';
import ViewCloneButton from '../buttons/viewCloneButton';
import ViewEditButton from '../buttons/viewEditButton';
import DeleteConfirmationDialog from '../dialogs/deleteConfirmationDialog';

const classesPrefix = 'viewCard';

const classes = {
    accordionWrapper: `${classesPrefix}-accordionWrapper`,
    accordionSummaryContent: `${classesPrefix}-accordionSummaryContent`,
    defaultViewIcon: `${classesPrefix}-defaultViewIcon`,
    viewNameWrapper: `${classesPrefix}-viewNameWrapper`,
    filterName: `${classesPrefix}-filterName`,
    filterValue: `${classesPrefix}-filterValue`,
    noFiltersError: `${classesPrefix}-noFiltersError`
};

const StyledAccordion = styled(Accordion)(({ theme }) => {
    return {
        [`&.${classes.accordionWrapper}`]: {
            '&:hover': {
                background: theme.palette.action.hover
            }
        },
        [`& .${classes.accordionSummaryContent}`]: {
            width: '100%',
            alignItems: 'center'
        },
        [`& .${classes.defaultViewIcon}`]: {
            marginRight: '4px',
            verticalAlign: 'middle',
            flexShrink: 0
        },
        [`& .${classes.viewNameWrapper}`]: {
            flexGrow: 1
        },
        [`& .${classes.filterName}`]: {
            fontWeight: 600,
            fontSize: '12px'
        },
        [`& .${classes.filterValue}`]: {
            marginBottom: '8px',
            fontSize: '12px'
        },
        [`& .${classes.noFiltersError}`]: {
            marginBottom: '8px'
        }
    };
});

const ViewCard = ({
    view
}: {
    view: ViewOverview;
}): JSX.Element => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [isExpanded, setIsExpanded] = useState(false);
    const [isFetchingDetails, setIsFetchingDetails] = useState(true);
    const [viewDetails, setViewDetails] = useState<View | null>(null);
    const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] = useState(false);

    const UrlShortenerApi = useTypedSelector((state) => { return state.availableServices.endpoints.UrlShortenerApi; });
    const shortUrlExpiryDays = useTypedSelector((state) => { return state.organization.organizationPreferences?.shortUrlExpiryDays; });
    const endpoints = useTypedSelector((state) => { return state.availableServices.endpoints; });
    const filtersName = useTypedSelector((state) => { return state.shipments.filtersName; });
    const orderFiltersName = useTypedSelector((state) => { return state.orders.filtersName; });
    const calendarFiltersName = useTypedSelector((state) => { return state.calendar.filtersName; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const skip = useTypedSelector((state) => { return state.views.skip; });
    const take = useTypedSelector((state) => { return state.views.take; });
    const searchTerm = useTypedSelector((state) => { return state.views.searchTerm; });
    const sortDirection = useTypedSelector((state) => { return state.views.sortDirection; });
    const sortColumn = useTypedSelector((state) => { return state.views.sortColumn; });
    const viewFilters = useTypedSelector((state) => { return state.views.viewFilters; });

    const isOrdersView = view.savedViewType === SavedViewType.SharedOrderView;
    const isCalendarView = view.savedViewType === SavedViewType.CalendarView;

    const isViewSelected = useMemo(() => {
        if (isOrdersView) {
            return orderFiltersName === view.customViewName;
        }
        if (isCalendarView) {
            return calendarFiltersName === view.customViewName;
        }
        return filtersName === view.customViewName;
    }, [isOrdersView, isCalendarView, orderFiltersName, calendarFiltersName, filtersName, view.customViewName]);

    const getDetails = useCallback((callback?: (details: View) => void): void => {
        const { DataViewApi } = endpoints;
        ApiService.get({ url: `${DataViewApi}${Endpoints.dataViewApi.viewDetails}/${view.customViewGuid}` })
            .then((response): void => {
                const { data } = response as GenericApiResponse<View>;
                const viewDetailsData = data[0];

                if (callback) {
                    callback(viewDetailsData);
                }

                setViewDetails(viewDetailsData);
            }).catch((): void => {
                toast.error('Error occurred while fetching view details.');
            }).finally((): void => {
                setIsFetchingDetails(false);
            });
    }, [endpoints, view]);

    // If the card is expanded, fetch the details
    useEffect(() => {
        if (isExpanded) {
            (async (): Promise<void> => {
                await getDetails();
            })();
        }
    }, [isExpanded, getDetails]);

    const renderFilterList = (): JSX.Element | JSX.Element[] => {
        if (isFetchingDetails) {
            return Array.from(new Array(3)).map((item, index): JSX.Element => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        <Typography className={classes.filterName}>
                            <Skeleton variant='text' width='100px' />
                        </Typography>
                        <Typography className={classes.filterValue}>
                            <Skeleton className={classes.filterValue} variant='text' width='200px' />
                        </Typography>
                    </Fragment>
                );
            });
        }

        if (viewDetails === null) {
            return (
                <Typography className={classes.noFiltersError} data-qa='noFilters-error'>No filters found.</Typography>
            );
        }

        const renderFilterDetails = (viewFilter: Filter): JSX.Element => {
            if (
                viewFilter.propertyName === AllowedFilterPropertyName.OriginScheduleStartDateTime ||
                viewFilter.propertyName === AllowedFilterPropertyName.OriginScheduleEndDateTime ||
                viewFilter.propertyName === AllowedFilterPropertyName.OriginAppointmentStartDateTime ||
                viewFilter.propertyName === AllowedFilterPropertyName.OriginAppointmentEndDateTime ||
                viewFilter.propertyName === AllowedFilterPropertyName.DestinationScheduleStartDateTime ||
                viewFilter.propertyName === AllowedFilterPropertyName.DestinationScheduleEndDateTime ||
                viewFilter.propertyName === AllowedFilterPropertyName.DestinationAppointmentStartDateTime ||
                viewFilter.propertyName === AllowedFilterPropertyName.DestinationAppointmentEndDateTime
            ) {
                // Check if the view date is a string representation of a date and return it unformatted
                if (
                    viewFilter.propertyValues[0].toLowerCase() === 'today' ||
                    viewFilter.propertyValues[0].toLowerCase() === 'tomorrow' ||
                    viewFilter.propertyValues[0].toLowerCase() === 'yesterday' ||
                    viewFilter.propertyValues[0].toLowerCase() === 'this week'
                ) {
                    return (
                        <Typography className={classes.filterValue} data-qa={`${viewFilter.propertyName}-filterValue`}>
                            {viewFilter.propertyValues[0]}
                        </Typography>
                    );
                }

                // otherwise, date property values comes back as an array with 1 item, the range of dates eg. '01/01/2000 - 02/02/2000'
                const dateRange = viewFilter.propertyValues[0]?.split(' - ');
                return (
                    <Typography className={classes.filterValue} data-qa={`${viewFilter.propertyName}-filterValue`}>
                        {`${format(new Date(dateRange[0]), simpleInternationalizedDateFormat)}
                        -
                        ${format(new Date(dateRange[1]), simpleInternationalizedDateFormat)}`}
                    </Typography>
                );
            }
            return (
                <Typography className={classes.filterValue} data-qa={`${viewFilter.propertyName}-filterValue`}>
                    {viewFilter.propertyValues?.toString().replace(/,/g, ', ')}
                </Typography>
            );
        };

        return viewDetails.savedViewProperty.map((viewFilter): JSX.Element => {
            const renderFormattedFilterName = (): string => {
                if (viewFilter.propertyName === AllowedFilterPropertyName.OrderDeliveryStatus) {
                    return 'Delivery Status';
                }
                if (viewFilter.propertyName === AllowedFilterPropertyName.OrderPickupStatus) {
                    return 'Pickup Status';
                }
                return capitalizeFirstLetter(addSpaceBeforeUppercaseCharacter(viewFilter.propertyName));
            };

            return (
                <Fragment key={viewFilter.propertyName}>
                    <Typography className={classes.filterName} data-qa={`${viewFilter.propertyName}-filterName`}>
                        {renderFormattedFilterName()}
                    </Typography>
                    {renderFilterDetails(viewFilter)}
                </Fragment>
            );
        });
    };

    const renderAssignedUsers = (): JSX.Element => {
        if (view.savedViewType === SavedViewType.AssignedView && viewDetails) {
            const assignedUsers = viewDetails.assignedViewUsers.map((user): string => {
                return user.username;
            });

            return (
                <Fragment>
                    <Typography className={classes.filterName} data-qa='assignedUsers-label'>
                        Assigned Users
                    </Typography>
                    <Typography className={classes.filterValue} data-qa='assignedUsers'>
                        {assignedUsers?.toString().replace(/,/g, ', ')}
                    </Typography>
                </Fragment>
            );
        }

        return <Fragment />;
    };

    const renderActions = (): JSX.Element => {
        if (isFetchingDetails) {
            return (
                <Fragment>
                    {
                        view.savedViewType === SavedViewType.UserView && view.isDefaultView === false &&
                        <Skeleton variant='rectangular' height='30px' width='145px' />
                    }

                    {
                        (view.savedViewType === SavedViewType.TransportationSharedView || view.savedViewType === SavedViewType.SharedOrderView) &&
                        <Skeleton variant='circular' height='30px' width='30px' />
                    }

                    <Skeleton variant='circular' height='30px' width='30px' />
                    <Skeleton variant='circular' height='30px' width='30px' />
                    <Skeleton variant='circular' height='30px' width='30px' />
                </Fragment>
            );
        }

        if (viewDetails === null || shareType === ShareType.OperatorReadOnly || shareType === ShareType.CustomerReadOnly) {
            return <Fragment />;
        }

        return (
            <Fragment>
                {
                    view.savedViewType === SavedViewType.UserView &&
                    <Button
                        size='small'
                        color='primary'
                        onClick={(): void => {
                            dispatch(updateView({
                                customViewGuid: viewDetails.savedView.savedViewGUID,
                                name: viewDetails.savedView.viewName,
                                savedViewType: viewDetails.savedView.savedViewType,
                                isDefault: !view.isDefaultView,
                                filters: viewDetails.savedViewProperty,
                                assignedViewUsers: viewDetails.assignedViewUsers,
                                // below params are needed to refetch the views list once the view has been added
                                skip,
                                take,
                                searchTerm,
                                sortColumn,
                                sortDirection,
                                viewFilters
                            }));
                        }}
                        data-qa='toggleDefaultView-action'
                    >
                        {view.isDefaultView ? 'Unset as Default View' : 'Set As Default View'}
                    </Button>
                }

                {
                    (view.savedViewType === SavedViewType.TransportationSharedView || view.savedViewType === SavedViewType.SharedOrderView) &&
                    <Tooltip title='Share'>
                        <IconButton
                            size='small'
                            onClick={(): void => {
                                const pageUrl = view.savedViewType === SavedViewType.SharedOrderView ? privateRoutes.ordersOverview : privateRoutes.shipmentsOverview;
                                // create the URL to the overview page with the shared view token and copy it to the clipboard.
                                handleShareUrlCopy({
                                    urlShortenerApi: UrlShortenerApi,
                                    pageUrl,
                                    token: view.sharedViewToken,
                                    successMessage: 'Shared view url copied to clipboard.',
                                    shortenedUrlName: `Shared view ${view.customViewGuid}`,
                                    expiryDays: shortUrlExpiryDays
                                });
                            }}
                            data-qa='shareView-action'
                        >
                            <Icon path={mdiShareVariant} size={1} />
                        </IconButton>
                    </Tooltip>
                }

                <ViewCloneButton view={viewDetails} />

                <ViewEditButton view={viewDetails} />

                <Tooltip title='Delete'>
                    <IconButton
                        size='small'
                        onClick={(): void => {
                            setDeleteConfirmationDialogIsOpen(true);
                        }}
                        data-qa='deleteView-action'
                    >
                        <Icon path={mdiDelete} size={1} />
                    </IconButton>
                </Tooltip>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <StyledAccordion
                className={classes.accordionWrapper}
                TransitionProps={{ unmountOnExit: true }}
                expanded={isExpanded}
                onChange={(): void => {
                    setIsExpanded(!isExpanded);
                }}
                data-qa={`${view.customViewGuid}-container`}
            >
                <AccordionSummary
                    classes={{
                        content: classes.accordionSummaryContent
                    }}
                    aria-controls={`${view.customViewGuid}-content`}
                    data-qa={`${view.customViewGuid}-header`}
                >
                    <Fragment>
                        {
                            view.isDefaultView &&
                            <Tooltip title='Default View'>
                                <Icon
                                    size='24px'
                                    className={classes.defaultViewIcon}
                                    path={mdiEye}
                                    color={theme.palette.text.primary}
                                    data-qa='view-defaultViewIcon'
                                />
                            </Tooltip>
                        }
                        <Tooltip title={view.customViewName}>
                            <Typography noWrap className={classes.viewNameWrapper} data-qa='customViewName'>{view.customViewName}</Typography>
                        </Tooltip>
                        <Button
                            size='small'
                            variant='outlined'
                            disabled={isViewSelected}
                            onClick={async (event): Promise<void> => {
                                // stop propagation to not expand the view when the apply button is clicked.
                                event.stopPropagation();

                                // if looking at an orders specific view we need to update orders specific filters
                                const updateReduxFilters = (details: View): void => {
                                    if (isOrdersView) {
                                        dispatch(updateOrdersFilters(details.savedViewProperty, view.customViewName));
                                    } else if (isCalendarView) {
                                        dispatch(updateCalendarFilters({ filters: details.savedViewProperty, filtersName: view.customViewName }));
                                    } else {
                                        dispatch(updateFilters(details.savedViewProperty, view.customViewName));
                                    }
                                };

                                // if the view details exists already, update the filters to apply the view
                                if (viewDetails) {
                                    updateReduxFilters(viewDetails);
                                } else {
                                    // otherwise, fetch the details, and then update the filters to apply the view
                                    await getDetails((details) => {
                                        updateReduxFilters(details);
                                    });
                                }
                            }}
                            data-qa='apply-button'
                        >
                            Apply
                        </Button>
                    </Fragment>
                </AccordionSummary>

                <AccordionDetails>
                    {renderFilterList()}
                    {renderAssignedUsers()}

                    <LabelValuePair
                        label='Updated Date'
                        // formats the date/time to local timezone from UTC
                        value={format(toDate(view.lastUpdatedDate), displayDateTimeFormat)}
                        fontSize='12px'
                        data-qa='lastUpdatedDate'
                    />
                    <LabelValuePair label='Updated By' value={view.lastUpdateBy} fontSize='12px' data-qa='lastUpdatedBy' />
                </AccordionDetails>

                <AccordionActions>
                    {renderActions()}
                </AccordionActions>
            </StyledAccordion>

            {
                deleteConfirmationDialogIsOpen &&
                <DeleteConfirmationDialog
                    isOpen={deleteConfirmationDialogIsOpen}
                    handleClose={(): void => {
                        setDeleteConfirmationDialogIsOpen(false);
                    }}
                    handleContinue={(): void => {
                        dispatch(deleteView({
                            customViewGuid: view.customViewGuid,
                            skip,
                            take,
                            searchTerm,
                            sortColumn,
                            sortDirection,
                            viewFilters
                        }));

                        // if the view is selected and then deleted, we will clear the filters in redux
                        if (isViewSelected) {
                            dispatch(updateFilters([]));
                        }
                        setDeleteConfirmationDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ViewCard;
