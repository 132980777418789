import React, { Fragment, useState, useEffect, useReducer } from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    FormGroup,
    FormControlLabel,
    Switch,
    Typography,
    CircularProgress,
    TextField,
    FormHelperText,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { mdiEye } from '@mdi/js';

import { GenericAction, useTypedSelector } from '../../redux';
import { updatePageReset, addView, updateView } from '../../redux/dialogUpdates';
import { ApiStatus, AllowedFilterPropertyName, SavedViewType } from '../../helpers/enums';
import { lowerCaseFirstLetter } from '../../helpers/dataUtils';
import { Filter } from '../../interfaces/services/shipment';
import { View, ViewUser } from '../../interfaces/services/views';
import {
    INITIALIZE_FILTERS,
    UPDATE_FILTER,
    FilterState,
    initialState
} from '../../interfaces/filterInterfaces';
import {
    deliveryStatusOptions,
    shipmentStatusOptions,
    modeTypeOptions,
    weatherSeverityOptions,
    hotShipmentOptions
} from '../../helpers/hardcodedOptionLists';
import Endpoints from '../../services/endpoints';
import CommonDialog from './common/commonDialog';
import AsyncCreatable from '../selects/asyncCreatable';
import MultiSelect from '../selects/multiSelect';
import AsyncAutocomplete from '../selects/asyncAutocomplete';
import FilterDateRangePicker from '../pickers/filterDateRangePicker';
import AsyncMultiSelect from '../selects/asyncMultiSelect';

const classesPrefix = 'viewDialog';

const classes = {
    viewNameWrapper: `${classesPrefix}-viewNameWrapper`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`&.${classes.viewNameWrapper}`]: {
            marginBottom: '16px'
        }
    };
});

const ViewDialog = ({
    view,
    createViewType,
    isOpen,
    closeDialog
}: {
    view?: View;
    createViewType?: SavedViewType;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [draftCreateViewType, setDraftCreateViewType] = useState<SavedViewType>(SavedViewType.UserView);
    const [allowedUsernames, setAllowedUserNames] = useState<string[]>([]);
    const [isAllowedUserNamesValid, setIsAllowedUserNamesValid] = useState(true);
    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const MasterData = useTypedSelector((state) => { return state.availableServices.endpoints.MasterData; });

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    const skip = useTypedSelector((state) => { return state.views.skip; });
    const take = useTypedSelector((state) => { return state.views.take; });
    const searchTerm = useTypedSelector((state) => { return state.views.searchTerm; });
    const sortDirection = useTypedSelector((state) => { return state.views.sortDirection; });
    const sortColumn = useTypedSelector((state) => { return state.views.sortColumn; });
    const viewFilters = useTypedSelector((state) => { return state.views.viewFilters; });

    const reducer = (
        state: FilterState,
        action: Action<typeof INITIALIZE_FILTERS> |
            GenericAction<typeof UPDATE_FILTER,
                {
                    filterId: AllowedFilterPropertyName;
                    filterValues: string[];
                }>
    ): FilterState => {
        switch (action.type) {
            case UPDATE_FILTER: {
                return {
                    ...state,
                    [action.payload.filterId]: action.payload.filterValues
                };
            }
            case INITIALIZE_FILTERS: {
                return initialState;
            }
            default: {
                return state;
            }
        }
    };

    const [filterState, localDispatch] = useReducer(reducer, initialState);

    const updateFilterState = (filterId: string, filterValues: string[]): void => {
        localDispatch({
            type: UPDATE_FILTER,
            payload: {
                filterId: filterId as AllowedFilterPropertyName,
                filterValues
            }
        });
    };

    useEffect((): void => {
        // if the view filters are empty, empty the local filter state
        if (view?.savedViewProperty.length === 0) {
            localDispatch({
                type: INITIALIZE_FILTERS
            });
        } else {
            view?.savedViewProperty.forEach((filter): void => {
                updateFilterState(lowerCaseFirstLetter(filter.propertyName) as AllowedFilterPropertyName, filter.propertyValues);
            });
        }
    }, [view]);

    useEffect((): void => {
        // if we are on the create version of this dialog, use the prop passed in for the view type
        if (createViewType) {
            setDraftCreateViewType(createViewType);
        }

        // otherwise, we are updating an existing view, so use those values instead for local state.
        if (view) {
            setName(view.savedView.viewName);
            setIsDefault(view.savedView.isDefaultFlag);
            setDraftCreateViewType(view.savedView.savedViewType);

            const assignedUserNames = view.assignedViewUsers.map((user): string => {
                return user.username;
            });
            setAllowedUserNames(assignedUserNames);
        }
    }, [createViewType, view]);

    // Validate form fields and determine if form can be submitted
    useEffect((): void => {
        let isNameFieldValid = true;
        let isAssignedUsersValid = true;

        if (!name) {
            isNameFieldValid = false;
        }

        // Checking that at least one filter has input from the user
        const isFiltersValid = Object.entries(filterState).some(([, filterValues]): boolean => {
            return filterValues.length > 0;
        });

        if (draftCreateViewType === SavedViewType.AssignedView) {
            if (allowedUsernames.length === 0) {
                isAssignedUsersValid = false;
            }
        }

        setIsAllowedUserNamesValid(isAssignedUsersValid);

        setCanSubmitForm(isNameFieldValid && isAssignedUsersValid && isFiltersValid);
    }, [draftCreateViewType, name, filterState, allowedUsernames]);

    // If the API update is successful, trigger the dialog to reset and close
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            closeDialog();
        }
    }, [status, dispatch, closeDialog]);

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        const filtersList = Object.entries(filterState).reduce((accumulator: Filter[], [filterName, filterValues]): Filter[] => {
            if (filterValues.length > 0) {
                accumulator.push({
                    propertyName: filterName as AllowedFilterPropertyName,
                    propertyValues: filterValues
                });
            }
            return accumulator;
        }, []);

        const assignedUserNames = allowedUsernames.map((user): ViewUser => {
            let username = user;
            if (user.includes(' - ')) {
                // Master Data returns the filterValues as "firstName LastName - emailAddress", but DataView only needs the email address
                // so we'll break it apart and only send the email address to the API.
                // Otherwise, we'll just the username as is because it likely came from the view itself.
                [, username] = user.split(' - ');
            }
            return {
                username
            };
        });

        const viewDetailsToSave = {
            name,
            savedViewType: draftCreateViewType,
            isDefault,
            filters: filtersList,
            assignedViewUsers: draftCreateViewType === SavedViewType.AssignedView ? assignedUserNames : [],
            // below params are needed to refetch the views list once the view has been added
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection,
            viewFilters
        };

        if (createViewType) {
            dispatch(addView(viewDetailsToSave));
        } else if (view) {
            dispatch(updateView({
                customViewGuid: view.savedView.savedViewGUID,
                ...viewDetailsToSave
            }));
        }
    };

    const renderFilters = (): JSX.Element => {
        if (draftCreateViewType === SavedViewType.SharedOrderView) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.PickupScheduleStartDateTime}
                            label={isMobile ? 'Pickup Sched. Start' : 'Pickup Scheduled Start'}
                            currentValue={filterState[AllowedFilterPropertyName.PickupScheduleStartDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.PickupScheduleEndDateTime}
                            label={isMobile ? 'Pickup Sched. End' : 'Pickup Scheduled End'}
                            currentValue={filterState[AllowedFilterPropertyName.PickupScheduleEndDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.PickupAppointmentStartDateTime}
                            label={isMobile ? 'Pickup Appt. Start' : 'Pickup Appointment Start'}
                            currentValue={filterState[AllowedFilterPropertyName.PickupAppointmentStartDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.PickupAppointmentEndDateTime}
                            label={isMobile ? 'Pickup Appt. End' : 'Pickup Appointment End'}
                            currentValue={filterState[AllowedFilterPropertyName.PickupAppointmentEndDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.DeliveryScheduleStartDateTime}
                            label={isMobile ? 'Delivery Sched. Start' : 'Delivery Scheduled Start'}
                            currentValue={filterState[AllowedFilterPropertyName.DeliveryScheduleStartDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.DeliveryScheduleEndDateTime}
                            label={isMobile ? 'Delivery Sched. End' : 'Delivery Scheduled End'}
                            currentValue={filterState[AllowedFilterPropertyName.DeliveryScheduleEndDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.DeliveryAppointmentStartDateTime}
                            label={isMobile ? 'Delivery Appt. Start' : 'Delivery Appointment Start'}
                            currentValue={filterState[AllowedFilterPropertyName.DeliveryAppointmentStartDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FilterDateRangePicker
                            id={AllowedFilterPropertyName.DeliveryAppointmentEndDateTime}
                            label={isMobile ? 'Delivery Appt. End' : 'Delivery Appointment End'}
                            currentValue={filterState[AllowedFilterPropertyName.DeliveryAppointmentEndDateTime]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.CustomerName}
                            label='Customers'
                            filterName='CustomerName'
                            values={filterState[AllowedFilterPropertyName.CustomerName]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.CarrierName}
                            label='Carrier'
                            filterName='CarrierName'
                            values={filterState[AllowedFilterPropertyName.CarrierName]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.PickupCity}
                            label='Pickup City'
                            filterName='PickupCityName'
                            values={filterState[AllowedFilterPropertyName.PickupCity]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.DeliveryCity}
                            label='Delivery City'
                            filterName='DeliveryCityName'
                            values={filterState[AllowedFilterPropertyName.DeliveryCity]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.PickupState}
                            label='Pickup State'
                            filterName='PickupStateCode'
                            values={filterState[AllowedFilterPropertyName.PickupState]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.DeliveryState}
                            label='Delivery State'
                            filterName='DeliveryStateCode'
                            values={filterState[AllowedFilterPropertyName.DeliveryState]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.PickupLocationCode}
                            label='Pickup Location Code'
                            filterName='PickupFixedLocationCode'
                            values={filterState[AllowedFilterPropertyName.PickupLocationCode]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.DeliveryLocationCode}
                            label='Delivery Location Code'
                            filterName='DeliveryFixedLocationCode'
                            values={filterState[AllowedFilterPropertyName.DeliveryLocationCode]}
                            handleChange={updateFilterState}
                            isOrder={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.OrderPickupStatus}
                            label='Pickup Status'
                            values={filterState[AllowedFilterPropertyName.OrderPickupStatus]}
                            availableOptions={deliveryStatusOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.OrderDeliveryStatus}
                            label='Delivery Status'
                            values={filterState[AllowedFilterPropertyName.OrderDeliveryStatus]}
                            availableOptions={deliveryStatusOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.ShipmentStatus}
                            label='Shipment Status'
                            values={filterState[AllowedFilterPropertyName.ShipmentStatus]}
                            availableOptions={shipmentStatusOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                </Grid>
            );
        }

        return (
            <Fragment>
                <Grid container spacing={1}>
                    {
                        draftCreateViewType !== SavedViewType.CalendarView &&
                        <Fragment>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.OriginScheduleStartDateTime}
                                    label={isMobile ? 'Origin Sched. Start' : 'Origin Scheduled Start'}
                                    currentValue={filterState[AllowedFilterPropertyName.OriginScheduleStartDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.OriginScheduleEndDateTime}
                                    label={isMobile ? 'Origin Sched. End' : 'Origin Scheduled End'}
                                    currentValue={filterState[AllowedFilterPropertyName.OriginScheduleEndDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.OriginAppointmentStartDateTime}
                                    label={isMobile ? 'Origin Appt. Start' : 'Origin Appointment Start'}
                                    currentValue={filterState[AllowedFilterPropertyName.OriginAppointmentStartDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.OriginAppointmentEndDateTime}
                                    label={isMobile ? 'Origin Appt. End' : 'Origin Appointment End'}
                                    currentValue={filterState[AllowedFilterPropertyName.OriginAppointmentEndDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.DestinationScheduleStartDateTime}
                                    label={isMobile ? 'Destination Sched. Start' : 'Destination Scheduled Start'}
                                    currentValue={filterState[AllowedFilterPropertyName.DestinationScheduleStartDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.DestinationScheduleEndDateTime}
                                    label={isMobile ? 'Destination Sched. End' : 'Destination Scheduled End'}
                                    currentValue={filterState[AllowedFilterPropertyName.DestinationScheduleEndDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.DestinationAppointmentStartDateTime}
                                    label={isMobile ? 'Destination Appt. Start' : 'Destination Appointment Start'}
                                    currentValue={filterState[AllowedFilterPropertyName.DestinationAppointmentStartDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FilterDateRangePicker
                                    id={AllowedFilterPropertyName.DestinationAppointmentEndDateTime}
                                    label={isMobile ? 'Destination Appt. End' : 'Destination Appointment End'}
                                    currentValue={filterState[AllowedFilterPropertyName.DestinationAppointmentEndDateTime]}
                                    handleChange={updateFilterState}
                                />
                            </Grid>
                        </Fragment>
                    }

                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.Customers}
                            label='Customers'
                            filterName='ProductCustomer'
                            values={filterState[AllowedFilterPropertyName.Customers]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.Partners}
                            label='Carrier'
                            filterName='CarrierName'
                            values={filterState[AllowedFilterPropertyName.Partners]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.Products}
                            label='Products'
                            filterName='ProductLine'
                            values={filterState[AllowedFilterPropertyName.Products]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.Brand}
                            label='Brand/Business Unit'
                            filterName='ProductBrand'
                            values={filterState[AllowedFilterPropertyName.Brand]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.Sku}
                            label='SKU/Commodity'
                            filterName='Sku'
                            values={filterState[AllowedFilterPropertyName.Sku]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncMultiSelect
                            id={AllowedFilterPropertyName.EquipmentType}
                            values={filterState[AllowedFilterPropertyName.EquipmentType]}
                            handleChange={updateFilterState}
                            url={`${MasterData}${Endpoints.masterDataApi.equipmentType}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.Origin}
                            label='Origin'
                            filterName='OriginDisplayfreightprovider'
                            values={filterState[AllowedFilterPropertyName.Origin]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.Destination}
                            label='Destination'
                            filterName='DestinationDisplayfreightprovider'
                            values={filterState[AllowedFilterPropertyName.Destination]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.OutboundLocation}
                            label='Outbound Location'
                            filterName='OutboundLocation'
                            values={filterState[AllowedFilterPropertyName.OutboundLocation]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.InboundLocation}
                            label='Inbound Location'
                            filterName='InboundLocation'
                            values={filterState[AllowedFilterPropertyName.InboundLocation]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.OriginState}
                            label='From State'
                            filterName='OriginStateCodefreightprovider'
                            values={filterState[AllowedFilterPropertyName.OriginState]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.DestinationState}
                            label='To State'
                            filterName='DestinationStateCodefreightprovider'
                            values={filterState[AllowedFilterPropertyName.DestinationState]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.OriginCountryCode}
                            label='From Country'
                            filterName='OriginCountryCode'
                            values={filterState[AllowedFilterPropertyName.OriginCountryCode]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncCreatable
                            id={AllowedFilterPropertyName.DestinationCountryCode}
                            label='To Country'
                            filterName='DestinationCountryCode'
                            values={filterState[AllowedFilterPropertyName.DestinationCountryCode]}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.DeliveryStatus}
                            label='Delivery Status'
                            values={filterState[AllowedFilterPropertyName.DeliveryStatus]}
                            availableOptions={deliveryStatusOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.ShipmentStatus}
                            label='Shipment Status'
                            values={filterState[AllowedFilterPropertyName.ShipmentStatus]}
                            availableOptions={shipmentStatusOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AsyncMultiSelect
                            id={AllowedFilterPropertyName.OrderExceptions}
                            values={filterState[AllowedFilterPropertyName.OrderExceptions]}
                            handleChange={updateFilterState}
                            url={`${MasterData}${Endpoints.masterDataApi.orderExceptions}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.ModeType}
                            label='Mode Type'
                            values={filterState[AllowedFilterPropertyName.ModeType]}
                            availableOptions={modeTypeOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.WeatherSeverity}
                            label='Weather Severity'
                            values={filterState[AllowedFilterPropertyName.WeatherSeverity]}
                            availableOptions={weatherSeverityOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiSelect
                            id={AllowedFilterPropertyName.IsPriorityShipment}
                            label='Hot Shipments'
                            values={filterState[AllowedFilterPropertyName.IsPriorityShipment]}
                            availableOptions={hotShipmentOptions}
                            isLoading={false}
                            handleChange={updateFilterState}
                        />
                    </Grid>
                </Grid>
                {
                    (draftCreateViewType === SavedViewType.TransportationSharedView || draftCreateViewType === SavedViewType.AssignedView) &&
                    <Grid container spacing={1} alignItems='flex-start'>
                        <Grid item xs={12} md={6}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={draftCreateViewType === SavedViewType.AssignedView}
                                            onChange={(event): void => {
                                                if (event.target.checked) {
                                                    setDraftCreateViewType(SavedViewType.AssignedView);
                                                } else {
                                                    setDraftCreateViewType(SavedViewType.TransportationSharedView);
                                                }
                                            }}
                                            color='primary'
                                        />
                                    }
                                    label='Assign this custom view to an existing user'
                                    data-qa='assignUsers-switch'
                                />
                            </FormGroup>
                        </Grid>
                        {
                            draftCreateViewType === SavedViewType.AssignedView &&
                            <Grid item xs={12} md={6}>
                                <AsyncAutocomplete
                                    id='Username'
                                    label='Username'
                                    filterName='Username'
                                    values={allowedUsernames}
                                    handleChange={(id, selectedOptions): void => {
                                        setAllowedUserNames(selectedOptions);
                                    }}
                                />
                                {
                                    isAllowedUserNamesValid === false &&
                                    <FormHelperText error>
                                        At least one user name must be selected
                                    </FormHelperText>
                                }
                            </Grid>
                        }
                    </Grid>
                }
            </Fragment>
        );
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Fragment>
                <StyledGrid container className={classes.viewNameWrapper}>
                    <Grid item>
                        <TextField
                            label='View Name'
                            value={name}
                            margin='none'
                            variant='filled'
                            fullWidth
                            onChange={(event): void => {
                                setName(event.currentTarget.value);
                            }}
                            inputProps={{
                                autoComplete: 'off',
                                'data-qa': 'saveViewName-input'
                            }}
                        />
                        {
                            draftCreateViewType === SavedViewType.UserView &&
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isDefault}
                                            onChange={(event): void => {
                                                setIsDefault(event.target.checked);
                                            }}
                                            color='primary'
                                        />
                                    }
                                    label='Set as Default View'
                                    data-qa='setAsDefault-switch'
                                />
                            </FormGroup>
                        }
                    </Grid>
                </StyledGrid>
                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>{errorMessage}</Typography>
                    </Grid>
                }
                {renderFilters()}
            </Fragment>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth='md'
            headerIcon={mdiEye}
            headerText='Save View'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canSubmitForm}
                        onClick={handleSave}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default ViewDialog;
