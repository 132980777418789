import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Card,
    CardContent,
    Grid,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { fetchOrderDetails } from '../../redux/orderDetails';
import { Stop } from '../../interfaces/services/shipmentDetails';
import { ApiStatus, OrderStopType } from '../../helpers/enums';
import OriginAndDestinationImage from '../../assets/images/maps/markers_origindestination.png';
import NoOrderDetails from '../errors/noOrderDetails';
import ShipmentSummaryCard from '../cards/shipmentSummaryCard';
import ShipmentSummaryCardLoading from '../loaders/cards/shipmentSummaryCardLoading';
import OrderDetailsCard from '../cards/orderDetailsCard';
import OrderDetailsCardLoading from '../loaders/cards/orderDetailsCardLoading';
import OrderDetailsMap from '../maps/orderDetailsMap';
import StopCard from '../cards/stopCard';
import StopCardLoading from '../loaders/cards/stopCardLoading';

const classesPrefix = 'orderDetails';

const classes = {
    gridContainer: `${classesPrefix}-gridContainer`,
    fullPageCardWrapper: `${classesPrefix}-fullPageCardWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.gridContainer}`]: {
            marginTop: 0,
            marginBottom: '16px'
        },
        [`& .${classes.fullPageCardWrapper}`]: {
            height: 'calc(100vh - 144px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.gridContainer}`]: {
                marginLeft: 0,
                marginRight: '8px',
                maxWidth: 'calc(100vw - 16px)'
            }
        }
    };
});

const OrderDetails = ({
    orderGuid
}: {
    /** unique identifier for the order */
    orderGuid: string;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const order = useTypedSelector((state) => { return state.orderDetails.order; });
    const orderDetailsStatus = useTypedSelector((state) => { return state.orderDetails.orderDetailsStatus; });

    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const isFetchingOrderDetails = orderDetailsStatus === ApiStatus.Idle || orderDetailsStatus === ApiStatus.Loading;

    useEffect((): void => {
        dispatch(fetchOrderDetails(orderGuid));
    }, [dispatch, orderGuid]);

    const renderStopCards = (): React.ReactNode => {
        if (isFetchingOrderDetails) {
            return (
                <Fragment>
                    <Grid item xs={12}>
                        <StopCardLoading />
                    </Grid>
                    <Grid item xs={12}>
                        <StopCardLoading />
                    </Grid>
                </Fragment>
            );
        }

        return order.stops.map((stop: Stop, index: number): React.ReactNode => {
            return (
                <Grid key={stop.shipmentStopGUID} item xs={12} data-qa={`${stop.stopReference}-container`}>
                    <StopCard
                        shipmentUniqueName={order.shipmentUniqueName}
                        modeType={order.modeType}
                        stop={stop}
                        orders={order.orders}
                        stopTypeLabel={index === 0 ? OrderStopType.Pickup : OrderStopType.Delivery}
                    />
                </Grid>
            );
        });
    };

    if ((!isFetchingOrderDetails && order.freightOrderGuid !== orderGuid) || orderDetailsStatus === ApiStatus.Failure) {
        return (
            <StyledGrid container justifyContent='center' alignItems='center' spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={10}>
                    <Card className={classes.fullPageCardWrapper}>
                        <CardContent>
                            <NoOrderDetails />
                        </CardContent>
                    </Card>
                </Grid>
            </StyledGrid>
        );
    }

    return (
        <StyledGrid container justifyContent='center' spacing={2} className={classes.gridContainer} data-qa='orderDetails-content'>
            <Grid item xs={12} md={10} data-qa='detailsCard-container'>
                <Grid container spacing={2}>
                    {
                        isFetchingOrderDetails
                            ? (
                                <Grid item xs={12} md={4}>
                                    <ShipmentSummaryCardLoading />
                                </Grid>
                            ) : (
                                order.stops[0] &&
                                <Grid item xs={12} md={4}>
                                    <ShipmentSummaryCard
                                        isFetching={isFetchingOrderDetails}
                                        freightProviderReferenceNumber={order.freightProviderReferenceNumber}
                                        shipmentStatus={null}
                                        deliveryStatus={order.stops[0].deliveryStatus}
                                        modeType={order.modeType}
                                        estimatedDeliveryDateTime={order.stops[0].estimatedDeliveryDateTime}
                                        mobileTrackingNumber={order.modeType}
                                        freightHauler={order.freightHauler}
                                        organizationContact={order.organizationContact}
                                        driver={order.driver}
                                    />
                                </Grid>
                            )
                    }

                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent>
                                <OrderDetailsMap
                                    isFetchingData={isFetchingOrderDetails}
                                    order={order}
                                    originAndDestinationImage={OriginAndDestinationImage}
                                    paletteMode={paletteMode}
                                    isMobile={isMobile}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    {
                        isFetchingOrderDetails
                            ? (
                                <Grid item xs={12}>
                                    <OrderDetailsCardLoading />
                                </Grid>
                            )
                            : (
                                order.orders[0] &&
                                <Grid item xs={12}>
                                    <OrderDetailsCard order={order.orders[0]} />
                                </Grid>
                            )
                    }

                    {renderStopCards()}
                </Grid>
            </Grid>
        </StyledGrid>
    );
};

export default OrderDetails;
