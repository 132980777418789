import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    Typography,
    Tabs,
    Tab,
    CircularProgress,
    TextField,
    FormControlLabel,
    Checkbox,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiMessageBulleted } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { updatePageReset, addShipmentNote, addShipmentStopNote } from '../../redux/dialogUpdates';
import { fetchShipmentDetails } from '../../redux/shipmentDetails';
import { ApiStatus } from '../../helpers/enums';
import { Stop } from '../../interfaces/services/shipmentDetails';
import CommonDialog from './common/commonDialog';
import ShipmentNoteList from '../lists/shipmentNoteList';
import ShipmentStopNoteList from '../lists/shipmentStopNoteList';
import ShipmentStopSelect from '../selects/shipmentStopSelect';
import StopContactLoading from '../loaders/labels/stopContactLoading';
import StopContact from '../labels/stopContact';

const classesPrefix = 'notesDialog';

const classes = {
    smsDriverSwitch: `${classesPrefix}-smsDriverSwitch`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.smsDriverSwitch}`]: {
            paddingLeft: '16px'
        }
    };
});

enum NotesTab {
    Shipment,
    Stops
}

const NotesDialog = ({
    shipmentUniqueName,
    initialStopSequence,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    isOpen: boolean;
    initialStopSequence?: number;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState(initialStopSequence !== undefined ? NotesTab.Stops : NotesTab.Shipment);
    const [currentStop, setCurrentStop] = useState<Stop | null>(null);
    const [noteText, setNoteText] = useState('');
    const [isShared, setIsShared] = useState(true);
    const [textDriver, setTextDriver] = useState(false);
    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    useEffect((): void => {
        // only fetch if we don't already have the details in redux
        if (shipmentUniqueName !== shipment.shipmentUniqueName) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipment.shipmentUniqueName, dispatch]);

    useEffect((): void => {
        // only fetch if we refreshed and cleared our shipment status back to idle
        if (shipmentStatus === ApiStatus.Idle) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipmentStatus, dispatch]);

    // Once the shipment details api succeeds, update the currentStop to be the initialStopSequence.
    useEffect((): void => {
        if (shipmentStatus === ApiStatus.Success) {
            setCurrentStop(shipment.stops[initialStopSequence || 0]);
        }
    }, [shipmentStatus, shipment.stops, initialStopSequence]);

    // Validate the document upload values and determine if form can be submitted
    useEffect((): void => {
        if (currentTab === NotesTab.Shipment) {
            setCanSubmitForm(noteText !== '');
        }
        if (currentTab === NotesTab.Stops) {
            setCanSubmitForm(noteText !== '' && currentStop !== null && currentStop.shipmentStopGUID !== null);
        }
    }, [currentTab, currentStop, noteText]);

    // If the API update is successful, trigger the dialog to reset
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            setNoteText('');
            dispatch(updatePageReset());
        }
    }, [status, dispatch]);

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        if (currentTab === NotesTab.Shipment) {
            const body = {
                shipmentUniqueName,
                text: noteText,
                isShared,
                textDriver
            };
            dispatch(addShipmentNote(body));
        }

        if (currentTab === NotesTab.Stops && currentStop && currentStop.shipmentStopGUID) {
            const body = {
                shipmentUniqueName,
                shipmentStopGUID: currentStop.shipmentStopGUID,
                text: noteText,
                isShared,
                textDriver
            };
            dispatch(addShipmentStopNote(body));
        }
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Fragment>
                <Tabs
                    value={currentTab}
                    onChange={(event, value: number): void => {
                        setCurrentTab(value);
                    }}
                    disabled={canSubmitForm}
                    centered
                    indicatorColor='primary'
                    textColor='primary'
                    data-qa='notes-tabList'
                >
                    <Tab
                        label='Shipment Notes'
                        value={NotesTab.Shipment}
                        data-qa='shipmentNotes-tab'
                    />

                    <Tab
                        label='Stop Notes'
                        value={NotesTab.Stops}
                        data-qa='stopNotes-tab'
                    />
                </Tabs>

                {
                    currentTab === NotesTab.Shipment &&
                    <ShipmentNoteList />
                }
                {
                    currentTab === NotesTab.Stops &&
                    <Fragment>
                        {
                            shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading ?
                                (
                                    <Fragment>
                                        <Skeleton variant='rectangular' width='100%' height='48px' />
                                        <StopContactLoading />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <ShipmentStopSelect
                                            isDisabled={false}
                                            stops={shipment.stops}
                                            currentStop={currentStop}
                                            handleChange={(value: Stop | null): void => {
                                                setCurrentStop(value);
                                            }}
                                        />
                                        <StopContact
                                            street1={currentStop?.address.streetAddress1 || null}
                                            street2={currentStop?.address.streetAddress2 || null}
                                            city={currentStop?.address.city || null}
                                            region={currentStop?.address.state || null}
                                            postal={currentStop?.address.postalCode || null}
                                            country={currentStop?.address.countryCode || null}
                                            contactName={currentStop?.contact.name || null}
                                            phoneNumber={currentStop?.contact.phoneNumber || null}
                                            phoneExtension={currentStop?.contact.phoneExtension || null}
                                        />
                                    </Fragment>
                                )
                        }
                        <ShipmentStopNoteList
                            currentStop={currentStop}
                        />
                    </Fragment>
                }
                { /* If the shipment or stop is not shared, allow notes to be added */
                    shareType === null &&
                    <StyledGrid container>
                        <Grid item xs={12}>
                            <TextField
                                label='Note Text'
                                placeholder='Type Note Here'
                                variant='filled'
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 500 }}
                                value={noteText}
                                onChange={(event): void => {
                                    setNoteText(event.currentTarget.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isShared}
                                                onChange={(event): void => {
                                                    setIsShared(event.target.checked);
                                                }}
                                                name='isNoteShared'
                                                color='primary'
                                            />
                                        }
                                        label='Share Note'
                                    />
                                </Grid>

                                <Grid item className={classes.smsDriverSwitch}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={textDriver}
                                                onChange={(event): void => {
                                                    setTextDriver(event.target.checked);
                                                }}
                                                name='textDriver'
                                                color='primary'
                                            />
                                        }
                                        label='Text Driver'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                            errorMessage &&
                            <Grid item xs={12}>
                                <Typography variant='caption' color='error'>{errorMessage}</Typography>
                            </Grid>
                        }
                    </StyledGrid>
                }
            </Fragment>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            fullWidth={true}
            headerIcon={mdiMessageBulleted}
            headerText='Notes'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='close-button'
                    >
                        Close
                    </Button>
                    {
                        shareType === null &&
                        <Button
                            color='primary'
                            variant='contained'
                            disabled={status === ApiStatus.Loading || !canSubmitForm}
                            onClick={handleSave}
                            startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                            data-qa='save-button'
                        >
                            {
                                status === ApiStatus.Loading ? 'Adding Note' : 'Add Note'
                            }
                        </Button>
                    }
                </Fragment>
            )}
        />
    );
};

export default NotesDialog;
