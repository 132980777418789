import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import jwtDecode from 'jwt-decode';
import axios, { AxiosResponse } from 'axios';

import './assets/CSS/persistGateLoader.css';
import App from './App';
import createAppStore from './redux/store';
import { publicRoutes } from './routes/appRouteList';
import { ApiStatus, ShareType } from './helpers/enums';
import { routeComparator } from './helpers/navigationUtils';
import { ServiceLocatorEndpoints } from './interfaces/services/serviceLocator';
import AuthService from './services/authService';
import { setLoginRedirectUrl, setUserLoginStatus, setShareType } from './redux/user';
import Endpoints from './services/endpoints';
import { GenericApiResponse } from './interfaces/services';

interface SharingToken {
    '10-4/LegacyOrganizationId': string;
    '10-4/OrganizationId': string;
    '10-4/OrganizationName': string;
    SharedViewGUID?: string;
    ShipmentUniqueName?: string;
    StopSequenceNumber?: string;
    aud: string;
    exp: number;
    iss: string;
    nbf: number;
}

const { persistor, store } = createAppStore();

// Initialize the AuthService, setup history and interceptors before the gate lifts
const onBeforeLift = async (): Promise<void> => {
    await new Promise((resolve): void => {
        /* eslint-disable consistent-return */
        const unsubscribe = store.subscribe(async (): Promise<void> => {
            const { status, endpoints }: { status: ApiStatus; endpoints: ServiceLocatorEndpoints; } = store.getState().availableServices;
            // check if the service locator call has completed
            if (status !== ApiStatus.Idle && status !== ApiStatus.Loading) {
                // Once we have fetched the endpoints from SL, we no longer want to subscribe to the change listener
                unsubscribe();

                const queryString = new URLSearchParams(window.location.search);
                const queryStringObject = Object.fromEntries(queryString.entries());

                // If the access_token is present on the URL query string OR there is an access token in the store, we are in some sort of "shared" view.
                // WHY look at two tokens? In a shared view situation, we can "lose" the token from the url when they navigate around the various pages they have access to,
                // so when they first come to a "shared" page (view, shipment, stop), we will store that token directly into the store.
                // Then no matter what happens to the URL (i.e. if they lose the token the URL), we can use the store version as our backup. This is the unexchanged
                // token that can be run back through all of the logic below and will not take them to the login page.
                if (queryStringObject.access_token || store.getState().user.accessToken) {
                    // Determine the token to use in the logic below - first defaulting to the token from the URL
                    // OR the token that is stored in the state.
                    const tokenToUse = queryStringObject.access_token || store.getState().user.accessToken;
                    try {
                        const decodedToken: SharingToken = jwtDecode(tokenToUse);

                        // If the token is for a shared view, it needs to be exchanged for a real token to use on the rest of the api's
                        if (decodedToken.SharedViewGUID !== undefined) {
                            try {
                                const json = await axios.get(`${endpoints.ShipmentsApi}${Endpoints.shipmentApi.tokenExchange}`, {
                                    headers: {
                                        Authorization: `Bearer ${tokenToUse}`
                                    }
                                }) as AxiosResponse<GenericApiResponse<string>>;
                                const { data }: { data: GenericApiResponse<string>; } = json;
                                if (data.data.length > 0) {
                                    const apiToken = data.data[0];
                                    // set the auth token to the access token from the token exchange api response, which will be used for any API calls
                                    AuthService.sharingTokenAuthorization(apiToken);
                                    // call masterdata to hydrate the cache for filters
                                    axios.post(`${store.getState().availableServices.endpoints.MasterData}${Endpoints.masterDataApi.hydrateFilterCache}`, {}, {
                                        headers: {
                                            Authorization: `Bearer ${apiToken}`
                                        }
                                    });
                                    // set share type in the store to "view" and pass the unexchanged token to keep in the store
                                    store.dispatch(setShareType(ShareType.View, tokenToUse));
                                    store.dispatch(setUserLoginStatus(ApiStatus.Success));
                                } else {
                                    throw new Error('Missing exchange token');
                                }
                            } catch (err) {
                                console.error('Error occurred while exchanging a shared view token.');
                                store.dispatch(setUserLoginStatus(ApiStatus.Failure));
                            }
                        } else {
                            // set the auth token to the access token from URL, which will be used for any API calls
                            AuthService.sharingTokenAuthorization(tokenToUse);
                            // if there is a StopSequenceNumber it is a shared stop else it is a shared shipment
                            // and pass the token to use to keep in the store
                            store.dispatch(setShareType(decodedToken.StopSequenceNumber !== undefined ? ShareType.Stop : ShareType.Shipment, tokenToUse));
                            store.dispatch(setUserLoginStatus(ApiStatus.Success));
                        }
                    } catch (err) {
                        console.error('Error decoding access token in url.');
                        store.dispatch(setShareType(ShareType.Unknown, ''));
                        store.dispatch(setUserLoginStatus(ApiStatus.Failure));
                    }
                } else if (endpoints.AuthService) {
                    AuthService.initialize(endpoints.AuthService);

                    const isPublicRoute = routeComparator(Object.values(publicRoutes), window.location.pathname);
                    if (isPublicRoute === false) {
                        store.dispatch(setLoginRedirectUrl(window.location.pathname));
                    }

                    store.dispatch(setUserLoginStatus(ApiStatus.Loading));
                } else {
                    // Currently redux throws an error if we don't get an AuthService entry from service locator, but this is to guard against potential changes
                    console.error('Service Locator was unable to locate the AuthService.');
                    store.dispatch(setUserLoginStatus(ApiStatus.Failure));
                }

                // persistor.flush() immediately writes all pending state to disk and returns a promise
                // WHY? because for some reason the above dispatches were not getting saved between the bootstrap #1 for the login component and bootstrap #2 when it goes to the authorize component
                // This allowed the above values to be rehydrated on the second bootstrap so that they were there to use after logging in.
                await persistor.flush();

                // Lift the gate and let the app navigate to correct page.
                return resolve(true);
            }
        });
        /* eslint-enable consistent-return */
    });
};

const container = document.getElementById('transportation-root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <PersistGate
            loading={<div className='loader' />}
            persistor={persistor}
            onBeforeLift={onBeforeLift}
        >
            <App />
        </PersistGate>
    </Provider>
);
