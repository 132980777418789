import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'noteCardLoading';

const classes = {
    noteContainer: `${classesPrefix}-noteContainer`,
    noteDetails: `${classesPrefix}-noteDetails`,
    sentToDriver: `${classesPrefix}-sentToDriver`
};

const StyledCard = styled(Card)(() => {
    return {
        [`&.${classes.noteContainer}`]: {
            marginBottom: '8px'
        },
        [`& .${classes.noteDetails}`]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '8px'
        },
        [`& .${classes.sentToDriver}`]: {
            display: 'flex'
        }
    };
});

const NoteCardLoading = (): JSX.Element => {
    return (
        <StyledCard className={classes.noteContainer} variant='outlined'>
            <CardContent>
                <Typography variant='body2' component='p'>
                    <Skeleton variant='text' width='100%' height='20px' />
                    <Skeleton variant='text' width='100%' height='20px' />
                </Typography>
                <div className={classes.noteDetails}>
                    <span className={classes.noteDetails}>
                        <Skeleton variant='circular' width='20px' height='20px' />
                        <Skeleton variant='text' width='80px' height='24px' />
                    </span>
                    <Skeleton variant='text' width='120px' height='20px' />
                </div>
                <div className={classes.sentToDriver}>
                    <Skeleton variant='circular' width='20px' height='20px' />
                    <Skeleton variant='text' width='120px' height='24px' />
                </div>
            </CardContent>
        </StyledCard>
    );
};

export default NoteCardLoading;
