import {
    MapImages,
    MapSources,
    MapLayers,
    MapLayerTypes,
    OrderDetailsFeatureTypes
} from '../enums';
import { MapLayer } from './interfaces';

/**
 * Creates a layer to display images on the map for each Point and 'featureType' of 'stop' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapOrderDetailsStopsLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.orderDetailsStopsLayer,
        type: MapLayerTypes.Symbol,
        source: mapSource,
        layout: {
            'icon-image': '{image}',
            'icon-size': 0.5,
            'icon-offset': ['get', 'iconOffset'],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-field': '{title}',
            'text-font': ['Roboto Medium'],
            'text-offset': ['get', 'textOffset'],
            'text-anchor': 'top',
            'text-allow-overlap': true
        },
        paint: {
            'text-color': ['get', 'textColor']
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', OrderDetailsFeatureTypes.OrderStop]
        ]
    };

    return layer;
};

/**
 * Adds the order details stops layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addOrderDetailsStopsLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.orderDetailsStopsLayer) === undefined) {
        const orderDetailsStopsLayer = getMapOrderDetailsStopsLayerGeo(mapSource);
        map.addLayer(orderDetailsStopsLayer);
    }
};

/**
 * Creates a layer to display a geofence polygon fill on the map for each Polygon and 'featureType' of 'geofence' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapOrderDetailsGeofenceFillLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.orderDetailsGeofenceFillLayer,
        type: MapLayerTypes.Fill,
        source: mapSource,
        paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.2
        },
        filter: ['all',
            ['==', '$type', 'Polygon'],
            ['==', 'featureType', OrderDetailsFeatureTypes.Geofence]
        ]
    };

    return layer;
};

/**
 * Creates a layer to display a geofence polygon outline on the map for each Polygon and 'featureType' of 'geofence' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapOrderDetailsGeofenceOutlineLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.orderDetailsGeofenceOutlineLayer,
        type: MapLayerTypes.Line,
        source: mapSource,
        paint: {
            'line-color': ['get', 'outlineColor'],
            'line-width': 2
        },
        filter: ['all',
            ['==', '$type', 'Polygon'],
            ['==', 'featureType', OrderDetailsFeatureTypes.Geofence]
        ]
    };

    return layer;
};

/**
 * Adds the order details geofence fill layer to the map if it doesn't already exist.
 * Adds the order details geofence outline layer to the map if it doesn't already exist.
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addOrderDetailsGeofenceLayers = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.orderDetailsGeofenceFillLayer) === undefined) {
        const orderDetailsGeofenceFillLayer = getMapOrderDetailsGeofenceFillLayerGeo(mapSource);
        map.addLayer(orderDetailsGeofenceFillLayer);
    }
    if (map.getLayer(MapLayers.orderDetailsGeofenceOutlineLayer) === undefined) {
        const orderDetailsGeofenceOutlineLayer = getMapOrderDetailsGeofenceOutlineLayerGeo(mapSource);
        map.addLayer(orderDetailsGeofenceOutlineLayer);
    }
};

/**
 * Creates a layer to display a circle on the map for each Point and 'featureType' of 'currentPosition' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapOrderDetailsCurrentPositionLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.orderDetailsCurrentPositionLayer,
        type: MapLayerTypes.Circle,
        source: mapSource,
        paint: {
            'circle-color': ['get', 'color'],
            'circle-opacity': 1,
            'circle-radius': 8,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#fff'
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', OrderDetailsFeatureTypes.CurrentPosition],
            ['==', 'hasPulse', false]
        ]
    };

    return layer;
};

/**
 * Adds the order details current position layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addOrderDetailsCurrentPositionLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.orderDetailsCurrentPositionLayer) === undefined) {
        const orderDetailsCurrentPositionLayer = getMapOrderDetailsCurrentPositionLayerGeo(mapSource);
        map.addLayer(orderDetailsCurrentPositionLayer);
    }
};

/**
 * Creates a layer to display a pulsing circle on the map for each Point and 'featureType' of 'currentPosition' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapOrderDetailsCurrentPositionPulseLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.orderDetailsCurrentPositionPulseLayer,
        type: MapLayerTypes.Symbol,
        source: mapSource,
        layout: {
            'icon-image': MapImages.currentLocationMapMarker,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', OrderDetailsFeatureTypes.CurrentPosition],
            ['==', 'hasPulse', true]
        ]
    };

    return layer;
};

/**
 * Adds the order details current position pulse layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addOrderDetailsCurrentPositionPulseLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.orderDetailsCurrentPositionPulseLayer) === undefined) {
        const orderDetailsCurrentPositionPulseLayer = getMapOrderDetailsCurrentPositionPulseLayerGeo(mapSource);
        map.addLayer(orderDetailsCurrentPositionPulseLayer);
    }
};
