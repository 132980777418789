import React, { Fragment, useState } from 'react';
import {
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight, mdiDotsVertical } from '@mdi/js';
import { ToolbarProps, View } from 'react-big-calendar';

import { CalendarView, CalendarNavigation } from '../../helpers/enums';
import Hidden from '../layouts/hidden';

const classesPrefix = 'calendarToolbar';

const classes = {
    gridWrapper: `${classesPrefix}-gridWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.gridWrapper}`]: {
            padding: '4px 16px'
        },
        [theme.breakpoints.only('xs')]: {
            [`&.${classes.gridWrapper}`]: {
                flexWrap: 'nowrap',
                paddingLeft: '4px',
                paddingRight: '4px'
            }
        }
    };
});

const CalendarToolbar = (props: ToolbarProps): JSX.Element => {
    const { onView, onNavigate, view } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: View): void => {
        onView(newView);
    };

    const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (): void => {
        setAnchorEl(null);
    };

    const handleMenuClick = (event: React.MouseEvent): void => {
        const { id } = event.currentTarget;
        if (id === CalendarNavigation.Today) {
            onNavigate(id);
        } else if (id === CalendarView.Month || id === CalendarView.Week || id === CalendarView.Day) {
            onView(id);
        }
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <StyledGrid container justifyContent='space-between' alignItems='center' className={classes.gridWrapper}>
                <Hidden breakpoint='md' direction='up'>
                    <Grid item>
                        <IconButton
                            onClick={(): void => {
                                onNavigate(CalendarNavigation.Previous);
                            }}
                            data-qa='calendar-action-previous'
                        >
                            <Icon
                                path={mdiChevronLeft}
                                size={1}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography>{props.label}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={(): void => {
                                onNavigate(CalendarNavigation.Next);
                            }}
                            data-qa='calendar-action-next'
                        >
                            <Icon
                                path={mdiChevronRight}
                                size={1}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleIconClick}
                        >
                            <Icon
                                path={mdiDotsVertical}
                                size={1}
                            />
                        </IconButton>
                    </Grid>
                </Hidden>
                <Hidden breakpoint='md' direction='down'>
                    <Grid item>
                        <Button
                            variant='outlined'
                            size='medium'
                            onClick={(): void => {
                                onNavigate(CalendarNavigation.Today);
                            }}
                            data-qa='calendar-action-today'
                        >
                            Today
                        </Button>
                        <IconButton
                            onClick={(): void => {
                                onNavigate(CalendarNavigation.Previous);
                            }}
                            data-qa='calendar-action-previous'
                        >
                            <Icon
                                path={mdiChevronLeft}
                                size={1}
                            />
                        </IconButton>
                        <IconButton
                            onClick={(): void => {
                                onNavigate(CalendarNavigation.Next);
                            }}
                            data-qa='calendar-action-next'
                        >
                            <Icon
                                path={mdiChevronRight}
                                size={1}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant='h5'>{props.label}</Typography>
                    </Grid>
                    <Grid item>
                        <ToggleButtonGroup
                            aria-label='month, week, day button group'
                            color='primary'
                            size='small'
                            value={view}
                            exclusive
                            onChange={handleViewChange}
                        >
                            <ToggleButton
                                value={CalendarView.Month}
                                selected={view === CalendarView.Month}
                                data-qa='calendar-action-month'
                            >
                                Month
                            </ToggleButton>
                            <ToggleButton
                                value={CalendarView.Week}
                                selected={view === CalendarView.Week}
                                data-qa='calendar-action-week'
                            >
                                Week
                            </ToggleButton>
                            <ToggleButton
                                value={CalendarView.Day}
                                selected={view === CalendarView.Day}
                                data-qa='calendar-action-day'
                            >
                                Day
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Hidden>
            </StyledGrid>
            <Hidden breakpoint='md' direction='up'>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    className='calendarActions-menu'
                >
                    <MenuItem
                        id={CalendarNavigation.Today}
                        onClick={handleMenuClick}
                        className='calendar-action-today-mobile'
                    >
                        Today
                    </MenuItem>
                    <MenuItem
                        id={CalendarView.Month}
                        onClick={handleMenuClick}
                        className='calendar-action-month-mobile'
                    >
                        Month
                    </MenuItem>
                    <MenuItem
                        id={CalendarView.Week}
                        onClick={handleMenuClick}
                        className='calendar-action-week-mobile'
                    >
                        Week
                    </MenuItem>
                    <MenuItem
                        id={CalendarView.Day}
                        onClick={handleMenuClick}
                        className='calendar-action-day-mobile'
                    >
                        Day
                    </MenuItem>
                </Menu>
            </Hidden>
        </Fragment>
    );
};

export default CalendarToolbar;
