import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CalendarStopType, DeliveryStatus } from '../../helpers/enums';
import { getDeliveryStatusColor, getDeliveryStatusLightColor } from '../../helpers/styleHelpers';

interface StyleProps {
    id: CalendarStopType | DeliveryStatus;
}

const classesPrefix = 'calendarFiltersCheckbox';

const classes = {
    formControlLabelRoot: `${classesPrefix}-formControlLabelRoot`,
    formControlLabel: `${classesPrefix}-formControlLabel`,
    labelWrapper: `${classesPrefix}-labelWrapper`,
    statusIndicator: `${classesPrefix}-statusIndicator`,
    dateTypeIndicator: `${classesPrefix}-dateTypeIndicator`,
    description: `${classesPrefix}-description`,
    label: `${classesPrefix}-label`
};

const StyledFormControlLabel = styled(
    FormControlLabel,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'id'; }
    }
)<StyleProps>(({ id, theme }) => {
    const getBackgroundColor = (): string => {
        switch (id) {
            case CalendarStopType.Delivery:
            case CalendarStopType.Pickup:
                return theme.palette.common.white;
            case DeliveryStatus.TenderAccepted:
            case DeliveryStatus.Early:
            case DeliveryStatus.OnTime:
            case DeliveryStatus.InJeopardy:
            case DeliveryStatus.Late:
            case DeliveryStatus.TrackingLost:
            default:
                return getDeliveryStatusColor(id);
        }
    };

    const getBorderColor = (): string => {
        switch (id) {
            case CalendarStopType.Delivery:
            case CalendarStopType.Pickup:
                return theme.palette.common.white;
            case DeliveryStatus.TenderAccepted:
            case DeliveryStatus.Early:
            case DeliveryStatus.OnTime:
            case DeliveryStatus.InJeopardy:
            case DeliveryStatus.Late:
            case DeliveryStatus.TrackingLost:
            default:
                return getDeliveryStatusLightColor(id);
        }
    };

    return {
        [`&.${classes.formControlLabelRoot}`]: {
            marginRight: 0
        },
        [`& .${classes.formControlLabel}`]: {
            width: '100%'
        },
        [`& .${classes.labelWrapper}`]: {
            display: 'inline-flex',
            alignItems: 'center',
            paddingTop: '3px',
            width: '100%'
        },
        [`& .${classes.statusIndicator}`]: {
            width: '16px',
            height: '16px',
            marginRight: '4px',
            backgroundColor: getBackgroundColor(),
            border: `1px solid ${getBorderColor()}`
        },
        [`& .${classes.dateTypeIndicator}`]: {
            marginLeft: '4px',
            marginRight: '8px'
        },
        [`& .${classes.description}`]: {
            flexGrow: 1
        },
        [`& .${classes.label}`]: {
            fontSize: '14px'
        }
    };
});

const CalendarFiltersCheckbox = ({
    id,
    isChecked,
    label,
    handleCheckboxChange,
    'data-qa': dataQa
}: {
    id: CalendarStopType | DeliveryStatus;
    isChecked: boolean;
    label: string;
    handleCheckboxChange: (isChecked: boolean) => void;
    'data-qa': string;
}): JSX.Element => {
    return (
        <StyledFormControlLabel
            id={id}
            classes={{
                root: classes.formControlLabelRoot,
                label: classes.formControlLabel
            }}
            control={
                <Checkbox
                    id={id}
                    color='primary'
                    checked={isChecked}
                    onChange={(event): void => {
                        handleCheckboxChange(event.target.checked);
                    }}
                    data-qa={`${dataQa}-checkbox`}
                />
            }
            label={
                <div className={classes.labelWrapper}>
                    {
                        id in DeliveryStatus &&
                        <div className={classes.statusIndicator} />
                    }
                    {
                        id in CalendarStopType &&
                        <Typography className={`${classes.label} ${classes.dateTypeIndicator}`} component='span'>
                            {id.charAt(0)}
                        </Typography>
                    }
                    <Typography className={`${classes.label} ${classes.description}`} component='span'>{label}</Typography>
                </div>
            }
            data-qa={dataQa}
        />
    );
};

export default CalendarFiltersCheckbox;
