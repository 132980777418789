import React, { useState, useEffect, useReducer, useRef, useCallback } from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import {
    Badge,
    Button,
    CircularProgress,
    TextField,
    Grid,
    Typography,
    Dialog,
    Toolbar,
    Skeleton,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';

import { GenericAction, useTypedSelector } from '../../redux';
import { createNotification, resetNotificationDetails, updateNotificationDetails } from '../../redux/notifications';
import { notificationDetailsInitialState } from '../../redux/notifications/initialState';
import { NotificationDetails, NotificationFilter } from '../../interfaces/services/notifications';
import { NotificationFilterState } from '../../interfaces/filterInterfaces';
import {
    ApiStatus,
    NotificationRecipientType,
    NotificationTypeLabel,
    NotificationTypes,
    TimePriorityType,
    AllowedNotificationFilterPropertyName,
    ShareType
} from '../../helpers/enums';
import { notificationTypeLabelOptions } from '../../helpers/hardcodedOptionLists';
import NotificationTypeOptions from './notificationTypeOptions';
import NotificationLevelOptions from './notificationLevelOptions';
import NotificationFilterDialog from '../dialogs/notificationFilterDialog';
import UnsavedChangesDialog from '../dialogs/unsavedChangesDialog';

const UPDATE_NAME = 'UPDATE_NAME';
const UPDATE_RECIPIENT_TYPE = 'UPDATE_RECIPIENT_TYPE';
const ADD_SHIPMENT_LEVEL_SMS_ENTRY = 'ADD_SHIPMENT_LEVEL_SMS_ENTRY';
const ADD_SHIPMENT_LEVEL_EMAIL_ENTRY = 'ADD_SHIPMENT_LEVEL_EMAIL_ENTRY';
const DELETE_SHIPMENT_LEVEL_SMS_ENTRY = 'DELETE_SHIPMENT_LEVEL_SMS_ENTRY';
const DELETE_SHIPMENT_LEVEL_EMAIL_ENTRY = 'DELETE_SHIPMENT_LEVEL_EMAIL_ENTRY';
const UPDATE_STOP_LEVEL_SMS_CHECKBOX = 'UPDATE_STOP_LEVEL_SMS_CHECKBOX';
const UPDATE_STOP_LEVEL_EMAIL_CHECKBOX = 'UPDATE_STOP_LEVEL_EMAIL_CHECKBOX';
const UPDATE_NOTIFICATION_TYPE_LABEL = 'UPDATE_NOTIFICATION_TYPE_LABEL';
const TOGGLE_NOTIFICATION_TYPE = 'TOGGLE_NOTIFICATION_TYPE';
const CLEAR_NOTIFICATION_TYPES = 'CLEAR_NOTIFICATION_TYPES';
const UPDATE_ETA_MINUTES_AWAY = 'UPDATE_ETA_MINUTES_AWAY';
const UPDATE_MINUTES_FROM = 'UPDATE_MINUTES_FROM';
const UPDATE_TIME_PRIORITY_TYPE = 'UPDATE_TIME_PRIORITY_TYPE';
const UPDATE_FILTERS = 'UPDATE_FILTERS';
const INITIALIZE_NOTIFICATION_DETAILS = 'INITIALIZE_NOTIFICATION_DETAILS';

const classesPrefix = 'notificationsForm';

const classes = {
    formWrapper: `${classesPrefix}-formWrapper`,
    form: `${classesPrefix}-form`,
    notificationNameWrapper: `${classesPrefix}-notificationNameWrapper`,
    notificationNameInput: `${classesPrefix}-notificationNameInput`,
    notificationTypeWrapper: `${classesPrefix}-notificationTypeWrapper`,
    actionsContainer: `${classesPrefix}-actionsContainer`,
    button: `${classesPrefix}-button`,
    radioSkeleton: `${classesPrefix}-radioSkeleton`,
    radioSkeletonLabel: `${classesPrefix}-radioSkeletonLabel`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.formWrapper}`]: {
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        [`& .${classes.form}`]: {
            padding: '16px',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        [`& .${classes.notificationNameWrapper}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '12px'
        },
        [`& .${classes.notificationNameInput}`]: {
            minWidth: '300px',
            maxWidth: '400px',
            marginRight: '16px'
        },
        [`& .${classes.notificationTypeWrapper}`]: {
            marginTop: '8px'
        },
        [`& .${classes.radioSkeleton}`]: {
            display: 'inline-flex',
            alignItems: 'center'
        },
        [`& .${classes.radioSkeletonLabel}`]: {
            margin: '0 8px',
            fontSize: '12px'
        },
        [`& .${classes.actionsContainer}`]: {
            padding: '6px 16px',
            borderTop: `1px solid ${theme.palette.divider}`
        },
        [`& .${classes.button}`]: {
            marginRight: '8px'
        },
        [theme.breakpoints.down('sm')]: {
            [`& .${classes.notificationNameInput}`]: {
                minWidth: 'unset',
                marginRight: 0
            }
        }
    };
});

const NotificationsForm = ({
    handleFormDirtyChange,
    isFormDirty,
    handleCancelClick,
    isDialogOpen
}: {
    handleFormDirtyChange: (isDirty: boolean) => void;
    isFormDirty: boolean;
    handleCancelClick: () => void;
    isDialogOpen: boolean;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Holds user inputs for email and SMS until they are checked valid and entered into draft state
    const [draftSmsTextField, setDraftSmsTextField] = useState('');
    const [draftEmailTextField, setDraftEmailTextField] = useState('');

    const [isLevelOptionsDirty, setIsLevelOptionsDirty] = useState(false);
    const [canSaveNotificationForm, setCanSaveNotificationForm] = useState(false);
    const [unsavedChangesDialogIsOpen, setUnsavedChangesDialogIsOpen] = useState(false);

    const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);
    // We format the filters so the dialog better understands them but also to get the proper filter count
    const [formattedFilters, setFormattedFilters] = useState<{ [K in AllowedNotificationFilterPropertyName]?: string[] }>({});

    // Used to reference our <form> for validity checks
    const formControlWrapper = useRef<HTMLFormElement>(null);

    const notificationDetailsStatus = useTypedSelector((state) => { return state.notifications.notificationDetailsStatus; });
    const notificationDetailsUpdateStatus = useTypedSelector((state) => { return state.notifications.notificationDetailsUpdateStatus; });
    const notificationDetails = useTypedSelector((state) => { return state.notifications.notificationDetails; });
    const skip = useTypedSelector((state) => { return state.notifications.skip; });
    const searchTerm = useTypedSelector((state) => { return state.notifications.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.notifications.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.notifications.sortDirection; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const reducer = (
        state: NotificationDetails,
        action: GenericAction<typeof UPDATE_NAME, { notificationName: string; }> |
            GenericAction<typeof UPDATE_RECIPIENT_TYPE, { notificationRecipientType: NotificationRecipientType; }> |
            GenericAction<typeof ADD_SHIPMENT_LEVEL_SMS_ENTRY, { newSms: string; }> |
            GenericAction<typeof ADD_SHIPMENT_LEVEL_EMAIL_ENTRY, { newEmail: string; }> |
            GenericAction<typeof DELETE_SHIPMENT_LEVEL_SMS_ENTRY, { sms: string; }> |
            GenericAction<typeof DELETE_SHIPMENT_LEVEL_EMAIL_ENTRY, { email: string; }> |
            GenericAction<typeof UPDATE_STOP_LEVEL_SMS_CHECKBOX, { notifyStopContactsBySms: boolean; }> |
            GenericAction<typeof UPDATE_STOP_LEVEL_EMAIL_CHECKBOX, { notifyStopContactsByEmail: boolean; }> |
            GenericAction<typeof UPDATE_NOTIFICATION_TYPE_LABEL, { notificationTypeLabel: NotificationTypeLabel; }> |
            GenericAction<typeof TOGGLE_NOTIFICATION_TYPE, { notification: NotificationTypes; }> |
            Action<typeof CLEAR_NOTIFICATION_TYPES> |
            GenericAction<typeof UPDATE_ETA_MINUTES_AWAY, { minutes: number; }> |
            GenericAction<typeof UPDATE_MINUTES_FROM, { minutesFrom: number; }> |
            GenericAction<typeof UPDATE_TIME_PRIORITY_TYPE, { priorityType: TimePriorityType; }> |
            GenericAction<typeof UPDATE_FILTERS, { allFilters: NotificationFilterState; }> |
            Action<typeof INITIALIZE_NOTIFICATION_DETAILS>
    ): NotificationDetails => {
        switch (action.type) {
            case UPDATE_NAME: {
                return {
                    ...state,
                    notificationName: action.payload.notificationName
                };
            }
            case UPDATE_RECIPIENT_TYPE: {
                return {
                    ...state,
                    notificationRecipientType: action.payload.notificationRecipientType
                };
            }
            case ADD_SHIPMENT_LEVEL_SMS_ENTRY: {
                return {
                    ...state,
                    smsList: [...state.smsList, action.payload.newSms]
                };
            }
            case ADD_SHIPMENT_LEVEL_EMAIL_ENTRY: {
                return {
                    ...state,
                    emailList: [...state.emailList, action.payload.newEmail]
                };
            }
            case DELETE_SHIPMENT_LEVEL_SMS_ENTRY: {
                return {
                    ...state,
                    smsList: state.smsList.filter((sms) => { return (sms !== action.payload.sms); })
                };
            }
            case DELETE_SHIPMENT_LEVEL_EMAIL_ENTRY: {
                return {
                    ...state,
                    emailList: state.emailList.filter((email) => { return (email !== action.payload.email); })
                };
            }
            case UPDATE_STOP_LEVEL_SMS_CHECKBOX: {
                return {
                    ...state,
                    notifyStopContactsBySms: action.payload.notifyStopContactsBySms
                };
            }
            case UPDATE_STOP_LEVEL_EMAIL_CHECKBOX: {
                return {
                    ...state,
                    notifyStopContactsByEmail: action.payload.notifyStopContactsByEmail
                };
            }
            case UPDATE_NOTIFICATION_TYPE_LABEL: {
                return {
                    ...state,
                    notificationTypeLabel: action.payload.notificationTypeLabel
                };
            }
            case TOGGLE_NOTIFICATION_TYPE: {
                return {
                    ...state,
                    notificationTypes: state.notificationTypes.includes(action.payload.notification)
                        ? state.notificationTypes.filter((notification) => { return (notification !== action.payload.notification); })
                        : [...state.notificationTypes, action.payload.notification]
                };
            }
            case CLEAR_NOTIFICATION_TYPES: {
                return {
                    ...state,
                    notificationTypes: []
                };
            }
            case UPDATE_ETA_MINUTES_AWAY: {
                return {
                    ...state,
                    notificationMetaData: action.payload.minutes
                };
            }
            case UPDATE_MINUTES_FROM: {
                return {
                    ...state,
                    minutes: action.payload.minutesFrom
                };
            }
            case UPDATE_TIME_PRIORITY_TYPE: {
                return {
                    ...state,
                    timePriorityType: action.payload.priorityType
                };
            }
            case UPDATE_FILTERS: {
                // Create an array separating out each propertyValue to create an array of objects that has one propertyName and one propertyValue per object as this is what the api needs
                const filtersList = Object.entries(action.payload.allFilters).reduce((accumulator: NotificationFilter[], [filterName, filterValues]): NotificationFilter[] => {
                    if (filterValues.length > 0) {
                        for (let i = 0; i < filterValues.length; i++) {
                            accumulator.push({
                                propertyName: filterName as AllowedNotificationFilterPropertyName,
                                propertyValue: filterValues[i]
                            });
                        }
                    }
                    return accumulator;
                }, []);

                return {
                    ...state,
                    filters: filtersList
                };
            }
            case INITIALIZE_NOTIFICATION_DETAILS: {
                return notificationDetails;
            }
            default: {
                return state;
            }
        }
    };

    const [draftNotificationDetails, localDispatch] = useReducer(reducer, notificationDetailsInitialState);

    // This is used to format the filters into an object with the propertyName as the key and an array of propertyValues
    // In addition we use this to know how many unique filters are selected
    const combineFilters = useCallback((): { [K in AllowedNotificationFilterPropertyName]?: string[] } => {
        // combine the individual filters received from the api into what the dialog expects
        return draftNotificationDetails.filters.reduce((
            (accumulator: { [K in AllowedNotificationFilterPropertyName]?: string[] }, { propertyName, propertyValue }): {
                [K in AllowedNotificationFilterPropertyName]?: string[]
            } => {
                // if the propertyName doesnt exist on the accumulator object, add it
                if (!accumulator[propertyName]) {
                    accumulator[propertyName] = [];
                }
                // push the propertyValue onto the propertyName for the accumulator
                accumulator[propertyName]!.push(propertyValue);
                return accumulator;
            }), {});
    }, [draftNotificationDetails.filters]);

    useEffect(() => {
        localDispatch({
            type: INITIALIZE_NOTIFICATION_DETAILS
        });
    }, [notificationDetails]);

    useEffect(() => {
        if (notificationDetailsUpdateStatus === ApiStatus.Success) {
            localDispatch({
                type: INITIALIZE_NOTIFICATION_DETAILS
            });
        }
    }, [notificationDetailsUpdateStatus]);

    useEffect((): void => {
        const combinedFilterValues = combineFilters();
        setFormattedFilters(combinedFilterValues);
    }, [draftNotificationDetails.filters, combineFilters]);

    useEffect(() => {
        handleFormDirtyChange(!isEqual(draftNotificationDetails, notificationDetails) || isLevelOptionsDirty);
    }, [draftNotificationDetails, handleFormDirtyChange, isLevelOptionsDirty, notificationDetails]);

    // Form validity check. checkValidity verifies 'required' fields are completed/filled/checked.
    useEffect(() => {
        const isLoading = notificationDetailsStatus === ApiStatus.Loading || notificationDetailsUpdateStatus === ApiStatus.Loading;
        const isValid = Boolean(formControlWrapper.current?.checkValidity());

        const canSave = isFormDirty && !isLoading && isValid && !isLevelOptionsDirty;
        setCanSaveNotificationForm(canSave);
    }, [
        notificationDetailsStatus,
        draftNotificationDetails,
        notificationDetails,
        notificationDetailsUpdateStatus,
        isFormDirty,
        isLevelOptionsDirty
    ]);

    const handleSetIsLevelOptionsDirty = (isDirty: boolean): void => {
        setIsLevelOptionsDirty(isDirty);
    };

    const handleClearNotificationTypes = (): void => {
        localDispatch({
            type: CLEAR_NOTIFICATION_TYPES
        });
    };

    const handleUpdateNotificationTypeLabel = (notificationTypeLabel: NotificationTypeLabel): void => {
        handleClearNotificationTypes();
        // When switching notificationTypeLabel, remove shipment status filters from the potential filter list if it is Shipment Status Change and the Shipment Status filters have been added
        if (notificationTypeLabel === NotificationTypeLabel.shipmentStatusChange && formattedFilters[AllowedNotificationFilterPropertyName.ShipmentStatus]) {
            // create a new updatedFilters object literal without the Shipment status
            const updatedFilters = Object.keys(formattedFilters).reduce((accumulator, currentValue) => {
                if (currentValue === AllowedNotificationFilterPropertyName.ShipmentStatus) {
                    return accumulator;
                }
                return {
                    ...accumulator,
                    [currentValue]: formattedFilters[currentValue as AllowedNotificationFilterPropertyName]
                };
            }, {});
            setFormattedFilters(updatedFilters);
            toast.info('Shipment Status filter is incompatible with Shipment Status Change notifications and has been removed.');
        } else {
            // recombine all the draft filters
            const combinedFilterValues = combineFilters();
            setFormattedFilters(combinedFilterValues);
        }

        localDispatch({
            type: UPDATE_NOTIFICATION_TYPE_LABEL,
            payload: { notificationTypeLabel }
        });
    };

    const handleUpdateRecipientType = (notificationRecipientType: NotificationRecipientType): void => {
        localDispatch({
            type: UPDATE_RECIPIENT_TYPE,
            payload: { notificationRecipientType }
        });

        // When switching to stopLevel, ensure displayed option is valid. If not, set default of Shipment Status Change
        if (notificationRecipientType === NotificationRecipientType.stopLevel &&
            notificationTypeLabelOptions.find((option) => {
                return draftNotificationDetails.notificationTypeLabel === option.value && option.displayOnStopLevel === false;
            })) {
            handleUpdateNotificationTypeLabel(NotificationTypeLabel.shipmentStatusChange);
        }
    };

    const handleAddShipmentLevelSmsEntry = (newSms: string): void => {
        localDispatch({
            type: ADD_SHIPMENT_LEVEL_SMS_ENTRY,
            payload: { newSms }
        });
    };

    const handleAddShipmentLevelEmailEntry = (newEmail: string): void => {
        localDispatch({
            type: ADD_SHIPMENT_LEVEL_EMAIL_ENTRY,
            payload: { newEmail }
        });
    };

    const handleDeleteShipmentLevelSmsEntry = (sms: string): void => {
        localDispatch({
            type: DELETE_SHIPMENT_LEVEL_SMS_ENTRY,
            payload: { sms }
        });
    };

    const handleDeleteShipmentLevelEmailEntry = (email: string): void => {
        localDispatch({
            type: DELETE_SHIPMENT_LEVEL_EMAIL_ENTRY,
            payload: { email }
        });
    };

    const handleUpdateStopLevelSmsCheckbox = (notifyStopContactsBySms: boolean): void => {
        localDispatch({
            type: UPDATE_STOP_LEVEL_SMS_CHECKBOX,
            payload: { notifyStopContactsBySms }
        });
    };

    const handleUpdateStopLevelEmailCheckbox = (notifyStopContactsByEmail: boolean): void => {
        localDispatch({
            type: UPDATE_STOP_LEVEL_EMAIL_CHECKBOX,
            payload: { notifyStopContactsByEmail }
        });
    };

    const handleToggleNotificationType = (notification: NotificationTypes): void => {
        localDispatch({
            type: TOGGLE_NOTIFICATION_TYPE,
            payload: { notification }
        });
    };

    const handleUpdateEtaMinutesAway = (minutes: number): void => {
        localDispatch({
            type: UPDATE_ETA_MINUTES_AWAY,
            payload: { minutes }
        });
    };

    const handleUpdateMinutesFromTime = (minutesFrom: number): void => {
        localDispatch({
            type: UPDATE_MINUTES_FROM,
            payload: { minutesFrom }
        });
    };

    const handleUpdateTimePriorityType = (priorityType: TimePriorityType): void => {
        localDispatch({
            type: UPDATE_TIME_PRIORITY_TYPE,
            payload: { priorityType }
        });
    };

    const handleCancel = (): void => {
        if (isFormDirty) {
            setUnsavedChangesDialogIsOpen(true);
        } else {
            dispatch(resetNotificationDetails());
            handleCancelClick();
        }
    };

    const handleSubmitClick = (): void => {
        // Cleaning state submission data here allows us to keep inputted user data when switching between options on the form
        // Duplication with draftNotificationDetailsForSubmission keeps state data immutable
        const draftNotificationDetailsForSubmission = { ...draftNotificationDetails };

        if (draftNotificationDetails.notificationRecipientType === NotificationRecipientType.shipmentLevel) {
            // API is expecting both fields to be false if using shipment level notifications
            draftNotificationDetailsForSubmission.notifyStopContactsBySms = false;
            draftNotificationDetailsForSubmission.notifyStopContactsByEmail = false;
        } else if (draftNotificationDetails.notificationRecipientType === NotificationRecipientType.stopLevel) {
            // API is expecting empty arrays if using stop level notifications
            draftNotificationDetailsForSubmission.smsList = [];
            draftNotificationDetailsForSubmission.emailList = [];
        }

        if (draftNotificationDetails.notificationTypeLabel === NotificationTypeLabel.shipmentStatusChange) {
            // Clear out Shipment status filters as they are incompatible with Shipment Status Change notifications
            draftNotificationDetailsForSubmission.filters = draftNotificationDetails.filters.filter((filter) => {
                return filter.propertyName !== AllowedNotificationFilterPropertyName.ShipmentStatus;
            });
        }

        if (draftNotificationDetails.notificationTypes[0] !== NotificationTypes.EtaIsSetNumberOfMinutesAway) {
            // API is expecting no metadata unless setting ETA minutes away
            draftNotificationDetailsForSubmission.notificationMetaData = null;
        }
        if (draftNotificationDetails.notificationTypes[0] !== NotificationTypes.EtaIsSetNumberOfMinutesFromTime) {
            // API is expecting no timePriorityType unless setting ETA minutes from time
            draftNotificationDetailsForSubmission.timePriorityType = null;
            draftNotificationDetailsForSubmission.minutes = null;
        }

        if (!draftNotificationDetails.notificationGuid) {
            dispatch(createNotification({
                notificationDetails: draftNotificationDetailsForSubmission,
                skip,
                searchTerm,
                sortColumn,
                sortDirection
            }));
        } else {
            dispatch(updateNotificationDetails({
                notificationDetails: draftNotificationDetailsForSubmission,
                skip,
                searchTerm,
                sortColumn,
                sortDirection
            }));
        }
    };

    const renderNotificationsForm = (): JSX.Element => {
        if (notificationDetailsStatus === ApiStatus.Loading) {
            return (
                <StyledDiv>
                    <Grid container className={classes.form}>
                        {
                            isMobile === false &&
                            <Grid item xs={12}>
                                <Typography variant='h6' gutterBottom>Edit Notification</Typography>
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.notificationNameWrapper}>
                            <Skeleton className={classes.notificationNameInput} height='60px' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' className={classes.radioSkeleton}>
                                <Skeleton variant='circular' width='24px' height='24px' />
                                <span className={classes.radioSkeletonLabel}>Shipment Level</span>
                            </Typography>
                            <Typography variant='body1' className={classes.radioSkeleton}>
                                <Skeleton variant='circular' width='24px' height='24px' />
                                <span className={classes.radioSkeletonLabel}>Stop Level</span>
                            </Typography>
                            <Grid item>
                                <Skeleton width='300px' height='85px' />
                                <Skeleton width='300px' height='85px' />
                                <Skeleton width='120px' height='30px' />
                                <Skeleton width='120px' height='30px' />
                                <Skeleton width='120px' height='30px' />
                            </Grid>
                        </Grid>
                    </Grid>
                </StyledDiv>
            );
        }

        return (
            <StyledDiv className={classes.formWrapper}>
                <form
                    ref={formControlWrapper}
                    className={classes.form}
                    onSubmit={(event): void => { event.preventDefault(); }}
                >
                    {
                        isMobile === false &&
                        <Typography variant='h6' gutterBottom>
                            {
                                notificationDetails.notificationGuid ? 'Edit Notification' : 'Create Notification'
                            }
                        </Typography>
                    }

                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm='auto' className={classes.notificationNameWrapper}>
                            <TextField
                                label='Notification Name'
                                id='notificationsForm-name-input'
                                className={classes.notificationNameInput}
                                fullWidth
                                variant='outlined'
                                disabled={shareType === ShareType.OperatorReadOnly}
                                value={draftNotificationDetails.notificationName}
                                error={isFormDirty ? draftNotificationDetails.notificationName === '' : false}
                                required={draftNotificationDetails.notificationName === ''}
                                onChange={(event): void => {
                                    localDispatch({
                                        type: UPDATE_NAME,
                                        payload: {
                                            notificationName: event.currentTarget.value
                                        }
                                    });
                                }}
                                type='text'
                                inputProps={{
                                    maxLength: 50,
                                    autoComplete: 'off'
                                }}
                                data-qa='notificationsForm-notification-name-input'
                            />
                        </Grid>

                        <Grid item xs={12} sm='auto'>
                            <Badge badgeContent={Object.keys(formattedFilters).length} color='primary'>
                                <Button
                                    variant='outlined'
                                    onClick={(): void => {
                                        setIsFiltersDialogOpen(true);
                                    }}
                                    data-qa='filtersDialog-action'
                                >
                                    Filters
                                </Button>
                            </Badge>
                        </Grid>

                        <Grid item xs={12}>
                            <NotificationLevelOptions
                                isFormDirty={isFormDirty}
                                draftNotificationDetails={draftNotificationDetails}
                                draftSmsTextField={draftSmsTextField}
                                draftEmailTextField={draftEmailTextField}
                                handleDraftSmsTextFieldChange={setDraftSmsTextField}
                                handleDraftEmailTextFieldChange={setDraftEmailTextField}
                                handleDirtyChange={handleSetIsLevelOptionsDirty}
                                handleUpdateRecipientType={handleUpdateRecipientType}
                                handleAddShipmentLevelSmsEntry={handleAddShipmentLevelSmsEntry}
                                handleAddShipmentLevelEmailEntry={handleAddShipmentLevelEmailEntry}
                                handleDeleteShipmentLevelSmsEntry={handleDeleteShipmentLevelSmsEntry}
                                handleDeleteShipmentLevelEmailEntry={handleDeleteShipmentLevelEmailEntry}
                                handleUpdateStopLevelSmsCheckbox={handleUpdateStopLevelSmsCheckbox}
                                handleUpdateStopLevelEmailCheckbox={handleUpdateStopLevelEmailCheckbox}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.notificationTypeWrapper}>
                            <NotificationTypeOptions
                                isFormDirty={isFormDirty}
                                draftNotificationDetails={draftNotificationDetails}
                                handleUpdateNotificationTypeLabel={handleUpdateNotificationTypeLabel}
                                handleToggleNotificationType={handleToggleNotificationType}
                                handleClearNotificationTypes={handleClearNotificationTypes}
                                handleUpdateEtaMinutesAway={handleUpdateEtaMinutesAway}
                                handleUpdateMinutesFromTime={handleUpdateMinutesFromTime}
                                handleUpdateTimePriorityType={handleUpdateTimePriorityType}
                            />
                        </Grid>
                    </Grid>
                </form>

                <Grid container justifyContent='center' className={classes.actionsContainer}>
                    <Grid item>
                        <Button
                            className={classes.button}
                            disabled={notificationDetailsUpdateStatus === ApiStatus.Loading}
                            onClick={handleCancel}
                            data-qa='notificationsForm-cancel'
                        >
                            Cancel
                        </Button>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={handleSubmitClick}
                            disabled={!canSaveNotificationForm}
                            startIcon={notificationDetailsUpdateStatus === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                            data-qa='notificationsForm-save'
                        >
                            {
                                notificationDetailsUpdateStatus === ApiStatus.Loading ? 'Saving' : 'Save'
                            }
                        </Button>
                    </Grid>
                </Grid>

                {
                    isFiltersDialogOpen &&
                    <NotificationFilterDialog
                        isOpen={isFiltersDialogOpen}
                        notificationTypeLabel={draftNotificationDetails.notificationTypeLabel}
                        closeDialog={(): void => {
                            setIsFiltersDialogOpen(false);
                        }}
                        handleSave={(allFilters): void => {
                            localDispatch({
                                type: UPDATE_FILTERS,
                                payload: {
                                    allFilters
                                }
                            });
                        }}
                        selectedFilters={formattedFilters}
                    />
                }
                {
                    unsavedChangesDialogIsOpen &&
                    <UnsavedChangesDialog
                        isOpen={unsavedChangesDialogIsOpen}
                        handleClose={(): void => {
                            setUnsavedChangesDialogIsOpen(false);
                        }}
                        handleContinue={(): void => {
                            dispatch(resetNotificationDetails());
                            localDispatch({
                                type: INITIALIZE_NOTIFICATION_DETAILS
                            });
                            setDraftEmailTextField('');
                            setDraftSmsTextField('');
                            setUnsavedChangesDialogIsOpen(false);
                            handleCancelClick();
                        }}
                    />
                }
            </StyledDiv>
        );
    };

    if (isMobile) {
        return (
            <Dialog fullScreen open={isDialogOpen}>
                <Toolbar>
                    <Typography variant='h6'>
                        {notificationDetails.notificationGuid || notificationDetailsStatus === ApiStatus.Loading ? 'Edit Notification' : 'Create Notification'}
                    </Typography>
                </Toolbar>
                {renderNotificationsForm()}
            </Dialog>
        );
    }

    return renderNotificationsForm();
};

export default NotificationsForm;
