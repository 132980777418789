import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Typography,
    RadioGroup,
    Radio,
    FormHelperText
} from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import { isTimeRangeValid } from '../../../helpers/dateUtils';
import PreferenceCard from '../preferenceCard';
import CustomTimePicker from '../../pickers/timePicker';

const DropTrailerRulesDeliveryCard = ({
    handleIsCardValidChange
}: {
    handleIsCardValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const [isDropTrailerWindowDeliveryTimeFromValid, setIsDropTrailerWindowDeliveryTimeFromValid] = useState(true);
    const [isDropTrailerWindowDeliveryTimeToValid, setIsDropTrailerWindowDeliveryTimeToValid] = useState(true);
    const [isDropTrailerWindowDeliveryTimeRangeValid, setIsDropTrailerWindowDeliveryTimeRangeValid] = useState(true);

    const {
        dropTrailerDeliveryRulesEnabled,
        isDropTrailerSameDayDelivery,
        dropTrailerWindowDeliveryTimeFrom,
        dropTrailerWindowDeliveryTimeTo
    } = organizationEditablePreferences;

    useEffect((): void => {
        setIsDropTrailerWindowDeliveryTimeRangeValid(isTimeRangeValid(dropTrailerWindowDeliveryTimeFrom, dropTrailerWindowDeliveryTimeTo));
    }, [dropTrailerWindowDeliveryTimeFrom, dropTrailerWindowDeliveryTimeTo]);

    useEffect((): void => {
        const isCardValid = dropTrailerDeliveryRulesEnabled === true && isDropTrailerSameDayDelivery === false
            ? isDropTrailerWindowDeliveryTimeFromValid && isDropTrailerWindowDeliveryTimeToValid && isDropTrailerWindowDeliveryTimeRangeValid
            : true;
        handleIsCardValidChange(isCardValid);
    }, [
        handleIsCardValidChange,
        dropTrailerDeliveryRulesEnabled,
        isDropTrailerSameDayDelivery,
        isDropTrailerWindowDeliveryTimeFromValid,
        isDropTrailerWindowDeliveryTimeToValid,
        isDropTrailerWindowDeliveryTimeRangeValid
    ]);

    const handleDropTrailerDeliveryRulesEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.dropTrailerDeliveryRulesEnabled, event.target.checked));
    };

    const handleIsDropTrailerSameDayDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value === 'true';
        dispatch(editPreferences(PreferencesFieldNames.isDropTrailerSameDayDelivery, value));
        if (value) {
            dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowDeliveryTimeFrom, null));
            dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowDeliveryTimeTo, null));
        }
    };

    const handleDropTrailerWindowDeliveryTimeFromChange = (time: string | null): void => {
        dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowDeliveryTimeFrom, time));
    };

    const handleDropTrailerWindowDeliveryTimeFromIsValidChange = (isTimeValid: boolean): void => {
        setIsDropTrailerWindowDeliveryTimeFromValid(isTimeValid);
    };

    const handleDropTrailerWindowDeliveryTimeToChange = (time: string | null): void => {
        dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowDeliveryTimeTo, time));
    };

    const handleDropTrailerWindowDeliveryTimeToIsValidChange = (isTimeValid: boolean): void => {
        setIsDropTrailerWindowDeliveryTimeToValid(isTimeValid);
    };

    return (
        <PreferenceCard
            header='Drop Trailer Rules - Delivery'
            informationText='Allows the user to override the on-time rules for delivery of a shipment that has a flag of "Drop/Swing Trailer" present on the shipment.'
        >
            <Typography component='p' variant='body2'>
                Manage how drop/swing trailers impact your on-time rules.
            </Typography>

            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={dropTrailerDeliveryRulesEnabled}
                            onChange={handleDropTrailerDeliveryRulesEnabledChange}
                            color='primary'
                        />
                    }
                    label='Delivery Rules'
                    data-qa='dropTrailerDeliveryRulesEnabled-switch'
                />
            </FormGroup>

            {
                dropTrailerDeliveryRulesEnabled === true &&
                <Fragment>
                    <RadioGroup
                        aria-label='isDropTrailerSameDayDelivery'
                        name='isDropTrailerSameDayDelivery'
                        value={String(isDropTrailerSameDayDelivery)}
                        onChange={handleIsDropTrailerSameDayDeliveryChange}
                    >
                        <FormControlLabel
                            value='true'
                            control={<Radio color='primary' />}
                            label='Same day delivery is considered On Time'
                            data-qa='sameDayDelivery-radio'
                        />
                        <FormControlLabel
                            value='false'
                            control={<Radio color='primary' />}
                            label='Delivered between these hours is considered On Time'
                            data-qa='deliveredHours-radio'
                        />
                    </RadioGroup>

                    {
                        isDropTrailerSameDayDelivery === false &&
                        <Fragment>
                            <CustomTimePicker
                                label='From'
                                incomingTime={dropTrailerWindowDeliveryTimeFrom}
                                handleTimeChange={handleDropTrailerWindowDeliveryTimeFromChange}
                                handleIsValidChange={handleDropTrailerWindowDeliveryTimeFromIsValidChange}
                                dataQa='dropTrailerWindowDeliveryTimeFrom-timePicker'
                            />
                            <CustomTimePicker
                                label='To'
                                incomingTime={dropTrailerWindowDeliveryTimeTo}
                                handleTimeChange={handleDropTrailerWindowDeliveryTimeToChange}
                                handleIsValidChange={handleDropTrailerWindowDeliveryTimeToIsValidChange}
                                dataQa='dropTrailerWindowDeliveryTimeTo-timePicker'
                            />
                            {
                                !isDropTrailerWindowDeliveryTimeRangeValid &&
                                <FormHelperText error>End time must be after start time</FormHelperText>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
        </PreferenceCard>
    );
};

export default DropTrailerRulesDeliveryCard;
