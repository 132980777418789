import React from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControlLabel,
    RadioGroup,
    Radio
} from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const StopArrivalDepartureDefaultCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handlePreferredStopTimeSourceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.preferredStopTimeSourceType, Number(event.currentTarget.value)));
    };

    return (
        <PreferenceCard
            header='Stop Arrival/Departure Default'
            informationText='This will be the arrival and departure that is used to determine the stop arrival. If only the non-preferred arrival/departure is present, it will be used in its place until the preferred arrival/departure is available.'
        >
            <RadioGroup
                aria-label='preferredStopTimeSourceType'
                name='preferredStopTimeSourceType'
                value={String(organizationEditablePreferences.preferredStopTimeSourceType)}
                onChange={handlePreferredStopTimeSourceChange}
            >
                <FormControlLabel
                    value='0'
                    disabled={organizationEditablePreferences.displayCarrierProvidedActualsOnly === true}
                    control={<Radio color='primary' />}
                    label='Geofence Arrival/Departure'
                    data-qa='geofence-radio'
                />
                <FormControlLabel
                    value='1'
                    disabled={organizationEditablePreferences.displayCarrierProvidedActualsOnly === true}
                    control={<Radio color='primary' />}
                    label='Carrier Provided Arrival/Departure'
                    data-qa='carrier-radio'
                />
            </RadioGroup>
        </PreferenceCard>
    );
};

export default StopArrivalDepartureDefaultCard;
