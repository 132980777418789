import React, { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { calculateHoursAndMinutes } from '../../helpers/dataUtils';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import { DeliveryStatus } from '../../helpers/enums';

const classesPrefix = 'onTimeRulesList';

const classes = {
    listWrapper: `${classesPrefix}-listWrapper`,
    listItem: `${classesPrefix}-listItem`,
    ruleText: `${classesPrefix}-ruleText`,
    earlyDescription: `${classesPrefix}-earlyDescription`,
    onTimeDescription: `${classesPrefix}-onTimeDescription`,
    inJeopardyDescription: `${classesPrefix}-inJeopardyDescription`,
    lateDescription: `${classesPrefix}-lateDescription`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`&.${classes.listWrapper}`]: {
            paddingInlineStart: '16px',
            margin: 0
        },
        [`& .${classes.listItem}`]: {
            width: '100%'
        },
        [`& .${classes.ruleText}`]: {
            fontSize: '14px'
        },
        [`& .${classes.earlyDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.Early),
            fontWeight: 600
        },
        [`& .${classes.onTimeDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.OnTime),
            fontWeight: 600
        },
        [`& .${classes.inJeopardyDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.InJeopardy),
            fontWeight: 600
        },
        [`& .${classes.lateDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.Late),
            fontWeight: 600
        }
    };
}) as typeof Grid;

const OnTimeRulesList = ({
    hasEarlyStatusDefined,
    hasInJeopardyStatusDefined,
    earlyShipmentStatusMinutes,
    onTimeShipmentStatusMinutes,
    inJeopardyShipmentStatusMinutes
}: {
    hasEarlyStatusDefined: boolean;
    hasInJeopardyStatusDefined: boolean;
    earlyShipmentStatusMinutes: number;
    onTimeShipmentStatusMinutes: number;
    inJeopardyShipmentStatusMinutes: number;
}): JSX.Element => {
    const earlyHoursAndMinutes = calculateHoursAndMinutes(earlyShipmentStatusMinutes);
    const onTimeHoursAndMinutes = calculateHoursAndMinutes(onTimeShipmentStatusMinutes);
    const inJeopardyHoursAndMinutes = calculateHoursAndMinutes(inJeopardyShipmentStatusMinutes);

    return (
        <StyledGrid container component='ul' className={classes.listWrapper}>
            {
                hasEarlyStatusDefined === true &&
                <Grid item component='li' className={classes.listItem}>
                    <Typography className={classes.ruleText}>
                        {'Shipment ETAs before '}
                        <span className={classes.onTimeDescription}>On Time</span>
                        {' will be tagged '}
                        <span className={classes.earlyDescription}>Early</span>
                    </Typography>
                </Grid>
            }

            <Grid item component='li' className={classes.listItem}>
                <Typography className={classes.ruleText}>
                    {'Shipments are '}
                    <span className={classes.onTimeDescription}>On Time</span>
                    {' when ETAs are '}
                    {
                        hasEarlyStatusDefined === true &&
                        <Fragment>
                            <span>{`between ${earlyHoursAndMinutes.hours} hours`}</span>
                            <span>{` ${earlyHoursAndMinutes.minutes} minutes `}</span>
                            <span>before and</span>
                        </Fragment>
                    }
                    <span>{` ${onTimeHoursAndMinutes.hours} hours`}</span>
                    <span>{` ${onTimeHoursAndMinutes.minutes} minutes `}</span>
                    <span>after scheduled time</span>
                </Typography>
            </Grid>

            {
                hasInJeopardyStatusDefined === true &&
                <Grid item component='li' className={classes.listItem}>
                    <Typography className={classes.ruleText}>
                        {'Shipments are '}
                        <span className={classes.inJeopardyDescription}>In Jeopardy</span>
                        {' when ETAs are '}
                        <span>{`${inJeopardyHoursAndMinutes.hours} hours`}</span>
                        <span>{` ${inJeopardyHoursAndMinutes.minutes} minutes `}</span>
                        <span>after scheduled time</span>
                    </Typography>
                </Grid>
            }

            <Grid item component='li' className={classes.listItem}>
                <Typography className={classes.ruleText}>
                    {'Shipment ETAs after '}
                    {
                        hasInJeopardyStatusDefined === true ?
                            (
                                <span className={classes.inJeopardyDescription}>In Jeopardy</span>
                            ) : (
                                <span className={classes.onTimeDescription}>On Time</span>
                            )
                    }
                    {' will be tagged '}
                    <span className={classes.lateDescription}>Late</span>
                </Typography>
            </Grid>
        </StyledGrid>
    );
};

export default OnTimeRulesList;
