import React, { Fragment, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';

import { View } from '../../interfaces/services/views';
import ViewDialog from '../dialogs/viewDialog';

const ViewEditButton = ({
    view
}: {
    view: View;
}): JSX.Element => {
    const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);

    return (
        <Fragment>
            <Tooltip title='Edit'>
                <IconButton
                    size='small'
                    onClick={(): void => {
                        setViewDialogIsOpen(true);
                    }}
                    data-qa='editView-action'
                >
                    <Icon path={mdiPencil} size={1} />
                </IconButton>
            </Tooltip>

            {
                viewDialogIsOpen &&
                <ViewDialog
                    view={view}
                    isOpen={viewDialogIsOpen}
                    closeDialog={(): void => {
                        setViewDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ViewEditButton;
