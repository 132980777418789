export const publicRoutes = {
    root: '/',
    authorize: '/authorize',
    revoke: '/revoke',
    notAuthorized: '/notAuthorized'
};

export const protectedRoutes = {
    shipmentDetails: '/shipmentDetails/:shipmentUniqueName',
    tripDetails: '/tripDetails/:tripIdentifier',
    orderDetails: '/orderDetails/:orderGuid'
};

export const privateRoutes = {
    shipmentsOverview: '/shipments/overview',
    shipmentsActive: '/shipments/active',
    shipmentsHistory: '/shipments/history',
    shipmentsCalendar: '/shipments/calendar',
    ordersOverview: '/orders/overview',
    ordersActive: '/orders/active',
    preferences: '/preferences',
    notifications: '/notifications/:notificationGuid?',
    // FIXME: route should just be '/locations'
    locations: '/alpha/locations',
    analytics: '/analytics'
};
