import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import Hidden from '../layouts/hidden';
import LabelValuePair from '../labels/labelValuePair';
import ShipmentDetailsLabelLink from '../links/shipmentDetailsLabelLink';
import StopAddress from '../labels/stopAddress';
import StopDateLabel from '../labels/stopDateLabel';

const classesPrefix = 'tripDetailsCard';

const classes = {
    addressContainer: `${classesPrefix}-addressContainer`,
    stopTitle: `${classesPrefix}-stopTitle`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.addressContainer}`]: {
            marginBottom: '4px'
        },
        [`& .${classes.stopTitle}`]: {
            fontWeight: 600,
            fontSize: '14px',
            paddingBottom: '4px'
        }
    };
});

const TripDetailsCard = (): JSX.Element => {
    const tripInformation = useTypedSelector((state) => { return state.tripDetails.tripInformation; });

    const {
        tripStatus,
        activeShipment,
        tripOriginStop,
        tripDestinationStop,
        shipments
    } = tripInformation;

    return (
        <StyledGrid container data-qa='tripDetailsCard-container'>
            <Grid item xs={12}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item xs={12} sm='auto'>
                                <LabelValuePair label='Trip Status' value={addSpaceBeforeUppercaseCharacter(tripStatus)} fontSize='16px' data-qa='tripStatus' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item xs='auto'>
                                <ShipmentDetailsLabelLink
                                    label='Active Shipment'
                                    shipmentUniqueName={activeShipment.shipmentUniqueName}
                                    freightProviderReferenceNumber={activeShipment.freightProviderReferenceNumber}
                                    modeType={activeShipment.modeType}
                                    fontSize='16px'
                                    alwaysOpenInNewTab
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <LabelValuePair label='Active Mode' value={activeShipment.modeType} fontSize='16px' data-qa='mode' />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <LabelValuePair label='Active Carrier' value={activeShipment.freightHauler.name} fontSize='16px' data-qa='carrier' />
                    </Grid>
                </Grid>

                <Grid container className={classes.addressContainer} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid container direction='column' spacing={1} data-qa='origin-container'>
                            <Grid item xs={12}>
                                <Typography className={classes.stopTitle} data-qa='origin-label'>Trip Origin</Typography>
                                <StopAddress
                                    description={tripOriginStop.description}
                                    location={tripOriginStop.fixedLocationCode}
                                    name={tripOriginStop.name}
                                    street1={tripOriginStop.address.streetAddress1}
                                    street2={tripOriginStop.address.streetAddress2}
                                    city={tripOriginStop.address.city}
                                    region={tripOriginStop.address.state}
                                    postal={tripOriginStop.address.postalCode}
                                    country={tripOriginStop.address.countryCode}
                                    modeType={shipments[0].modeType}
                                />
                                <Hidden breakpoint='md' direction='up'>
                                    <StopDateLabel stop={tripOriginStop} />
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container direction='column' spacing={1} data-qa='destination-container'>
                            <Grid item xs={12}>
                                <Typography className={classes.stopTitle} data-qa='destination-label'>Trip Destination</Typography>
                                <StopAddress
                                    description={tripDestinationStop.description}
                                    location={tripDestinationStop.fixedLocationCode}
                                    name={tripDestinationStop.name}
                                    street1={tripDestinationStop.address.streetAddress1}
                                    street2={tripDestinationStop.address.streetAddress2}
                                    city={tripDestinationStop.address.city}
                                    region={tripDestinationStop.address.state}
                                    postal={tripDestinationStop.address.postalCode}
                                    country={tripDestinationStop.address.countryCode}
                                    modeType={shipments[shipments.length - 1].modeType}
                                />
                                <Hidden breakpoint='md' direction='up'>
                                    <StopDateLabel stop={tripDestinationStop} />
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden breakpoint='md' direction='down'>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <StopDateLabel stop={tripOriginStop} />
                        </Grid>
                        <Grid item xs={6}>
                            <StopDateLabel stop={tripDestinationStop} />
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </StyledGrid>
    );
};

export default TripDetailsCard;
