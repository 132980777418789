import React from 'react';
import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ColDef } from '../../interfaces/muiTableInterfaces';

const classesPrefix = 'dataListCell';

const classes = {
    tableCell: `${classesPrefix}-tableCell`,
    clickableCell: `${classesPrefix}-clickableCell`,
    tableCellSpan: `${classesPrefix}-tableCellSpan`
};

const StyledTableCell = styled(TableCell)(({ theme }) => {
    return {
        [`&.${classes.tableCell}`]: {
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        [`&.${classes.clickableCell}`]: {
            color: theme.appColors.action,
            fontWeight: 600,
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline'
            }
        },
        [`& .${classes.tableCellSpan}`]: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
        }
    };
});

const DataListCell = <T extends object>({
    column,
    row
}: {
    /** the metadata about the current column */
    column: ColDef<T>;
    /** the data for the current row */
    row: T;
}): JSX.Element => {
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const handleCellClick = (): void => {
        if (column.onClick && shareType === null) {
            column.onClick({ field: column.field, value: row[column.field], width: `${column.width || 100}px`, data: row });
        }
    };

    return (
        <StyledTableCell
            className={`${classes.tableCell}${column.onClick && shareType === null && ` ${classes.clickableCell}`}`}
            data-qa={column.field}
            onClick={handleCellClick}
        >
            {
                column.renderCell
                    ? column.renderCell({
                        field: column.field, value: row[column.field], width: `${column.width || 100}px`, data: row
                    })
                    // @ts-ignore ReactNode typing issue - this will be converted to MUI data grid in the future so left as-is for now
                    : <span className={classes.tableCellSpan} style={{ width: `${column.width || 100}px` }}>{row[column.field]}</span>
            }
        </StyledTableCell>
    );
};

export default DataListCell;
