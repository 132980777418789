import React, { useEffect, useState } from 'react';
import {
    IconButton,
    Grid,
    TextField,
    InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiMagnify, mdiClose } from '@mdi/js';

const classesPrefix = 'headerSearchInput';

const classes = {
    searchForm: `${classesPrefix}-searchForm`,
    textField: `${classesPrefix}-textField`,
    searchIcon: `${classesPrefix}-searchIcon`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`& .${classes.searchForm}`]: {
            width: '100%'
        },
        [`& .${classes.textField}`]: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            '& .MuiFormLabel-root': {
                color: theme.palette.text.primary,
                opacity: 1,
                padding: '0 4px',
                zIndex: 1
            },
            '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
                paddingLeft: '4px'
            }
        },
        [`& .${classes.searchIcon}`]: {
            color: theme.palette.text.primary
        }
    };
});

const HeaderSearchInput = ({
    id,
    inputLabel,
    searchTerm,
    handleSearchTermSubmit
}: {
    id: string;
    /** Label to display in the input. */
    inputLabel: string;
    /** Incoming search term. */
    searchTerm: string;
    /** Function to handle the search term submit. */
    handleSearchTermSubmit: (searchTerm: string) => void;
}): JSX.Element => {
    const [draftSearchTerm, setDraftSearchTerm] = useState('');

    useEffect(() => {
        setDraftSearchTerm(searchTerm);
    }, [searchTerm]);

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        handleSearchTermSubmit(draftSearchTerm.trim());
    };

    return (
        <StyledGrid item xs={12} md={4} xl={3} data-qa={`${id}-container`}>
            <form onSubmit={handleSubmit} autoComplete='off' className={classes.searchForm} data-qa={`${id}-form`}>
                <TextField
                    id={id}
                    type='text'
                    variant='filled'
                    size='small'
                    label={inputLabel}
                    classes={{
                        root: classes.textField
                    }}
                    value={draftSearchTerm}
                    onChange={(event): void => {
                        setDraftSearchTerm(event.currentTarget.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                {
                                    draftSearchTerm.length > 0 &&
                                    <IconButton
                                        aria-label='clear search term'
                                        classes={{
                                            root: classes.searchIcon
                                        }}
                                        onClick={(): void => {
                                            setDraftSearchTerm('');
                                            handleSearchTermSubmit('');
                                        }}
                                        data-qa={`${id}-action-clear`}
                                    >
                                        <Icon path={mdiClose} size={1} />
                                    </IconButton>
                                }
                                <IconButton
                                    aria-label='start search'
                                    classes={{
                                        root: classes.searchIcon
                                    }}
                                    onClick={handleSubmit}
                                    data-qa={`${id}-action-search`}
                                >
                                    <Icon path={mdiMagnify} size={1} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        inputProps: {
                            autoComplete: 'off',
                            'data-qa': `${id}-input`
                        }
                    }}
                />
            </form>
        </StyledGrid>
    );
};

export default HeaderSearchInput;
