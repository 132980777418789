/**
 * Accepts city, region, postal code, and country and concatenates them together in the expected format, "City, region postal country" depending on what data is available
 * @param city city string can be null. If city and region are passed in a comma will be appended after the city
 * @param region region or state string. Can be null
 * @param postal postal or zip string. Can be null
 * @param country country string. Is optional or can be null
 * @returns string of a formatted city, region, postal, and country code based on what's available.
 */
export const formatCityRegionPostalCountry = (
    {
        city,
        region,
        postal,
        country
    }: {
        city: string | null;
        region: string | null;
        postal?: string | null;
        country?: string | null;
    }
): string => {
    let result = '';
    if (city && region) {
        result += `${city}, ${region}`;
    } else {
        result += `${city || ''}${region || ''}`;
    }
    if (postal) {
        result += ` ${postal}`;
    }
    if (country) {
        result += ` ${country}`;
    }
    return result;
};

/**
 * Accepts address line 1, address line 2, city, region, postal code, and country and concatenates them together in the expected format, "Street 1 Street 2 City, region postal country" depending on what data is available *
 * @param street1 street 1 string can be null
 * @param street2 street 2 string can be null
 * @param city city string can be null. If city and region are passed in a comma will be appended after the city
 * @param region region or state string. Can be null
 * @param postal postal or zip string. Can be null
 * @param country country string. Is optional or can be null
 * @returns string of a full formatted address
 */
export const formatFullAddress = (
    {
        street1,
        street2,
        city,
        region,
        postal,
        country
    }: {
        street1: string | null;
        street2: string | null;
        city: string | null;
        region: string | null;
        postal?: string | null;
        country?: string | null;
    }
): string => {
    let address = '';
    if (street1) {
        address = street1;
    }

    if (street2) {
        address += ` ${street2}`;
    }

    // Get the city, region, postal, and country and trim it to remove possible leading whitespace
    const cityRegionPostalCountry = formatCityRegionPostalCountry({
        city, region, postal, country
    }).trim();

    if (cityRegionPostalCountry) {
        address += ` ${cityRegionPostalCountry}`;
    }

    return address.trim();
};
