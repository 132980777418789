import React, { Fragment } from 'react';
import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FilterItem } from '../../interfaces/filterInterfaces';

const classesPrefix = 'multiSelect';

const classes = {
    autocomplete: `${classesPrefix}-autocomplete`,
    formControl: `${classesPrefix}-formControl`,
    formLabelShrink: `${classesPrefix}-formLabelShrink`
};

const StyledAutocomplete = styled(Autocomplete)(() => {
    return {
        [`&.${classes.autocomplete}`]: {
            marginTop: '16px',
            marginBottom: '8px',
            '& + .MuiAutocomplete-popperDisablePortal': {
                position: 'static',
                '& .MuiAutocomplete-listbox': {
                    maxHeight: 'none'
                }
            }
        },
        [`& .${classes.formControl}`]: {
            margin: 0
        },
        [`& .${classes.formLabelShrink}`]: {
            top: '-4px'
        }
    };
}) as typeof Autocomplete;

const MultiSelect = ({
    id,
    label,
    values,
    availableOptions,
    disabled,
    isLoading,
    handleChange
}: {
    id: string;
    label: string;
    values: string[];
    availableOptions: FilterItem[];
    disabled?: boolean;
    isLoading: boolean;
    handleChange: (id: string, selectedOptions: string[]) => void;
}): JSX.Element => {
    const getNewFilterValues = (propertyValues: FilterItem[]): string[] => {
        const newFilterPropertyValues = propertyValues.map((propertyValue): string => {
            return propertyValue.filterValue;
        });

        return newFilterPropertyValues;
    };

    const getSelectedFilterOptions = (options: FilterItem[], selectedFilterValues: string[]): FilterItem[] => {
        const selectedFilterOptions = options.filter((option): boolean => {
            return selectedFilterValues.includes(option.filterValue);
        });

        return selectedFilterOptions;
    };

    return (
        <StyledAutocomplete
            id={id}
            className={classes.autocomplete}
            options={availableOptions}
            getOptionLabel={(option): string => {
                return option.label;
            }}
            isOptionEqualToValue={(option, value): boolean => {
                return value.filterValue === option.filterValue;
            }}
            value={getSelectedFilterOptions(availableOptions, values)}
            onChange={(event, newValues): void => {
                const newFilterPropertyValues = getNewFilterValues(newValues);
                handleChange(id, newFilterPropertyValues);
            }}
            renderTags={(tags, getTagProps): JSX.Element[] => {
                return tags.map((option, index): JSX.Element => {
                    const alphaNumericOption = option.label.replace(/[^A-Za-z0-9]/g, '').toLowerCase();
                    return (
                        <Chip
                            {...getTagProps({ index })}
                            key={alphaNumericOption}
                            variant='outlined'
                            label={option.label}
                            data-qa={`${alphaNumericOption}-chip`}
                        />
                    );
                });
            }}
            renderInput={(params): JSX.Element => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={values.length > 0 ? '' : 'Select...'}
                        margin='normal'
                        variant='filled'
                        fullWidth
                        classes={{
                            root: classes.formControl
                        }}
                        InputLabelProps={{
                            classes: {
                                shrink: classes.formLabelShrink
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            )
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            'data-qa': `${id}-input`
                        }}
                    />
                );
            }}
            // use disabled for 'loading' state because the Autocomplete loading prop appears to be useless
            disabled={isLoading || disabled}
            fullWidth
            multiple
            clearOnBlur
            filterSelectedOptions
            openOnFocus
            disablePortal
            disableCloseOnSelect
            data-qa={`${id}-multiselect`}
        />
    );

};

export default MultiSelect;
