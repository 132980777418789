import React from 'react';
import { PaletteMode } from '@mui/material';
import { styled } from '@mui/material/styles';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { bbox, Feature, Polygon, Position, Properties } from '@turf/turf';

import { addLocationGeofenceSource } from '../../helpers/maps/sources/locationGeofence';
import { addLocationPointLayer, addLocationGeofenceLayers } from '../../helpers/maps/layers/locationGeofence';
import { fitMapToBounds } from '../../helpers/maps/mapUtils';
import { MapSources, MapImages } from '../../helpers/maps/enums';
import MapSpinner from '../loaders/mapSpinner';
import MapControlBar from '../actionBars/mapControlBar';

import '../../assets/CSS/trimbleMaps.css';

const classesPrefix = 'locationGeofenceMap';

const classes = {
    map: `${classesPrefix}-map`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.map}`]: {
            height: '250px'
        }
    };
});

interface LocationGeofenceMapProps {
    /** Indicator to show loading spinner while data is fetching. */
    isFetchingData: boolean;
    /** The [long, lat] coordinates of the fixed location. */
    fixedLocationLongLat: Position;
    /** The polygon feature. */
    geofencePolygon: Feature<Polygon, Properties> | null;
    /** The radius in feet for the geofence. */
    geofenceRadiusInFeet: number | null;
    /** Image to show on the map for the location. */
    markerImage: string;
    /** Palette Mode used to determine map style. */
    paletteMode: PaletteMode;
    /** Indicator to trigger a map resize function. */
    resize?: boolean;
}

interface LocationGeofenceMapState {
    mapTileStyle: string;
    mapStyleLoaded: boolean;
}

let map: any = null;
const mapContainer = React.createRef<HTMLDivElement>();

class LocationGeofenceMap extends React.Component<LocationGeofenceMapProps, LocationGeofenceMapState> {

    constructor(props: LocationGeofenceMapProps) {
        super(props);

        TrimbleMaps.APIKey = 'A413EAA4767EC44E94A2360AE03B8689';

        this.state = {
            mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
            mapStyleLoaded: false
        };
    }

    componentDidMount(): void {
        map = new TrimbleMaps.Map({
            container: mapContainer.current, // Ties to an HTML DOM element
            center: this.props.fixedLocationLongLat, // Sets initial center of map to the current location
            style: this.state.mapTileStyle, // Sets initial map style
            minZoom: 1.55, // To stop the zoom from showing multiple worlds
            zoom: 4, // Sets initial zoom level
            attributionControl: false // Removes default attribution control, so we can customize it below
        }).addControl(
            new TrimbleMaps.AttributionControl({
                compact: true // Minimizes the attribution to a compact version
            })
        ).addControl(
            // Adds the zoom in/out and compass buttons
            new TrimbleMaps.NavigationControl({
                showCompass: true,
                showZoom: true
            }),
            'top-right'
        ).addControl(
            new TrimbleMaps.FullscreenControl()
        );

        map.setRegion(TrimbleMaps.Common.Region.WW);

        // README: The event `style.load` is no longer a public event of mapbox. We will need to use some combination of using `styledata` and `map.isStyleLoaded()` at some point.
        // However, these do not work the same and there are several open issues with mapbox that have yet to be resolved, such as this one:
        // https://github.com/mapbox/mapbox-gl-js/issues/9779
        // Once a map style is loaded, add any layers that were on back to the map
        map.on('style.load', (): void => {
            // The style.load event can fire after the component has been unmounted.
            if (map === null) {
                return;
            }

            this.addLocationToMap();

            // HACK: trigger a resize function to make map take up full width
            map.resize();
            this.setState({ mapStyleLoaded: true });
        });
    }

    componentDidUpdate(prevProps: LocationGeofenceMapProps, prevState: LocationGeofenceMapState): void {
        // if the map is null, hold any CDU updates
        if (map === null) {
            return;
        }

        // if the paletteMode prop has changed, set the map style in state accordingly to trigger a re-render and the next CDU will update the map style to match.
        if (prevProps.paletteMode !== this.props.paletteMode) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
                mapStyleLoaded: false
            });
        }

        // if the map style state var has changed and the map style hasn't been loaded, set the style on the map itself
        if (prevState.mapTileStyle !== this.state.mapTileStyle && this.state.mapStyleLoaded === false) {
            map.setStyle(this.state.mapTileStyle);
        }

        if (this.state.mapStyleLoaded === false) {
            return;
        }

        // add location to map if props have updated
        if (prevProps.fixedLocationLongLat !== this.props.fixedLocationLongLat ||
            prevProps.geofencePolygon !== this.props.geofencePolygon ||
            prevProps.geofenceRadiusInFeet !== this.props.geofencePolygon) {
            this.addLocationToMap();
        }

        // if resize prop is passed and is true force map to resize.
        if (this.props.resize === true) {
            map.resize();
        }
    }

    handleMapStyleMenuItemClick = (mapStyle: string): void => {
        this.setState({
            mapTileStyle: mapStyle,
            mapStyleLoaded: false
        });
    };

    addLocationToMap = (): void => {
        // add images to map for the location
        map.loadImage(this.props.markerImage, (error: unknown, image: any): void => {
            if (map.hasImage(MapImages.locationMapMarker) === false) {
                map.addImage(MapImages.locationMapMarker, image);
            }
        });

        // add map source
        const locationSource = addLocationGeofenceSource(map, this.props.fixedLocationLongLat, this.props.geofencePolygon, this.props.geofenceRadiusInFeet);

        // add geofence layers
        addLocationGeofenceLayers(map, MapSources.locationGeofenceSource);

        // add point layers
        addLocationPointLayer(map, MapSources.locationGeofenceSource);

        if (locationSource.data.features.length > 0) {
            const boundingBox = bbox(locationSource.data);
            fitMapToBounds({
                map,
                bounds: boundingBox,
                zoomMap: true
            });
        }
    };

    render(): JSX.Element {
        return (
            <StyledDiv
                id='trimble-map-container'
                ref={mapContainer}
                className={classes.map}
                data-qa='locationGeofenceMap-container'
            >
                {
                    this.props.isFetchingData &&
                    <MapSpinner />
                }

                <MapControlBar
                    mapTileStyle={this.state.mapTileStyle}
                    handleMapStyleMenuItemClick={(mapStyle: string): void => { this.handleMapStyleMenuItemClick(mapStyle); }}
                />
            </StyledDiv>
        );
    }

}

export default LocationGeofenceMap;
