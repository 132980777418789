import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Paper, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { updatePageReset } from '../../redux/dialogUpdates';
import { ApiStatus, SideSheetType } from '../../helpers/enums';
import CalendarShipmentHeader from '../headers/calendarShipmentHeader';
import FullScreenOverlaySpinner from '../loaders/fullScreenOverlaySpinner';
import CustomCalendar from '../calendars/customCalendar';
import SideSheet from '../sideSheets/sideSheet';
import CalendarFilters from '../sideSheets/calendarFilters';
import CalendarViews from '../sideSheets/calendarViews';

interface StyleProps {
    filtersName: string;
}

const classesPrefix = 'shipmentsCalendarPage';

const classes = {
    wrapper: `${classesPrefix}-wrapper`
};

const StyledPaper = styled(
    Paper,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'filtersName'; }
    }
)<StyleProps>(({ filtersName, theme }) => {
    return {
        [`&.${classes.wrapper}`]: {
            transition: 'width 400ms ease-in-out',
            height: 'calc(100vh - 116px)'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.wrapper}`]: {
                height: filtersName ? 'calc(100vh - 200px)' : 'calc(100vh - 152px)'
            }
        }
    };
});

const ShipmentsCalendarPage = (): JSX.Element => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [currentSideSheet, setCurrentSideSheet] = useState<SideSheetType | null>(null);

    const shipmentListStatus = useTypedSelector((state) => { return state.calendar.shipmentListStatus; });
    const filtersName = useTypedSelector((state) => { return state.calendar.filtersName; });

    const sideSheetIsOpen = currentSideSheet !== null;
    let paperStyle: React.CSSProperties = { width: 'auto' };
    if (isMobile) {
        paperStyle = { width: 'auto' };
    } else if (sideSheetIsOpen) {
        paperStyle = { width: 'calc(100% - 331px)' };
    }

    return (
        <Fragment>
            <FullScreenOverlaySpinner open={shipmentListStatus === ApiStatus.Idle || shipmentListStatus === ApiStatus.Loading} />

            <CalendarShipmentHeader
                currentSideSheet={currentSideSheet}
                handleCurrentSideSheetChange={(newSideSheet: SideSheetType): void => {
                    if (currentSideSheet === SideSheetType.Filters) {
                        // since saving a view triggers a "dialog" update, reset the status here when the side sheet is closed.
                        dispatch(updatePageReset());
                    }
                    if (newSideSheet === currentSideSheet) {
                        setCurrentSideSheet(null);
                    } else {
                        setCurrentSideSheet(newSideSheet);
                    }
                }}
            />

            <StyledPaper
                id='shipmentsPage'
                square
                filtersName={filtersName}
                className={classes.wrapper}
                style={paperStyle}
                data-qa='shipmentsPage-container'
            >
                <CustomCalendar />
            </StyledPaper>

            {
                currentSideSheet === SideSheetType.Filters &&
                <SideSheet
                    open={currentSideSheet === SideSheetType.Filters}
                    closeSideSheet={(): void => {
                        setCurrentSideSheet(null);
                        // since saving a view triggers a "dialog" update, reset the status here when the side sheet is closed.
                        dispatch(updatePageReset());
                    }}
                >
                    <CalendarFilters />
                </SideSheet>
            }

            {
                currentSideSheet === SideSheetType.Views &&
                <SideSheet
                    open={currentSideSheet === SideSheetType.Views}
                    closeSideSheet={(): void => {
                        setCurrentSideSheet(null);
                    }}
                >
                    <CalendarViews />
                </SideSheet>
            }
        </Fragment>
    );
};

export default ShipmentsCalendarPage;
