import React, { useRef } from 'react';
import { TableSortLabel, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import Draggable from 'react-draggable';
import { ColDef, Order } from '../../interfaces/muiTableInterfaces';

const classesPrefix = 'tableColumnHeader';

const classes = {
    tableHeader: `${classesPrefix}-tableHeader`,
    tableHeaderResizeHandle: `${classesPrefix}-tableHeaderResizeHandle`,
    tableHeaderResizeHandleActive: `${classesPrefix}-tableHeaderResizeHandleActive`,
    tableHeadersortIcon: `${classesPrefix}-tableHeadersortIcon`
};

const StyledTableCell = styled(TableCell)(() => {
    return {
        [`& .${classes.tableHeader}`]: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            display: 'inline-block'
        },
        [`& .${classes.tableHeaderResizeHandle}`]: {
            cursor: 'col-resize',
            fontSize: '1rem',
            verticalAlign: 'middle',
            position: 'absolute',
            right: 0
        },
        [`& .${classes.tableHeaderResizeHandleActive}`]: {
            color: 'transparent'
        },
        [`& .${classes.tableHeadersortIcon}`]: {
            position: 'absolute',
            left: '92%'
        }
    };
});

const TableColumnHeader = <T extends object>({
    column,
    order,
    orderBy,
    handleWidthChange,
    handleRequestSort
}: {
    /** the metadata about the current column */
    column: ColDef<T>;
    /** the direction the table is currently being sorted. asc or desc */
    order: Order;
    /** the column the table is currently being sorted by */
    orderBy: keyof T;
    /** function to update the width of the column */
    handleWidthChange: (field: keyof T, newWidth: number) => void;
    /** function to update the column being sorted and the sort direction */
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
}): JSX.Element => {
    const draggableRef = useRef(null);
    const createSortHandler = (property: keyof T) => {
        return (event: React.MouseEvent<unknown>): void => {
            handleRequestSort(event, property);
        };
    };

    return (
        <StyledTableCell
            sortDirection={orderBy === column.field ? order : false}
            data-qa={`${column.field}-column-header`}
        >
            <TableSortLabel
                classes={{ icon: classes.tableHeadersortIcon }}
                active={orderBy === column.field}
                direction={order}
                onClick={createSortHandler(column.field)}
                style={{
                    width: `${column.width || 100}px`
                }}
                data-qa={`${column.field}-label`}
            >
                <span className={classes.tableHeader}>
                    {column.headerName}
                </span>
            </TableSortLabel>
            <Draggable
                nodeRef={draggableRef}
                axis='x'
                defaultClassName={classes.tableHeaderResizeHandle}
                defaultClassNameDragging={classes.tableHeaderResizeHandleActive}
                onDrag={(event, data): void => {
                    const newWidth = (column.width || 100) + data.deltaX;
                    handleWidthChange(column.field, newWidth);
                }}
                position={{ x: 0, y: 0 }}
            >
                <span ref={draggableRef} data-qa='resizeHandle'>⋮</span>
            </Draggable>
        </StyledTableCell>
    );
};

export default TableColumnHeader;
