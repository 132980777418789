import React from 'react';

import { ShipmentData } from '../../interfaces/services/shipmentDetails';
import { isShipmentLtl } from '../../helpers/shipmentUtils';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import { ModeType } from '../../helpers/enums';
import Hidden from '../layouts/hidden';
import MilestoneProgressBar from './milestoneProgressBar';
import MilestoneProgressBarLoading from '../loaders/progressBars/milestoneProgressBarLoading';
import HaulLengthProgressBar from './haulLengthProgressBar';
import HaulLengthProgressBarLoading from '../loaders/progressBars/haulLengthProgressBarLoading';

const ModeBasedProgressBar = ({
    shipment,
    modeType
}: {
    shipment: ShipmentData | null;
    modeType?: ModeType;
}): JSX.Element => {
    if (shipment) {
        // use the shipment mode type since we know it
        if (isShipmentLtl(shipment.modeType)) {
            return (
                <Hidden breakpoint='sm' direction='down'>
                    <MilestoneProgressBar
                        milestoneProgress={shipment.currentMilestoneProgress}
                        color={getDeliveryStatusColor(shipment.deliveryStatus)}
                    />
                </Hidden>
            );
        }

        return (
            <Hidden breakpoint='sm' direction='down'>
                <HaulLengthProgressBar
                    haulLength={shipment.haulLength}
                    haulLinearUnitType={shipment.haulLinearUnitType}
                    remainingHaulLength={shipment.remainingHaulLength}
                    shipmentStatus={shipment.shipmentStatus}
                    deliveryStatus={shipment.deliveryStatus}
                    stops={shipment.stops}
                />
            </Hidden>
        );
    }

    // if we have a modeType prop use that to determine the proper loader. With shipmentDetails we know the modeType before we have the shipment details
    if (modeType && isShipmentLtl(modeType)) {
        return (
            <Hidden breakpoint='sm' direction='down'>
                <MilestoneProgressBarLoading />
            </Hidden>
        );
    }

    return (
        <Hidden breakpoint='sm' direction='down'>
            <HaulLengthProgressBarLoading />
        </Hidden>
    );

};

export default ModeBasedProgressBar;
