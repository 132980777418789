import React, { Fragment, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ApiStatus, DeliveryStatus } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import { SearchDimension } from '../../interfaces/services/shipmentDimensionSummary';
import { trackingStatuses, notTrackingStatuses } from '../../helpers/maps/enums';

/** Interface used to indicate a count of total filtered and non-filtered shipments for each DeliveryStatus. */
interface DeliveryStatusCounts {
    /** Total count for filtered shipments. If null, the value will not show. */
    filteredCount: number | null;
    /** Total count for non-filtered shipments. */
    totalCount: number;
    /** The Delivery Status for the counts. */
    status: DeliveryStatus;
}

interface StyleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    deliveryStatus: DeliveryStatus;
}

const classesPrefix = 'deliveryStatusLegend';

const classes = {
    totalRowWrapper: `${classesPrefix}-totalRowWrapper`,
    totalTitle: `${classesPrefix}-totalTitle`,
    filteredCountSeparator: `${classesPrefix}-filteredCountSeparator`,
    totalCount: `${classesPrefix}-totalCount`,
    notTrackingTitle: `${classesPrefix}-notTrackingTitle`,
    statusWrapper: `${classesPrefix}-statusWrapper`,
    statusCircle: `${classesPrefix}-statusCircle`,
    statusName: `${classesPrefix}-statusName`,
    statusCount: `${classesPrefix}-statusCount`,
    countRow: `${classesPrefix}-countRow`,
    countWrapper: `${classesPrefix}-countWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.totalRowWrapper}`]: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: '4px'
        },
        [`&.${classes.notTrackingTitle}`]: {
            marginTop: '8px'
        },
        [`& .${classes.totalTitle}`]: {
            fontSize: '12px',
            fontWeight: 600
        },
        [`& .${classes.filteredCountSeparator}`]: {
            borderRight: `1px solid ${theme.palette.text.secondary}`
        },
        [`& .${classes.totalCount}`]: {
            fontSize: '12px',
            paddingLeft: '4px',
            paddingRight: '4px',
            fontWeight: 600
        },
        [`& .${classes.statusWrapper}`]: {
            display: 'inline-flex'
        },
        [`& .${classes.statusName}`]: {
            fontSize: '12px'
        },
        [`& .${classes.statusCount}`]: {
            fontSize: '12px',
            paddingLeft: '4px',
            paddingRight: '4px'
        },
        [`& .${classes.countRow}`]: {
            margin: '4px 0'
        },
        [`& .${classes.countWrapper}`]: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    };
});

const StyledDeliveryStatusDiv = styled(
    'div',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'deliveryStatus'; }
    }
)<StyleProps>(({ deliveryStatus }) => {
    return {
        [`&.${classes.statusCircle}`]: {
            height: '16px',
            width: '16px',
            marginRight: '4px',
            borderRadius: '50%',
            backgroundColor: getDeliveryStatusColor(deliveryStatus)
        }
    };
});

const DeliveryStatusLegend = ({
    mapLegendStatus,
    mapLegend,
    mapLegendFilteredStatus,
    mapLegendFiltered
}: {
    mapLegendStatus: ApiStatus;
    mapLegend: SearchDimension[];
    mapLegendFilteredStatus: ApiStatus;
    mapLegendFiltered: SearchDimension[];
}): JSX.Element => {
    const deliveryStatusList = useMemo((): DeliveryStatusCounts[] => {
        if (mapLegendStatus !== ApiStatus.Idle && mapLegendStatus !== ApiStatus.Loading &&
            mapLegendFilteredStatus !== ApiStatus.Idle && mapLegendFilteredStatus !== ApiStatus.Loading) {
            const list = mapLegend.map((deliveryStatusItem: SearchDimension): DeliveryStatusCounts => {
                // check for the corresponding filtered delivery status
                const filteredItem = mapLegendFiltered.find((filteredDeliveryStatusItem): boolean => {
                    return filteredDeliveryStatusItem.value === deliveryStatusItem.value;
                });

                return {
                    filteredCount: filteredItem?.count || null,
                    totalCount: deliveryStatusItem.count,
                    status: deliveryStatusItem.value as DeliveryStatus
                };
            });

            return list;
        }
        return [];
    }, [mapLegendStatus, mapLegendFilteredStatus, mapLegend, mapLegendFiltered]);

    const renderTotalRow = (title: string, statusList: DeliveryStatus[]): JSX.Element => {
        const initialValue = 0;

        let isFiltered = false;
        const filteredTotal = deliveryStatusList.reduce((accumulator: number, currentValue: DeliveryStatusCounts): number => {
            const currentDeliveryStatus = statusList.find((status): boolean => {
                return status === currentValue.status;
            });

            if (currentValue.filteredCount !== null) {
                isFiltered = true;
            }

            return +accumulator + (currentDeliveryStatus && currentValue.filteredCount ? +currentValue.filteredCount : +0);

        }, initialValue);

        const total = deliveryStatusList.reduce((accumulator: number, currentValue: DeliveryStatusCounts): number => {
            const currentDeliveryStatus = statusList.find((status): boolean => {
                return status === currentValue.status;
            });

            return +accumulator + (currentDeliveryStatus ? +currentValue.totalCount : +0);

        }, initialValue);

        return (
            <Fragment>
                <Grid item xs={isFiltered ? 6 : 9}>
                    <Typography className={classes.totalTitle} data-qa={`${title}-label`}>{title}</Typography>
                </Grid>
                {
                    isFiltered &&
                    <Grid item xs={3} className={classes.countWrapper}>
                        <Typography className={`${classes.totalCount} ${classes.filteredCountSeparator}`} data-qa={`${title}-filteredTotal`}>{filteredTotal || 0}</Typography>
                    </Grid>
                }
                <Grid item xs={3} className={classes.countWrapper}>
                    <Typography className={classes.totalCount} data-qa={`${title}-total`}>{total}</Typography>
                </Grid>
            </Fragment>
        );
    };

    const renderCountRow = (currentDeliveryStatus: DeliveryStatus): JSX.Element => {
        const isFiltered = deliveryStatusList.some((deliveryStatusCount): boolean => {
            return deliveryStatusCount.filteredCount !== null;
        });

        const currentDeliveryStatusCount = deliveryStatusList.find((deliveryStatusCount): boolean => {
            return deliveryStatusCount.status === currentDeliveryStatus;
        });

        return (
            <Grid item xs={12} key={currentDeliveryStatus} className={classes.countRow}>
                <Grid container>
                    <Grid item xs={isFiltered ? 6 : 9} className={classes.statusWrapper}>
                        <StyledDeliveryStatusDiv deliveryStatus={currentDeliveryStatus} className={classes.statusCircle} />
                        <Typography className={classes.statusName} data-qa={`${currentDeliveryStatus}-label`}>{addSpaceBeforeUppercaseCharacter(currentDeliveryStatus)}</Typography>
                    </Grid>
                    {
                        isFiltered &&
                        <Grid item xs={3} className={classes.countWrapper}>
                            <Typography
                                className={`${classes.statusCount} ${classes.filteredCountSeparator}`}
                                data-qa={`${currentDeliveryStatus}-filteredTotal`}
                            >
                                {currentDeliveryStatusCount?.filteredCount || 0}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={3} className={classes.countWrapper}>
                        <Typography className={classes.statusCount} data-qa={`${currentDeliveryStatus}-total`}>{currentDeliveryStatusCount?.totalCount || 0}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Fragment>
            <StyledGrid container className={classes.totalRowWrapper}>
                {renderTotalRow('Tracking', trackingStatuses)}
            </StyledGrid>
            <StyledGrid container>
                {
                    trackingStatuses.map((deliveryStatus: DeliveryStatus): JSX.Element => {
                        return renderCountRow(deliveryStatus);
                    })
                }
            </StyledGrid>
            <StyledGrid container className={`${classes.totalRowWrapper} ${classes.notTrackingTitle}`}>
                {renderTotalRow('Not Tracking', notTrackingStatuses)}
            </StyledGrid>
            <StyledGrid container>
                {
                    notTrackingStatuses.map((deliveryStatus: DeliveryStatus): JSX.Element => {
                        return renderCountRow(deliveryStatus);
                    })
                }
            </StyledGrid>
        </Fragment>
    );
};

export default DeliveryStatusLegend;
