import React, { Fragment, useState } from 'react';
import { Link } from '@mui/material';

import { useAccessTokenUrl } from '../../hooks/useAccessTokenUrl';
import LabelValuePair from '../labels/labelValuePair';
import OrderDetailsDialog from '../dialogs/orderDetailsDialog';

const OrderDetailsLabelLink = ({
    label,
    freightOrderGuid,
    orderNumber,
    fontSize = '14px',
    noWrap = false,
    alwaysOpenInNewTab = false
}: {
    // The display label describing the order link.
    label: string;
    // The freightOrderGuid of the order.
    freightOrderGuid: string;
    // The orderNumber of the order details to display */
    orderNumber: string;
    // The size of the font.
    fontSize?: string;
    // If true, the text will not wrap, but instead will truncate with a text overflow ellipsis.
    noWrap?: boolean;
    // When true, always open the order details in a new tab
    alwaysOpenInNewTab?: boolean;
}): JSX.Element => {
    const [orderDetailsDialogIsOpen, setOrderDetailsDialogIsOpen] = useState(false);
    const url = useAccessTokenUrl(`/orderDetails/${freightOrderGuid}`);

    return (
        <Fragment>
            <LabelValuePair
                label={label}
                fontSize={fontSize}
                noWrap={noWrap}
                value={
                    <span data-qa='orderDetailsNavigation-container'>
                        <Link
                            target='_blank'
                            rel='noopener noreferrer'
                            href={url}
                            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
                                if (alwaysOpenInNewTab === false) {
                                    // README: When the link is clicked js will open the Dialog and will not navigate.
                                    // If the user right clicks and selects open in a new tab the href value is used to navigate
                                    event.preventDefault();
                                    setOrderDetailsDialogIsOpen(true);
                                }
                            }}
                            data-qa='orderDetails-navigation'
                        >
                            {orderNumber}
                        </Link>
                    </span>
                }
                data-qa='orderNumber'
            />

            {
                orderDetailsDialogIsOpen &&
                <OrderDetailsDialog
                    orderGuid={freightOrderGuid}
                    handleDialogClose={(): void => {
                        setOrderDetailsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default OrderDetailsLabelLink;
