import { PaletteMode } from '@mui/material';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

// More on creating a Custom Theme: https://mui.com/customization/theming/
export const createApplicationTheme = ({
    paletteMode,
    primaryNavBackground,
    primaryMain,
    secondaryMain
}: {
    paletteMode: PaletteMode;
    primaryNavBackground: string | null;
    primaryMain: string | null;
    secondaryMain: string | null;
}): Theme => {
    // To use any default theme colors in our overrides, we need to create an instance of the default theme using the correct mode
    // https://mui.com/customization/default-theme/
    const defaultTheme = createTheme({ palette: { mode: paletteMode } });

    // NOTE: When adding or deleting colors in this const be sure to keep the CustomAppColors interface in sync
    const themeColors = {
        // user defined theme variables
        primaryNavBackground: primaryNavBackground || '#00437b', // primary nav background color
        primaryMain: primaryMain || '#005f9e', // primary main
        secondaryMain: secondaryMain || '#FFBE00', // secondary main

        // other custom app colors
        action: '#009ad9',
        progressDefaultBackground: '#E5E5E5',
        appToolbarBackground: paletteMode === 'light' ? defaultTheme.palette.grey[200] : defaultTheme.palette.grey[600],
        pageBackground: paletteMode === 'light' ? defaultTheme.palette.grey[300] : defaultTheme.palette.grey[700],

        // status action colors
        priorityActionBackground: '#ff6d00',
        exceptionsActionBackground: '#f44336',
        temperatureActionBackground: '#f44336',
        notesActionBackground: '#1976d2',
        documentsActionBackground: '#1976d2'
    };

    const themeOptions: ThemeOptions = {
        appColors: themeColors,
        typography: {
            fontFamily: '"Open Sans", Roboto, Helvetica, Arial, sans-serif'
        },
        palette: {
            mode: paletteMode,
            primary: {
                main: themeColors.primaryMain
            },
            secondary: {
                main: themeColors.secondaryMain
            }
        },
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: themeColors.appToolbarBackground,
                        maxHeight: '64px'
                    },
                    dense: {
                        backgroundColor: themeColors.primaryNavBackground
                    }
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    input: {
                        fontSize: '16px',
                        fontWeight: 600
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 600
                    }
                }
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            fontWeight: 600
                        }
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    textColorPrimary: {
                        color: defaultTheme.palette.text.primary,
                        '&.Mui-selected': {
                            color: defaultTheme.palette.text.primary,
                            fontWeight: 600
                        }
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        '& caption': {
                            captionSide: 'top',
                            borderBottom: `1px solid ${defaultTheme.palette.divider}`
                        }
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginRight: '0px'
                    },
                    label: {
                        fontSize: '12px'
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '& > a': {
                            color: defaultTheme.palette.text.primary
                        },
                        '& > button': {
                            color: defaultTheme.palette.text.primary
                        }
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: 'inherit'
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: themeColors.action,
                        fontWeight: 600
                    }
                },
                defaultProps: {
                    underline: 'hover'
                }
            },
            // this override prevents weird blue outline on time picker in Safari browser
            MuiPaper: {
                defaultProps: {
                    sx: {
                        '& *:focus': {
                            outline: 'none'
                        }
                    }
                }
            }
        }
    };

    return createTheme(themeOptions);
};
