import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import Hidden from '../../layouts/hidden';
import LabelValuePairLoading from '../labels/labelValuePairLoading';

const classesPrefix = 'shipmentCardLoading';

const classes = {
    cardContainer: `${classesPrefix}-cardContainer`,
    cardWrapper: `${classesPrefix}-cardWrapper`,
    addressContainer: `${classesPrefix}-addressContainer`,
    stopTitle: `${classesPrefix}-stopTitle`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.cardContainer}`]: {
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:first-of-type': {
                borderTop: 0
            }
        },
        [`& .${classes.cardWrapper}`]: {
            padding: '8px 16px'
        },
        [`& .${classes.addressContainer}`]: {
            marginBottom: '4px'
        },
        [`& .${classes.stopTitle}`]: {
            fontWeight: 600,
            fontSize: '12px',
            paddingBottom: '4px'
        }
    };
});

const ShipmentCardLoading = (): JSX.Element => {
    return (
        <StyledGrid container className={classes.cardContainer}>
            <Grid item xs={12} className={classes.cardWrapper}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <LabelValuePairLoading label='Shipment' width='120px' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValuePairLoading label='Carrier' width='120px' />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <LabelValuePairLoading label='Mode' width='60px' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValuePairLoading label='Current Stop' width='12px' />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <LabelValuePairLoading label='Tractor #' width='60px' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValuePairLoading label='Trailer #' width='60px' />
                    </Grid>
                </Grid>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item xs={12} sm='auto'>
                                <Skeleton variant='text' width='70px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant='rectangular' width='100px' height='30px' />
                    </Grid>
                </Grid>

                <Grid container className={classes.addressContainer} spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.stopTitle}>Origin</Typography>
                        <Skeleton variant='text' width='80px' />
                        <Skeleton variant='text' width='120px' />
                        <Skeleton variant='text' width='120px' />

                        <Hidden breakpoint='sm' direction='up'>
                            <Skeleton variant='text' width='150px' />
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.stopTitle}>Destination</Typography>
                        <Skeleton variant='text' width='80px' />
                        <Skeleton variant='text' width='120px' />
                        <Skeleton variant='text' width='120px' />

                        <Hidden breakpoint='sm' direction='up'>
                            <Skeleton variant='text' width='150px' />
                        </Hidden>
                    </Grid>
                </Grid>

                <Hidden breakpoint='xs'>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Skeleton variant='text' width='150px' />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant='text' width='150px' />
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </StyledGrid>
    );
};

export default ShipmentCardLoading;
