import React from 'react';
import {
    Checkbox,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiDragHorizontalVariant } from '@mdi/js';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { UserSettingAttribute } from '../../interfaces/services/organization';

const classesPrefix = 'draggableSettingsList';

const classes = {
    textContainer: `${classesPrefix}-textContainer`
};

const StyledList = styled(List)(() => {
    return {
        [`& .${classes.textContainer}`]: {
            paddingRight: '28px'
        }
    };
});

const DraggableSettingsList = ({
    list,
    handleDragEnd,
    handleCheckboxToggle
}: {
    list: UserSettingAttribute[];
    handleDragEnd: (result: DropResult) => void;
    handleCheckboxToggle: (itemKey: string) => void;
}): JSX.Element => {
    const userSettingsUpdateStatus = useTypedSelector((state) => { return state.user.userSettingsUpdateStatus; });
    const userSettingsStatus = useTypedSelector((state) => { return state.user.userSettingsStatus; });

    const onDragEnd = (result: DropResult): void => {
        handleDragEnd(result);
    };

    const renderListItems = (): JSX.Element[] => {
        if (userSettingsStatus === ApiStatus.Idle || userSettingsStatus === ApiStatus.Loading ||
            userSettingsUpdateStatus === ApiStatus.Loading) {
            return Array.from(new Array(25)).map((item, index): JSX.Element => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItemButton key={index}>
                        <ListItemIcon>
                            <Skeleton variant='rectangular' width='24px' height='24px' />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Skeleton variant='rectangular' width='180px' height='20px' />
                            }
                        />
                        <ListItemSecondaryAction>
                            <Skeleton variant='rectangular' width='24px' height='24px' />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                );
            });
        }

        return list.map((counter, index): JSX.Element => {
            const labelId = `checkbox-list-label-${counter.key}`;
            return (
                <Draggable key={counter.key} draggableId={counter.key} index={index}>
                    {(provided): JSX.Element => {
                        return (
                            <ListItemButton
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                dense
                                onClick={(): void => { handleCheckboxToggle(counter.key); }}
                                data-qa={`${counter.key}-listItem`}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge='start'
                                        color='primary'
                                        checked={counter.visible}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        data-qa={`${counter.key}-checkbox`}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    className={classes.textContainer}
                                    primary={counter.displayName}
                                    data-qa={`${counter.key}-label`}
                                />
                                <ListItemSecondaryAction
                                    {...provided.dragHandleProps}
                                >
                                    <Icon path={mdiDragHorizontalVariant} size={1} />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        );
                    }}
                </Draggable>
            );
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='counterList'>
                {(provided): JSX.Element => {
                    return (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <StyledList>
                                {renderListItems()}
                            </StyledList>
                            {provided.placeholder}
                        </div>
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableSettingsList;
