import React, { Fragment, useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Tooltip,
    IconButton,
    Link
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import {
    mdiCircleOutline,
    mdiMapMarker,
    mdiCheckCircleOutline,
    mdiAlarm,
    mdiMessageBulleted,
    mdiShareVariant,
    mdiAttachment,
    mdiFileDocument
} from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { handleShareUrlCopy } from '../../helpers/navigationUtils';
import { Order, Stop } from '../../interfaces/services/shipmentDetails';
import { ModeType, OrderStopType, ShareType } from '../../helpers/enums';
import { formatPhoneNumber } from '../../helpers/dataUtils';
import ExpansionPanel from '../expansionPanel/expansionPanel';
import DeliveryStatusIndicator from '../labels/deliveryStatusIndicator';
import StopAddress from '../labels/stopAddress';
import StopDateLabel from '../labels/stopDateLabel';
import LabelValuePair from '../labels/labelValuePair';
import StopOrdersTable from '../tables/stopOrdersTable';
import ShipmentActionButton from '../buttons/shipmentActionButton';
import EditTimesDialog from '../dialogs/editTimesDialog';
import NotesDialog from '../dialogs/notesDialog';
import DocumentsDialog from '../dialogs/documentsDialog';

const classesPrefix = 'stopCard';

const classes = {
    stopHeader: `${classesPrefix}-stopHeader`,
    stopLabel: `${classesPrefix}-stopLabel`,
    additionalInfoContainer: `${classesPrefix}-additionalInfoContainer`,
    addressContainer: `${classesPrefix}-addressContainer`,
    displayData: `${classesPrefix}-displayData`,
    deliveryStatusContainer: `${classesPrefix}-deliveryStatusContainer`,
    iconButtonWrapper: `${classesPrefix}-iconButtonWrapper`,
    notes: `${classesPrefix}-notes`,
    documents: `${classesPrefix}-documents`,
    iconButton: `${classesPrefix}-iconButton`
};

const StyledCard = styled(Card)(({ theme }) => {
    return {
        [`& .${classes.stopHeader}`]: {
            display: 'flex',
            alignItems: 'center'
        },
        [`& .${classes.stopLabel}`]: {
            display: 'inline-block',
            fontWeight: 600,
            fontSize: '16px',
            marginLeft: '4px',
            verticalAlign: 'top'
        },
        [`& .${classes.additionalInfoContainer}`]: {
            marginBottom: '8px'
        },
        [`& .${classes.addressContainer}`]: {
            margin: '8px 0 8px 0'
        },
        [`& .${classes.displayData}`]: {
            fontWeight: 400,
            marginLeft: '4px',
            marginRight: '4px'
        },
        [`& .${classes.deliveryStatusContainer}`]: {
            flexGrow: 1
        },
        [`& .${classes.iconButtonWrapper}`]: {
            justifyContent: 'center',
            height: '28px',
            width: '28px',
            borderRadius: '50%',
            margin: '4px'
        },
        [`& .${classes.notes}`]: {
            backgroundColor: theme.appColors.notesActionBackground
        },
        [`& .${classes.documents}`]: {
            backgroundColor: theme.appColors.documentsActionBackground
        },
        [`& .${classes.iconButton}`]: {
            padding: '0px'
        }
    };
});

const StopCard = ({
    shipmentUniqueName,
    modeType,
    stop,
    orders,
    stopTypeLabel
}: {
    /** Unique identifier for the shipment */
    shipmentUniqueName: string;
    /** The mode type for the shipment. */
    modeType: ModeType;
    /** The stop information available for display. */
    stop: Stop;
    /** Total Number of stops on the shipment */
    /** The orders connected to the shipment */
    orders: Order[];
    /** Label to use for the stop card */
    stopTypeLabel: React.ReactNode;
}): JSX.Element => {
    const theme = useTheme();

    const [editTimesDialogIsOpen, setEditTimesDialogIsOpen] = useState(false);
    const [notesDialogIsOpen, setNotesDialogIsOpen] = useState(false);
    const [documentsDialogIsOpen, setDocumentsDialogIsOpen] = useState(false);

    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const UrlShortenerApi = useTypedSelector((state) => { return state.availableServices.endpoints.UrlShortenerApi; });
    const shortUrlExpiryDays = useTypedSelector((state) => { return state.organization.organizationPreferences?.shortUrlExpiryDays; });

    const ordersForStop = orders.filter((order) => {
        return order.pickupStopReference === stop.stopReference || order.deliveryStopReference === stop.stopReference;
    });

    const renderStopCardIcon = (): JSX.Element => {
        let cardIcon = mdiCircleOutline;
        if (stop.isActiveStop) {
            cardIcon = mdiMapMarker;
        } else if (stop.hasStopBeenVisited) {
            cardIcon = mdiCheckCircleOutline;
        }

        return (
            <Icon
                path={cardIcon}
                size={1}
                color={theme.palette.text.secondary}
            />
        );
    };

    const renderStopCardActions = (): JSX.Element => {
        /* If the shareType is null it is not a shared stop so display the following actions */
        if (shareType === null) {
            return (
                <Fragment>
                    <ShipmentActionButton
                        title='Edit Times'
                        handleClick={(): void => {
                            setEditTimesDialogIsOpen(true);
                        }}
                        data-qa='editTimes-action'
                        mdiIcon={mdiAlarm}
                        iconColor={theme.palette.text.secondary}
                    />
                    <ShipmentActionButton
                        title='Share Stop'
                        handleClick={(): void => {
                            handleShareUrlCopy({
                                urlShortenerApi: UrlShortenerApi,
                                pageUrl: `/shipmentDetails/${shipmentUniqueName}`,
                                token: stop.stopSharingToken,
                                successMessage: 'Shipment share url copied to clipboard.',
                                shortenedUrlName: `Shipment ${shipmentUniqueName}`,
                                expiryDays: shortUrlExpiryDays
                            });
                        }}
                        data-qa='shareStop-action-open'
                        mdiIcon={mdiShareVariant}
                        iconColor={theme.palette.text.secondary}
                    />
                    <ShipmentActionButton
                        title='View and Add Notes'
                        handleClick={(): void => {
                            setNotesDialogIsOpen(true);
                        }}
                        data-qa='addNotes-action'
                        mdiIcon={mdiMessageBulleted}
                        iconColor={theme.palette.text.secondary}
                    />
                    <ShipmentActionButton
                        title='View and Attach Documents'
                        handleClick={(): void => {
                            setDocumentsDialogIsOpen(true);
                        }}
                        data-qa='addDocuments-action'
                        mdiIcon={mdiAttachment}
                        iconColor={theme.palette.text.secondary}
                    />
                </Fragment>
            );
        }
        /* If the stop is shared and has notes on it display the view notes action */
        if (stop.stopNotes.length > 0) {
            return (
                <Fragment>
                    <ShipmentActionButton
                        title='View Notes'
                        handleClick={(): void => {
                            setNotesDialogIsOpen(true);
                        }}
                        data-qa='viewNotes-action'
                        mdiIcon={mdiMessageBulleted}
                        iconColor={theme.palette.text.secondary}
                    />
                    <ShipmentActionButton
                        title='View Documents'
                        handleClick={(): void => {
                            setDocumentsDialogIsOpen(true);
                        }}
                        data-qa='viewDocuments-action'
                        mdiIcon={mdiAttachment}
                        iconColor={theme.palette.text.secondary}
                    />
                </Fragment>
            );
        }

        return <Fragment />;
    };

    return (
        <Fragment>
            <StyledCard>
                <CardContent>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} md={6} className={classes.stopHeader}>
                            {renderStopCardIcon()}
                            <Typography className={classes.stopLabel} data-qa='stopTypeLabel'>
                                {stopTypeLabel}
                            </Typography>
                            {
                                stop.stopNotes.length > 0 &&
                                <div className={`${classes.iconButtonWrapper} ${classes.notes} ${classes.stopHeader}`}>
                                    <Tooltip title='Stop Notes'>
                                        <IconButton
                                            className={classes.iconButton}
                                            onClick={(): void => {
                                                setNotesDialogIsOpen(true);
                                            }}
                                            data-qa='stopNotes-button'
                                        >
                                            <Icon
                                                path={mdiMessageBulleted}
                                                size='20px'
                                                color={theme.palette.common.white}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                            {
                                stop.documents.length > 0 &&
                                <div className={`${classes.iconButtonWrapper} ${classes.documents} ${classes.stopHeader}`}>
                                    <Tooltip title='Stop Documents'>
                                        <IconButton
                                            className={classes.iconButton}
                                            onClick={(): void => {
                                                setDocumentsDialogIsOpen(true);
                                            }}
                                            data-qa='stopDocuments-button'
                                        >
                                            <Icon
                                                path={mdiFileDocument}
                                                size='20px'
                                                color={theme.palette.common.white}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                        </Grid>

                        <Grid item className={classes.deliveryStatusContainer}>
                            <DeliveryStatusIndicator
                                deliveryStatus={stop.deliveryStatus}
                            />
                        </Grid>
                        <Grid item>
                            {
                                stopTypeLabel !== OrderStopType.Pickup && stopTypeLabel !== OrderStopType.Delivery &&
                                renderStopCardActions()
                            }
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className={classes.addressContainer}>
                            <StopAddress
                                description={stop.description}
                                location={stop.fixedLocationCode}
                                name={stop.name}
                                street1={stop.address.streetAddress1}
                                street2={stop.address.streetAddress2}
                                city={stop.address.city}
                                region={stop.address.state}
                                postal={stop.address.postalCode}
                                country={stop.address.countryCode}
                                modeType={modeType}
                                singleLineAddress={true}
                            />
                        </Grid>
                        {
                            stop.additionalInformation &&
                            <Grid item xs={12} className={classes.additionalInfoContainer}>
                                <LabelValuePair
                                    label='Additional Info'
                                    value={stop.additionalInformation}
                                    fontSize='12px'
                                    data-qa='additionalInfo'
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            {
                                stop.contact.name &&
                                <LabelValuePair
                                    label='Contact Name'
                                    value={stop.contact.name}
                                    fontSize='12px'
                                    data-qa='contactName'
                                />
                            }
                            {
                                stop.contact.phoneNumber &&
                                <LabelValuePair
                                    label='Phone'
                                    value={
                                        <Fragment>
                                            <Link href={`tel:${stop.contact.phoneNumber}`} data-qa='phoneNumber'>
                                                {formatPhoneNumber(stop.contact.phoneNumber)}
                                            </Link>
                                            {
                                                stop.contact.phoneExtension &&
                                                <Fragment>
                                                    <span className={classes.displayData} data-qa='extension-label'>
                                                        ext.
                                                    </span>
                                                    <span data-qa='extension'>
                                                        {stop.contact.phoneExtension}
                                                    </span>
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                    fontSize='12px'
                                    data-qa='contactPhone'
                                />
                            }
                            {
                                stop.contact.emailAddress &&
                                <LabelValuePair
                                    label='Email'
                                    value={
                                        <Link href={`mailto:${stop.contact.emailAddress}`}>
                                            {stop.contact.emailAddress}
                                        </Link>
                                    }
                                    fontSize='12px'
                                    data-qa='contactEmail'
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LabelValuePair
                                label='Anticipated Dwell Time'
                                value={stop.dwellTimeAnticipatedDisplay}
                                fontSize='12px'
                                data-qa='anticipatedDwellTime'
                            />
                            <LabelValuePair
                                label='Actual Dwell Time'
                                value={stop.dwellTimeDisplay}
                                fontSize='12px'
                                data-qa='dwellTime'
                            />
                            <StopDateLabel stop={stop} />
                        </Grid>
                    </Grid>

                    {
                        ordersForStop.length > 0 &&
                        <ExpansionPanel
                            label='Orders'
                            defaultExpanded={shareType === ShareType.Stop}
                        >
                            <StopOrdersTable
                                isFetchingData={false}
                                orders={ordersForStop}
                                stopReference={stop.stopReference}
                            />
                        </ExpansionPanel>
                    }
                </CardContent>
            </StyledCard>
            {
                editTimesDialogIsOpen &&
                <EditTimesDialog
                    shipmentUniqueName={shipmentUniqueName}
                    initialStopSequence={stop.stopSequence - 1}
                    isOpen={editTimesDialogIsOpen}
                    closeDialog={(): void => {
                        setEditTimesDialogIsOpen(false);
                    }}
                />
            }
            {
                notesDialogIsOpen &&
                <NotesDialog
                    shipmentUniqueName={shipmentUniqueName}
                    initialStopSequence={stop.stopSequence - 1}
                    isOpen={notesDialogIsOpen}
                    closeDialog={(): void => {
                        setNotesDialogIsOpen(false);
                    }}
                />
            }
            {
                documentsDialogIsOpen &&
                <DocumentsDialog
                    shipmentUniqueName={shipmentUniqueName}
                    initialStopSequence={stop.stopSequence - 1}
                    isOpen={documentsDialogIsOpen}
                    closeDialog={(): void => {
                        setDocumentsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default StopCard;
