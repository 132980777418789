import React from 'react';
import { PaletteMode } from '@mui/material';
import { styled } from '@mui/material/styles';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Feature, Point } from 'geojson';
import { bbox, Polygon } from '@turf/turf';

import { ShipmentData } from '../../interfaces/services/shipmentDetails';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import { addTripDetailsSource } from '../../helpers/maps/sources/tripDetails';
import { ShipmentDetailsMapSourceProperties } from '../../helpers/maps/sources/shipmentDetails';
import {
    addShipmentDetailsStopsLayer,
    addShipmentDetailsGeofenceLayers,
    addShipmentDetailsRouteLayer,
    addShipmentDetailsPastPositionsLayer,
    addShipmentDetailsCurrentPositionLayer,
    addShipmentDetailsCurrentPositionPulseLayer
} from '../../helpers/maps/layers/shipmentDetails';
import { addTrafficLayers } from '../../helpers/maps/layers/traffic';
import {
    toggleLayerVisibility,
    fitMapToBounds,
    trimbleMapsPopup,
    handleMouseEnter,
    handleMouseLeave,
    handlePopupMouseEnter,
    handleLineStringPopupMouseEnter,
    handlePopupMouseLeave
} from '../../helpers/maps/mapUtils';
import {
    MapSources,
    MapLayers,
    MapImages
} from '../../helpers/maps/enums';
import MapSpinner from '../loaders/mapSpinner';
import MapControlBar from '../actionBars/mapControlBar';
import MapLegendWrapper from '../mapLegends/mapLegendWrapper';

import '../../assets/CSS/trimbleMaps.css';

const classesPrefix = 'tripDetailsMap';

const classes = {
    map: `${classesPrefix}-map`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.map}`]: {
            height: '100%'
        }
    };
});

interface TripDetailsMapProps {
    /** Indicator to show loading spinner while data is fetching. */
    isFetchingData: boolean;
    /** Indicator to show or hide the route line when first loading the map. */
    isRouteLineVisible: boolean;
    /** Active shipment to use for the current location. */
    activeShipment: ShipmentData;
    /** Shipments to show on the map. */
    shipments: ShipmentData[];
    /** Image to show on the map for the origin and destination stops. */
    originAndDestinationImage: string;
    /** Image to show on the map for the intermediate stops. */
    intermediateStopImage: string;
    /** Palette Mode used to determine map style. */
    paletteMode: PaletteMode;
    /** Indicator for if the screen is a mobile size. */
    isMobile: boolean;
    /** Indicator to trigger a map resize function. */
    resize?: boolean;
}

interface TripDetailsMapState {
    isWeatherRadarActive: boolean;
    isWeatherRoadSurfaceActive: boolean;
    isTrafficActive: boolean;
    isTrafficIncidentActive: boolean;
    is3dBuildingsLayerDisabled: boolean;
    is3dBuildingsActive: boolean;
    isRouteLineActive: boolean;
    isMapScrollZoomActive: boolean;
    mapTileStyle: string;
    mapStyleLoaded: boolean;
    flyToOn: boolean;
    flyToLocation: string | null;
    mapRouteBounds: any;
    zoomMap: boolean;
}

let map: any = null;
const mapContainer = React.createRef<HTMLDivElement>();

class TripDetailsMap extends React.Component<TripDetailsMapProps, TripDetailsMapState> {

    constructor(props: TripDetailsMapProps) {
        super(props);

        TrimbleMaps.APIKey = 'A413EAA4767EC44E94A2360AE03B8689';

        this.state = {
            isWeatherRadarActive: false,
            isWeatherRoadSurfaceActive: false,
            isTrafficActive: false,
            isTrafficIncidentActive: false,
            is3dBuildingsLayerDisabled: false,
            is3dBuildingsActive: false,
            isRouteLineActive: this.props.isRouteLineVisible,
            isMapScrollZoomActive: false,
            mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
            mapStyleLoaded: false,
            flyToOn: false,
            flyToLocation: null,
            mapRouteBounds: [],
            zoomMap: true
        };
    }

    componentDidMount(): void {
        // default lat/long to center of USA
        const latitude = 39.828347;
        const longitude = -98.579488;

        map = new TrimbleMaps.Map({
            container: mapContainer.current, // Ties to an HTML DOM element
            center: [longitude, latitude], // Sets initial center of map to the current location
            style: this.state.mapTileStyle, // Sets initial map style
            minZoom: 1.55, // To stop the zoom from showing multiple worlds
            zoom: 4, // Sets initial zoom level
            attributionControl: false, // Removes default attribution control, so we can customize it below
            scrollZoom: false, // disables scrollZoom interaction by default
            dragPan: false // disables dragPan interaction by default
        }).addControl(
            new TrimbleMaps.AttributionControl({
                compact: true // Minimizes the attribution to a compact version
            })
        ).addControl(
            // Adds the zoom in/out and compass buttons
            new TrimbleMaps.NavigationControl({
                showCompass: true,
                showZoom: true
            }),
            'top-right'
        ).addControl(
            new TrimbleMaps.FullscreenControl()
        );

        map.setRegion(TrimbleMaps.Common.Region.WW);

        // README: The event `style.load` is no longer a public event of mapbox. We will need to use some combination of using `styledata` and `map.isStyleLoaded()` at some point.
        // However, these do not work the same and there are several open issues with mapbox that have yet to be resolved, such as this one:
        // https://github.com/mapbox/mapbox-gl-js/issues/9779
        // Once a map style is loaded, add any layers that were on back to the map
        map.on('style.load', (): void => {
            // The style.load event can fire after the component has been unmounted.
            if (map === null) {
                return;
            }

            this.removeTripDetailsEventHandlers();
            this.addTripDetailsToMap();
            addTrafficLayers(map, TrimbleMaps.APIKey, this.state.isTrafficActive);
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);
            toggleLayerVisibility(map, MapLayers.shipmentDetailsRouteLayer, this.state.isRouteLineActive);

            // HACK: trigger a resize function to make map take up full width
            map.resize();
            this.setState({ mapStyleLoaded: true });
        });

        map.on('load', () => {
            const trafficIncident = new TrimbleMaps.TrafficIncident();
            trafficIncident.addTo(map);

            const handleTrafficIncidentClick = new TrimbleMaps.TrafficIncidentClickControl();
            map.addControl(handleTrafficIncidentClick);
        });

        map.on('trafficincident', () => {
            toggleLayerVisibility(map, MapLayers.trafficIncidents, this.state.isTrafficIncidentActive);
        });
    }

    componentDidUpdate(prevProps: TripDetailsMapProps, prevState: TripDetailsMapState): void {
        // if the map is null, hold any CDU updates
        if (map === null) {
            return;
        }

        // if the paletteMode prop has changed, set the map style in state accordingly to trigger a re-render and the next CDU will update the map style to match.
        if (prevProps.paletteMode !== this.props.paletteMode) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
                mapStyleLoaded: false,
                zoomMap: false
            });
        }

        // if the map style state var has changed and the map style hasn't been loaded, set the style on the map itself
        if (prevState.mapTileStyle !== this.state.mapTileStyle && this.state.mapStyleLoaded === false) {
            map.setStyle(this.state.mapTileStyle);
        }

        if (this.state.mapStyleLoaded === false) {
            return;
        }

        // toggle weather radar
        if (prevState.isWeatherRadarActive !== this.state.isWeatherRadarActive) {
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
        }

        // toggle weather road surface and traffic
        if (prevState.isWeatherRoadSurfaceActive !== this.state.isWeatherRoadSurfaceActive || prevState.isTrafficActive !== this.state.isTrafficActive) {
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            toggleLayerVisibility(map, MapLayers.europeTraffic, this.state.isTrafficActive);
            toggleLayerVisibility(map, MapLayers.northAmericaTraffic, this.state.isTrafficActive);
        }

        // toggle traffic incidents
        if (prevState.isTrafficIncidentActive !== this.state.isTrafficIncidentActive && map.getLayer(MapLayers.trafficIncidents) !== undefined) {
            toggleLayerVisibility(map, MapLayers.trafficIncidents, this.state.isTrafficIncidentActive);
        }

        // toggle 3D buildings
        if (prevState.is3dBuildingsActive !== this.state.is3dBuildingsActive) {
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);
        }

        // toggle route line
        if (prevState.isRouteLineActive !== this.state.isRouteLineActive) {
            toggleLayerVisibility(map, MapLayers.shipmentDetailsRouteLayer, this.state.isRouteLineActive);
        }

        // toggle scroll zoom
        if (prevState.isMapScrollZoomActive !== this.state.isMapScrollZoomActive) {
            if (this.state.isMapScrollZoomActive) {
                map.scrollZoom.enable();
            } else {
                map.scrollZoom.disable();
            }
        }

        // toggle drag pan
        if (this.props.isMobile || prevState.isMapScrollZoomActive !== this.state.isMapScrollZoomActive) {
            if (this.state.isMapScrollZoomActive) {
                map.dragPan.enable();
            } else {
                map.dragPan.disable();
            }
        } else if (!this.props.isMobile) {
            map.dragPan.enable();
        }

        // add shipments to map if props have updated
        if (prevProps.shipments !== this.props.shipments) {
            this.removeTripDetailsEventHandlers();
            this.addTripDetailsToMap();
        }

        // if resize prop is passed and is true force map to resize.
        if (this.props.resize === true) {
            map.resize();
        }
    }

    componentWillUnmount(): void {
        this.removeTripDetailsEventHandlers();
    }

    handlePointClick = (featureId: string, featureBounds: any, maxZoom?: number): void => {
        // Center and zoom in the map to the coordinates of any clicked symbol from the layer.
        if (this.state.flyToOn === true && this.state.flyToLocation === featureId) {
            fitMapToBounds({
                map,
                bounds: this.state.mapRouteBounds,
                zoomMap: this.state.zoomMap
            });

            this.setState({
                flyToOn: false,
                flyToLocation: null
            });
        } else {
            fitMapToBounds({
                map,
                bounds: featureBounds,
                zoomMap: this.state.zoomMap,
                maxZoom
            });

            this.setState({
                flyToOn: true,
                flyToLocation: featureId
            });
        }
    };

    handleShipmentDetailsPastPositionClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.shipmentDetailsPastPositionsLayer) {
            const { geometry, properties }: { geometry: Point; properties: ShipmentDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);
            this.handlePointClick(properties.id, bounds, 13);
        }
    };

    handleShipmentDetailsCurrentLocationClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.shipmentDetailsCurrentPositionLayer || currentFeature.layer.id === MapLayers.shipmentDetailsCurrentPositionPulseLayer) {
            const { geometry, properties }: { geometry: Point; properties: ShipmentDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);
            this.handlePointClick(properties.id, bounds, 13);
        }
    };

    handleShipmentDetailsStopClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.shipmentDetailsStopsLayer) {
            let maxZoom: number | undefined;
            const { geometry, properties }: { geometry: Point; properties: ShipmentDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);

            const { geofenceFeaturePoints } = properties;
            if (geofenceFeaturePoints) {
                // because the geojson properties get converted to strings by mapbox, we first must re-type this var as unknown
                const unknownGeofence = geofenceFeaturePoints as unknown;
                // then we can properly re-type it as a string, which can then be JSON parsed back to the original type of a Polygon Feature
                const geofenceFeature: Feature<Polygon, ShipmentDetailsMapSourceProperties> = JSON.parse(unknownGeofence as string);

                geofenceFeature.geometry.coordinates.forEach((segment: number[][]): void => {
                    segment.forEach((coord: number[]): void => {
                        bounds.extend(coord);
                    });
                });
            } else {
                // if there is no geofence on the stop, we want to stop the map from trying to zoom way too far into the point, so we set a max zoom here only.
                maxZoom = 13;
            }
            this.handlePointClick(properties.id, bounds, maxZoom);
        }
    };

    handleLineStringHover = (e: any, routeLinePopup: any): void => {
        // Get the layer that was hovered
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];

        // If it was this layer, fire the hover function
        if (currentFeature.layer.id === MapLayers.shipmentDetailsRouteLayer) {
            const { properties }: { geometry: Point; properties: ShipmentDetailsMapSourceProperties; } = currentFeature;

            // find the current shipment that is being hovered
            const currentShipment = this.props.shipments.find((shipment) => {
                return shipment.freightProviderReferenceNumber === properties.shipmentId;
            });

            // if the shipment is found, adjust the map source to only show the single shipment on the map and show the popup
            if (currentShipment) {
                addTripDetailsSource(map, [currentShipment]);

                handleLineStringPopupMouseEnter(map, e, routeLinePopup);
            }
        }
    };

    handleLineStringHoverLeave = (routeLinePopup: any): void => {
        addTripDetailsSource(map, this.props.shipments);

        handlePopupMouseLeave(map, routeLinePopup);
    };

    addTripDetailsToMap = (): void => {
        // add images to map for stops
        map.loadImage(this.props.originAndDestinationImage, (error: unknown, originDestinationImage: any): void => {
            if (map.hasImage(MapImages.originDestinationMapMarker) === false) {
                map.addImage(MapImages.originDestinationMapMarker, originDestinationImage);
            }
        });

        map.loadImage(this.props.intermediateStopImage, (error: unknown, stopImage: any): void => {
            if (map.hasImage(MapImages.stopMapMarker) === false) {
                map.addImage(MapImages.stopMapMarker, stopImage);
            }
        });

        // add map source
        const tripDetailsSource = addTripDetailsSource(map, this.props.shipments);

        // add geofence layers
        addShipmentDetailsGeofenceLayers(map, MapSources.tripDetailsSource);

        // add route
        addShipmentDetailsRouteLayer(map, MapSources.tripDetailsSource, true);
        map.on('mouseenter', MapLayers.shipmentDetailsRouteLayer, (e: any): void => { this.handleLineStringHover(e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.shipmentDetailsRouteLayer, (): void => { this.handleLineStringHoverLeave(trimbleMapsPopup); });

        // add past positions and mouse events
        addShipmentDetailsPastPositionsLayer(map, MapSources.tripDetailsSource);
        map.on('click', MapLayers.shipmentDetailsPastPositionsLayer, this.handleShipmentDetailsPastPositionClick);
        map.on('mouseenter', MapLayers.shipmentDetailsPastPositionsLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.shipmentDetailsPastPositionsLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        const size = 120;
        const pulseColor = getDeliveryStatusColor(this.props.activeShipment.deliveryStatus);

        // This implements `StyleImageInterface` to draw a pulsing dot icon on the map.
        const pulsingDot: {
            width: number;
            height: number;
            context: CanvasRenderingContext2D | null;
            data: Uint8ClampedArray | Uint8Array;
            onAdd: () => void;
            onRemove: () => void;
            render: () => boolean;
        } = {
            width: size,
            height: size,
            context: null,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map, get the rendering context for the map canvas.
            onAdd(): void {
                const canvas = document.createElement('canvas');
                canvas.width = pulsingDot.width;
                canvas.height = pulsingDot.height;
                pulsingDot.context = canvas.getContext('2d');
            },

            onRemove(): void {
                pulsingDot.context = null;
            },

            // Call once before every frame where the icon will be used.
            render(): boolean {
                const duration = 2000;
                const time = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * time + radius;

                if (pulsingDot.context !== null) {
                    // Draw the outer circle.
                    pulsingDot.context.clearRect(0, 0, pulsingDot.width, pulsingDot.height);
                    pulsingDot.context.beginPath();
                    pulsingDot.context.arc(
                        pulsingDot.width / 2,
                        pulsingDot.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    pulsingDot.context.globalAlpha = 1 - time;
                    pulsingDot.context.fillStyle = pulseColor;
                    pulsingDot.context.fill();

                    // Draw the inner circle.
                    pulsingDot.context.beginPath();
                    pulsingDot.context.arc(
                        pulsingDot.width / 2,
                        pulsingDot.height / 2,
                        radius,
                        0,
                        Math.PI * 2
                    );
                    pulsingDot.context.fillStyle = pulseColor;
                    pulsingDot.context.globalAlpha = 1;
                    pulsingDot.context.strokeStyle = '#fff';
                    pulsingDot.context.lineWidth = 4;
                    pulsingDot.context.fill();
                    pulsingDot.context.stroke();

                    // Update this image's data with data from the canvas.
                    pulsingDot.data = pulsingDot.context.getImageData(
                        0,
                        0,
                        pulsingDot.width,
                        pulsingDot.height
                    ).data;
                }

                // Continuously repaint the map, resulting in the smooth animation of the dot.
                map.triggerRepaint();

                // Return `true` to let the map know that the image was updated.
                return true;
            }
        };

        // add pulsing current location image
        if (map.hasImage(MapImages.currentLocationMapMarker) === false) {
            map.addImage(MapImages.currentLocationMapMarker, pulsingDot, { pixelRatio: 2 });
        }

        // add current position pulse and mouse events
        addShipmentDetailsCurrentPositionPulseLayer(map, MapSources.tripDetailsSource);
        map.on('click', MapLayers.shipmentDetailsCurrentPositionPulseLayer, this.handleShipmentDetailsCurrentLocationClick);
        map.on('mouseenter', MapLayers.shipmentDetailsCurrentPositionPulseLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.shipmentDetailsCurrentPositionPulseLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        // add current position and mouse events
        addShipmentDetailsCurrentPositionLayer(map, MapSources.tripDetailsSource);
        map.on('click', MapLayers.shipmentDetailsCurrentPositionLayer, this.handleShipmentDetailsCurrentLocationClick);
        map.on('mouseenter', MapLayers.shipmentDetailsCurrentPositionLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.shipmentDetailsCurrentPositionLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        // add stops and mouse events
        addShipmentDetailsStopsLayer(map, MapSources.tripDetailsSource);
        map.on('click', MapLayers.shipmentDetailsStopsLayer, this.handleShipmentDetailsStopClick);
        map.on('mouseenter', MapLayers.shipmentDetailsStopsLayer, (): void => { handleMouseEnter(map); });
        map.on('mouseleave', MapLayers.shipmentDetailsStopsLayer, (): void => { handleMouseLeave(map); });

        if (tripDetailsSource.data.features.length > 0) {
            const boundingBox = bbox(tripDetailsSource.data);
            this.setState({
                mapRouteBounds: boundingBox
            });
            fitMapToBounds({
                map,
                bounds: boundingBox,
                zoomMap: this.state.zoomMap
            });
        }
    };

    removeTripDetailsEventHandlers = (): void => {
        if (map.getLayer(MapLayers.shipmentDetailsRouteLayer) !== undefined) {
            map.off('mouseenter', MapLayers.shipmentDetailsRouteLayer, this.handleLineStringHover);
            map.off('mouseleave', MapLayers.shipmentDetailsRouteLayer, this.handleLineStringHoverLeave);
        }

        if (map.getLayer(MapLayers.shipmentDetailsPastPositionsLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsPastPositionsLayer, this.handleShipmentDetailsPastPositionClick);
            map.off('mouseenter', MapLayers.shipmentDetailsPastPositionsLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsPastPositionsLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.shipmentDetailsCurrentPositionPulseLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsCurrentPositionPulseLayer, this.handleShipmentDetailsCurrentLocationClick);
            map.off('mouseenter', MapLayers.shipmentDetailsCurrentPositionPulseLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsCurrentPositionPulseLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.shipmentDetailsCurrentPositionLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsCurrentPositionLayer, this.handleShipmentDetailsCurrentLocationClick);
            map.off('mouseenter', MapLayers.shipmentDetailsCurrentPositionLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsCurrentPositionLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.shipmentDetailsStopsLayer) !== undefined) {
            map.off('click', MapLayers.shipmentDetailsStopsLayer, this.handleShipmentDetailsStopClick);
            map.off('mouseenter', MapLayers.shipmentDetailsStopsLayer, handleMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsStopsLayer, handleMouseLeave);
        }

        if (map.hasImage(MapImages.currentLocationMapMarker) === true) {
            map.removeImage(MapImages.currentLocationMapMarker);
        }
    };

    render(): JSX.Element {
        return (
            <StyledDiv
                id='trimble-map-container'
                ref={mapContainer}
                className={classes.map}
                data-qa='tripDetailsMap-container'
            >
                {
                    this.props.isFetchingData &&
                    <MapSpinner />
                }

                <MapControlBar
                    isWeatherRadarActive={this.state.isWeatherRadarActive}
                    handleWeatherRadarClick={(): void => {
                        this.setState((state) => {
                            return {
                                isWeatherRadarActive: !state.isWeatherRadarActive
                            };
                        });
                    }}
                    isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                    handleWeatherRoadSurfaceClick={(): void => {
                        this.setState((state) => {
                            return {
                                isWeatherRoadSurfaceActive: !state.isWeatherRoadSurfaceActive,
                                isTrafficActive: !state.isWeatherRoadSurfaceActive === true ? false : state.isTrafficActive
                            };
                        });
                    }}
                    isTrafficActive={this.state.isTrafficActive}
                    handleTrafficClick={(): void => {
                        this.setState((state) => {
                            return {
                                isTrafficActive: !state.isTrafficActive,
                                isWeatherRoadSurfaceActive: !state.isTrafficActive === true ? false : state.isWeatherRoadSurfaceActive
                            };
                        });
                    }}
                    isTrafficIncidentActive={this.state.isTrafficIncidentActive}
                    handleTrafficIncidentClick={(): void => {
                        this.setState((state) => {
                            return {
                                isTrafficIncidentActive: !state.isTrafficIncidentActive
                            };
                        });
                    }}
                    is3dBuildingsLayerDisabled={this.state.is3dBuildingsLayerDisabled}
                    is3dBuildingsActive={this.state.is3dBuildingsActive}
                    handle3dBuildingClick={(): void => {
                        this.setState((state) => {
                            return {
                                is3dBuildingsActive: !state.is3dBuildingsActive
                            };
                        });
                    }}
                    isRouteLineActive={this.state.isRouteLineActive}
                    handleRouteLineClick={(): void => {
                        this.setState((state) => {
                            return {
                                isRouteLineActive: !state.isRouteLineActive
                            };
                        });
                    }}
                    mapTileStyle={this.state.mapTileStyle}
                    handleMapStyleMenuItemClick={(mapStyle: string): void => {
                        let is3dBuildingsLayerDisabled = false;
                        let { is3dBuildingsActive } = this.state;
                        if (mapStyle === TrimbleMaps.Common.Style.SATELLITE) {
                            is3dBuildingsLayerDisabled = true; // disable 3d buildings button when in satellite mode
                            is3dBuildingsActive = false; // turn 3d building layer off when in satellite mode
                        }

                        this.setState({
                            is3dBuildingsLayerDisabled,
                            is3dBuildingsActive,
                            mapTileStyle: mapStyle,
                            mapStyleLoaded: false,
                            zoomMap: false
                        });
                    }}
                    isMapScrollZoomActive={!this.state.isMapScrollZoomActive}
                    handleMapScrollZoomClick={(): void => {
                        this.setState((state) => {
                            return {
                                isMapScrollZoomActive: !state.isMapScrollZoomActive
                            };
                        });
                    }}
                />

                <MapLegendWrapper
                    isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                    isMapLegendEnabled={false}
                    mapLegendType={null}
                />
            </StyledDiv>
        );
    }

}

export default TripDetailsMap;
