import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'haulLengthProgressBarLoading';

const classes = {
    haulLength: `${classesPrefix}-haulLength`,
    milesRemaining: `${classesPrefix}-milesRemaining`,
    progressBar: `${classesPrefix}-progressBar`,
    tripInfo: `${classesPrefix}-tripInfo`,
    tripInfoText: `${classesPrefix}-tripInfoText`,
    tripStatusText: `${classesPrefix}-tripStatusText`
};

const StyledSection = styled('section')(() => {
    return {
        [`&.${classes.haulLength}`]: {
            width: '100%',
            marginTop: '16px'
        },
        [`& .${classes.milesRemaining}`]: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        [`& .${classes.progressBar}`]: {
            position: 'relative',
            width: '100%',
            borderRadius: '4px'
        },
        [`& .${classes.tripInfo}`]: {
            marginTop: '4px'
        },
        [`& .${classes.tripInfoText}`]: {
            fontSize: '12px'
        },
        [`& .${classes.tripStatusText}`]: {
            marginBottom: '4px'
        }
    };
});

const HaulLengthProgressBarLoading = (): JSX.Element => {
    return (
        <StyledSection className={classes.haulLength}>
            <Typography className={classes.tripStatusText}>
                <Skeleton variant='text' width='100px' />
            </Typography>
            <Skeleton className={classes.progressBar} variant='rectangular' height='30px' />
            <Grid container className={classes.tripInfo}>
                <Grid item xs={6}>
                    <Typography className={classes.tripInfoText}><Skeleton variant='text' width='70px' /></Typography>
                </Grid>
                <Grid item xs={6} className={classes.milesRemaining}>
                    <Typography className={classes.tripInfoText}><Skeleton variant='text' width='70px' /></Typography>
                </Grid>
            </Grid>
        </StyledSection>
    );
};

export default HaulLengthProgressBarLoading;
