import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Tooltip
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames, ModeType } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';
import ModeTypeTabs from '../../tabs/modeTypeTabs';
import DeliveryStatusRules from './deliveryStatusRules';

const classesPrefix = 'deliveryStatusRulesCard';

const classes = {
    informationIcon: `${classesPrefix}-informationIcon`
};

const StyledFormGroup = styled(FormGroup)(() => {
    return {
        [`& .${classes.informationIcon}`]: {
            verticalAlign: 'middle',
            marginLeft: '4px'
        }
    };
});

const DeliveryStatusRulesCard = ({
    handleIsCardValidChange
}: {
    handleIsCardValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const [currentTab, setCurrentTab] = useState<ModeType>(ModeType.Truckload);
    const [isTruckloadRuleValid, setIsTruckloadRuleValid] = useState(true);
    const [isLTLRuleValid, setIsLTLRuleValid] = useState(true);
    const [isParcelRuleValid, setIsParcelRuleValid] = useState(true);
    const [isRailRuleValid, setIsRailRuleValid] = useState(true);
    const [isOceanRuleValid, setIsOceanRuleValid] = useState(true);
    const [isUnknownRuleValid, setIsUnknownRuleValid] = useState(true);

    useEffect((): void => {
        const isCardValid =
            isTruckloadRuleValid &&
            isLTLRuleValid &&
            isParcelRuleValid &&
            isRailRuleValid &&
            isOceanRuleValid &&
            isUnknownRuleValid;
        handleIsCardValidChange(isCardValid);
    }, [
        handleIsCardValidChange,
        isTruckloadRuleValid,
        isLTLRuleValid,
        isParcelRuleValid,
        isRailRuleValid,
        isOceanRuleValid,
        isUnknownRuleValid
    ]);

    const handleTabChange = (newValue: ModeType): void => {
        setCurrentTab(newValue);
    };

    const handleEarlyIsAGoodThingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.earlyIsAGoodThing, event.target.checked));
    };

    const handleIsRuleValidChange = (modeType: ModeType, isValid: boolean): void => {
        switch (modeType) {
            case ModeType.Truckload: {
                setIsTruckloadRuleValid(isValid);
                break;
            }
            case ModeType.LTL: {
                setIsLTLRuleValid(isValid);
                break;
            }
            case ModeType.Parcel: {
                setIsParcelRuleValid(isValid);
                break;
            }
            case ModeType.Rail: {
                setIsRailRuleValid(isValid);
                break;
            }
            case ModeType.Ocean: {
                setIsOceanRuleValid(isValid);
                break;
            }
            case ModeType.Unknown: {
                setIsUnknownRuleValid(isValid);
                break;
            }
            default:
                // default case will never get hit
                console.error(`${modeType} currently not supported in deliveryStatusRulesCard validation.`);
                break;
        }
    };

    const renderDeliveryStatusRule = (): JSX.Element => {
        const rulesForModeType = organizationEditablePreferences.freightPortalPreferencesOnTimeRules
            .find((item): boolean => {
                return item.modeType === currentTab;
            });

        if (rulesForModeType) {
            return (
                <DeliveryStatusRules
                    selectedModeType={currentTab}
                    currentOnTimeRules={rulesForModeType}
                    handleIsRuleValidChange={(isValid: boolean): void => {
                        handleIsRuleValidChange(currentTab, isValid);
                    }}
                />
            );
        }

        return (
            <Fragment />
        );
    };

    return (
        <PreferenceCard header='Delivery Status Rules'>
            <StyledFormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organizationEditablePreferences.earlyIsAGoodThing}
                            onChange={handleEarlyIsAGoodThingChange}
                            color='primary'
                        />
                    }
                    label={
                        <Fragment>
                            My Organization considers Early shipments to be On Time
                            <Tooltip
                                title='By checking this option, shipments that deliver early will be grouped with your on time shipments on your dashboard.'
                                aria-label='info'
                            >
                                <Icon
                                    className={classes.informationIcon}
                                    path={mdiInformation}
                                    size={1}
                                    color={theme.palette.text.secondary}
                                />
                            </Tooltip>
                        </Fragment>
                    }
                    data-qa='earlyIsAGoodThing-switch'
                />
            </StyledFormGroup>

            <ModeTypeTabs currentTab={currentTab} handleTabChange={handleTabChange} />

            {renderDeliveryStatusRule()}
        </PreferenceCard>
    );
};

export default DeliveryStatusRulesCard;
