import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import ApiService from '../../services/apiService';
import { FilterDataResponse } from '../../interfaces/services/masterData';
import { FilterItem } from '../../interfaces/filterInterfaces';
import { GenericApiResponse } from '../../interfaces/services';
import { OrderException } from '../../interfaces/services/organization';
import { addSpaceBeforeUppercaseCharacter, capitalizeFirstLetter } from '../../helpers/dataUtils';
import MultiSelect from './multiSelect';

const AsyncMultiSelect = ({
    id,
    values,
    handleChange,
    url,
    disabled = false
}: {
    id: string;
    values: string[];
    handleChange: (id: string, selectedOptions: string[]) => void;
    url: string;
    disabled?: boolean;
}): JSX.Element => {
    const [filterOptions, setFilterOptions] = useState<FilterItem[]>([]);
    const [isFetchingMasterData, setIsFetchingMasterData] = useState(false);

    const filterName = capitalizeFirstLetter(addSpaceBeforeUppercaseCharacter(id));

    const createFilterItem = (filterValue: string): FilterItem => {
        return {
            label: filterValue,
            filterValue
        };
    };

    useEffect((): void => {
        // Filter type calls the MasterData to populate the filter options
        setIsFetchingMasterData(true);
        ApiService.get({ url })
            .then((response): void => {
                const { filterData, status } = response as FilterDataResponse;
                if (status === 202) {
                    toast.warn('Filter data is being compiled, type ahead filters will not work at the moment - please try again shortly.');
                } else if (filterData) {
                    // if filterData isnt undefined we are using masterdata
                    setFilterOptions(filterData.map((filter) => {
                        return createFilterItem(filter.filterValue);
                    }));
                } else if (id.toLowerCase() === 'orderexceptions') {
                    const { data } = response as GenericApiResponse<OrderException>;
                    const orderExceptionFilterOptions = data.reduce((accumulator: FilterItem[], { exceptionDescription }): FilterItem[] => {
                        if (exceptionDescription) {
                            accumulator.push(createFilterItem(exceptionDescription));
                        }
                        return accumulator;
                    }, []);
                    setFilterOptions(orderExceptionFilterOptions);
                } else {
                    console.error(`Unable to handle asyncMultiSelect response from ${url}`);
                    throw new Error();
                }
            }).catch(() => {
                toast.error(`An error occurred retrieving ${filterName} options.`);
            }).finally(() => {
                setIsFetchingMasterData(false);
            });
    }, [id, filterName, url]);

    const buildFilterOptions = (): FilterItem[] => {
        // if state is empty return the list from masterdata
        if (values.length === 0) {
            return filterOptions;
        }

        const previouslySavedFilters = [];
        // loop over state values
        for (let i = 0; i < values.length; i++) {
            let hasValue = false;
            // loop over masterdata values
            for (let j = 0; j < filterOptions.length; j++) {
                // if one of the values coming back from masterdata exists break out of the loop because the value already exists in our options
                if (filterOptions[j]?.filterValue === values[i]) {
                    hasValue = true;
                    break;
                }
            }
            // if we have a value that isnt in the masterdata list create the option
            if (!hasValue) {
                previouslySavedFilters.push(createFilterItem(values[i]));
            }
        }
        return [...filterOptions, ...previouslySavedFilters];
    };

    return (
        <MultiSelect
            id={id}
            label={filterName}
            values={values}
            availableOptions={buildFilterOptions()}
            isLoading={isFetchingMasterData}
            handleChange={handleChange}
            disabled={disabled}
        />
    );
};

export default AsyncMultiSelect;
