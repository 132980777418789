import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Typography,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    InputLabel,
    FormHelperText,
    FormControl,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { validateHoursAndMinutes } from '../../../helpers/dataUtils';
import { etaIncreasesOrDecreasesOptions } from '../../../helpers/hardcodedOptionLists';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'etaBaselinePriorityCard';

const classes = {
    selectWrapper: `${classesPrefix}-selectWrapper`,
    selectField: `${classesPrefix}-selectField`,
    textField: `${classesPrefix}-textField`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.selectWrapper}`]: {
            marginTop: '16px'
        },
        [`& .${classes.selectField}`]: {
            maxWidth: '280px'
        },
        [`& .${classes.textField}`]: {
            marginTop: '16px',
            width: '60px'
        }
    };
});

const EtaBaselinePriorityCard = ({
    handleIsCardValidChange
}: {
    handleIsCardValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });
    const { etaHotloadBaselineEnabled, etaHotLoadBaselineIncreaseOrDecrease, etaHotloadBaselineMinutes } = organizationEditablePreferences;

    const [isEtaHotloadBaselineMinutesValid, setIsEtaHotloadBaselineMinutesValid] = useState(true);

    useEffect((): void => {
        const isValid = validateHoursAndMinutes(etaHotloadBaselineMinutes, 1440);
        setIsEtaHotloadBaselineMinutesValid(isValid);
    }, [etaHotloadBaselineMinutes]);

    useEffect((): void => {
        const isCardValid = etaHotloadBaselineEnabled ? isEtaHotloadBaselineMinutesValid : true;
        handleIsCardValidChange(isCardValid);
    }, [handleIsCardValidChange, etaHotloadBaselineEnabled, isEtaHotloadBaselineMinutesValid]);

    const handleHotLoadBaselineEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.etaHotloadBaselineEnabled, event.target.checked));
    };

    const handleHotLoadBaselineIncreaseOrDecreaseChange = (event: SelectChangeEvent<unknown>): void => {
        dispatch(editPreferences(PreferencesFieldNames.etaHotLoadBaselineIncreaseOrDecrease, Number(event.target.value)));
    };

    const handleHotLoadBaselineMinutesChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.etaHotloadBaselineMinutes, Number(event.currentTarget.value)));
    };

    return (
        <PreferenceCard
            header='ETA Baseline - Priority (Hot) Shipments'
            informationText='ETA baselines are used to determine the ETA that will be used when triggering an ETA based notification.'
        >
            <Typography component='p' variant='body2'>
                Set a new ETA baseline after ETA changes on Priority Shipments?
            </Typography>

            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={etaHotloadBaselineEnabled}
                            onChange={handleHotLoadBaselineEnabledChange}
                            color='primary'
                        />
                    }
                    label='Enable ETA Baseline'
                    data-qa='etaHotloadBaselineEnabled-switch'
                />
            </FormGroup>

            {
                etaHotloadBaselineEnabled === true &&
                <StyledDiv className={classes.selectWrapper}>
                    <FormControl fullWidth variant='standard'>
                        <InputLabel id='hotLoadBaselineLabel'>Set new ETA baseline when</InputLabel>
                        <Select
                            labelId='hotLoadBaselineLabel'
                            className={classes.selectField}
                            variant='standard'
                            value={etaHotLoadBaselineIncreaseOrDecrease}
                            onChange={handleHotLoadBaselineIncreaseOrDecreaseChange}
                            inputProps={{
                                'data-qa': 'etaHotLoadBaseline-input'
                            }}
                        >
                            {
                                etaIncreasesOrDecreasesOptions.map((option) => {
                                    return (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            data-qa={`etaHotLoadBaseline-item-${option.label}`}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>

                    <TextField
                        className={classes.textField}
                        variant='standard'
                        label='Minutes'
                        value={etaHotloadBaselineMinutes}
                        onChange={handleHotLoadBaselineMinutesChange}
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 1440 } }}
                        error={!isEtaHotloadBaselineMinutesValid}
                        data-qa='etaHotloadBaselineMinutes-minutes-input'
                    />

                    {
                        !isEtaHotloadBaselineMinutesValid &&
                        <FormHelperText error>Total time cannot exceed 24 hours and 0 minutes.</FormHelperText>
                    }
                </StyledDiv>
            }
        </PreferenceCard>
    );
};

export default EtaBaselinePriorityCard;
