import {
    GeoJsonTypes,
    Point,
    Feature
} from 'geojson';

import { ShipmentListData } from '../../../interfaces/services/shipment';
import { DeliveryStatus, FreightHaulerIdentifierTypeName, ModeType } from '../../enums';
import { formatCityRegionPostalCountry } from '../../addressUtils';
import { addSpaceBeforeUppercaseCharacter } from '../../dataUtils';
import { zonedDateTimeToDisplay } from '../../dateUtils';
import { isShipmentLtl } from '../../shipmentUtils';
import { getDeliveryStatusColor } from '../../styleHelpers';
import { MapSources } from '../enums';

/** Interface used for the properties object on the Shipment List Map Source. */
export interface ShipmentListMapSourceProperties {
    description: string;
    shipmentUniqueName: string;
    modeType: ModeType;
    deliveryStatus: DeliveryStatus;
    color: string;
}

/** Interface used to define the Shipment List Map Source. */
export interface ShipmentListMapSource {
    type: 'geojson';
    data: {
        type: GeoJsonTypes;
        features: Feature<Point, ShipmentListMapSourceProperties>[];
    };
}

/** Interface used to define the Shipment List Cluster Map Source. */
export interface ShipmentListClusterMapSource extends ShipmentListMapSource {
    cluster: boolean;
    clusterMaxZoom: number;
    clusterRadius: number;
    clusterProperties: {
        [K in DeliveryStatus]: [string, (string | number | (string | string[])[])[]];
    };
}

/**
 * Using a list of shipments, it will loop through them and create a geojson feature for each one
 * that contains the HTML for the popup, current location, and the color based on the delivery status.
 * @param shipmentList List of shipments
 * @returns The geojson features for each point on the map
 */
const getShipmentCurrentLocationPoints = (shipmentList: ShipmentListData[]): Feature<Point, ShipmentListMapSourceProperties>[] => {
    const shipmentCurrentLocationPoints = shipmentList.map((shipment): Feature<Point, ShipmentListMapSourceProperties> => {
        let currentLocationLongLat = [0, 0];
        if (shipment.currentPositionLongitude && shipment.currentPositionLatitude) {
            currentLocationLongLat = [shipment.currentPositionLongitude, shipment.currentPositionLatitude];
        } else if (shipment.originLongitude && shipment.originLatitude) {
            currentLocationLongLat = [shipment.originLongitude, shipment.originLatitude];
        }

        let tractorRow = '';
        if (shipment.tractorReferenceNumber) {
            tractorRow = `
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Tractor Reference #:</div>
                    <div class='mapTooltipCell'>${shipment.tractorReferenceNumber}</div>
                </div>
            `;
        }

        let trailerRow = '';
        if (shipment.trailerReferenceNumber) {
            trailerRow = `
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Trailer Reference #:</div>
                    <div class='mapTooltipCell'>${shipment.trailerReferenceNumber}</div>
                </div>
            `;
        }

        let scacRow = '';
        if (shipment.freightHaulerIdentifierTypeName === FreightHaulerIdentifierTypeName.SCACCode && shipment.freightHaulerIdentifierName !== null) {
            scacRow = `
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>SCAC #:</div>
                    <div class='mapTooltipCell'>${shipment.freightHaulerIdentifierName}</div>
                </div>
            `;
        }

        const tooltipHtml = `
            <div class='mapTooltip'>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'><strong>Shipment Id:</strong></div>
                    <div class='mapTooltipCell'><strong>${shipment.freightProviderReferenceNumber}</strong></div>
                </div>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Status:</div>
                    <div class='mapTooltipCell'>
                        ${addSpaceBeforeUppercaseCharacter((isShipmentLtl(shipment.modeType) && shipment.currentMilestoneType) ? shipment.currentMilestoneType : shipment.shipmentStatus)}
                    </div>
                </div>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Current Location:</div>
                    <div class='mapTooltipCell'>
                        ${formatCityRegionPostalCountry({ city: shipment.currentPositionCity, region: shipment.currentPositionState })}
                    </div>
                </div>
                <div class='mapTooltipRow'>
                    <div class='mapTooltipCell'>Date/Time:</div>
                    <div class='mapTooltipCell'>${zonedDateTimeToDisplay(shipment.currentPositionDateTime)}</div>
                </div>
                ${tractorRow}
                ${trailerRow}
                ${scacRow}
            </div>
        `;

        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: currentLocationLongLat
            },
            properties: {
                description: tooltipHtml,
                shipmentUniqueName: shipment.shipmentUniqueName,
                modeType: shipment.modeType,
                deliveryStatus: shipment.deliveryStatus,
                color: getDeliveryStatusColor(shipment.deliveryStatus)
            }
        };
    });

    return shipmentCurrentLocationPoints;
};

/**
 * Creates the geojson source using the feature points for the shipment list map.
 * @param shipmentList List of shipments
 * @returns A geojson source to be added to the map
 */
const getMapShipmentListSource = (shipmentList: ShipmentListData[]): ShipmentListMapSource => {
    const shipmentCurrentLocationPoints = getShipmentCurrentLocationPoints(shipmentList);

    const shipmentListSource: ShipmentListMapSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: shipmentCurrentLocationPoints
        }
    };

    return shipmentListSource;
};

/**
* Adds the shipment list source to the map if it doesn't already exist
* @param map Instance of your map
* @param shipmentList List of shipments to use in the map source
*/
export const addShipmentListSource = (map: any, shipmentList: ShipmentListData[]): ShipmentListMapSource => {
    const shipmentListSource = getMapShipmentListSource(shipmentList);

    if (map.getSource(MapSources.shipmentListSource) === undefined) {
        map.addSource(MapSources.shipmentListSource, shipmentListSource);
    } else {
        map.getSource(MapSources.shipmentListSource).setData(shipmentListSource.data);
    }

    return shipmentListSource;
};

/**
 * Creates the clustered geojson source using the feature points for the shipment list map.
 * @param shipmentList List of shipments
 * @returns A geojson source to be added to the map
 */
const getMapShipmentListClusterSource = (shipmentList: ShipmentListData[]): ShipmentListClusterMapSource => {
    const shipmentCurrentLocationPoints = getShipmentCurrentLocationPoints(shipmentList);

    // filters for classifying shipments into six categories based on delivery status
    const tenderAccepted = ['==', ['get', 'deliveryStatus'], DeliveryStatus.TenderAccepted];
    const early = ['==', ['get', 'deliveryStatus'], DeliveryStatus.Early];
    const onTime = ['==', ['get', 'deliveryStatus'], DeliveryStatus.OnTime];
    const inJeopardy = ['==', ['get', 'deliveryStatus'], DeliveryStatus.InJeopardy];
    const late = ['==', ['get', 'deliveryStatus'], DeliveryStatus.Late];
    const trackingLost = ['==', ['get', 'deliveryStatus'], DeliveryStatus.TrackingLost];

    const shipmentListClusterSource: ShipmentListClusterMapSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: shipmentCurrentLocationPoints
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 80, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
            // keep separate counts for each delivery status in a cluster
            [DeliveryStatus.TenderAccepted]: ['+', ['case', tenderAccepted, 1, 0]],
            [DeliveryStatus.Early]: ['+', ['case', early, 1, 0]],
            [DeliveryStatus.OnTime]: ['+', ['case', onTime, 1, 0]],
            [DeliveryStatus.InJeopardy]: ['+', ['case', inJeopardy, 1, 0]],
            [DeliveryStatus.Late]: ['+', ['case', late, 1, 0]],
            [DeliveryStatus.TrackingLost]: ['+', ['case', trackingLost, 1, 0]]
        }
    };

    return shipmentListClusterSource;
};

/**
* Adds the shipment list cluster source to the map if it doesn't already exist
* @param map Instance of your map
* @param shipmentList List of shipments to use in the map source
*/
export const addShipmentListClusterSource = (map: any, shipmentList: ShipmentListData[]): ShipmentListClusterMapSource => {
    const shipmentListClusterSource = getMapShipmentListClusterSource(shipmentList);

    if (map.getSource(MapSources.shipmentListClusterSource) === undefined) {
        map.addSource(MapSources.shipmentListClusterSource, shipmentListClusterSource);
    } else {
        map.getSource(MapSources.shipmentListClusterSource).setData(shipmentListClusterSource.data);
    }

    return shipmentListClusterSource;
};
