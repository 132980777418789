import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DropResult } from 'react-beautiful-dnd';

import { useTypedSelector, GenericAction } from '../../redux';
import { updateUserSettings } from '../../redux/user';
import { ApiStatus } from '../../helpers/enums';
import { UserSettingAttribute } from '../../interfaces/services/organization';
import ErrorPage from '../errors/errorPage';
import DraggableSettingsList from '../lists/draggableSettingsList';

const classesPrefix = 'userSettings';

const classes = {
    listContainer: `${classesPrefix}-listContainer`,
    description: `${classesPrefix}-description`,
    actionsContainer: `${classesPrefix}-actionsContainer`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.listContainer}`]: {
            flexGrow: 1,
            overflowY: 'auto',
            padding: '8px 8px 8px'
        },
        [`& .${classes.description}`]: {
            fontSize: '12px'
        }
    };
});

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.actionsContainer}`]: {
            padding: '8px 16px',
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            textAlign: 'center'
        }
    };
});

const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';
const UPDATE_ORDER = 'UPDATE_ORDER';
const INITIALIZE_DATA = 'INITIALIZE_DATA';

type DataType = 'userCounters' | 'userColumns'

interface UserSettingsState {
    userCounters: UserSettingAttribute[];
    userColumns: UserSettingAttribute[];
}

enum UserSettingsTab {
    Counters,
    Columns
}

const UserSettings = (): JSX.Element => {
    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState(UserSettingsTab.Counters);

    const userSettingsUpdateStatus = useTypedSelector((state) => { return state.user.userSettingsUpdateStatus; });
    const userSettingsStatus = useTypedSelector((state) => { return state.user.userSettingsStatus; });
    const userSettingsCounters = useTypedSelector((state) => { return state.user.userSettings.userSettingsCounters; });
    const userSettingsColumns = useTypedSelector((state) => { return state.user.userSettings.userSettingsColumns; });
    const mapLegendDisplay = useTypedSelector((state) => { return state.user.userSettings.mapLegendDisplay; });

    const initialState: UserSettingsState = {
        userCounters: [],
        userColumns: []
    };

    const reducer = (
        state: UserSettingsState,
        action:
            GenericAction<typeof UPDATE_VISIBILITY, { dataType: DataType; itemKey: string; }> |
            GenericAction<typeof UPDATE_ORDER, { dataType: DataType; list: UserSettingAttribute[]; }> |
            GenericAction<typeof INITIALIZE_DATA, { dataType: DataType; }>
    ): UserSettingsState => {
        switch (action.type) {
            case UPDATE_VISIBILITY: {
                const { dataType, itemKey } = action.payload;
                return {
                    ...state,
                    [dataType]: state[dataType].map((item): UserSettingAttribute => {
                        if (item.key === itemKey) {
                            return {
                                ...item,
                                visible: !item.visible
                            };
                        }

                        return item;
                    })
                };
            }
            case UPDATE_ORDER: {
                const { dataType, list } = action.payload;
                return {
                    ...state,
                    [dataType]: list.map((item, index): UserSettingAttribute => {
                        return {
                            ...item,
                            order: index + 1
                        };
                    })
                };
            }
            case INITIALIZE_DATA: {
                const { dataType } = action.payload;
                return {
                    ...state,
                    ...dataType === 'userCounters' && { userCounters: userSettingsCounters },
                    ...dataType === 'userColumns' && { userColumns: userSettingsColumns }
                };
            }
            default: {
                return state;
            }
        }
    };

    const [userSettingsState, localDispatch] = useReducer(reducer, initialState);

    useEffect((): void => {
        localDispatch({
            type: INITIALIZE_DATA,
            payload: {
                dataType: 'userCounters'
            }
        });
    }, [userSettingsCounters]);

    useEffect((): void => {
        localDispatch({
            type: INITIALIZE_DATA,
            payload: {
                dataType: 'userColumns'
            }
        });
    }, [userSettingsColumns]);

    const reorder = (list: UserSettingAttribute[], fromIndex: number, toIndex: number): UserSettingAttribute[] => {
        const reordedList = list.reduce((accumulator: UserSettingAttribute[], current: UserSettingAttribute, index: number, self: UserSettingAttribute[]): UserSettingAttribute[] => {
            if (fromIndex === toIndex) {
                accumulator.push(current);
            }
            if (index === fromIndex) {
                return accumulator;
            }
            if (fromIndex < toIndex) {
                accumulator.push(current);
            }
            if (index === toIndex) {
                accumulator.push(self[fromIndex]);
            }
            if (fromIndex > toIndex) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        return reordedList;
    };

    const onDragEnd = (dataType: DataType, result: DropResult): void => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const reorderedList = reorder(
            userSettingsState[dataType],
            source.index,
            destination.index
        );

        localDispatch({
            type: UPDATE_ORDER,
            payload: {
                dataType,
                list: reorderedList
            }
        });
    };

    const handleCheckboxToggle = (dataType: DataType, itemKey: string): void => {
        localDispatch({
            type: UPDATE_VISIBILITY,
            payload: {
                dataType,
                itemKey
            }
        });
    };

    const onDragCountersEnd = (result: DropResult): void => {
        onDragEnd('userCounters', result);
    };

    const handleCountersCheckboxToggle = (counterKey: string): void => {
        handleCheckboxToggle('userCounters', counterKey);
    };

    const onDragColumnsEnd = (result: DropResult): void => {
        onDragEnd('userColumns', result);
    };

    const handleColumnsCheckboxToggle = (columnKey: string): void => {
        handleCheckboxToggle('userColumns', columnKey);
    };

    const handleResetClick = (): void => {
        localDispatch({
            type: INITIALIZE_DATA,
            payload: {
                dataType: 'userCounters'
            }
        });
        localDispatch({
            type: INITIALIZE_DATA,
            payload: {
                dataType: 'userColumns'
            }
        });
    };

    const handleSaveClick = (): void => {
        const userSettings = {
            userSettingsColumns: userSettingsState.userColumns,
            userSettingsCounters: userSettingsState.userCounters,
            mapLegendDisplay
        };
        dispatch(updateUserSettings(userSettings));
    };

    // checks to make sure that at least one column has been set to visible
    const isAColumnVisible = (): boolean => {
        return userSettingsState.userColumns.some((column) => {
            return column.visible;
        });
    };

    return (
        <Fragment>
            <Tabs
                value={currentTab}
                onChange={(event, value: number): void => {
                    setCurrentTab(value);
                }}
                centered
                indicatorColor='primary'
                textColor='primary'
                data-qa='userSettings-tabList'
            >
                <Tab
                    label='Counters'
                    value={UserSettingsTab.Counters}
                    data-qa='counters-tab'
                />

                <Tab
                    label='Columns'
                    value={UserSettingsTab.Columns}
                    data-qa='columns-tab'
                />
            </Tabs>

            {
                currentTab === UserSettingsTab.Counters &&
                <StyledDiv className={classes.listContainer} data-qa='counters-container'>
                    {
                        ((userSettingsStatus !== ApiStatus.Idle && userSettingsStatus !== ApiStatus.Loading && userSettingsCounters.length === 0) || userSettingsStatus === ApiStatus.Failure) ?
                            (
                                <ErrorPage errorHeaderText=''>
                                    <Typography variant='body1' data-qa='noCounters'>No counters available in your user settings.</Typography>
                                </ErrorPage>
                            ) : (
                                <Fragment>
                                    <Typography variant='body2' className={classes.description}>
                                        Select the counter tabs you wish to see on your page. Click and drag them into the order you want them to be presented in the tab header.
                                    </Typography>
                                    <DraggableSettingsList
                                        list={userSettingsState.userCounters}
                                        handleDragEnd={onDragCountersEnd}
                                        handleCheckboxToggle={handleCountersCheckboxToggle}
                                    />
                                </Fragment>
                            )
                    }
                </StyledDiv>
            }

            {
                currentTab === UserSettingsTab.Columns &&
                <StyledDiv className={classes.listContainer} data-qa='columns-container'>
                    {
                        ((userSettingsStatus !== ApiStatus.Idle && userSettingsStatus !== ApiStatus.Loading && userSettingsColumns.length === 0) || userSettingsStatus === ApiStatus.Failure) ?
                            (
                                <ErrorPage errorHeaderText=''>
                                    <Typography variant='body1' data-qa='noColumns'>No columns available in your user settings.</Typography>
                                </ErrorPage>
                            ) : (
                                <Fragment>
                                    <Typography variant='body2' className={classes.description}>
                                        Select the columns you wish to see on your page. Click and drag them into the order you want them to be presented in the table.
                                    </Typography>
                                    <DraggableSettingsList
                                        list={userSettingsState.userColumns}
                                        handleDragEnd={onDragColumnsEnd}
                                        handleCheckboxToggle={handleColumnsCheckboxToggle}
                                    />
                                </Fragment>
                            )
                    }
                </StyledDiv>
            }

            <StyledGrid
                container
                className={classes.actionsContainer}
            >
                <Grid item xs={6}>
                    <Button size='small' onClick={handleResetClick} data-qa='resetCounters-button'>Reset</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        disabled={
                            userSettingsCounters.length === 0 ||
                            userSettingsStatus === ApiStatus.Idle ||
                            userSettingsStatus === ApiStatus.Loading ||
                            userSettingsUpdateStatus === ApiStatus.Loading ||
                            !isAColumnVisible()
                        }
                        onClick={handleSaveClick}
                        startIcon={userSettingsUpdateStatus === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='saveCounters-button'
                    >
                        {
                            userSettingsUpdateStatus === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Grid>
            </StyledGrid>
        </Fragment>
    );
};

export default UserSettings;
