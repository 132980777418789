import { ShareType } from '../helpers/enums';
import { useTypedSelector } from '../redux';

export const useAccessTokenUrl = (baseRoute: string): string => {
    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const accessToken = useTypedSelector((state) => { return state.user.accessToken; });

    if (shareType === null || shareType === ShareType.OperatorReadOnly || shareType === ShareType.CustomerReadOnly) {
        return baseRoute;
    }

    return `${baseRoute}?access_token=${accessToken}`;
};
