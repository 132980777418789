import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { Typography } from '@mui/material';

import { useTypedSelector } from '../redux';
import { ApiStatus, ApplicationResourceNames, ShareType } from '../helpers/enums';
import { routeComparator } from '../helpers/navigationUtils';
import { publicRoutes, protectedRoutes, privateRoutes } from './appRouteList';
import HorizontallyCenteredSpinner from '../components/loaders/horizontallyCenteredSpinner';
import ShipmentsPage from '../components/pages/shipmentsPage';
import ShipmentDetailsPage from '../components/pages/shipmentDetailsPage';
import TripDetailsPage from '../components/pages/tripDetailsPage';
import ShipmentsCalendarPage from '../components/pages/shipmentsCalendarPage';
import PreferencesPage from '../components/pages/preferencesPage';
import NotificationsPage from '../components/pages/notificationsPage';
import LocationsPage from '../components/pages/locationsPage';
import OrdersPage from '../components/pages/ordersPage';
import OrderDetailsPage from '../components/pages/orderDetailsPage';
import AnalyticsPage from '../components/pages/analyticsPage';
import ErrorBoundary from '../components/errors/errorBoundary';
import ErrorPage from '../components/errors/errorPage';
import NotAuthorized from '../components/errors/notAuthorized';
import LoginError from '../components/errors/loginError';
import PageNotFound from '../components/errors/pageNotFound';

import Login from '../components/pages/authentication/login';
import Authorize from '../components/pages/authentication/authorize';
import Revoke from '../components/pages/authentication/revoke';
import AuthenticatedRoute from './authenticatedRoute';

const AllRoutes = (): JSX.Element => {
    const endpointStatus = useTypedSelector((state) => { return state.availableServices.status; });
    const userLoginStatus = useTypedSelector((state) => { return state.user.userLoginStatus; });
    const loginRedirectUrl = useTypedSelector((state) => { return state.user.loginRedirectUrl; });

    const userInformationStatus = useTypedSelector((state) => { return state.user.userInformationStatus; });
    const userInformation = useTypedSelector((state) => { return state.user.userInformation; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    if (endpointStatus === ApiStatus.Failure) {
        return (
            <Switch>
                <Route path='*'>
                    <ErrorBoundary>
                        <ErrorPage errorHeaderText='Initialization Error'>
                            <Typography variant='body1'>A failure occurred while locating one of the required services. Please try again.</Typography>
                        </ErrorPage>
                    </ErrorBoundary>
                </Route>
            </Switch>
        );
    }

    if (userLoginStatus === ApiStatus.Failure) {
        return (
            <Switch>
                <Route path='*'>
                    <ErrorBoundary>
                        <LoginError />
                    </ErrorBoundary>
                </Route>
            </Switch>
        );
    }

    // If the user is logged in and doesnt have a sharing token we need to wait on the userInformation to build the routes and redirect
    if (userLoginStatus === ApiStatus.Success && shareType !== ShareType.Shipment && shareType !== ShareType.Stop && (userInformationStatus === ApiStatus.Idle || userInformationStatus === ApiStatus.Loading)) {
        return (
            <HorizontallyCenteredSpinner />
        );
    }

    const appRouteList = userInformation.userApplications.transportationUi.filter((app) => {
        return !app.resourcePath.startsWith('http');
    });

    const routeLookup: { [k in ApplicationResourceNames]?: {
        route: string;
        component: React.ComponentType<{}>;
    } } = {
        [ApplicationResourceNames.ShipmentsOverview]: {
            route: privateRoutes.shipmentsOverview,
            component: ShipmentsPage
        },
        [ApplicationResourceNames.ShipmentListActive]: {
            route: privateRoutes.shipmentsActive,
            component: ShipmentsPage
        },
        [ApplicationResourceNames.ShipmentsCalendar]: {
            route: privateRoutes.shipmentsCalendar,
            component: ShipmentsCalendarPage
        },
        [ApplicationResourceNames.ShipmentListHistory]: {
            route: privateRoutes.shipmentsHistory,
            component: ShipmentsPage
        },
        [ApplicationResourceNames.OrdersOverview]: {
            route: privateRoutes.ordersOverview,
            component: OrdersPage
        },
        [ApplicationResourceNames.OrdersListActive]: {
            route: privateRoutes.ordersActive,
            component: OrdersPage
        },
        [ApplicationResourceNames.Preferences]: {
            route: privateRoutes.preferences,
            component: PreferencesPage
        },
        [ApplicationResourceNames.CustomNotifications]: {
            route: privateRoutes.notifications,
            component: NotificationsPage
        },
        [ApplicationResourceNames.TuiLocations]: {
            route: privateRoutes.locations,
            component: LocationsPage
        },
        [ApplicationResourceNames.Analytics]: {
            route: privateRoutes.analytics,
            component: AnalyticsPage
        }
    };

    const renderRedirect = (): JSX.Element => {
        const isRedirectUrlPublicRoute = routeComparator(Object.values(publicRoutes), loginRedirectUrl);

        let redirectUrl = publicRoutes.notAuthorized;
        if (!isRedirectUrlPublicRoute) {
            redirectUrl = loginRedirectUrl;
        } else if (appRouteList.length > 0) {
            redirectUrl = appRouteList[0].resourcePath;
        }

        return (
            <Redirect
                exact
                from={publicRoutes.authorize}
                to={redirectUrl}
            />
        );
    };

    return (
        <Switch>
            {
                userInformationStatus === ApiStatus.Success && renderRedirect()
            }
            <Route exact path={publicRoutes.root}>
                <ErrorBoundary>
                    <Login />
                </ErrorBoundary>
            </Route>
            <Route exact path={publicRoutes.authorize}>
                <ErrorBoundary>
                    <Authorize />
                </ErrorBoundary>
            </Route>
            <Route exact path={publicRoutes.revoke}>
                <ErrorBoundary>
                    <Revoke />
                </ErrorBoundary>
            </Route>
            <Route exact path={publicRoutes.notAuthorized}>
                <ErrorBoundary>
                    <NotAuthorized />
                </ErrorBoundary>
            </Route>
            {
                userLoginStatus === ApiStatus.Loading &&
                <Redirect
                    exact
                    from='*'
                    to={publicRoutes.root}
                />
            }
            <React.StrictMode>
                {
                    appRouteList.map((app) => {
                        const routeData = routeLookup[app.resourceKey];
                        return (
                            <AuthenticatedRoute
                                exact
                                key={app.resourceKey}
                                path={routeData?.route || app.resourcePath}
                                component={routeData?.component || PageNotFound}
                            />
                        );
                    })
                }
                <AuthenticatedRoute
                    exact
                    key={protectedRoutes.shipmentDetails}
                    path={protectedRoutes.shipmentDetails}
                    component={ShipmentDetailsPage}
                />
                <AuthenticatedRoute
                    exact
                    key={protectedRoutes.tripDetails}
                    path={protectedRoutes.tripDetails}
                    component={TripDetailsPage}
                />
                <AuthenticatedRoute
                    exact
                    key={protectedRoutes.orderDetails}
                    path={protectedRoutes.orderDetails}
                    component={OrderDetailsPage}
                />
            </React.StrictMode>
            <Route>
                <ErrorBoundary>
                    <PageNotFound />
                </ErrorBoundary>
            </Route>
        </Switch>
    );
};

export default AllRoutes;
