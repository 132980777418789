import {
    GeoJsonTypes,
    Point,
    LineString,
    Polygon,
    Feature
} from 'geojson';

import { ShipmentData, Stop } from '../../../interfaces/services/shipmentDetails';
import { StopType, ShipmentStatus } from '../../enums';
import { formatCityRegionPostalCountry } from '../../addressUtils';
import { groupBy, addSpaceBeforeUppercaseCharacter } from '../../dataUtils';
import { zonedDateTimeToDisplay } from '../../dateUtils';
import { isShipmentLtl } from '../../shipmentUtils';
import { getDeliveryStatusColor } from '../../styleHelpers';
import { GeoFenceFeatureProperties } from '../layers/interfaces';
import { getGeofenceFeaturePointsForStop } from '../mapUtils';
import { MapSources, MapImages, ShipmentDetailsFeatureTypes } from '../enums';

/** Interface used for the properties object on the Shipment Details Map Source. */
export interface ShipmentDetailsMapSourceProperties extends GeoFenceFeatureProperties<ShipmentDetailsFeatureTypes> {
    id: string;
    shipmentId?: string;
    stopSequence?: number;
    title?: string;
    image?: MapImages;
    textColor?: string;
    iconOffset?: number[];
    textOffset?: number[];
    geofenceFeaturePoints?: Feature<Polygon, GeoFenceFeatureProperties<ShipmentDetailsFeatureTypes>> | undefined;
    description?: string;
    hasPulse?: boolean;
}

/** Interface used to define the Shipment Details Map Source. */
export interface ShipmentDetailsMapSource {
    type: 'geojson';
    data: {
        type: GeoJsonTypes;
        features: Feature<Point | Polygon | LineString, ShipmentDetailsMapSourceProperties>[];
    };
}

/** Interface used to define a stop with the additional ShipmentId property added. */
export interface CustomStopData extends Stop {
    shipmentId: string;
}

/**
 * Using the shipment stops list, it will first group the stops by latitude to find any duplicate locations.
 * Using the grouped latitudes, it will first sort them and then determine the details that the source will have for each stop.
 * If a duplicate lat/long is found, it will offset the icon so they both will show on the map.
 * Using all of this stop information, it will add a Point feature to the source.
 * Using the pastPositions list, it will add each as a Point feature to the source.
 * Using the currentPosition object, if available, it will add a Point feature to the source.
 * Using the route param, it will also add a MultiLineString feature to the source for the route line.
 * Returns a geojson source to be added to the map.
 * @param shipments The shipments to show on the map
 * @param allShipmentStops The stops across all shipments that will be shown on the map
 */
export const getShipmentDetailsSource = (shipments: ShipmentData[], allShipmentStops: CustomStopData[]): ShipmentDetailsMapSource => {
    const shipmentDetailsFeatures: Feature<Point | Polygon | LineString, ShipmentDetailsMapSourceProperties>[] = [];

    const groupedLatitudes = groupBy(allShipmentStops, 'latitude');
    Object.keys(groupedLatitudes).forEach((lat: string): void => {
        const shipmentStops: CustomStopData[] = groupedLatitudes[lat];
        shipmentStops.sort((a: CustomStopData, b: CustomStopData): number => {
            return a.longitude - b.longitude;
        });

        let iconOffset = 0;
        let textOffset = 0;
        for (let i = 0; i < shipmentStops.length; i++) {
            const currentStop = shipmentStops[i];
            let title;
            let id;
            let image;
            let textColor;

            if (shipmentStops[i - 1] !== undefined) {
                if (currentStop.longitude === shipmentStops[i - 1].longitude) {
                    iconOffset += 32;
                    textOffset += 1;
                }
            }

            // Add origin, destination, and stop markers
            if (currentStop.stopType === StopType.Origin) {
                title = 'O';
                id = `${currentStop.shipmentId}_stop${currentStop.stopType}`;
                image = MapImages.originDestinationMapMarker;
                textColor = '#ffffff';
            } else if (currentStop.stopType === StopType.Destination) {
                title = 'D';
                id = `stop${currentStop.stopType}`;
                image = MapImages.originDestinationMapMarker;
                textColor = '#ffffff';
            } else {
                const stopCounter = (currentStop.stopSequence - 1).toString();
                title = stopCounter;
                id = `stop${currentStop.stopType}${stopCounter}`;
                image = MapImages.stopMapMarker;
                textColor = '#000000';
            }

            const stopLongLat = [currentStop.longitude, currentStop.latitude];

            const geofenceFeature = getGeofenceFeaturePointsForStop(currentStop, ShipmentDetailsFeatureTypes.Geofence);
            if (geofenceFeature) {
                shipmentDetailsFeatures.push(geofenceFeature);
            }

            shipmentDetailsFeatures.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: stopLongLat
                },
                properties: {
                    id,
                    featureType: ShipmentDetailsFeatureTypes.ShipmentStop,
                    shipmentId: currentStop.shipmentId,
                    stopSequence: currentStop.stopSequence,
                    title,
                    image,
                    textColor,
                    iconOffset: [iconOffset, -32],
                    textOffset: [textOffset, -1.9],
                    ...geofenceFeature && { geofenceFeaturePoints: geofenceFeature }
                }
            });
        }
    });

    shipments.forEach((shipment): void => {
        const {
            breadcrumbPositions,
            currentPosition,
            plannedRoute
        } = shipment;

        let breadcrumbs = breadcrumbPositions;
        if (shipment.currentPosition) {
            if (shipment.shipmentStatus === ShipmentStatus.Delivered ||
                shipment.shipmentStatus === ShipmentStatus.Completed ||
                shipment.shipmentStatus === ShipmentStatus.Cancelled) {
                // if the shipment has been delivered, completed, or cancelled see if the current position exists as a breadcrumb
                const currentLocationBreadcrumb = breadcrumbPositions.find((breadcrumb): boolean => {
                    return shipment.currentPosition?.positionEventId === breadcrumb.positionEventId;
                });

                // if the current location breadcrumb was not already in the breadcrumb list add it
                if (currentLocationBreadcrumb === undefined) {
                    breadcrumbs = [...breadcrumbPositions, shipment.currentPosition];
                }
            } else {
                // if the status is not delivered, completed, or cancelled, filter the breadcrumbs to remove the current position breadcrumb
                breadcrumbs = breadcrumbPositions.filter((breadcrumb): boolean => {
                    return breadcrumb.positionEventId !== shipment.currentPosition?.positionEventId;
                });
            }
        }

        breadcrumbs.forEach((position, index): void => {
            const tooltipHtml = `
                <div class='mapTooltip'>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'><strong>Nearest Location:</strong></div>
                        <div class='mapTooltipCell'>
                            <strong>
                                ${formatCityRegionPostalCountry({ city: position.cityName, region: position.stateCode })}
                            </strong>
                        </div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Date/Time:</div>
                        <div class='mapTooltipCell'>${zonedDateTimeToDisplay(position.positionEventDateTime)}</div>
                    </div>
                </div>
            `;

            const positionLongLat = [position.longitude, position.latitude];

            shipmentDetailsFeatures.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: positionLongLat
                },
                properties: {
                    id: `${shipment.freightProviderReferenceNumber}_pastPosition${index}`,
                    featureType: ShipmentDetailsFeatureTypes.PastPosition,
                    description: tooltipHtml
                }
            });
        });

        if (currentPosition &&
            currentPosition.latitude !== null && currentPosition.longitude !== null &&
            shipment.shipmentStatus !== ShipmentStatus.Delivered &&
            shipment.shipmentStatus !== ShipmentStatus.Completed &&
            shipment.shipmentStatus !== ShipmentStatus.Cancelled
        ) {
            const currentPositionLongLat = [currentPosition.longitude, currentPosition.latitude];

            const tooltipHtml = `
                <div class='mapTooltip'>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'><strong>Nearest Location:</strong></div>
                        <div class='mapTooltipCell'>
                            <strong>
                                ${formatCityRegionPostalCountry({ city: currentPosition.cityName, region: currentPosition.stateCode })}
                            </strong>
                        </div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Date/Time:</div>
                        <div class='mapTooltipCell'>${zonedDateTimeToDisplay(currentPosition.positionEventDateTime)}</div>
                    </div>
                </div>
            `;

            shipmentDetailsFeatures.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: currentPositionLongLat
                },
                properties: {
                    id: `${shipment.freightProviderReferenceNumber}_currentPosition`,
                    featureType: ShipmentDetailsFeatureTypes.CurrentPosition,
                    description: tooltipHtml,
                    color: getDeliveryStatusColor(shipment.deliveryStatus),
                    hasPulse: currentPosition.positionEventId === shipment.pulsePositionEventId
                }
            });
        }

        if (plannedRoute.length > 0) {
            const [originStop] = shipment.stops.filter((stop): boolean => {
                return stop.stopType === StopType.Origin;
            });
            const [destinationStop] = shipment.stops.filter((stop): boolean => {
                return stop.stopType === StopType.Destination;
            });

            const tooltipHtml = `
                <div class='mapTooltip'>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'><strong>Shipment ID:</strong></div>
                        <div class='mapTooltipCell'>
                            <strong>
                                ${shipment.freightProviderReferenceNumber}
                            </strong>
                        </div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Status:</div>
                        <div class='mapTooltipCell'>
                            ${addSpaceBeforeUppercaseCharacter(isShipmentLtl(shipment.modeType) ? shipment.currentMilestoneProgress.furthest : shipment.shipmentStatus)}
                        </div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Mode:</div>
                        <div class='mapTooltipCell'>${shipment.modeType}</div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Carrier:</div>
                        <div class='mapTooltipCell'>${shipment.freightHauler.name}</div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Origin:</div>
                        <div class='mapTooltipCell'>${formatCityRegionPostalCountry({ city: originStop?.address?.city || null, region: originStop?.address?.state || null })}</div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Destination:</div>
                        <div class='mapTooltipCell'>${formatCityRegionPostalCountry({ city: destinationStop?.address?.city || null, region: destinationStop?.address?.state || null })}</div>
                    </div>
                </div>
            `;

            shipmentDetailsFeatures.push({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: plannedRoute
                },
                properties: {
                    id: `${shipment.freightProviderReferenceNumber}_routeLine`,
                    shipmentId: shipment.freightProviderReferenceNumber,
                    featureType: ShipmentDetailsFeatureTypes.RouteLine,
                    description: tooltipHtml
                }
            });
        }
    });

    const shipmentDetailsStopsSource: ShipmentDetailsMapSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: shipmentDetailsFeatures
        }
    };

    return shipmentDetailsStopsSource;
};

/**
* Adds the shipment details source to the map if it doesn't already exist
 * @param map Instance of your map
 * @param shipment The shipment details to show on the map
*/
export const addShipmentDetailsSource = (map: any, shipment: ShipmentData): ShipmentDetailsMapSource => {
    // We need to process all of the stops together across all shipments so that we can properly offset any duplicates.
    const allShipmentStops = shipment.stops.map((stop): CustomStopData => {
        return {
            ...stop,
            shipmentId: shipment.freightProviderReferenceNumber
        };
    });

    const shipmentDetailsSource = getShipmentDetailsSource([shipment], allShipmentStops);

    if (map.getSource(MapSources.shipmentDetailsSource) === undefined) {
        map.addSource(MapSources.shipmentDetailsSource, shipmentDetailsSource);
    } else {
        map.getSource(MapSources.shipmentDetailsSource).setData(shipmentDetailsSource.data);
    }

    return shipmentDetailsSource;
};
