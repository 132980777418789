import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import {
    mdiDotsVertical,
    mdiAlarm,
    mdiAttachment,
    mdiMessageBulleted,
    mdiNumeric,
    mdiCellphone,
    mdiShareVariant,
    mdiFire,
    mdiTruck
} from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { updateShipmentPriority } from '../../redux/shipments';
import { ShipmentListData } from '../../interfaces/services/shipment';
import { ShareType } from '../../helpers/enums';
import { handleShareUrlCopy } from '../../helpers/navigationUtils';
import Hidden from '../layouts/hidden';
import ShipmentActionButton from '../buttons/shipmentActionButton';
import ShipmentDetailsIconButton from '../links/shipmentDetailsIconButton';
import ShipmentDetailsMenuItem from '../links/shipmentDetailsMenuItem';
import EditTimesDialog from '../dialogs/editTimesDialog';
import AddReferenceNumbersDialog from '../dialogs/addReferenceNumbersDialog';
import ManageDriverDialog from '../dialogs/manageDriverDialog';
import NotesDialog from '../dialogs/notesDialog';
import DocumentsDialog from '../dialogs/documentsDialog';
import ShipmentDetailsDialog from '../dialogs/shipmentDetailsDialog';
import ShipmentStatusDialog from '../dialogs/shipmentStatusDialog';

const classesPrefix = 'shipmentListActions';

const classes = {
    iconButton: `${classesPrefix}-iconButton`
};

const StyledIconButton = styled(IconButton)(() => {
    return {
        [`&.${classes.iconButton}`]: {
            padding: 0
        }
    };
});

const ShipmentListActions = ({
    shipment,
    iconColor
}: {
    /** The shipment to use in the actions. */
    shipment: ShipmentListData;
    /** The color of the icons */
    iconColor: string;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editTimesDialogIsOpen, setEditTimesDialogIsOpen] = useState(false);
    const [addReferenceNumbersDialogIsOpen, setAddReferenceNumbersDialogIsOpen] = useState(false);
    const [manageDriverDialogIsOpen, setManageDriverDialogIsOpen] = useState(false);
    const [notesDialogIsOpen, setNotesDialogIsOpen] = useState(false);
    const [documentsDialogIsOpen, setDocumentsDialogIsOpen] = useState(false);
    const [shipmentDetailsDialogIsOpen, setShipmentDetailsDialogIsOpen] = useState(false);
    const [shipmentStatusDialogIsOpen, setShipmentStatusDialogIsOpen] = useState(false);

    const UrlShortenerApi = useTypedSelector((state) => { return state.availableServices.endpoints.UrlShortenerApi; });
    const shortUrlExpiryDays = useTypedSelector((state) => { return state.organization.organizationPreferences?.shortUrlExpiryDays; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    if (shareType === ShareType.CustomerReadOnly) {
        return (
            <Fragment>
                <ShipmentDetailsIconButton
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    modeType={shipment.modeType}
                    iconColor={iconColor}
                    iconPadding={0}
                />
                <ShipmentActionButton
                    title='Share Shipment'
                    handleClick={(): void => {
                        handleShareUrlCopy({
                            urlShortenerApi: UrlShortenerApi,
                            pageUrl: `/shipmentDetails/${shipment.shipmentUniqueName}`,
                            token: shipment.singleShipmentSharingToken,
                            successMessage: 'Shipment share url copied to clipboard.',
                            shortenedUrlName: `Shipment ${shipment.shipmentUniqueName}`,
                            expiryDays: shortUrlExpiryDays
                        });
                    }}
                    mdiIcon={mdiShareVariant}
                    iconColor={iconColor}
                    iconPadding={0}
                    data-qa='shareShipment-action'
                />
            </Fragment>
        );
    }

    if (shareType !== null) {
        return (
            <ShipmentDetailsIconButton
                shipmentUniqueName={shipment.shipmentUniqueName}
                modeType={shipment.modeType}
                iconColor={iconColor}
                iconPadding={0}
            />
        );
    }

    return (
        <Fragment>
            <Hidden breakpoint='lg' direction='down'>
                <ShipmentActionButton
                    title='Manage Driver'
                    handleClick={(): void => {
                        setManageDriverDialogIsOpen(true);
                    }}
                    mdiIcon={mdiCellphone}
                    iconColor={iconColor}
                    iconPadding={0}
                    data-qa='manageDriver-action-open'
                />
            </Hidden>

            <Tooltip title='More Actions'>
                <StyledIconButton
                    className={classes.iconButton}
                    onClick={(event): void => {
                        setAnchorEl(event.currentTarget);
                    }}
                    data-qa='moreActions-action'
                >
                    <Icon
                        path={mdiDotsVertical}
                        size={1}
                        color={iconColor}
                    />
                </StyledIconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(): void => {
                    setAnchorEl(null);
                }}
                data-qa='moreActions-menu'
            >
                {/* Span wrapper needed as HTML DOM element for MUI tooltip */}
                <span>
                    <Hidden breakpoint='lg' direction='up'>
                        <MenuItem
                            onClick={(): void => {
                                setAnchorEl(null);
                                setManageDriverDialogIsOpen(true);
                            }}
                            data-qa='manageDriver-action-mobile'
                        >
                            <ListItemIcon>
                                <Icon
                                    path={mdiCellphone}
                                    size={1}
                                    color={iconColor}
                                />
                            </ListItemIcon>
                            <Typography variant='inherit'>Manage Driver</Typography>
                        </MenuItem>
                    </Hidden>
                </span>

                <MenuItem
                    onClick={(): void => {
                        setAnchorEl(null);
                        setEditTimesDialogIsOpen(true);
                    }}
                    data-qa='editTimes-action'
                >
                    <ListItemIcon>
                        <Icon
                            path={mdiAlarm}
                            size={1}
                            color={iconColor}
                        />
                    </ListItemIcon>
                    <Typography variant='inherit'>Edit Times</Typography>
                </MenuItem>

                <MenuItem
                    onClick={(): void => {
                        setAnchorEl(null);
                        setAddReferenceNumbersDialogIsOpen(true);
                    }}
                    data-qa='addReferenceNumbers-action'
                >
                    <ListItemIcon>
                        <Icon
                            path={mdiNumeric}
                            size={1}
                            color={iconColor}
                        />
                    </ListItemIcon>
                    <Typography variant='inherit'>Add Reference Numbers</Typography>
                </MenuItem>

                <MenuItem
                    onClick={(): void => {
                        setAnchorEl(null);
                        setShipmentStatusDialogIsOpen(true);
                    }}
                    data-qa='updateShipmentStatus-action'
                >
                    <ListItemIcon>
                        <Icon
                            path={mdiTruck}
                            size={1}
                            color={iconColor}
                        />
                    </ListItemIcon>
                    <Typography variant='inherit'>Update Shipment Status</Typography>
                </MenuItem>

                <MenuItem
                    onClick={(): void => {
                        setAnchorEl(null);
                        handleShareUrlCopy({
                            urlShortenerApi: UrlShortenerApi,
                            pageUrl: `/shipmentDetails/${shipment.shipmentUniqueName}`,
                            token: shipment.singleShipmentSharingToken,
                            successMessage: 'Shipment share url copied to clipboard.',
                            shortenedUrlName: `Shipment ${shipment.shipmentUniqueName}`,
                            expiryDays: shortUrlExpiryDays
                        });
                    }}
                    data-qa='shareShipment-action'
                >
                    <ListItemIcon>
                        <Icon
                            path={mdiShareVariant}
                            size={1}
                            color={iconColor}
                        />
                    </ListItemIcon>
                    <Typography variant='inherit'>Share Shipment</Typography>
                </MenuItem>

                <MenuItem
                    onClick={(): void => {
                        setAnchorEl(null);
                        setNotesDialogIsOpen(true);
                    }}
                    data-qa='addNotes-action'
                >
                    <ListItemIcon>
                        <Icon
                            path={mdiMessageBulleted}
                            size={1}
                            color={iconColor}
                        />
                    </ListItemIcon>
                    <Typography variant='inherit'>View and Add Notes</Typography>
                </MenuItem>

                <MenuItem
                    onClick={(): void => {
                        setAnchorEl(null);
                        setDocumentsDialogIsOpen(true);
                    }}
                    data-qa='addDocuments-action'
                >
                    <ListItemIcon>
                        <Icon
                            path={mdiAttachment}
                            size={1}
                            color={iconColor}
                        />
                    </ListItemIcon>
                    <Typography variant='inherit'>View and Attach Documents</Typography>
                </MenuItem>

                <MenuItem
                    onClick={(): void => {
                        setAnchorEl(null);
                        dispatch(updateShipmentPriority({
                            shipmentUniqueName: shipment.shipmentUniqueName,
                            isPriorityShipment: !shipment.isPriorityShipment
                        }));
                    }}
                    data-qa='shipmentPriority-action'
                >
                    <ListItemIcon>
                        <Icon
                            path={mdiFire}
                            size={1}
                            color={iconColor}
                        />
                    </ListItemIcon>
                    <Typography variant='inherit'>{`${shipment.isPriorityShipment ? 'Remove' : 'Add'} Priority Shipment Flag`}</Typography>
                </MenuItem>

                <ShipmentDetailsMenuItem
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    iconColor={iconColor}
                    handleClick={(event): void => {
                        setAnchorEl(null);
                        // README: When the link is clicked js will open the Dialog and will not navigate.
                        // If the user right clicks and selects open in a new tab the href value is used to navigate
                        event.preventDefault();
                        setShipmentDetailsDialogIsOpen(true);
                    }}
                />
            </Menu>

            {
                editTimesDialogIsOpen &&
                <EditTimesDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={editTimesDialogIsOpen}
                    closeDialog={(): void => {
                        setEditTimesDialogIsOpen(false);
                    }}
                />
            }

            {
                addReferenceNumbersDialogIsOpen &&
                <AddReferenceNumbersDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    initialTractorId={shipment.tractorReferenceNumber}
                    initialTrailerId={shipment.trailerReferenceNumber}
                    isOpen={addReferenceNumbersDialogIsOpen}
                    closeDialog={(): void => {
                        setAddReferenceNumbersDialogIsOpen(false);
                    }}
                />
            }

            {
                manageDriverDialogIsOpen &&
                <ManageDriverDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    freightHaulerIdentifierTypeName={shipment.freightHaulerIdentifierTypeName}
                    freightHaulerIdentifierName={shipment.freightHaulerIdentifierName}
                    initialPhoneNumber={shipment.mobileTrackingPhoneNumber}
                    isOpen={manageDriverDialogIsOpen}
                    closeDialog={(): void => {
                        setManageDriverDialogIsOpen(false);
                    }}
                />
            }

            {
                shipmentStatusDialogIsOpen &&
                <ShipmentStatusDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    freightHaulerIdentifierTypeName={shipment.freightHaulerIdentifierTypeName}
                    freightHaulerIdentifierName={shipment.freightHaulerIdentifierName}
                    shipmentStatus={shipment.shipmentStatus}
                    isOpen={shipmentStatusDialogIsOpen}
                    closeDialog={(): void => {
                        setShipmentStatusDialogIsOpen(false);
                    }}
                />
            }

            {
                notesDialogIsOpen &&
                <NotesDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={notesDialogIsOpen}
                    closeDialog={(): void => {
                        setNotesDialogIsOpen(false);
                    }}
                />
            }

            {
                documentsDialogIsOpen &&
                <DocumentsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={documentsDialogIsOpen}
                    closeDialog={(): void => {
                        setDocumentsDialogIsOpen(false);
                    }}
                />
            }

            {
                shipmentDetailsDialogIsOpen &&
                <ShipmentDetailsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    modeType={shipment.modeType}
                    handleDialogClose={(): void => {
                        setShipmentDetailsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentListActions;
