import React from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ShipmentHistoryItem } from '../../interfaces/services/shipmentHistory';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';

const classesPrefix = 'shipmentHistoryTable';

const classes = {
    tableWrapper: `${classesPrefix}-tableWrapper`,
    tableHeader: `${classesPrefix}-tableHeader`
};

const StyledTableContainer = styled(TableContainer)(() => {
    return {
        [`&.${classes.tableWrapper}`]: {
            paddingTop: '8px'
        },
        [`& .${classes.tableHeader}`]: {
            fontWeight: 600,
            minWidth: '160px'
        }
    };
});

const ShipmentHistoryTable = ({
    isFetching,
    timeZone,
    shipmentHistory
}: {
    isFetching: boolean;
    timeZone: string;
    shipmentHistory: ShipmentHistoryItem[];
}): JSX.Element => {
    const renderTableBody = (): JSX.Element | JSX.Element[] => {
        if (isFetching) {
            return Array.from(new Array(3)).map((item, index): JSX.Element => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                        <TableCell><Skeleton variant='text' width='160px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='120px' /></TableCell>
                        <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                    </TableRow>
                );
            });
        }

        if (shipmentHistory.length === 0) {
            return (
                <TableRow>
                    <TableCell align='center' colSpan={3} data-qa='shipmentHistory-noResults'>No Shipment History Found</TableCell>
                </TableRow>
            );
        }

        return shipmentHistory.map((historyItem, index): JSX.Element => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index} data-qa={`shipmentHistory-row-${index + 1}`}>
                    <TableCell data-qa='description'>{historyItem.description}</TableCell>
                    <TableCell data-qa='time'>{zonedDateTimeToDisplay(historyItem.eventDateTime) || '--'}</TableCell>
                    <TableCell data-qa='source'>{historyItem.source}</TableCell>
                </TableRow>
            );
        });
    };

    return (
        <StyledTableContainer className={classes.tableWrapper}>
            <Table size='small' aria-label='Shipment History' data-qa='shipmentHistory-table'>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='description-column-header'>Description</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='time-column-header'>{`Time (${timeZone})`}</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='source-column-header'>Source</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-qa='shipmentHistory-content'>
                    {renderTableBody()}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default ShipmentHistoryTable;
