import React from 'react';
import { Typography } from '@mui/material';
import ErrorPage from './errorPage';

const NoShipmentDetails = (): JSX.Element => {
    return (
        <ErrorPage errorHeaderText='No Shipment Details Found'>
            <Typography data-qa='noShipmentDetails'>
                We were unable to locate the details for this shipment.
            </Typography>
        </ErrorPage>
    );
};

export default NoShipmentDetails;
