import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { fetchOrdersList } from '../../redux/orders';
import { OrderListData } from '../../interfaces/services/orders';
import { CellParams, ColDef, Order } from '../../interfaces/muiTableInterfaces';
import { ApiStatus, ShareType, SortDirection } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import DataListCell from './dataListCell';
import TableColumnHeader from './tableColumnHeader';
import DataListLoading from '../loaders/lists/dataListLoading';
import DataListNoResults from '../errors/dataListNoResults';
import ShipmentDetailsLabelLink from '../links/shipmentDetailsLabelLink';
import OrderDetailsLabelLink from '../links/orderDetailsLabelLink';

interface StyleProps {
    filtersName: string;
    shareType: ShareType | null;
}

const classesPrefix = 'orderListTable';

const classes = {
    ordersTableWrapper: `${classesPrefix}-ordersTableWrapper`,
    tableHeader: `${classesPrefix}-tableHeader`,
    tableRow: `${classesPrefix}-tableRow`,
    tableCellSpan: `${classesPrefix}-tableCellSpan`
};

const StyledTableContainer = styled(
    TableContainer,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'filtersName' && prop !== 'shareType'; }
    }
)<StyleProps>(({ filtersName, shareType, theme }) => {
    let subtractHeight = 194;
    if (shareType === ShareType.View) {
        subtractHeight = 158;
    }
    if (filtersName) {
        subtractHeight += 48;
    }
    return {
        [`&.${classes.ordersTableWrapper}`]: {
            width: '100%',
            height: 'calc(100vh - 164px)',
            borderBottom: `2px solid ${theme.palette.divider}`,
            overflow: 'scroll'
        },
        [`& .${classes.tableHeader}`]: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            display: 'inline-block'
        },
        [`& .${classes.tableRow}`]: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover
            }
        },
        [`& .${classes.tableCellSpan}`]: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.ordersTableWrapper}`]: {
                height: `calc(100vh - ${subtractHeight}px)`
            }
        }
    };
});

const OrderListTable = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });
    const ordersListStatus = useTypedSelector((state) => { return state.orders.ordersListStatus; });
    const ordersList = useTypedSelector((state) => { return state.orders.ordersList; });
    const activeOrders = useTypedSelector((state) => { return state.orders.activeOrders; });
    const skip = useTypedSelector((state) => { return state.orders.skip; });
    const take = useTypedSelector((state) => { return state.orders.take; });
    const searchTerm = useTypedSelector((state) => { return state.orders.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.orders.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.orders.sortDirection; });
    const filters = useTypedSelector((state) => { return state.orders.filters; });
    const filtersName = useTypedSelector((state) => { return state.orders.filtersName; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const renderShipmentDetailsLink = (fieldName: 'shipmentUniqueName' | 'freightProviderReferenceNumber', params: CellParams<OrderListData>): JSX.Element => {
        if (shareType === ShareType.View) {
            return (
                <span className={classes.tableCellSpan} style={{ width: params.width }}>
                    {params.value as string}
                </span>
            );
        }
        return (
            <span className={classes.tableCellSpan} style={{ width: params.width }}>
                <ShipmentDetailsLabelLink
                    label=''
                    noWrap
                    shipmentUniqueName={params.data.shipmentUniqueName}
                    freightProviderReferenceNumber={params.data.freightProviderReferenceNumber}
                    modeType={params.data.modeType}
                    displayField={fieldName}
                />
            </span>
        );
    };

    const renderStatusField = (params: CellParams<OrderListData>): JSX.Element => {
        return <span className={classes.tableCellSpan} style={{ width: params.width }}>{addSpaceBeforeUppercaseCharacter(params.value as string)}</span>;
    };

    const renderDateField = (params: CellParams<OrderListData>): JSX.Element => {
        return <span className={classes.tableCellSpan} style={{ width: params.width }}>{zonedDateTimeToDisplay(params.value as string | null) || '--'}</span>;
    };

    const renderEtaDateField = (params: CellParams<OrderListData>): JSX.Element => {
        if (organizationPreferences?.showEta === false) {
            return <Tooltip title='ETA hidden by Preference Setting'><span className={classes.tableCellSpan} style={{ width: params.width }}>--</span></Tooltip>;
        }
        return <span className={classes.tableCellSpan} style={{ width: params.width }}>{zonedDateTimeToDisplay(params.value as string | null) || '--'}</span>;
    };

    /* eslint-disable react/display-name */
    const headCells: ColDef<OrderListData>[] = [
        {
            headerName: 'Order Number',
            field: 'orderNumber',
            width: 130,
            renderCell: (params: CellParams<OrderListData>): JSX.Element => {
                return (
                    <span className={classes.tableCellSpan} style={{ width: params.width }}>
                        <OrderDetailsLabelLink
                            label=''
                            noWrap
                            freightOrderGuid={params.data.freightOrderGuid}
                            orderNumber={params.data.orderNumber}
                        />
                    </span>
                );
            }
        },
        {
            headerName: 'System ID',
            field: 'shipmentUniqueName',
            width: 130,
            renderCell: (params: CellParams<OrderListData>): JSX.Element => {
                return renderShipmentDetailsLink('shipmentUniqueName', params);
            }
        },
        {
            headerName: 'Freight Provider Reference Number',
            field: 'freightProviderReferenceNumber',
            width: 130,
            renderCell: (params: CellParams<OrderListData>): JSX.Element => {
                return renderShipmentDetailsLink('freightProviderReferenceNumber', params);
            }
        },
        {
            headerName: 'Carrier Name',
            field: 'carrierName'
        },
        {
            headerName: 'Customer Name',
            field: 'customerName'
        },
        {
            headerName: 'Pickup Status',
            field: 'pickupDeliveryStatus',
            renderCell: renderStatusField
        },
        {
            headerName: 'Delivery Status',
            field: 'deliveryDeliveryStatus',
            renderCell: renderStatusField
        },
        {
            headerName: 'Shipment Status',
            field: 'shipmentStatus',
            renderCell: renderStatusField
        },
        {
            headerName: 'Pickup Stop ETA',
            field: 'pickupStopEta',
            width: 150,
            renderCell: renderEtaDateField
        },
        {
            headerName: 'Delivery Stop ETA',
            field: 'deliveryStopEta',
            width: 150,
            renderCell: renderEtaDateField
        },
        {
            headerName: 'Pickup Street Address 1',
            field: 'pickupStreet1'
        },
        {
            headerName: 'Pickup City',
            field: 'pickupCity'
        },
        {
            headerName: 'Pickup State',
            field: 'pickupState'
        },
        {
            headerName: 'Pickup Zip',
            field: 'pickupZip'
        },
        {
            headerName: 'Delivery Street Address 1',
            field: 'deliveryStreet1'
        },
        {
            headerName: 'Delivery City',
            field: 'deliveryCity'
        },
        {
            headerName: 'Delivery State',
            field: 'deliveryState'
        },
        {
            headerName: 'Delivery Zip',
            field: 'deliveryZip'
        },
        {
            headerName: 'Pickup Scheduled Time Start',
            field: 'pickupScheduleStartDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Pickup Scheduled Time End',
            field: 'pickupScheduleEndDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Pickup Appointment Time Start',
            field: 'pickupAppointmentStartDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Pickup Appointment Time End',
            field: 'pickupAppointmentEndDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Pickup Geofence Arrival Time',
            field: 'pickupGeofenceArrivalDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Pickup Geofence Departure Time',
            field: 'pickupGeofenceDepartureDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Pickup Carrier Arrival Time',
            field: 'pickupCarrierArrivalDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Pickup Carrier Departure Time',
            field: 'pickupCarrierDepartureDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Scheduled Time Start',
            field: 'deliveryScheduleStartDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Scheduled Time End',
            field: 'deliveryScheduleEndDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Appointment Time Start',
            field: 'deliveryAppointmentStartDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Appointment Time End',
            field: 'deliveryAppointmentEndDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Geofence Arrival Time',
            field: 'deliveryGeofenceArrivalDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Geofence Departure Time',
            field: 'deliveryGeofenceDepartureDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Carrier Arrival Time',
            field: 'deliveryCarrierArrivalDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Delivery Carrier Departure Time',
            field: 'deliveryCarrierDepartureDateTime',
            width: 150,
            renderCell: renderDateField
        },
        {
            headerName: 'Tractor #',
            field: 'tractorReferenceNumber',
            renderCell: (params: CellParams<OrderListData>): JSX.Element => {
                return <span className={classes.tableCellSpan} style={{ width: params.width }}>{params.value as string || '--'}</span>;
            }
        },
        {
            headerName: 'Trailer #',
            field: 'trailerReferenceNumber',
            renderCell: (params: CellParams<OrderListData>): JSX.Element => {
                return <span className={classes.tableCellSpan} style={{ width: params.width }}>{params.value as string || '--'}</span>;
            }
        }
    ];
    /* eslint-enable react/display-name */

    const [columnData, setColumnData] = useState<ColDef<OrderListData>[]>((): ColDef<OrderListData>[] => { return headCells; });
    const [sortOrder, setSortOrder] = useState<Order>(sortDirection === SortDirection.Ascending ? 'asc' : 'desc');
    const [orderBy, setOrderBy] = useState<keyof OrderListData>(sortColumn);

    useEffect((): void => {
        setSortOrder(sortDirection === SortDirection.Ascending ? 'asc' : 'desc');
        setOrderBy(sortColumn);
    }, [sortDirection, sortColumn]);

    const handleWidthChange = (columnId: keyof OrderListData, width: number): void => {
        const newColumns = columnData.map((column): ColDef<OrderListData> => {
            if (column.field === columnId) {
                return {
                    ...column,
                    width
                };
            }
            return column;
        });
        setColumnData(newColumns);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, newSortColumn: keyof OrderListData): void => {
        const isAsc = orderBy === newSortColumn && sortOrder === 'asc';
        const newSortDirection = isAsc ? SortDirection.Descending : SortDirection.Ascending;
        setSortOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(newSortColumn);

        dispatch(fetchOrdersList({
            activeOrders,
            skip,
            take,
            searchTerm,
            sortColumn: newSortColumn,
            sortDirection: newSortDirection,
            filters
        }));
    };

    // Creates a memoized list of the table rows which will only adjust when the orderList changes.
    // This helps fix some performance issues that are causing this entire table to re-render unnecessarily.
    const memoizedTableRows = useMemo((): JSX.Element[] => {
        return ordersList.map((row): JSX.Element => {
            return (
                <TableRow key={row.freightOrderGuid} classes={{ root: classes.tableRow }} data-qa={`orderList-row-${row.freightOrderGuid}`}>
                    {
                        columnData.map((column): JSX.Element => {
                            return (
                                <DataListCell
                                    key={`${row.freightOrderGuid}${column.field}`}
                                    column={column}
                                    row={row}
                                />
                            );
                        })
                    }
                </TableRow>
            );
        });
    }, [ordersList, columnData]);

    const renderTableBody = (): JSX.Element | JSX.Element[] => {
        // if just the orders api is running, render only a loading table body
        if (ordersListStatus === ApiStatus.Idle || ordersListStatus === ApiStatus.Loading) {
            return <DataListLoading columnData={columnData} staticColumns={[]} />;
        }

        if (ordersList.length === 0) {
            return (
                <TableBody>
                    <DataListNoResults
                        message='No Active Orders Found'
                        colSpan={columnData.length + 1}
                    />
                </TableBody>
            );
        }

        return (
            <TableBody>
                {memoizedTableRows}
            </TableBody>
        );
    };

    return (
        <StyledTableContainer filtersName={filtersName} shareType={shareType} className={classes.ordersTableWrapper}>
            <Table
                stickyHeader
                aria-labelledby='tableTitle'
                size='small'
                aria-label='order table'
                data-qa='orderList-table'
            >
                <TableHead>
                    <TableRow>
                        {
                            columnData.map((column): JSX.Element => {
                                return (
                                    <TableColumnHeader
                                        key={column.field}
                                        column={column}
                                        order={sortOrder}
                                        orderBy={orderBy}
                                        handleWidthChange={handleWidthChange}
                                        handleRequestSort={handleRequestSort}
                                    />
                                );
                            })
                        }
                    </TableRow>
                </TableHead>
                {renderTableBody()}
            </Table>
        </StyledTableContainer>
    );
};

export default OrderListTable;
