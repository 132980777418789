import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import {
    Tabs,
    Tab,
    Typography,
    Grid,
    Button,
    CircularProgress,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { updatePreferences, resetPreferences } from '../../redux/organization';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import HorizontallyCenteredSpinner from '../loaders/horizontallyCenteredSpinner';
import ErrorPage from '../errors/errorPage';

import DeliveryStatusRulesCard from '../cards/statusCards/deliveryStatusRulesCard';
import DropTrailerRulesPickupCard from '../cards/statusCards/dropTrailerRulesPickupCard';
import DropTrailerRulesDelveryCard from '../cards/statusCards/dropTrailerRulesDeliveryCard';
import DeliveryStatusDisplayCard from '../cards/statusCards/deliveryStatusDisplayCard';
import AutoSetShipmentsToDeliveredCard from '../cards/statusCards/autoSetShipmentsToDeliveredCard';
import DeliveryStatusGeofenceCard from '../cards/statusCards/deliveryStatusGeofenceCard';
import TrackingLostRuleCard from '../cards/statusCards/trackingLostRuleCard';
import StopArrivalDepartureDefaultCard from '../cards/statusCards/stopArrivalDepartureDefaultCard';

import EtaDisplayCard from '../cards/etaCards/etaDisplayCard';
import DefaultDwellTimeForStopsCard from '../cards/etaCards/defaultDwellTimeForStopsCard';
import AppointmentTimeAsEtaCard from '../cards/etaCards/appointmentTimeAsEtaCard';
import EtaBaselineCard from '../cards/etaCards/etaBaselineCard';
import EtaBaselinePriorityCard from '../cards/etaCards/etaBaselinePriorityCard';

import ApplicationColorsCard from '../cards/appearanceCards/applicationColorsCard';
import DefaultSortOrderCard from '../cards/appearanceCards/defaultSortOrderCard';
import CustomizeLogoCard from '../cards/appearanceCards/customizeLogoCard';

import AutoRefreshPreferencesCard from '../cards/generalCards/autoRefreshPreferencesCard';
import ShipmentExpirationCard from '../cards/generalCards/shipmentExpirationCard';
import DeliveredShipmentsToHistoryPageCard from '../cards/generalCards/deliveredShipmentsToHistoryPageCard';
import ManualShipmentCreationCard from '../cards/generalCards/manualShipmentCreationCard';
import TemperatureAlertsCard from '../cards/generalCards/temperatureAlertsCard';
import SuggestedRoutesCard from '../cards/generalCards/suggestedRoutesCard';

import UnsavedChangesDialog from '../dialogs/unsavedChangesDialog';

enum PreferenceTabNames {
    status,
    eta,
    appearance,
    general
}

const classesPrefix = 'preferencesPage';

const classes = {
    pageWrapper: `${classesPrefix}-pageWrapper`,
    preferencesWrapper: `${classesPrefix}-preferencesWrapper`,
    preferencesDescription: `${classesPrefix}-preferencesDescription`,
    preferencesUpdated: `${classesPrefix}-preferencesUpdated`,
    actionsContainer: `${classesPrefix}-actionsContainer`,
    button: `${classesPrefix}-button`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.pageWrapper}`]: {
            height: 'calc(100vh - 48px)',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        [`& .${classes.preferencesWrapper}`]: {
            padding: '0 36px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto'
        },
        [`& .${classes.preferencesDescription}`]: {
            textAlign: 'center',
            marginTop: '16px',
            marginBottom: '8px'
        },
        [`& .${classes.preferencesUpdated}`]: {
            textAlign: 'center',
            fontStyle: 'italic'
        },
        [`& .${classes.actionsContainer}`]: {
            padding: '8px 16px',
            borderTop: `1px solid ${theme.palette.divider}`
        },
        [`& .${classes.button}`]: {
            marginRight: '8px'
        },
        [theme.breakpoints.down('lg')]: {
            [`& .${classes.preferencesWrapper}`]: {
                padding: '8px 0'
            }
        }
    };
});

const PreferencesPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const scrollableTabs = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeTabName, setActiveTabName] = useState<PreferenceTabNames>(PreferenceTabNames.status);
    const [canSavePreferences, setCanSavePreferences] = useState(false);
    const [unsavedChangesDialogIsOpen, setUnsavedChangesDialogIsOpen] = useState(false);

    // status tabs cards
    const [isDeliveryStatusRulesCardValid, setIsDeliveryStatusRulesCardValid] = useState(true);
    const [isDropTrailerRulesPickupCardValid, setIsDropTrailerRulesPickupCardValid] = useState(true);
    const [isDropTrailerRulesDeliveryCardValid, setIsDropTrailerRulesDeliveryCardValid] = useState(true);
    const [isDeliveryStatusGeofenceCardValid, setIsDeliveryStatusGeofenceCardValid] = useState(true);
    const [isTrackingLostRuleCardValid, setIsTrackingLostRuleCardValid] = useState(true);

    // eta cards
    const [isDefaultDwellTimeForStopsCardValid, setIsDefaultDwellTimeForStopsCardValid] = useState(true);
    const [isEtaBaselineCardValid, setIsEtaBaselineCardValid] = useState(true);
    const [isEtaBaselinePriorityCardValid, setIsEtaBaselinePriorityCardValid] = useState(true);

    // general cards
    const [isTemperatureAlertsCardValid, setIsTemperatureAlertsCardValid] = useState(true);

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const organizationPreferencesUpdateStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesUpdateStatus; });
    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    useEffect((): void => {
        const isLoading = organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading ||
            organizationPreferencesUpdateStatus === ApiStatus.Loading;

        const isStatusTabValid = isDeliveryStatusRulesCardValid && isDropTrailerRulesPickupCardValid && isDropTrailerRulesDeliveryCardValid && isDeliveryStatusGeofenceCardValid && isTrackingLostRuleCardValid;
        const isEtaTabValid = isDefaultDwellTimeForStopsCardValid && isEtaBaselineCardValid && isEtaBaselinePriorityCardValid;
        const isGeneralTabValid = isTemperatureAlertsCardValid;

        const canSave = !isEqual(organizationPreferences, organizationEditablePreferences) && !isLoading && isStatusTabValid && isEtaTabValid && isGeneralTabValid;
        setCanSavePreferences(canSave);
    }, [
        organizationPreferencesStatus,
        organizationPreferences,
        organizationPreferencesUpdateStatus,
        organizationEditablePreferences,
        // status cards
        isDeliveryStatusRulesCardValid,
        isDropTrailerRulesPickupCardValid,
        isDropTrailerRulesDeliveryCardValid,
        isDeliveryStatusGeofenceCardValid,
        isTrackingLostRuleCardValid,
        // eta cards
        isDefaultDwellTimeForStopsCardValid,
        isEtaBaselineCardValid,
        isEtaBaselinePriorityCardValid,
        // general cards
        isTemperatureAlertsCardValid
    ]);

    const handleTabChange = (event: React.SyntheticEvent, tabName: PreferenceTabNames): void => {
        setActiveTabName(tabName);
    };

    // Status tab cards
    const handleIsDeliveryStatusRulesCardValidChange = (isValid: boolean): void => {
        setIsDeliveryStatusRulesCardValid(isValid);
    };

    const handleIsDropTrailerRulesPickupCardValidChange = (isValid: boolean): void => {
        setIsDropTrailerRulesPickupCardValid(isValid);
    };

    const handleIsDropTrailerRulesDeliveryCardValidChange = (isValid: boolean): void => {
        setIsDropTrailerRulesDeliveryCardValid(isValid);
    };

    const handleIsDeliveryStatusGeofenceCardValidChange = (isValid: boolean): void => {
        setIsDeliveryStatusGeofenceCardValid(isValid);
    };

    const handleIsTrackingLostRuleCardValidChange = (isValid: boolean): void => {
        setIsTrackingLostRuleCardValid(isValid);
    };

    // eta cards
    const handleIsDefaultDwellTimeForStopsCardValidChange = (isValid: boolean): void => {
        setIsDefaultDwellTimeForStopsCardValid(isValid);
    };

    const handleIsEtaBaselineCardValidChange = (isValid: boolean): void => {
        setIsEtaBaselineCardValid(isValid);
    };

    const handleIsEtaBaselinePriorityCardValidChange = (isValid: boolean): void => {
        setIsEtaBaselinePriorityCardValid(isValid);
    };

    // general cards
    const handleIsTemperatureAlertsCardValidChange = (isValid: boolean): void => {
        setIsTemperatureAlertsCardValid(isValid);
    };

    // preferences actions
    const handleCancelPreferencesClick = (): void => {
        if (canSavePreferences) {
            setUnsavedChangesDialogIsOpen(true);
        } else {
            dispatch(resetPreferences());
        }
    };

    const handleSavePreferencesClick = (): void => {
        if (organizationPreferences && organizationEditablePreferences) {
            dispatch(updatePreferences(organizationPreferences, organizationEditablePreferences));
        }
    };

    if (organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading) {
        return <HorizontallyCenteredSpinner loadingText='Loading Preferences...' />;
    }

    if (organizationPreferences === null || organizationPreferencesStatus === ApiStatus.Failure) {
        return (
            <ErrorPage errorHeaderText='Preferences Error'>
                <Typography data-qa='preferencesError'>
                    Unable to locate preferences.
                </Typography>
            </ErrorPage>
        );
    }

    return (
        <StyledDiv className={classes.pageWrapper}>
            <Tabs
                value={activeTabName}
                onChange={handleTabChange}
                indicatorColor='primary'
                textColor='primary'
                centered={!scrollableTabs}
                variant={scrollableTabs ? 'scrollable' : 'standard'}
                scrollButtons='auto'
            >
                <Tab
                    label='Status'
                    value={PreferenceTabNames.status}
                    data-qa='preferences-statusTab'
                />
                <Tab
                    label='ETA'
                    value={PreferenceTabNames.eta}
                    data-qa='preferences-statusTab'
                />
                <Tab
                    label='Appearance'
                    value={PreferenceTabNames.appearance}
                    data-qa='preferences-appearanceTab'
                />
                <Tab
                    label='General'
                    value={PreferenceTabNames.general}
                    data-qa='preferences-generalTab'
                />
            </Tabs>

            <div className={classes.preferencesWrapper}>
                <Typography variant='body2' className={classes.preferencesDescription}>
                    PLEASE NOTE: Any changes made to these Preferences will impact your entire organization, along with any associated shared and restricted views.
                    In addition, the Dashboard metrics and reports for your organization will also be affected.
                </Typography>
                {
                    organizationEditablePreferences.lastEditDate &&
                    <Typography variant='body2' className={classes.preferencesUpdated}>
                        {
                            `Last updated ${zonedDateTimeToDisplay(organizationEditablePreferences.lastEditDate)}
                                ${organizationEditablePreferences.lastEditedEmailAddress ? ` by ${organizationEditablePreferences.lastEditedEmailAddress}` : ''}`
                        }
                    </Typography>
                }

                {
                    activeTabName === PreferenceTabNames.status &&
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <DeliveryStatusRulesCard handleIsCardValidChange={handleIsDeliveryStatusRulesCardValidChange} />
                            <DropTrailerRulesPickupCard handleIsCardValidChange={handleIsDropTrailerRulesPickupCardValidChange} />
                            <DropTrailerRulesDelveryCard handleIsCardValidChange={handleIsDropTrailerRulesDeliveryCardValidChange} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DeliveryStatusDisplayCard />
                            <AutoSetShipmentsToDeliveredCard />
                            <DeliveryStatusGeofenceCard handleIsCardValidChange={handleIsDeliveryStatusGeofenceCardValidChange} />
                            <TrackingLostRuleCard handleIsCardValidChange={handleIsTrackingLostRuleCardValidChange} />
                            <StopArrivalDepartureDefaultCard />
                        </Grid>
                    </Grid>
                }
                {
                    activeTabName === PreferenceTabNames.eta &&
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <EtaDisplayCard />
                            <DefaultDwellTimeForStopsCard handleIsCardValidChange={handleIsDefaultDwellTimeForStopsCardValidChange} />
                            <AppointmentTimeAsEtaCard />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EtaBaselineCard handleIsCardValidChange={handleIsEtaBaselineCardValidChange} />
                            <EtaBaselinePriorityCard handleIsCardValidChange={handleIsEtaBaselinePriorityCardValidChange} />
                        </Grid>
                    </Grid>
                }
                {
                    activeTabName === PreferenceTabNames.appearance &&
                    <Grid container>
                        <Grid item xs={12}>
                            <ApplicationColorsCard />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DefaultSortOrderCard />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomizeLogoCard />
                        </Grid>
                    </Grid>
                }
                {
                    activeTabName === PreferenceTabNames.general &&
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <AutoRefreshPreferencesCard />
                            <ShipmentExpirationCard />
                            <DeliveredShipmentsToHistoryPageCard />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ManualShipmentCreationCard />
                            <TemperatureAlertsCard handleIsCardValidChange={handleIsTemperatureAlertsCardValidChange} />
                            <SuggestedRoutesCard />
                        </Grid>
                    </Grid>
                }
            </div>

            <Grid container justifyContent='center' className={classes.actionsContainer}>
                <Grid item>
                    <Button className={classes.button} onClick={handleCancelPreferencesClick} data-qa='cancel-button'>Cancel</Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={!canSavePreferences}
                        onClick={handleSavePreferencesClick}
                        startIcon={organizationPreferencesUpdateStatus === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='savePreferences-button'
                    >
                        {
                            organizationPreferencesUpdateStatus === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Grid>
            </Grid>

            {
                unsavedChangesDialogIsOpen &&
                <UnsavedChangesDialog
                    isOpen={unsavedChangesDialogIsOpen}
                    handleClose={(): void => {
                        setUnsavedChangesDialogIsOpen(false);
                    }}
                    handleContinue={(): void => {
                        dispatch(resetPreferences());
                        setUnsavedChangesDialogIsOpen(false);
                    }}
                />
            }
        </StyledDiv>
    );
};

export default PreferencesPage;
