import {
    GeoJsonTypes,
    Point,
    Polygon,
    Feature
} from 'geojson';

import { OrderDetailsData } from '../../../interfaces/services/orderDetails';
import { formatCityRegionPostalCountry } from '../../addressUtils';
import { zonedDateTimeToDisplay } from '../../dateUtils';
import { getDeliveryStatusColor } from '../../styleHelpers';
import { GeoFenceFeatureProperties } from '../layers/interfaces';
import { getGeofenceFeaturePointsForStop } from '../mapUtils';
import { MapSources, MapImages, OrderDetailsFeatureTypes } from '../enums';

/** Interface used for the properties object on the Order Details Map Source. */
export interface OrderDetailsMapSourceProperties extends GeoFenceFeatureProperties<OrderDetailsFeatureTypes> {
    id: string;
    stopSequence?: number;
    title?: string;
    image?: MapImages;
    textColor?: string;
    iconOffset?: number[];
    textOffset?: number[];
    geofenceFeaturePoints?: Feature<Polygon, GeoFenceFeatureProperties<OrderDetailsFeatureTypes>> | undefined;
    description?: string;
    hasPulse?: boolean;
}

/** Interface used to define the Order Details Map Source. */
export interface OrderDetailsMapSource {
    type: 'geojson';
    data: {
        type: GeoJsonTypes;
        features: Feature<Point | Polygon, OrderDetailsMapSourceProperties>[];
    };
}

/**
 * Using the stop information, it will add a Point feature to the source.
 * Using the currentPosition object, if available, it will add a Point feature to the source.
 * Returns a geojson source to be added to the map.
 * @param orders The orders to show on the map
 */
export const getOrderDetailsSource = (order: OrderDetailsData): OrderDetailsMapSource => {
    const orderDetailsFeatures: Feature<Point | Polygon, OrderDetailsMapSourceProperties>[] = [];

    for (let i = 0; i < order.stops.length; i++) {
        const currentStop = order.stops[i];
        let title;
        let id;
        let image;
        let textColor;

        // Add pickup and delivery stop markers
        if (i === 0) {
            title = 'P';
            id = 'stopPickup';
            image = MapImages.originDestinationMapMarker;
            textColor = '#ffffff';
        } else {
            title = 'D';
            id = 'stopDelivery';
            image = MapImages.originDestinationMapMarker;
            textColor = '#ffffff';
        }

        const stopLongLat = [currentStop.longitude, currentStop.latitude];

        const geofenceFeature = getGeofenceFeaturePointsForStop(currentStop, OrderDetailsFeatureTypes.Geofence);
        if (geofenceFeature) {
            orderDetailsFeatures.push(geofenceFeature);
        }
        orderDetailsFeatures.push({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: stopLongLat
            },
            properties: {
                id,
                featureType: OrderDetailsFeatureTypes.OrderStop,
                stopSequence: currentStop.stopSequence,
                title,
                image,
                textColor,
                iconOffset: [0, -32],
                textOffset: [0, -1.9],
                ...geofenceFeature && { geofenceFeaturePoints: geofenceFeature }
            }
        });
    }

    const { currentPosition } = order;

    if (currentPosition &&
        currentPosition.latitude !== null && currentPosition.longitude !== null
    // FIXME: when the API returns the shipmentStatus we can use these conditions, but for now it is okay to continue showing the current position
    // shipment.shipmentStatus !== ShipmentStatus.Delivered &&
    // shipment.shipmentStatus !== ShipmentStatus.Completed &&
    // shipment.shipmentStatus !== ShipmentStatus.Cancelled
    ) {
        const currentPositionLongLat = [currentPosition.longitude, currentPosition.latitude];

        const tooltipHtml = `
                <div class='mapTooltip'>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'><strong>Nearest Location:</strong></div>
                        <div class='mapTooltipCell'>
                            <strong>
                                ${formatCityRegionPostalCountry({ city: currentPosition.cityName, region: currentPosition.stateCode })}
                            </strong>
                        </div>
                    </div>
                    <div class='mapTooltipRow'>
                        <div class='mapTooltipCell'>Date/Time:</div>
                        <div class='mapTooltipCell'>${zonedDateTimeToDisplay(currentPosition.positionEventDateTime)}</div>
                    </div>
                </div>
            `;

        orderDetailsFeatures.push({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: currentPositionLongLat
            },
            properties: {
                id: 'currentPosition',
                featureType: OrderDetailsFeatureTypes.CurrentPosition,
                description: tooltipHtml,
                color: getDeliveryStatusColor(order.stops[0].deliveryStatus),
                hasPulse: true
            }
        });
    }

    const orderDetailsStopsSource: OrderDetailsMapSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: orderDetailsFeatures
        }
    };

    return orderDetailsStopsSource;
};

/**
* Adds the order details source to the map if it doesn't already exist
 * @param map Instance of your map
 * @param order The order details to show on the map
*/
export const addOrderDetailsSource = (map: any, order: OrderDetailsData): OrderDetailsMapSource => {
    const orderDetailsSource = getOrderDetailsSource(order);

    if (map.getSource(MapSources.orderDetailsSource) === undefined) {
        map.addSource(MapSources.orderDetailsSource, orderDetailsSource);
    } else {
        map.getSource(MapSources.orderDetailsSource).setData(orderDetailsSource.data);
    }

    return orderDetailsSource;
};
