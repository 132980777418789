import { combineReducers, Action } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage/session';

/* V8 Redux more correctly defines action dispatch type for redux-thunk, which only allows for Action objects.
*  Unless we switch to React Toolkit and define our typed hooks, we need to import the types needed for
*  our thunks to be correctly typed. Issue Link: https://github.com/reduxjs/redux-thunk/issues/333#issuecomment-1109308664 */
import type {} from 'redux-thunk/extend-redux';

import { analyticsReducer } from './analytics';
import { availableServicesReducer } from './serviceLocator';
import { calendarReducer } from './calendar';
import { carrierContactsReducer } from './carrierContacts';
import { dialogUpdatesReducer } from './dialogUpdates';
import { dimensionsReducer } from './dimensions';
import { driversReducer } from './drivers';
import { locationDetailsReducer } from './locationDetails';
import { notificationReducer } from './notifications';
import { locationReducer } from './locations';
import { orderDetailsReducer } from './orderDetails';
import { ordersReducer } from './orders';
import { organizationReducer } from './organization';
import { shipmentDetailsReducer } from './shipmentDetails';
import { shipmentListReducer } from './shipments';
import { tripDetailsReducer } from './tripDetails';
import { userReducer } from './user';
import { viewsReducer } from './views';

const EMPTY_STORE = 'EMPTY_STORE';

const appReducer = combineReducers({
    analytics: analyticsReducer,
    availableServices: availableServicesReducer,
    calendar: calendarReducer,
    carrierContacts: carrierContactsReducer,
    dialogUpdates: dialogUpdatesReducer,
    dimensions: dimensionsReducer,
    drivers: driversReducer,
    locationDetails: locationDetailsReducer,
    locations: locationReducer,
    notifications: notificationReducer,
    orderDetails: orderDetailsReducer,
    orders: ordersReducer,
    organization: organizationReducer,
    shipmentDetails: shipmentDetailsReducer,
    shipments: shipmentListReducer,
    tripDetails: tripDetailsReducer,
    user: userReducer,
    views: viewsReducer
});

type AppState = ReturnType<typeof appReducer>;

export const emptyReduxStore = (): Action<typeof EMPTY_STORE> => {
    return {
        type: EMPTY_STORE
    };
};

export const rootReducer = (state: any, action: any): AppState => {
    switch (action.type) {
        case EMPTY_STORE: {
            storage.removeItem('persist:transportation_ui');
            return appReducer(undefined, action);
        }
        default:
            return appReducer(state, action);
    }
};

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export interface GenericAction<T, P> {
    type: T;
    payload: P;
}
