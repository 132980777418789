import { ShipmentData } from '../../../interfaces/services/shipmentDetails';
import { StopType } from '../../enums';
import { MapSources } from '../enums';
import { CustomStopData, ShipmentDetailsMapSource, getShipmentDetailsSource } from './shipmentDetails';

/**
* Adds the trip details source to the map if it doesn't already exist
 * @param map Instance of your map
 * @param shipments The shipments to show on the map
*/
export const addTripDetailsSource = (map: any, shipments: ShipmentData[]): ShipmentDetailsMapSource => {
    const allShipmentStops = (): CustomStopData[] => {
        // if there is just one shipment, return all of the stops on the shipment
        if (shipments.length === 1) {
            return shipments[0].stops.map((stop): CustomStopData => {
                return {
                    ...stop,
                    shipmentId: shipments[0].freightProviderReferenceNumber
                };
            });
        }

        // if there are multiple shipments, use the below logic to create a list of shipment stops:
        // If it's the first shipment in the list, return the origin stop with the StopType of Origin
        // If it's any other shipment in the list, return the origin stop, with the StopType of Intermediate
        // If it's the last shipment in the list, also return the destination stop with the StopType of Destination
        return shipments.reduce((filtered: CustomStopData[], shipment: ShipmentData, currentIndex: number) => {
            const [originStop, destinationStop] = shipment.stops.filter((stop): boolean => {
                return stop.stopType === StopType.Origin || stop.stopType === StopType.Destination;
            });

            filtered.push({
                ...originStop,
                stopSequence: currentIndex + 1,
                stopType: currentIndex === 0 ? StopType.Origin : StopType.Intermediate,
                shipmentId: shipment.freightProviderReferenceNumber
            });

            if (currentIndex === shipments.length - 1) {
                filtered.push({
                    ...destinationStop,
                    stopSequence: currentIndex + 1,
                    shipmentId: shipment.freightProviderReferenceNumber
                });
            }

            return filtered;
        }, []);
    };

    const tripDetailsSource = getShipmentDetailsSource(shipments, allShipmentStops());

    if (map.getSource(MapSources.tripDetailsSource) === undefined) {
        map.addSource(MapSources.tripDetailsSource, tripDetailsSource);
    } else {
        map.getSource(MapSources.tripDetailsSource).setData(tripDetailsSource.data);
    }

    return tripDetailsSource;
};
