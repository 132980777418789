import React from 'react';
import {
    Card,
    CardContent,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiEyeOutline, mdiEyeOffOutline, mdiCellphoneWireless, mdiSteering } from '@mdi/js';

import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { Note, StopNote } from '../../interfaces/services/shipmentDetails';

const classesPrefix = 'noteCard';

const classes = {
    noteContainer: `${classesPrefix}-noteContainer`,
    notePrivateContainer: `${classesPrefix}-notePrivateContainer`,
    noteDetails: `${classesPrefix}-noteDetails`,
    noteIconLabel: `${classesPrefix}-noteIconLabel`,
    stopLabel: `${classesPrefix}-stopLabel`
};

const StyledCard = styled(Card)(({ theme }) => {
    return {
        [`&.${classes.noteContainer}`]: {
            marginBottom: '8px'
        },
        [`&.${classes.notePrivateContainer}`]: {
            backgroundColor: theme.palette.action.focus
        },
        [`& .${classes.noteDetails}`]: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: '8px'
        },
        [`& .${classes.noteIconLabel}`]: {
            paddingLeft: '8px',
            paddingRight: '8px',
            flexGrow: 1
        },
        [`& .${classes.stopLabel}`]: {
            fontWeight: 600
        }
    };
});

const NoteCard = ({
    note,
    stopLabel
}: {
    note: StopNote | Note;
    stopLabel?: string;
}): JSX.Element => {
    const theme = useTheme();

    return (
        <StyledCard className={`${classes.noteContainer} ${note.isShared ? '' : classes.notePrivateContainer}`} variant='outlined' data-qa={`shipmentNote-${note.noteGuid}`}>
            <CardContent>
                {
                    stopLabel &&
                    <Typography className={classes.stopLabel} data-qa='stopTypeLabel'>
                        {stopLabel}
                    </Typography>
                }
                <Typography variant='body2' component='p' data-qa='shipmentNote-text'>
                    {note.text}
                </Typography>
                <div className={classes.noteDetails}>
                    <Icon
                        path={note.isShared ? mdiEyeOutline : mdiEyeOffOutline}
                        size='20px'
                        color={note.isShared ? theme.palette.text.secondary : theme.palette.text.primary}
                        data-qa='visibility-icon'
                    />
                    <Typography className={classes.noteIconLabel} color={note.isShared ? 'textSecondary' : 'textPrimary'} component='h4' data-qa='shipmentNote-visibility'>
                        {note.isShared ? 'PUBLIC' : 'PRIVATE'}
                    </Typography>
                    <Typography color={note.isShared ? 'textSecondary' : 'textPrimary'} variant='subtitle2' data-qa='shipmentNote-createdDate'>
                        {zonedDateTimeToDisplay(note.createdDate)}
                    </Typography>
                </div>
                {
                    note.noteSentToDriver &&
                    <div className={classes.noteDetails}>
                        <Icon
                            path={mdiCellphoneWireless}
                            size='20px'
                            color={theme.palette.text.secondary}
                            data-qa='noteSentToDriver-icon'
                        />
                        <Typography className={classes.noteIconLabel} color='textSecondary' component='h4' data-qa='shipmentNote-sentToDriver'>
                            Sent to Driver
                        </Typography>
                    </div>
                }
                {
                    'driverAdded' in note && note.driverAdded &&
                    <div className={classes.noteDetails}>
                        <Icon
                            path={mdiSteering}
                            size='20px'
                            color={theme.palette.text.secondary}
                            data-qa='driverAdded-icon'
                        />
                        <Typography className={classes.noteIconLabel} color='textSecondary' component='h4' data-qa='stopNote-driverAdded'>
                            Driver Note
                        </Typography>
                    </div>
                }
            </CardContent>
        </StyledCard>
    );
};

export default NoteCard;
