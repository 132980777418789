import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import LabelValuePairLoading from './labelValuePairLoading';

const classesPrefix = 'driverDetailsLoading';

const classes = {
    sectionHeader: `${classesPrefix}-sectionHeader`
};

const StyledTypography = styled(Typography)(({ theme }) => {
    return {
        [`&.${classes.sectionHeader}`]: {
            fontSize: '12px'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.sectionHeader}`]: {
                marginTop: '8px'
            }
        }
    };
});

const DriverDetailsLoading = (): JSX.Element => {
    return (
        <Fragment>
            <StyledTypography className={classes.sectionHeader} color='textSecondary'>
                Driver Info
            </StyledTypography>
            <LabelValuePairLoading label='Name' width='80px' />
            <LabelValuePairLoading label='Phone' width='100px' />
            <LabelValuePairLoading label='Email' width='150px' />
            <LabelValuePairLoading label='Mobile Tracking Number' width='100px' />
        </Fragment>
    );
};

export default DriverDetailsLoading;
