import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControlLabel,
    Typography,
    RadioGroup,
    Radio,
    Tooltip
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'deliveryStatusDisplayCard';

const classes = {
    informationIcon: `${classesPrefix}-informationIcon`
};

const StyledRadioGroup = styled(RadioGroup)(() => {
    return {
        [`& .${classes.informationIcon}`]: {
            verticalAlign: 'middle',
            marginLeft: '4px'
        }
    };
});

const DeliveryStatusDisplayCard = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleDeliveryStatusDisplayChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value === 'true';
        dispatch(editPreferences(PreferencesFieldNames.displayDeliveryStatusToDestination, value));
    };

    return (
        <PreferenceCard
            header='Delivery Status Display'
            informationText='This will be the delivery status shown on the shipments page on the shipment list to the left and the delivery status toggle. When you open a shipment you will see delivery status per stop.'
        >
            <Typography component='p' variant='body2'>
                What delivery Status should be shown for your shipments?
            </Typography>

            <StyledRadioGroup
                aria-label='deliveryStatusDisplay'
                name='deliveryStatusDisplay'
                value={String(organizationEditablePreferences.displayDeliveryStatusToDestination)}
                onChange={handleDeliveryStatusDisplayChange}
            >
                <FormControlLabel
                    value='true'
                    control={<Radio color='primary' />}
                    label='Delivery Status to the final Destination'
                    data-qa='finalDestination-radio'
                />
                <FormControlLabel
                    value='false'
                    control={<Radio color='primary' />}
                    label={
                        <Fragment>
                            Delivery Status to the active stop
                            <Tooltip
                                title='The active stop is the current stop the driver is at or on his way to. Once an actual departure time is recorded it will no long be active.'
                                aria-label='info'
                            >
                                <Icon
                                    className={classes.informationIcon}
                                    path={mdiInformation}
                                    size={1}
                                    color={theme.palette.text.secondary}
                                />
                            </Tooltip>
                        </Fragment>
                    }
                    data-qa='activeStop-radio'
                />
            </StyledRadioGroup>
        </PreferenceCard>
    );
};

export default DeliveryStatusDisplayCard;
