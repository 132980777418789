import { createTransform } from 'redux-persist';
import { ApiStatus } from '../helpers/enums';
import { RootState } from '.';
import { initialUserInformationState } from './user/initialState';

const TransformState = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: RootState) => {
        // return state as is
        return inboundState;
    },
    (outboundState: RootState, key) => {
        // all API statuses should be reset to initial idle status on re-hydrate
        switch (key) {
            case 'calendar': {
                return {
                    ...outboundState,
                    shipmentListStatus: ApiStatus.Idle
                };
            }
            case 'dialogUpdates': {
                return {
                    ...outboundState,
                    // reset the error message as well just to make sure it isn't shown again
                    errorMessage: '',
                    status: ApiStatus.Idle
                };
            }
            case 'dimensions': {
                return {
                    ...outboundState,
                    orderMapLegendFilteredStatus: ApiStatus.Idle,
                    orderMapLegendStatus: ApiStatus.Idle,
                    shipmentMapLegendFilteredStatus: ApiStatus.Idle,
                    shipmentMapLegendStatus: ApiStatus.Idle,
                    summaryCountsStatus: ApiStatus.Idle
                };
            }
            case 'drivers': {
                return {
                    ...outboundState,
                    driverDetailsStatus: ApiStatus.Idle
                };
            }
            case 'locationDetails': {
                return {
                    ...outboundState,
                    fixedLocationDetailsStatus: ApiStatus.Idle
                };
            }
            case 'locations': {
                return {
                    ...outboundState,
                    locationsListStatus: ApiStatus.Idle,
                    locationDetailsStatus: ApiStatus.Idle,
                    locationDetailsUpdateStatus: ApiStatus.Idle
                };
            }
            case 'notifications': {
                return {
                    ...outboundState,
                    notificationsListStatus: ApiStatus.Idle,
                    notificationDetailsStatus: ApiStatus.Idle,
                    notificationDetailsUpdateStatus: ApiStatus.Idle
                };
            }
            case 'organization': {
                return {
                    ...outboundState,
                    organizationInformationStatus: ApiStatus.Idle,
                    // reset the preferences because they are used to render data based on the users preferences and should not be re-hydrated
                    organizationPreferences: null,
                    organizationPreferencesStatus: ApiStatus.Idle,
                    organizationPreferencesUpdateStatus: ApiStatus.Idle
                };
            }
            case 'shipments': {
                return {
                    ...outboundState,
                    shipmentListStatus: ApiStatus.Idle,
                    isExporting: false
                };
            }
            case 'user': {
                return {
                    ...outboundState,
                    // reset user information as well because it is used for permissions and should not be re-hydrated
                    userInformation: initialUserInformationState,
                    shareType: null,
                    userInformationStatus: ApiStatus.Idle,
                    userSettingsStatus: ApiStatus.Idle,
                    userSettingsUpdateStatus: ApiStatus.Idle
                };
            }
            case 'views': {
                return {
                    ...outboundState,
                    viewsListStatus: ApiStatus.Idle
                };
            }
            default:
                return outboundState;
        }
    },
    // define which reducers this transform gets called for.
    {
        // README: 'availableServices', 'shipmentDetails', and 'tripDetails' reducers are blacklisted in store.ts as we have no reason to persist the data.
        // additionally 'shipmentDetails' and 'tripDetails' consume a large amount of sessionStorage which can lead to the persist failing
        whitelist: [
            'calendar',
            'dialogUpdates',
            'dimensions',
            'drivers',
            'locationDetails',
            'notifications',
            'organization',
            'shipments',
            'user',
            'views'
        ]
    }
);

export default TransformState;
