import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { initialOrderDetailsState } from './initialState';
import { OrderDetailsData } from '../../interfaces/services/orderDetails';
import { GenericApiResponse } from '../../interfaces/services';

const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';

interface OrderData {
    orderDetailsStatus: ApiStatus;
    order: OrderDetailsData;
}

const requestOrderDetails = (): Action<typeof ORDER_DETAILS_REQUEST> => {
    return {
        type: ORDER_DETAILS_REQUEST
    };
};

const receiveOrderDetails = (json: OrderDetailsData): GenericAction<typeof ORDER_DETAILS_SUCCESS, OrderDetailsData> => {
    return {
        type: ORDER_DETAILS_SUCCESS,
        payload: json
    };
};

const requestOrderDetailsFailed = (): Action<typeof ORDER_DETAILS_FAILURE> => {
    return {
        type: ORDER_DETAILS_FAILURE
    };
};

export const fetchOrderDetails = (orderGuid: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestOrderDetails());

        try {
            const json = await ApiService.get({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.orderDetails}/${orderGuid}`
            }) as GenericApiResponse<OrderDetailsData>;
            dispatch(receiveOrderDetails(json.data[0]));
        } catch (error) {
            dispatch(requestOrderDetailsFailed());
            toast.error('Error occurred while fetching order details.');
        }

    };
};

type OrderDetailsActionTypes = ReturnType<typeof requestOrderDetails> | ReturnType<typeof receiveOrderDetails> | ReturnType<typeof requestOrderDetailsFailed>;

export const orderDetailsReducer = (ordersData: OrderData = {
    orderDetailsStatus: ApiStatus.Idle,
    order: initialOrderDetailsState
}, action: OrderDetailsActionTypes): OrderData => {
    switch (action.type) {
        case ORDER_DETAILS_REQUEST: {
            return {
                ...ordersData,
                orderDetailsStatus: ApiStatus.Loading
            };
        }
        case ORDER_DETAILS_SUCCESS: {
            return {
                ...ordersData,
                orderDetailsStatus: ApiStatus.Success,
                order: action.payload
            };
        }
        case ORDER_DETAILS_FAILURE: {
            return {
                ...ordersData,
                orderDetailsStatus: ApiStatus.Failure,
                order: initialOrderDetailsState
            };
        }
        default:
            return ordersData;
    }
};
