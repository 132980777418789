import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import Hidden from '../../layouts/hidden';
import LabelValuePairLoading from '../../loaders/labels/labelValuePairLoading';

const classesPrefix = 'tripDetailsCardLoading';

const classes = {
    addressContainer: `${classesPrefix}-addressContainer`,
    stopTitle: `${classesPrefix}-stopTitle`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.addressContainer}`]: {
            marginBottom: '4px'
        },
        [`& .${classes.stopTitle}`]: {
            fontWeight: 600,
            fontSize: '14px',
            paddingBottom: '4px'
        }
    };
});

const TripDetailsCardLoading = (): JSX.Element => {
    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item xs={12} sm='auto'>
                                <LabelValuePairLoading label='Trip Status' width='70px' fontSize='16px' />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item xs='auto'>
                                <LabelValuePairLoading label='Active Shipment' width='120px' fontSize='16px' />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <LabelValuePairLoading label='Active Mode' width='70px' fontSize='16px' />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <LabelValuePairLoading label='Active Carrier' width='120px' fontSize='16px' />
                    </Grid>
                </Grid>

                <Grid container className={classes.addressContainer} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid container direction='column' spacing={1}>
                            <Grid item xs={12}>
                                <Typography className={classes.stopTitle}>Trip Origin</Typography>
                                <Skeleton variant='text' width='80px' />
                                <Skeleton variant='text' width='120px' />
                                <Skeleton variant='text' width='120px' />
                                <Hidden breakpoint='md' direction='up'>
                                    <Skeleton variant='text' width='160px' height='24px' />
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container direction='column' spacing={1}>
                            <Grid item xs={12}>
                                <Typography className={classes.stopTitle}>Trip Destination</Typography>
                                <Skeleton variant='text' width='80px' />
                                <Skeleton variant='text' width='120px' />
                                <Skeleton variant='text' width='120px' />
                                <Hidden breakpoint='md' direction='up'>
                                    <Skeleton variant='text' width='160px' height='24px' />
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden breakpoint='md' direction='down'>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Skeleton variant='text' width='160px' height='24px' />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant='text' width='160px' height='24px' />
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </StyledGrid>
    );
};

export default TripDetailsCardLoading;
