import React from 'react';

import { useTypedSelector } from '../../redux';
import { ModeType, ShareType } from '../../helpers/enums';
import TransparentFullScreenDialog from './common/transparentFullScreenDialog';
import ShipmentDetailsActions from '../actionBars/shipmentDetailsActions';
import ShipmentDetails from '../sections/shipmentDetails';

const ShipmentDetailsDialog = ({
    shipmentUniqueName,
    modeType,
    handleDialogClose
}: {
    /** unique identifier for the shipment */
    shipmentUniqueName: string;
    /** mode type for the shipment */
    modeType: ModeType;
    /** Function triggered by the close action */
    handleDialogClose: () => void;
}): JSX.Element => {
    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const shipmentList = useTypedSelector((state) => { return state.shipments.shipmentList; });

    const selectedShipment = shipmentList.find((shipment) => {
        return shipment.shipmentUniqueName === shipmentUniqueName;
    });

    return (
        <TransparentFullScreenDialog
            headerText={`Shipment ${selectedShipment?.freightProviderReferenceNumber || ''}`}
            handleDialogClose={handleDialogClose}
            additionalActions={shareType === null || shareType === ShareType.CustomerReadOnly ? <ShipmentDetailsActions showShipmentDetailsNavigation /> : undefined}
        >
            <ShipmentDetails
                shipmentUniqueName={shipmentUniqueName}
                modeType={modeType}
            />
        </TransparentFullScreenDialog>
    );
};

export default ShipmentDetailsDialog;
