import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, DeliveryStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { GenericApiResponse } from '../../interfaces/services';
import {
    UPDATE_SHIPMENT_PRIORITY,
    updateShipmentPrioritySuccess
} from '../dialogUpdates';
import {
    TripInformationServiceData,
    TripInformationRouteServiceData,
    TripServiceShipmentData,
    TripInformationData,
    TripShipmentData,
    TripHistoryData
} from '../../interfaces/services/trip';
import { initialTripInformation } from './initialState';
import { determineCurrentMilestoneProgressData } from '../../helpers/shipmentUtils';

const TRIP_INFORMATION_REQUEST = 'TRIP_INFORMATION_REQUEST';
const TRIP_INFORMATION_SUCCESS = 'TRIP_INFORMATION_SUCCESS';
const TRIP_INFORMATION_FAILURE = 'TRIP_INFORMATION_FAILURE';

const TRIP_HISTORY_REQUEST = 'TRIP_HISTORY_REQUEST';
const TRIP_HISTORY_SUCCESS = 'TRIP_HISTORY_SUCCESS';
const TRIP_HISTORY_FAILURE = 'TRIP_HISTORY_FAILURE';

interface Trips {
    tripInformationStatus: ApiStatus;
    tripInformation: TripInformationData;
    tripHistoryStatus: ApiStatus;
    tripHistory: TripHistoryData[];
}

const requestTripInformation = (): Action<typeof TRIP_INFORMATION_REQUEST> => {
    return {
        type: TRIP_INFORMATION_REQUEST
    };
};

const receiveTripInformation = (tripDetails: TripInformationServiceData, tripRoutes: TripInformationRouteServiceData[]): GenericAction<typeof TRIP_INFORMATION_SUCCESS,
    {
        tripDetails: TripInformationServiceData;
        tripRoutes: TripInformationRouteServiceData[];
    }> => {
    return {
        type: TRIP_INFORMATION_SUCCESS,
        payload: {
            tripDetails,
            tripRoutes
        }
    };
};

const requestTripInformationFailed = (): Action<typeof TRIP_INFORMATION_FAILURE> => {
    return {
        type: TRIP_INFORMATION_FAILURE
    };
};

export const fetchTripInformationAndRoute = (tripIdentifier: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestTripInformation());

        const tripDetailsPromise = async (): Promise<TripInformationServiceData | null> => {
            try {
                const json = await ApiService.get({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.tripDetails}/${tripIdentifier}` }) as GenericApiResponse<TripInformationServiceData>;
                if (json.data.length > 0) {
                    return json.data[0];
                }

                throw new Error('Missing Trip Information');
            } catch (err) {
                toast.error('Error occurred while fetching trip information.');
                return null;
            }
        };

        const tripDetailsRoutePromise = async (): Promise<TripInformationRouteServiceData[]> => {
            try {
                const json = await ApiService.get({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.tripDetails}/${tripIdentifier}/route` }) as GenericApiResponse<TripInformationRouteServiceData>;
                if (json.data.length > 0) {
                    return json.data;
                }

                throw new Error('Missing Trip Route Information');
            } catch (err) {
                toast.error('Error occurred while fetching trip route information.');

                return [];
            }
        };

        Promise.all([
            tripDetailsPromise(),
            tripDetailsRoutePromise()
        ]).then(([
            tripDetails,
            tripDetailsRoutes
        ]): void => {
            // Since the promise can either return the data or null, we will check that it's not null before calling it a success
            if (tripDetails !== null) {
                dispatch(receiveTripInformation(tripDetails, tripDetailsRoutes));
            } else {
                // If it is null, we will trigger the failure for the trip information so that the error page will render
                dispatch(requestTripInformationFailed());
            }
        });
    };
};

const requestTripHistory = (): Action<typeof TRIP_HISTORY_REQUEST> => {
    return {
        type: TRIP_HISTORY_REQUEST
    };
};

const receiveTripHistory = (data: TripHistoryData[]): GenericAction<typeof TRIP_HISTORY_SUCCESS, TripHistoryData[]> => {
    return {
        type: TRIP_HISTORY_SUCCESS,
        payload: data
    };
};

const requestTripHistoryFailed = (): Action<typeof TRIP_HISTORY_FAILURE> => {
    return {
        type: TRIP_HISTORY_FAILURE
    };
};

export const fetchTripHistory = (tripIdentifier: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestTripHistory());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.tripHistory}/${tripIdentifier}/history` }) as GenericApiResponse<TripHistoryData>;

            const { data } = json;
            dispatch(receiveTripHistory(data));
        } catch (err) {
            dispatch(requestTripHistoryFailed());
            toast.error('Error occurred while fetching trip history.');
        }
    };
};

type TripActionTypes =
    ReturnType<typeof requestTripInformation> | ReturnType<typeof receiveTripInformation> | ReturnType<typeof requestTripInformationFailed> |
    ReturnType<typeof requestTripHistory> | ReturnType<typeof receiveTripHistory> | ReturnType<typeof requestTripHistoryFailed> | ReturnType<typeof updateShipmentPrioritySuccess>;

export const tripDetailsReducer = (tripData: Trips = {
    tripInformationStatus: ApiStatus.Idle,
    tripInformation: initialTripInformation,
    tripHistoryStatus: ApiStatus.Idle,
    tripHistory: []
}, action: TripActionTypes): Trips => {
    switch (action.type) {
        case TRIP_INFORMATION_REQUEST: {
            return {
                ...tripData,
                tripInformationStatus: ApiStatus.Loading
            };
        }
        case TRIP_INFORMATION_SUCCESS: {
            const activeShipment = action.payload.tripDetails.shipments.find((shipment): boolean => {
                return shipment.shipmentUniqueName === action.payload.tripDetails.activeShipmentUniqueName;
            });

            const activeShipmentRoute = action.payload.tripRoutes.find((tripRouteDetails): boolean => {
                return tripRouteDetails.shipmentUniqueName === action.payload.tripDetails.activeShipmentUniqueName;
            });

            if (activeShipment) {
                const activeShipmentMilestoneProgress = determineCurrentMilestoneProgressData(activeShipment);

                return {
                    ...tripData,
                    tripInformationStatus: ApiStatus.Success,
                    tripInformation: {
                        tripId: action.payload.tripDetails.tripId,
                        activeShipmentUniqueName: action.payload.tripDetails.activeShipmentUniqueName,
                        tripStatus: action.payload.tripDetails.tripStatus,
                        tripOriginStop: action.payload.tripDetails.tripOriginStop,
                        tripDestinationStop: action.payload.tripDetails.tripDestinationStop,
                        tripMostRecentPositionEvent: action.payload.tripDetails.tripMostRecentPositionEvent,
                        activeShipment: {
                            ...activeShipment,
                            plannedRoute: activeShipmentRoute?.routeDetails?.plannedRoute || [],
                            breadcrumbPositions: activeShipmentRoute?.routeDetails?.breadcrumbPositions || [],
                            currentMilestoneProgress: activeShipmentMilestoneProgress,
                            pulsePositionEventId: null
                        },
                        shipments: action.payload.tripDetails.shipments.map((shipment: TripServiceShipmentData): TripShipmentData => {
                            const currentMilestoneProgress = determineCurrentMilestoneProgressData(shipment);

                            const shipmentRoute = action.payload.tripRoutes.find((tripRouteDetails): boolean => {
                                return tripRouteDetails.shipmentUniqueName === shipment.shipmentUniqueName;
                            });
                            return {
                                ...shipment,
                                plannedRoute: shipmentRoute?.routeDetails?.plannedRoute || [],
                                breadcrumbPositions: shipmentRoute?.routeDetails?.breadcrumbPositions || [],
                                currentMilestoneProgress,
                                // if this shipment is the "active shipment", there is a current position, and the delivery status isn't tracking lost, pulse the current position, otherwise pass in null
                                pulsePositionEventId:
                                    shipment.shipmentUniqueName === activeShipment.shipmentUniqueName && shipment.currentPosition !== null && shipment.deliveryStatus !== DeliveryStatus.TrackingLost ?
                                        shipment.currentPosition.positionEventId :
                                        null
                            };
                        })
                    }
                };
            }

            // Just in case there isn't an active shipment, we want to show a failure notice to the user
            // because it's likely that something has gone horribly wrong in the API
            console.error('No active shipment found in the trip details');
            return {
                ...tripData,
                tripInformationStatus: ApiStatus.Failure,
                tripInformation: initialTripInformation
            };
        }
        case TRIP_INFORMATION_FAILURE: {
            return {
                ...tripData,
                tripInformationStatus: ApiStatus.Failure,
                // if the trip details API fails, just set all of the data to the initial state so that the component won't render
                tripInformation: initialTripInformation
            };
        }
        case TRIP_HISTORY_REQUEST: {
            return {
                ...tripData,
                tripHistoryStatus: ApiStatus.Loading
            };
        }
        case TRIP_HISTORY_SUCCESS: {
            return {
                ...tripData,
                tripHistoryStatus: ApiStatus.Success,
                tripHistory: action.payload
            };
        }
        case TRIP_HISTORY_FAILURE: {
            return {
                ...tripData,
                tripHistoryStatus: ApiStatus.Failure,
                tripHistory: []
            };
        }
        case UPDATE_SHIPMENT_PRIORITY: {
            return {
                ...tripData,
                tripInformation: {
                    ...tripData.tripInformation,
                    shipments: tripData.tripInformation.shipments.map((shipment) => {
                        if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                            return {
                                ...shipment,
                                isPriorityShipment: action.payload.isPriorityShipment
                            };
                        }
                        return shipment;
                    })
                }
            };
        }
        default:
            return tripData;
    }
};
