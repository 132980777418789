import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Typography,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const ManualShipmentCreationCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleIsManualShipmentEnabledFlagChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.isManualShipmentEnabledFlag, event.target.checked));
    };

    return (
        <PreferenceCard header='Manual Shipment Creation'>
            <Typography component='p' variant='body2'>
                Allow users in your organization to create shipments manually?
            </Typography>

            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organizationEditablePreferences.isManualShipmentEnabledFlag}
                            onChange={handleIsManualShipmentEnabledFlagChange}
                            color='primary'
                        />
                    }
                    label='Enable Manual Shipment Creation'
                    data-qa='isManualShipmentEnabledFlag-switch'
                />
            </FormGroup>
        </PreferenceCard>
    );
};

export default ManualShipmentCreationCard;
