import React, { useState } from 'react';
import {
    CircularProgress,
    Grid,
    Link,
    Tooltip,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';

import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { useTypedSelector } from '../../redux';
import { GenericApiResponse } from '../../interfaces/services';

interface DocumentRequestResponse {
    documentLink: string;
    isThumbnail: boolean;
}

const classesPrefix = 'documentDownloadLink';

const classes = {
    document: `${classesPrefix}-document`,
    loadingIcon: `${classesPrefix}-loadingIcon`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.document}`]: {
            cursor: 'pointer',
            fontWeight: 600,
            display: 'block'
        },
        [`& .${classes.loadingIcon}`]: {
            verticalAlign: 'middle',
            marginLeft: '4px'
        }
    };
});

const DocumentDownloadLink = ({ documentName, documentID, shipmentUniqueName }: {
    documentName: string;
    documentID: number;
    shipmentUniqueName: string;
}): JSX.Element => {
    const [isDownloading, setIsDownloading] = useState(false);

    const shipmentsApi = useTypedSelector((state) => { return state.availableServices.endpoints.ShipmentsApi; });

    const handleDocumentDownload = (): Promise<void> => {
        setIsDownloading(true);
        return ApiService.get({ url: `${shipmentsApi}${Endpoints.shipmentApi.details}/${shipmentUniqueName}/documents/${documentID}` })
            .then((response): void => {
                const { data } = response as GenericApiResponse<DocumentRequestResponse>;
                const link = document.createElement('a');
                link.href = data[0].documentLink;
                link.download = documentName;
                // some browsers needs the anchor to be in the doc
                document.body.append(link);
                link.click();
                link.remove();
            }).catch((): void => {
                toast.error('Document failed to download.');
            }).finally((): void => {
                setIsDownloading(false);
            });
    };

    return (
        <StyledGrid container>
            <Grid item xs={isDownloading ? 10 : 12}>
                <Tooltip title={documentName}>
                    <Typography
                        className={classes.document}
                        variant='body1'
                        component={Link}
                        noWrap
                        onClick={(): void => {
                            handleDocumentDownload();
                        }}
                        data-qa='document-download'
                    >
                        {documentName}
                    </Typography>
                </Tooltip>
            </Grid>
            {
                isDownloading &&
                <Grid item xs={2}>
                    <CircularProgress className={classes.loadingIcon} color='inherit' size={16} />
                </Grid>
            }
        </StyledGrid>
    );
};

export default DocumentDownloadLink;
