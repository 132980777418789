import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Typography,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const SuggestedRoutesCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleShowPCMilerRouteByDefaultFlagChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.showPCMilerRouteByDefaultFlag, event.target.checked));
    };

    return (
        <PreferenceCard header='Suggested Routes'>
            <Typography component='p' variant='body2'>
                Choose to have the route on or off by default:
            </Typography>

            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organizationEditablePreferences.showPCMilerRouteByDefaultFlag}
                            onChange={handleShowPCMilerRouteByDefaultFlagChange}
                            color='primary'
                        />
                    }
                    label='PC Miler'
                    data-qa='showPCMilerRouteByDefaultFlag-switch'
                />
            </FormGroup>
        </PreferenceCard>
    );
};

export default SuggestedRoutesCard;
