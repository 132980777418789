import React, { useState, useEffect } from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    Chip,
    TextField,
    FormGroup,
    Checkbox,
    Grid,
    Autocomplete,
    AutocompleteRenderInputParams
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { useTypedSelector } from '../../redux';
import { NotificationDetails } from '../../interfaces/services/notifications';
import { NotificationRecipientType, ShareType } from '../../helpers/enums';
import { formatPhoneNumber } from '../../helpers/dataUtils';

const classesPrefix = 'notificationLevelOptions';

const classes = {
    chip: `${classesPrefix}-chip`,
    textFieldChipInput: `${classesPrefix}-textFieldChipInput`,
    radioGroup: `${classesPrefix}-radioGroup`,
    radioGroupLabel: `${classesPrefix}-radioGroupLabel`,
    error: `${classesPrefix}-error`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.radioGroup}`]: {
            display: 'inline'
        },
        [`& .${classes.radioGroupLabel}`]: {
            marginRight: '12px'
        },
        [`& .${classes.chip}`]: {
            margin: '2px'
        },
        [`& .${classes.textFieldChipInput}`]: {
            margin: '8px 0'
        },
        [`& .${classes.error}`]: {
            marginBottom: '8px'
        }
    };
});

const NotificationLevelOptions = ({
    isFormDirty,
    draftNotificationDetails,
    draftSmsTextField,
    draftEmailTextField,
    handleDraftSmsTextFieldChange,
    handleDraftEmailTextFieldChange,
    handleDirtyChange,
    handleUpdateRecipientType,
    handleAddShipmentLevelSmsEntry,
    handleAddShipmentLevelEmailEntry,
    handleDeleteShipmentLevelSmsEntry,
    handleDeleteShipmentLevelEmailEntry,
    handleUpdateStopLevelSmsCheckbox,
    handleUpdateStopLevelEmailCheckbox
}: {
    isFormDirty: boolean;
    draftNotificationDetails: NotificationDetails;
    draftSmsTextField: string;
    draftEmailTextField: string;
    handleDraftSmsTextFieldChange: (draftSms: string) => void;
    handleDraftEmailTextFieldChange: (draftEmail: string) => void;
    handleDirtyChange: (isDirty: boolean) => void;
    handleUpdateRecipientType: (notificationRecipientType: NotificationRecipientType) => void;
    handleAddShipmentLevelSmsEntry: (newSms: string) => void;
    handleAddShipmentLevelEmailEntry: (newEmail: string) => void;
    handleDeleteShipmentLevelSmsEntry: (sms: string) => void;
    handleDeleteShipmentLevelEmailEntry: (email: string) => void;
    handleUpdateStopLevelSmsCheckbox: (notifyStopContactsBySms: boolean) => void;
    handleUpdateStopLevelEmailCheckbox: (notifyStopContactsByEmail: boolean) => void;
}): JSX.Element => {
    const [hasSmsInputError, setHasSmsInputError] = useState(false);
    const [hasEmailInputError, setHasEmailInputError] = useState(false);

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    useEffect(() => {
        handleDirtyChange(draftSmsTextField !== '' || draftEmailTextField !== '');
    }, [draftSmsTextField, draftEmailTextField, handleDirtyChange]);

    const createSmsChip = (): void => {
        const userInput = draftSmsTextField.replace(/[ ()-]/g, '');

        // If the number input fits a local US or CA format without a country code, format it automatically
        const formattedUserInput = userInput.length === 10 && !userInput.startsWith('+')
            ? `+1${userInput}`
            : userInput;

        if (isPossiblePhoneNumber(formattedUserInput) &&
            !draftNotificationDetails.smsList.includes(formattedUserInput)) {
            handleAddShipmentLevelSmsEntry(formattedUserInput);
            handleDraftSmsTextFieldChange('');
        } else {
            setHasSmsInputError(true);
        }
    };

    const handleSmsInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        setHasSmsInputError(false);

        if (!draftSmsTextField) {
            return;
        }
        if (event.key === 'Enter' || event.key === ',' || event.key === 'Tab') {
            event.preventDefault();
            createSmsChip();
        }
    };

    const createEmailChip = (): void => {
        if (!draftNotificationDetails.emailList.includes(draftEmailTextField) &&
            // Regex to verify email address input includes two values seperated by '@'
            (/^[^\s@]+@[^\s@]+$/.test(draftEmailTextField))) {
            handleAddShipmentLevelEmailEntry(draftEmailTextField);
            handleDraftEmailTextFieldChange('');
        } else {
            setHasEmailInputError(true);
        }
    };

    const handleEmailInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        setHasEmailInputError(false);

        if (!draftEmailTextField) {
            return;
        }
        if (event.key === 'Enter' || event.key === ',' || event.key === 'Tab') {
            event.preventDefault();
            createEmailChip();
        }
    };

    const hasStopLevelError = !draftNotificationDetails.notifyStopContactsByEmail && !draftNotificationDetails.notifyStopContactsBySms;
    const hasShipmentLevelError = draftNotificationDetails.smsList.length === 0 && draftNotificationDetails.emailList.length === 0;

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <FormControl
                    component='fieldset'
                    disabled={shareType === ShareType.OperatorReadOnly}
                >
                    <RadioGroup
                        aria-label='notificationLevel'
                        name='notificationLevel'
                        value={draftNotificationDetails.notificationRecipientType}
                        onChange={(event): void => {
                            const recipientType = event.currentTarget.value as NotificationRecipientType;
                            handleUpdateRecipientType(recipientType);
                        }}
                        className={classes.radioGroup}
                    >
                        <FormControlLabel
                            className={classes.radioGroupLabel}
                            value={NotificationRecipientType.shipmentLevel}
                            control={<Radio color='primary' />}
                            label='Shipment Level'
                            data-qa='notificationForm-shipment-level-radio'
                        />
                        <FormControlLabel
                            value={NotificationRecipientType.stopLevel}
                            control={<Radio color='primary' />}
                            label='Stop Level'
                            data-qa='notificationForm-stop-level-radio'
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {
                draftNotificationDetails.notificationRecipientType === NotificationRecipientType.shipmentLevel &&
                <Grid item xs={12}>
                    <FormHelperText>Press &apos;Enter&apos; to add a new SMS number or email address.</FormHelperText>
                    <Autocomplete
                        id='shipment-level-sms-input'
                        options={[]}
                        disabled={shareType === ShareType.OperatorReadOnly}
                        value={draftNotificationDetails.smsList}
                        inputValue={draftSmsTextField}
                        multiple
                        freeSolo
                        limitTags={-1}
                        disableClearable
                        renderTags={(): JSX.Element[] => {
                            return draftNotificationDetails.smsList.map((number: string) => {
                                return (
                                    <Chip
                                        key={number}
                                        label={formatPhoneNumber(number)}
                                        className={classes.chip}
                                        disabled={shareType === ShareType.OperatorReadOnly}
                                        onDelete={(): void => { handleDeleteShipmentLevelSmsEntry(number); }}
                                    />
                                );
                            });
                        }}
                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => {
                            return (
                                <TextField
                                    {...params}
                                    label='SMS Recipients'
                                    type='tel'
                                    variant='outlined'
                                    placeholder='Add phone...'
                                    fullWidth
                                    value={draftSmsTextField}
                                    className={classes.textFieldChipInput}
                                    required={hasShipmentLevelError}
                                    error={isFormDirty ? (hasShipmentLevelError || hasSmsInputError) : false}
                                    helperText={hasSmsInputError ? 'Please verify phone numbers include a proper country code starting with "+"' : ''}
                                    onKeyDown={(event): void => { handleSmsInputKeyDown(event); }}
                                    onBlur={(): void => {
                                        if (draftSmsTextField) {
                                            createSmsChip();
                                        }
                                    }}
                                    onChange={(event): void => {
                                        const cleanedInput = event.target.value.replace(/[^ ()0-9+-]/g, '');
                                        handleDraftSmsTextFieldChange(cleanedInput);
                                    }}
                                />
                            );
                        }}
                        data-qa='notificationForm-shipment-level-sms-input'
                    />

                    <Autocomplete
                        id='shipment-level-email-input'
                        options={[]}
                        value={draftNotificationDetails.emailList}
                        disabled={shareType === ShareType.OperatorReadOnly}
                        inputValue={draftEmailTextField}
                        multiple
                        freeSolo
                        limitTags={-1}
                        disableClearable
                        renderTags={(): JSX.Element[] => {
                            return draftNotificationDetails.emailList.map((email: string) => {
                                return (
                                    <Chip
                                        key={email}
                                        label={email}
                                        className={classes.chip}
                                        disabled={shareType === ShareType.OperatorReadOnly}
                                        onDelete={(): void => { handleDeleteShipmentLevelEmailEntry(email); }}
                                    />
                                );
                            });
                        }}
                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => {
                            return (
                                <TextField
                                    {...params}
                                    label='Email Recipients'
                                    type='email'
                                    variant='outlined'
                                    placeholder='Add email...'
                                    fullWidth
                                    value={draftEmailTextField}
                                    className={classes.textFieldChipInput}
                                    required={hasShipmentLevelError}
                                    error={isFormDirty ? (hasShipmentLevelError || hasEmailInputError) : false}
                                    helperText={hasEmailInputError ? 'Please input a valid email address' : ''}
                                    onKeyDown={(event): void => { handleEmailInputKeyDown(event); }}
                                    onBlur={(): void => {
                                        if (draftEmailTextField) {
                                            createEmailChip();
                                        }
                                    }}
                                    onChange={(event): void => {
                                        const cleanedInput = event.target.value.replace(/[ ]/g, '');
                                        handleDraftEmailTextFieldChange(cleanedInput);
                                    }}
                                />
                            );
                        }}
                        data-qa='notificationForm-shipment-level-email-input'
                    />
                    {
                        hasShipmentLevelError && isFormDirty &&
                        <FormHelperText error className={classes.error}>Please enter at least one SMS number or email address</FormHelperText>
                    }
                </Grid>
            }
            {
                draftNotificationDetails.notificationRecipientType === NotificationRecipientType.stopLevel &&
                <Grid item xs={12}>
                    <FormControl
                        error={hasStopLevelError}
                        component='fieldset'
                        disabled={shareType === ShareType.OperatorReadOnly}
                    >
                        <FormGroup>
                            <FormControlLabel
                                label='Notify All Stop Contacts by Text/SMS'
                                control={
                                    <Checkbox
                                        name='notifyStopContactsBySms'
                                        color='primary'
                                        required={hasStopLevelError}
                                        checked={draftNotificationDetails.notifyStopContactsBySms}
                                        onChange={(event): void => { handleUpdateStopLevelSmsCheckbox(event.target.checked); }}
                                        data-qa='notificationForm-stop-contacts-sms-checkbox'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Notify All Stop Contacts by Email'
                                control={
                                    <Checkbox
                                        name='notifyStopContactsByEmail'
                                        color='primary'
                                        required={hasStopLevelError}
                                        checked={draftNotificationDetails.notifyStopContactsByEmail}
                                        onChange={(event): void => { handleUpdateStopLevelEmailCheckbox(event.target.checked); }}
                                        data-qa='notificationForm-stop-contacts-email-checkbox'
                                    />
                                }
                            />
                        </FormGroup>
                        {
                            hasStopLevelError && isFormDirty &&
                            <FormHelperText error className={classes.error}>Please pick at least one stop level communication preference</FormHelperText>
                        }
                    </FormControl>
                </Grid>
            }
        </StyledGrid>
    );
};

export default NotificationLevelOptions;
