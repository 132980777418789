import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
    FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { editPreferencesOnTimeRules } from '../../../redux/organization';
import { PreferencesOnTimeRules } from '../../../interfaces/services/organization';
import { calculateHoursAndMinutes, calculateMinutes, validateHoursAndMinutes } from '../../../helpers/dataUtils';
import { ModeType, PreferencesOnTimeRulesFieldNames } from '../../../helpers/enums';

const classesPrefix = 'defaultDwellTimeForStops';

const classes = {
    textFieldWrapper: `${classesPrefix}-textFieldWrapper`,
    textField: `${classesPrefix}-textField`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.textFieldWrapper}`]: {
            marginLeft: '16px',
            marginRight: '16px'
        },
        [`& .${classes.textField}`]: {
            width: '50px'
        }
    };
});

const DefaultDwellTimeForStops = ({
    currentOnTimeRules,
    selectedModeType,
    handleIsRuleValidChange
}: {
    selectedModeType: ModeType;
    currentOnTimeRules: PreferencesOnTimeRules;
    handleIsRuleValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const { dwellTimeEnabled, dwellTimeMinutes } = currentOnTimeRules;

    const [isDwellTimeMinutesValid, setIsDwellTimeMinutesValid] = useState(true);

    useEffect((): void => {
        const isValid = validateHoursAndMinutes(dwellTimeMinutes, 1440);
        setIsDwellTimeMinutesValid(isValid);
    }, [dwellTimeMinutes]);

    useEffect((): void => {
        const isRuleValid = dwellTimeEnabled ? isDwellTimeMinutesValid : true;
        handleIsRuleValidChange(isRuleValid);
    }, [handleIsRuleValidChange, dwellTimeEnabled, isDwellTimeMinutesValid]);

    const handleDwellTimeEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.dwellTimeEnabled, event.target.checked));
    };

    const handleDwellTimeHoursChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(Number(event.currentTarget.value), calculateHoursAndMinutes(dwellTimeMinutes).minutes);
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.dwellTimeMinutes, updatedMinutes));
    };

    const handleDwellTimeMinutesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const updatedMinutes = calculateMinutes(calculateHoursAndMinutes(dwellTimeMinutes).hours, Number(event.currentTarget.value));
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.dwellTimeMinutes, updatedMinutes));
    };

    return (
        <Fragment>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={dwellTimeEnabled}
                            onChange={handleDwellTimeEnabledChange}
                            color='primary'
                        />
                    }
                    label='Enable Dwell Time'
                    data-qa='dwellTimeEnabled-switch'
                />
            </FormGroup>

            {
                dwellTimeEnabled === true &&
                <Fragment>
                    <StyledGrid container>
                        <Grid item>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Hours'
                                value={calculateHoursAndMinutes(dwellTimeMinutes).hours}
                                onChange={handleDwellTimeHoursChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 24 } }}
                                error={!isDwellTimeMinutesValid}
                                data-qa='dwellTimeMinutes-hours-input'
                            />
                        </Grid>

                        <Grid item className={classes.textFieldWrapper}>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Minutes'
                                value={calculateHoursAndMinutes(dwellTimeMinutes).minutes}
                                onChange={handleDwellTimeMinutesChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 59 } }}
                                error={!isDwellTimeMinutesValid}
                                data-qa='dwellTimeMinutes-minutes-input'
                            />
                        </Grid>
                    </StyledGrid>
                    {
                        !isDwellTimeMinutesValid &&
                        <FormHelperText error>Total time cannot exceed 24 hours and 0 minutes.</FormHelperText>
                    }
                </Fragment>
            }
        </Fragment>
    );
};

export default DefaultDwellTimeForStops;
