import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material';

const AppHeaderMenuItemLoading = (): JSX.Element => {
    return (
        <ListItemButton>
            <ListItemIcon>
                <Skeleton variant='rectangular' width='24px' height='24px' />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Skeleton variant='rectangular' width='120px' height='20px' />
                }
            />
        </ListItemButton>
    );
};

export default AppHeaderMenuItemLoading;
