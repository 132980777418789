import React from 'react';
import { Card, CardContent, Typography, Tooltip, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

const classesPrefix = 'preferenceCard';

const classes = {
    cardWrapper: `${classesPrefix}-cardWrapper`,
    header: `${classesPrefix}-header`
};

const StyledCard = styled(Card)(({ theme }) => {
    return {
        [`&.${classes.cardWrapper}`]: {
            margin: '16px'
        },
        [`& .${classes.header}`]: {
            fontWeight: 600
        },
        [theme.breakpoints.down('lg')]: {
            [`&.${classes.cardWrapper}`]: {
                margin: '8px'
            }
        }
    };
});

const PreferenceCard = ({
    header,
    informationText,
    children
}: {
    header: string;
    informationText?: string;
    children: React.ReactNode;
}): JSX.Element => {
    const theme = useTheme();

    return (
        <StyledCard className={classes.cardWrapper}>
            <CardContent>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item xs={11}>
                        <Typography gutterBottom variant='h6' component='h3' className={classes.header}>
                            {header}
                        </Typography>
                    </Grid>
                    {
                        informationText &&
                        <Grid item xs={1}>
                            <Tooltip title={informationText} aria-label='info'>
                                <Icon
                                    path={mdiInformation}
                                    size={1}
                                    color={theme.palette.text.secondary}
                                />
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
                {children}
            </CardContent>
        </StyledCard>
    );

};

export default PreferenceCard;
