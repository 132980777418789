import React, { Fragment, useMemo } from 'react';
import { Typography } from '@mui/material';

import { useTypedSelector } from '../../redux';
import { OrderListData } from '../../interfaces/services/orders';
import { ApiStatus } from '../../helpers/enums';
import ErrorPage from '../errors/errorPage';
import OrderCard from '../cards/orderCard';
import OrderCardLoading from '../loaders/cards/orderCardLoading';

const OrderCardList = (): JSX.Element => {
    const ordersListStatus = useTypedSelector((state) => { return state.orders.ordersListStatus; });
    const ordersList = useTypedSelector((state) => { return state.orders.ordersList; });

    const isFetchingData = ordersListStatus === ApiStatus.Idle || ordersListStatus === ApiStatus.Loading;

    // Creates a memoized list of the order cards which will only adjust when the ordersList changes.
    // This helps fix some performance issues that are causing this entire list to re-render unnecessarily.
    const memoizedOrderCardList = useMemo((): JSX.Element[] => {
        return ordersList.map((order: OrderListData): JSX.Element => {
            return (
                <OrderCard
                    key={order.freightOrderGuid}
                    order={order}
                />
            );
        });
    }, [ordersList]);

    if (isFetchingData) {
        return (
            <Fragment>
                {
                    Array.from(new Array(5)).map((item, index): JSX.Element => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <OrderCardLoading key={index} />
                        );
                    })
                }
            </Fragment>
        );
    }

    if (ordersList.length === 0 || ordersListStatus === ApiStatus.Failure) {
        return (
            <ErrorPage errorHeaderText=''>
                <Typography variant='body1' data-qa='noShipments'>No Orders available.</Typography>
            </ErrorPage>
        );
    }

    return (
        <Fragment>
            {memoizedOrderCardList}
        </Fragment>
    );
};

export default OrderCardList;
