import { DeliveryStatus, MilestoneType, StopType } from '../../helpers/enums';
import { Stop } from '../../interfaces/services/shipmentDetails';
import { TripInformationData } from '../../interfaces/services/trip';
import { initialShipmentDetailsState } from '../shipmentDetails/initialState';

const intialTripInformationStopState: Stop = {
    shipmentStopGUID: '',
    appointment: null,
    stopSequence: 0,
    stopType: StopType.Origin,
    description: null,
    fixedLocationCode: null,
    name: null,
    stopReference: '',
    contact: {
        name: null,
        phoneNumber: null,
        phoneExtension: null,
        faxNumber: null,
        emailAddress: null
    },
    address: {
        streetAddress1: null,
        streetAddress2: null,
        city: null,
        state: null,
        postalCode: null,
        countryCode: null
    },
    latitude: 0,
    longitude: 0,
    hasStopBeenVisited: false,
    isActiveStop: false,
    isDropTrailer: null,
    dwellTimeDisplay: '',
    dwellTimeAnticipatedDisplay: '',
    deliveryStatus: DeliveryStatus.Early,
    polygonGeofenceIn: null,
    polygonGeofenceOut: null,
    geofenceRadiusInFeet: 0,
    geofenceOutRadiusInFeet: 0,
    scheduledStartDateTime: null,
    scheduledEndDateTime: null,
    actualArrivalDateTime: null,
    actualDepartureDateTime: null,
    estimatedDeliveryDateTime: null,
    geofenceArrivalDateTime: null,
    geofenceDepartureDateTime: null,
    carrierDepartureDateTime: null,
    carrierArrivalDateTime: null,
    stopNotes: [],
    documents: [],
    stopSharingToken: '',
    additionalInformation: null
};

export const initialTripInformation: TripInformationData = {
    tripId: '',
    activeShipmentUniqueName: '',
    tripStatus: MilestoneType.Pending,
    activeShipment: initialShipmentDetailsState,
    shipments: [],
    tripOriginStop: intialTripInformationStopState,
    tripDestinationStop: {
        ...intialTripInformationStopState,
        stopType: StopType.Destination
    },
    tripMostRecentPositionEvent: null
};
