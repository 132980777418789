import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'roadSurfaceLegend';

const classes = {
    rectangle: `${classesPrefix}-rectangle`,
    wet: `${classesPrefix}-wet`,
    mix: `${classesPrefix}-mix`,
    ice: `${classesPrefix}-ice`,
    surfaceDescription: `${classesPrefix}-surfaceDescription`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.rectangle}`]: {
            width: '16px',
            height: '5px'
        },
        [`& .${classes.wet}`]: {
            background: '#00FF00'
        },
        [`& .${classes.mix}`]: {
            background: '#0000FF'
        },
        [`& .${classes.ice}`]: {
            background: '#FF0000'
        },
        [`& .${classes.surfaceDescription}`]: {
            fontSize: '12px',
            paddingLeft: '12px'
        }
    };
});

const RoadSurfaceLegend = (): JSX.Element => {
    return (
        <StyledGrid container justifyContent='flex-start' alignItems='center'>
            <Grid item xs={1}>
                <div className={`${classes.rectangle} ${classes.wet}`} />
            </Grid>
            <Grid item xs={11}>
                <Typography className={classes.surfaceDescription} data-qa='wet-label'>Wet</Typography>
            </Grid>
            <Grid item xs={1}>
                <div className={`${classes.rectangle} ${classes.mix}`} />
            </Grid>
            <Grid item xs={11}>
                <Typography className={classes.surfaceDescription} data-qa='mix-label'>Mix water/snow</Typography>
            </Grid>
            <Grid item xs={1}>
                <div className={`${classes.rectangle} ${classes.ice}`} />
            </Grid>
            <Grid item xs={11}>
                <Typography className={classes.surfaceDescription} data-qa='iceSnow-label'>Ice/Snow</Typography>
            </Grid>
        </StyledGrid>
    );
};

export default RoadSurfaceLegend;
