import React from 'react';

import { useTypedSelector } from '../../redux';
import TransparentFullScreenDialog from './common/transparentFullScreenDialog';
import OrderDetails from '../sections/orderDetails';

const OrderDetailsDialog = ({
    orderGuid,
    handleDialogClose
}: {
    /** unique identifier for the order */
    orderGuid: string;
    /** Function triggered by the close action */
    handleDialogClose: () => void;
}): JSX.Element => {
    const ordersList = useTypedSelector((state) => { return state.orders.ordersList; });

    const selectedOrder = ordersList.find((order) => {
        return order.freightOrderGuid === orderGuid;
    });

    return (
        <TransparentFullScreenDialog
            headerText={`Order ${selectedOrder?.orderNumber || ''}`}
            handleDialogClose={handleDialogClose}
        >
            <OrderDetails
                orderGuid={orderGuid}
            />
        </TransparentFullScreenDialog>
    );
};

export default OrderDetailsDialog;
