import { GroupedMilestones } from '../interfaces/componentInterfaces';
import {
    ShipmentServiceData,
    ShipmentMilestone,
    MilestoneProgressData
} from '../interfaces/services/shipmentDetails';
import {
    PreferredStopTimeSourceType,
    MilestoneType,
    ModeType
} from './enums';
import { groupByWithDefaultObject } from './dataUtils';
import { zonedDateTimeToDisplay } from './dateUtils';

/**
 * This will take in a preference, and two dates, from the geofence and carrier. Using the preference it will determine the preferential order to return the correct date.
 * @param preferredStopTimeSourceType Preference on whether the geofence or carrier dates should be preferred.
 * @param geofenceDateTime An arrival or departure date updated from the geofence.
 * @param carrierDateTime An arrival or departure date updated from the carrier.
 */
const determineDateUsingPreference = ({
    preferredStopTimeSourceType,
    dateType,
    geofenceDateTime,
    carrierDateTime
}: {
    preferredStopTimeSourceType: PreferredStopTimeSourceType;
    dateType: 'Arrived' | 'Departed';
    geofenceDateTime: string | null;
    carrierDateTime: string | null;
}): { label: string; value: string | null; } => {
    const geofence = {
        label: `${dateType} (Geofence)`,
        value: zonedDateTimeToDisplay(geofenceDateTime)
    };
    const carrier = {
        label: `${dateType} (Carrier)`,
        value: zonedDateTimeToDisplay(carrierDateTime)
    };

    // When the preference is set to use geofence arrival/departures, it will first return the geofence date if available,
    // otherwise will default to the carrier provided date. If neither are available, it will return null.
    if (preferredStopTimeSourceType === PreferredStopTimeSourceType.Geofence) {
        if (geofenceDateTime) {
            return geofence;
        }

        if (carrierDateTime) {
            return carrier;
        }
    }

    // When the preference is set to use carrier provided arrival/departures, it will first return the carrier date if available,
    // otherwise will default to the geofence provided date. If neither are available, it will return null.
    if (preferredStopTimeSourceType === PreferredStopTimeSourceType.Carrier) {
        if (carrierDateTime) {
            return carrier;
        }

        if (geofenceDateTime) {
            return geofence;
        }
    }

    return { label: dateType, value: null };
};

/**
 * This will take in a preference and multiple dates, and using the business logic will determine which date should show to the user.
 * Order wise, it will prefer a departure date first, then an arrival date, then the ETA. If none of those are available null should be returned.
 * @param preferredStopTimeSourceType Preference on whether the geofence or carrier dates should be preferred.
 * @param preferenceShowEta Preference on whether the Eta should be shown.
 * @param geofenceDepartureDateTime A departure date updated from the geofence.
 * @param carrierDepartureDateTime A departure date updated from the carrier.
 * @param geofenceArrivalDateTime An arrival date updated from the geofence.
 * @param carrierArrivalDateTime An arrival date updated from the carrier.
 * @param stopEta An ETA date on the current stop.
 */
export const determineDisplayDates = (
    preferredStopTimeSourceType: PreferredStopTimeSourceType,
    preferenceShowEta: boolean,
    geofenceDepartureDateTime: string | null,
    carrierDepartureDateTime: string | null,
    geofenceArrivalDateTime: string | null,
    carrierArrivalDateTime: string | null,
    stopEta: string | null
): { label: string; value: string | null; }[] => {
    const displayDates = [];

    const arrivalDate = determineDateUsingPreference({
        preferredStopTimeSourceType, dateType: 'Arrived', geofenceDateTime: geofenceArrivalDateTime, carrierDateTime: carrierArrivalDateTime
    });
    displayDates.push(arrivalDate);

    const departureDate = determineDateUsingPreference({
        preferredStopTimeSourceType, dateType: 'Departed', geofenceDateTime: geofenceDepartureDateTime, carrierDateTime: carrierDepartureDateTime
    });
    displayDates.push(departureDate);

    // If neither a departure or arrival dates exist, add the ETA date if available.
    if (arrivalDate.value === null && departureDate.value === null && stopEta !== null && preferenceShowEta) {
        displayDates.push({
            label: 'ETA',
            value: zonedDateTimeToDisplay(stopEta)
        });
    }

    return displayDates;
};

export const isShipmentLtl = (modeType: ModeType): boolean => {
    return modeType === ModeType.LTL || modeType === ModeType.Parcel;
};

export const determineCurrentMilestoneProgressData = (shipment: ShipmentServiceData): MilestoneProgressData => {
    const defaultMilestoneObject: GroupedMilestones = {
        [MilestoneType.Pending]: [],
        [MilestoneType.PickedUp]: [],
        [MilestoneType.InTransit]: [],
        [MilestoneType.OutForDelivery]: [],
        [MilestoneType.Delivered]: []
    };

    const milestones: GroupedMilestones = groupByWithDefaultObject(shipment.shipmentMilestones, 'milestoneType', defaultMilestoneObject);

    let furthestMilestone = null;
    if (milestones[MilestoneType.Pending].length > 0) {
        furthestMilestone = MilestoneType.Pending;
    }
    if (milestones[MilestoneType.PickedUp].length > 0) {
        furthestMilestone = MilestoneType.PickedUp;
    }
    if (milestones[MilestoneType.InTransit].length > 0) {
        furthestMilestone = MilestoneType.InTransit;
    }
    if (milestones[MilestoneType.OutForDelivery].length > 0) {
        furthestMilestone = MilestoneType.OutForDelivery;
    }
    if (milestones[MilestoneType.Delivered].length > 0) {
        furthestMilestone = MilestoneType.Delivered;
    }

    let earliest = null;
    if (furthestMilestone) {
        const sortedMilestones = milestones[furthestMilestone].sort((a: ShipmentMilestone, b: ShipmentMilestone): number => {
            return new Date(a.eventDateTime).getTime() - new Date(b.eventDateTime).getTime();
        });
        earliest = sortedMilestones[0].eventDateTime;
    }

    return furthestMilestone && earliest ? {
        furthest: furthestMilestone,
        receivedDate: zonedDateTimeToDisplay(earliest) || '--'
    } : {
        // if no milestones exist, default to Pending and the shipment created date
        furthest: MilestoneType.Pending,
        receivedDate: zonedDateTimeToDisplay(shipment.createdDate) || '--'
    };
};
