import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { fetchOrdersList, updateOrdersSort } from '../../redux/orders';
import { fetchOrderMapLegend, fetchOrderMapLegendFiltered } from '../../redux/dimensions';
import { ApiStatus, ShareType, SideSheetType } from '../../helpers/enums';
import { orderSortOrderList } from '../../helpers/hardcodedOptionLists';
import { OrdersSortOption } from '../../interfaces/componentInterfaces';
import { privateRoutes } from '../../routes/appRouteList';
import Hidden from '../layouts/hidden';
import OrderHeader from '../headers/orderHeader';
import SortSelect from '../selects/sortSelect';
import OrderCardList from '../lists/orderCardList';
import OrdersMapWrapper from '../maps/ordersMapWrapper';
import OrderListTable from '../tables/orderListTable';
import ListPagination from '../paginations/listPagination';
import SideSheet from '../sideSheets/sideSheet';
import OrderViews from '../sideSheets/orderViews';
import OrderFilters from '../sideSheets/orderFilters';

interface StyleProps {
    filtersName: string;
    shareType: ShareType | null;
}

const classesPrefix = 'ordersPage';

const classes = {
    cardListBody: `${classesPrefix}-cardListBody`,
    sortSelectWrapper: `${classesPrefix}-sortSelectWrapper`,
    paginationWrapper: `${classesPrefix}-paginationWrapper`
};

const StyledPaper = styled(
    Paper,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'filtersName' && prop !== 'shareType'; }
    }
)<StyleProps>(({ filtersName, shareType, theme }) => {
    let subtractHeight = 251;
    if (shareType === ShareType.View) {
        subtractHeight = 215;
    }
    if (filtersName) {
        subtractHeight += 48;
    }
    return {
        [`& .${classes.cardListBody}`]: {
            overflow: 'hidden auto',
            height: 'calc(100vh - 220px)',
            borderTop: `2px solid ${theme.palette.divider}`,
            borderBottom: `2px solid ${theme.palette.divider}`
        },
        [`& .${classes.sortSelectWrapper}`]: {
            padding: '8px',
            maxWidth: '400px'
        },
        [`& .${classes.paginationWrapper}`]: {
            flexGrow: 1
        },
        [theme.breakpoints.down('md')]: {
            [`& .${classes.cardListBody}`]: {
                height: `calc(100vh - ${subtractHeight}px)`
            }
        }
    };
});

const OrdersPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmallDevice = useMediaQuery(theme.breakpoints.only('xs'));
    const isMediumDevice = useMediaQuery(theme.breakpoints.only('md'));

    const [currentSideSheet, setCurrentSideSheet] = useState<SideSheetType | null>(null);
    const [transitionHasEnded, setTransitionHasEnded] = useState(false);

    const ordersList = useTypedSelector((state) => { return state.orders.ordersList; });
    const ordersListStatus = useTypedSelector((state) => { return state.orders.ordersListStatus; });
    const totalRecords = useTypedSelector((state) => { return state.orders.totalRecords; });
    const skip = useTypedSelector((state) => { return state.orders.skip; });
    const take = useTypedSelector((state) => { return state.orders.take; });
    const searchTerm = useTypedSelector((state) => { return state.orders.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.orders.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.orders.sortDirection; });
    const activeOrders = useTypedSelector((state) => { return state.orders.activeOrders; });
    const filters = useTypedSelector((state) => { return state.orders.filters; });
    const filtersName = useTypedSelector((state) => { return state.orders.filtersName; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    useEffect(() => {
        dispatch(fetchOrdersList({ sortColumn, sortDirection, filters }));
    }, [dispatch, filters, sortColumn, sortDirection]);

    // only fetch map legend data if on the order overview page when map is present
    useEffect((): void => {
        if (!isMobile && location.pathname === privateRoutes.ordersOverview) {
            dispatch(fetchOrderMapLegend());
            dispatch(fetchOrderMapLegendFiltered(filters));
        }
    }, [isMobile, location, filters, dispatch]);

    const selectedSort = useMemo((): OrdersSortOption => {
        const sortOption = orderSortOrderList.find((sortItem): boolean => {
            return sortItem.sortDirection === sortDirection && sortItem.sortColumn === sortColumn;
        });

        return sortOption || orderSortOrderList[1];
    }, [sortDirection, sortColumn]);

    // any time the transition ends toggle the state to update child components that may be dependent on the transition ending such as the <ShipmentsMap /> resize
    const handleTransitionEnd = (event: any): void => {
        // we only care about the shipmentsPage transition
        if (event.target.id !== 'ordersPage') {
            return;
        }

        setTransitionHasEnded(true);
    };

    const renderPagination = (): JSX.Element => {
        return (
            <Grid item className={classes.paginationWrapper}>
                <ListPagination
                    isFetchingData={ordersListStatus === ApiStatus.Idle || ordersListStatus === ApiStatus.Loading}
                    skip={skip}
                    take={take}
                    loadedRecordsCount={ordersList.length}
                    totalRecordsCount={totalRecords}
                    showPaginationTotals={!isExtraSmallDevice && !isMediumDevice}
                    showFirstButton={!isMobile}
                    showLastButton={!isMobile}
                    siblingCount={0}
                    handlePaginationChange={(newSkip: number): void => {
                        dispatch(fetchOrdersList({
                            activeOrders,
                            skip: newSkip,
                            take,
                            sortDirection,
                            sortColumn,
                            searchTerm,
                            filters
                        }));
                    }}
                />
            </Grid>
        );
    };

    const renderOrdersPageBody = (): JSX.Element => {
        if (location.pathname === privateRoutes.ordersActive) {
            return (
                <Fragment>
                    <OrderListTable />
                    {renderPagination()}
                </Fragment>
            );
        }

        return (
            <Grid container>
                <Grid item xs={12} md={6} xl={5}>
                    <div className={classes.sortSelectWrapper} data-qa='ordersSort-container'>
                        <SortSelect
                            id='orderSort'
                            selectedValue={selectedSort}
                            availableOptions={orderSortOrderList}
                            handleChange={(newValue): void => {
                                dispatch(updateOrdersSort(newValue.sortColumn, newValue.sortDirection));
                            }}
                        />
                    </div>
                    <div className={classes.cardListBody} data-qa='ordersCards-container'>
                        <OrderCardList />
                    </div>
                    {renderPagination()}
                </Grid>

                <Hidden breakpoint='md' direction='down'>
                    <Grid item xs={6} xl={7}>
                        <OrdersMapWrapper resize={transitionHasEnded} />
                    </Grid>
                </Hidden>
            </Grid>
        );
    };

    const sideSheetIsOpen = currentSideSheet !== null;
    let paperStyle: React.CSSProperties = { width: 'auto' };
    if (isMobile) {
        paperStyle = { width: 'auto' };
    } else if (sideSheetIsOpen) {
        paperStyle = { width: 'calc(100% - 331px)' };
    }

    return (
        <Fragment>
            <OrderHeader
                currentSideSheet={currentSideSheet}
                handleCurrentSideSheetChange={(newSideSheet: SideSheetType): void => {
                    if (newSideSheet === currentSideSheet) {
                        setCurrentSideSheet(null);
                    } else {
                        setCurrentSideSheet(newSideSheet);
                    }
                }}
            />
            <StyledPaper
                id='ordersPage'
                square
                filtersName={filtersName}
                shareType={shareType}
                style={paperStyle}
                onTransitionEnd={handleTransitionEnd}
                data-qa='ordersPage-container'
            >
                {renderOrdersPageBody()}
            </StyledPaper>

            {
                currentSideSheet === SideSheetType.Filters &&
                <SideSheet
                    open={currentSideSheet === SideSheetType.Filters}
                    closeSideSheet={(): void => {
                        setCurrentSideSheet(null);
                    }}
                >
                    <OrderFilters />
                </SideSheet>
            }
            {
                currentSideSheet === SideSheetType.Views &&
                <SideSheet
                    open={currentSideSheet === SideSheetType.Views}
                    closeSideSheet={(): void => {
                        setCurrentSideSheet(null);
                    }}
                >
                    <OrderViews />
                </SideSheet>
            }
        </Fragment>
    );
};

export default OrdersPage;
