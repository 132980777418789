import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, InputLabel, Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { shipmentSortOrderList } from '../../../helpers/hardcodedOptionLists';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'defaultSortOrderCard';

const classes = {
    selectWrapper: `${classesPrefix}-selectWrapper`,
    selectField: `${classesPrefix}-selectField`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.selectWrapper}`]: {
            marginTop: '16px'
        },
        [`& .${classes.selectField}`]: {
            maxWidth: '320px'
        }
    };
});

const DefaultSortOrderCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleSortOptionFieldTypeChange = (event: SelectChangeEvent<unknown>): void => {
        dispatch(editPreferences(PreferencesFieldNames.sortOptionFieldType, Number(event.target.value)));
    };

    return (
        <PreferenceCard header='Default Sort Order'>
            <Typography component='p' variant='body2'>
                How do you want your shipments sorted on default?
            </Typography>

            <StyledDiv className={classes.selectWrapper}>
                <FormControl fullWidth variant='standard'>
                    <InputLabel id='sortOptionFieldTypeLabel'>Sort by</InputLabel>
                    <Select
                        labelId='sortOptionFieldTypeLabel'
                        className={classes.selectField}
                        variant='standard'
                        value={organizationEditablePreferences.sortOptionFieldType}
                        onChange={handleSortOptionFieldTypeChange}
                        inputProps={{
                            'data-qa': 'sortOptionFieldType-input'
                        }}
                    >
                        {
                            shipmentSortOrderList.map((option) => {
                                return (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        data-qa={`sortOptionFieldType-item-${option.label}`}
                                    >
                                        {option.label}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </StyledDiv>
        </PreferenceCard>
    );
};

export default DefaultSortOrderCard;
