import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LinearUnit, ShipmentStatus, DeliveryStatus } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import { Stop } from '../../interfaces/services/shipmentDetails';
import ProgressBar from './progressBar';

const classesPrefix = 'haulLengthProgressBar';

const classes = {
    haulLength: `${classesPrefix}-haulLength`,
    milesRemaining: `${classesPrefix}-milesRemaining`,
    tripInfo: `${classesPrefix}-tripInfo`,
    tripInfoText: `${classesPrefix}-tripInfoText`,
    tripStatusText: `${classesPrefix}-tripStatusText`
};

const StyledSection = styled('section')(({ theme }) => {
    return {
        [`&.${classes.haulLength}`]: {
            width: '100%',
            marginTop: '16px'
        },
        [`& .${classes.milesRemaining}`]: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        [`& .${classes.tripInfo}`]: {
            marginTop: '4px'
        },
        [`& .${classes.tripInfoText}`]: {
            fontSize: '12px',
            color: theme.palette.text.secondary
        },
        [`& .${classes.tripStatusText}`]: {
            marginBottom: '4px'
        }
    };
});

const HaulLengthProgressBar = ({
    haulLength,
    haulLinearUnitType,
    remainingHaulLength,
    shipmentStatus,
    deliveryStatus,
    stops
}: {
    haulLength: number;
    haulLinearUnitType: LinearUnit;
    remainingHaulLength: number | null;
    shipmentStatus: ShipmentStatus;
    deliveryStatus: DeliveryStatus;
    stops: Stop[];
}): JSX.Element => {
    const measurementUnit = (haulLinearUnitType === LinearUnit.Kilometers) ? 'kilometers' : 'miles';
    let color = 'transparent';
    let additionalMessage = null;
    let trackingLostMessage = null;
    let progress = 0;
    let milesTraveled = 0;
    let milesRemaining = remainingHaulLength;
    let statusLabel = addSpaceBeforeUppercaseCharacter(shipmentStatus);

    // find active stop info
    const activeStop = stops.find((stop) => {
        return stop.isActiveStop;
    });

    // if status type is 'In Transit' add stop description
    if (shipmentStatus === ShipmentStatus.InTransit) {
        // verify there is an active stop
        if (activeStop !== undefined) {
            let labelPreposition = 'to';
            if (activeStop.actualArrivalDateTime !== null) {
                // don't add "Arrived" to the statusLabel for tracking lost
                statusLabel = deliveryStatus !== DeliveryStatus.TrackingLost ? 'Arrived' : '';
                labelPreposition = 'at';
            }
            // if we have the stop name display it
            if (activeStop.name !== null && activeStop.name !== '') {
                additionalMessage = ` ${labelPreposition} ${activeStop.name}`;
            } else if (activeStop.stopSequence === 1) {
                // if the stop sequence is 1 say in transit to origin
                additionalMessage = ` ${labelPreposition} Origin`;
            } else if (activeStop.stopSequence > 1 && activeStop.stopType.toLowerCase() !== 'destination') {
                // if the stop sequence greater than 1 and stop type isnt destination, display 'in transit to Stop {{stopSequence}}'
                additionalMessage = ` ${labelPreposition} Stop ${activeStop.stopSequence}`;
            } else if (activeStop.stopType.toLowerCase() === 'destination') {
                // if stop type is destination, display 'in transit to destination'
                additionalMessage = ` ${labelPreposition} Destination`;
            }
        }
    } else if (shipmentStatus === ShipmentStatus.EnRoute) {
        // if status type is 'en route' add ' to Origin'
        additionalMessage = ' to Origin';
    }

    // Dont show tracking lost message if Shipment status is Pending, dispatched, delivered, completed, or cancelled
    const showTrackingLostMsg =
        shipmentStatus !== ShipmentStatus.Pending && shipmentStatus !== ShipmentStatus.Dispatched && shipmentStatus !== ShipmentStatus.Delivered &&
        shipmentStatus !== ShipmentStatus.Completed && shipmentStatus !== ShipmentStatus.Cancelled;

    if (deliveryStatus === DeliveryStatus.TrackingLost && showTrackingLostMsg === true) {
        trackingLostMessage = `${addSpaceBeforeUppercaseCharacter(deliveryStatus)} while `;
    }

    if (milesRemaining !== null) {
        // if the miles remaining is greater than the haul length, do not calculate the progress
        milesTraveled = (haulLength > milesRemaining) ? haulLength - milesRemaining : 0;
        progress = (milesTraveled !== 0 && haulLength !== 0) ? (milesTraveled / haulLength) * 100 : 0;

        // if the miles traveled is greater than zero get the status color to apply to the progress bar
        if (milesTraveled > 0) {
            color = getDeliveryStatusColor(deliveryStatus);
        }
    }

    // if status type is 'Delivered', 'Completed', or 'At Consignee' zero out miles remaining and make progress 100% no matter what
    if (shipmentStatus === ShipmentStatus.Delivered || shipmentStatus === ShipmentStatus.Completed || shipmentStatus === ShipmentStatus.AtConsignee) {
        milesTraveled = haulLength;
        progress = 100;
        milesRemaining = 0;
        color = getDeliveryStatusColor(deliveryStatus);
    } else if (shipmentStatus === ShipmentStatus.Cancelled) {
        // if status type is 'Cancelled' zero out progress no matter what
        milesTraveled = 0;
        progress = 0;
        milesRemaining = 0;
    }

    return (
        <StyledSection className={classes.haulLength} data-qa='haulLengthProgressBar-container'>
            <Typography className={classes.tripStatusText}>
                {
                    trackingLostMessage &&
                    <span data-qa='haulLengthProgressBar-trackingLost'>{trackingLostMessage}</span>
                }
                <span data-qa='haulLengthProgressBar-status'>{statusLabel}</span>
                {
                    additionalMessage &&
                    <span>{additionalMessage}</span>
                }
            </Typography>
            <ProgressBar percentage={progress} color={color} title={`% is based on ${measurementUnit} traveled out of LOH.`} />
            <Grid container data-qa='haulLengthProgressBar-tripInfo' className={classes.tripInfo}>
                <Grid item xs={6}>
                    <Typography data-qa='haulLengthProgressBar-milesTraveled' className={classes.tripInfoText}>{`${Math.trunc(milesTraveled)} ${measurementUnit} traveled`}</Typography>
                </Grid>
                {
                    milesRemaining !== null &&
                    <Grid item xs={6} className={classes.milesRemaining}>
                        <Typography data-qa='haulLengthProgressBar-milesRemaining' className={classes.tripInfoText}>{`${Math.trunc(milesRemaining)} ${measurementUnit} remaining`}</Typography>
                    </Grid>
                }
            </Grid>
        </StyledSection>
    );
};

export default HaulLengthProgressBar;
