import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { mdiThermometerAlert } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchShipmentTemperatures } from '../../redux/shipmentDetails';
import { ApiStatus } from '../../helpers/enums';
import CommonDialog from './common/commonDialog';
import TemperatureHistoryLineChart from '../charts/temperatureHistoryLineChart';

const TemperatureHistoryDialog = ({
    shipmentUniqueName,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const temperaturesStatus = useTypedSelector((state) => { return state.shipmentDetails.temperaturesStatus; });

    useEffect(() => {
        dispatch(fetchShipmentTemperatures(shipmentUniqueName));
    }, [dispatch, shipmentUniqueName]);

    // Don't allow the dialog to close if the API is still running
    const handleClose = (): void => {
        if (temperaturesStatus !== ApiStatus.Loading) {
            closeDialog();
        }
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerIcon={mdiThermometerAlert}
            headerText='Temperature History'
            content={(
                <TemperatureHistoryLineChart />
            )}
            actions={(
                <Button
                    disabled={temperaturesStatus === ApiStatus.Loading}
                    onClick={handleClose}
                    data-qa='close-button'
                >
                    Close
                </Button>
            )}
        />
    );
};

export default TemperatureHistoryDialog;
