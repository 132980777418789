import { ShipmentData } from '../../interfaces/services/shipmentDetails';
import { ShipmentTemperatures } from '../../interfaces/services/shipmentTemperatures';
import { ShipmentHistoryData } from '../../interfaces/services/shipmentHistory';
import {
    LinearUnit,
    TemperatureUnit,
    ShipmentStatus,
    DeliveryStatus,
    WeatherAlertSeverity,
    ModeType,
    MilestoneType
} from '../../helpers/enums';

export const initialShipmentDetailsState: ShipmentData = {
    // These fields come from the Shipment Details API
    createdDate: '',
    organization: {
        name: null,
        mailingAddressLine1: null,
        mailingAddressLine2: null,
        mailingPostalCode: null,
        mailingCountryCode: null,
        mailingCityName: null,
        mailingStateCode: null,
        localTimeZone: null,
        mailingPhoneNumber: null
    },
    driver: null,
    equipmentType: null,
    equipmentSize: null,
    lastStatusUpdate: null,
    freightHauler: {
        name: '',
        identifiers: []
    },
    haulLength: 0,
    remainingHaulLength: 0,
    haulLinearUnitType: LinearUnit.Miles,
    isHazardous: false,
    shareGuid: null,
    shipmentStatus: ShipmentStatus.Pending,
    shipmentTrackingType: '',
    shipmentUniqueName: '',
    isPriorityShipment: false,
    modeType: ModeType.Unknown,
    minimumTemperature: null,
    maximumTemperature: null,
    deliveryStatus: DeliveryStatus.OnTime,
    mobileTrackingNumber: null,
    isMobileTrackingActiveFlag: false,
    tripId: null,
    weatherSeverity: WeatherAlertSeverity.Unknown,
    hasTemperatureExceededBoundsFlag: false,
    mobileTrackingStatus: '',
    tractorReferenceNumber: null,
    trailerReferenceNumber: null,
    freightProviderReferenceNumber: '',
    currentPosition: null,
    notes: [],
    documents: [],
    deliveryExceptions: [],
    stops: [],
    referenceNumbers: [],
    orders: [],
    shipmentMilestones: [],
    totalShipmentAndOrderExceptions: 0,
    totalStopCount: 0,
    singleShipmentSharingToken: '',
    isActive: true,
    organizationContact: null,
    hasShipmentDocuments: false,
    hasShipmentNotes: false,

    // These fields come from the Shipment Details Route API
    breadcrumbPositions: [],
    plannedRoute: [],

    // NOTE: these fields don't come from an API
    currentMilestoneProgress: {
        furthest: MilestoneType.Pending,
        receivedDate: ''
    },
    pulsePositionEventId: null
};

export const initialShipmentTemperaturesState: ShipmentTemperatures = {
    temperatureRange: {
        minimumTemperature: 0,
        maximumTemperature: 0,
        temperatureUnitType: TemperatureUnit.Fahrenheit
    },
    temperatureHistory: []
};

export const initialShipmentHistoryState: ShipmentHistoryData = {
    organizationPreferredTimeZone: '',
    changeHistory: [],
    trackingAndStatusHistory: []
};
