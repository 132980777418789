import React, { Fragment, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';

import StopContactDialog from '../dialogs/stopContactDialog';

const classesPrefix = 'stopContactInfo';

const classes = {
    iconButton: `${classesPrefix}-iconButton`
};

const StyledIconButton = styled(IconButton)(() => {
    return {
        [`&.${classes.iconButton}`]: {
            padding: 0,
            marginLeft: '4px'
        }
    };
});

const StopContactInfo = ({
    contactName,
    contactPhone,
    contactEmail
}: {
    contactName: string | null;
    contactPhone: string | null;
    contactEmail: string | null;
}): JSX.Element => {
    const [stopContactDialogIsOpen, setStopContactDialogIsOpen] = useState(false);

    return (
        <Fragment>
            <Tooltip title='Click to view Contact Info'>
                <StyledIconButton
                    className={classes.iconButton}
                    onClick={(): void => {
                        setStopContactDialogIsOpen(true);
                    }}
                    data-qa='contactInfo-button'
                >
                    <Icon path={mdiInformationOutline} size='20px' />
                </StyledIconButton>
            </Tooltip>

            {
                stopContactDialogIsOpen &&
                <StopContactDialog
                    contactName={contactName}
                    contactPhoneNumber={contactPhone}
                    contactEmail={contactEmail}
                    isOpen={stopContactDialogIsOpen}
                    closeDialog={(): void => {
                        setStopContactDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default StopContactInfo;
