import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';
import LogoUploader from '../../fileUploaders/logoUploader';

const CustomizeLogoCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleRemoveCustomLogo = (): void => {
        dispatch(editPreferences(PreferencesFieldNames.customLogoToken, null));
    };

    const handleCustomLogoUpload = (logoToUpload: File): void => {
        dispatch(editPreferences(PreferencesFieldNames.customLogoToken, logoToUpload));
    };

    return (
        <PreferenceCard header='Customize Logo'>
            <Typography component='p' variant='body2'>
                Upload your logo. Logo cannot be more than 2mb and must be at least 70x70 pixels.
            </Typography>

            <LogoUploader
                hasCustomLogo={organizationEditablePreferences.customLogoToken !== null}
                preferencesCustomLogo={organizationEditablePreferences.customLogoToken}
                handleRemoveCustomLogo={handleRemoveCustomLogo}
                handleCustomLogoToUpload={handleCustomLogoUpload}
            />
        </PreferenceCard>
    );
};

export default CustomizeLogoCard;
