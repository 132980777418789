import {
    MapSources,
    MapLayers,
    MapLayerTypes,
    MapImages,
    LocationGeofenceFeatureTypes
} from '../enums';
import { MapLayer } from './interfaces';

/**
 * Creates a layer to display an image on the map for each Point and 'featureType' of 'location' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapLocationPointLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.locationPointLayer,
        type: MapLayerTypes.Symbol,
        source: mapSource,
        layout: {
            'icon-image': MapImages.locationMapMarker,
            'icon-size': 1.0
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['==', 'featureType', LocationGeofenceFeatureTypes.Location]
        ]
    };

    return layer;
};

/**
 * Adds the location point layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addLocationPointLayer = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.locationPointLayer) === undefined) {
        const layer = getMapLocationPointLayerGeo(mapSource);
        map.addLayer(layer);
    }
};

/**
 * Creates a layer to display a geofence polygon fill on the map for each Polygon and 'featureType' of 'geofence' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapLocationGeofenceFillLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.locationGeofenceFillLayer,
        type: MapLayerTypes.Fill,
        source: mapSource,
        paint: {
            'fill-color': '#2196F3',
            'fill-opacity': 0.2
        },
        filter: ['all',
            ['==', '$type', 'Polygon'],
            ['==', 'featureType', LocationGeofenceFeatureTypes.Geofence]
        ]
    };

    return layer;
};

/**
 * Creates a layer to display a geofence polygon outline on the map for each Polygon and 'featureType' of 'geofence' in the given map source.
 * @param mapSource Name of the map source
 */
const getMapLocationGeofenceOutlineLayerGeo = (mapSource: MapSources): MapLayer => {
    const layer: MapLayer = {
        id: MapLayers.locationGeofenceOutlineLayer,
        type: MapLayerTypes.Line,
        source: mapSource,
        paint: {
            'line-color': '#0e59c1',
            'line-width': 2
        },
        filter: ['all',
            ['==', '$type', 'Polygon'],
            ['==', 'featureType', LocationGeofenceFeatureTypes.Geofence]
        ]
    };

    return layer;
};

/**
 * Adds the location geofence fill layer to the map if it doesn't already exist.
 * Adds the location geofence outline layer to the map if it doesn't already exist.
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addLocationGeofenceLayers = (map: any, mapSource: MapSources): void => {
    if (map.getLayer(MapLayers.locationGeofenceFillLayer) === undefined) {
        const layer = getMapLocationGeofenceFillLayerGeo(mapSource);
        map.addLayer(layer);
    }
    if (map.getLayer(MapLayers.locationGeofenceOutlineLayer) === undefined) {
        const layer = getMapLocationGeofenceOutlineLayerGeo(mapSource);
        map.addLayer(layer);
    }
};
