import axios from 'axios';

const isUrlValid = (url: string): boolean => {
    if (url?.startsWith('http')) {
        return true;
    }

    console.error('Endpoint not found in service locator', url);
    return false;
};

const ApiService = {
    /**
     * Makes a GET call through axios
     * @param url URL of the API to call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async get({
        url,
        useBearerToken = true
    }: {
        url: string;
        useBearerToken?: boolean;
    }): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        const response = await axios({
            url,
            method: 'GET',
            metadata: {
                useBearerToken
            }
        });

        return {
            ...response.data,
            ...{ status: response.status }
        };
    },

    /**
     * Makes a PATCH call through axios
     * @param url URL of the API to call
     * @param body JSON body of the PATCH API call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async patch({
        url,
        body = {},
        useBearerToken = true
    }: {
        url: string;
        body?: object;
        useBearerToken?: boolean;
    }): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        const response = await axios({
            url,
            method: 'PATCH',
            data: body,
            metadata: {
                useBearerToken
            }
        });

        return {
            ...response.data,
            ...{ status: response.status }
        };
    },

    /**
     * Makes a POST call through axios
     * @param url URL of the API to call
     * @param body JSON body of the POST API call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async post({
        url,
        body = {},
        useBearerToken = true,
        customHeaders = {}
    }: {
        url: string;
        body?: object;
        useBearerToken?: boolean;
        customHeaders?: object;
    }): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        // custom headers must be passed in as part of the metadata object instead because the headers will get overwritten in React.StrictMode
        // https://github.com/axios/axios/issues/2825#issuecomment-784264784
        const response = await axios({
            url,
            method: 'POST',
            data: body,
            metadata: {
                useBearerToken,
                customHeaders
            }
        });

        return {
            ...response.data,
            ...{ status: response.status }
        };
    },

    /**
     * Makes a PUT call through axios
     * @param url URL of the API to call
     * @param body JSON body of the PUT API call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async put({
        url,
        body = {},
        useBearerToken = true,
        customHeaders = {}
    }: {
        url: string;
        body?: object;
        useBearerToken?: boolean;
        customHeaders?: object;
    }): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        const response = await axios({
            url,
            method: 'PUT',
            data: body,
            metadata: {
                useBearerToken,
                customHeaders
            }
        });

        return {
            ...response.data,
            ...{ status: response.status }
        };
    },

    /**
     * Makes a DELETE call through axios
     * @param url URL of the API to call
     * @param useBearerToken Indicator for if the bearer token should be passed in the API request
     */
    async delete({
        url,
        useBearerToken = true,
        customHeaders = {}
    }: {
        url: string;
        useBearerToken?: boolean;
        customHeaders?: object;
    }): Promise<unknown> {
        if (isUrlValid(url) === false) {
            throw new Error('Service endpoint is not valid');
        }

        const response = await axios({
            url,
            method: 'DELETE',
            metadata: {
                useBearerToken,
                customHeaders
            }
        });

        return {
            ...response.data,
            ...{ status: response.status }
        };
    }
};

export default ApiService;
