import React, { useState, useEffect, useRef } from 'react';
import {
    Paper,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import {
    mdiMap,
    mdiBrightness7,
    mdiSatellite
} from '@mdi/js';

import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import MapButton from '../buttons/mapButton';

const classesPrefix = 'mapStyleControlBar';

const classes = {
    menuWrapper: `${classesPrefix}-menuWrapper`,
    menuItem: `${classesPrefix}-menuItem`,
    menuItemText: `${classesPrefix}-menuItemText`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`& .${classes.menuWrapper}`]: {
            position: 'absolute',
            left: '36px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 30
        },
        [`& .${classes.menuItem}`]: {
            color: theme.palette.text.secondary,
            '&.Mui-selected': {
                backgroundColor: 'transparent',
                color: theme.appColors.action
            }
        },
        [`& .${classes.menuItemText}`]: {
            fontSize: '13px'
        }
    };
});

const MapStyleControlBar = ({
    mapTileStyle,
    handleMapStyleMenuItemClick
}: {
    /** Current selected map tile style. */
    mapTileStyle: string;
    /** Function to handle the change of the map style. */
    handleMapStyleMenuItemClick: (mapStyle: string) => void;
}): JSX.Element => {
    const styleControlWrapper = useRef<HTMLDivElement>(null);
    const [isMapMenuStyleOpen, setIsMapMenuStyleOpen] = useState(false);

    const handleDocumentClick = (e: any): void => {
        // if the styleControlWrapper is not null it means the menu is in the nav so check if the click was not a child element of the element
        if (styleControlWrapper.current !== null && !styleControlWrapper.current.contains(e.target)) {
            setIsMapMenuStyleOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleDocumentClick);
        // remove listener when unmounted
        return ((): void => {
            document.removeEventListener('mousedown', handleDocumentClick);
        });
    }, []);

    const toggleMapStyleMenu = (): void => {
        setIsMapMenuStyleOpen(!isMapMenuStyleOpen);
    };

    const handleMenuItemClick = (mapStyle: string): void => {
        setIsMapMenuStyleOpen(false);
        handleMapStyleMenuItemClick(mapStyle);
    };

    return (
        <StyledDiv ref={styleControlWrapper}>
            <MapButton
                title='Map Style'
                isActive={false}
                mdiIcon={mdiMap}
                handleMapButtonClick={toggleMapStyleMenu}
                data-qa='mapStyleMenu-action-toggle'
            />
            {
                isMapMenuStyleOpen === true &&
                <Paper elevation={3} className={classes.menuWrapper} data-qa='mapStyleMenu-container'>
                    <MenuList>
                        <MenuItem
                            className={classes.menuItem}
                            selected={mapTileStyle === TrimbleMaps.Common.Style.TRANSPORTATION}
                            onClick={(): void => { handleMenuItemClick(TrimbleMaps.Common.Style.TRANSPORTATION); }}
                            data-qa='mapStyleMenuItem-standard'
                        >
                            <ListItemIcon>
                                <Icon path={mdiMap} size={1} />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.menuItemText
                                }}
                                primary='Standard'
                            />
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                            selected={mapTileStyle === TrimbleMaps.Common.Style.TRANSPORTATION_DARK}
                            onClick={(): void => { handleMenuItemClick(TrimbleMaps.Common.Style.TRANSPORTATION_DARK); }}
                            data-qa='mapStyleMenuItem-dark'
                        >
                            <ListItemIcon>
                                <Icon path={mdiBrightness7} size={1} />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.menuItemText
                                }}
                                primary='Dark'
                            />
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                            selected={mapTileStyle === TrimbleMaps.Common.Style.SATELLITE}
                            onClick={(): void => { handleMenuItemClick(TrimbleMaps.Common.Style.SATELLITE); }}
                            data-qa='mapStyleMenuItem-satellite'
                        >
                            <ListItemIcon>
                                <Icon path={mdiSatellite} size={1} />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.menuItemText
                                }}
                                primary='Satellite'
                            />
                        </MenuItem>
                    </MenuList>
                </Paper>
            }
        </StyledDiv>
    );
};

export default MapStyleControlBar;
