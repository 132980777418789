import React, { Fragment, useMemo } from 'react';
import { Grid, Paper, Tooltip as MuiTooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import Icon from '@mdi/react';
import { mdiThermometerAlert } from '@mdi/js';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ReferenceLine,
    ResponsiveContainer,
    Label,
    TooltipProps
} from 'recharts';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { displayDateTimeFormat } from '../../helpers/dateUtils';
import { TemperatureHistory } from '../../interfaces/services/shipmentTemperatures';
import HorizontallyCenteredSpinner from '../loaders/horizontallyCenteredSpinner';
import LabelValuePair from '../labels/labelValuePair';
import LabelValuePairLoading from '../loaders/labels/labelValuePairLoading';

interface CustomTemperatureHistory extends TemperatureHistory {
    temperatureTime: number;
}

const classesPrefix = 'temperatureHistoryLineChart';

const classes = {
    breachWrapper: `${classesPrefix}-breachWrapper`,
    tooltipWrapper: `${classesPrefix}-tooltipWrapper`,
    tooltipLabel: `${classesPrefix}-tooltipLabel`,
    tooltipValue: `${classesPrefix}-tooltipValue`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.breachWrapper}`]: {
            display: 'inline-flex'
        },
        [`& .${classes.tooltipWrapper}`]: {
            padding: '8px'
        },
        [`& .${classes.tooltipLabel}`]: {
            fontSize: '14px',
            fontWeight: 600
        },
        [`& .${classes.tooltipValue}`]: {
            fontSize: '12px'
        }
    };
});

const TemperatureHistoryLineChart = (): JSX.Element => {
    const theme = useTheme();

    const temperaturesStatus = useTypedSelector((state) => { return state.shipmentDetails.temperaturesStatus; });
    const temperatures = useTypedSelector((state) => { return state.shipmentDetails.temperatures; });

    const temperatureHistory = useMemo((): CustomTemperatureHistory[] => {
        if (temperaturesStatus !== ApiStatus.Idle && temperaturesStatus !== ApiStatus.Loading) {
            const list = temperatures.temperatureHistory.map((item): CustomTemperatureHistory => {
                // strip off the first 16 characters which will end after the minutes, e.g. 2019-04-24T04:30
                const dateTimeOnly = item.temperatureDateTime.substring(0, 16);

                return {
                    ...item,
                    // add on the epoch time using the date for the chart to respect it
                    temperatureTime: parseISO(dateTimeOnly).getTime()
                };
            });

            return list;
        }
        return [];
    }, [temperaturesStatus, temperatures]);

    if (temperaturesStatus === ApiStatus.Idle || temperaturesStatus === ApiStatus.Loading) {
        return (
            <StyledGrid container spacing={2}>
                <Grid item>
                    <LabelValuePairLoading label='Current Temperature' width='50px' />
                </Grid>
                <Grid item>
                    <LabelValuePairLoading label='Required Temperature' width='65px' />
                </Grid>
                <Grid item xs={12}>
                    <HorizontallyCenteredSpinner />
                </Grid>
            </StyledGrid>
        );
    }

    const degreeAndUnitLabel = `${String.fromCharCode(176)}${temperatures.temperatureRange.temperatureUnitType.charAt(0)}`;
    const currentTemperatureDetails = temperatures.temperatureHistory.length > 0 ? temperatures.temperatureHistory[temperatures.temperatureHistory.length - 1] : null;

    const renderCustomizedXAxisTick = (chartProps: any): JSX.Element => {
        const {
            x, y, payload
        } = chartProps;

        return (
            <text
                x={x}
                y={y}
                dy={10}
                textAnchor='middle'
                fill={theme.palette.text.primary}
                fontSize={12}
                fontWeight={400}
            >
                {format(payload.value, displayDateTimeFormat)}
            </text>
        );
    };

    const renderCustomizedDot = (chartProps: any): JSX.Element => {
        const {
            cx, cy, payload
        } = chartProps;

        return (
            <circle
                key={`circle-${cx}${cy}`}
                cx={cx}
                cy={cy}
                r={4}
                stroke={payload.temperatureBreach ? theme.palette.error.dark : theme.palette.primary.main}
                fill={theme.palette.common.white}
                strokeWidth={1}
            />
        );
    };

    const renderTemperatureBreachIcon = (): JSX.Element => {
        return (
            <MuiTooltip title='Temperature Breach'>
                <div data-qa='temperatureBreach-icon'>
                    <Icon
                        path={mdiThermometerAlert}
                        size='20px'
                        color={theme.palette.error.main}
                    />
                </div>
            </MuiTooltip>
        );
    };

    const renderCustomToolTip = ({ active, payload, label }: TooltipProps<number, string>): JSX.Element => {
        if (active && payload && payload.length > 0) {
            return (
                <Paper className={classes.tooltipWrapper}>
                    <Typography className={classes.tooltipLabel} variant='body1'>
                        {format(label, displayDateTimeFormat)}
                    </Typography>
                    <Typography className={classes.tooltipValue} variant='body2' component='div'>
                        <span>Temperature: </span>
                        <span className={classes.breachWrapper}>
                            {`${payload[0].value}${degreeAndUnitLabel}`}
                            {
                                payload[0].payload.temperatureBreach &&
                                renderTemperatureBreachIcon()
                            }
                        </span>
                    </Typography>
                </Paper>
            );
        }

        return <Fragment />;
    };

    return (
        <StyledGrid container spacing={2}>
            <Grid item className={classes.breachWrapper}>
                <LabelValuePair
                    label='Current Temperature'
                    value={`${currentTemperatureDetails?.temperature}${degreeAndUnitLabel}`}
                    data-qa='currentTemperature'
                />
                {
                    currentTemperatureDetails?.temperatureBreach &&
                    renderTemperatureBreachIcon()
                }
            </Grid>
            <Grid item>
                <LabelValuePair
                    label='Required Temperature'
                    value={`${temperatures.temperatureRange.minimumTemperature}${degreeAndUnitLabel} - ${temperatures.temperatureRange.maximumTemperature}${degreeAndUnitLabel}`}
                    data-qa='requiredTemperature'
                />
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width='100%' height={300}>
                    <LineChart
                        data={temperatureHistory}
                        margin={{
                            top: 20,
                            right: 10,
                            left: 10,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                            dataKey='temperatureTime'
                            domain={['min', 'max']}
                            tick={renderCustomizedXAxisTick}
                            type='number'
                        />
                        <YAxis
                            dataKey='temperature'
                            domain={['auto', 'auto']}
                            interval='preserveStartEnd'
                            stroke={theme.palette.text.primary}
                            label={{
                                value: `Temperature ${degreeAndUnitLabel}`,
                                angle: -90,
                                dx: -25,
                                position: 'outsideLeft',
                                fill: theme.palette.text.primary
                            }}
                        />
                        <Tooltip
                            content={renderCustomToolTip}
                        />
                        <ReferenceLine
                            y={temperatures.temperatureRange.minimumTemperature}
                            label={<Label value='Min' fill={theme.palette.text.primary} />}
                            ifOverflow='extendDomain'
                            stroke={theme.palette.error.dark}
                        />
                        <ReferenceLine
                            y={temperatures.temperatureRange.maximumTemperature}
                            label={<Label value='Max' fill={theme.palette.text.primary} />}
                            ifOverflow='extendDomain'
                            stroke={theme.palette.error.dark}
                        />
                        <Line type='monotone' dataKey='temperature' name='Temperature' stroke={theme.palette.primary.main} dot={renderCustomizedDot} />
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
        </StyledGrid>
    );
};

export default TemperatureHistoryLineChart;
