import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { fetchDriverDetails } from '../../redux/drivers';
import { ApiStatus } from '../../helpers/enums';
import { formatPhoneNumber } from '../../helpers/dataUtils';
import { Driver } from '../../interfaces/services/shipmentDetails';
import Hidden from '../layouts/hidden';
import LabelValuePair from '../labels/labelValuePair';
import DriverDetailsLoading from '../loaders/labels/driverDetailsLoading';

const classesPrefix = 'driverDetails';

const classes = {
    sectionHeader: `${classesPrefix}-sectionHeader`,
    driverImage: `${classesPrefix}-driverImage`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`& .${classes.sectionHeader}`]: {
            fontSize: '12px'
        },
        [`& .${classes.driverImage}`]: {
            width: '100px',
            height: '100px',
            maxWidth: '100%'
        },
        [theme.breakpoints.down('lg')]: {
            [`& .${classes.driverImage}`]: {
                width: '75px',
                height: '75px'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`& .${classes.sectionHeader}`]: {
                marginTop: '8px'
            }
        }
    };
});

const DriverDetails = ({
    isFetching = false,
    driver,
    mobileTrackingNumber
}: {
    isFetching?: boolean;
    driver: Driver | null;
    mobileTrackingNumber: string | null;
}): JSX.Element => {
    const dispatch = useDispatch();

    const driverDetailsStatus = useTypedSelector((state) => { return state.drivers.driverDetailsStatus; });
    const driverDetails = useTypedSelector((state) => { return state.drivers.driverDetails; });

    useEffect((): void => {
        if (!isFetching) {
            dispatch(fetchDriverDetails({ mobileTrackingNumber }));
        }
    }, [isFetching, mobileTrackingNumber, dispatch]);

    const renderAvatar = (): JSX.Element => {
        return (
            <Avatar
                className={classes.driverImage}
                alt='Driver Photograph'
                src={driverDetails.driverImage || undefined}
                data-qa='driverImage'
            >
                <Typography>{`${driverDetails.firstName.charAt(0)} ${driverDetails.lastName.charAt(0)}`}</Typography>
            </Avatar>
        );
    };

    if (isFetching || driverDetailsStatus === ApiStatus.Idle || driverDetailsStatus === ApiStatus.Loading) {
        return (
            <DriverDetailsLoading />
        );
    }

    if (driverDetails.driverGuid === '' || driverDetailsStatus === ApiStatus.Failure) {
        return (
            <StyledGrid container>
                <Grid item xs={12}>
                    <Typography className={classes.sectionHeader} color='textSecondary'>
                        Driver Info
                    </Typography>
                    <LabelValuePair
                        label='Name'
                        value={driver?.firstName && driver?.lastName ? `${driver.firstName} ${driver.lastName}` : `${driver?.firstName || ''}${driver?.lastName || ''}`}
                        data-qa='driverName'
                    />
                    <LabelValuePair
                        label='Phone'
                        value={
                            driver?.phoneNumber &&
                            <Link href={`tel:${driver.phoneNumber}`}>
                                {formatPhoneNumber(driver.phoneNumber)}
                            </Link>
                        }
                        data-qa='driverPhone'
                    />
                    <LabelValuePair
                        label='Email'
                        value={
                            driver?.emailAddress &&
                            <Link href={`mailto:${driver.emailAddress}`}>
                                {driver.emailAddress}
                            </Link>
                        }
                        data-qa='driverEmail'
                    />
                    {
                        mobileTrackingNumber &&
                        <LabelValuePair
                            label='Mobile Tracking Number'
                            value={
                                <Link href={`tel:${mobileTrackingNumber}`}>
                                    {formatPhoneNumber(mobileTrackingNumber)}
                                </Link>
                            }
                            data-qa='mobileTrackingNumber'
                        />
                    }
                </Grid>
            </StyledGrid>
        );
    }

    return (
        <StyledGrid container alignItems='center' spacing={1}>
            <Hidden breakpoint='lg' direction='down'>
                <Grid item xs={3}>
                    {renderAvatar()}
                </Grid>
            </Hidden>
            <Grid item xs={12} lg={9}>
                <Typography className={classes.sectionHeader} color='textSecondary'>
                    Driver Info
                </Typography>
                <Hidden breakpoint='lg' direction='up'>
                    {renderAvatar()}
                </Hidden>
                <LabelValuePair
                    label='Name'
                    value={`${driverDetails.firstName} ${driverDetails.lastName}`}
                    noWrap
                    data-qa='driverName'
                />
                <LabelValuePair
                    label='Phone'
                    value={
                        <Link href={`tel:${driverDetails.phoneNumber}`}>
                            {formatPhoneNumber(driverDetails.phoneNumber)}
                        </Link>
                    }
                    data-qa='driverPhone'
                />
                {
                    driverDetails.emailAddress &&
                    <LabelValuePair
                        label='Email'
                        value={
                            <Link href={`mailto:${driverDetails.emailAddress}`}>
                                {driverDetails.emailAddress}
                            </Link>
                        }
                        noWrap
                        data-qa='driverEmail'
                    />
                }
                {
                    driverDetails.note &&
                    <LabelValuePair
                        label='Note'
                        value={driverDetails.note}
                        data-qa='driverNote'
                    />
                }
            </Grid>
        </StyledGrid>
    );
};

export default DriverDetails;
