import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { shipmentsToHistoryPageTimeOptions } from '../../../helpers/hardcodedOptionLists';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'deliveredShipmentsToHistoryPageCard';

const classes = {
    selectField: `${classesPrefix}-selectField`
};

const StyledSelect = styled(Select)(() => {
    return {
        [`&.${classes.selectField}`]: {
            marginTop: '16px',
            maxWidth: '320px'
        }
    };
});

const DeliveredShipmentsToHistoryPageCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleShipmentsToHistoryPageTimeframeInHoursChange = (event: SelectChangeEvent<unknown>): void => {
        dispatch(editPreferences(PreferencesFieldNames.shipmentsToHistoryPageTimeframeInHours, Number(event.target.value)));
    };

    return (
        <PreferenceCard header='Delivered Shipments to History Page'>
            <Typography component='p' variant='body2'>
                When should shipments be moved to the history page?
            </Typography>
            <StyledSelect
                className={classes.selectField}
                variant='standard'
                value={organizationEditablePreferences.shipmentsToHistoryPageTimeframeInHours}
                onChange={handleShipmentsToHistoryPageTimeframeInHoursChange}
                inputProps={{
                    'data-qa': 'shipmentToHistory-input'
                }}
            >
                {
                    shipmentsToHistoryPageTimeOptions.map((option) => {
                        return (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                data-qa={`shipmentToHistory-item-${option.label}`}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })
                }
            </StyledSelect>
        </PreferenceCard>
    );
};

export default DeliveredShipmentsToHistoryPageCard;
