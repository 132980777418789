import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import Hidden from '../../layouts/hidden';
import LabelValuePairLoading from '../../loaders/labels/labelValuePairLoading';

const classesPrefix = 'shipmentDetailsCardLoading';

const classes = {
    addressContainer: `${classesPrefix}-addressContainer`,
    stopTitle: `${classesPrefix}-stopTitle`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.addressContainer}`]: {
            marginBottom: '4px'
        },
        [`& .${classes.stopTitle}`]: {
            fontWeight: 600,
            fontSize: '14px',
            paddingBottom: '4px'
        }
    };
});

const ShipmentDetailsCardLoading = ({
    showShipmentCardIcon,
    showShipmentDetailsNavigation
}: {
    /** Optionally shows a skeleton for the shipment card icon. */
    showShipmentCardIcon?: boolean;
    /** Optionally shows a skeleton for the shipment details navigation link. */
    showShipmentDetailsNavigation?: boolean;
}): JSX.Element => {
    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid container alignItems='center' spacing={1}>
                            {
                                showShipmentCardIcon &&
                                <Grid item xs={1} sm='auto'>
                                    <Skeleton variant='circular' width='28px' height='28px' />
                                </Grid>
                            }
                            <Grid item xs={showShipmentCardIcon ? 11 : 12} sm='auto'>
                                <Skeleton variant='text' width='70px' />
                            </Grid>
                            {
                                showShipmentDetailsNavigation &&
                                <Grid item xs={12} sm='auto'>
                                    <Skeleton variant='text' width='120px' />
                                </Grid>
                            }
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                            <Grid item>
                                <Skeleton variant='circular' width='28px' height='28px' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Skeleton variant='rectangular' width='100px' height='34px' />
                    </Grid>
                </Grid>

                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6}>
                        <LabelValuePairLoading label='Mode' width='70px' fontSize='16px' />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <LabelValuePairLoading label='Carrier' width='120px' fontSize='16px' />
                    </Grid>
                </Grid>

                <Grid container className={classes.addressContainer} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.stopTitle}>Origin</Typography>
                        <Skeleton variant='text' width='80px' />
                        <Skeleton variant='text' width='120px' />
                        <Skeleton variant='text' width='120px' />
                        <Hidden breakpoint='md' direction='up'>
                            <Skeleton variant='text' width='160px' height='24px' />
                        </Hidden>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography className={classes.stopTitle}>Destination</Typography>
                        <Skeleton variant='text' width='80px' />
                        <Skeleton variant='text' width='120px' />
                        <Skeleton variant='text' width='120px' />
                        <Hidden breakpoint='md' direction='up'>
                            <Skeleton variant='text' width='160px' height='24px' />
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden breakpoint='md' direction='down'>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Skeleton variant='text' width='160px' height='24px' />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant='text' width='160px' height='24px' />
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </StyledGrid>
    );
};

export default ShipmentDetailsCardLoading;
