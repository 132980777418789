import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, DeliveryStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { ShipmentServiceData, ShipmentData, ShipmentRouteData } from '../../interfaces/services/shipmentDetails';
import { ShipmentTemperatures } from '../../interfaces/services/shipmentTemperatures';
import { ShipmentDetailsMapWeatherAlert, TrimbleMapsCountyInformation, WeatherAlert } from '../../interfaces/services/shipmentWeather';
import { ShipmentHistoryData } from '../../interfaces/services/shipmentHistory';
import { initialShipmentDetailsState, initialShipmentTemperaturesState, initialShipmentHistoryState } from './initialState';
import { determineCurrentMilestoneProgressData } from '../../helpers/shipmentUtils';
import {
    UPDATE_TRACTOR_TRAILER_SUCCESS,
    UPDATE_MOBILE_TRACKING_SUCCESS,
    ADD_SHIPMENT_NOTE_SUCCESS,
    ADD_SHIPMENT_STOP_NOTE_SUCCESS,
    UPLOAD_DOCUMENT_SUCCESS,
    UPDATE_STOP_TIMES_SUCCESS,
    UPDATE_SHIPMENT_PRIORITY,
    UPDATE_SHIPMENT_STATUS_SUCCESS,
    updateTractorTrailerSuccess,
    updateMobileTrackingSuccess,
    addShipmentNoteSuccess,
    addShipmentStopNoteSuccess,
    uploadDocumentSuccess,
    updateShipmentPrioritySuccess,
    updateStopTimesSuccess,
    updateShipmentStatusSuccess
} from '../dialogUpdates';
import { GenericApiResponse } from '../../interfaces/services';

const SHIPMENT_DETAILS_REQUEST = 'SHIPMENT_DETAILS_REQUEST';
const SHIPMENT_DETAILS_SUCCESS = 'SHIPMENT_DETAILS_SUCCESS';
const SHIPMENT_DETAILS_FAILURE = 'SHIPMENT_DETAILS_FAILURE';

const SHIPMENT_TEMPERATURES_REQUEST = 'SHIPMENT_TEMPERATURES_REQUEST';
const SHIPMENT_TEMPERATURES_SUCCESS = 'SHIPMENT_TEMPERATURES_SUCCESS';
const SHIPMENT_TEMPERATURES_FAILURE = 'SHIPMENT_TEMPERATURES_FAILURE';

const SHIPMENT_WEATHER_REQUEST = 'SHIPMENT_WEATHER_REQUEST';
const SHIPMENT_WEATHER_SUCCESS = 'SHIPMENT_WEATHER_SUCCESS';
const SHIPMENT_WEATHER_FAILURE = 'SHIPMENT_WEATHER_FAILURE';

const SHIPMENT_HISTORY_REQUEST = 'SHIPMENT_HISTORY_REQUEST';
const SHIPMENT_HISTORY_SUCCESS = 'SHIPMENT_HISTORY_SUCCESS';
const SHIPMENT_HISTORY_FAILURE = 'SHIPMENT_HISTORY_FAILURE';

interface ShipmentDetailsData {
    shipmentStatus: ApiStatus;
    shipment: ShipmentData;
    temperaturesStatus: ApiStatus;
    temperatures: ShipmentTemperatures;
    weatherStatus: ApiStatus;
    weatherAlerts: WeatherAlert[];
    weatherPolygons: ShipmentDetailsMapWeatherAlert[];
    historyStatus: ApiStatus;
    history: ShipmentHistoryData;
}

const requestShipmentDetails = (): Action<typeof SHIPMENT_DETAILS_REQUEST> => {
    return {
        type: SHIPMENT_DETAILS_REQUEST
    };
};

const receiveShipmentDetails = (
    shipmentDetails: ShipmentServiceData,
    shipmentRoute: ShipmentRouteData = { breadcrumbPositions: [], plannedRoute: [] }
): GenericAction<typeof SHIPMENT_DETAILS_SUCCESS,
    {
        shipmentDetails: ShipmentServiceData;
        shipmentRoute: ShipmentRouteData;
    }> => {
    return {
        type: SHIPMENT_DETAILS_SUCCESS,
        payload: {
            shipmentDetails,
            shipmentRoute
        }
    };
};

const requestShipmentDetailsFailed = (): Action<typeof SHIPMENT_DETAILS_FAILURE> => {
    return {
        type: SHIPMENT_DETAILS_FAILURE
    };
};

const shipmentDetailsPromise = async (shipmentDetailsUri: string): Promise<ShipmentServiceData | null> => {
    try {
        const json = await ApiService.get({ url: shipmentDetailsUri }) as GenericApiResponse<ShipmentServiceData>;
        if (json.data.length > 0) {
            return json.data[0];
        }

        throw new Error('Missing Shipment Details');
    } catch (err) {
        toast.error('Error occurred while fetching shipment details.');
        return null;
    }
};

export const fetchShipmentDetails = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentDetails());
        const shipmentDetails = await shipmentDetailsPromise(`${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.details}/${shipmentUniqueName}`) as ShipmentServiceData;
        // Since the promise can either return the data or null, we will check that it's not null before calling it a success
        if (shipmentDetails !== null) {
            dispatch(receiveShipmentDetails(shipmentDetails));
        } else {
            // If it is null, we will trigger the failure for the shipment details so that the error page will render
            dispatch(requestShipmentDetailsFailed());
        }
    };
};

export const fetchShipmentDetailsAndRoute = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentDetails());

        const shipmentDetailsRoutePromise = async (): Promise<ShipmentRouteData | null> => {
            try {
                const json = await ApiService.get({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.details}/${shipmentUniqueName}/route` }) as GenericApiResponse<ShipmentRouteData>;
                if (json.data.length > 0) {
                    return json.data[0];
                }

                throw new Error('Missing Shipment Details Route');
            } catch (err) {
                toast.error('Error occurred while fetching shipment details route.');
                return null;
            }
        };

        Promise.all([
            shipmentDetailsPromise(`${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.details}/${shipmentUniqueName}`),
            shipmentDetailsRoutePromise()
        ]).then(([
            shipmentDetails,
            shipmentDetailsRoute
        ]): void => {
            // Since the promise can either return the data or null, we will check that it's not null before calling it a success
            if (shipmentDetails !== null) {
                // Since the route promise can either return the data or null, we will check the existence of the route data and default it before passing it to the reducer
                dispatch(receiveShipmentDetails(shipmentDetails, {
                    plannedRoute: shipmentDetailsRoute?.plannedRoute || [],
                    breadcrumbPositions: shipmentDetailsRoute?.breadcrumbPositions || []
                }));
            } else {
                // If it is null, we will trigger the failure for the shipment details so that the error page will render
                dispatch(requestShipmentDetailsFailed());
            }
        });
    };
};

const requestShipmentTemperatures = (): Action<typeof SHIPMENT_TEMPERATURES_REQUEST> => {
    return {
        type: SHIPMENT_TEMPERATURES_REQUEST
    };
};

const receiveShipmentTemperatures = (data: ShipmentTemperatures): GenericAction<typeof SHIPMENT_TEMPERATURES_SUCCESS, ShipmentTemperatures> => {
    return {
        type: SHIPMENT_TEMPERATURES_SUCCESS,
        payload: data
    };
};

const requestShipmentTemperaturesFailed = (): Action<typeof SHIPMENT_TEMPERATURES_FAILURE> => {
    return {
        type: SHIPMENT_TEMPERATURES_FAILURE
    };
};

export const fetchShipmentTemperatures = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentTemperatures());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.temperatures}/${shipmentUniqueName}/temperatures` }) as GenericApiResponse<ShipmentTemperatures>;

            const { data } = json;
            dispatch(receiveShipmentTemperatures(data[0]));
        } catch (err) {
            dispatch(requestShipmentTemperaturesFailed());
            toast.error('Error occurred while fetching shipment temperatures.');
        }
    };
};

const requestShipmentWeather = (): Action<typeof SHIPMENT_WEATHER_REQUEST> => {
    return {
        type: SHIPMENT_WEATHER_REQUEST
    };
};

const receiveShipmentWeather = (weatherAlerts: WeatherAlert[], weatherCountyInformation: TrimbleMapsCountyInformation[]): GenericAction<typeof SHIPMENT_WEATHER_SUCCESS,
    {
        weatherAlerts: WeatherAlert[];
        weatherCountyInformation: TrimbleMapsCountyInformation[];
    }> => {
    return {
        type: SHIPMENT_WEATHER_SUCCESS,
        payload: {
            weatherAlerts,
            weatherCountyInformation
        }
    };
};

const requestShipmentWeatherFailed = (): Action<typeof SHIPMENT_WEATHER_FAILURE> => {
    return {
        type: SHIPMENT_WEATHER_FAILURE
    };
};

export const fetchShipmentWeather = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentWeather());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.weather}/${shipmentUniqueName}/weather` }) as GenericApiResponse<WeatherAlert>;
            const { data } = json;

            // create a list of all fips codes across all alerts and then make sure it's a unique list
            const allFipsCodes = data.map((alert: WeatherAlert) => {
                return alert.fipsCodes;
            });
            const uniqueFipsCodes = Array.from(new Set(allFipsCodes.flat()));

            // The Trimble Maps API can only handle 200 fips codes at a time, so we need to chunk up the list to make multiple calls if there are more.
            const size = 200;
            const chunkedArray: string[][] = [];
            let chunkIndex = 0;
            while (chunkIndex < uniqueFipsCodes.length) {
                chunkedArray.push(uniqueFipsCodes.slice(chunkIndex, size + chunkIndex));
                chunkIndex += size;
            }

            // The ALK polygons endpoint needs the query strings formatted slightly different than regular query strings
            const toQueryString = (paramsObject: Record<string, unknown>): string => {
                return Object.keys(paramsObject).map((key) => {
                    return `${encodeURIComponent(key)}=${encodeURIComponent((paramsObject as any)[key])}`;
                }).join('&');
            };

            const trimbleMapsCountyPromises = chunkedArray.map((codes: string[]): Promise<any> => {
                const options = {
                    method: 'GET',
                    headers: {
                        Authorization: 'A413EAA4767EC44E94A2360AE03B8689',
                        'Content-Type': 'application/json'
                    }
                };

                return fetch(`${Endpoints.trimbleMaps.polygonsCounty}?${toQueryString({ codes })}`, options)
                    .then((response): Promise<any> => {
                        if (response.status === 200) {
                            return response.json();
                        }
                        toast.error('Failed to retrieve polygon information for FIPS codes');
                        console.error(`Failed to retrieve polygon information for FIPS codes: ${codes}.`);
                        return Promise.resolve([]);
                    }, (error): void => {
                        toast.error('Fatal error while retrieving polygon information for FIPS codes');
                        console.error(`Fatal error while retrieving polygon information for FIPS codes: ${codes}. Error: ${error}`);
                    })
                    .then((trimbleMapsJson: TrimbleMapsCountyInformation[]): TrimbleMapsCountyInformation[] => {
                        if (trimbleMapsJson !== undefined) {
                            return trimbleMapsJson;
                        }
                        return [];
                    });
            });

            Promise.all(trimbleMapsCountyPromises).then((countyInformation: TrimbleMapsCountyInformation[][]): void => {
                const flattenedCountyInformation = countyInformation.flat();
                dispatch(receiveShipmentWeather(data, flattenedCountyInformation));
            });
        } catch (error) {
            dispatch(requestShipmentWeatherFailed());
            toast.error('Error occurred while fetching shipment weather.', error);
        }
    };
};

const requestShipmentHistory = (): Action<typeof SHIPMENT_HISTORY_REQUEST> => {
    return {
        type: SHIPMENT_HISTORY_REQUEST
    };
};

const receiveShipmentHistory = (data: ShipmentHistoryData): GenericAction<typeof SHIPMENT_HISTORY_SUCCESS, ShipmentHistoryData> => {
    return {
        type: SHIPMENT_HISTORY_SUCCESS,
        payload: data
    };
};

const requestShipmentHistoryFailed = (): Action<typeof SHIPMENT_HISTORY_FAILURE> => {
    return {
        type: SHIPMENT_HISTORY_FAILURE
    };
};

export const fetchShipmentHistory = (shipmentUniqueName: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentHistory());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.history}/${shipmentUniqueName}/history` }) as GenericApiResponse<ShipmentHistoryData>;

            const { data } = json;
            dispatch(receiveShipmentHistory(data[0]));
        } catch (err) {
            dispatch(requestShipmentHistoryFailed());
            toast.error('Error occurred while fetching shipment history.');
        }
    };
};

type ShipmentDetailsActionTypes =
    ReturnType<typeof requestShipmentDetails> | ReturnType<typeof receiveShipmentDetails> | ReturnType<typeof requestShipmentDetailsFailed> |
    ReturnType<typeof requestShipmentTemperatures> | ReturnType<typeof receiveShipmentTemperatures> | ReturnType<typeof requestShipmentTemperaturesFailed> |
    ReturnType<typeof requestShipmentWeather> | ReturnType<typeof receiveShipmentWeather> | ReturnType<typeof requestShipmentWeatherFailed> |
    ReturnType<typeof requestShipmentHistory> | ReturnType<typeof receiveShipmentHistory> | ReturnType<typeof requestShipmentHistoryFailed> |
    ReturnType<typeof updateTractorTrailerSuccess> | ReturnType<typeof updateMobileTrackingSuccess> |
    ReturnType<typeof addShipmentNoteSuccess> | ReturnType<typeof addShipmentStopNoteSuccess> |
    ReturnType<typeof uploadDocumentSuccess> | ReturnType<typeof updateStopTimesSuccess> | ReturnType<typeof updateShipmentPrioritySuccess> | ReturnType<typeof updateShipmentStatusSuccess>;

export const shipmentDetailsReducer = (shipmentDetailsData: ShipmentDetailsData = {
    shipmentStatus: ApiStatus.Idle,
    shipment: initialShipmentDetailsState,
    temperaturesStatus: ApiStatus.Idle,
    temperatures: initialShipmentTemperaturesState,
    weatherStatus: ApiStatus.Idle,
    weatherAlerts: [],
    weatherPolygons: [],
    historyStatus: ApiStatus.Idle,
    history: initialShipmentHistoryState
}, action: ShipmentDetailsActionTypes): ShipmentDetailsData => {
    switch (action.type) {
        case SHIPMENT_DETAILS_REQUEST: {
            return {
                ...shipmentDetailsData,
                shipmentStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_DETAILS_SUCCESS: {
            const currentMilestoneProgress = determineCurrentMilestoneProgressData(action.payload.shipmentDetails);
            return {
                ...shipmentDetailsData,
                shipmentStatus: ApiStatus.Success,
                shipment: {
                    ...action.payload.shipmentDetails,
                    ...action.payload.shipmentRoute,
                    currentMilestoneProgress,
                    pulsePositionEventId: action.payload.shipmentDetails.currentPosition !== null &&
                        action.payload.shipmentDetails.deliveryStatus !== DeliveryStatus.TrackingLost ?
                        action.payload.shipmentDetails.currentPosition.positionEventId :
                        null
                }
            };
        }
        case SHIPMENT_DETAILS_FAILURE: {
            return {
                ...shipmentDetailsData,
                shipmentStatus: ApiStatus.Failure,
                shipment: initialShipmentDetailsState
            };
        }
        case SHIPMENT_TEMPERATURES_REQUEST: {
            return {
                ...shipmentDetailsData,
                temperaturesStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_TEMPERATURES_SUCCESS: {
            return {
                ...shipmentDetailsData,
                temperaturesStatus: ApiStatus.Success,
                temperatures: action.payload
            };
        }
        case SHIPMENT_TEMPERATURES_FAILURE: {
            return {
                ...shipmentDetailsData,
                temperaturesStatus: ApiStatus.Failure,
                temperatures: initialShipmentTemperaturesState
            };
        }
        case SHIPMENT_WEATHER_REQUEST: {
            return {
                ...shipmentDetailsData,
                weatherStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_WEATHER_SUCCESS: {
            const { weatherAlerts, weatherCountyInformation } = action.payload;
            const weatherPolygons: ShipmentDetailsMapWeatherAlert[] = [];

            weatherCountyInformation.forEach((county: TrimbleMapsCountyInformation): void => {
                const weatherAlertsForFipsCode = weatherAlerts.filter((alert): boolean => {
                    return alert.fipsCodes.includes(county.Code);
                });

                if (county.Polygon) {
                    // the response from trimble maps can be a polygon or multipolygon,
                    // so we check to see what the string starts with and will do different logic for each
                    if (county.Polygon.startsWith('P')) {
                        weatherPolygons.push({
                            alertList: weatherAlertsForFipsCode,
                            fipsCode: county.Code,
                            countyName: county.Name,
                            polygon: county.Polygon
                        });
                    } else if (county.Polygon.startsWith('M')) {
                        // split the multipolygons up and then get the data points for each polygon
                        const splitMultiPolygons = county.Polygon.split(')), ((');
                        splitMultiPolygons.forEach((singlePolygon) => {
                            weatherPolygons.push({
                                alertList: weatherAlertsForFipsCode,
                                fipsCode: county.Code,
                                countyName: county.Name,
                                polygon: singlePolygon
                            });
                        });
                    }
                }
            });

            return {
                ...shipmentDetailsData,
                weatherStatus: ApiStatus.Success,
                weatherAlerts,
                weatherPolygons
            };
        }
        case SHIPMENT_WEATHER_FAILURE: {
            return {
                ...shipmentDetailsData,
                weatherStatus: ApiStatus.Failure,
                weatherAlerts: [],
                weatherPolygons: []
            };
        }
        case SHIPMENT_HISTORY_REQUEST: {
            return {
                ...shipmentDetailsData,
                historyStatus: ApiStatus.Loading
            };
        }
        case SHIPMENT_HISTORY_SUCCESS: {
            return {
                ...shipmentDetailsData,
                historyStatus: ApiStatus.Success,
                history: action.payload
            };
        }
        case SHIPMENT_HISTORY_FAILURE: {
            return {
                ...shipmentDetailsData,
                historyStatus: ApiStatus.Failure,
                history: initialShipmentHistoryState
            };
        }
        case UPDATE_TRACTOR_TRAILER_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        ...action.payload.assets?.tractorReferenceNumber !== undefined && {
                            tractorReferenceNumber: action.payload.assets.tractorReferenceNumber
                        },
                        ...action.payload.assets?.trailerReferenceNumber !== undefined && {
                            trailerReferenceNumber: action.payload.assets.trailerReferenceNumber
                        }
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPDATE_MOBILE_TRACKING_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        mobileTrackingNumber: action.payload.driverPhoneNumber
                    }
                };
            }
            return shipmentDetailsData;
        }
        case ADD_SHIPMENT_NOTE_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        notes: [...shipmentDetailsData.shipment.notes, action.payload.note],
                        hasShipmentNotes: true
                    }
                };
            }
            return shipmentDetailsData;
        }
        case ADD_SHIPMENT_STOP_NOTE_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        stops: shipmentDetailsData.shipment.stops.map((stop) => {
                            if (stop.shipmentStopGUID === action.payload.shipmentStopGUID) {
                                return {
                                    ...stop,
                                    stopNotes: [...stop.stopNotes, action.payload.note]
                                };
                            }
                            return stop;
                        })
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPLOAD_DOCUMENT_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        documents: [...shipmentDetailsData.shipment.documents, action.payload.document],
                        hasShipmentDocuments: true
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPDATE_STOP_TIMES_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        stops: shipmentDetailsData.shipment.stops.map((stop) => {
                            if (stop.stopSequence === action.payload.stopSequence) {
                                return {
                                    ...stop,
                                    ...action.payload.appointmentDate && action.payload.appointmentEndDate && {
                                        appointment: {
                                            startTime: action.payload.appointmentDate,
                                            endTime: action.payload.appointmentEndDate
                                        }
                                    },
                                    ...action.payload.estimatedDeliveryDate && {
                                        estimatedDeliveryDateTime: action.payload.estimatedDeliveryDate
                                    },
                                    ...action.payload.positionEventType === 'Arrived' && action.payload.reportedDate && {
                                        actualArrivalDateTime: action.payload.reportedDate
                                    },
                                    ...action.payload.positionEventType === 'Departed' && action.payload.reportedDate && {
                                        actualDepartureDateTime: action.payload.reportedDate
                                    }
                                };
                            }
                            return stop;
                        })
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPDATE_SHIPMENT_PRIORITY: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        isPriorityShipment: action.payload.isPriorityShipment
                    }
                };
            }
            return shipmentDetailsData;
        }
        case UPDATE_SHIPMENT_STATUS_SUCCESS: {
            if (shipmentDetailsData.shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                return {
                    ...shipmentDetailsData,
                    shipment: {
                        ...shipmentDetailsData.shipment,
                        shipmentStatus: action.payload.shipmentStatus
                    }
                };
            }
            return shipmentDetailsData;
        }
        default:
            return shipmentDetailsData;
    }
};
