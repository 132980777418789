import { MapLayerTypes, MapSources, MapLayers } from '../enums';
import { isVisibleUtil } from '../mapUtils';
import { MapLayer } from './interfaces';

/**
 * Creates a layer to display circles on the map for each Point in the shipmentListSource.
 */
const getMapShipmentListLayerGeo = (isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const layer: MapLayer = {
        id: MapLayers.shipmentListLayer,
        type: MapLayerTypes.Circle,
        source: MapSources.shipmentListSource,
        layout: {
            visibility
        },
        paint: {
            'circle-radius': {
                base: 8,
                stops: [
                    // zoom is 10 -> circle radius will be 10px
                    [10, 10],
                    [12.5, 15],
                    [15, 20],
                    [18, 25],
                    [19, 30],
                    [20, 40],
                    [21, 50],
                    [22, 60]
                ]
            },
            'circle-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
        },
        filter: ['==', '$type', 'Point']
    };

    return layer;
};

/**
 * Adds the shipment list layer to the map if it doesn't already exist
 * @param map Instance of your map
 */
export const addShipmentListLayer = (map: any, isVisible: boolean): void => {
    if (map.getLayer(MapLayers.shipmentListLayer) === undefined) {
        const shipmentListLayer = getMapShipmentListLayerGeo(isVisible);
        map.addLayer(shipmentListLayer);
    }
};

/**
 * Creates a layer to display circles on the map for each Point in the shipmentListSource that isn't inside of a cluster.
 */
const getMapShipmentListClusterLayerGeo = (isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const layer: MapLayer = {
        id: MapLayers.shipmentListClusterLayer,
        type: MapLayerTypes.Circle,
        source: MapSources.shipmentListClusterSource,
        layout: {
            visibility
        },
        paint: {
            'circle-radius': {
                base: 8,
                stops: [
                    // zoom is 10 -> circle radius will be 10px
                    [10, 10],
                    [12.5, 15],
                    [15, 20],
                    [18, 25],
                    [19, 30],
                    [20, 40],
                    [21, 50],
                    [22, 60]
                ]
            },
            'circle-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#ffffff'
        },
        filter: ['all',
            ['==', '$type', 'Point'],
            ['!=', 'cluster', true]
        ]
    };

    return layer;
};

/**
 * Adds the shipment list clustered layer to the map if it doesn't already exist
 * @param map Instance of your map
 */
export const addShipmentListClusterLayer = (map: any, isVisible: boolean): void => {
    if (map.getLayer(MapLayers.shipmentListClusterLayer) === undefined) {
        const shipmentListClusterLayer = getMapShipmentListClusterLayerGeo(isVisible);
        map.addLayer(shipmentListClusterLayer);
    }
};
