import {
    circle,
    polygon,
    Feature as TurfFeature,
    Polygon,
    Properties,
    Coord,
    Units
} from '@turf/turf';
import { Feature } from 'geojson';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';

import { Stop } from '../../interfaces/services/shipmentDetails';
import { DeliveryStatus, StopType } from '../enums';
import { getDeliveryStatusColor } from '../styleHelpers';
import { ClusterFeatureProperties, GeoFenceFeatureProperties } from './layers/interfaces';
import { MapLayers } from './enums';

/**
 * Using an incoming isVisible boolean, this will return the required layer visibility term used in the Trimble Maps layers.
 * @param isVisible Indicator for if the layer should be visible.
 */
export const isVisibleUtil = (isVisible: boolean): 'visible' | 'none' => {
    const visibility = isVisible ? 'visible' : 'none';
    return visibility;
};

/**
 * Using a polygon in a Well-Known-Text format, it will convert it into a polygon that the map will expect.
 * @param polygonGeofence Well-Known-Text string format of a polygon
 * @returns A polygon that can be used on the map
 */
export const getPolygonGeofencePointsFromWkt = (polygonGeofence: string): number[][] => {
    // this data is also coming across as a "Well-Known Text" format, so we need to get out the lat/longs array
    const polygonLatLongs = polygonGeofence.replace(/(\(|\))/gm, '').replace(/[a-zA-Z]+/gmi, '').trim();
    const positions = polygonLatLongs.split(', ');

    const polygonGeofencePoints = positions.map((position): number[] => {
        const positionSplit = position.split(' ');
        return [Number(positionSplit[0]), Number(positionSplit[1])];
    });

    return polygonGeofencePoints;
};

export const getGeofenceRadiusInMiles = (radiusInFeet: number): number => {
    const radiusInMiles = Number((Math.round((radiusInFeet * 0.000189394) * 100) / 100).toFixed(2));
    return radiusInMiles;
};

export const getGeofenceRadiusInFeet = (radiusInMiles: number): number => {
    const radiusInFeet = Number((radiusInMiles * 5280).toFixed(0));
    return radiusInFeet;
};

/**
 * Using a center point and a radius, it will return a geojson polygon feature.
 * @param center The center location for the circle
 * @param radius The radius for the circle in whatever unit you pass in
 * @param units The units of measure for the circle radius
 * @param featureProperties An optional object of additional properties to add to the geojson feature. If not supplied, it will default to an empty object.
 */
export const getGeofenceFeaturePointsCircle = (center: Coord, radius: number, units: Units, featureProperties: Properties = {}): TurfFeature<Polygon, Properties> => {
    const options = {
        units,
        properties: { ...featureProperties }
    };
    const circleFeature = circle(center, radius, options);

    return circleFeature;
};

/**
 * Using a list of Long/Lats, it will return a geojson polygon feature.
 * @param geofencePolygonPoints List of Long/Lats that make up the polygon.
 * @param featureProperties An optional object of additional properties to add to the geojson feature. If not supplied, it will default to an empty object.
 */
const getGeofenceFeaturePointsPolygon = (geofencePolygonPoints: number[][], featureProperties: Properties = {}): TurfFeature<Polygon, Properties> => {
    const polygonFeature = polygon([geofencePolygonPoints], featureProperties);

    return polygonFeature;
};

/**
 * Using the stop, this will return the geofence properties for it.
 * If there are coordinates for a polygon geofence, it will use that to determine the geofence feature using a Turf utility.
 * If there is a radius given, it will use that to build a circle geofence feature using a Turf utility.
 * If there are no geofence properties available, it will return undefined.
 * @param stop The stop to determine the geofence properties for
 */
export const getGeofenceFeaturePointsForStop = <T>(stop: Stop, featureType: T): Feature<Polygon, GeoFenceFeatureProperties<T>> | undefined => {
    const stopId = stop.stopType === StopType.Intermediate ? `geofence${stop.stopType}${(stop.stopSequence - 1).toString()}` : `geofence${stop.stopType}`;
    const stopLongLat = [stop.longitude, stop.latitude];

    let geofenceFeaturePoints: TurfFeature<Polygon, Properties> | undefined;

    let polygonPoints: number[][] | null = null;
    let radius = 0.01;
    if (stop.hasStopBeenVisited) {
        polygonPoints = stop.polygonGeofenceOut ? getPolygonGeofencePointsFromWkt(stop.polygonGeofenceOut) : null;
        radius = getGeofenceRadiusInMiles(stop.geofenceOutRadiusInFeet);
    } else {
        polygonPoints = stop.polygonGeofenceIn ? getPolygonGeofencePointsFromWkt(stop.polygonGeofenceIn) : null;
        radius = getGeofenceRadiusInMiles(stop.geofenceRadiusInFeet);
    }

    const geofenceFeatureProperties: GeoFenceFeatureProperties<T> = {
        id: stopId,
        featureType,
        color: stop.hasStopBeenVisited ? '#199B2D' : '#2196F3',
        outlineColor: stop.hasStopBeenVisited ? '#0C761D' : '#0e59c1'
    };

    if (polygonPoints) {
        geofenceFeaturePoints = getGeofenceFeaturePointsPolygon(polygonPoints, geofenceFeatureProperties);
    } else {
        // The geofence radius is required, but in case 0 is passed in we will short circuit the value to be a default 0.01 so that the map doesn't break
        geofenceFeaturePoints = getGeofenceFeaturePointsCircle(stopLongLat, radius || 0.01, 'miles', geofenceFeatureProperties);
    }

    const geofenceFeature = geofenceFeaturePoints as Feature<Polygon, GeoFenceFeatureProperties<T>> | undefined;
    return geofenceFeature;
};

/**
 * Toggles the visibility property of the layer given on the map.
 * @param map Instance of your map
 * @param layerName Specific Map Layer you want to toggle the visibility on
 * @param isVisible Indicator on whether the layer should be visible or not
 */
export const toggleLayerVisibility = (map: any, layerName: MapLayers, isVisible: boolean): void => {
    // Toggle a specific layers visibilty based on the state that is passed in
    const visibility = isVisibleUtil(isVisible);

    // first make sure that the layer exists first, as an extra safeguard
    if (map.getLayer(layerName) !== undefined) {
        map.setLayoutProperty(layerName, 'visibility', visibility);
    }
};

/**
 * Triggers the fitBounds method on the map in order to zoom the map into the set of bounds given.
 * @param map Instance of your map
 * @param bounds Set of bounds as determined by your various layers (new TrimbleMaps.LngLatBounds()).
 * @param zoomMap Indicator on whether the map should be zoomed or not.
 * @param padding Padding on the map to set on the fitBounds method. This is optional.
 * @param maxZoom The maximum amount of zoom to stop the map from zooming in too far. This is optional.
 */
export const fitMapToBounds = ({
    map,
    bounds,
    zoomMap,
    padding = {
        top: 45,
        bottom: 45,
        left: 80,
        right: 80
    },
    maxZoom
}: {
    map: any;
    bounds: any;
    zoomMap: boolean;
    padding?: {
        top: number;
        bottom: number;
        left: number;
        right: number;
    };
    maxZoom?: number;
}): void => {
    const options = {
        padding,
        ...(maxZoom !== undefined) && {
            maxZoom
        }
    };

    if (zoomMap) {
        map.fitBounds(bounds, options);
    }
};

/**
 * Create an instance of a popup to be used in the mouse event functions
 */
export const trimbleMapsPopup = new TrimbleMaps.Popup({
    offset: {
        bottom: [0, -20],
        left: [20, 0],
        right: [-20, 0],
        top: [0, 20]
    },
    maxWidth: '280px', // default is '240px'
    closeButton: false,
    closeOnClick: false
});

/**
 * Changes the cursor on the map to a pointer
 * @param map Instance of your map
 */
export const handleMouseEnter = (map: any): void => {
    // Change the cursor to a pointer when the it enters a feature in the layer.
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = 'pointer';
};

/**
 * Sets the mouse cursor back to the default
 * @param map Instance of your map
 */
export const handleMouseLeave = (map: any): void => {
    // eslint-disable-next-line no-param-reassign
    map.getCanvas().style.cursor = '';
};

/**
 * Displays a popup when hovering over a layer that has a mouseenter event added
 * @param map Instance of your map
 * @param e Event of the mouseenter
 * @param popup A TrimbleMaps.Popup that will be shown
 */
export const handlePopupMouseEnter = (map: any, e: any, popup: any): void => {
    handleMouseEnter(map);
    const coordinates = e.features[0].geometry.coordinates.slice();
    const { description } = e.features[0].properties;

    // Ensure that if the map is zoomed out such that multiple copies of the feature
    // are visible, the popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates based on the feature found.
    popup
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
};

/**
 * Displays a popup when hovering over a LineString layer that has a mouseenter event added
 * @param map Instance of your map
 * @param e Event of the mouseenter
 * @param popup A TrimbleMaps.Popup that will be shown
 */
export const handleLineStringPopupMouseEnter = (map: any, e: any, popup: any): void => {
    handleMouseEnter(map);
    const { description } = e.features[0].properties;

    // Populate the popup and set its coordinates based on the location of the mouse event.
    popup
        .setLngLat(e.lngLat)
        .setHTML(description)
        .addTo(map);
};

/**
 * Removes the popup when the mouseleave event is fired on the layer
 * @param map Instance of your map
 * @param popup A TrimbleMaps.Popup that will be hidden
 */
export const handlePopupMouseLeave = (map: any, popup: any): void => {
    handleMouseLeave(map);
    popup.remove();
};

/**
 * Creates an SVG path for the segment of a donut for clustered points on the map
 * @param start
 * @param end
 * @param r
 * @param r0
 * @param color
 * @returns SVG path
 */
export const donutSegment = (start: number, end: number, r: number, r0: number, color: string): string => {
    const adjustedEnd = (end - start === 1) ? end - 0.00001 : end;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (adjustedEnd - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);
    const largeArc = adjustedEnd - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path
        d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}"
        fill="${color}"
    />`;
};

/**
 * Creates an SVG donut chart from feature properties
 * @param properties Cluster properties of the feature
 * @returns ChildNode for the SVG
 */
export const createDonutChart = (properties: ClusterFeatureProperties): ChildNode | null => {
    // colors to use for the categories
    const colors = [
        getDeliveryStatusColor(DeliveryStatus.TenderAccepted),
        getDeliveryStatusColor(DeliveryStatus.Early),
        getDeliveryStatusColor(DeliveryStatus.OnTime),
        getDeliveryStatusColor(DeliveryStatus.InJeopardy),
        getDeliveryStatusColor(DeliveryStatus.Late),
        getDeliveryStatusColor(DeliveryStatus.TrackingLost)
    ];

    const offsets: number[] = [];
    const counts = [
        properties.TenderAccepted,
        properties.Early,
        properties.OnTime,
        properties.InJeopardy,
        properties.Late,
        properties.TrackingLost
    ];

    let total = 0;
    counts.forEach((count) => {
        offsets.push(total);
        total += count;
    });

    let fontSize = 16;
    let r = 18;
    if (total >= 1000) {
        fontSize = 22;
        r = 50;
    } else if (total >= 100) {
        fontSize = 20;
        r = 32;
    } else if (total >= 10) {
        fontSize = 18;
        r = 24;
    }

    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div id="${properties.cluster_id}" style="cursor: pointer"><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

    for (let i = 0; i < counts.length; i++) {
        html += donutSegment(
            offsets[i] / total,
            (offsets[i] + counts[i]) / total,
            r,
            r0,
            colors[i]
        );
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
            <text dominant-baseline="central" transform="translate(${r}, ${r})">
                ${properties.point_count.toLocaleString()}
            </text>
            </svg>
            </div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
};
