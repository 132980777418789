import React, { Fragment, useEffect, useState } from 'react';
import {
    Drawer,
    Paper,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const classesPrefix = 'sideSheet';

const classes = {
    drawerPaper: `${classesPrefix}-drawerPaper`,
    sideSheet: `${classesPrefix}-sideSheet`,
    sideSheetContainer: `${classesPrefix}-sideSheetContainer`
};

const StyledSection = styled('section')(() => {
    return {
        [`&.${classes.sideSheetContainer}`]: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto'
        }
    };
});

const StyledDrawer = styled(Drawer)(({ theme }) => {
    return {
        [`& .${classes.drawerPaper}`]: {
            width: '320px'
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.drawerPaper}`]: {
                width: '250px'
            }
        }
    };
});

const StyledPaper = styled(Paper)(() => {
    return {
        [`&.${classes.sideSheet}`]: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            height: 'calc(100vh - 116px)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            transition: 'width 400ms ease-in-out, opacity 1.5s ease-in-out'
        }
    };
});

const SideSheet = ({
    open,
    closeSideSheet,
    children
}: {
    /** Indicator for if the side sheet is open. */
    open: boolean;
    /** Function to close the side sheet. */
    closeSideSheet: () => void;
    /** Children to render in the side sheet. */
    children?: React.ReactNode;
}): JSX.Element => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [sideSheetWidth, setSideSheetWidth] = useState('0px');
    const [sideSheetBodyOpacity, setSideSheetBodyOpacity] = useState(0);

    useEffect((): void => {
        setSideSheetWidth('330px');
        setSideSheetBodyOpacity(1);
    }, []);

    const renderSideSheetBody = (): JSX.Element => {
        return (
            <StyledSection className={classes.sideSheetContainer} data-qa='sidesheet-content'>
                {children}
            </StyledSection>
        );
    };

    if (open === false) {
        return <Fragment />;
    }

    if (isMobile) {
        return (
            <StyledDrawer
                anchor='right'
                classes={{ paper: classes.drawerPaper }}
                open={open}
                onClose={(): void => { closeSideSheet(); }}
                data-qa='sidesheet-container-mobile'
            >
                {renderSideSheetBody()}
            </StyledDrawer>
        );
    }

    return (
        <StyledPaper className={classes.sideSheet} style={{ width: sideSheetWidth, opacity: sideSheetBodyOpacity }} data-qa='sidesheet-container'>
            {renderSideSheetBody()}
        </StyledPaper>
    );
};

export default SideSheet;
