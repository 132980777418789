import React, { Fragment, useState } from 'react';
import { Link } from '@mui/material';

import { useAccessTokenUrl } from '../../hooks/useAccessTokenUrl';
import LabelValuePair from '../labels/labelValuePair';
import TripDetailsDialog from '../dialogs/tripDetailsDialog';

const TripDetailsLabelLink = ({
    label,
    tripId,
    fontSize = '14px',
    noWrap = false
}: {
    /** The display label describing the shipment link. */
    label: string;
    /** Unique identifier for the trip. */
    tripId: string;
    /**
     * The size of the font.
     * @default 14px
     */
    fontSize?: string;
    /**
     * If true, the text will not wrap, but instead will truncate with a text overflow ellipsis.
     * @default false
     */
    noWrap?: boolean;
}): JSX.Element => {
    const [tripDetailsDialogIsOpen, setTripDetailsDialogIsOpen] = useState(false);
    const url = useAccessTokenUrl(`/tripDetails/${tripId}`);

    return (
        <Fragment>
            <LabelValuePair
                label={label}
                fontSize={fontSize}
                noWrap={noWrap}
                value={
                    <span data-qa='tripIdentifierNavigation-container'>
                        <Link
                            target='_blank'
                            rel='noopener noreferrer'
                            href={url}
                            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
                                // README: When the link is clicked js will open the Dialog and will not navigate to the url
                                // If the user right clicks and selects open in a new tab the href value is used to navigate
                                event.preventDefault();
                                setTripDetailsDialogIsOpen(true);
                            }}
                            data-qa='tripDetails-navigation'
                        >
                            {tripId}
                        </Link>
                    </span>
                }
                data-qa='tripId'
            />

            {
                tripDetailsDialogIsOpen && tripId &&
                <TripDetailsDialog
                    tripIdentifier={tripId}
                    handleDialogClose={(): void => {
                        setTripDetailsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default TripDetailsLabelLink;
