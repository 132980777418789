import React from 'react';
import {
    CircularProgress,
    Grid,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'mapSpinner';

const classes = {
    loadingWrapper: `${classesPrefix}-loadingWrapper`,
    loadingText: `${classesPrefix}-loadingText`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.loadingWrapper}`]: {
            minHeight: '150px',
            height: '100%',
            textAlign: 'center',
            backgroundColor: 'transparent',
            zIndex: 11,
            position: 'absolute'
        },
        [`& .${classes.loadingText}`]: {
            fontSize: '10px',
            fontWeight: 'lighter',
            color: theme.palette.text.primary,
            textTransform: 'uppercase'
        }
    };
});

const MapSpinner = (): JSX.Element => {
    return (
        <StyledGrid container alignItems='center' alignContent='center' className={classes.loadingWrapper}>
            <Grid item xs={12}>
                <CircularProgress thickness={5} disableShrink />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='caption' className={classes.loadingText}>Loading</Typography>
            </Grid>
        </StyledGrid>
    );
};

export default MapSpinner;
