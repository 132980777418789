import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Link,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchCarrierContacts } from '../../redux/carrierContacts';
import { ContactDetails } from '../../interfaces/services/carrierContacts';
import { formatPhoneNumber } from '../../helpers/dataUtils';
import { ApiStatus } from '../../helpers/enums';

const classesPrefix = 'carrierContactsTable';

const classes = {
    caption: `${classesPrefix}-caption`,
    tableHeader: `${classesPrefix}-tableHeader`,
    textContainer: `${classesPrefix}-textContainer`,
    description: `${classesPrefix}-description`
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => {
    return {
        [`& .${classes.caption}`]: {
            fontWeight: 600
        },
        [`& .${classes.tableHeader}`]: {
            fontWeight: 600
        },
        [`& .${classes.textContainer}`]: {
            display: 'flex',
            justifyContent: 'center',
            color: theme.appColors.action,
            cursor: 'pointer',
            marginTop: '8px'
        },
        [`& .${classes.description}`]: {
            padding: '0 12px',
            position: 'relative',
            bottom: '6px',
            fontWeight: 600
        }
    };
});

const CarrierContactsTable = ({
    limitDisplayedRowsCount = 0
}: {
    /** Limit the number of rows displayed. Must be greater than 0 to turn on functionality */
    limitDisplayedRowsCount?: number;
}): JSX.Element => {
    const dispatch = useDispatch();

    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });

    const contactsStatus = useTypedSelector((state) => { return state.carrierContacts.contactsStatus; });
    const contacts = useTypedSelector((state) => { return state.carrierContacts.contacts; });

    const [showFullTable, setShowFullTable] = useState(false);

    useEffect(() => {
        if (shipmentStatus !== ApiStatus.Idle && shipmentStatus !== ApiStatus.Loading) {
            dispatch(fetchCarrierContacts({
                freightHaulerIdentifierType: shipment.freightHauler.identifiers[0]?.freightHaulerIdentifierType,
                identifier: shipment.freightHauler.identifiers[0]?.identifier
            }));
        }
    }, [dispatch, shipmentStatus, shipment.freightHauler.identifiers]);

    const renderTableRow = (contact: ContactDetails, index: number): JSX.Element => {
        return (
            <TableRow key={index} data-qa={`carrierContact-row-${index + 1}`}>
                <TableCell data-qa='name'>{`${contact.firstName} ${contact.lastName}`}</TableCell>
                <TableCell data-qa='phoneNumber'>
                    {
                        contact.phoneNumber &&
                        <Link href={`tel:${contact.phoneNumber}`}>
                            {formatPhoneNumber(contact.phoneNumber)}
                        </Link>
                    }
                </TableCell>
                <TableCell data-qa='email'>{contact.emailAddress}</TableCell>
            </TableRow>
        );
    };

    const renderTableBody = (): JSX.Element | JSX.Element[] => {
        if (shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading ||
            contactsStatus === ApiStatus.Idle || contactsStatus === ApiStatus.Loading) {
            return (
                <TableRow>
                    <TableCell><Skeleton variant='text' width='120px' /></TableCell>
                    <TableCell><Skeleton variant='text' width='100px' /></TableCell>
                    <TableCell><Skeleton variant='text' width='140px' /></TableCell>
                </TableRow>
            );
        }

        if (contacts.length === 0) {
            return (
                <TableRow>
                    <TableCell align='center' colSpan={3} data-qa='carrierContact-noResults'>No Carrier Contacts Found</TableCell>
                </TableRow>
            );
        }

        // If limitDisplayedRowsCount is 0, meaning no limit has been set, or showFullTable is true show all rows in the table
        if (limitDisplayedRowsCount === 0 || showFullTable) {
            return contacts.map((contact, index): JSX.Element => {
                return renderTableRow(contact, index);
            });
        }

        const limitedResults = [];
        // loop through the shipment milestones and add up to the number of rows defined by limitDisplayedRowsCount
        for (let i = 0; i < contacts.length; i++) {
            // check if we have another row to add by subtracting 1 from limitDisplayedRowsCount to match the shipmentMilestones array
            if ((limitDisplayedRowsCount - 1) >= i) {
                const milestone = contacts[i];
                limitedResults.push(
                    renderTableRow(milestone, i)
                );
            }
        }

        return limitedResults;
    };

    const renderExpansionToggle = (): JSX.Element => {
        if (contacts.length > limitDisplayedRowsCount) {
            return (
                <Grid container>
                    <Grid item xs={12} onClick={(): void => { setShowFullTable(!showFullTable); }} className={classes.textContainer}>
                        {
                            showFullTable
                                ? (
                                    <Typography>
                                        <Icon path={mdiChevronUp} size={1} />
                                        <span className={classes.description}>Hide</span>
                                        <Icon path={mdiChevronUp} size={1} />
                                    </Typography>
                                ) : (
                                    <Typography>
                                        <Icon path={mdiChevronDown} size={1} />
                                        <span className={classes.description}>Show More</span>
                                        <Icon path={mdiChevronDown} size={1} />
                                    </Typography>
                                )
                        }
                    </Grid>
                </Grid>
            );
        }
        return <Fragment />;
    };

    return (
        <StyledTableContainer>
            <Table size='small' aria-label='Carrier Contacts' data-qa='carrierContact-table'>
                <caption>
                    <Typography className={classes.caption} variant='h6' data-qa='carrierContact-header'>Carrier Contacts</Typography>
                </caption>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='name-column-header'>Name</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='phoneNumber-column-header'>Phone Number</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='email-column-header'>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-qa='carrierContact-content'>
                    {renderTableBody()}
                </TableBody>
            </Table>
            {limitDisplayedRowsCount !== 0 && renderExpansionToggle()}
        </StyledTableContainer>
    );
};

export default CarrierContactsTable;
