import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, FreightHaulerIdentifierTypeName } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { GenericApiResponse } from '../../interfaces/services';
import { ContactDetails } from '../../interfaces/services/carrierContacts';

const CARRIER_CONTACTS_REQUEST = 'CARRIER_CONTACTS_REQUEST';
const CARRIER_CONTACTS_SUCCESS = 'CARRIER_CONTACTS_SUCCESS';
const CARRIER_CONTACTS_FAILURE = 'CARRIER_CONTACTS_FAILURE';

interface ContactsData {
    contactsStatus: ApiStatus;
    contacts: ContactDetails[];
}

const requestShipmentContacts = (): Action<typeof CARRIER_CONTACTS_REQUEST> => {
    return {
        type: CARRIER_CONTACTS_REQUEST
    };
};

const receiveShipmentContacts = (data: ContactDetails[]): GenericAction<typeof CARRIER_CONTACTS_SUCCESS, ContactDetails[]> => {
    return {
        type: CARRIER_CONTACTS_SUCCESS,
        payload: data
    };
};

const requestShipmentContactsFailed = (): Action<typeof CARRIER_CONTACTS_FAILURE> => {
    return {
        type: CARRIER_CONTACTS_FAILURE
    };
};

export const fetchCarrierContacts = ({
    freightHaulerIdentifierType,
    identifier
}: {
    freightHaulerIdentifierType?: FreightHaulerIdentifierTypeName;
    identifier?: string | null;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestShipmentContacts());

        // if there isn't a freight hauler identifier, we will just reset the state to be successful with an empty array
        // and let the component handle rendering the correct messaging.
        if (!freightHaulerIdentifierType || !identifier) {
            dispatch(receiveShipmentContacts([]));
        } else {
            try {
                const json = await ApiService.get({
                    url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.contactInformation}/${freightHaulerIdentifierType}/identifierName/${identifier}`
                }) as GenericApiResponse<ContactDetails>;

                const { data } = json;
                dispatch(receiveShipmentContacts(data));
            } catch (err) {
                dispatch(requestShipmentContactsFailed());
                toast.error('Error occurred while fetching carrier contacts.');
            }
        }
    };
};

type CarrierContactsActionTypes =
    ReturnType<typeof requestShipmentContacts> | ReturnType<typeof receiveShipmentContacts> | ReturnType<typeof requestShipmentContactsFailed>;

export const carrierContactsReducer = (contactsData: ContactsData = {
    contactsStatus: ApiStatus.Idle,
    contacts: []
}, action: CarrierContactsActionTypes): ContactsData => {
    switch (action.type) {
        case CARRIER_CONTACTS_REQUEST: {
            return {
                ...contactsData,
                contactsStatus: ApiStatus.Loading
            };
        }
        case CARRIER_CONTACTS_SUCCESS: {
            return {
                ...contactsData,
                contactsStatus: ApiStatus.Success,
                contacts: action.payload
            };
        }
        case CARRIER_CONTACTS_FAILURE: {
            return {
                ...contactsData,
                contactsStatus: ApiStatus.Failure,
                contacts: []
            };
        }
        default:
            return contactsData;
    }
};
