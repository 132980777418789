import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { rootReducer } from '.';
import TransformState from './storeTransforms';
import { AppStore } from '../interfaces/storeInterfaces';
import { fetchServiceLocatorEndpoints } from './serviceLocator';

const middleWare = process.env.NODE_ENV !== 'production' ? [thunk, logger] : [thunk];

const persistConfig = {
    key: 'transportation_ui',
    // 'shipmentDetails' and 'tripDetails' consume a large amount of sessionStorage which can lead to the persist failing
    blacklist: ['availableServices', 'analytics', 'shipmentDetails', 'tripDetails'],
    transforms: [TransformState],
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createAppStore = (): AppStore => {
    const store = createStore(persistedReducer, applyMiddleware(...middleWare));
    const persistor = persistStore(store, null, (): void => {
        // seed SL endpoints into redux
        store.dispatch(fetchServiceLocatorEndpoints());
    });
    return { store, persistor };
};

export default createAppStore;
