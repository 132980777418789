import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Typography,
    RadioGroup,
    Radio,
    FormHelperText
} from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import { isTimeRangeValid } from '../../../helpers/dateUtils';
import PreferenceCard from '../preferenceCard';
import CustomTimePicker from '../../pickers/timePicker';

const DropTrailerRulesPickupCard = ({
    handleIsCardValidChange
}: {
    handleIsCardValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const [isDropTrailerWindowPickupTimeRangeValid, setIsDropTrailerWindowPickupTimeRangeValid] = useState(true);
    const [isDropTrailerWindowPickupTimeFromValid, setIsDropTrailerWindowPickupTimeFromValid] = useState(true);
    const [isDropTrailerWindowPickupTimeToValid, setIsDropTrailerWindowPickupTimeToValid] = useState(true);
    const {
        dropTrailerPickupRulesEnabled,
        isDropTrailerSameDayPickup,
        dropTrailerWindowPickupTimeFrom,
        dropTrailerWindowPickupTimeTo
    } = organizationEditablePreferences;

    useEffect((): void => {
        setIsDropTrailerWindowPickupTimeRangeValid(isTimeRangeValid(dropTrailerWindowPickupTimeFrom, dropTrailerWindowPickupTimeTo));
    }, [dropTrailerWindowPickupTimeFrom, dropTrailerWindowPickupTimeTo]);

    useEffect((): void => {
        const isCardValid = dropTrailerPickupRulesEnabled === true && isDropTrailerSameDayPickup === false
            ? isDropTrailerWindowPickupTimeFromValid && isDropTrailerWindowPickupTimeToValid && isDropTrailerWindowPickupTimeRangeValid
            : true;
        handleIsCardValidChange(isCardValid);
    }, [
        handleIsCardValidChange,
        dropTrailerPickupRulesEnabled,
        isDropTrailerSameDayPickup,
        isDropTrailerWindowPickupTimeFromValid,
        isDropTrailerWindowPickupTimeToValid,
        isDropTrailerWindowPickupTimeRangeValid
    ]);

    const handleDropTrailerPickupRulesEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.dropTrailerPickupRulesEnabled, event.target.checked));
    };

    const handleIsDropTrailerSameDayPickupChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value === 'true';
        dispatch(editPreferences(PreferencesFieldNames.isDropTrailerSameDayPickup, value));
        if (value) {
            dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowPickupTimeFrom, null));
            dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowPickupTimeTo, null));
        }
    };

    const handleDropTrailerWindowPickupTimeFromChange = (time: string | null): void => {
        dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowPickupTimeFrom, time));
    };

    const handleDropTrailerWindowPickupTimeFromIsValidChange = (isTimeValid: boolean): void => {
        setIsDropTrailerWindowPickupTimeFromValid(isTimeValid);
    };

    const handleDropTrailerWindowPickupTimeToChange = (time: string | null): void => {
        dispatch(editPreferences(PreferencesFieldNames.dropTrailerWindowPickupTimeTo, time));
    };

    const handleDropTrailerWindowPickupTimeToIsValidChange = (isTimeValid: boolean): void => {
        setIsDropTrailerWindowPickupTimeToValid(isTimeValid);
    };

    return (
        <PreferenceCard
            header='Drop Trailer Rules - Pickup'
            informationText='Allows the user to override the on-time rules for pickup of a shipment that has a flag of "Drop/Swing Trailer" present on the shipment.'
        >
            <Typography component='p' variant='body2'>
                Manage how drop/swing trailers impact your on-time rules.
            </Typography>

            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={dropTrailerPickupRulesEnabled}
                            onChange={handleDropTrailerPickupRulesEnabledChange}
                            color='primary'
                        />
                    }
                    label='Pickup Rules'
                    data-qa='dropTrailerPickupRulesEnabled-switch'
                />
            </FormGroup>

            {
                dropTrailerPickupRulesEnabled === true &&
                <Fragment>
                    <RadioGroup
                        aria-label='isDropTrailerSameDayPickup'
                        name='isDropTrailerSameDayPickup'
                        value={String(isDropTrailerSameDayPickup)}
                        onChange={handleIsDropTrailerSameDayPickupChange}
                    >
                        <FormControlLabel
                            value='true'
                            control={<Radio color='primary' />}
                            label='Same day pickup is considered On Time'
                            data-qa='sameDayPickup-radio'
                        />
                        <FormControlLabel
                            value='false'
                            control={<Radio color='primary' />}
                            label='Picked up between these hours is considered On Time'
                            data-qa='pickupHours-radio'
                        />
                    </RadioGroup>

                    {
                        isDropTrailerSameDayPickup === false &&
                        <Fragment>
                            <CustomTimePicker
                                label='From'
                                incomingTime={dropTrailerWindowPickupTimeFrom}
                                handleTimeChange={handleDropTrailerWindowPickupTimeFromChange}
                                handleIsValidChange={handleDropTrailerWindowPickupTimeFromIsValidChange}
                                dataQa='dropTrailerWindowPickupTimeFrom-timePicker'
                            />
                            <CustomTimePicker
                                label='To'
                                incomingTime={dropTrailerWindowPickupTimeTo}
                                handleTimeChange={handleDropTrailerWindowPickupTimeToChange}
                                handleIsValidChange={handleDropTrailerWindowPickupTimeToIsValidChange}
                                dataQa='dropTrailerWindowPickupTimeTo-timePicker'
                            />
                            {
                                !isDropTrailerWindowPickupTimeRangeValid &&
                                <FormHelperText error>End time must be after start time</FormHelperText>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
        </PreferenceCard>
    );
};

export default DropTrailerRulesPickupCard;
