import { OrderDetailsData } from '../../interfaces/services/orderDetails';
import { ModeType } from '../../helpers/enums';

export const initialOrderDetailsState: OrderDetailsData = {
    freightOrderGuid: '',
    shipmentUniqueName: '',
    freightProviderReferenceNumber: '',
    modeType: ModeType.Unknown,
    totalStopCount: 0,
    mobileTrackingNumber: null,
    tractorReferenceNumber: null,
    trailerReferenceNumber: null,
    currentPosition: null,
    driver: null,
    freightHauler: {
        name: '',
        identifiers: []
    },
    organizationContact: null,
    stops: [],
    orders: [],
    referenceNumbers: []
};
