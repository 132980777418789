import React from 'react';
import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';

interface StyleProps extends IconButtonProps {
    iconPadding?: number;
}

const classesPrefix = 'shipmentActionButton';

const classes = {
    iconButtonRoot: `${classesPrefix}-iconButtonRoot`
};

const StyledIconButton = styled(
    IconButton,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'iconPadding'; }
    }
)<StyleProps>(({ iconPadding }) => {
    return {
        [`&.${classes.iconButtonRoot}`]: {
            padding: `${iconPadding}px`
        }
    };
});

const ShipmentActionButton = ({
    title,
    mdiIcon,
    iconColor,
    iconPadding = 6,
    'data-qa': dataQa,
    handleClick
}: {
    title: string;
    mdiIcon: string;
    iconColor: string;
    /**
     * @default 6
     */
    iconPadding?: number;
    'data-qa': string;
    handleClick: () => void;
}): JSX.Element => {
    return (
        <Tooltip title={title}>
            <StyledIconButton
                iconPadding={iconPadding}
                onClick={(): void => {
                    handleClick();
                }}
                classes={{ root: classes.iconButtonRoot }}
                data-qa={dataQa}
            >
                <Icon
                    path={mdiIcon}
                    size={1}
                    color={iconColor}
                />
            </StyledIconButton>
        </Tooltip>
    );
};

export default ShipmentActionButton;
