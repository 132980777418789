import React, { Fragment, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

import { ModeType } from '../../helpers/enums';
import { useAccessTokenUrl } from '../../hooks/useAccessTokenUrl';
import ShipmentDetailsDialog from '../dialogs/shipmentDetailsDialog';

const ShipmentDetailsIconButton = ({
    shipmentUniqueName,
    modeType,
    iconColor,
    iconPadding = 6,
    alwaysOpenInNewTab = false
}: {
    /** unique identifier for the shipment */
    shipmentUniqueName: string;
    /** mode type for the shipment */
    modeType: ModeType;
    /** the color of the icon */
    iconColor: string;
    /** Optional padding value
     * @default 6
    */
    iconPadding?: number;
    /** Always open the shipment details in a new tab
     * @default false
     */
    alwaysOpenInNewTab?: boolean;
}): JSX.Element => {
    const [shipmentDetailsDialogIsOpen, setShipmentDetailsDialogIsOpen] = useState(false);
    const url = useAccessTokenUrl(`/shipmentDetails/${shipmentUniqueName}`);

    return (
        <Fragment>
            <Tooltip title='Shipment Details' placement='bottom'>
                <IconButton
                    style={{ padding: `${iconPadding}px` }}
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={url}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
                        if (alwaysOpenInNewTab === false) {
                            // Remove focus from the button so the tooltip does not remain after the click
                            event.currentTarget.blur();
                            // README: When the link is clicked js will open the Dialog and will not navigate.
                            // If the user right clicks and selects open in a new tab the href value is used to navigate
                            event.preventDefault();
                            setShipmentDetailsDialogIsOpen(true);
                        }
                    }}
                    data-qa='shipmentList-action-detailsNavigation'
                >
                    <Icon
                        path={mdiOpenInNew}
                        size={1}
                        color={iconColor}
                    />
                </IconButton>
            </Tooltip>

            {
                shipmentDetailsDialogIsOpen &&
                <ShipmentDetailsDialog
                    shipmentUniqueName={shipmentUniqueName}
                    modeType={modeType}
                    handleDialogClose={(): void => {
                        setShipmentDetailsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentDetailsIconButton;
