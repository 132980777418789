import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    Typography,
    CircularProgress,
    FormHelperText,
    TextField,
    Tab,
    Tabs
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiAccountBox, mdiChevronDown } from '@mdi/js';
import { CountryCode } from 'libphonenumber-js';
import PhoneInput, { parsePhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';

import { useTypedSelector } from '../../redux';
import { updatePageReset, updateMobileTracking, addShipmentNote } from '../../redux/dialogUpdates';
import { fetchDriverDetails } from '../../redux/drivers';
import { fetchShipmentDetails } from '../../redux/shipmentDetails';
import { ApiStatus, FreightHaulerIdentifierTypeName } from '../../helpers/enums';
import { DriverDetails } from '../../interfaces/services/drivers';
import DriverLookupAutocomplete from '../selects/driverLookupAutocomplete';
import DriverNoteList from '../lists/driverNoteList';
import CommonDialog from './common/commonDialog';
import '../../assets/CSS/reactPhoneNumberInput.css';

const classesPrefix = 'manageDriverDialog';

const classes = {
    arrowIcon: `${classesPrefix}-arrowIcon`,
    phoneWrapper: `${classesPrefix}-phoneWrapper`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`&.${classes.phoneWrapper}`]: {
            minHeight: '244px',
            marginTop: '8px'
        },
        [`& .${classes.arrowIcon}`]: {
            opacity: 0.45,
            marginTop: '3px'
        }
    };
});

enum ManageDriverTab {
    Assign,
    Message
}

const PhoneNumberTextField = React.forwardRef((
    props,
    ref
): JSX.Element => {
    return (
        <TextField
            {...props}
            inputRef={ref}
            label='Driver Phone Number'
            margin='none'
            variant='filled'
            fullWidth
            inputProps={{
                autoComplete: 'off',
                'data-qa': 'driverPhoneNumber-input'
            }}
        />
    );
});
PhoneNumberTextField.displayName = 'PhoneNumberTextField';

const ArrowComponent = (): JSX.Element => {
    return (
        <Icon className={classes.arrowIcon} path={mdiChevronDown} size={1} />
    );
};

const ManageDriverDialog = ({
    shipmentUniqueName,
    freightHaulerIdentifierTypeName,
    freightHaulerIdentifierName,
    initialPhoneNumber,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    freightHaulerIdentifierTypeName: FreightHaulerIdentifierTypeName | null;
    freightHaulerIdentifierName: string | null;
    initialPhoneNumber: string | null;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState(ManageDriverTab.Assign);

    const [phoneNumberHelperText, setPhoneNumberHelperText] = useState('');
    const [draftPhoneNumber, setDraftPhoneNumber] = useState('');
    const [draftDriverDetails, setDraftDriverDetails] = useState<DriverDetails | null>(null);
    const [noteText, setNoteText] = useState('');

    const [canAssignDriver, setCanAssignDriver] = useState(false);
    const [canSendMessage, setCanSendMessage] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const driverDetails = useTypedSelector((state) => { return state.drivers.driverDetails; });

    // only fetch if we don't already have the details in redux
    useEffect((): void => {
        if (shipmentUniqueName !== shipment.shipmentUniqueName) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipment.shipmentUniqueName, dispatch]);

    // only fetch if we refreshed and cleared our shipment status back to idle
    useEffect((): void => {
        if (shipmentStatus === ApiStatus.Idle) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipmentStatus, dispatch]);

    // Update local draft value if prop changes
    useEffect((): void => {
        // default the phoneNumber to the phone number passed in or empty string if nothing was passed
        let phoneNumber = initialPhoneNumber || '';

        if (phoneNumber) {
            // try to get a phone number object if an initial phone number was plugged in
            // if the phone number can't be parsed, undefined is returned
            const draftPhoneObj = parsePhoneNumber(phoneNumber);

            // if we have a phone number object, get the parsed phone number
            if (draftPhoneObj && draftPhoneObj.number) {
                phoneNumber = draftPhoneObj.number as string;
            }
        }

        setDraftPhoneNumber(phoneNumber);
    }, [initialPhoneNumber]);

    // Lookup driver details by phone number if available
    useEffect(() => {
        dispatch(fetchDriverDetails({ mobileTrackingNumber: initialPhoneNumber }));
    }, [dispatch, initialPhoneNumber]);

    // Keep draft details updated with state
    useEffect((): void => {
        setDraftDriverDetails(driverDetails.driverGuid ? driverDetails : null);
    }, [driverDetails]);

    // Validate draft tractor/trailer values and determine if form can be submitted
    useEffect((): void => {
        let helperText = '';
        const hasPhoneNumberChanged = draftPhoneNumber !== initialPhoneNumber;

        if (draftPhoneNumber && !isPossiblePhoneNumber(draftPhoneNumber)) {
            helperText = 'Phone Number is invalid';
        }

        setPhoneNumberHelperText(hasPhoneNumberChanged ? helperText : '');
        setCanAssignDriver(draftPhoneNumber?.length > 0 && hasPhoneNumberChanged && helperText === '' && freightHaulerIdentifierTypeName !== null);
    }, [draftPhoneNumber, initialPhoneNumber, freightHaulerIdentifierTypeName]);

    // If the API update is successful, trigger the dialog to reset
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            setNoteText('');
        }
    }, [status, dispatch]);

    // Validate that message textfield is not empty and determine if message can be sent
    useEffect((): void => {
        setCanSendMessage(noteText.trim() !== '');
    }, [noteText]);

    const handlePhoneNumberChange = (newPhoneNumber: string): void => {
        setDraftPhoneNumber(newPhoneNumber);
        setDraftDriverDetails(null);
    };

    const handleSelectDriver = (newDriver: DriverDetails | null): void => {
        setDraftDriverDetails(newDriver);
        setDraftPhoneNumber(newDriver ? newDriver.phoneNumber : '');
    };

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        dispatch(updateMobileTracking({
            shipmentUniqueName,
            freightHaulerIdentifierTypeName,
            freightHaulerIdentifierName,
            driverPhoneNumber: draftPhoneNumber
        }));
    };

    const handleSend = (): void => {
        const body = {
            shipmentUniqueName,
            text: noteText,
            isShared: true,
            textDriver: true
        };
        dispatch(addShipmentNote(body));
    };

    const determineDefaultCountryCode = (): CountryCode => {
        // if there is an intial phone number, parse it and attempt to use the country code returned by the parser
        if (initialPhoneNumber) {
            const draftPhoneObj = parsePhoneNumber(initialPhoneNumber);
            return draftPhoneObj?.country || 'US';
        }

        // otherwise, attempt to use the browser language country code or just default to US
        // react-phone-number-input only recognizes abbreviations in all caps
        if (navigator.language) {
            const browserLanguage = navigator.language.split('-');
            if (browserLanguage.length > 1) {
                return browserLanguage[1].toUpperCase() as CountryCode;
            }
        }

        // At a minimum default to US
        return 'US';
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Fragment>
                <Tabs
                    value={currentTab}
                    onChange={(event, value: number): void => {
                        setCurrentTab(value);
                    }}
                    variant='fullWidth'
                    indicatorColor='primary'
                    textColor='primary'
                    data-qa='manageDriver-tabList'
                >
                    <Tab
                        label='assign driver'
                        value={ManageDriverTab.Assign}
                        data-qa='assignDriver-tab'
                    />

                    <Tab
                        label='message driver'
                        value={ManageDriverTab.Message}
                        data-qa='messageDriver-tab'
                    />
                </Tabs>

                {
                    currentTab === ManageDriverTab.Assign &&
                    <StyledGrid container spacing={4} direction='column' className={classes.phoneWrapper}>
                        <Grid item>
                            <PhoneInput
                                placeholder='Driver Mobile Number'
                                defaultCountry={determineDefaultCountryCode()}
                                countrySelectProps={{
                                    unicodeFlags: true,
                                    arrowComponent: ArrowComponent
                                }}
                                international
                                addInternationalOption
                                countryCallingCodeEditable
                                countryOptionsOrder={['US', 'CA', 'MX', 'AU', '...']}
                                value={draftPhoneNumber}
                                onChange={handlePhoneNumberChange}
                                // @ts-ignore
                                inputComponent={PhoneNumberTextField}
                            />
                            <FormHelperText error>
                                {phoneNumberHelperText}
                            </FormHelperText>
                        </Grid>
                        <Grid item>
                            <DriverLookupAutocomplete
                                draftDriverDetails={draftDriverDetails}
                                handleSelectDriver={handleSelectDriver}
                            />
                        </Grid>

                        {
                            !freightHaulerIdentifierTypeName &&
                            <Grid item xs={12}>
                                <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                            </Grid>
                        }
                    </StyledGrid>
                }
                {
                    currentTab === ManageDriverTab.Message &&
                    <Fragment>
                        <DriverNoteList />
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            variant='filled'
                            placeholder='Type Message Here...'
                            onChange={(event): void => {
                                setNoteText(event.currentTarget.value);
                            }}
                            value={noteText}
                            data-qa='driverMessage-input'
                        />
                    </Fragment>
                }
                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>{errorMessage}</Typography>
                    </Grid>
                }
            </Fragment>
        );
    };

    const renderDialogActions = (): JSX.Element => {
        return (
            <Fragment>
                <Button
                    disabled={status === ApiStatus.Loading}
                    onClick={handleClose}
                    data-qa='cancel-button'
                >
                    Cancel
                </Button>
                {
                    currentTab === ManageDriverTab.Assign &&
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canAssignDriver}
                        onClick={handleSave}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                }
                {
                    currentTab === ManageDriverTab.Message &&
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || !canSendMessage}
                        onClick={handleSend}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='send-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Sending' : 'Send'
                        }
                    </Button>
                }
            </Fragment>
        );
    };

    return (
        <CommonDialog
            headerText='Manage Driver'
            open={isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth='xs'
            headerIcon={mdiAccountBox}
            content={renderDialogContent()}
            actions={renderDialogActions()}
        />
    );
};

export default ManageDriverDialog;
