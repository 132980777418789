import React from 'react';
import { Typography } from '@mui/material';
import ErrorPage from './errorPage';

const NoOrderDetails = (): JSX.Element => {
    return (
        <ErrorPage errorHeaderText='No Order Details Found'>
            <Typography data-qa='noOrderDetails'>
                We were unable to locate the details for this order.
            </Typography>
        </ErrorPage>
    );
};

export default NoOrderDetails;
