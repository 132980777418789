import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MilestoneType } from '../../helpers/enums';

interface StyleProps {
    milestoneProgress: number;
    currentStep: number;
    totalSteps: number;
    color: string;
}

const classesPrefix = 'milestoneProgressBarStep';

const classes = {
    milestoneItem: `${classesPrefix}-milestoneItem`,
    milestoneLabel: `${classesPrefix}-milestoneLabel`,
    milestoneStepper: `${classesPrefix}-milestoneStepper`
};

const StyledDiv = styled(
    'div',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => {
            return prop !== 'milestoneProgress' && prop !== 'currentStep' && prop !== 'totalSteps' && prop !== 'color';
        }
    }
)<StyleProps>(({ milestoneProgress, currentStep, totalSteps, color, theme }) => {
    return {
        [`&.${classes.milestoneItem}`]: {
            width: `calc((100% / ${totalSteps}) - 8px)`,
            display: 'inline-block',
            marginRight: '8px'
        },
        [`& .${classes.milestoneLabel}`]: {
            textAlign: 'center',
            fontSize: '16px'
        },
        [`& .${classes.milestoneStepper}`]: {
            height: '24px',
            backgroundColor: currentStep <= milestoneProgress ? color : theme.appColors.progressDefaultBackground
        }
    };
});

const MilestoneProgressBarStep = ({
    label,
    milestoneProgress,
    currentStep,
    color
}: {
    label: string;
    milestoneProgress: number;
    currentStep: number;
    color: string;
}): JSX.Element => {
    const totalSteps = Object.keys(MilestoneType).length;

    return (
        <StyledDiv
            milestoneProgress={milestoneProgress}
            currentStep={currentStep}
            totalSteps={totalSteps}
            color={color}
            className={classes.milestoneItem}
            data-qa='milestoneItem-container'
        >
            <Typography variant='h6' className={classes.milestoneLabel} data-qa={`milestone-${label}-label`}>{label}</Typography>
            <div className={classes.milestoneStepper} />
        </StyledDiv>
    );
};

export default MilestoneProgressBarStep;
