import React, { Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Drawer,
    List,
    IconButton,
    Grid,
    Typography,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import {
    mdiMenu,
    mdiClose,
    mdiPackageVariantClosed as shipmentsIcon,
    mdiViewList as listViewIcon,
    mdiEmailOutline as notificationsIcon,
    mdiDomain as locationsIcon,
    mdiAccountCog as preferencesIcon,
    mdiMonitorDashboard as dashboardIcon,
    mdiHistory as historyIcon,
    mdiCalendar as calendarIcon,
    mdiReceiptText as ordersIcon,
    mdiFormatListCheckbox as ordersListActiveIcon
} from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { ApiStatus, ApplicationResourceNames } from '../../helpers/enums';
import trimbleLogo from '../../assets/images/trimble_logo.png';
import AppHeaderMenuItemLoading from '../loaders/headers/appHeaderMenuItemLoading';
import AppHeaderMenuItem from './appHeaderMenuItem';

const classesPrefix = 'appHeaderHamburgerMenu';

const classes = {
    drawerPaper: `${classesPrefix}-drawerPaper`,
    menuHeader: `${classesPrefix}-menuHeader`,
    companyName: `${classesPrefix}-companyName`,
    companyLogo: `${classesPrefix}-companyLogo`,
    pagesHeader: `${classesPrefix}-pagesHeader`
};

const StyledDrawer = styled(Drawer)(({ theme }) => {
    return {
        [`& .${classes.drawerPaper}`]: {
            width: '320px'
        },
        [`& .${classes.menuHeader}`]: {
            padding: '16px 4px 32px 4px'
        },
        [`& .${classes.companyName}`]: {
            fontSize: '20px',
            paddingLeft: '16px'
        },
        [`& .${classes.companyLogo}`]: {
            maxWidth: '240px',
            maxHeight: '120px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        [`& .${classes.pagesHeader}`]: {
            paddingTop: '32px',
            paddingLeft: '16px',
            fontSize: '16px',
            fontWeight: 600
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.drawerPaper}`]: {
                width: '250px'
            }
        }
    };
});

const AppHeaderHamburgerMenu = (): JSX.Element => {
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

    const organizationInformationStatus = useTypedSelector((state) => { return state.organization.organizationInformationStatus; });
    const organizationInformation = useTypedSelector((state) => { return state.organization.organizationInformation; });

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const userInformationStatus = useTypedSelector((state) => { return state.user.userInformationStatus; });
    const userInformation = useTypedSelector((state) => { return state.user.userInformation; });

    const renderMenu = (): JSX.Element | JSX.Element[] => {
        if (userInformationStatus === ApiStatus.Idle || userInformationStatus === ApiStatus.Loading) {
            return (
                <Fragment>
                    <Typography className={classes.pagesHeader} variant='h4'><Skeleton variant='text' width='60px' /></Typography>
                    <List>
                        {
                            Array.from(new Array(3)).map((item, index): JSX.Element => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <AppHeaderMenuItemLoading key={index} />
                                );
                            })
                        }
                    </List>
                </Fragment>
            );
        }

        const mdiIcons: { [k in ApplicationResourceNames]?: string; } = {
            [ApplicationResourceNames.ShipmentsOverview]: shipmentsIcon,
            [ApplicationResourceNames.ShipmentListActive]: listViewIcon,
            [ApplicationResourceNames.ShipmentsCalendar]: calendarIcon,
            [ApplicationResourceNames.ShipmentListHistory]: historyIcon,
            [ApplicationResourceNames.OrdersOverview]: ordersIcon,
            [ApplicationResourceNames.OrdersListActive]: ordersListActiveIcon,
            [ApplicationResourceNames.CustomNotifications]: notificationsIcon,
            [ApplicationResourceNames.Locations]: locationsIcon,
            [ApplicationResourceNames.Preferences]: preferencesIcon,
            [ApplicationResourceNames.Analytics]: dashboardIcon,

            [ApplicationResourceNames.Shipments]: shipmentsIcon
        };

        return (
            <Fragment>
                {
                    userInformation.userApplications.transportationUi.length > 0 &&
                    <Fragment>
                        <Typography className={classes.pagesHeader} variant='h4' data-qa='hamburgerMenu-transportationUi-header'>Pages</Typography>
                        <List data-qa='hamburgerMenu-transportationUi-menu'>
                            {
                                userInformation.userApplications.transportationUi.map((app): JSX.Element => {
                                    const mdiIcon = mdiIcons[app.resourceKey] || null;

                                    if (mdiIcon) {
                                        return (
                                            <AppHeaderMenuItem
                                                key={app.resourceKey}
                                                application={app}
                                                mdiIcon={mdiIcon}
                                                linkComponent={app.resourcePath.startsWith('http') ? undefined : RouterLink}
                                                handleMenuItemClick={(): void => {
                                                    setIsHamburgerMenuOpen(false);
                                                }}
                                            />
                                        );
                                    }
                                    return <Fragment key={app.resourceKey} />;
                                })
                            }
                        </List>
                    </Fragment>
                }

                {
                    userInformation.userApplications.freightPortal.length > 0 &&
                    <Fragment>
                        <Typography className={classes.pagesHeader} variant='h4' data-qa='hamburgerMenu-freightPortal-header'>Legacy Pages</Typography>
                        <List data-qa='hamburgerMenu-freightPortal-menu'>
                            {
                                userInformation.userApplications.freightPortal.map((app): JSX.Element => {
                                    const mdiIcon = mdiIcons[app.resourceKey] || null;

                                    if (mdiIcon) {
                                        return (
                                            <AppHeaderMenuItem
                                                key={app.resourceKey}
                                                application={app}
                                                mdiIcon={mdiIcon}
                                                handleMenuItemClick={(): void => {
                                                    setIsHamburgerMenuOpen(false);
                                                }}
                                            />
                                        );
                                    }
                                    return <Fragment key={app.resourceKey} />;
                                })
                            }
                        </List>
                    </Fragment>
                }
            </Fragment>
        );
    };

    return (
        <Fragment>
            <IconButton
                edge='start'
                color='inherit'
                onClick={(): void => {
                    setIsHamburgerMenuOpen(true);
                }}
                data-qa='applicationHeader-hamburger-button'
            >
                <Icon path={mdiMenu} size={1} />
            </IconButton>
            <StyledDrawer
                classes={{ paper: classes.drawerPaper }}
                open={isHamburgerMenuOpen}
                onClose={(): void => { setIsHamburgerMenuOpen(false); }}
                data-qa='hamburgerMenu-container'
            >
                <Grid container alignItems='center' className={classes.menuHeader}>
                    <Grid item xs='auto'>
                        <IconButton
                            aria-label='close'
                            onClick={(): void => { setIsHamburgerMenuOpen(false); }}
                            data-qa='hamburgerMenu-action-close'
                        >
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography noWrap={true} className={classes.companyName} variant='h3' data-qa='hamburgerMenu-companyName'>
                            {
                                organizationInformationStatus === ApiStatus.Idle || organizationInformationStatus === ApiStatus.Loading ?
                                    (
                                        <Skeleton width='160px' height='30px' />
                                    ) : (
                                        organizationInformation.organizationName || ''
                                    )
                            }
                        </Typography>
                    </Grid>
                </Grid>

                {
                    organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading ?
                        (
                            <Skeleton className={classes.companyLogo} variant='rectangular' width='240px' height='120px' />
                        ) : (
                            <img className={classes.companyLogo} src={organizationPreferences?.customLogoToken || trimbleLogo} alt='company logo' data-qa='hamburgerMenu-company-logo' />
                        )
                }

                {renderMenu()}
            </StyledDrawer>
        </Fragment>
    );
};

export default AppHeaderHamburgerMenu;
