import React, { Fragment } from 'react';
import { TableRow, TableCell, TableBody, TableHead, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ColDef } from '../../../interfaces/muiTableInterfaces';

const classesPrefix = 'dataListLoading';

const classes = {
    tableHeader: `${classesPrefix}-tableHeader`
};

const StyledTableHead = styled(TableHead)(() => {
    return {
        [`& .${classes.tableHeader}`]: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            display: 'inline-block'
        }
    };
});

const DataListLoading = <T extends object>({
    columnData,
    staticColumns
}: {
    /** the metadata about the columns with dynamic data */
    columnData: ColDef<T>[];
    /** the metadata about the columns with static data, such as actions */
    staticColumns: { field: string; width: number; }[];
}): JSX.Element => {
    return (
        <Fragment>
            {
                columnData.length === 0 &&
                <StyledTableHead>
                    <TableRow>
                        {
                            staticColumns.map((staticColumn, staticColumnIndex): JSX.Element => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <TableCell key={`${staticColumn.field}${staticColumnIndex}`} width={staticColumn.width}>
                                        <span className={classes.tableHeader}>
                                            {staticColumn.field}
                                        </span>
                                    </TableCell>
                                );
                            })
                        }
                        {
                            Array.from(new Array(20)).map((column, columnIndex): JSX.Element => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <TableCell key={columnIndex}>
                                        <Skeleton variant='text' width='100px' />
                                    </TableCell>
                                );
                            })
                        }
                    </TableRow>
                </StyledTableHead>
            }
            <TableBody>
                {
                    Array.from(new Array(10)).map((row, rowIndex): JSX.Element => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRow key={rowIndex}>
                                {
                                    staticColumns.map((staticColumn, staticColumnIndex): JSX.Element => {
                                        return (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <TableCell key={`${staticColumn.field}${staticColumnIndex}`}>
                                                <Skeleton variant='rectangular' width={`${staticColumn.width || 100}px`} height='24px' />
                                            </TableCell>
                                        );
                                    })
                                }
                                {
                                    columnData.length > 0 ? (
                                        columnData.map((column): JSX.Element => {
                                            return (
                                                <TableCell key={column.field.toString()}>
                                                    <Skeleton variant='text' width={`${column.width || 100}px`} />
                                                </TableCell>
                                            );
                                        })
                                    ) : (
                                        Array.from(new Array(20)).map((column, columnIndex): JSX.Element => {
                                            return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <TableCell key={`${rowIndex}${columnIndex}`}>
                                                    <Skeleton variant='text' width='100px' />
                                                </TableCell>
                                            );
                                        })
                                    )
                                }
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </Fragment>
    );
};

export default DataListLoading;
