import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

const classesPrefix = 'expansionPanel';

const classes = {
    textContainer: `${classesPrefix}-textContainer`,
    description: `${classesPrefix}-description`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`& .${classes.textContainer}`]: {
            display: 'flex',
            justifyContent: 'center',
            color: theme.appColors.action,
            cursor: 'pointer',
            paddingTop: '8px'
        },
        [`& .${classes.description}`]: {
            padding: '0 12px',
            position: 'relative',
            bottom: '6px',
            fontWeight: 600
        }
    };
});

const ExpansionPanel = ({
    label,
    children,
    defaultExpanded
}: {
    /** Optional label to show with the words 'Hide' and 'Show'. Defaults to show 'Hide Details' and 'Show Details'. */
    label?: string;
    /** Children to render in the expansion panel. */
    children?: React.ReactNode;
    /** Is the panel open by default */
    defaultExpanded?: boolean;
}): JSX.Element => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded || false);

    return (
        <StyledGrid container>
            <Grid item xs={12} onClick={(): void => { setIsExpanded(!isExpanded); }} className={classes.textContainer}>
                {
                    isExpanded
                        ? (
                            <Typography>
                                <Icon path={mdiChevronUp} size={1} />
                                <span className={classes.description}>{`Hide ${label || 'Details'}`}</span>
                                <Icon path={mdiChevronUp} size={1} />
                            </Typography>
                        ) : (
                            <Typography>
                                <Icon path={mdiChevronDown} size={1} />
                                <span className={classes.description}>{`Show ${label || 'Details'}`}</span>
                                <Icon path={mdiChevronDown} size={1} />
                            </Typography>
                        )
                }
            </Grid>
            {
                isExpanded &&
                <Grid item xs={12}>
                    {children}
                </Grid>
            }
        </StyledGrid>
    );
};

export default ExpansionPanel;
