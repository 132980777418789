import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { GenericApiResponse } from '../../interfaces/services';
import { AnalyticsWorkspace, AnalyticsReport, AnalyticsReports } from '../../interfaces/services/organization';
import { ApiStatus } from '../../helpers/enums';

interface AnalyticsData {
    workspacesListStatus: ApiStatus;
    workspacesList: AnalyticsWorkspace[];
    reportsListStatus: ApiStatus;
    reportsList: AnalyticsReport[];
    reportTokenStatus: ApiStatus;
    reportToken: string;
}

const WORKSPACES_LIST_REQUEST = 'WORKSPACES_LIST_REQUEST';
const WORKSPACES_LIST_SUCCESS = 'WORKSPACES_LIST_SUCCESS';
const WORKSPACES_LIST_FAILURE = 'WORKSPACES_LIST_FAILURE';

const REPORTS_LIST_REQUEST = 'REPORTS_LIST_REQUEST';
const REPORTS_LIST_SUCCESS = 'REPORTS_LIST_SUCCESS';
const REPORTS_LIST_FAILURE = 'REPORTS_LIST_FAILURE';

const REPORT_TOKEN_REQUEST = 'REPORT_TOKEN_REQUEST';
const REPORT_TOKEN_SUCCESS = 'REPORT_TOKEN_SUCCESS';
const REPORT_TOKEN_FAILURE = 'REPORT_TOKEN_FAILURE';

const requestWorkspacesList = (): Action<typeof WORKSPACES_LIST_REQUEST> => {
    return {
        type: WORKSPACES_LIST_REQUEST
    };
};

const receiveWorkspacesList = (json: AnalyticsWorkspace[]): GenericAction<typeof WORKSPACES_LIST_SUCCESS, AnalyticsWorkspace[]> => {
    return {
        type: WORKSPACES_LIST_SUCCESS,
        payload: json
    };
};

const requestWorkspacesListFailed = (): Action<typeof WORKSPACES_LIST_FAILURE> => {
    return {
        type: WORKSPACES_LIST_FAILURE
    };
};

export const fetchAvailableWorkspaces = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestWorkspacesList());

        try {
            const { data } = await ApiService.get({ url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.analyticsWorkspaces}` }) as GenericApiResponse<AnalyticsWorkspace[]>;
            if (data.length > 0 && data[0].length > 0) {
                dispatch(receiveWorkspacesList(data[0]));
            } else {
                throw new Error('Unable to retrieve workspaces.');
            }
        } catch (error) {
            dispatch(requestWorkspacesListFailed());
            toast.error('Unable to fetch specified workspace.');
        }
    };
};

const requestReportsList = (): Action<typeof REPORTS_LIST_REQUEST> => {
    return {
        type: REPORTS_LIST_REQUEST
    };
};

const receiveReportsList = (json: AnalyticsReport[]): GenericAction<typeof REPORTS_LIST_SUCCESS, AnalyticsReport[]> => {
    return {
        type: REPORTS_LIST_SUCCESS,
        payload: json
    };
};

const requestReportsListFailed = (): Action<typeof REPORTS_LIST_FAILURE> => {
    return {
        type: REPORTS_LIST_FAILURE
    };
};

export const fetchReportsList = (workspaceGuid: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestReportsList());

        try {
            const { data } = await ApiService.get({ url: `${getState().availableServices.endpoints.DashboardsApi}${Endpoints.dashboardsApi.reportsList}/${workspaceGuid}/reports` }) as GenericApiResponse<AnalyticsReports>;
            if (data.length > 0) {
                dispatch(receiveReportsList(data[0].value));
            } else {
                throw new Error('Unable to retrieve reports.');
            }
        } catch (error) {
            dispatch(requestReportsListFailed());
            toast.error('Unable to fetch specified report.');
        }
    };
};

const requestReportToken = (): Action<typeof REPORT_TOKEN_REQUEST> => {
    return {
        type: REPORT_TOKEN_REQUEST
    };
};

const receiveReportToken = (json: { token: string; }): GenericAction<typeof REPORT_TOKEN_SUCCESS, { token: string; }> => {
    return {
        type: REPORT_TOKEN_SUCCESS,
        payload: json
    };
};

const requestReportTokenFailed = (): Action<typeof REPORT_TOKEN_FAILURE> => {
    return {
        type: REPORT_TOKEN_FAILURE
    };
};

export const fetchReportToken = (reportGuid: string, workspaceGuid: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestReportToken());
        try {
            const { data } = await ApiService.get({ url: `${getState().availableServices.endpoints.DashboardsApi}${Endpoints.dashboardsApi.reportToken}/${workspaceGuid}/reports/${reportGuid}/GenerateToken` }) as GenericApiResponse<{ token: string; }>;
            if (data.length > 0) {
                dispatch(receiveReportToken(data[0]));
            } else {
                throw new Error('Unable to retrieve report token.');
            }
        } catch (error) {
            dispatch(requestReportTokenFailed());
            toast.error('Unable to fetch chart config.');
        }
    };
};

type AnalyticsActionTypes =
    ReturnType<typeof requestWorkspacesList> | ReturnType<typeof receiveWorkspacesList> | ReturnType<typeof requestWorkspacesListFailed> |
    ReturnType<typeof requestReportsList> | ReturnType<typeof receiveReportsList> | ReturnType<typeof requestReportsListFailed> |
    ReturnType<typeof requestReportToken> | ReturnType<typeof receiveReportToken> | ReturnType<typeof requestReportTokenFailed>;

export const analyticsReducer = (analyticsData: AnalyticsData = {
    workspacesListStatus: ApiStatus.Idle,
    workspacesList: [],
    reportsListStatus: ApiStatus.Idle,
    reportsList: [],
    reportTokenStatus: ApiStatus.Idle,
    reportToken: ''
}, action: AnalyticsActionTypes): AnalyticsData => {
    switch (action.type) {
        case WORKSPACES_LIST_REQUEST: {
            return {
                ...analyticsData,
                workspacesListStatus: ApiStatus.Loading
            };
        }
        case WORKSPACES_LIST_SUCCESS: {
            return {
                ...analyticsData,
                workspacesListStatus: ApiStatus.Success,
                workspacesList: action.payload
            };
        }
        case WORKSPACES_LIST_FAILURE: {
            return {
                ...analyticsData,
                workspacesListStatus: ApiStatus.Failure,
                workspacesList: []
            };
        }
        case REPORTS_LIST_REQUEST: {
            return {
                ...analyticsData,
                reportsListStatus: ApiStatus.Loading
            };
        }
        case REPORTS_LIST_SUCCESS: {
            return {
                ...analyticsData,
                reportsListStatus: ApiStatus.Success,
                reportsList: action.payload
            };
        }
        case REPORTS_LIST_FAILURE: {
            return {
                ...analyticsData,
                reportsListStatus: ApiStatus.Failure,
                reportsList: []
            };
        }
        case REPORT_TOKEN_REQUEST: {
            return {
                ...analyticsData,
                reportTokenStatus: ApiStatus.Loading
            };
        }
        case REPORT_TOKEN_SUCCESS: {
            return {
                ...analyticsData,
                reportTokenStatus: ApiStatus.Success,
                reportToken: action.payload.token
            };
        }
        case REPORT_TOKEN_FAILURE: {
            return {
                ...analyticsData,
                reportTokenStatus: ApiStatus.Failure,
                reportToken: ''
            };
        }
        default:
            return analyticsData;
    }
};
