import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, SortDirection } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { OrderListData, OrdersListRequestPayload } from '../../interfaces/services/orders';
import { Filter } from '../../interfaces/services/shipment';
import { GenericApiResponse } from '../../interfaces/services';

const ORDERS_LIST_REQUEST = 'ORDERS_LIST_REQUEST';
const ORDERS_LIST_SUCCESS = 'ORDERS_LIST_SUCCESS';
const ORDERS_LIST_FAILURE = 'ORDERS_LIST_FAILURE';
const ORDERS_LIST_UPDATE_FILTERS = 'ORDERS_LIST_UPDATE_FILTERS';
const ORDERS_LIST_UPDATE_SORT = 'ORDERS_LIST_UPDATE_SORT';

interface OrdersData {
    ordersListStatus: ApiStatus;
    ordersList: OrderListData[];
    totalRecords: number;
    activeOrders: boolean;
    skip: number;
    take: number;
    sortColumn: keyof OrderListData;
    sortDirection: SortDirection;
    searchTerm: string;
    filters: Filter[];
    filtersName: string;
}

const requestOrdersList = (json: OrdersListRequestPayload): GenericAction<typeof ORDERS_LIST_REQUEST, OrdersListRequestPayload> => {
    return {
        type: ORDERS_LIST_REQUEST,
        payload: json
    };
};

const receiveOrdersList = (json: GenericApiResponse<OrderListData>): GenericAction<typeof ORDERS_LIST_SUCCESS, GenericApiResponse<OrderListData>> => {
    return {
        type: ORDERS_LIST_SUCCESS,
        payload: json
    };
};

const requestOrdersListFailed = (): Action<typeof ORDERS_LIST_FAILURE> => {
    return {
        type: ORDERS_LIST_FAILURE
    };
};

export const updateOrdersFilters = (filters: Filter[], filtersName: string = ''): GenericAction<typeof ORDERS_LIST_UPDATE_FILTERS,
    {
        filters: Filter[];
        filtersName: string;
    }> => {
    return {
        type: ORDERS_LIST_UPDATE_FILTERS,
        payload: {
            filters,
            filtersName
        }
    };
};

export const updateOrdersSort = (sortColumn: keyof OrderListData, sortDirection: SortDirection): GenericAction<typeof ORDERS_LIST_UPDATE_SORT,
    {
        sortColumn: keyof OrderListData;
        sortDirection: SortDirection;
    }> => {
    return {
        type: ORDERS_LIST_UPDATE_SORT,
        payload: {
            sortColumn,
            sortDirection
        }
    };
};

export const fetchOrdersList = ({
    activeOrders = true,
    skip = 0,
    take = 100,
    sortColumn = 'pickupScheduleStartDateTime',
    sortDirection = SortDirection.Descending,
    searchTerm = '',
    filters = []
}: {
    activeOrders?: boolean;
    skip?: number;
    take?: number;
    sortColumn?: keyof OrderListData;
    sortDirection?: SortDirection;
    searchTerm?: string;
    filters?: Filter[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const requestBody: OrdersListRequestPayload = {
            activeOrders,
            skip,
            take,
            sortColumn,
            sortDirection,
            searchTerm,
            filters
        };
        dispatch(requestOrdersList(requestBody));

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.orders}`,
                body: requestBody
            }) as GenericApiResponse<OrderListData>;
            dispatch(receiveOrdersList(json));
        } catch (error) {
            dispatch(requestOrdersListFailed());
            toast.error('Error occurred while fetching orders.');
        }
    };
};

type OrdersActionTypes = ReturnType<typeof requestOrdersList> | ReturnType<typeof receiveOrdersList> | ReturnType<typeof requestOrdersListFailed> |
    ReturnType<typeof updateOrdersFilters> | ReturnType<typeof updateOrdersSort>;

export const ordersReducer = (ordersData: OrdersData = {
    ordersListStatus: ApiStatus.Idle,
    ordersList: [],
    totalRecords: 0,
    activeOrders: true,
    skip: 0,
    take: 100,
    sortColumn: 'pickupScheduleStartDateTime',
    sortDirection: SortDirection.Descending,
    searchTerm: '',
    filters: [],
    filtersName: ''
}, action: OrdersActionTypes): OrdersData => {
    switch (action.type) {
        case ORDERS_LIST_REQUEST: {
            return {
                ...ordersData,
                ordersListStatus: ApiStatus.Loading,
                activeOrders: action.payload.activeOrders,
                skip: action.payload.skip,
                take: action.payload.take,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection,
                searchTerm: action.payload.searchTerm,
                filters: action.payload.filters
            };
        }
        case ORDERS_LIST_SUCCESS: {
            return {
                ...ordersData,
                ordersListStatus: ApiStatus.Success,
                ordersList: action.payload.data,
                totalRecords: action.payload.totalPossibleCount
            };
        }
        case ORDERS_LIST_FAILURE: {
            return {
                ...ordersData,
                ordersListStatus: ApiStatus.Failure,
                ordersList: []
            };
        }
        case ORDERS_LIST_UPDATE_FILTERS: {
            return {
                ...ordersData,
                ordersListStatus: ApiStatus.Idle,
                filters: action.payload.filters,
                filtersName: action.payload.filtersName
            };
        }
        case ORDERS_LIST_UPDATE_SORT: {
            return {
                ...ordersData,
                ordersListStatus: ApiStatus.Idle,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection
            };
        }
        default:
            return ordersData;
    }
};
