import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    Typography,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { fetchViewsList } from '../../redux/views';
import { ApiStatus, SavedViewType, ShareType } from '../../helpers/enums';
import { viewSortOptions } from '../../helpers/hardcodedOptionLists';
import { ViewsSortOption } from '../../interfaces/componentInterfaces';
import SearchInput from '../inputs/searchInput';
import SortSelect from '../selects/sortSelect';
import ViewCard from '../cards/viewCard';
import ListPagination from '../paginations/listPagination';
import ViewDialog from '../dialogs/viewDialog';

const classesPrefix = 'viewsList';

const classes = {
    viewsContainer: `${classesPrefix}-viewsContainer`,
    searchWrapper: `${classesPrefix}-searchWrapper`,
    loadingIcon: `${classesPrefix}-loadingIcon`,
    noViewsError: `${classesPrefix}-noViewsError`,
    actionsContainer: `${classesPrefix}-actionsContainer`
};

const StyledSearchDiv = styled('div')(() => {
    return {
        [`&.${classes.searchWrapper}`]: {
            margin: '8px'
        }
    };
});

const StyledViewsDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.viewsContainer}`]: {
            flexGrow: 1,
            overflowY: 'auto',
            padding: '8px 8px 8px',
            borderTop: `2px solid ${theme.palette.divider}`
        },
        [`& .${classes.loadingIcon}`]: {
            marginRight: '4px'
        },
        [`& .${classes.noViewsError}`]: {
            margin: '8px'
        }
    };
});

const StyleActionsGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.actionsContainer}`]: {
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            textAlign: 'center'
        }
    };
});

const ViewsList = ({
    viewFilters,
    createViewType
}: {
    viewFilters: SavedViewType[];
    createViewType: SavedViewType;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);

    const viewsListStatus = useTypedSelector((state) => { return state.views.viewsListStatus; });
    const viewsList = useTypedSelector((state) => { return state.views.viewsList; });
    const skip = useTypedSelector((state) => { return state.views.skip; });
    const take = useTypedSelector((state) => { return state.views.take; });
    const searchTerm = useTypedSelector((state) => { return state.views.searchTerm; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const sortDirection = useTypedSelector((state) => { return state.views.sortDirection; });
    const sortColumn = useTypedSelector((state) => { return state.views.sortColumn; });
    const totalRecords = useTypedSelector((state) => { return state.views.totalRecords; });

    const selectedSort = useMemo((): ViewsSortOption => {
        const sortOption = viewSortOptions.find((sortItem): boolean => {
            return sortItem.sortDirection === sortDirection && sortItem.sortColumn === sortColumn;
        });

        return sortOption || viewSortOptions[0];
    }, [sortDirection, sortColumn]);

    useEffect((): void => {
        dispatch(fetchViewsList({
            viewFilters
        }));
    }, [dispatch, viewFilters]);

    const handleSearchTermSubmit = (submittedSearchTerm: string): void => {
        dispatch(fetchViewsList({
            searchTerm: submittedSearchTerm,
            sortColumn,
            sortDirection,
            viewFilters
        }));
    };

    const renderViewList = (): JSX.Element => {
        if (viewsListStatus === ApiStatus.Idle || viewsListStatus === ApiStatus.Loading) {
            return (
                <Fragment>
                    {
                        Array.from(new Array(3)).map((item, index): JSX.Element => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <Accordion key={index}>
                                    <AccordionSummary expandIcon={<Skeleton variant='rectangular' width='64px' height='30px' />}>
                                        <Grid container alignItems='center'>
                                            <Skeleton className={classes.loadingIcon} variant='circular' width='24px' height='24px' />
                                            <Skeleton variant='text' width='120px' />
                                        </Grid>
                                    </AccordionSummary>
                                </Accordion>
                            );
                        })
                    }
                </Fragment>
            );
        }

        if (viewsList.length === 0) {
            return (
                <Typography className={classes.noViewsError}>No views found.</Typography>
            );
        }

        return (
            <Fragment>
                {
                    viewsList.map((view) => {
                        return (
                            <ViewCard
                                key={view.customViewGuid}
                                view={view}
                            />
                        );
                    })
                }
            </Fragment>
        );
    };

    return (
        <Fragment>
            <StyledSearchDiv className={classes.searchWrapper}>
                <SearchInput
                    id='viewSearch'
                    inputLabel='Search'
                    searchTerm={searchTerm}
                    handleSearchTermSubmit={handleSearchTermSubmit}
                />
                <SortSelect
                    id='viewSort'
                    selectedValue={selectedSort}
                    availableOptions={viewSortOptions}
                    handleChange={(newValue): void => {
                        dispatch(fetchViewsList({
                            skip,
                            take,
                            searchTerm,
                            sortColumn: newValue.sortColumn,
                            sortDirection: newValue.sortDirection,
                            viewFilters
                        }));
                    }}
                />
            </StyledSearchDiv>

            <StyledViewsDiv className={classes.viewsContainer} data-qa='views-container'>
                {renderViewList()}
            </StyledViewsDiv>

            <StyleActionsGrid
                container
                justifyContent='space-around'
                alignItems='center'
                className={classes.actionsContainer}
            >
                {
                    shareType !== ShareType.OperatorReadOnly && shareType !== ShareType.CustomerReadOnly &&
                    <Grid item>
                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={(): void => {
                                setViewDialogIsOpen(true);
                            }}
                        >
                            Create
                        </Button>
                    </Grid>
                }

                <Grid item>
                    <ListPagination
                        isFetchingData={viewsListStatus === ApiStatus.Idle || viewsListStatus === ApiStatus.Loading}
                        skip={skip}
                        take={take}
                        showPaginationTotals={false}
                        loadedRecordsCount={viewsList.length}
                        totalRecordsCount={totalRecords}
                        showFirstButton={false}
                        showLastButton={false}
                        handlePaginationChange={(newSkip: number): void => {
                            dispatch(fetchViewsList({
                                skip: newSkip,
                                take,
                                searchTerm,
                                sortColumn,
                                sortDirection,
                                viewFilters
                            }));
                        }}
                    />
                </Grid>
            </StyleActionsGrid>

            {
                viewDialogIsOpen &&
                <ViewDialog
                    createViewType={createViewType}
                    isOpen={viewDialogIsOpen}
                    closeDialog={(): void => {
                        setViewDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ViewsList;
