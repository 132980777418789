import React, { Fragment } from 'react';
import { Grid, GridProps, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import {
    mdiFire,
    mdiWeatherLightningRainy,
    mdiBarcodeScan,
    mdiThermometerAlert,
    mdiMessageBulleted,
    mdiFileDocument
} from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { getWeatherSeverityColor } from '../../helpers/styleHelpers';
import { WeatherAlertSeverity } from '../../helpers/enums';

interface StyleProps extends GridProps {
    weatherSeverity?: WeatherAlertSeverity | null;
}

const classesPrefix = 'statusActionBar';

const classes = {
    iconButton: `${classesPrefix}-iconButton`,
    statusIcon: `${classesPrefix}-statusIcon`,
    priority: `${classesPrefix}-priority`,
    weather: `${classesPrefix}-weather`,
    exceptions: `${classesPrefix}-exceptions`,
    temperature: `${classesPrefix}-temperature`,
    notes: `${classesPrefix}-notes`,
    documents: `${classesPrefix}-documents`
};

const StyledGrid = styled(
    Grid,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'weatherSeverity'; }
    }
)<StyleProps>(({ weatherSeverity, theme }) => {
    return {
        [`&.${classes.statusIcon}`]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '28px',
            width: '28px',
            borderRadius: '50%',
            margin: '4px'
        },
        [`&.${classes.priority}`]: {
            backgroundColor: theme.appColors.priorityActionBackground,
            color: theme.palette.common.white
        },
        [`&.${classes.weather}`]: {
            backgroundColor: getWeatherSeverityColor(weatherSeverity || null)
        },
        [`&.${classes.exceptions}`]: {
            backgroundColor: theme.appColors.exceptionsActionBackground
        },
        [`&.${classes.temperature}`]: {
            backgroundColor: theme.appColors.temperatureActionBackground
        },
        [`&.${classes.notes}`]: {
            backgroundColor: theme.appColors.notesActionBackground
        },
        [`&.${classes.documents}`]: {
            backgroundColor: theme.appColors.documentsActionBackground
        },
        [`& .${classes.iconButton}`]: {
            padding: 0,
            color: theme.palette.common.white
        }
    };
});

const StatusActionBar = ({
    isPriorityShipment,
    handleShipmentPriorityClick,
    weatherSeverity,
    handleWeatherSeverityClick,
    totalShipmentExceptions,
    handleShipmentExceptionsClick,
    hasTemperatureExceededBounds,
    handleTemperatureExceededBoundsClick,
    hasShipmentNotes,
    handleShipmentNotesClick,
    hasShipmentDocuments,
    handleShipmentDocumentsClick
}: {
    /** Display priority shipment indicator */
    isPriorityShipment: boolean;
    /** Handle the click of the shipment priority icon */
    handleShipmentPriorityClick: () => void;
    /** Display weather severity indicator */
    weatherSeverity: WeatherAlertSeverity | null;
    /** Handle the click of the weather severity icon */
    handleWeatherSeverityClick: () => void;
    /** Display Shipment Exceptions indicator */
    totalShipmentExceptions: number | null;
    /** Handle the click of the shipment exceptions icon */
    handleShipmentExceptionsClick: () => void;
    /** Display temperature bounds breach indicator */
    hasTemperatureExceededBounds: boolean;
    /** Handle the click of the temperature bounds breach icon */
    handleTemperatureExceededBoundsClick: () => void;
    /** Display shipment notes indicator */
    hasShipmentNotes: boolean;
    /** Handle the click of the shipment notes icon */
    handleShipmentNotesClick: () => void;
    /** Display shipment documents indicator */
    hasShipmentDocuments: boolean;
    /** Handle the click of the shipment documents icon */
    handleShipmentDocumentsClick: () => void;
}): JSX.Element => {
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    return (
        <Fragment>
            {
                isPriorityShipment &&
                <StyledGrid item className={`${classes.statusIcon} ${classes.priority}`} data-qa='shipmentPriority-container'>
                    <Tooltip title='Priority Shipment'>
                        { /* Priority Shipment is only actionable when it is not a shared shipment */
                            shareType === null
                                ? (
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={(): void => {
                                            handleShipmentPriorityClick();
                                        }}
                                        data-qa='shipmentPriority-button'
                                    >
                                        <Icon path={mdiFire} size='20px' />
                                    </IconButton>
                                ) : (
                                    <Icon path={mdiFire} size='20px' />
                                )
                        }
                    </Tooltip>
                </StyledGrid>
            }
            {
                weatherSeverity !== null &&
                <StyledGrid item weatherSeverity={weatherSeverity} className={`${classes.statusIcon} ${classes.weather}`} data-qa='weatherSeverity-container'>
                    <Tooltip title={`${weatherSeverity} Weather Alert`}>
                        <IconButton
                            className={classes.iconButton}
                            onClick={(): void => {
                                handleWeatherSeverityClick();
                            }}
                            data-qa='weatherSeverity-button'
                        >
                            <Icon path={mdiWeatherLightningRainy} size='20px' />
                        </IconButton>
                    </Tooltip>
                </StyledGrid>
            }
            {
                totalShipmentExceptions !== null && totalShipmentExceptions > 0 &&
                <StyledGrid item className={`${classes.statusIcon} ${classes.exceptions}`} data-qa='shipmentExceptions-container'>
                    <Tooltip title='Shipment Exceptions'>
                        <IconButton
                            className={classes.iconButton}
                            onClick={(): void => {
                                handleShipmentExceptionsClick();
                            }}
                            data-qa='shipmentExceptions-button'
                        >
                            <Icon path={mdiBarcodeScan} size='20px' />
                        </IconButton>
                    </Tooltip>
                </StyledGrid>
            }
            {
                hasTemperatureExceededBounds &&
                <StyledGrid item className={`${classes.statusIcon} ${classes.temperature}`} data-qa='temperatureAlerts-container'>
                    <Tooltip title='Temperature Alerts'>
                        <IconButton
                            className={classes.iconButton}
                            onClick={(): void => {
                                handleTemperatureExceededBoundsClick();
                            }}
                            data-qa='temperatureAlert-button'
                        >
                            <Icon path={mdiThermometerAlert} size='20px' />
                        </IconButton>
                    </Tooltip>
                </StyledGrid>
            }
            {
                hasShipmentNotes &&
                <StyledGrid item className={`${classes.statusIcon} ${classes.notes}`} data-qa='shipmentNotes-container'>
                    <Tooltip title='Shipment Notes'>
                        <IconButton
                            className={classes.iconButton}
                            onClick={(): void => {
                                handleShipmentNotesClick();
                            }}
                            data-qa='shipmentNotes-button'
                        >
                            <Icon path={mdiMessageBulleted} size='20px' />
                        </IconButton>
                    </Tooltip>
                </StyledGrid>
            }
            {
                hasShipmentDocuments &&
                <StyledGrid item className={`${classes.statusIcon} ${classes.documents}`} data-qa='shipmentDocuments-container'>
                    <Tooltip title='Shipment Documents'>
                        <IconButton
                            className={classes.iconButton}
                            onClick={(): void => {
                                handleShipmentDocumentsClick();
                            }}
                            data-qa='shipmentDocument-button'
                        >
                            <Icon path={mdiFileDocument} size='20px' />
                        </IconButton>
                    </Tooltip>
                </StyledGrid>
            }
        </Fragment>
    );
};

export default StatusActionBar;
