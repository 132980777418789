import React, { Fragment } from 'react';
import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';

import { formatFullAddress } from '../../helpers/addressUtils';
import { formatPhoneNumber } from '../../helpers/dataUtils';
import LabelValuePair from './labelValuePair';

const classesPrefix = 'stopContact';

const classes = {
    displayData: `${classesPrefix}-displayData`,
    address: `${classesPrefix}-address`
};

const StyledAddressSpan = styled('span')(() => {
    return {
        [`&.${classes.address}`]: {
            display: 'inline-block'
        }
    };
});

const StyledExtensionSpan = styled('span')(() => {
    return {
        [`&.${classes.displayData}`]: {
            marginLeft: '4px',
            marginRight: '4px'
        }
    };
});

const StopContact = ({
    street1,
    street2,
    city,
    region,
    postal,
    country,
    contactName,
    phoneNumber,
    phoneExtension
}: {
    /** The street1 for the stop */
    street1: string | null;
    /** The street2 for the stop */
    street2: string | null;
    /** The city for the stop */
    city: string | null;
    /** The region or state for the stop */
    region: string | null;
    /** The postal code for the stop */
    postal: string | null;
    /** The country code for the stop */
    country: string | null;
    /** The contact name for the stop */
    contactName: string | null;
    /** The contact phone number for the stop */
    phoneNumber: string | null;
    /** The phone extension for the stop */
    phoneExtension: string | null;
}): JSX.Element => {
    return (
        <Fragment>
            <LabelValuePair
                label='Address'
                value={
                    <StyledAddressSpan className={classes.address} data-qa='stopAddress'>
                        {
                            formatFullAddress({
                                street1,
                                street2,
                                city,
                                region,
                                postal,
                                country
                            })
                        }
                    </StyledAddressSpan>
                }
                fontSize='12px'
                data-qa='stopAddress'
            />
            <LabelValuePair label='Contact Name' value={contactName || ''} fontSize='12px' data-qa='contactName' />
            <LabelValuePair
                label='Phone'
                value={
                    <Fragment>
                        {
                            phoneNumber &&
                            <Fragment>
                                <Link href={`tel:${phoneNumber}`} data-qa='phoneNumber'>
                                    {formatPhoneNumber(phoneNumber)}
                                </Link>
                                {
                                    phoneExtension &&
                                    <Fragment>
                                        <StyledExtensionSpan className={classes.displayData} data-qa='extension-label'>
                                            ext.
                                        </StyledExtensionSpan>
                                        <span data-qa='extension'>
                                            {phoneExtension}
                                        </span>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Fragment>
                }
                fontSize='12px'
                data-qa='phone'
            />
        </Fragment>
    );
};

export default StopContact;
