import React from 'react';
import { useParams } from 'react-router-dom';
import { Toolbar, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus, ShareType } from '../../helpers/enums';
import ShipmentDetailsActions from '../actionBars/shipmentDetailsActions';
import ShipmentDetails from '../sections/shipmentDetails';

const classesPrefix = 'shipmentDetailsPage';

const classes = {
    wrapper: `${classesPrefix}-wrapper`,
    toolbar: `${classesPrefix}-toolbar`,
    title: `${classesPrefix}-title`,
    inlineSkeleton: `${classesPrefix}-inlineSkeleton`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.wrapper}`]: {
            backgroundColor: theme.appColors.pageBackground,
            paddingBottom: '16px'
        },
        [`& .${classes.toolbar}`]: {
            top: '48px',
            right: 0,
            left: 'auto',
            position: 'sticky',
            zIndex: 11
        },
        [`& .${classes.title}`]: {
            marginRight: '16px',
            flexGrow: 1
        },
        [`& .${classes.inlineSkeleton}`]: {
            display: 'inline-flex',
            marginLeft: '4px'
        }
    };
});

const ShipmentDetailsPage = (): JSX.Element => {
    const { shipmentUniqueName } = useParams<{ shipmentUniqueName: string; }>();
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    return (
        <StyledDiv className={classes.wrapper} data-qa='shipmentDetailsPage-container'>
            <Toolbar classes={{ root: classes.toolbar }}>
                <Typography variant='h6' className={classes.title} data-qa='shipmentDetails-header'>
                    Shipment
                    {
                        shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading
                            ? <Skeleton className={classes.inlineSkeleton} variant='text' width='100px' />
                            : ` ${shipment.freightProviderReferenceNumber || ''}`
                    }
                </Typography>
                { /* If the shipment or stop is not shared, add the actions */
                    (shareType === null || shareType === ShareType.CustomerReadOnly) &&
                    <ShipmentDetailsActions />
                }
            </Toolbar>
            <ShipmentDetails
                shipmentUniqueName={shipmentUniqueName}
            />
        </StyledDiv>
    );
};

export default ShipmentDetailsPage;
