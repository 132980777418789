import React, { Fragment } from 'react';
import { Button, Link } from '@mui/material';
import { mdiInformationOutline } from '@mdi/js';

import { formatPhoneNumber } from '../../helpers/dataUtils';
import CommonDialog from './common/commonDialog';
import LabelValuePair from '../labels/labelValuePair';

const StopContactDialog = ({
    contactName,
    contactPhoneNumber,
    contactEmail,
    isOpen,
    closeDialog
}: {
    contactName: string | null;
    contactPhoneNumber: string | null;
    contactEmail: string | null;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const handleClose = (): void => {
        closeDialog();
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Fragment>
                <LabelValuePair
                    label='Contact Name'
                    value={contactName}
                    data-qa='stopContactName'
                />
                <LabelValuePair
                    label='Contact Phone'
                    value={
                        contactPhoneNumber &&
                        <Link href={`tel:${contactPhoneNumber}`}>
                            {formatPhoneNumber(contactPhoneNumber)}
                        </Link>
                    }
                    data-qa='stopContactPhoneNumber'
                />
                <LabelValuePair
                    label='Contact Email'
                    value={
                        <Link href={`mailto:${contactEmail}`}>
                            {contactEmail}
                        </Link>
                    }
                    data-qa='stopContactEmail'
                />
            </Fragment>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='xs'
            headerIcon={mdiInformationOutline}
            headerText='Stop Contact'
            content={renderDialogContent()}
            actions={(
                <Button
                    onClick={handleClose}
                    data-qa='close-button'
                >
                    Close
                </Button>
            )}
        />
    );
};

export default StopContactDialog;
