import React from 'react';
import {
    Grid,
    Skeleton,
    Typography,
    Card,
    CardContent
} from '@mui/material';

import LabelValuePairLoading from '../labels/labelValuePairLoading';

const ShipmentSummaryCardLoading = (): JSX.Element => {
    return (
        <Card>
            <CardContent>
                <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                    <Grid item xs={4}>
                        <Skeleton variant='text' width='70px' />
                    </Grid>
                    <Grid item>
                        <Skeleton variant='rectangular' width='100px' height='30px' />
                    </Grid>
                    <Grid item xs={12}>
                        <LabelValuePairLoading label='ETA' width='120px' />
                    </Grid>
                    <Grid item xs={12}>
                        <LabelValuePairLoading label='Shipment ID' width='120px' />
                        <LabelValuePairLoading label='Mode' width='120px' />
                        <LabelValuePairLoading label='Carrier' width='120px' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize='12px' color='textSecondary'>
                            Driver Info
                        </Typography>
                        <LabelValuePairLoading label='Name' width='120px' />
                        <LabelValuePairLoading label='Phone' width='120px' />
                        <LabelValuePairLoading label='Email' width='180px' />
                        <LabelValuePairLoading label='Mobile Tracking Number' width='180px' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize='12px' color='textSecondary'>
                            Freight Provider
                        </Typography>
                        <LabelValuePairLoading label='Name' width='120px' />
                        <LabelValuePairLoading label='Phone' width='120px' />
                        <LabelValuePairLoading label='Email' width='180px' />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ShipmentSummaryCardLoading;
