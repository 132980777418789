import React, { useCallback, useEffect, useState } from 'react';
import { TextField, Tooltip, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiPound, mdiClose } from '@mdi/js';

const classesPrefix = 'colorPicker';

const classes = {
    textField: `${classesPrefix}-textField`
};

const StyledTextField = styled(TextField)(() => {
    return {
        [`&.${classes.textField}`]: {
            width: '160px'
        }
    };
});

const ColorPicker = ({
    label,
    color,
    onChange,
    dataQa
}: {
    /** Label to show on the color picker. */
    label: string;
    /** HEX color value or null. */
    color: string | null;
    /** Handles the change for the color. */
    onChange: (newColor: string | null) => void;
    /** data-qa description */
    dataQa: string;
}): JSX.Element => {
    /** Escapes all non-hexadecimal characters including "#" */
    const escape = useCallback((value: string) => {
        return value.replace(/([^0-9A-F]+)/gi, '').substr(0, 6);
    }, []);

    /** Validates hexadecimal strings */
    const validate = useCallback((value: string) => {
        const hexValidator = /^#?([0-9A-F]{3,8})$/i;
        const match = hexValidator.exec(value);
        const hexLength = match ? match[1].length : 0;

        return (
            hexLength === 3 || // '#rgb' format
            hexLength === 6 // '#rrggbb' format
        );
    }, []);

    const [draftColor, setDraftColor] = useState(() => { return escape(color || ''); });

    // Update the local state when `color` property value is changed
    useEffect(() => {
        setDraftColor(escape(color || ''));
    }, [color, escape]);

    // Trigger `onChange` handler only if the input value is a valid color or if it's empty
    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = escape(event.target.value);
        setDraftColor(inputValue);
        if (event.target.value === '') {
            onChange(null);
        } else if (validate(inputValue)) {
            // Adds "#" symbol to the beginning of the string
            onChange(`#${inputValue}`);
        }
    }, [escape, validate, onChange]);

    // Take the color from props if the last typed draftColor (in local state) is not valid
    const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (!validate(event.target.value)) {
            setDraftColor(escape(color || ''));
        }
    }, [color, escape, validate]);

    const handleDelete = (): void => {
        setDraftColor('');
        onChange(null);
    };

    return (
        <StyledTextField
            className={classes.textField}
            label={label}
            placeholder='HEX Color'
            variant='standard'
            margin='normal'
            spellCheck='false'
            value={draftColor}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <Icon path={mdiPound} size={0.75} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position='end'>
                        <Tooltip title={`Remove Custom ${label} Color`}>
                            <IconButton onClick={handleDelete}>
                                <Icon path={mdiClose} size={1} />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                )
            }}
            data-qa={dataQa}
        />
    );
};

export default ColorPicker;
