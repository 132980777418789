import React from 'react';
import {
    Card,
    CardContent,
    Grid
} from '@mui/material';

import { Order } from '../../interfaces/services/shipmentDetails';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import LabelValuePair from '../labels/labelValuePair';
import OrderCommoditiesTable from '../tables/orderCommoditiesTable';

const OrderDetailsCard = ({
    order
}: {
    order: Order;
}): JSX.Element => {

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <LabelValuePair label='Order ID' value={order.orderNumber} data-qa='orderId' />
                        {
                            order.customerReference &&
                            <LabelValuePair label='Customer Reference' value={order.customerReference} data-qa='customerReference' />
                        }
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {
                            order.customerName &&
                            <LabelValuePair label='Customer Name' value={order.customerName} data-qa='customerName' />
                        }
                        {
                            order.orderDateTime &&
                            <LabelValuePair label='Order Date' value={zonedDateTimeToDisplay(order.orderDateTime)} data-qa='orderDate' />
                        }
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {
                            order.orderTypeName &&
                            <LabelValuePair label='Order Type' value={order.orderTypeName} data-qa='orderType' />
                        }
                        {
                            order.serviceLevel &&
                            <LabelValuePair label='Service Level' value={order.serviceLevel} data-qa='serviceLevel' />
                        }
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {
                            order.orderReference &&
                            <LabelValuePair label='Order Reference' value={order.orderReference} data-qa='orderReference' />
                        }
                    </Grid>
                </Grid>
                {
                    order.commodities.length > 0 &&
                    <OrderCommoditiesTable commodities={order.commodities} />
                }
            </CardContent>
        </Card>
    );
};

export default OrderDetailsCard;
