import React, { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { OrderListMapSourceProperties } from '../../helpers/maps/sources/orderList';
import OrdersMap from './ordersMap';
import OrderDetailsDialog from '../dialogs/orderDetailsDialog';

const classesPrefix = 'ordersMapWrapper';

const classes = {
    mapWrapper: `${classesPrefix}-mapWrapper`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.mapWrapper}`]: {
            height: 'calc(100vh - 116px)'
        }
    };
});

const OrdersMapWrapper = ({
    resize
}: {
    /** Indicator to trigger a map resize function. */
    resize?: boolean;
}): JSX.Element => {
    const [orderDetailsDialog, setOrderDetailsDialog] = useState({
        open: false,
        freightOrderGuid: ''
    });

    const ordersListStatus = useTypedSelector((state) => { return state.orders.ordersListStatus; });
    const ordersList = useTypedSelector((state) => { return state.orders.ordersList; });

    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const handleOrderMarkerClick = (properties: OrderListMapSourceProperties): void => {
        const { freightOrderGuid } = properties;

        setOrderDetailsDialog({
            open: true,
            freightOrderGuid
        });
    };

    return (
        <Fragment>
            <StyledDiv className={classes.mapWrapper}>
                <OrdersMap
                    isFetchingData={ordersListStatus === ApiStatus.Idle || ordersListStatus === ApiStatus.Loading}
                    orderList={ordersList}
                    handleOrderMarkerClick={handleOrderMarkerClick}
                    paletteMode={paletteMode}
                    resize={resize}
                />
            </StyledDiv>

            {
                orderDetailsDialog.open && orderDetailsDialog.freightOrderGuid &&
                <OrderDetailsDialog
                    orderGuid={orderDetailsDialog.freightOrderGuid}
                    handleDialogClose={(): void => {
                        setOrderDetailsDialog({
                            open: false,
                            freightOrderGuid: ''
                        });
                    }}
                />
            }
        </Fragment>
    );
};

export default OrdersMapWrapper;
