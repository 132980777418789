import React from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { SortOption, SortColumns } from '../../interfaces/componentInterfaces';

const SortSelect = <T extends SortOption<SortColumns>>({
    id,
    selectedValue,
    availableOptions,
    isDisabled = false,
    handleChange
}: {
    /** Id to use for data-qa fields */
    id: string;
    /** Selected value (can't be null) */
    selectedValue: NonNullable<T>;
    /** List of available option to show in select */
    availableOptions: T[];
    /**
     * Indicator to disable select
     * @default false
     */
    isDisabled?: boolean;
    /** Handle change function */
    handleChange: (newValue: T) => void;
}): JSX.Element => {
    return (
        <Autocomplete
            size='small'
            fullWidth
            disabled={isDisabled}
            disableClearable={true}
            multiple={false}
            options={availableOptions}
            value={selectedValue}
            getOptionLabel={(option): string => { return option.label; }}
            onChange={(event, newValue: T): void => {
                handleChange(newValue);
            }}
            renderInput={(params): JSX.Element => {
                return (
                    <TextField
                        {...params}
                        fullWidth
                        margin='none'
                        variant='outlined'
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            'data-qa': `${id}-input`
                        }}
                    />
                );
            }}
            data-qa={`${id}-select`}
        />
    );
};

export default SortSelect;
