import React, { useMemo } from 'react';

import { SavedViewType } from '../../helpers/enums';
import ViewsList from '../lists/viewsList';

const CalendarViews = (): JSX.Element => {
    const viewFilters = useMemo((): SavedViewType[] => {
        return [SavedViewType.CalendarView];
    }, []);

    return (
        <ViewsList
            viewFilters={viewFilters}
            createViewType={SavedViewType.CalendarView}
        />
    );
};

export default CalendarViews;
