import { GeoJsonTypes, Polygon, Feature } from 'geojson';

import { ShipmentDetailsMapWeatherAlert, WeatherAlert } from '../../../interfaces/services/shipmentWeather';
import { WeatherAlertSeverity } from '../../enums';
import { getWeatherSeverityColor } from '../../styleHelpers';
import { getPolygonGeofencePointsFromWkt } from '../mapUtils';
import { MapSources } from '../enums';

/** Interface used for the properties object on the Shipment Details Map Weather Source. */
export interface ShipmentDetailsMapWeatherSourceProperties {
    color: string;
    countyName: string;
    fipsCode: string;
    weatherAlerts: WeatherAlert[];
}

/** Interface used to define the Shipment Details Map Weather Source. */
export interface ShipmentDetailsMapWeatherSource {
    type: 'geojson';
    data: {
        type: GeoJsonTypes;
        features: Feature<Polygon, ShipmentDetailsMapWeatherSourceProperties>[];
    };
}

/**
 * Using the list of polygons, it will create a feature list for the map source.
 * @param weatherPolygons Weather polygons with alert data to be shown on the map.
 * @returns The geojson source of the weather polygons to be added to the map.
 */
const getShipmentDetailsWeatherSource = (weatherPolygons: ShipmentDetailsMapWeatherAlert[]): ShipmentDetailsMapWeatherSource => {
    const weatherAlertFeatures: Feature<Polygon, ShipmentDetailsMapWeatherSourceProperties>[] = weatherPolygons.map((alert) => {
        const polygonFeature = getPolygonGeofencePointsFromWkt(alert.polygon);

        return {
            type: 'Feature',
            properties: {
                color: getWeatherSeverityColor(alert.alertList[0]?.severity || WeatherAlertSeverity.Unknown),
                countyName: alert.countyName,
                fipsCode: alert.fipsCode,
                weatherAlerts: alert.alertList
            },
            geometry: {
                type: 'Polygon',
                coordinates: [polygonFeature]
            },
            id: alert.fipsCode
        };
    });

    const source: ShipmentDetailsMapWeatherSource = {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: weatherAlertFeatures
        }
    };

    return source;
};

/**
* Adds the shipment details weather source to the map if it doesn't already exist
 * @param map Instance of your map
 * @param weatherAlerts The weather polygons to show on the map
 * @returns The geojson source of the weather polygons to be added to the map.
*/
export const addShipmentDetailsWeatherSource = (map: any, weatherAlerts: ShipmentDetailsMapWeatherAlert[]): ShipmentDetailsMapWeatherSource => {
    const source = getShipmentDetailsWeatherSource(weatherAlerts);

    if (map.getSource(MapSources.shipmentDetailsWeatherSource) === undefined) {
        map.addSource(MapSources.shipmentDetailsWeatherSource, source);
    } else {
        map.getSource(MapSources.shipmentDetailsWeatherSource).setData(source.data);
    }

    return source;
};
