import React, { Fragment } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus, ModeType } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import DriverDetails from '../labels/driverDetails';
import LabelValuePair from '../labels/labelValuePair';
import DriverDetailsLoading from '../loaders/labels/driverDetailsLoading';
import LabelValuePairLoading from '../loaders/labels/labelValuePairLoading';

const classesPrefix = 'shipmentAdditionalDetails';

const classes = {
    additionalDetailsSection: `${classesPrefix}-additionalDetailsSection`
};

const StyledCard = styled(Card)(({ theme }) => {
    return {
        [`& .${classes.additionalDetailsSection}`]: {
            marginTop: '16px'
        },
        [theme.breakpoints.down('md')]: {
            [`& .${classes.additionalDetailsSection}`]: {
                marginTop: '8px'
            }
        }
    };
});

const ShipmentAdditionalDetails = (): JSX.Element => {
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });

    const calculateTotalWeight = (): string => {
        let totalWeight = 0;
        let weightUnitComparator;
        for (let i = 0; i < shipment.orders.length; i++) {
            const order = shipment.orders[i];
            for (let j = 0; j < order.commodities.length; j++) {
                const commodity = order.commodities[j];
                weightUnitComparator = order.commodities[0].weightUnit;
                if (weightUnitComparator !== commodity.weightUnit) {
                    // return an empty string if we get mismatched weightUnits
                    return '';
                }
                if (commodity.weightAmount !== null) {
                    totalWeight += commodity.weightAmount;
                }
            }
        }
        return weightUnitComparator ? `${totalWeight} ${addSpaceBeforeUppercaseCharacter(weightUnitComparator)}` : totalWeight.toString();
    };

    if (shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading) {
        return (
            <StyledCard>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <LabelValuePairLoading label='System ID' width='100px' />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <LabelValuePairLoading label='Tracking Method' width='60px' />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.additionalDetailsSection}>
                        <Grid item xs={12} md={4}>
                            <LabelValuePairLoading label='Equipment Type' width='60px' />
                            <LabelValuePairLoading label='Equipment Size' width='40px' />
                            <LabelValuePairLoading label='Total Weight' width='40px' />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <LabelValuePairLoading label='Total Length of Haul' width='40px' />
                            <LabelValuePairLoading label='Total Miles Remaining' width='40px' />
                            <LabelValuePairLoading label='Total Stops' width='20px' />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DriverDetailsLoading />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.additionalDetailsSection}>
                        <Grid item xs={12} md={4}>
                            <LabelValuePairLoading label='' width='120px' />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <LabelValuePairLoading label='' width='120px' />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <LabelValuePairLoading label='' width='120px' />
                        </Grid>
                    </Grid>
                </CardContent>
            </StyledCard>
        );
    }

    return (
        <StyledCard>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <LabelValuePair label='System ID' value={shipment.shipmentUniqueName} data-qa='systemId' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <LabelValuePair label='Tracking Method' value={shipment.shipmentTrackingType} data-qa='trackingMethod' />
                    </Grid>
                </Grid>
                <Grid container className={classes.additionalDetailsSection}>
                    <Grid item xs={12} md={4}>
                        <LabelValuePair label='Equipment Type' value={shipment.equipmentType || ''} data-qa='equipmentType' />
                        <LabelValuePair label='Equipment Size' value={shipment.equipmentSize || ''} data-qa='equipmentSize' />
                        <LabelValuePair label='Total Weight' value={calculateTotalWeight()} data-qa='totalWeight' />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {
                            shipment.modeType === ModeType.Truckload &&
                            <Fragment>
                                <LabelValuePair label='Total Length of Haul' value={`${shipment.haulLength} ${shipment.haulLinearUnitType}`} data-qa='lengthOfHaul' />
                                <LabelValuePair label={`Total ${shipment.haulLinearUnitType} Remaining`} value={shipment.remainingHaulLength || ''} data-qa='totalMilesRemaining' />
                            </Fragment>
                        }
                        <LabelValuePair label='Total Stops' value={shipment.totalStopCount} data-qa='totalStops' />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DriverDetails
                            driver={shipment.driver}
                            mobileTrackingNumber={shipment.mobileTrackingNumber}
                        />
                    </Grid>
                </Grid>
                {
                    shipment.referenceNumbers.length > 0 &&
                    <Grid container className={classes.additionalDetailsSection}>
                        {
                            shipment.referenceNumbers.map((reference) => {
                                return (
                                    <Grid key={reference.referenceNumber} item xs={12} md={4}>
                                        <LabelValuePair label={addSpaceBeforeUppercaseCharacter(reference.referenceNumberType)} value={reference.referenceNumber} data-qa={reference.referenceNumberType} />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                }
            </CardContent>
        </StyledCard>
    );
};

export default ShipmentAdditionalDetails;
