import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    AppBar,
    Skeleton,
    Toolbar,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TrimbleAppLauncher from '@tcx/trimble-app-launcher/lib/AppLauncher';

import { useTypedSelector } from '../../redux';
import { fetchOrganizationInformation, fetchOrganizationPreferences } from '../../redux/organization';
import { fetchUserInformation } from '../../redux/user';
import { ApiStatus, ShareType } from '../../helpers/enums';
import { publicRoutes } from '../../routes/appRouteList';
import Hidden from '../layouts/hidden';
import HomeButton from '../buttons/homeButton';
import AppHeaderHamburgerMenu from './appHeaderHamburgerMenu';
import AppHeaderAccountMenu from './appHeaderAccountMenu';

import trimbleLogo from '../../assets/images/trimble_logo_white.png';
import EndOfLifeHeader from './endOfLifeBanner';

const classesPrefix = 'appHeader';

const classes = {
    appBar: `${classesPrefix}-appBar`,
    appTitle: `${classesPrefix}-appTitle`,
    appLogoWrapper: `${classesPrefix}-appLogoWrapper`,
    companyLogo: `${classesPrefix}-companyLogo`
};

const StyledAppBar = styled(
    AppBar,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'customLogoToken'; }
    }
)<{ customLogoToken?: string | null; }>(({ customLogoToken, theme }) => {
    const contrastText = theme.palette.getContrastText(theme.appColors.primaryNavBackground);
    return {
        [`&.${classes.appBar}`]: {
            color: contrastText
        },
        [`& .${classes.appTitle}`]: {
            flexGrow: 1,
            borderLeft: `1px solid ${contrastText}`,
            marginLeft: '16px',
            paddingLeft: '16px',
            fontSize: '16px'
        },
        [`& .${classes.companyLogo}`]: {
            ...customLogoToken && { backgroundColor: theme.palette.common.white },
            maxHeight: '40px',
            maxWidth: '325px'
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.appLogoWrapper}`]: {
                flexGrow: 1
            },
            [`& .${classes.companyLogo}`]: {
                maxWidth: '100px'
            }
        }
    };
});

const AppHeader = (): JSX.Element => {
    const dispatch = useDispatch();

    const userLoginStatus = useTypedSelector((state) => { return state.user.userLoginStatus; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const isSharing = shareType === ShareType.Shipment || shareType === ShareType.Stop || shareType === ShareType.Unknown;

    useEffect(() => {
        if (userLoginStatus === ApiStatus.Success) {
            if (isSharing) {
                dispatch(fetchOrganizationPreferences());
            } else {
                dispatch(fetchOrganizationInformation());
                dispatch(fetchOrganizationPreferences());
                dispatch(fetchUserInformation());
            }
        }
    }, [userLoginStatus, isSharing, dispatch]);

    const renderLogo = (): JSX.Element => {
        if (organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading) {
            return (
                <Skeleton className={classes.companyLogo} variant='rectangular' width='150px' height='40px' />
            );
        }

        return (
            <img className={classes.companyLogo} src={organizationPreferences?.customLogoToken || trimbleLogo} alt='company logo' data-qa='hamburgerMenu-company-logo' />
        );
    };

    if (userLoginStatus !== ApiStatus.Idle && userLoginStatus !== ApiStatus.Loading) {
        return (
            <Fragment>
                <StyledAppBar customLogoToken={organizationPreferences?.customLogoToken} className={classes.appBar} position='sticky' data-qa='applicationHeader-container'>
                    <Toolbar variant='dense'>
                        {
                            !isSharing &&
                            <AppHeaderHamburgerMenu />
                        }
                        <div className={classes.appLogoWrapper} data-qa='applicationHeader-companyLogo-container'>
                            {
                                isSharing ?
                                    (
                                        renderLogo()
                                    ) : (
                                        <HomeButton tooltipTitle='Back to Home' homeRoute={publicRoutes.root}>
                                            {renderLogo()}
                                        </HomeButton>
                                    )
                            }
                        </div>
                        <Hidden breakpoint='xs'>
                            <Typography variant='h6' color='inherit' className={classes.appTitle} data-qa='applicationHeader-applicationDescription'>
                                Visibility
                            </Typography>
                        </Hidden>
                        {
                            (shareType === null || shareType === ShareType.OperatorReadOnly || shareType === ShareType.CustomerReadOnly) &&
                            <Fragment>
                                <TrimbleAppLauncher />
                                <AppHeaderAccountMenu />
                            </Fragment>
                        }
                    </Toolbar>
                </StyledAppBar>

                <EndOfLifeHeader />
            </Fragment>

        );
    }

    return <Fragment />;
};

export default AppHeader;
