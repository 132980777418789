import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    CircularProgress,
    DialogContentText,
    Grid,
    Typography
} from '@mui/material';
import { mdiFire } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { updatePageReset, updateShipmentPriority } from '../../redux/dialogUpdates';
import { ApiStatus } from '../../helpers/enums';
import CommonDialog from './common/commonDialog';

const ShipmentPriorityDialog = ({
    freightProviderReferenceNumber,
    shipmentUniqueName,
    isOpen,
    closeDialog
}: {
    freightProviderReferenceNumber: string;
    shipmentUniqueName: string;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    // If the API update is successful, trigger the dialog to reset and close
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            closeDialog();
        }
    }, [status, dispatch, closeDialog]);

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleConfirm = (): void => {
        dispatch(updateShipmentPriority({
            shipmentUniqueName,
            isPriorityShipment: false
        }));
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Fragment>
                <DialogContentText id='alert-dialog-description'>
                    {`Do you want to remove priority shipment flag for shipment ${freightProviderReferenceNumber}?`}
                </DialogContentText>
                {
                    errorMessage &&
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='caption' color='error'>{errorMessage}</Typography>
                        </Grid>
                    </Grid>
                }
            </Fragment>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerIcon={mdiFire}
            headerText='Remove Priority Shipment Flag'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading}
                        onClick={handleConfirm}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='confirm-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Updating' : 'Confirm'
                        }
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default ShipmentPriorityDialog;
