import React from 'react';
import { Grid, Typography, Pagination, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'listPagination';

const classes = {
    paginationWrapper: `${classesPrefix}-paginationWrapper`,
    paginationTextWrapper: `${classesPrefix}-paginationTextWrapper`,
    paginationText: `${classesPrefix}-paginationText`,
    paginatorWrapper: `${classesPrefix}-paginatorWrapper`,
    paginator: `${classesPrefix}-paginator`,
    paginatorLoader: `${classesPrefix}-paginatorLoader`,
    inlineSkeleton: `${classesPrefix}-inlineSkeleton`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.paginationWrapper}`]: {
            alignItems: 'center',
            padding: '8px 8px 0'
        },
        [`& .${classes.paginationTextWrapper}`]: {
            flexGrow: 1,
            paddingBottom: '8px'
        },
        [`& .${classes.paginationText}`]: {
            fontSize: '14px'
        },
        [`& .${classes.paginatorWrapper}`]: {
            margin: 'auto',
            paddingBottom: '8px'
        },
        [`& .${classes.paginatorLoader}`]: {
            width: '70%'
        },
        [`& .${classes.inlineSkeleton}`]: {
            display: 'inline-block',
            margin: '0 4px'
        },
        [theme.breakpoints.only('md')]: {
            [`& .${classes.paginationText}`]: {
                textAlign: 'center'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`& .${classes.paginationText}`]: {
                textAlign: 'center'
            },
            [`& .${classes.paginator}`]: {
                '& .MuiPaginationItem-root': {
                    height: '24px',
                    width: '24px',
                    minWidth: '24px',
                    margin: 0,
                    padding: 0
                }
            }
        }
    };
});

const ListPagination = ({
    isFetchingData,
    skip,
    take,
    loadedRecordsCount,
    totalRecordsCount,
    showFirstButton = true,
    showLastButton = true,
    showPaginationTotals = true,
    siblingCount,
    handlePaginationChange
}: {
    /** Indicator for if the loading skeletons should show. */
    isFetchingData: boolean;
    /** How many records are skipped in order to show the correct counts. */
    skip: number;
    /** How many records are shown on each page. */
    take: number;
    /** How many records are actually attempting to show on the page. */
    loadedRecordsCount: number;
    /** How many records there are in total. */
    totalRecordsCount: number;
    /** Indicator to show the first page button. */
    showFirstButton?: boolean;
    /** Indicator to show the last page button. */
    showLastButton?: boolean;
    /** Prop to display or hide pagination element. */
    showPaginationTotals?: boolean;
    /** Function to handle the page change. */
    handlePaginationChange: (newSkip: number) => void;
    /** Prop to display sibling pages or not. */
    siblingCount?: number;
}): JSX.Element => {
    if (isFetchingData) {
        return (
            <StyledGrid container className={classes.paginationWrapper}>
                {
                    showPaginationTotals &&
                    <Grid item xs='auto' className={classes.paginationTextWrapper}>
                        <Typography className={classes.paginationText}>
                            <Skeleton className={classes.inlineSkeleton} variant='text' width='10px' />
                            -
                            <Skeleton className={classes.inlineSkeleton} variant='text' width='10px' />
                            of
                            <Skeleton className={classes.inlineSkeleton} variant='text' width='10px' />
                        </Typography>
                    </Grid>
                }
                <Grid item className={`${classes.paginatorWrapper} ${classes.paginatorLoader}`}>
                    <Skeleton className={classes.paginator} variant='text' height='32px' width='100%' />
                </Grid>
            </StyledGrid>
        );
    }

    // zero-based page index from the current skip and take amount
    const pageIndex = skip / take;
    // add 1 to pageIndex to get current page
    const currentPage = pageIndex + 1;
    // add 1 to the zero-base result sequence start
    const paginationSequenceStart = loadedRecordsCount === 0 ? 0 : skip + 1;
    // If the sum of take and skip are less than the total records show that otherwise show the total records.
    const paginationSequenceEnd = take + skip < totalRecordsCount ? take + skip : totalRecordsCount;

    return (
        <StyledGrid container className={classes.paginationWrapper} data-qa='pagination-container'>
            {
                showPaginationTotals &&
                <Grid item xs='auto' className={classes.paginationTextWrapper}>
                    <Typography className={classes.paginationText} data-qa='showingOf-label'>
                        {`${paginationSequenceStart} - ${paginationSequenceEnd} of ${totalRecordsCount}`}
                    </Typography>
                </Grid>
            }
            <Grid item className={classes.paginatorWrapper}>
                <Pagination
                    className={classes.paginator}
                    color='primary'
                    count={Math.ceil(totalRecordsCount / take)}
                    page={currentPage}
                    showFirstButton={showFirstButton}
                    showLastButton={showLastButton}
                    siblingCount={siblingCount}
                    onChange={(event, page: number): void => {
                        handlePaginationChange((page - 1) * take);
                    }}
                    data-qa='paginator'
                />
            </Grid>
        </StyledGrid>
    );
};

export default ListPagination;
