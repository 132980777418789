import React from 'react';
import { PaletteMode } from '@mui/material';
import { styled } from '@mui/material/styles';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { bbox } from '@turf/turf';

import { OrderListData } from '../../interfaces/services/orders';
import { OrderListMapSourceProperties, addOrderListSource, addOrderListClusterSource } from '../../helpers/maps/sources/orderList';
import { ClusterFeatureProperties } from '../../helpers/maps/layers/interfaces';
import { addOrderListLayer, addOrderListClusterLayer } from '../../helpers/maps/layers/orderList';
import { addTrafficLayers } from '../../helpers/maps/layers/traffic';
import {
    toggleLayerVisibility,
    fitMapToBounds,
    trimbleMapsPopup,
    handlePopupMouseEnter,
    handlePopupMouseLeave,
    createDonutChart
} from '../../helpers/maps/mapUtils';
import { MapLayers, MapSources } from '../../helpers/maps/enums';
import MapSpinner from '../loaders/mapSpinner';
import MapControlBar from '../actionBars/mapControlBar';
import MapLegendWrapper from '../mapLegends/mapLegendWrapper';

import '../../assets/CSS/trimbleMaps.css';

const classesPrefix = 'ordersMap';

const classes = {
    map: `${classesPrefix}-map`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.map}`]: {
            height: '100%'
        }
    };
});

interface OrdersMapProps {
    /** Indicator to show loading spinner while data is fetching. */
    isFetchingData: boolean;
    /** List of orders to show on the map. */
    orderList: OrderListData[];
    /** Function to handle the click on a dot from the order list map. */
    handleOrderMarkerClick: (orderMarkerProperties: OrderListMapSourceProperties) => void;
    /** Palette Mode used to determine map style. */
    paletteMode: PaletteMode;
    /** Indicator to trigger a map resize function. */
    resize?: boolean;
}

interface OrdersMapState {
    isWeatherRadarActive: boolean;
    isWeatherRoadSurfaceActive: boolean;
    isTrafficActive: boolean;
    is3dBuildingsLayerDisabled: boolean;
    is3dBuildingsActive: boolean;
    isClusteringActive: boolean;
    mapTileStyle: string;
    mapStyleLoaded: boolean;
    zoomMap: boolean;
}

let map: any = null;
const mapContainer = React.createRef<HTMLDivElement>();

// objects for caching and keeping track of HTML marker objects (for performance)
let markers: any = {};
let markersOnScreen: any = {};

class OrdersMap extends React.Component<OrdersMapProps, OrdersMapState> {

    constructor(props: OrdersMapProps) {
        super(props);

        TrimbleMaps.APIKey = 'A413EAA4767EC44E94A2360AE03B8689';

        this.state = {
            isWeatherRadarActive: false,
            isWeatherRoadSurfaceActive: false,
            isTrafficActive: false,
            is3dBuildingsLayerDisabled: false,
            is3dBuildingsActive: false,
            isClusteringActive: false,
            mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
            mapStyleLoaded: false,
            zoomMap: true
        };
    }

    componentDidMount(): void {
        const latitude = 39.828347;
        const longitude = -98.579488;

        map = new TrimbleMaps.Map({
            container: mapContainer.current, // Ties to an HTML DOM element
            center: [longitude, latitude], // Sets initial center of map
            style: this.state.mapTileStyle, // Sets initial map style
            minZoom: 1.55, // To stop the zoom from showing multiple worlds
            zoom: 4, // Sets initial zoom level
            attributionControl: false // Removes default attribution control, so we can customize it below
        }).addControl(
            new TrimbleMaps.AttributionControl({
                compact: true // Minimizes the attribution to a compact version
            })
        ).addControl(
            // Adds the zoom in/out and compass buttons
            new TrimbleMaps.NavigationControl({
                showCompass: true,
                showZoom: true
            }),
            'top-right'
        ).addControl(
            new TrimbleMaps.FullscreenControl()
        );

        map.setRegion(TrimbleMaps.Common.Region.WW);

        // README: The event `style.load` is no longer a public event of mapbox. We will need to use some combination of using `styledata` and `map.isStyleLoaded()` at some point.
        // However, these do not work the same and there are several open issues with mapbox that have yet to be resolved, such as this one:
        // https://github.com/mapbox/mapbox-gl-js/issues/9779
        // Once a map style is loaded, add any layers that were on back to the map
        map.on('style.load', (): void => {
            // The style.load event can fire after the component has been unmounted.
            if (map === null) {
                return;
            }

            this.removeOrderEventHandlers();
            this.addOrderMarkersToMap();
            addTrafficLayers(map, TrimbleMaps.APIKey, this.state.isTrafficActive);
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);

            // HACK: trigger a resize function to make map take up full width
            map.resize();
            this.setState({ mapStyleLoaded: true });
        });

        // When one of the map sources loads or changes, this will remove the markers from the map and reset the data
        map.on('sourcedata', () => {
            Object.keys(markersOnScreen).forEach((id: string) => {
                markersOnScreen[id].remove();
            });
            markers = {};
            markersOnScreen = {};
        });

        // On every render of the map, update the markers (if needed)
        map.on('render', () => {
            // if clustering is off, we are fetching data, the map source isn't loaded, or the map style isn't loaded yet, skip this render
            if (!this.state.isClusteringActive || this.props.isFetchingData || !map.isSourceLoaded(MapSources.orderListClusterSource) || this.state.mapStyleLoaded === false) {
                return;
            }

            this.updateMarkers();
        });
    }

    componentDidUpdate(prevProps: OrdersMapProps, prevState: OrdersMapState): void {
        // if the map is null, hold any CDU updates
        if (map === null) {
            return;
        }

        // if the paletteMode prop has changed, set the map style in state accordingly to trigger a re-render and the next CDU will update the map style to match.
        if (prevProps.paletteMode !== this.props.paletteMode) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
                mapStyleLoaded: false,
                zoomMap: false
            });
        }

        // if the map style state var has changed and the map style hasn't been loaded, set the style on the map itself
        if (prevState.mapTileStyle !== this.state.mapTileStyle && this.state.mapStyleLoaded === false) {
            map.setStyle(this.state.mapTileStyle);
        }

        if (this.state.mapStyleLoaded === false) {
            return;
        }

        // remove the map makers from the DOM when doing clustering and being in the loading state
        if (this.state.isClusteringActive && map.isSourceLoaded(MapSources.orderListClusterSource) && prevProps.isFetchingData !== this.props.isFetchingData) {
            Object.keys(markersOnScreen).forEach((id: string) => {
                const element = document.getElementById(id);
                if (element?.parentNode) {
                    element.parentNode.removeChild(element);
                }
            });
        }

        // toggle weather radar
        if (prevState.isWeatherRadarActive !== this.state.isWeatherRadarActive) {
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
        }

        // toggle weather road surface and traffic
        if (prevState.isWeatherRoadSurfaceActive !== this.state.isWeatherRoadSurfaceActive || prevState.isTrafficActive !== this.state.isTrafficActive) {
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            toggleLayerVisibility(map, MapLayers.europeTraffic, this.state.isTrafficActive);
            toggleLayerVisibility(map, MapLayers.northAmericaTraffic, this.state.isTrafficActive);
        }

        // toggle 3D buildings
        if (prevState.is3dBuildingsActive !== this.state.is3dBuildingsActive) {
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);
        }

        // toggle the order layers based on if clustering is on or off
        if (prevState.isClusteringActive !== this.state.isClusteringActive) {
            toggleLayerVisibility(map, MapLayers.orderListLayer, !this.state.isClusteringActive);
            toggleLayerVisibility(map, MapLayers.orderListClusterLayer, this.state.isClusteringActive);
        }

        // add order markers to map if props have updated
        if (prevProps.orderList !== this.props.orderList) {
            this.removeOrderEventHandlers();
            this.addOrderMarkersToMap();
        }

        // if resize prop is passed and is true force map to resize.
        if (this.props.resize === true) {
            map.resize();
        }
    }

    componentWillUnmount(): void {
        this.removeOrderEventHandlers();
    }

    handleWeatherRadarClick = (): void => {
        this.setState((state) => {
            return {
                isWeatherRadarActive: !state.isWeatherRadarActive
            };
        });
    };

    handleWeatherRoadSurfaceClick = (): void => {
        this.setState((state) => {
            return {
                isWeatherRoadSurfaceActive: !state.isWeatherRoadSurfaceActive,
                isTrafficActive: !state.isWeatherRoadSurfaceActive === true ? false : state.isTrafficActive
            };
        });
    };

    handleTrafficClick = (): void => {
        this.setState((state) => {
            return {
                isTrafficActive: !state.isTrafficActive,
                isWeatherRoadSurfaceActive: !state.isTrafficActive === true ? false : state.isWeatherRoadSurfaceActive
            };
        });
    };

    handle3dBuildingClick = (): void => {
        this.setState((state) => {
            return {
                is3dBuildingsActive: !state.is3dBuildingsActive
            };
        });
    };

    handleClusterClick = (): void => {
        this.setState((state) => {
            return {
                isClusteringActive: !state.isClusteringActive
            };
        });
    };

    handleMapStyleMenuItemClick = (mapStyle: string): void => {
        let is3dBuildingsLayerDisabled = false;
        let { is3dBuildingsActive } = this.state;
        if (mapStyle === TrimbleMaps.Common.Style.SATELLITE) {
            is3dBuildingsLayerDisabled = true; // disable 3d buildings button when in satellite mode
            is3dBuildingsActive = false; // turn 3d building layer off when in satellite mode
        }

        this.setState({
            is3dBuildingsLayerDisabled,
            is3dBuildingsActive,
            mapTileStyle: mapStyle,
            mapStyleLoaded: false,
            zoomMap: false
        });
    };

    handleOrderMarkerClick = (e: any): void => {
        // pull custom properties off the geoJson obj to send to parent
        const { properties }: { properties: OrderListMapSourceProperties; } = e.features[0];
        // handle action from parent component
        if (this.props.handleOrderMarkerClick) {
            this.props.handleOrderMarkerClick(properties);
        }
    };

    handleOrderMarkerClusterClick = (clusterId: number, coordinates: number[]): void => {
        map.getSource(MapSources.orderListClusterSource).getClusterExpansionZoom(
            clusterId,
            (err: any, zoom: number) => {
                if (err) { return; }

                map.easeTo({
                    center: coordinates,
                    zoom
                });
            }
        );
    };

    updateMarkers = (): void => {
        const newMarkers: any = {};
        const features = map.querySourceFeatures(MapSources.orderListClusterSource);

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet), and add it to the map if it's not there already
        features.forEach((feature: any) => {
            const coords = feature.geometry.coordinates;
            const { properties }: { properties: ClusterFeatureProperties; } = feature;
            if (properties.cluster) {
                const id = properties.cluster_id;

                if (!markers[id]) {
                    const el = createDonutChart(properties);
                    markers[id] = new TrimbleMaps.Marker({
                        element: el
                    }).setLngLat(coords);

                    // when the DOM element is clicked, we will zoom in to show the next set of clusters
                    el?.addEventListener('click', () => {
                        this.handleOrderMarkerClusterClick(id, coords);
                    });
                }
                newMarkers[id] = markers[id];

                if (!markersOnScreen[id]) {
                    markers[id].addTo(map);
                }
            }
        });

        // for every marker we've added previously, remove those that are no longer visible
        Object.keys(markersOnScreen).forEach((id: string) => {
            if (!newMarkers[id]) {
                markersOnScreen[id].remove();
            }
        });

        markersOnScreen = newMarkers;
    };

    addOrderMarkersToMap = (): void => {
        const orderListSource = addOrderListSource(map, this.props.orderList);
        addOrderListClusterSource(map, this.props.orderList);

        addOrderListLayer(map, !this.state.isClusteringActive);
        addOrderListClusterLayer(map, this.state.isClusteringActive);

        map.on('click', MapLayers.orderListLayer, this.handleOrderMarkerClick);
        map.on('mouseenter', MapLayers.orderListLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.orderListLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        map.on('click', MapLayers.orderListClusterLayer, this.handleOrderMarkerClick);
        map.on('mouseenter', MapLayers.orderListClusterLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.orderListClusterLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        if (orderListSource.data.features.length > 0) {
            const boundingBox = bbox(orderListSource.data);
            fitMapToBounds({
                map,
                bounds: boundingBox,
                zoomMap: this.state.zoomMap,
                maxZoom: 12
            });
        }
    };

    removeOrderEventHandlers = (): void => {
        if (map.getLayer(MapLayers.orderListLayer) !== undefined) {
            map.off('click', MapLayers.orderListLayer, this.handleOrderMarkerClick);
            map.off('mouseenter', MapLayers.orderListLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.orderListLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.orderListClusterLayer) !== undefined) {
            map.off('click', MapLayers.orderListClusterLayer, this.handleOrderMarkerClick);
            map.off('mouseenter', MapLayers.orderListClusterLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.orderListClusterLayer, handlePopupMouseLeave);
        }
    };

    render(): JSX.Element {
        return (
            <StyledDiv
                id='trimble-map-container'
                ref={mapContainer}
                className={classes.map}
                data-qa='orderMap-container'
            >
                {
                    this.props.isFetchingData &&
                    <MapSpinner />
                }

                <MapControlBar
                    isWeatherRadarActive={this.state.isWeatherRadarActive}
                    handleWeatherRadarClick={(): void => { this.handleWeatherRadarClick(); }}
                    isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                    handleWeatherRoadSurfaceClick={(): void => { this.handleWeatherRoadSurfaceClick(); }}
                    isTrafficActive={this.state.isTrafficActive}
                    handleTrafficClick={(): void => { this.handleTrafficClick(); }}
                    is3dBuildingsLayerDisabled={this.state.is3dBuildingsLayerDisabled}
                    is3dBuildingsActive={this.state.is3dBuildingsActive}
                    handle3dBuildingClick={(): void => { this.handle3dBuildingClick(); }}
                    isClusteringActive={this.state.isClusteringActive}
                    handleClusterClick={(): void => { this.handleClusterClick(); }}
                    mapTileStyle={this.state.mapTileStyle}
                    handleMapStyleMenuItemClick={(mapStyle: string): void => { this.handleMapStyleMenuItemClick(mapStyle); }}
                    showMapLegend={true}
                />

                <MapLegendWrapper
                    isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                    isMapLegendEnabled={true}
                    mapLegendType='orders'
                />
            </StyledDiv>
        );
    }

}

export default OrdersMap;
