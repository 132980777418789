import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiBarcodeScan } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchShipmentDetails } from '../../redux/shipmentDetails';
import { Order } from '../../interfaces/services/shipmentDetails';
import { ApiStatus } from '../../helpers/enums';
import CommonDialog from './common/commonDialog';
import LabelValuePair from '../labels/labelValuePair';
import LabelValuePairLoading from '../loaders/labels/labelValuePairLoading';
import DocumentDownloadLink from '../links/documentDownloadLink';

const classesPrefix = 'orderExceptionsDialog';

const classes = {
    exceptionContainer: `${classesPrefix}-exceptionContainer`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`& .${classes.exceptionContainer}`]: {
            padding: '8px 0',
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-of-type': {
                borderBottom: 0,
                paddingBottom: 0
            }
        }
    };
});

const OrderExceptionsDialog = ({
    shipmentUniqueName,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });

    const isFetchingShipment = shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading;

    useEffect((): void => {
        // only fetch if we don't already have the details in redux
        if (shipmentUniqueName !== shipment.shipmentUniqueName) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipment.shipmentUniqueName, dispatch]);

    useEffect((): void => {
        // only fetch if we refreshed and cleared our shipment status back to idle
        if (shipmentStatus === ApiStatus.Idle) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipmentStatus, dispatch]);

    // Don't allow the dialog to close if the API's are still running
    const handleClose = (): void => {
        if (!isFetchingShipment) {
            closeDialog();
        }
    };

    const renderStopInformation = (order: Order): JSX.Element => {
        const deliveryStop = shipment.stops.find((stop) => {
            return stop.stopReference === order.deliveryStopReference;
        });

        // Only display delivery (non-origin) stop information
        if (deliveryStop !== undefined && deliveryStop.stopSequence > 1) {
            return (
                <LabelValuePair
                    label='Stop Number'
                    value={deliveryStop.stopSequence - 1}
                    data-qa='stopNumber'
                />
            );
        }

        return <Fragment />;
    };

    const renderDialogContent = (): JSX.Element => {
        if (isFetchingShipment) {
            return (
                <StyledGrid container>
                    <Grid item xs={12} className={classes.exceptionContainer}>
                        <LabelValuePairLoading label='Order Number' width='100px' />
                        <LabelValuePairLoading label='Stop Number' width='100px' />
                        <LabelValuePairLoading label='Exception Type' width='150px' />
                        <LabelValuePairLoading label='Description' width='150px' />
                        <LabelValuePairLoading label='Photos' width='200px' isStacked />
                    </Grid>
                </StyledGrid>
            );
        }

        return (
            <StyledGrid container>
                {
                    shipment.orders.map((order) => {
                        if (order.exception) {
                            return (
                                <Grid key={order.orderNumber} item xs={12} className={classes.exceptionContainer}>
                                    <LabelValuePair
                                        label='Order Number'
                                        value={order.orderNumber}
                                        data-qa='orderNumber'
                                    />
                                    {renderStopInformation(order)}
                                    <LabelValuePair
                                        label='Exception Type'
                                        value={order.exception.exceptionTypeDescription}
                                        data-qa='exceptionType'
                                    />
                                    <LabelValuePair
                                        label='Description'
                                        value={order.exception.description}
                                        data-qa='description'
                                    />
                                    <LabelValuePair
                                        label='Photos'
                                        value={
                                            order.exception.exceptionFiles.map((document) => {
                                                // only make the full image downloadable
                                                if (document.fullImageId === 0) {
                                                    return (
                                                        <DocumentDownloadLink
                                                            key={document.documentId}
                                                            documentName={document.documentDescription}
                                                            documentID={document.documentId}
                                                            shipmentUniqueName={shipmentUniqueName}
                                                        />
                                                    );
                                                }
                                                return <Fragment key={document.documentId} />;
                                            })
                                        }
                                        isStacked={true}
                                        data-qa='photos'
                                    />
                                </Grid>
                            );
                        }
                        return <Fragment key={order.orderNumber} />;
                    })
                }
            </StyledGrid>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            headerIcon={mdiBarcodeScan}
            headerText='Order Exceptions'
            content={renderDialogContent()}
            actions={(
                <Button
                    disabled={isFetchingShipment}
                    onClick={handleClose}
                    data-qa='close-button'
                >
                    Close
                </Button>
            )}
        />
    );
};

export default OrderExceptionsDialog;
