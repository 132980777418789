import { AllowedFilterPropertyName, AllowedNotificationFilterPropertyName } from '../helpers/enums';

export const INITIALIZE_FILTERS = 'INITIALIZE_FILTERS';
export const UPDATE_FILTER = 'UPDATE_FILTER';

// Mapped type with enum values as the key
export type FilterState = {
    [K in AllowedFilterPropertyName]: string[];
}

export type NotificationFilterState = {
    [K in AllowedNotificationFilterPropertyName]: string[];
}

/** Interface used for a filter item. */
export interface FilterItem {
    /** Label to display */
    label: string;
    /** Value of the filter item */
    filterValue: string;
}

export const initialState: FilterState = {
    [AllowedFilterPropertyName.OriginScheduleStartDateTime]: [],
    [AllowedFilterPropertyName.OriginScheduleEndDateTime]: [],
    [AllowedFilterPropertyName.OriginAppointmentStartDateTime]: [],
    [AllowedFilterPropertyName.OriginAppointmentEndDateTime]: [],
    [AllowedFilterPropertyName.DestinationScheduleStartDateTime]: [],
    [AllowedFilterPropertyName.DestinationScheduleEndDateTime]: [],
    [AllowedFilterPropertyName.DestinationAppointmentStartDateTime]: [],
    [AllowedFilterPropertyName.DestinationAppointmentEndDateTime]: [],
    [AllowedFilterPropertyName.Customers]: [],
    [AllowedFilterPropertyName.Partners]: [],
    [AllowedFilterPropertyName.Products]: [],
    [AllowedFilterPropertyName.Brand]: [],
    [AllowedFilterPropertyName.Sku]: [],
    [AllowedFilterPropertyName.Origin]: [],
    [AllowedFilterPropertyName.Destination]: [],
    [AllowedFilterPropertyName.InboundLocation]: [],
    [AllowedFilterPropertyName.OutboundLocation]: [],
    [AllowedFilterPropertyName.OriginState]: [],
    [AllowedFilterPropertyName.DestinationState]: [],
    [AllowedFilterPropertyName.OriginCountryCode]: [],
    [AllowedFilterPropertyName.DestinationCountryCode]: [],
    [AllowedFilterPropertyName.DeliveryStatus]: [],
    [AllowedFilterPropertyName.ShipmentStatus]: [],
    [AllowedFilterPropertyName.OrderExceptions]: [],
    [AllowedFilterPropertyName.ModeType]: [],
    [AllowedFilterPropertyName.EquipmentType]: [],
    [AllowedFilterPropertyName.WeatherSeverity]: [],
    [AllowedFilterPropertyName.IsPriorityShipment]: [],
    [AllowedFilterPropertyName.CurrentMilestoneType]: [],
    // calendar initial state
    [AllowedFilterPropertyName.AppointmentDateRange]: [],
    [AllowedFilterPropertyName.OriginAppointmentDateRange]: [],
    [AllowedFilterPropertyName.OriginScheduleDateRange]: [],
    [AllowedFilterPropertyName.ScheduleDateRange]: [],
    [AllowedFilterPropertyName.DestinationAppointmentDateRange]: [],
    [AllowedFilterPropertyName.DestinationScheduleDateRange]: [],
    // orders initial state
    [AllowedFilterPropertyName.PickupScheduleStartDateTime]: [],
    [AllowedFilterPropertyName.PickupScheduleEndDateTime]: [],
    [AllowedFilterPropertyName.PickupAppointmentStartDateTime]: [],
    [AllowedFilterPropertyName.PickupAppointmentEndDateTime]: [],
    [AllowedFilterPropertyName.DeliveryScheduleStartDateTime]: [],
    [AllowedFilterPropertyName.DeliveryScheduleEndDateTime]: [],
    [AllowedFilterPropertyName.DeliveryAppointmentStartDateTime]: [],
    [AllowedFilterPropertyName.DeliveryAppointmentEndDateTime]: [],
    [AllowedFilterPropertyName.CustomerName]: [],
    [AllowedFilterPropertyName.CarrierName]: [],
    [AllowedFilterPropertyName.OrderPickupStatus]: [],
    [AllowedFilterPropertyName.OrderDeliveryStatus]: [],
    [AllowedFilterPropertyName.PickupCity]: [],
    [AllowedFilterPropertyName.DeliveryCity]: [],
    [AllowedFilterPropertyName.PickupState]: [],
    [AllowedFilterPropertyName.DeliveryState]: [],
    [AllowedFilterPropertyName.PickupLocationCode]: [],
    [AllowedFilterPropertyName.DeliveryLocationCode]: []
};

export const initialNotificationState: NotificationFilterState = {
    [AllowedNotificationFilterPropertyName.Customers]: [],
    [AllowedNotificationFilterPropertyName.Partners]: [],
    [AllowedNotificationFilterPropertyName.Products]: [],
    [AllowedNotificationFilterPropertyName.Brand]: [],
    [AllowedNotificationFilterPropertyName.Sku]: [],
    [AllowedNotificationFilterPropertyName.Origin]: [],
    [AllowedNotificationFilterPropertyName.Destination]: [],
    [AllowedNotificationFilterPropertyName.InboundLocation]: [],
    [AllowedNotificationFilterPropertyName.OutboundLocation]: [],
    [AllowedNotificationFilterPropertyName.DestinationState]: [],
    [AllowedNotificationFilterPropertyName.OriginState]: [],
    [AllowedNotificationFilterPropertyName.DeliveryStatus]: [],
    [AllowedNotificationFilterPropertyName.ShipmentStatus]: [],
    [AllowedNotificationFilterPropertyName.OrderExceptions]: [],
    [AllowedNotificationFilterPropertyName.ModeType]: [],
    [AllowedNotificationFilterPropertyName.EquipmentType]: [],
    [AllowedNotificationFilterPropertyName.HotShipments]: []
};
