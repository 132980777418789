import React, { Fragment, useState } from 'react';
import {
    Typography,
    Link,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { formatFullAddress, formatCityRegionPostalCountry } from '../../helpers/addressUtils';
import { ModeType } from '../../helpers/enums';
import LocationDetailsDialog from '../dialogs/locationDetailsDialog';
import LabelValuePair from './labelValuePair';

const classesPrefix = 'stopAddress';

const classes = {
    buttonRoot: `${classesPrefix}-buttonRoot`,
    address: `${classesPrefix}-address`,
    unavailableText: `${classesPrefix}-unavailableText`
};

const StyledButton = styled(Button)(({ theme }) => {
    return {
        [`&.${classes.buttonRoot}`]: {
            padding: 0,
            textTransform: 'none',
            minWidth: 'auto',
            fontWeight: 400,
            lineHeight: 1.43,
            color: theme.appColors.action,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        }
    };
}) as typeof Button;

const StyledAddressTypography = styled(Typography)(({ theme }) => {
    return {
        [`&.${classes.address}`]: {
            color: theme.palette.text.secondary,
            fontSize: '14px',
            fontStyle: 'normal'
        }
    };
}) as typeof Typography;

const StyledErrorTypography = styled(Typography)(({ theme }) => {
    return {
        [`&.${classes.unavailableText}`]: {
            color: theme.palette.text.secondary,
            fontSize: '14px'
        }
    };
});

const StopAddress = ({
    description,
    location,
    name,
    street1,
    street2,
    city,
    region,
    postal,
    country,
    modeType,
    singleLineAddress = false
}: {
    /** The description for the stop */
    description: string | null;
    /** The location name for the stop */
    location: string | null;
    /** The name for the stop */
    name: string | null;
    /** The street1 for the stop */
    street1: string | null;
    /** The street2 for the stop */
    street2: string | null;
    /** The city for the stop */
    city: string | null;
    /** The region or state for the stop */
    region: string | null;
    /** The postal code for the stop */
    postal: string | null;
    /** The country code for the stop */
    country: string | null;
    /** The mode type for the shipment. */
    modeType: ModeType;
    /** Indicator if the address should show on 1 line versus 2 */
    singleLineAddress?: boolean;
}): JSX.Element => {
    const [locationDialogIsOpen, setLocationDialogIsOpen] = useState(false);

    if (description || location || name || street1 || street2 || city || region || postal || country) {
        return (
            <Fragment>
                {
                    description &&
                    <LabelValuePair label={location ? 'Location Name' : 'Stop Description'} value={description} data-qa='stopLocationName' />
                }
                {
                    location &&
                    <LabelValuePair
                        label='Location Code'
                        data-qa='stopLocationCode'
                        value={
                            <StyledButton
                                classes={{
                                    root: classes.buttonRoot
                                }}
                                component={Link}
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                onClick={(): void => {
                                    setLocationDialogIsOpen(true);
                                }}
                                data-qa='stopLocationCode-action'
                            >
                                {location}
                            </StyledButton>
                        }
                    />
                }
                {
                    name &&
                    <LabelValuePair label='Stop Name' value={name} data-qa='stopName' />
                }
                {
                    singleLineAddress ?
                        (
                            <StyledAddressTypography className={classes.address} component='address'>
                                {
                                    formatFullAddress({
                                        street1, street2, city, region, postal, country
                                    })
                                }
                            </StyledAddressTypography>
                        ) : (
                            <Fragment>
                                <StyledAddressTypography className={classes.address} component='address' data-qa='street'>
                                    {`${street1 || ''} ${street2 || ''}`}
                                </StyledAddressTypography>
                                <StyledAddressTypography className={classes.address} component='address' data-qa='cityRegionPostalCountry'>
                                    {
                                        formatCityRegionPostalCountry({
                                            city, region, postal, country
                                        })
                                    }
                                </StyledAddressTypography>
                            </Fragment>
                        )
                }
                {
                    locationDialogIsOpen && location &&
                    <LocationDetailsDialog
                        locationCode={location}
                        modeType={modeType}
                        isOpen={locationDialogIsOpen}
                        closeDialog={(): void => {
                            setLocationDialogIsOpen(false);
                        }}
                    />
                }
            </Fragment>
        );
    }

    return <StyledErrorTypography className={classes.unavailableText} data-qa='addressUnavailable'>Unavailable</StyledErrorTypography>;
};

export default StopAddress;
