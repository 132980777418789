export default {
    dashboardsApi: {
        reportsList: '/api/v1/PowerBi/workspaces',
        reportToken: '/api/v1/PowerBi/workspaces'
    },
    dataViewApi: {
        viewsList: '/v2/customViews',
        viewDetails: '/v2/customViews',
        addView: '/v2/customViews',
        editView: '/v2/customViews',
        deleteView: '/v2/customViews',
        notifications: '/v1/customNotifications',
        notificationDetails: '/v1/customNotifications',
        deleteNotification: '/v1/customNotifications',
        updateNotification: '/v1/customNotifications'
    },
    freightPortalApi: {
        shipmentStatusUpdate: '/atlas/frexapi/shipment/status',
        stopStatusUpdate: '/atlas/frexapi/shipment/stopstatus',
        mobileTrackingUpdate: '/atlas/frexapi/shipment/track/',
        addDocument: '/atlas/frexapi/shipment/document/upload/'
    },
    locationManagementApi: {
        fixedLocation: '/v1/fixedlocation'
    },
    masterDataApi: {
        filters: '/api/v1/filters/',
        orderFilters: '/api/v1/filters/Orders/',
        orderExceptions: '/api/v1/filters/OrderException',
        hydrateFilterCache: '/api/v1/Filters/CreateCacheEntry',
        equipmentType: '/api/v1/filters/EquipmentTypeFreightProvider'
    },
    organizationApi: {
        organizationInformation: '/v1/organizations/me',
        organizationPreferences: '/v2/organizations/me/preferences',
        updateLogo: '/v1/organizations/me/preferences/logo',
        userInformation: '/v2/users/me/apps',
        userSettings: '/v1/users/settings',
        driverDetails: '/v1/drivers',
        orderExceptionList: '/v1/organizations/me/exceptionTypes',
        analyticsWorkspaces: '/v1/organizations/me/PowerBIWorkspaces',
        contactInformation: '/v1/contactInformation'
    },
    shipmentApi: {
        headers: '/v2/shipments/headers',
        reopen: '/v2/shipments',
        details: '/v1/shipments',
        dimensionSummary: '/v1/shipments/dimensionSummary',
        orderDimensionSummary: '/v1/orders/dimensionSummary',
        tripDetails: '/v1/trips',
        tripHistory: '/v1/trips',
        temperatures: '/v2/shipments',
        shipmentMovement: '/v1/shipmentMovement',
        weather: '/v2/shipments',
        history: '/v1/shipments',
        shipmentNotes: '/v1/shipments',
        shipmentStopNotes: '/v1/shipments/stops',
        priority: '/v1/shipments',
        tokenExchange: '/v1/tokenExchange',
        orders: '/v1/orders',
        orderDetails: '/v1/orders'
    },
    serviceLocator: 'https://services.10-4.com/ResourceUrls',
    trimbleMaps: {
        polygonsCounty: 'https://pcmiler.alk.com/apis/REST/v1.0/service.svc/polygons/county'
    },
    urlShortenerApi: {
        shortenUrl: '/api/UrlShortener'
    }
};
