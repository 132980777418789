import React, { Fragment } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ApplicationResource } from '../../interfaces/services/organization';
import Hidden from '../layouts/hidden';
import PageViewButton from '../buttons/pageViewButton';

const classesPrefix = 'pageViewButtons';

const classes = {
    navigationButtonWrapper: `${classesPrefix}-navigationButtonWrapper`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`&.${classes.navigationButtonWrapper}`]: {
            flexGrow: 1
        }
    };
});

const PageViewButtons = ({
    pageViewList
}: {
    pageViewList: ApplicationResource[];
}): JSX.Element => {

    return (
        <Hidden breakpoint='lg' direction='down'>
            <StyledGrid item md='auto' className={classes.navigationButtonWrapper} data-qa='navigation-actions-container'>
                <Grid container>
                    {
                        pageViewList.length > 1 ?
                            pageViewList.map((app): JSX.Element => {
                                return (
                                    <Grid item key={app.resourceKey}>
                                        <PageViewButton app={app} />
                                    </Grid>
                                );
                            })
                            : <Fragment />
                    }
                </Grid>
            </StyledGrid>
        </Hidden>
    );
};

export default PageViewButtons;
