import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Autocomplete,
    Button,
    CircularProgress,
    TextField,
    Typography
} from '@mui/material';
import { mdiTruck } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { updatePageReset, updateShipmentStatus } from '../../redux/dialogUpdates';
import { ApiStatus, FreightHaulerIdentifierTypeName, ShipmentStatus } from '../../helpers/enums';
import { shipmentStatusOptions } from '../../helpers/hardcodedOptionLists';
import CommonDialog from './common/commonDialog';

const ShipmentStatusDialog = ({
    shipmentUniqueName,
    freightHaulerIdentifierTypeName,
    freightHaulerIdentifierName,
    shipmentStatus,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    freightHaulerIdentifierTypeName: FreightHaulerIdentifierTypeName | null;
    freightHaulerIdentifierName: string | null;
    shipmentStatus: ShipmentStatus;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [draftShipmentStatus, setDraftShipmentStatus] = useState(shipmentStatus);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        dispatch(updateShipmentStatus({
            shipmentUniqueName,
            freightHaulerIdentifierTypeName,
            freightHaulerIdentifierName,
            shipmentStatus: draftShipmentStatus
        }));
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Fragment>
                <Autocomplete
                    disableClearable={true}
                    options={shipmentStatusOptions}
                    getOptionLabel={(option): string => { return option.label; }}
                    onChange={(event, newValue): void => {
                        setDraftShipmentStatus(newValue.filterValue);
                    }}
                    value={
                        shipmentStatusOptions.find((option) => {
                            return option.filterValue === draftShipmentStatus;
                        })
                    }
                    renderInput={(params): JSX.Element => {
                        return (
                            <TextField
                                {...params}
                                variant='outlined'
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                    'data-qa': 'shipmentStatus-input'
                                }}
                            />
                        );
                    }}
                    data-qa='shipmentStatus-select'
                />
                {
                    !freightHaulerIdentifierTypeName &&
                    <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                }
                {
                    errorMessage &&
                    <Typography variant='caption' color='error'>{errorMessage}</Typography>
                }
            </Fragment>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            maxWidth='xs'
            fullWidth={true}
            headerIcon={mdiTruck}
            headerText='Shipment Status'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        onClick={handleClose}
                        data-qa='close-button'
                    >
                        Close
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={shipmentStatus === draftShipmentStatus || status === ApiStatus.Loading || !freightHaulerIdentifierTypeName}
                        onClick={handleSave}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default ShipmentStatusDialog;
