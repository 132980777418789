import React from 'react';
import { Grid, GridProps, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyleProps extends GridProps {
    fontSize?: string;
    isStacked?: boolean;
    /** component prop has to be manually added to fix a TS error that `styled` throws */
    component?: React.ElementType;
}

const classesPrefix = 'labelValuePairLoading';

const classes = {
    labelWrapper: `${classesPrefix}-labelWrapper`,
    text: `${classesPrefix}-text`,
    inlineSkeleton: `${classesPrefix}-inlineSkeleton`
};

const StyledGrid = styled(
    Grid,
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'fontSize' && prop !== 'isStacked'; }
    }
)<StyleProps>(({ fontSize, isStacked }) => {
    return {
        [`&.${classes.labelWrapper}`]: {
            margin: 0
        },
        [`& .${classes.text}`]: {
            marginRight: '4px',
            fontWeight: 600,
            fontSize,
            ...isStacked && {
                width: '100%'
            }
        },
        [`& .${classes.inlineSkeleton}`]: {
            display: 'inline-block',
            marginRight: '4px',
            fontSize
        }
    };
});

const LabelValuePairLoading = ({
    label,
    width,
    fontSize = '14px',
    isStacked = false
}: {
    /** The display label describing the data. */
    label: string;
    /** Passed to the skeleton loader to change the length. */
    width: string;
    /** The size of the font.
    * @default 14px
    */
    fontSize?: string;
    /**
    * If true, the label will be full width with the value on the next line.
    * @default false
    */
    isStacked?: boolean;
}): JSX.Element => {
    return (
        <StyledGrid container component='dl' fontSize={fontSize} isStacked={isStacked} className={classes.labelWrapper}>
            <Grid item component='dt' className={classes.text}>{label}</Grid>
            <Grid item component='dd'>
                <Skeleton className={classes.inlineSkeleton} variant='text' width={width} />
            </Grid>
        </StyledGrid>
    );
};

export default LabelValuePairLoading;
