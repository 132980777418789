import React, { useState, Fragment } from 'react';
import {
    Grid,
    Typography,
    Tooltip,
    TooltipProps
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ShipmentListData } from '../../interfaces/services/shipment';
import { formatCityRegionPostalCountry } from '../../helpers/addressUtils';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { determineDisplayDates, isShipmentLtl } from '../../helpers/shipmentUtils';
import { StopType } from '../../helpers/enums';
import Hidden from '../layouts/hidden';
import LabelValuePair from '../labels/labelValuePair';
import StatusActionBar from '../actionBars/statusActionBar';
import DeliveryStatusIndicator from '../labels/deliveryStatusIndicator';
import ShipmentListActions from '../actionBars/shipmentListActions';
import ShipmentDetailsLabelLink from '../links/shipmentDetailsLabelLink';
import TripDetailsLabelLink from '../links/tripDetailsLabelLink';
import StopAddress from '../labels/stopAddress';
import StopContactInfo from '../buttons/stopContactInfo';
import NotesDialog from '../dialogs/notesDialog';
import DocumentsDialog from '../dialogs/documentsDialog';
import WeatherAlertsDialog from '../dialogs/weatherAlertsDialog';
import TemperatureHistoryDialog from '../dialogs/temperatureHistoryDialog';
import ShipmentPriorityDialog from '../dialogs/shipmentPriorityDialog';
import OrderExceptionsDialog from '../dialogs/orderExceptionsDialog';
import DeliveryStatusTooltip from '../tooltips/deliveryStatusTooltip';

const classesPrefix = 'shipmentCard';

const classes = {
    cardContainer: `${classesPrefix}-cardContainer`,
    cardWrapper: `${classesPrefix}-cardWrapper`,
    linksWrapper: `${classesPrefix}-linksWrapper`,
    statusLabel: `${classesPrefix}-statusLabel`,
    addressContainer: `${classesPrefix}-addressContainer`,
    stopTitle: `${classesPrefix}-stopTitle`,
    address: `${classesPrefix}-address`,
    unavailableText: `${classesPrefix}-unavailableText`,
    stopTypeContainer: `${classesPrefix}-stopTypeContainer`,
    activeStopTooltip: `${classesPrefix}-activeStopTooltip`,
    activeStopLabel: `${classesPrefix}-activeStopLabel`,
    activeStopAddress: `${classesPrefix}-activeStopAddress`,
    activeStopUnavailableText: `${classesPrefix}-activeStopUnavailableText`,
    activeStopDisplayDate: `${classesPrefix}-activeStopDisplayDate`,
    locationName: `${classesPrefix}-locationName`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.cardContainer}`]: {
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:first-of-type': {
                borderTop: 0
            },
            '&:last-of-type': {
                borderBottom: 0
            }
        },
        [`& .${classes.cardWrapper}`]: {
            padding: '8px 16px'
        },
        [`& .${classes.linksWrapper}`]: {
            flexGrow: 1
        },
        [`& .${classes.activeStopTooltip}`]: {
            display: 'inline-block',
            '&:hover': {
                cursor: 'pointer'
            }
        },
        [`& .${classes.statusLabel}`]: {
            margin: '8px 0',
            fontSize: '14px',
            fontWeight: 600
        },
        [`& .${classes.addressContainer}`]: {
            marginBottom: '4px'
        },
        [`& .${classes.stopTitle}`]: {
            fontWeight: 600,
            fontSize: '12px'
        },
        [`& .${classes.address}`]: {
            color: theme.palette.text.secondary,
            fontSize: '12px',
            fontStyle: 'normal'
        },
        [`& .${classes.unavailableText}`]: {
            color: theme.palette.text.secondary,
            fontSize: '12px'
        },
        [`& .${classes.stopTypeContainer}`]: {
            display: 'flex',
            alignItems: 'center'
        }
    };
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => {
    return <Tooltip {...props} classes={{ popper: className }} />;
})(() => {
    return {
        [`& .${classes.activeStopLabel}`]: {
            fontSize: '12px',
            fontWeight: 600
        },
        [`& .${classes.activeStopAddress}`]: {
            fontSize: '12px'
        },
        [`& .${classes.activeStopUnavailableText}`]: {
            fontSize: '12px'
        },
        [`& .${classes.activeStopDisplayDate}`]: {
            paddingTop: '8px',
            fontSize: '12px'
        },
        [`& .${classes.locationName}`]: {
            fontSize: '12px',
            fontWeight: 400,
            fontStyle: 'italic'
        }
    };
});

const ShipmentCard = ({
    shipment
}: {
    /** The shipment details to display in the card. */
    shipment: ShipmentListData;
}): JSX.Element => {
    const theme = useTheme();

    const [notesDialogIsOpen, setNotesDialogIsOpen] = useState(false);
    const [documentsDialogIsOpen, setDocumentsDialogIsOpen] = useState(false);
    const [weatherAlertsDialogIsOpen, setWeatherAlertsDialogIsOpen] = useState(false);
    const [temperatureExceededBoundsDialogIsOpen, setTemperatureExceededBoundsDialogIsOpen] = useState(false);
    const [shipmentPriorityDialogIsOpen, setShipmentPriorityDialogIsOpen] = useState(false);
    const [orderExceptionsDialogIsOpen, setOrderExceptionsDialogIsOpen] = useState(false);

    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    const originDisplayDateList = organizationPreferences ?
        determineDisplayDates(
            organizationPreferences.preferredStopTimeSourceType,
            organizationPreferences.showEta,
            shipment.originGeofenceDepartureDateTime,
            shipment.originCarrierDepartureDateTime,
            shipment.originGeofenceArrivalDateTime,
            shipment.originCarrierArrivalDateTime,
            shipment.originStopETA
        ) : [];

    const destinationDisplayDateList = organizationPreferences ?
        determineDisplayDates(
            organizationPreferences.preferredStopTimeSourceType,
            organizationPreferences.showEta,
            shipment.destinationGeofenceDepartureDateTime,
            shipment.destinationCarrierDepartureDateTime,
            shipment.destinationGeofenceArrivalDateTime,
            shipment.destinationCarrierArrivalDateTime,
            shipment.destinationStopETA
        ) : [];

    const renderStopDates = (stopType: StopType, dateList: { label: string; value: string | null; }[]): JSX.Element[] => {
        return dateList.map((dateObject): JSX.Element => {
            const alphanumericLabel = dateObject.label.replace(/[^A-Z0-9]+/ig, '');
            if (dateObject.value) {
                return <LabelValuePair key={alphanumericLabel} label={dateObject.label} value={dateObject.value} fontSize='12px' data-qa={`${stopType.toLowerCase()}${alphanumericLabel}`} />;
            }
            return <Fragment key={alphanumericLabel} />;
        });
    };

    const renderCurrentStop = (): JSX.Element => {
        let currentStop: string | number = shipment.activeStopSequence - 1;
        if (shipment.activeStopSequence === 1) {
            currentStop = 'Origin';
        } else if (shipment.activeStopSequence === shipment.numberOfStops) {
            currentStop = 'Destination';
        }
        return (
            <StyledTooltip
                placement='left-start'
                title={
                    <Fragment>
                        <Typography className={classes.activeStopLabel} data-qa='currentStop-label'>Current Stop</Typography>
                        {
                            shipment.activeStopLocationName || shipment.activeStopName ||
                                shipment.activeStopAddress1 || shipment.activeStopAddress2 ||
                                shipment.activeStopCity || shipment.activeStopState || shipment.activeStopZip ?
                                (
                                    <Fragment>
                                        <Typography className={classes.locationName} data-qa='stopName'>{shipment.activeStopLocationName || shipment.activeStopName || ''}</Typography>
                                        <Typography className={classes.activeStopAddress} component='address' data-qa='street'>
                                            {`${shipment.activeStopAddress1 || ''} ${shipment.activeStopAddress2 || ''}`}
                                        </Typography>
                                        <Typography className={classes.activeStopAddress} component='address' data-qa='cityRegionPostal'>
                                            {formatCityRegionPostalCountry({ city: shipment.activeStopCity, region: shipment.activeStopState, postal: shipment.activeStopZip })}
                                        </Typography>
                                    </Fragment>
                                ) : (
                                    <Typography className={classes.activeStopUnavailableText} data-qa='addressUnavailable'>Unavailable</Typography>
                                )
                        }
                        <Typography className={classes.activeStopDisplayDate} data-qa='activeStopDisplayDate'>
                            {shipment.activeStopETA ? `ETA ${zonedDateTimeToDisplay(shipment.activeStopETA)}` : ''}
                        </Typography>
                    </Fragment>
                }
            >
                <span className={classes.activeStopTooltip} data-qa='activeStopSequence'>{currentStop}</span>
            </StyledTooltip>
        );
    };

    return (
        <Fragment>
            <StyledGrid
                container
                className={classes.cardContainer}
                data-qa='shipmentCard-container'
            >
                <Grid item xs={12} className={classes.cardWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={shipment.tripId ? 6 : 12} className={classes.linksWrapper}>
                                    <ShipmentDetailsLabelLink
                                        label='Shipment'
                                        noWrap
                                        shipmentUniqueName={shipment.shipmentUniqueName}
                                        freightProviderReferenceNumber={shipment.freightProviderReferenceNumber}
                                        modeType={shipment.modeType}
                                    />
                                </Grid>
                                {
                                    shipment.tripId &&
                                    <Grid item xs={12} lg={6}>
                                        <TripDetailsLabelLink
                                            label='Trip ID'
                                            tripId={shipment.tripId}
                                            noWrap
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={10} lg={8} xl={10}>
                                    <LabelValuePair
                                        noWrap
                                        label='Carrier'
                                        value={shipment.carrierName}
                                        data-qa='carrier'
                                    />
                                </Grid>
                                <Grid item>
                                    <ShipmentListActions shipment={shipment} iconColor={theme.palette.text.secondary} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <LabelValuePair label='Mode' value={shipment.modeType} data-qa='mode' />
                        </Grid>
                        <Grid item xs={12} sm={6} data-qa='activeStop-container'>
                            <LabelValuePair label='Current Stop' value={renderCurrentStop()} data-qa='activeStop' />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <LabelValuePair label='Tractor #' value={shipment.tractorReferenceNumber} data-qa='tractorNumber' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValuePair label='Trailer #' value={shipment.trailerReferenceNumber} data-qa='trailerNumber' />
                        </Grid>
                    </Grid>

                    <Grid container alignItems='center' spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Grid container alignItems='center'>
                                <Grid item xs={12} sm='auto'>
                                    <Typography className={classes.statusLabel} data-qa='shipmentStatus'>
                                        {
                                            addSpaceBeforeUppercaseCharacter(
                                                isShipmentLtl(shipment.modeType) && shipment.currentMilestoneType
                                                    ? shipment.currentMilestoneType
                                                    : shipment.shipmentStatus
                                            )
                                        }
                                    </Typography>
                                </Grid>
                                <StatusActionBar
                                    isPriorityShipment={shipment.isPriorityShipment}
                                    handleShipmentPriorityClick={(): void => {
                                        setShipmentPriorityDialogIsOpen(true);
                                    }}
                                    weatherSeverity={shipment.weatherSeverity}
                                    handleWeatherSeverityClick={(): void => {
                                        setWeatherAlertsDialogIsOpen(true);
                                    }}
                                    totalShipmentExceptions={shipment.totalShipmentExceptions}
                                    handleShipmentExceptionsClick={(): void => {
                                        setOrderExceptionsDialogIsOpen(true);
                                    }}
                                    hasTemperatureExceededBounds={shipment.hasTemperatureExceededBoundsFlag}
                                    handleTemperatureExceededBoundsClick={(): void => {
                                        setTemperatureExceededBoundsDialogIsOpen(true);
                                    }}
                                    hasShipmentNotes={shipment.hasShipmentNotes}
                                    handleShipmentNotesClick={(): void => {
                                        setNotesDialogIsOpen(true);
                                    }}
                                    hasShipmentDocuments={shipment.hasShipmentDocuments}
                                    handleShipmentDocumentsClick={(): void => {
                                        setDocumentsDialogIsOpen(true);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeliveryStatusIndicator
                                deliveryStatus={shipment.deliveryStatus}
                            />
                            {
                                shipment.currentPositionDateTime &&
                                <DeliveryStatusTooltip
                                    timestamp={shipment.currentPositionDateTime}
                                    currentCity={shipment.currentPositionCity}
                                    currentState={shipment.currentPositionState}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container className={classes.addressContainer} spacing={1}>
                        <Grid item xs={12} sm={6} data-qa='origin-container'>
                            <div className={classes.stopTypeContainer}>
                                <Typography className={classes.stopTitle} data-qa='origin-label'>Origin</Typography>
                                {
                                    (shipment.originContactName || shipment.originContactPhoneNumber || shipment.originContactEmailAddress) &&
                                    <StopContactInfo
                                        contactName={shipment.originContactName}
                                        contactPhone={shipment.originContactPhoneNumber}
                                        contactEmail={shipment.originContactEmailAddress}
                                    />
                                }
                            </div>

                            {
                                shipment.originLocationName &&
                                <LabelValuePair label='Location Name' value={shipment.originLocationName} data-qa='stopLocationName' />
                            }
                            <StopAddress
                                description={null}
                                location={shipment.originFixedLocationCode}
                                name={shipment.originStopName}
                                street1={shipment.originStreet1}
                                street2={shipment.originStreet2}
                                city={shipment.originCity}
                                region={shipment.originState}
                                postal={shipment.originZip}
                                country={shipment.originCountryCode}
                                modeType={shipment.modeType}
                            />
                            <Hidden breakpoint='sm' direction='up'>
                                {renderStopDates(StopType.Origin, originDisplayDateList)}
                            </Hidden>
                        </Grid>

                        <Grid item xs={12} sm={6} data-qa='destination-container'>
                            <div className={classes.stopTypeContainer}>
                                <Typography className={classes.stopTitle} data-qa='destination-label'>Destination</Typography>
                                {
                                    (shipment.destinationContactName || shipment.destinationContactPhoneNumber || shipment.destinationContactEmailAddress) &&
                                    <StopContactInfo
                                        contactName={shipment.destinationContactName}
                                        contactPhone={shipment.destinationContactPhoneNumber}
                                        contactEmail={shipment.destinationContactEmailAddress}
                                    />
                                }

                            </div>

                            {
                                shipment.destinationLocationName &&
                                <LabelValuePair label='Location Name' value={shipment.destinationLocationName} data-qa='stopLocationName' />
                            }
                            <StopAddress
                                description={null}
                                location={shipment.destinationFixedLocationCode}
                                name={shipment.destinationStopName}
                                street1={shipment.destinationAddress1}
                                street2={shipment.destinationAddress2}
                                city={shipment.destinationCity}
                                region={shipment.destinationState}
                                postal={shipment.destinationZip}
                                country={shipment.destinationCountryCode}
                                modeType={shipment.modeType}
                            />
                            <Hidden breakpoint='sm' direction='up'>
                                {renderStopDates(StopType.Destination, destinationDisplayDateList)}
                            </Hidden>
                        </Grid>
                    </Grid>

                    <Hidden breakpoint='xs'>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                {renderStopDates(StopType.Origin, originDisplayDateList)}
                            </Grid>
                            <Grid item xs={6}>
                                {renderStopDates(StopType.Destination, destinationDisplayDateList)}
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </StyledGrid>

            {
                notesDialogIsOpen &&
                <NotesDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={notesDialogIsOpen}
                    closeDialog={(): void => {
                        setNotesDialogIsOpen(false);
                    }}
                />
            }

            {
                documentsDialogIsOpen &&
                <DocumentsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={documentsDialogIsOpen}
                    closeDialog={(): void => {
                        setDocumentsDialogIsOpen(false);
                    }}
                />
            }

            {
                weatherAlertsDialogIsOpen &&
                <WeatherAlertsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={weatherAlertsDialogIsOpen}
                    closeDialog={(): void => {
                        setWeatherAlertsDialogIsOpen(false);
                    }}
                />
            }

            {
                temperatureExceededBoundsDialogIsOpen &&
                <TemperatureHistoryDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={temperatureExceededBoundsDialogIsOpen}
                    closeDialog={(): void => {
                        setTemperatureExceededBoundsDialogIsOpen(false);
                    }}
                />
            }

            {
                shipmentPriorityDialogIsOpen &&
                <ShipmentPriorityDialog
                    freightProviderReferenceNumber={shipment.freightProviderReferenceNumber}
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={shipmentPriorityDialogIsOpen}
                    closeDialog={(): void => {
                        setShipmentPriorityDialogIsOpen(false);
                    }}
                />
            }

            {
                orderExceptionsDialogIsOpen &&
                <OrderExceptionsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={orderExceptionsDialogIsOpen}
                    closeDialog={(): void => {
                        setOrderExceptionsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentCard;
