import React, { Fragment, useState } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import { ShipmentMilestone } from '../../interfaces/services/shipmentDetails';
import { formatFullAddress } from '../../helpers/addressUtils';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';

const classesPrefix = 'progressHistoryTable';

const classes = {
    caption: `${classesPrefix}-caption`,
    tableHeader: `${classesPrefix}-tableHeader`,
    textContainer: `${classesPrefix}-textContainer`,
    description: `${classesPrefix}-description`
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => {
    return {
        [`& .${classes.caption}`]: {
            fontWeight: 600
        },
        [`& .${classes.tableHeader}`]: {
            fontWeight: 600
        },
        [`& .${classes.textContainer}`]: {
            display: 'flex',
            justifyContent: 'center',
            color: theme.appColors.action,
            cursor: 'pointer',
            marginTop: '8px'
        },
        [`& .${classes.description}`]: {
            padding: '0 12px',
            position: 'relative',
            bottom: '6px',
            fontWeight: 600
        }
    };
});

const ProgressHistoryTable = ({
    shipmentMilestones,
    limitDisplayedRowsCount = 0
}: {
    shipmentMilestones: ShipmentMilestone[];
    /** Limit the number of rows displayed. Must be greater than 0 to turn on functionality */
    limitDisplayedRowsCount?: number;
}): JSX.Element => {
    const [showFullTable, setShowFullTable] = useState(false);

    const renderTableRow = (milestone: ShipmentMilestone, index: number): JSX.Element => {
        return (
            <TableRow key={index} data-qa={`progressHistory-row-${index + 1}`}>
                <TableCell data-qa='milestone'>{addSpaceBeforeUppercaseCharacter(milestone.milestoneType)}</TableCell>
                <TableCell data-qa='location'>
                    {formatFullAddress({
                        street1: milestone.streetAddressLine1,
                        street2: milestone.streetAddressLine2,
                        city: milestone.cityName,
                        region: milestone.stateCode,
                        postal: milestone.postalCode,
                        country: milestone.countryCode
                    })}
                </TableCell>
                <TableCell data-qa='time'>{zonedDateTimeToDisplay(milestone.eventDateTime)}</TableCell>
                <TableCell data-qa='detail'>{milestone.eventDetail}</TableCell>
            </TableRow>
        );
    };

    const renderTableBody = (): JSX.Element | JSX.Element[] => {
        if (shipmentMilestones.length === 0) {
            return (
                <TableRow>
                    <TableCell align='center' colSpan={4} data-qa='progressHistory-noResults'>No Progress History Found</TableCell>
                </TableRow>
            );
        }

        // If limitDisplayedRowsCount is 0, meaning no limit has been set, or showFullTable is true show all rows in the table
        if (limitDisplayedRowsCount === 0 || showFullTable) {
            return shipmentMilestones.map((milestone, index): JSX.Element => {
                return renderTableRow(milestone, index);
            });
        }

        const limitedResults = [];
        // loop through the shipment milestones and add up to the number of rows defined by limitDisplayedRowsCount
        for (let i = 0; i < shipmentMilestones.length; i++) {
            // check if we have another row to add by subtracting 1 from limitDisplayedRowsCount to match the shipmentMilestones array
            if ((limitDisplayedRowsCount - 1) >= i) {
                const milestone = shipmentMilestones[i];
                limitedResults.push(
                    renderTableRow(milestone, i)
                );
            }
        }
        return limitedResults;

    };

    const renderExpansionToggle = (): JSX.Element => {
        if (shipmentMilestones.length > limitDisplayedRowsCount) {
            return (
                <Grid container>
                    <Grid item xs={12} onClick={(): void => { setShowFullTable(!showFullTable); }} className={classes.textContainer}>
                        {
                            showFullTable
                                ? (
                                    <Typography>
                                        <Icon path={mdiChevronUp} size={1} />
                                        <span className={classes.description}>Hide</span>
                                        <Icon path={mdiChevronUp} size={1} />
                                    </Typography>
                                ) : (
                                    <Typography>
                                        <Icon path={mdiChevronDown} size={1} />
                                        <span className={classes.description}>Show More</span>
                                        <Icon path={mdiChevronDown} size={1} />
                                    </Typography>
                                )
                        }
                    </Grid>
                </Grid>
            );
        }
        return <Fragment />;
    };

    return (
        <StyledTableContainer>
            <Table size='small' aria-label='Progress History' data-qa='progressHistory-table'>
                <caption>
                    <Typography className={classes.caption} variant='h6' data-qa='progressHistory-header'>Progress History</Typography>
                </caption>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='milestone-column-header'>Milestone</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='location-column-header'>Location</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='time-column-header'>Time</TableCell>
                        <TableCell classes={{ head: classes.tableHeader }} data-qa='detail-column-header'>Detail</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-qa='progressHistory-content'>
                    {renderTableBody()}
                </TableBody>
            </Table>
            {limitDisplayedRowsCount !== 0 && renderExpansionToggle()}
        </StyledTableContainer>
    );
};

export default ProgressHistoryTable;
