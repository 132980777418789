import React, { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus, ModeType, ShareType } from '../../helpers/enums';
import { ShipmentListMapSourceProperties } from '../../helpers/maps/sources/shipmentList';
import ShipmentsMap from './shipmentsMap';
import ShipmentDetailsDialog from '../dialogs/shipmentDetailsDialog';

interface StyleProps {
    shareType: ShareType | null;
}

const classesPrefix = 'shipmentsMapWrapper';

const classes = {
    mapWrapper: `${classesPrefix}-mapWrapper`
};

const StyledDiv = styled(
    'div',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'shareType'; }
    }
)<StyleProps>(({ shareType }) => {
    return {
        [`&.${classes.mapWrapper}`]: {
            height: shareType === ShareType.View ? 'calc(100vh - 116px)' : 'calc(100vh - 184px)'
        }
    };
});

const ShipmentsMapWrapper = ({
    resize
}: {
    /** Indicator to trigger a map resize function. */
    resize?: boolean;
}): JSX.Element => {
    const [shipmentDetailsDialog, setShipmentDetailsDialog] = useState({
        open: false,
        shipmentUniqueName: '',
        modeType: ModeType.Unknown
    });

    const shipmentListStatus = useTypedSelector((state) => { return state.shipments.shipmentListStatus; });
    const shipmentList = useTypedSelector((state) => { return state.shipments.shipmentList; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const handleShipmentMarkerClick = (properties: ShipmentListMapSourceProperties): void => {
        const { shipmentUniqueName, modeType } = properties;

        setShipmentDetailsDialog({
            open: true,
            shipmentUniqueName,
            modeType
        });
    };

    return (
        <Fragment>
            <StyledDiv shareType={shareType} className={classes.mapWrapper}>
                <ShipmentsMap
                    isFetchingData={shipmentListStatus === ApiStatus.Idle || shipmentListStatus === ApiStatus.Loading}
                    shipmentList={shipmentList}
                    handleShipmentMarkerClick={handleShipmentMarkerClick}
                    paletteMode={paletteMode}
                    resize={resize}
                />
            </StyledDiv>

            {
                shipmentDetailsDialog.open && shipmentDetailsDialog.shipmentUniqueName &&
                <ShipmentDetailsDialog
                    shipmentUniqueName={shipmentDetailsDialog.shipmentUniqueName}
                    modeType={shipmentDetailsDialog.modeType}
                    handleDialogClose={(): void => {
                        setShipmentDetailsDialog({
                            open: false,
                            shipmentUniqueName: '',
                            modeType: ModeType.Unknown
                        });
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentsMapWrapper;
