import React from 'react';

import TransparentFullScreenDialog from './common/transparentFullScreenDialog';
import TripDetails from '../sections/tripDetails';

const TripDetailsDialog = ({
    tripIdentifier,
    handleDialogClose
}: {
    /** unique identifier for the trip */
    tripIdentifier: string;
    /** Function triggered by the close action */
    handleDialogClose: () => void;
}): JSX.Element => {
    return (
        <TransparentFullScreenDialog
            headerText={`Trip ${tripIdentifier}`}
            handleDialogClose={handleDialogClose}
        >
            <TripDetails
                tripIdentifier={tripIdentifier}
            />
        </TransparentFullScreenDialog>
    );
};

export default TripDetailsDialog;
