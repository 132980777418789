import React, { Fragment, useState } from 'react';
import { Link } from '@mui/material';

import { ModeType } from '../../helpers/enums';
import { useAccessTokenUrl } from '../../hooks/useAccessTokenUrl';
import LabelValuePair from '../labels/labelValuePair';
import ShipmentDetailsDialog from '../dialogs/shipmentDetailsDialog';

const ShipmentDetailsLabelLink = ({
    label,
    shipmentUniqueName,
    freightProviderReferenceNumber,
    modeType,
    displayField = 'freightProviderReferenceNumber',
    fontSize = '14px',
    noWrap = false,
    alwaysOpenInNewTab = false
}: {
    /** The display label describing the shipment link. */
    label: string;
    /** Unique identifier for the shipment */
    shipmentUniqueName: string;
    /** Freight provider reference number for the shipment */
    freightProviderReferenceNumber: string;
    /** Mode type for the shipment */
    modeType: ModeType;
    /** To allow showing either the shipmentUniqueName or freightProviderReferenceNumber in link */
    displayField?: 'shipmentUniqueName' | 'freightProviderReferenceNumber';
    /**
     * The size of the font.
     * @default 14px
     */
    fontSize?: string;
    /**
     * If true, the text will not wrap, but instead will truncate with a text overflow ellipsis.
     * @default false
     */
    noWrap?: boolean;
    /** Always open the shipment details in a new tab
     * @default false
     */
    alwaysOpenInNewTab?: boolean;
}): JSX.Element => {
    const [shipmentDetailsDialogIsOpen, setShipmentDetailsDialogIsOpen] = useState(false);
    const url = useAccessTokenUrl(`/shipmentDetails/${shipmentUniqueName}`);

    return (
        <Fragment>
            <LabelValuePair
                label={label}
                fontSize={fontSize}
                noWrap={noWrap}
                value={
                    <span data-qa='shipmentDetailsNavigation-container'>
                        <Link
                            target='_blank'
                            rel='noopener noreferrer'
                            href={url}
                            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void => {
                                if (alwaysOpenInNewTab === false) {
                                    // Remove focus from the button so the tooltip does not remain after the click
                                    event.currentTarget.blur();
                                    // README: When the link is clicked js will open the Dialog and will not navigate.
                                    // If the user right clicks and selects open in a new tab the href value is used to navigate
                                    event.preventDefault();
                                    setShipmentDetailsDialogIsOpen(true);
                                }
                            }}
                            data-qa='shipmentDetails-navigation'
                        >
                            {displayField === 'freightProviderReferenceNumber' && freightProviderReferenceNumber}
                            {displayField === 'shipmentUniqueName' && shipmentUniqueName}
                        </Link>
                    </span>
                }
                data-qa='shipmentId'
            />

            {
                shipmentDetailsDialogIsOpen &&
                <ShipmentDetailsDialog
                    shipmentUniqueName={shipmentUniqueName}
                    modeType={modeType}
                    handleDialogClose={(): void => {
                        setShipmentDetailsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentDetailsLabelLink;
