import React, { Fragment, useState } from 'react';
import { Tabs, Tab } from '@mui/material';

import { ShipmentHistoryData } from '../../interfaces/services/shipmentHistory';
import ShipmentHistoryTable from '../tables/shipmentHistoryTable';

enum HistoryTab {
    Tracking,
    Change
}

const ShipmentHistoryTabs = ({
    isFetching,
    history
}: {
    isFetching: boolean;
    history: ShipmentHistoryData;
}): JSX.Element => {
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <Fragment>
            <Tabs
                value={currentTab}
                onChange={(event, value: number): void => {
                    setCurrentTab(value);
                }}
                centered
                indicatorColor='primary'
                textColor='primary'
                data-qa='history-tabList'
            >
                <Tab
                    label='Tracking &amp; Status History'
                    value={HistoryTab.Tracking}
                    data-qa='trackingHistory-tab'
                />

                <Tab
                    label='Change History'
                    value={HistoryTab.Change}
                    data-qa='changeHistory-tab'
                />
            </Tabs>
            {
                currentTab === HistoryTab.Tracking &&
                <ShipmentHistoryTable
                    isFetching={isFetching}
                    timeZone={history.organizationPreferredTimeZone}
                    shipmentHistory={history.trackingAndStatusHistory}
                />
            }
            {
                currentTab === HistoryTab.Change &&
                <ShipmentHistoryTable
                    isFetching={isFetching}
                    timeZone={history.organizationPreferredTimeZone}
                    shipmentHistory={history.changeHistory}
                />
            }
        </Fragment>
    );
};

export default ShipmentHistoryTabs;
