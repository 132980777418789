import React from 'react';
import { Typography } from '@mui/material';
import ErrorPage from './errorPage';

const NoTripDetails = (): JSX.Element => {
    return (
        <ErrorPage errorHeaderText='No Trip Details Found'>
            <Typography data-qa='noTripDetails'>
                We were unable to locate the details for this trip.
            </Typography>
        </ErrorPage>
    );
};

export default NoTripDetails;
