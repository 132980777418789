import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { GenericApiResponse } from '../../interfaces/services';
import { FixedLocationData } from '../../interfaces/services/locationManagement';
import { ApiStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { initialLocationDetailsState } from './initialState';

const LOCATION_DETAILS_REQUEST = 'LOCATION_DETAILS_REQUEST';
const LOCATION_DETAILS_SUCCESS = 'LOCATION_DETAILS_SUCCESS';
const LOCATION_DETAILS_FAILURE = 'LOCATION_DETAILS_FAILURE';

interface LocationDetailsData {
    fixedLocationDetailsStatus: ApiStatus;
    fixedLocationDetails: FixedLocationData;
}

const requestLocationDetails = (): Action<typeof LOCATION_DETAILS_REQUEST> => {
    return {
        type: LOCATION_DETAILS_REQUEST
    };
};

const receiveLocationDetails = (json: FixedLocationData): GenericAction<typeof LOCATION_DETAILS_SUCCESS, FixedLocationData> => {
    return {
        type: LOCATION_DETAILS_SUCCESS,
        payload: json
    };
};

const requestLocationDetailsFailed = (): Action<typeof LOCATION_DETAILS_FAILURE> => {
    return {
        type: LOCATION_DETAILS_FAILURE
    };
};

export const fetchLocationDetails = (locationCode: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestLocationDetails());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.LocationManagementApi}${Endpoints.locationManagementApi.fixedLocation}/${locationCode}` }) as GenericApiResponse<FixedLocationData>;
            // If the location api fails to find the locationCode an empty array is returned and we need to handle that with an error
            if (json.data.length > 0) {
                dispatch(receiveLocationDetails(json.data[0]));
            } else {
                throw new Error(`Unable to retrieve location details for ${locationCode}.`);
            }
        } catch (err) {
            dispatch(requestLocationDetailsFailed());
            toast.error(`Error occurred while fetching location details for ${locationCode}.`);
        }
    };
};

type LocationDetailsActionTypes = ReturnType<typeof requestLocationDetails> | ReturnType<typeof receiveLocationDetails> | ReturnType<typeof requestLocationDetailsFailed>;

export const locationDetailsReducer = (locationDetailsData: LocationDetailsData = {
    fixedLocationDetailsStatus: ApiStatus.Idle,
    fixedLocationDetails: initialLocationDetailsState
}, action: LocationDetailsActionTypes): LocationDetailsData => {
    switch (action.type) {
        case LOCATION_DETAILS_REQUEST: {
            return {
                ...locationDetailsData,
                fixedLocationDetailsStatus: ApiStatus.Loading
            };
        }
        case LOCATION_DETAILS_SUCCESS: {
            return {
                ...locationDetailsData,
                fixedLocationDetailsStatus: ApiStatus.Success,
                fixedLocationDetails: action.payload
            };
        }
        case LOCATION_DETAILS_FAILURE: {
            return {
                ...locationDetailsData,
                fixedLocationDetailsStatus: ApiStatus.Failure,
                fixedLocationDetails: initialLocationDetailsState
            };
        }
        default:
            return locationDetailsData;
    }
};
