import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, InputLabel, Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { shipmentExpirationTimeOptions } from '../../../helpers/hardcodedOptionLists';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'shipmentExpirationCard';

const classes = {
    selectWrapper: `${classesPrefix}-selectWrapper`,
    selectField: `${classesPrefix}-selectField`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.selectWrapper}`]: {
            marginTop: '16px'
        },
        [`& .${classes.selectField}`]: {
            maxWidth: '320px'
        }
    };
});

const ShipmentExpirationCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleShipmentExpirationTimeFrameHoursChange = (event: SelectChangeEvent<unknown>): void => {
        dispatch(editPreferences(PreferencesFieldNames.shipmentExpirationTimeFrameHours, Number(event.target.value)));
    };

    return (
        <PreferenceCard header='Shipment Expiration'>
            <Typography component='p' variant='body2'>
                How many days after the Scheduled Delivery Date would you like shipments that DO NOT DELIVER to expire and move from the shipments page to the history page?
            </Typography>

            <StyledDiv className={classes.selectWrapper}>
                <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor='shipmentExpirationTimeFrameHoursLabel'>Days</InputLabel>
                    <Select
                        labelId='shipmentExpirationTimeFrameHoursLabel'
                        className={classes.selectField}
                        variant='standard'
                        value={organizationEditablePreferences.shipmentExpirationTimeFrameHours}
                        onChange={handleShipmentExpirationTimeFrameHoursChange}
                        inputProps={{
                            'data-qa': 'shipmentExpirationTimeFrame-label'
                        }}
                    >
                        {
                            shipmentExpirationTimeOptions.map((option) => {
                                return (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        data-qa={`shipmentExpirationTimeFrame-item-${option.label}`}
                                    >
                                        {option.label}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </StyledDiv>
        </PreferenceCard>
    );
};

export default ShipmentExpirationCard;
