export enum AllowedFilterPropertyName {
    // date filters
    OriginScheduleStartDateTime = 'originScheduleStartDateTime',
    OriginScheduleEndDateTime = 'originScheduleEndDateTime',
    OriginAppointmentStartDateTime = 'originAppointmentStartDateTime',
    OriginAppointmentEndDateTime = 'originAppointmentEndDateTime',
    DestinationScheduleStartDateTime = 'destinationScheduleStartDateTime',
    DestinationScheduleEndDateTime = 'destinationScheduleEndDateTime',
    DestinationAppointmentStartDateTime = 'destinationAppointmentStartDateTime',
    DestinationAppointmentEndDateTime = 'destinationAppointmentEndDateTime',

    // date filters for calendar
    AppointmentDateRange = 'appointmentDateRange',
    OriginAppointmentDateRange = 'originAppointmentDateRange',
    DestinationAppointmentDateRange = 'destinationAppointmentDateRange',
    ScheduleDateRange = 'scheduleDateRange',
    OriginScheduleDateRange = 'originScheduleDateRange',
    DestinationScheduleDateRange = 'destinationScheduleDateRange',

    // filters using Master Data
    Customers = 'productCustomer',
    Partners = 'partnerName',
    Products = 'productLine',
    Brand = 'productBrand',
    Sku = 'sku',
    Origin = 'originDisplay',
    Destination = 'destinationDisplay',
    InboundLocation = 'inboundLocation',
    OutboundLocation = 'outboundLocation',
    OriginState = 'originState',
    DestinationState = 'destinationState',
    OriginCountryCode = 'originCountryCode',
    DestinationCountryCode = 'destinationCountryCode',
    OrderExceptions = 'orderExceptions',

    // hardcoded filters
    DeliveryStatus = 'deliveryStatus',
    ShipmentStatus = 'shipmentStatus',
    ModeType = 'modeType',
    EquipmentType = 'equipmentType',
    WeatherSeverity = 'weatherSeverity',
    IsPriorityShipment = 'isPriorityShipment',
    CurrentMilestoneType = 'currentMilestoneType',

    // order filters
    PickupScheduleStartDateTime = 'pickupScheduleStartDateTime',
    PickupScheduleEndDateTime = 'pickupScheduleEndDateTime',
    PickupAppointmentStartDateTime = 'pickupAppointmentStartDateTime',
    PickupAppointmentEndDateTime = 'pickupAppointmentEndDateTime',
    DeliveryScheduleStartDateTime = 'deliveryScheduleStartDateTime',
    DeliveryScheduleEndDateTime = 'deliveryScheduleEndDateTime',
    DeliveryAppointmentStartDateTime = 'deliveryAppointmentStartDateTime',
    DeliveryAppointmentEndDateTime = 'deliveryAppointmentEndDateTime',
    OrderPickupStatus = 'pickupDeliveryStatus',
    OrderDeliveryStatus = 'deliveryDeliveryStatus',

    // order filters using Master Data
    CustomerName = 'customerName',
    CarrierName = 'carrierName',
    PickupCity = 'pickupCity',
    DeliveryCity = 'deliveryCity',
    PickupState = 'pickupState',
    DeliveryState = 'deliveryState',
    PickupLocationCode = 'pickupFixedLocationCode',
    DeliveryLocationCode = 'deliveryFixedLocationCode',
}

export enum AllowedNotificationFilterPropertyName {
    // filters using Master Data
    Brand = 'Brands',
    Customers = 'ProductCustomers',
    Destination = 'DestinationDisplay',
    InboundLocation = 'DistributionCenters',
    OrderExceptions = 'OrderExceptions',
    Origin = 'OriginDisplay',
    OutboundLocation = 'OriginFixedLocation',
    Partners = 'PartnerName',
    Products = 'ProductLines',
    Sku = 'Skus',
    OriginState = 'OriginStateCode',
    DestinationState = 'DestinationStateCode',

    // hardcoded filters
    DeliveryStatus = 'DeliveryStatus',
    ShipmentStatus = 'Status',
    EquipmentType = 'EquipmentType',
    HotShipments = 'HotShipments',
    ModeType = 'ModeType'
}

export enum ApiStatus {
    Idle = 'idle',
    Loading = 'loading',
    Success = 'success',
    Failure = 'failure'
}

export enum ApplicationResourceNames {
    ShipmentsOverview = 'ShipmentsOverview',
    ShipmentListActive = 'ShipmentListActive',
    ShipmentsCalendar = 'ShipmentsCalendar',
    ShipmentListHistory = 'ShipmentListHistory',
    OrdersOverview = 'Orders',
    OrdersListActive = 'OrdersListActive',
    Preferences = 'Preferences',
    CustomNotifications = 'CustomNotifications',
    Analytics = 'Dashboard',
    TuiLocations = 'TuiLocations',

    // FP Next
    Locations = 'Locations',

    // FREX
    Shipments = 'Shipments',
    CustomViews = 'CustomViews',

    Settings = 'Settings',
    ContactSupport = 'ContactSupport',
    FAQs = 'FAQs',
    LogOut = 'LogOut'
}

export enum CalendarDateType {
    Scheduled = 'Scheduled',
    Appointment = 'Appointment'
}

export enum CalendarNavigation {
    Today = 'TODAY',
    Next = 'NEXT',
    Previous = 'PREV'
}

export enum CalendarStopType {
    Delivery = 'Delivery',
    Pickup = 'Pickup'
}

export enum CalendarView {
    Month = 'month',
    Week = 'week',
    Day = 'day'
}

export enum DayOfWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

export enum DimensionNames {
    DeliveryStatus = 'DeliveryStatus',
    ShipmentStatus = 'ShipmentStatus',
    ModeType = 'ModeType',
    WeatherSeverity = 'WeatherSeverity',
    ExceptionStatus = 'ExceptionStatus',
    HasTemperatureExceededBounds = 'HasTemperatureExceededBoundsFlag',
    IsPriorityShipment = 'IsPriorityShipmentFlag',
    IsMobileTrackingActive = 'IsMobileTrackingActiveFlag',
    DocumentStatus = 'DocumentStatus',
    NoteStatus = 'NoteStatus'
}

/** Contains list of Delivery Statuses for the Shipment. */
export enum DeliveryStatus {
    Early = 'Early',
    OnTime = 'OnTime',
    InJeopardy = 'InJeopardy',
    Late = 'Late',
    TrackingLost = 'TrackingLost',
    TenderAccepted = 'TenderAccepted'
}

export enum DocumentType {
    All = 'All',
    RateConfirmation = 'RateConfirmation',
    POD = 'POD',
    BOL = 'BOL',
    Invoice = 'Invoice',
    FreightDamageDocumentation = 'FreightDamageDocumentation',
    TruckTrailerDamageDocumentation = 'TruckTrailerDamageDocumentation',
    LumperReceipt = 'LumperReceipt',
    DeliveryTicketReceiptOrder = 'DeliveryTicketReceiptOrder',
    Insurance = 'Insurance',
    WeightScaleCertificate = 'WeightScaleCertificate',
    FineTicket = 'FineTicket',
    DOTInspection = 'DOTInspection',
    ODPermit = 'ODPermit',
    Directions = 'Directions',
    PackingSlip = 'PackingSlip',
    Check = 'Check',
    FreightBill = 'FreightBill',
    Log = 'Log',
    FuelReceipt = 'FuelReceipt',
    ScaleReceipt = 'ScaleReceipt',
    TripSheet = 'TripSheet',
    ProofOfPerformance = 'ProofOfPerformance',
    Match = 'Match',
    Other = 'Other',
    CarrierLogoSmall = 'CarrierLogoSmall',
    CarrierLogoLarge = 'CarrierLogoLarge',
    W9 = 'W9',
    LetterOfAuthority = 'LetterOfAuthority',
    FuelSurcharge = 'FuelSurcharge',
    CarrierAgreement = 'CarrierAgreement',
    AccessorialList = 'AccessorialList',
    SignatureCapture = 'SignatureCapture'
}

export enum EquipmentType {
    Conestoga = 'Conestoga',
    Container = 'Container',
    DryBulk = 'DryBulk',
    Flatbed = 'Flatbed',
    Other = 'Other',
    Reefer = 'Reefer',
    Tanker = 'Tanker',
    Undeclared = 'Undeclared',
    Van = 'Van'
}

export enum FixedLocationAuthorityType {
    TrimbleVisibility = 'TrimbleVisibility',
    ALKPlaces = 'ALKPlaces'
}

export enum FixedLocationTypes {
    Unknown = 'Unknown',
    Generic = 'Generic',
    DistributionCenter = 'DistributionCenter',
    ManufacturingFacility = 'ManufacturingFacility',
    Crossdock = 'Crossdock',
    Terminal = 'Terminal',
    Fuel = 'Fuel',
    TrailerDropYard = 'TrailerDropYard',
    TractorDropYard = 'TractorDropYard',
    TractorTrailerSwap = 'TractorTrailerSwap',
    RestArea = 'RestArea',
    TruckWash = 'TruckWash',
    TankWash = 'TankWash',
    Customer = 'Customer'
}

/** Contains list of Freight Hauler Identifier Type Names for the Shipment. */
export enum FreightHaulerIdentifierTypeName {
    MCNumber = 'MCNumber',
    USDotNumber = 'USDotNumber',
    SCACCode = 'SCACCode',
    PNetCID = 'PNetCID'
}

export enum LinearUnit {
    Miles = 'Miles',
    Feet = 'Feet',
    Kilometers = 'Kilometers',
    Meters = 'Meters',
    Inches = 'Inches'
}

export enum LinearUnitNumeric {
    None,
    Miles,
    Feet,
    Kilometers,
    Meters,
    Inches
}

export enum LocationsSortColumns {
    City = 'CityName',
    LastUpdateDate = 'LastUpdatedDate',
    LocationName = 'FixedLocationName',
    LocationCode = 'FixedLocationCode'
}

/** Contains list of Mode types for the Shipment. */
export enum ModeType {
    Unknown = 'Unknown',
    Truckload = 'Truckload',
    Rail = 'Rail',
    LTL = 'LTL',
    Ocean = 'Ocean',
    Parcel = 'Parcel'
}

/** Contains list of Milestone types for the Shipment. */
export enum MilestoneType {
    Pending = 'Pending',
    PickedUp = 'PickedUp',
    InTransit = 'InTransit',
    OutForDelivery = 'OutForDelivery',
    Delivered = 'Delivered'
}

export enum NotificationRecipientType {
    shipmentLevel = 'shipmentLevel',
    stopLevel = 'stopLevel'
}

export enum NotificationsSortColumns {
    lastUpdateDate = 'LastUpdatedDate',
    notificationName = 'Name',
    notificationRecipientType = 'NotificationRecipientType',
    notificationTypeLabel = 'NotificationTypeLabel'
}

export enum NotificationTypeLabel {
    deliveryStatusChange = 'deliveryStatusChange',
    orderExceptionAdded = 'orderExceptionAdded',
    temperatureOutOfRange = 'temperatureOutOfRange',
    shipmentStatusChange = 'shipmentStatusChange',
    etaChanged = 'etaChanged',
    dwellTime = 'dwellTime'
}

export enum NotificationTypes {
    None = 'None',
    DeliveryStatusLost = 'DeliveryStatusLost',
    DeliveryStatusLate = 'DeliveryStatusLate',
    DeliveryStatusInJeopardy = 'DeliveryStatusInJeopardy',
    DeliveryStatusOnTime = 'DeliveryStatusOnTime',
    DeliveryStatusEarly = 'DeliveryStatusEarly',
    ShipmentStatusPending = 'ShipmentStatusPending',
    ShipmentStatusDispached = 'ShipmentStatusDispached',
    ShipmentStatusEnRoute = 'ShipmentStatusEnRoute',
    ShipmentStatusAtShipper = 'ShipmentStatusAtShipper',
    ShipmentStatusInTransit = 'ShipmentStatusInTransit',
    ShipmentStatusAtConsignee = 'ShipmentStatusAtConsignee',
    ShipmentStatusDelivered = 'ShipmentStatusDelivered',
    ShipmentStatusCompleted = 'ShipmentStatusCompleted',
    ShipmentStatusCancelled = 'ShipmentStatusCancelled',
    ExtendedDwell = 'ExtendedDwell',
    PODUpdate = 'PODUpdate',
    OrderException = 'OrderException',
    TemperatureOutOfRange = 'TemperatureOutOfRange',
    EtaHasChanged = 'EtaHasChanged',
    EtaBaselineHasChanged = 'EtaBaselineHasChanged',
    EtaIsSetNumberOfMinutesAway = 'EtaIsSetNumberOfMinutesAway',
    EtaIsSetNumberOfMinutesFromTime = 'EtaIsSetNumberOfMinutesFromTime'
}

export enum OrderStopType {
    Pickup = 'Pickup',
    Delivery = 'Delivery'
}

export enum PieceUnit {
    Pallet = 'Pallet',
    Box = 'Box',
    Each = 'Each',
    Gallon = 'Gallon',
    Liter = 'Liter',
    Ounce = 'Ounce'
}

export enum PreferencesFieldNames {
    autoRefreshMinutes = 'autoRefreshMinutes',
    isManualShipmentEnabledFlag = 'isManualShipmentEnabledFlag',
    showPCMilerRouteByDefaultFlag = 'showPCMilerRouteByDefaultFlag',
    geofenceRadiusInFeet = 'geofenceRadiusInFeet',
    earlyIsAGoodThing = 'earlyIsAGoodThing',
    sortOptionFieldType = 'sortOptionFieldType',
    shipmentsToHistoryPageTimeframeInHours = 'shipmentsToHistoryPageTimeframeInHours',
    autoSetAtConsigneeToDeliveredFlag = 'autoSetAtConsigneeToDeliveredFlag',
    displayDeliveryStatusToDestination = 'displayDeliveryStatusToDestination',
    customLogoToken = 'customLogoToken',
    showEta = 'showEta',
    shipmentExpirationTimeFrameHours = 'shipmentExpirationTimeFrameHours',
    temperatureAlertMinutes = 'temperatureAlertMinutes',
    temperatureAlertBelowMin = 'temperatureAlertBelowMin',
    temperatureAlertAboveMax = 'temperatureAlertAboveMax',
    displayCarrierProvidedActualsOnly = 'displayCarrierProvidedActualsOnly',
    dropTrailerPickupRulesEnabled = 'dropTrailerPickupRulesEnabled',
    dropTrailerDeliveryRulesEnabled = 'dropTrailerDeliveryRulesEnabled',
    isDropTrailerSameDayPickup = 'isDropTrailerSameDayPickup',
    isDropTrailerSameDayDelivery = 'isDropTrailerSameDayDelivery',
    dropTrailerWindowPickupTimeFrom = 'dropTrailerWindowPickupTimeFrom',
    dropTrailerWindowPickupTimeTo = 'dropTrailerWindowPickupTimeTo',
    dropTrailerWindowDeliveryTimeFrom = 'dropTrailerWindowDeliveryTimeFrom',
    dropTrailerWindowDeliveryTimeTo = 'dropTrailerWindowDeliveryTimeTo',
    hasRequiredAppointmentEarlyRules = 'hasRequiredAppointmentEarlyRules',
    etaBaselineIncreaseOrDecrease = 'etaBaselineIncreaseOrDecrease',
    etaBaselineEnabled = 'etaBaselineEnabled',
    etaBaselineMinutes = 'etaBaselineMinutes',
    etaHotLoadBaselineIncreaseOrDecrease = 'etaHotLoadBaselineIncreaseOrDecrease',
    etaHotloadBaselineEnabled = 'etaHotloadBaselineEnabled',
    etaHotloadBaselineMinutes = 'etaHotloadBaselineMinutes',
    preferredStopTimeSourceType = 'preferredStopTimeSourceType',
    freightPortalPreferencesOnTimeRules = 'freightPortalPreferencesOnTimeRules',
    freightPortalColorPreference = 'freightPortalColorPreference'
}

export enum PreferencesOnTimeRulesFieldNames {
    earlyShipmentStatusMinutes = 'earlyShipmentStatusMinutes',
    onTimeShipmentStatusMinutes = 'onTimeShipmentStatusMinutes',
    inJeopardyShipmentStatusMinutes = 'inJeopardyShipmentStatusMinutes',
    lateShipmentStatusMinutes = 'lateShipmentStatusMinutes',
    hasEarlyStatusDefined = 'hasEarlyStatusDefined',
    hasInJeopardyStatusDefined = 'hasInJeopardyStatusDefined',
    trackingLostMinutes = 'trackingLostMinutes',
    hasTrackingLostDefined = 'hasTrackingLostDefined',
    dwellTimeEnabled = 'dwellTimeEnabled',
    dwellTimeMinutes = 'dwellTimeMinutes',
}

export enum PreferencesThemeColors {
    PrimaryNavBackground = 'primaryNavBackground',
    PrimaryMain = 'primaryMain',
    SecondaryMain = 'secondaryMain'
}

/** Used for a preference on whether geofence or carrier dates should be used for arrivals and departures. */
export enum PreferredStopTimeSourceType {
    Geofence,
    Carrier
}

export enum PublicDocumentType {
    All = 0,
    RateConfirmation = 1,
    POD = 2,
    BOL = 3,
    Invoice = 4,
    FreightDamageDocumentation = 5,
    TruckTrailerDamageDocumentation = 6,
    LumperReceipt = 7,
    DeliveryTicketReceiptOrder = 8,
    Insurance = 9,
    WeightScaleCertificate = 10,
    FineTicket = 11,
    DOTInspection = 12,
    ODPermit = 13,
    Directions = 14,
    PackingSlip = 15,
    Check = 16,
    FreightBill = 17,
    Log = 18,
    FuelReceipt = 19,
    ScaleReceipt = 20,
    TripSheet = 21,
    ProofOfPerformance = 22,
    Match = 23,
    Other = 24,
    CarrierLogoSmall = 25,
    CarrierLogoLarge = 26,
    W9 = 32,
    LetterOfAuthority = 33,
    FuelSurcharge = 34,
    CarrierAgreement = 35,
    AccessorialList = 36,
    SignatureCapture = 37
}

export enum ReferenceNumberType {
    CustomerReferenceNumber = 'CustomerReferenceNumber',
    JobNumber = 'JobNumber',
    PONumber = 'PONumber',
    PRONumber = 'PRONumber',
    BillOfLading = 'BillOfLading',
    SiteReferenceNumber = 'SiteReferenceNumber',
    PickupNumber = 'PickupNumber',
    DeliveryNumber = 'DeliveryNumber',
    AppointmentNumber = 'AppointmentNumber',
    SealNumber = 'SealNumber',
    OtherNumber = 'OtherNumber',
    LoadNumber = 'LoadNumber',
    MMSINumber = 'MMSINumber',
    FedexNumber = 'FedexNumber',
    USPSNumber = 'USPSNumber',
    UPSNumber = 'UPSNumber',
    ContainerNumber = 'ContainerNumber',
    TripNumber = 'TripNumber',
    VesselNumber = 'VesselNumber',
    VoyageNumber = 'VoyageNumber',
    WorkOrderNumber = 'WorkOrderNumber',
    BookingNumber = 'BookingNumber',
    PickupReferenceNumber = 'PickupReferenceNumber',
    DropoffReferenceNumber = 'DropoffReferenceNumber',
    CarrierAssignedReferenceNumber = 'CarrierAssignedReferenceNumber',
    TractorNumber = 'TractorNumber',
    TrailerNumber = 'TrailerNumber',
    FreightProviderReferenceNumber = 'FreightProviderReferenceNumber'
}

export enum SavedViewType {
    UserView = 'UserView',
    TransportationSharedView = 'TransportationSharedView',
    AssignedView = 'AssignedView',
    SharedOrderView = 'SharedOrderView',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    CalendarView = 'CalendarView'
}

export enum ShareType {
    OperatorReadOnly = 'OperatorReadOnly',
    CustomerReadOnly = 'CustomerReadOnly',
    Shipment = 'Shipment',
    Stop = 'Stop',
    View = 'View',
    Unknown = 'Unknown'
}

/** Contains list of Shipment Statuses for the Shipment. */
export enum ShipmentStatus {
    Accepted = 'Accepted',
    AtConsignee = 'AtConsignee',
    AtShipper = 'AtShipper',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
    Delivered = 'Delivered',
    Dispatched = 'Dispatched',
    EnRoute = 'EnRoute',
    InTransit = 'InTransit',
    OnHold = 'OnHold',
    Pending = 'Pending'
}

export enum SideSheetType {
    Filters,
    Views,
    UserSettings
}

export enum SortDirection {
    Ascending = 'ascending',
    Descending = 'descending'
}

/** Contains list of Stop types for the Shipment. */
export enum StopType {
    Origin = 'Origin',
    Intermediate = 'Intermediate',
    Destination = 'Destination'
}

export enum TemperatureUnit {
    Fahrenheit = 'Fahrenheit',
    Celsius = 'Celsius'
}

export enum TimePriorityType {
    Appointment = 'Appointment',
    Scheduled = 'Scheduled'
}

export enum VolumeUnit {
    Gallons = 'Gallons',
    Liters = 'Liters',
    CubicFeet = 'CubicFeet'
}

/** Contains list of Weather Severity for the Shipment. */
export enum WeatherAlertSeverity {
    Severe = 'Severe',
    Moderate = 'Moderate',
    Minor = 'Minor',
    Unknown = 'Unknown'
}

export enum WeightUnit {
    Pounds = 'Pounds',
    Kilograms = 'Kilograms',
    MetricTons = 'MetricTons',
    ShortTons = 'ShortTons'
}
