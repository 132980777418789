import React from 'react';
import { Typography } from '@mui/material';
import ErrorPage from './errorPage';

const PageNotFound = (): JSX.Element => {
    return (
        <ErrorPage errorHeaderText='Page Not Found Error'>
            <Typography data-qa='pageNotFoundError'>
                We are unable to locate this page.
            </Typography>
        </ErrorPage>
    );
};

export default PageNotFound;
