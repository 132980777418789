import { pathToRegexp } from 'path-to-regexp';
import { toast } from 'react-toastify';
import { addDays } from 'date-fns';

import ApiService from '../services/apiService';
import Endpoints from '../services/endpoints';
import { GenericApiResponse } from '../interfaces/services';

export const routeComparator = (routeList: string[], routeToCompare: string): boolean => {
    return routeList.some((route) => {
        const routeRegex = pathToRegexp(route);
        return routeRegex.test(routeToCompare);
    });
};

/**
 * Based off the below parameters this function will call the urlShortener api and construct a share URL from the response or default the longer url based off the parameters.
 * Then it copies the url into the clipboard and displays a success toast
 * @param urlShortenerApi the api endpoint for the url shortener.
 * @param pageUrl the url of the page being shared.
 * @param token the token passed in the url used for authorization when sharing.
 * @param successMessage the message to display in the toast. Defaults to 'Share url copied to clipboard.'
 * @param shortenedUrlName string or null passed to the api to help identify the url. Optional and defaults to null.
 * @param expiryDays number or null passed to the api to determine how many days from the present the short URL will expire. Optional and defaults to null.
 */
export const handleShareUrlCopy = async ({
    urlShortenerApi,
    pageUrl,
    token,
    successMessage = 'Share url copied to clipboard.',
    shortenedUrlName = null,
    expiryDays = null
}: {
    urlShortenerApi: string;
    pageUrl: string;
    token: string;
    successMessage?: string;
    shortenedUrlName?: string | null;
    expiryDays?: number | null;
}): Promise<void> => {
    let shareURL = `${window.location.origin}${pageUrl}?access_token=${token}`;

    const expirationDate = expiryDays != null ? addDays(new Date().getTime(), expiryDays) : null;
    const copyToClipboard = (url: string): void => {
        navigator.clipboard.writeText(url).then(() => {
            toast.success(successMessage);
        }, () => {
            toast.error('The url failed to copy to the clipboard');
            console.log('Share url', url);
        });
    };

    // README: sadly the below conditions are not enough to guarantee the browser is safari
    // regex that excludes Chrome, Edge, and all Android browsers from the user agent and checks the vendor is Apple
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
        // if we think it is safari, return the full url. This option seems safer than the ClipboardItem hack specific to safari
        copyToClipboard(shareURL);
    } else {
        // All other browsers make a request to the url shortener api and then copy to the clipboard
        try {
            const { data } = await ApiService.post({
                url: `${urlShortenerApi}${Endpoints.urlShortenerApi.shortenUrl}`,
                body: {
                    url: shareURL,
                    expiration: expirationDate,
                    ...shortenedUrlName && { title: shortenedUrlName }
                }
            }) as GenericApiResponse<{ longUrl: string; shortUrl: string; title: string; }>;
            shareURL = data[0].shortUrl;
        } catch (error) {
            console.error('Url shortener api failed');
        }

        copyToClipboard(shareURL);
    }
};
