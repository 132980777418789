import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Chip, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';

import { useTypedSelector } from '../../redux';
import { deleteNotification, fetchNotificationDetails } from '../../redux/notifications';
import { NotificationData } from '../../interfaces/services/notifications';
import { ShareType } from '../../helpers/enums';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { addSpaceBeforeUppercaseCharacter, capitalizeFirstLetter } from '../../helpers/dataUtils';
import UnsavedChangesDialog from '../dialogs/unsavedChangesDialog';
import DeleteConfirmationDialog from '../dialogs/deleteConfirmationDialog';

const classesPrefix = 'notificationCard';

const classes = {
    cardContainer: `${classesPrefix}-cardContainer`,
    cardWrapper: `${classesPrefix}-cardWrapper`,
    cardSelected: `${classesPrefix}-cardSelected`,
    updateWrapper: `${classesPrefix}-updateWrapper`,
    updateContainer: `${classesPrefix}-updateContainer`,
    rightSideWrapper: `${classesPrefix}-rightSideWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.cardContainer}`]: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-of-type': {
                borderBottom: 0
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.action.hover
            }
        },
        [`&.${classes.cardSelected}`]: {
            backgroundColor: theme.palette.action.selected
        },
        [`& .${classes.cardWrapper}`]: {
            padding: '8px 16px'
        },
        [`& .${classes.updateWrapper}`]: {
            color: theme.palette.text.secondary,
            fontSize: '12px'
        },
        [`& .${classes.updateContainer}`]: {
            alignSelf: 'center'
        },
        [`& .${classes.rightSideWrapper}`]: {
            textAlign: 'right'
        }
    };
});

const NotificationCard = ({
    notification: {
        notificationGuid,
        notificationName,
        notificationRecipientType,
        notificationTypeLabel,
        lastUpdateDate
    },
    isFormDirty,
    handleCardClick
}: {
    notification: NotificationData;
    isFormDirty: boolean;
    handleCardClick: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [unsavedChangesDialogIsOpen, setUnsavedChangesDialogIsOpen] = useState(false);
    const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] = useState(false);

    const skip = useTypedSelector((state) => { return state.notifications.skip; });
    const searchTerm = useTypedSelector((state) => { return state.notifications.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.notifications.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.notifications.sortDirection; });
    const notificationDetails = useTypedSelector((state) => { return state.notifications.notificationDetails; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const handleNotificationCardClick = (): void => {
        if (isFormDirty) {
            setUnsavedChangesDialogIsOpen(true);
        } else if (notificationGuid !== notificationDetails.notificationGuid) {
            handleCardClick();
            dispatch(fetchNotificationDetails(notificationGuid));
        }
    };

    const handleDeleteClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        setDeleteConfirmationDialogIsOpen(true);
    };

    return (
        <Fragment>
            <StyledGrid
                container
                data-qa='notificationSummaryCard-container'
                className={`${classes.cardContainer} ${notificationGuid === notificationDetails.notificationGuid ? classes.cardSelected : ''}`}
                alignItems='center'
                onClick={handleNotificationCardClick}
            >
                <Grid item xs={12} className={classes.cardWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Typography data-qa='notificationName' noWrap={true}>
                                {notificationName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.rightSideWrapper}>
                            <Typography data-qa='notificationRecipientType'>
                                {capitalizeFirstLetter(addSpaceBeforeUppercaseCharacter(notificationRecipientType))}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} data-qa='notificationTypeLabel-container'>
                            {
                                notificationTypeLabel &&
                                <Chip label={capitalizeFirstLetter(addSpaceBeforeUppercaseCharacter(notificationTypeLabel))} />
                            }
                        </Grid>
                        <Grid item xs={8} className={classes.updateContainer}>
                            <Typography className={classes.updateWrapper} data-qa='notificationLastUpdated'>
                                {`Last updated ${zonedDateTimeToDisplay(lastUpdateDate)}`}
                            </Typography>
                        </Grid>
                        {
                            shareType !== ShareType.OperatorReadOnly &&
                            <Grid item xs={4} className={classes.rightSideWrapper}>
                                <Tooltip title='Delete'>
                                    <IconButton
                                        size='small'
                                        onClick={handleDeleteClick}
                                        data-qa='deleteNotification-action'
                                    >
                                        <Icon path={mdiDelete} size={1} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </StyledGrid>
            {
                unsavedChangesDialogIsOpen &&
                <UnsavedChangesDialog
                    isOpen={unsavedChangesDialogIsOpen}
                    handleClose={(): void => {
                        setUnsavedChangesDialogIsOpen(false);
                    }}
                    handleContinue={(): void => {
                        dispatch(fetchNotificationDetails(notificationGuid));
                        setUnsavedChangesDialogIsOpen(false);
                    }}
                />
            }
            {
                deleteConfirmationDialogIsOpen &&
                <DeleteConfirmationDialog
                    isOpen={deleteConfirmationDialogIsOpen}
                    handleClose={(): void => {
                        setDeleteConfirmationDialogIsOpen(false);
                    }}
                    handleContinue={(): void => {
                        dispatch(deleteNotification({
                            notificationGuid,
                            skip,
                            searchTerm,
                            sortColumn,
                            sortDirection
                        }));
                        setDeleteConfirmationDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default NotificationCard;
