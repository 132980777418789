import React from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'filterNameChip';

const classes = {
    chipWrapper: `${classesPrefix}-chipWrapper`,
    chip: `${classesPrefix}-chip`,
    chipText: `${classesPrefix}-chipText`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.chipWrapper}`]: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'normal'
        },
        [`& .${classes.chip}`]: {
            backgroundColor: theme.palette.background.default,
            position: 'static'
        },
        [`& .${classes.chipText}`]: {
            maxWidth: '340px'
        },
        [theme.breakpoints.only('lg')]: {
            [`& .${classes.chipText}`]: {
                maxWidth: '200px'
            }
        },
        [theme.breakpoints.only('md')]: {
            [`&.${classes.chipWrapper}`]: {
                justifyContent: 'center'
            },
            [`& .${classes.chipText}`]: {
                maxWidth: '140px'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.chipWrapper}`]: {
                justifyContent: 'center'
            },
            [`& .${classes.chip}`]: {
                position: 'fixed',
                bottom: '8px',
                zIndex: 1
            },
            [`& .${classes.chipText}`]: {
                maxWidth: '80vw'
            }
        }
    };
});

const FilterNameChip = ({
    filterName,
    handleChipDelete
}: {
    /** Current filter name */
    filterName: string;
    /** Function to handle the clearing of the filter chip. */
    handleChipDelete: () => void;
}): JSX.Element => {
    return (
        <StyledGrid item xs={12} md='auto' className={classes.chipWrapper}>
            <Chip
                variant='outlined'
                label={<Typography noWrap className={classes.chipText}>{filterName}</Typography>}
                className={classes.chip}
                onDelete={(): void => {
                    handleChipDelete();
                }}
                data-qa='shipmentHeader-chip'
            />
        </StyledGrid>
    );
};

export default FilterNameChip;
