import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../redux';
import { setUserLoginStatus } from '../../../redux/user';
import Endpoints from '../../../services/endpoints';
import { ApiStatus } from '../../../helpers/enums';
import AuthService from '../../../services/authService';
import ApiService from '../../../services/apiService';
import HorizontallyCenteredSpinner from '../../loaders/horizontallyCenteredSpinner';

const Authorize = (): JSX.Element => {
    const dispatch = useDispatch();
    const MasterData = useTypedSelector((state) => { return state.availableServices.endpoints.MasterData; });

    useEffect(() => {
        (async (): Promise<void> => {
            const user = await AuthService.loginCallback();
            // eslint-disable-next-line camelcase
            if (user !== null && user?.access_token) {
                // call masterdata to hydrate the cache for filters
                ApiService.post({ url: `${MasterData}${Endpoints.masterDataApi.hydrateFilterCache}` });
                dispatch(setUserLoginStatus(ApiStatus.Success));
            } else {
                dispatch(setUserLoginStatus(ApiStatus.Failure));
            }
        })();
    }, [dispatch, MasterData]);

    return <HorizontallyCenteredSpinner />;
};

export default Authorize;
