import {
    MapSources,
    MapLayers,
    MapLayerTypes
} from '../enums';
import { isVisibleUtil } from '../mapUtils';
import { MapLayer } from './interfaces';

/**
 * Creates a layer to display a polygon on the map for each alert in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsWeatherLayerGeo = (mapSource: MapSources, isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsWeatherLayer,
        type: MapLayerTypes.Fill,
        source: mapSource,
        layout: {
            visibility
        },
        paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.75,
                0.5
            ]
        }
    };

    return layer;
};

/**
 * Creates a layer to display a polygon on the map for each alert in the given map source.
 * @param mapSource Name of the map source
 */
const getMapShipmentDetailsWeatherSelectedLayerGeo = (mapSource: MapSources, isVisible: boolean): MapLayer => {
    const visibility = isVisibleUtil(isVisible);

    const layer: MapLayer = {
        id: MapLayers.shipmentDetailsWeatherSelectedLayer,
        type: MapLayerTypes.Fill,
        source: mapSource,
        layout: {
            visibility
        },
        paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 1
        },
        filter: ['in', 'fipsCode', '']
    };

    return layer;
};

/**
 * Adds the shipment details weather layer to the map if it doesn't already exist
 * @param map Instance of your map
 * @param mapSource Name of the map source
 */
export const addShipmentDetailsWeatherLayer = (map: any, mapSource: MapSources, isVisible: boolean): void => {
    if (map.getLayer(MapLayers.shipmentDetailsWeatherLayer) === undefined) {
        const shipmentDetailsWeatherLayer = getMapShipmentDetailsWeatherLayerGeo(mapSource, isVisible);
        map.addLayer(shipmentDetailsWeatherLayer, 'labels_waterbody_detailed'); // Place polygons under labels.
    }

    if (map.getLayer(MapLayers.shipmentDetailsWeatherSelectedLayer) === undefined) {
        const shipmentDetailsWeatherSelectedLayer = getMapShipmentDetailsWeatherSelectedLayerGeo(mapSource, isVisible);
        map.addLayer(shipmentDetailsWeatherSelectedLayer, 'labels_waterbody_detailed'); // Place polygons under labels.
    }
};
