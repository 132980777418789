import React, { Fragment, useMemo, useState } from 'react';
import { Tabs, Tab } from '@mui/material';

import { useTypedSelector } from '../../redux';
import { ApplicationResourceNames, SavedViewType } from '../../helpers/enums';
import ViewsList from '../lists/viewsList';

enum ViewsTab {
    Saved,
    Shared
}

const Views = (): JSX.Element => {
    const [currentTab, setCurrentTab] = useState(ViewsTab.Saved);

    const userInformation = useTypedSelector((state) => { return state.user.userInformation; });
    const hasSharedViews = userInformation.userApplications.freightPortal.find(({ resourceKey }) => {
        return resourceKey === ApplicationResourceNames.CustomViews;
    });

    const viewFilters = useMemo((): SavedViewType[] => {
        if (currentTab === ViewsTab.Saved) {
            return [SavedViewType.UserView];
        }

        if (currentTab === ViewsTab.Shared) {
            return [SavedViewType.TransportationSharedView, SavedViewType.AssignedView];
        }

        return [];
    }, [currentTab]);

    return (
        <Fragment>
            <Tabs
                value={currentTab}
                onChange={(event, value: number): void => {
                    setCurrentTab(value);
                }}
                centered
                indicatorColor='primary'
                textColor='primary'
                data-qa='views-tabList'
            >
                <Tab
                    label='Saved Views'
                    value={ViewsTab.Saved}
                    data-qa='saved-tab'
                />
                {
                    hasSharedViews &&
                    <Tab
                        label='Shared Views'
                        value={ViewsTab.Shared}
                        data-qa='shared-tab'
                    />
                }
            </Tabs>

            <ViewsList
                viewFilters={viewFilters}
                createViewType={currentTab === ViewsTab.Saved ? SavedViewType.UserView : SavedViewType.TransportationSharedView}
            />
        </Fragment>
    );
};

export default Views;
