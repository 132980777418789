import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { mdiRefresh, mdiShareVariant } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { reopenShipment } from '../../redux/shipments';
import { ModeType, ShareType } from '../../helpers/enums';
import { handleShareUrlCopy } from '../../helpers/navigationUtils';
import ShipmentActionButton from '../buttons/shipmentActionButton';
import ShipmentDetailsIconButton from '../links/shipmentDetailsIconButton';

const ShipmentListHistoryActions = ({
    shipmentUniqueName,
    modeType,
    singleShipmentSharingToken
}: {
    /** unique identifier for the shipment */
    shipmentUniqueName: string;
    /** mode type for the shipment */
    modeType: ModeType;
    /** Token used to autenticate a shared shipment */
    singleShipmentSharingToken: string;
}): JSX.Element => {

    const theme = useTheme();
    const dispatch = useDispatch();

    const UrlShortenerApi = useTypedSelector((state) => { return state.availableServices.endpoints.UrlShortenerApi; });
    const shortUrlExpiryDays = useTypedSelector((state) => { return state.organization.organizationPreferences?.shortUrlExpiryDays; });

    const skip = useTypedSelector((state) => { return state.shipments.skip; });
    const take = useTypedSelector((state) => { return state.shipments.take; });
    const searchTerm = useTypedSelector((state) => { return state.shipments.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.shipments.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.shipments.sortDirection; });
    const filters = useTypedSelector((state) => { return state.shipments.filters; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    if (shareType === ShareType.CustomerReadOnly) {
        return (
            <Fragment>
                <ShipmentDetailsIconButton
                    modeType={modeType}
                    shipmentUniqueName={shipmentUniqueName}
                    iconColor={theme.palette.text.primary}
                    iconPadding={0}
                />
                <ShipmentActionButton
                    title='Share Shipment'
                    handleClick={(): void => {
                        handleShareUrlCopy({
                            urlShortenerApi: UrlShortenerApi,
                            pageUrl: `/shipmentDetails/${shipmentUniqueName}`,
                            token: singleShipmentSharingToken,
                            successMessage: 'Shipment share url copied to clipboard.',
                            shortenedUrlName: `Shipment ${shipmentUniqueName}`,
                            expiryDays: shortUrlExpiryDays
                        });
                    }}
                    mdiIcon={mdiShareVariant}
                    iconColor={theme.palette.text.primary}
                    iconPadding={0}
                    data-qa='shareShipment-action-open'
                />
            </Fragment>
        );
    }

    if (shareType !== null) {
        return (
            <ShipmentDetailsIconButton
                modeType={modeType}
                shipmentUniqueName={shipmentUniqueName}
                iconColor={theme.palette.text.primary}
                iconPadding={0}
            />
        );
    }

    return (
        <Fragment>
            <ShipmentActionButton
                title='Reopen'
                handleClick={(): void => {
                    dispatch(reopenShipment({
                        shipmentUniqueName,
                        skip,
                        take,
                        searchTerm,
                        sortColumn,
                        sortDirection,
                        filters
                    }));
                }}
                data-qa='reopen-action'
                mdiIcon={mdiRefresh}
                iconColor={theme.palette.text.primary}
                iconPadding={0}
            />
            <ShipmentActionButton
                title='Share Shipment'
                handleClick={(): void => {
                    handleShareUrlCopy({
                        urlShortenerApi: UrlShortenerApi,
                        pageUrl: `/shipmentDetails/${shipmentUniqueName}`,
                        token: singleShipmentSharingToken,
                        successMessage: 'Shipment share url copied to clipboard.',
                        shortenedUrlName: `Shipment ${shipmentUniqueName}`,
                        expiryDays: shortUrlExpiryDays
                    });
                }}
                data-qa='shareShipment-action-open'
                mdiIcon={mdiShareVariant}
                iconColor={theme.palette.text.primary}
                iconPadding={0}
            />
            <ShipmentDetailsIconButton
                modeType={modeType}
                shipmentUniqueName={shipmentUniqueName}
                iconColor={theme.palette.text.primary}
                iconPadding={0}
            />
        </Fragment>
    );
};

export default ShipmentListHistoryActions;
