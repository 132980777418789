import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { GenericApiResponse } from '../../interfaces/services';
import { ViewOverview, ViewSortColumns } from '../../interfaces/services/views';
import { ApiStatus, SavedViewType, SortDirection } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';

const VIEWS_LIST_REQUEST = 'VIEWS_LIST_REQUEST';
const VIEWS_LIST_SUCCESS = 'VIEWS_LIST_SUCCESS';
const VIEWS_LIST_FAILURE = 'VIEWS_LIST_FAILURE';
const DELETE_VIEW_REQUEST = 'DELETE_VIEW_REQUEST';
const DELETE_VIEW_FAILURE = 'DELETE_VIEW_FAILURE';

interface ViewsListRequestPayload {
    skip: number;
    take: number;
    searchTerm: string;
    sortDirection: SortDirection;
    sortColumn: ViewSortColumns;
    viewFilters: SavedViewType[];
}

interface ViewsData extends ViewsListRequestPayload {
    viewsListStatus: ApiStatus;
    viewsList: ViewOverview[];
    totalRecords: number;
}

const requestViewsList = (json: ViewsListRequestPayload): GenericAction<typeof VIEWS_LIST_REQUEST, ViewsListRequestPayload> => {
    return {
        type: VIEWS_LIST_REQUEST,
        payload: json
    };
};

const receiveViewsList = (json: GenericApiResponse<ViewOverview>): GenericAction<typeof VIEWS_LIST_SUCCESS, GenericApiResponse<ViewOverview>> => {
    return {
        type: VIEWS_LIST_SUCCESS,
        payload: json
    };
};

const requestViewsListFailed = (): Action<typeof VIEWS_LIST_FAILURE> => {
    return {
        type: VIEWS_LIST_FAILURE
    };
};

export const fetchViewsList = ({
    skip = 0,
    take = 100,
    searchTerm = '',
    sortColumn = 'customViewName',
    sortDirection = SortDirection.Ascending,
    viewFilters
}: {
    skip?: number;
    take?: number;
    searchTerm?: string;
    sortColumn?: ViewSortColumns;
    sortDirection?: SortDirection;
    viewFilters: SavedViewType[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const requestBody: ViewsListRequestPayload = {
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection,
            viewFilters
        };
        dispatch(requestViewsList(requestBody));

        const queryString = new URLSearchParams({
            skip: skip.toString(),
            take: take.toString(),
            searchTerm,
            sortColumn,
            sortDirection,
            viewFilters: viewFilters.toString()
        });

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.viewsList}?${queryString.toString()}` }) as GenericApiResponse<ViewOverview>;
            dispatch(receiveViewsList(json));
        } catch (err) {
            dispatch(requestViewsListFailed());
            toast.error('Error occurred while fetching views list.');
        }
    };
};

const requestDeleteView = (): Action<typeof DELETE_VIEW_REQUEST> => {
    return {
        type: DELETE_VIEW_REQUEST
    };
};

const requestDeleteViewFailed = (): Action<typeof DELETE_VIEW_FAILURE> => {
    return {
        type: DELETE_VIEW_FAILURE
    };
};

export const deleteView = ({
    customViewGuid,
    // below params are used to refetch the views list after the view is deleted
    skip,
    take,
    searchTerm,
    sortColumn,
    sortDirection,
    viewFilters
}: {
    customViewGuid: string;
    skip: number;
    take: number;
    searchTerm: string;
    sortColumn: ViewSortColumns;
    sortDirection: SortDirection;
    viewFilters: SavedViewType[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestDeleteView());

        try {
            await ApiService.delete({ url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.deleteView}/${customViewGuid}` });
            dispatch(fetchViewsList({
                skip,
                take,
                searchTerm,
                sortColumn,
                sortDirection,
                viewFilters
            }));
            toast.success('View successfully deleted.');
        } catch (error) {
            dispatch(requestDeleteViewFailed());
            toast.error('Failed to delete view.');
        }
    };
};

type ViewsActionTypes = ReturnType<typeof requestViewsList> | ReturnType<typeof receiveViewsList> | ReturnType<typeof requestViewsListFailed> |
    ReturnType<typeof requestDeleteView> | ReturnType<typeof requestDeleteViewFailed>;

export const viewsReducer = (viewsData: ViewsData = {
    viewsListStatus: ApiStatus.Idle,
    viewsList: [],
    totalRecords: 0,
    skip: 0,
    take: 100,
    sortDirection: SortDirection.Ascending,
    sortColumn: 'customViewName',
    searchTerm: '',
    viewFilters: []
}, action: ViewsActionTypes): ViewsData => {
    switch (action.type) {
        case VIEWS_LIST_REQUEST: {
            return {
                ...viewsData,
                viewsListStatus: ApiStatus.Loading,
                totalRecords: 0,
                skip: action.payload.skip,
                searchTerm: action.payload.searchTerm,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection,
                viewFilters: action.payload.viewFilters
            };
        }
        case VIEWS_LIST_SUCCESS: {
            return {
                ...viewsData,
                viewsListStatus: ApiStatus.Success,
                viewsList: action.payload.data,
                totalRecords: action.payload.totalPossibleCount
            };
        }
        case VIEWS_LIST_FAILURE: {
            return {
                ...viewsData,
                viewsListStatus: ApiStatus.Failure,
                viewsList: []
            };
        }
        case DELETE_VIEW_REQUEST: {
            return {
                ...viewsData,
                viewsListStatus: ApiStatus.Idle
            };
        }
        case DELETE_VIEW_FAILURE: {
            // If deleting a view fails a snackbar is shown but the loader state needs to end to redisplay the views list
            return {
                ...viewsData,
                viewsListStatus: ApiStatus.Success
            };
        }
        default:
            return viewsData;
    }
};
