import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { toast } from 'react-toastify';
import { Report as PowerBiReport } from 'powerbi-report-component';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { fetchAvailableWorkspaces, fetchReportsList, fetchReportToken } from '../../redux/analytics';
import { AnalyticsWorkspace, AnalyticsReport } from '../../interfaces/services/organization';
import FullScreenOverlaySpinner from '../loaders/fullScreenOverlaySpinner';
import ErrorPage from '../errors/errorPage';

const classesPrefix = 'analyticsPage';

const classes = {
    dialogTitle: `${classesPrefix}-dialogTitle`,
    reportsListContainer: `${classesPrefix}-reportsListContainer`,
    analyticsContainer: `${classesPrefix}-analyticsContainer`,
    reportsList: `${classesPrefix}-reportsList`
};

const StyledDialog = styled(Dialog)(({ theme }) => {
    return {
        [`& .${classes.dialogTitle}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: 0
        }
    };
});

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.reportsListContainer}`]: {
            height: 'calc(100vh - 48px)',
            overflowY: 'scroll'
        },
        [`& .${classes.analyticsContainer}`]: {
            height: 'calc(100vh - 92px)'
        }
    };
});

const StyledList = styled(List)(() => {
    return {
        [`&.${classes.reportsList}`]: {
            paddingTop: 0,
            cursor: 'pointer'
        }
    };
});

const AnalyticsPage = (): JSX.Element => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState<AnalyticsWorkspace | null>(null);
    const [selectedReport, setSelectedReport] = useState<AnalyticsReport | null>(null);

    const workspacesList = useTypedSelector((state) => { return state.analytics.workspacesList; });
    const workspacesListStatus = useTypedSelector((state) => { return state.analytics.workspacesListStatus; });

    const reportsList = useTypedSelector((state) => { return state.analytics.reportsList; });
    const reportsListStatus = useTypedSelector((state) => { return state.analytics.reportsListStatus; });

    const reportTokenStatus = useTypedSelector((state) => { return state.analytics.reportTokenStatus; });
    const reportToken = useTypedSelector((state) => { return state.analytics.reportToken; });

    // fetch the list of available workspaces
    useEffect((): void => {
        dispatch(fetchAvailableWorkspaces());
    }, [dispatch]);

    // after we have the list of workspaces setSelectedWorkspace to the first workspace
    useEffect((): void => {
        if (workspacesListStatus === ApiStatus.Success && workspacesList.length > 0) {
            setSelectedWorkspace(workspacesList[0]);
        }
    }, [dispatch, workspacesListStatus, workspacesList]);

    // after we get the workspaces back and when the selectedWorkspace changes fetch the reports for a selectedWorkspace
    useEffect((): void => {
        if (selectedWorkspace) {
            dispatch(fetchReportsList(selectedWorkspace.powerBiworkspaceGUID));
        }
    }, [dispatch, selectedWorkspace]);

    // after we have the list of reports setSelectedReport to the first report
    useEffect((): void => {
        if (reportsListStatus === ApiStatus.Success && reportsList.length > 0) {
            setSelectedReport(reportsList[0]);
        }
    }, [dispatch, reportsListStatus, reportsList]);

    // when the selectedReport changes fetch the token for the reports
    useEffect((): void => {
        if (selectedWorkspace && selectedReport) {
            dispatch(fetchReportToken(selectedReport.id, selectedWorkspace.powerBiworkspaceGUID));
        }
    }, [dispatch, selectedReport, selectedWorkspace]);

    useEffect((): void => {
        setIsMobileDialogOpen(isMobile);
    }, [isMobile]);

    if ((workspacesListStatus === ApiStatus.Idle || workspacesListStatus === ApiStatus.Loading ||
        reportsListStatus === ApiStatus.Idle || reportsListStatus === ApiStatus.Loading ||
        reportTokenStatus === ApiStatus.Idle || reportTokenStatus === ApiStatus.Loading) &&
        workspacesListStatus !== ApiStatus.Failure && reportsListStatus !== ApiStatus.Failure && reportTokenStatus !== ApiStatus.Failure
    ) {
        return <FullScreenOverlaySpinner open={true} />;
    }

    if (workspacesListStatus === ApiStatus.Failure || reportsListStatus === ApiStatus.Failure || reportTokenStatus === ApiStatus.Failure) {
        return (
            <ErrorPage errorHeaderText='Error fetching report'>
                <Typography data-qa='reportError'>
                    We were unable to fetch the report. Please contact support for assistance.
                </Typography>
            </ErrorPage>
        );
    }

    const renderReportsList = (): JSX.Element => {
        return (
            <Fragment>
                {
                    selectedWorkspace &&
                    <StyledList className={classes.reportsList}>
                        {
                            reportsList.map((report) => {
                                return (
                                    <ListItem
                                        key={report.id}
                                        selected={selectedReport?.id ? report.id === selectedReport.id : false}
                                        divider={true}
                                        onClick={(): void => {
                                            setSelectedReport(report);
                                            if (isMobile) {
                                                setIsMobileDialogOpen(true);
                                            }
                                        }}
                                    >
                                        <ListItemText primary={report.name} />
                                    </ListItem>
                                );
                            })
                        }
                    </StyledList>
                }
            </Fragment>
        );
    };

    const renderAnalytics = (): JSX.Element => {
        return (
            <Fragment>
                {
                    selectedWorkspace &&
                    <Tabs
                        value={selectedWorkspace.powerBiworkspaceGUID}
                        onChange={(event, newValue): void => {
                            const newWorkspace = workspacesList.find((workspace) => {
                                return workspace.powerBiworkspaceGUID === newValue;
                            });
                            if (newWorkspace) {
                                setSelectedWorkspace(newWorkspace);
                                setSelectedReport(null);
                            } else {
                                toast.error('Unable to update workspace.');
                            }

                        }}
                        indicatorColor='primary'
                        textColor='primary'
                        centered={true}
                    >
                        {
                            workspacesList.map((workspace) => {
                                return (
                                    <Tab
                                        key={workspace.powerBiworkspaceGUID}
                                        label={workspace.powerBiworkspaceName}
                                        value={workspace.powerBiworkspaceGUID}
                                        disabled={workspacesList.length === 1}
                                    />
                                );
                            })
                        }
                    </Tabs>
                }
                {
                    selectedWorkspace && selectedReport &&
                    <PowerBiReport
                        reportMode='View'
                        tokenType='Embed'
                        accessToken={reportToken}
                        embedUrl={selectedReport.embedUrl}
                        embedId={selectedReport.id}
                        permissions='Read'
                        style={{ height: '99%' }}
                    />
                }
            </Fragment>
        );
    };

    if (isMobile) {
        if (isMobileDialogOpen) {
            return (
                <StyledDialog
                    fullScreen
                    open={isMobileDialogOpen}
                    onClose={(): void => { setIsMobileDialogOpen(false); }}
                >
                    <DialogTitle className={classes.dialogTitle}>
                        <IconButton
                            onClick={(): void => {
                                setIsMobileDialogOpen(false);
                            }}
                        >
                            <Icon
                                path={mdiClose}
                                size={1}
                                color={theme.palette.common.white}
                            />
                        </IconButton>
                    </DialogTitle>
                    {renderAnalytics()}
                </StyledDialog>
            );
        }

        return renderReportsList();
    }

    return (
        <StyledGrid container>
            <Grid item xs={12} md={4} lg={2} className={classes.reportsListContainer}>
                {renderReportsList()}
            </Grid>
            <Grid item xs={12} md={8} lg={10} className={classes.analyticsContainer}>
                {renderAnalytics()}
            </Grid>
        </StyledGrid>
    );
};

export default AnalyticsPage;
