import React, {
    Fragment,
    useEffect,
    useReducer,
    useState
} from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import {
    Button,
    Grid,
    FormControl,
    FormGroup,
    FormLabel,
    FormHelperText,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Endpoints from '../../services/endpoints';
import { GenericAction, useTypedSelector } from '../../redux';
import { updateCalendarFilters } from '../../redux/calendar';
import { Filter } from '../../interfaces/services/shipment';
import {
    INITIALIZE_FILTERS,
    UPDATE_FILTER,
    UPDATE_FILTER as UPDATE_MORE_FILTERS
} from '../../interfaces/filterInterfaces';
import {
    UPDATE_DELIVERY_STATUS_FILTERS,
    UPDATE_STOP_TYPE_FILTERS,
    CalendarFilterState,
    calendarFilterInitialState
} from '../../interfaces/calendarFilterInterfaces';
import {
    AllowedFilterPropertyName,
    DeliveryStatus,
    CalendarStopType,
    CalendarDateType
} from '../../helpers/enums';
import {
    deliveryStatusOptions,
    shipmentStatusOptions,
    modeTypeOptions,
    weatherSeverityOptions,
    hotShipmentOptions,
    calendarStopTypeOptions,
    calendarDateTypeOptions
} from '../../helpers/hardcodedOptionLists';

import AsyncCreatable from '../selects/asyncCreatable';
import AsyncMultiSelect from '../selects/asyncMultiSelect';
import MultiSelect from '../selects/multiSelect';
import CalendarFiltersCheckbox from '../checkboxes/calendarFiltersCheckbox';

const classesPrefix = 'calendarFilters';

const classes = {
    filtersContainer: `${classesPrefix}-filtersContainer`,
    actionsContainer: `${classesPrefix}-actionsContainer`,
    formControl: `${classesPrefix}-formControl`,
    filterGroupLabelWrapper: `${classesPrefix}-filterGroupLabelWrapper`,
    filterGroupLabel: `${classesPrefix}-filterGroupLabel`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.filtersContainer}`]: {
            flexGrow: 1,
            overflowY: 'auto',
            padding: '8px 8px 8px'
        },
        [`& .${classes.formControl}`]: {
            marginTop: '24px'
        },
        [`& .${classes.filterGroupLabelWrapper}`]: {
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            width: '100%'
        },
        [`& .${classes.filterGroupLabel}`]: {
            flexGrow: 1
        }
    };
});

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.actionsContainer}`]: {
            padding: '8px 16px',
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper
        }
    };
});

const CalendarFilters = (): JSX.Element => {
    const dispatch = useDispatch();

    const MasterData = useTypedSelector((state) => { return state.availableServices.endpoints.MasterData; });

    const filters = useTypedSelector((state) => { return state.calendar.filters; });
    const dateType = useTypedSelector((state) => { return state.calendar.dateType; });
    const isStopTypeDelivery = useTypedSelector((state) => { return state.calendar.isStopTypeDelivery; });
    const isStopTypePickup = useTypedSelector((state) => { return state.calendar.isStopTypePickup; });

    const [draftDateType, setDraftDateType] = useState(dateType);

    const reducer = (
        state: CalendarFilterState,
        action: Action<typeof INITIALIZE_FILTERS> |
            GenericAction<typeof UPDATE_FILTER, { filterId: AllowedFilterPropertyName; filterValues: string[]; }> |
            GenericAction<typeof UPDATE_STOP_TYPE_FILTERS, { checkboxId: CalendarStopType; isChecked: boolean; }> |
            GenericAction<typeof UPDATE_DELIVERY_STATUS_FILTERS, { checkboxId: DeliveryStatus; isChecked: boolean; }>
    ): CalendarFilterState => {
        switch (action.type) {
            case UPDATE_STOP_TYPE_FILTERS: {
                return {
                    ...state,
                    calendarStopTypes: {
                        ...state.calendarStopTypes,
                        [action.payload.checkboxId]: action.payload.isChecked
                    }
                };
            }
            case UPDATE_DELIVERY_STATUS_FILTERS: {
                return {
                    ...state,
                    deliveryStatuses: {
                        ...state.deliveryStatuses,
                        [action.payload.checkboxId]: action.payload.isChecked
                    }
                };
            }
            case UPDATE_MORE_FILTERS: {
                return {
                    ...state,
                    moreFilters: {
                        ...state.moreFilters,
                        [action.payload.filterId]: action.payload.filterValues
                    }
                };
            }
            case INITIALIZE_FILTERS: {
                return calendarFilterInitialState;
            }
            default: {
                return state;
            }
        }
    };

    const [filterState, localDispatch] = useReducer(reducer, calendarFilterInitialState);

    const updateMoreFiltersState = (filterId: string, filterValues: string[]): void => {
        localDispatch({
            type: UPDATE_MORE_FILTERS,
            payload: {
                filterId: filterId as AllowedFilterPropertyName,
                filterValues
            }
        });
    };

    const updateStopTypeCheckboxState = (checkboxId: CalendarStopType, isChecked: boolean): void => {
        localDispatch({
            type: UPDATE_STOP_TYPE_FILTERS,
            payload: {
                checkboxId,
                isChecked
            }
        });
    };

    const updateDeliveryStatusCheckboxState = (checkboxId: DeliveryStatus, isChecked: boolean): void => {
        localDispatch({
            type: UPDATE_DELIVERY_STATUS_FILTERS,
            payload: {
                checkboxId,
                isChecked
            }
        });
    };

    useEffect((): void => {
        setDraftDateType(dateType);
    }, [dateType]);

    useEffect((): void => {
        // if the redux filters are empty, empty the local filter state
        if (filters.length === 0) {
            localDispatch({
                type: INITIALIZE_FILTERS
            });
        } else {
            filters.forEach((filter): void => {
                if (filter.propertyName === AllowedFilterPropertyName.DeliveryStatus) {

                    // Update the delivery status checkboxes
                    if (filter.propertyValues.length !== 0) {
                        Object.values(DeliveryStatus).forEach((status) => {
                            updateDeliveryStatusCheckboxState(status, filter.propertyValues.includes(status));
                        });
                    }
                } else {
                    updateMoreFiltersState(filter.propertyName, filter.propertyValues);
                }
            });
        }

        updateStopTypeCheckboxState(CalendarStopType.Pickup, isStopTypePickup);
        updateStopTypeCheckboxState(CalendarStopType.Delivery, isStopTypeDelivery);
    }, [filters, isStopTypePickup, isStopTypeDelivery]);

    const createFilterList = (): Filter[] => {
        const filterList = Object.entries(filterState.moreFilters)
            .reduce((accumulator: Filter[], [filterName, filterValues]): Filter[] => {
                if (filterValues.length > 0) {
                    accumulator.push({
                        propertyName: filterName as AllowedFilterPropertyName,
                        propertyValues: filterValues
                    });
                }

                return accumulator;
            }, []);

        // If any delivery status checkboxes are unchecked, create an array from the checked values
        if (Object.values(filterState.deliveryStatuses).includes(false)) {
            filterList.push(
                {
                    propertyName: AllowedFilterPropertyName.DeliveryStatus,
                    propertyValues: Object.keys(filterState.deliveryStatuses).filter((key) => {
                        return filterState.deliveryStatuses[key as DeliveryStatus];
                    })
                }
            );
        }

        return filterList;
    };

    const handleResetClick = (): void => {
        localDispatch({
            type: INITIALIZE_FILTERS
        });
        setDraftDateType(CalendarDateType.Scheduled);
    };

    const handleApplyClick = (): void => {
        const newFilters = createFilterList();
        const calendarFilters = {
            filters: newFilters,
            filtersName: newFilters.length > 0 ||
                !filterState.calendarStopTypes.Delivery ||
                !filterState.calendarStopTypes.Pickup ||
                draftDateType !== CalendarDateType.Scheduled
                ? 'Filtered'
                : '',
            dateType: draftDateType,
            isStopTypePickup: filterState.calendarStopTypes.Pickup,
            isStopTypeDelivery: filterState.calendarStopTypes.Delivery
        };

        dispatch(updateCalendarFilters(calendarFilters));
    };

    const hasStatusCheckboxError = !Object.values(filterState.deliveryStatuses).includes(true);
    const hasTypeCheckboxError = !Object.values(filterState.calendarStopTypes).includes(true);

    return (
        <Fragment>
            <StyledDiv className={classes.filtersContainer} data-qa='filters-container'>
                <FormControl fullWidth variant='filled'>
                    <InputLabel id='dateType-select-label'>Date Type</InputLabel>
                    <Select
                        labelId='dateType-select-label'
                        value={draftDateType}
                        fullWidth
                        onChange={(event): void => {
                            setDraftDateType(event.target.value as CalendarDateType);
                        }}
                        inputProps={{
                            'data-qa': 'dateType-input'
                        }}
                    >
                        {
                            calendarDateTypeOptions.map((option) => {
                                return (
                                    <MenuItem
                                        key={option.filterValue}
                                        value={option.filterValue}
                                        data-qa={`dateType-item-${option.filterValue}`}
                                    >
                                        {option.label}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl fullWidth className={classes.formControl} component='fieldset'>
                    <FormLabel component='legend' className={classes.filterGroupLabelWrapper}>
                        <span className={classes.filterGroupLabel}>Stop Type</span>
                    </FormLabel>
                    <FormGroup>
                        {
                            calendarStopTypeOptions.map((option) => {
                                return (
                                    <CalendarFiltersCheckbox
                                        key={option.filterValue}
                                        id={option.filterValue}
                                        isChecked={filterState.calendarStopTypes[option.filterValue]}
                                        label={option.label}
                                        handleCheckboxChange={(isChecked): void => {
                                            updateStopTypeCheckboxState(option.filterValue, isChecked);
                                        }}
                                        data-qa={`type-checkbox-${option.filterValue}`}
                                    />
                                );
                            })
                        }
                    </FormGroup>
                </FormControl>
                {
                    hasTypeCheckboxError &&
                    <FormHelperText error>Please select at least one stop type</FormHelperText>
                }

                <FormControl fullWidth className={classes.formControl} component='fieldset'>
                    <FormLabel component='legend' className={classes.filterGroupLabelWrapper}>
                        <span className={classes.filterGroupLabel}>Delivery Status</span>
                    </FormLabel>
                    <FormGroup>
                        {
                            deliveryStatusOptions.map((option) => {
                                return (
                                    <CalendarFiltersCheckbox
                                        key={option.filterValue}
                                        id={option.filterValue}
                                        isChecked={filterState.deliveryStatuses[option.filterValue]}
                                        label={option.label}
                                        handleCheckboxChange={(isChecked): void => {
                                            updateDeliveryStatusCheckboxState(option.filterValue, isChecked);
                                        }}
                                        data-qa={`status-checkbox-${option.filterValue}`}
                                    />
                                );
                            })
                        }
                    </FormGroup>
                </FormControl>
                {
                    hasStatusCheckboxError &&
                    <FormHelperText error>Please select at least one status</FormHelperText>
                }

                <AsyncCreatable
                    id={AllowedFilterPropertyName.Customers}
                    label='Customers'
                    filterName='ProductCustomer'
                    values={filterState.moreFilters[AllowedFilterPropertyName.Customers]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Partners}
                    label='Carrier'
                    filterName='CarrierName'
                    values={filterState.moreFilters[AllowedFilterPropertyName.Partners]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Products}
                    label='Products'
                    filterName='ProductLine'
                    values={filterState.moreFilters[AllowedFilterPropertyName.Products]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Brand}
                    label='Brand/Business Unit'
                    filterName='ProductBrand'
                    values={filterState.moreFilters[AllowedFilterPropertyName.Brand]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Sku}
                    label='SKU/Commodity'
                    filterName='Sku'
                    values={filterState.moreFilters[AllowedFilterPropertyName.Sku]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncMultiSelect
                    id={AllowedFilterPropertyName.EquipmentType}
                    values={filterState.moreFilters[AllowedFilterPropertyName.EquipmentType]}
                    handleChange={updateMoreFiltersState}
                    url={`${MasterData}${Endpoints.masterDataApi.equipmentType}`}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Origin}
                    label='Origin'
                    filterName='OriginDisplayfreightprovider'
                    values={filterState.moreFilters[AllowedFilterPropertyName.Origin]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.Destination}
                    label='Destination'
                    filterName='DestinationDisplayfreightprovider'
                    values={filterState.moreFilters[AllowedFilterPropertyName.Destination]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.InboundLocation}
                    label='Inbound Location'
                    filterName='InboundLocation'
                    values={filterState.moreFilters[AllowedFilterPropertyName.InboundLocation]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.OutboundLocation}
                    label='Outbound Location'
                    filterName='OutboundLocation'
                    values={filterState.moreFilters[AllowedFilterPropertyName.OutboundLocation]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.DestinationState}
                    label='To State'
                    filterName='DestinationStateCodefreightprovider'
                    values={filterState.moreFilters[AllowedFilterPropertyName.DestinationState]}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.OriginState}
                    label='From State'
                    filterName='OriginStateCodefreightprovider'
                    values={filterState.moreFilters[AllowedFilterPropertyName.OriginState]}
                    handleChange={updateMoreFiltersState}
                />

                <MultiSelect
                    id={AllowedFilterPropertyName.ShipmentStatus}
                    label='Shipment Status'
                    values={filterState.moreFilters[AllowedFilterPropertyName.ShipmentStatus]}
                    availableOptions={shipmentStatusOptions}
                    isLoading={false}
                    handleChange={updateMoreFiltersState}
                />
                <AsyncMultiSelect
                    id={AllowedFilterPropertyName.OrderExceptions}
                    values={filterState.moreFilters[AllowedFilterPropertyName.OrderExceptions]}
                    handleChange={updateMoreFiltersState}
                    url={`${MasterData}${Endpoints.masterDataApi.orderExceptions}`}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.ModeType}
                    label='Mode Type'
                    values={filterState.moreFilters[AllowedFilterPropertyName.ModeType]}
                    availableOptions={modeTypeOptions}
                    isLoading={false}
                    handleChange={updateMoreFiltersState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.WeatherSeverity}
                    label='Weather Severity'
                    values={filterState.moreFilters[AllowedFilterPropertyName.WeatherSeverity]}
                    availableOptions={weatherSeverityOptions}
                    isLoading={false}
                    handleChange={updateMoreFiltersState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.IsPriorityShipment}
                    label='Hot Shipments'
                    values={filterState.moreFilters[AllowedFilterPropertyName.IsPriorityShipment]}
                    availableOptions={hotShipmentOptions}
                    isLoading={false}
                    handleChange={updateMoreFiltersState}
                />
            </StyledDiv>

            <StyledGrid
                container
                justifyContent='space-between'
                className={classes.actionsContainer}
            >
                <Grid item>
                    <Button size='small' onClick={handleResetClick} data-qa='resetFilters-button'>Reset</Button>
                </Grid>
                <Grid item>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        onClick={handleApplyClick}
                        disabled={hasStatusCheckboxError || hasTypeCheckboxError}
                        data-qa='applyFilters-button'
                    >
                        Apply
                    </Button>
                </Grid>
            </StyledGrid>
        </Fragment>
    );
};

export default CalendarFilters;
