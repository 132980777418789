import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    FormGroup,
    FormControlLabel,
    Switch,
    Typography,
    TextField,
    FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { editPreferencesOnTimeRules } from '../../../redux/organization';
import { PreferencesOnTimeRules } from '../../../interfaces/services/organization';
import { calculateHoursAndMinutes, calculateMinutes, validateHoursAndMinutes } from '../../../helpers/dataUtils';
import { ModeType, PreferencesOnTimeRulesFieldNames } from '../../../helpers/enums';

const classesPrefix = 'trackingLostRule';

const classes = {
    gridWrapper: `${classesPrefix}-gridWrapper`,
    textField: `${classesPrefix}-textField`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.gridWrapper}`]: {
            marginRight: '16px'
        },
        [`& .${classes.textField}`]: {
            width: '50px'
        }
    };
});

const TrackingLostRule = ({
    selectedModeType,
    handleIsRuleValidChange,
    currentOnTimeRules
}: {
    selectedModeType: ModeType;
    currentOnTimeRules: PreferencesOnTimeRules;
    handleIsRuleValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [isTrackingLostMinutesValid, setIsTrackingLostMinutesValid] = useState(true);

    const { hasTrackingLostDefined, trackingLostMinutes } = currentOnTimeRules;

    useEffect((): void => {
        const isValid = validateHoursAndMinutes(trackingLostMinutes, 1440);
        setIsTrackingLostMinutesValid(isValid);
    }, [trackingLostMinutes]);

    useEffect((): void => {
        const isRuleValid = hasTrackingLostDefined ? isTrackingLostMinutesValid : true;
        handleIsRuleValidChange(isRuleValid);
    }, [handleIsRuleValidChange, hasTrackingLostDefined, isTrackingLostMinutesValid]);

    const handleEnableTrackingLostChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.hasTrackingLostDefined, event.target.checked));
    };

    const handleTrackingLostHoursChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(Number(event.currentTarget.value), calculateHoursAndMinutes(trackingLostMinutes).minutes);
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.trackingLostMinutes, updatedMinutes));
    };

    const handleTrackingLostMinutesChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(calculateHoursAndMinutes(trackingLostMinutes).hours, Number(event.currentTarget.value));
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.trackingLostMinutes, updatedMinutes));
    };

    return (
        <Fragment>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasTrackingLostDefined}
                            onChange={handleEnableTrackingLostChange}
                            color='primary'
                        />
                    }
                    label='Enable Tracking Lost'
                    data-qa='hasTrackingLostDefined-switch'
                />
            </FormGroup>

            {
                hasTrackingLostDefined &&
                <Fragment>
                    <StyledGrid container alignItems='flex-end'>
                        <Grid item className={classes.gridWrapper}>
                            <Typography component='p' variant='body2'>After</Typography>
                        </Grid>
                        <Grid item className={classes.gridWrapper}>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Hours'
                                value={calculateHoursAndMinutes(trackingLostMinutes).hours}
                                onChange={handleTrackingLostHoursChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 24 } }}
                                error={!isTrackingLostMinutesValid}
                                data-qa='trackingLostMinutes-hours-input'
                            />
                        </Grid>
                        <Grid item className={classes.gridWrapper}>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Minutes'
                                value={calculateHoursAndMinutes(trackingLostMinutes).minutes}
                                onChange={handleTrackingLostMinutesChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 59 } }}
                                error={!isTrackingLostMinutesValid}
                                data-qa='trackingLostMinutes-minutes-input'
                            />
                        </Grid>
                        <Grid item className={classes.gridWrapper}>
                            <Typography component='p' variant='body2'>without a position event</Typography>
                        </Grid>
                    </StyledGrid>
                    {
                        !isTrackingLostMinutesValid &&
                        <FormHelperText error>Total time cannot exceed 24 hours and 0 minutes.</FormHelperText>
                    }
                </Fragment>
            }
        </Fragment>
    );
};

export default TrackingLostRule;
