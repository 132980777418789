import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpansionPanel from '../../expansionPanel/expansionPanel';
import StopOrdersTable from '../../tables/stopOrdersTable';

const classesPrefix = 'stopCardLoading';

const classes = {
    stopOf: `${classesPrefix}-stopOf`,
    addressContainer: `${classesPrefix}-addressContainer`,
    skeletonText: `${classesPrefix}-skeletonText`,
    flexContainer: `${classesPrefix}-flexContainer`,
    deliveryStatusContainer: `${classesPrefix}-deliveryStatusContainer`,
    loadingIcons: `${classesPrefix}-loadingIcons`
};

const StyledCard = styled(Card)(() => {
    return {
        [`& .${classes.stopOf}`]: {
            fontWeight: 600,
            marginLeft: '4px',
            '& > span': {
                display: 'inline-block',
                marginLeft: '4px',
                marginRight: '4px'
            }
        },
        [`& .${classes.addressContainer}`]: {
            margin: '8px 0 8px 0'
        },
        [`& .${classes.skeletonText}`]: {
            display: 'flex',
            fontSize: '12px',
            '& > span': {
                marginRight: '4px'
            }
        },
        [`& .${classes.flexContainer}`]: {
            display: 'flex'
        },
        [`& .${classes.deliveryStatusContainer}`]: {
            flexGrow: 1
        },
        [`& .${classes.loadingIcons}`]: {
            marginLeft: '6px'
        }
    };
});

const StopCardLoading = (): JSX.Element => {
    return (
        <StyledCard>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={6} className={classes.flexContainer}>
                        <Skeleton variant='circular' width='24px' height='24px' />
                        <Typography className={classes.stopOf}>
                            Stop
                            <Skeleton variant='text' width='8px' />
                            of
                            <Skeleton variant='text' width='8px' />
                        </Typography>
                    </Grid>
                    <Grid item className={classes.deliveryStatusContainer}>
                        <Skeleton variant='rectangular' width='100px' height='30px' />
                    </Grid>
                    <Grid item className={classes.flexContainer}>
                        <Skeleton className={classes.loadingIcons} variant='circular' width='32px' height='32px' />
                        <Skeleton className={classes.loadingIcons} variant='circular' width='32px' height='32px' />
                        <Skeleton className={classes.loadingIcons} variant='circular' width='32px' height='32px' />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.addressContainer}>
                        <Skeleton variant='text' width='80px' />
                        <Skeleton variant='text' width='240px' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                        <Typography className={classes.skeletonText}>
                            <Skeleton variant='text' width='80px' />
                            <Skeleton variant='text' width='120px' />
                        </Typography>
                    </Grid>
                </Grid>

                <ExpansionPanel label='Orders'>
                    <StopOrdersTable
                        isFetchingData={true}
                        orders={[]}
                        stopReference=''
                    />
                </ExpansionPanel>
            </CardContent>
        </StyledCard>
    );
};

export default StopCardLoading;
