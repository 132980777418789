import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import {
    AllowedFilterPropertyName,
    ApiStatus,
    CalendarStopType,
    CalendarDateType,
    SortDirection
} from '../../helpers/enums';
import { formatDateForCalendar } from '../../helpers/dateUtils';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { Filter, ShipmentListData, ShipmentRequestPayload, ShipmentService } from '../../interfaces/services/shipment';
import { CalendarShipment } from '../../interfaces/services/shipmentCalendar';

const CALENDAR_SHIPMENT_LIST_UPDATE_FILTERS = 'CALENDAR_SHIPMENT_LIST_UPDATE_FILTERS';
const CALENDAR_SHIPMENT_LIST_REQUEST = 'CALENDAR_SHIPMENT_LIST_REQUEST';
const CALENDAR_SHIPMENT_LIST_SUCCESS = 'CALENDAR_SHIPMENT_LIST_SUCCESS';
const CALENDAR_SHIPMENT_LIST_FAILURE = 'CALENDAR_SHIPMENT_LIST_FAILURE';

interface CalendarShipments {
    shipmentListStatus: ApiStatus;
    shipmentList: CalendarShipment[];
    searchTerm: string;
    filters: Filter[];
    filtersName: string;
    startDate: string;
    endDate: string;
    dateType: CalendarDateType;
    isStopTypePickup: boolean;
    isStopTypeDelivery: boolean;
}

interface CalendarShipmentRequestPayload {
    searchTerm: string;
    startDate: string;
    endDate: string;
}

interface UpdateCalendarFiltersParams {
    filters?: Filter[];
    filtersName?: string;
    dateType?: CalendarDateType;
    isStopTypePickup?: boolean;
    isStopTypeDelivery?: boolean;
}

const requestCalendarShipments = (json: CalendarShipmentRequestPayload): GenericAction<typeof CALENDAR_SHIPMENT_LIST_REQUEST, CalendarShipmentRequestPayload> => {
    return {
        type: CALENDAR_SHIPMENT_LIST_REQUEST,
        payload: json
    };
};

const receiveCalendarShipments = ({
    shipments,
    dateType,
    isStopTypePickup,
    isStopTypeDelivery
}: {
    shipments: ShipmentListData[];
    dateType: CalendarDateType;
    isStopTypePickup: boolean;
    isStopTypeDelivery: boolean;
}): GenericAction<typeof CALENDAR_SHIPMENT_LIST_SUCCESS,
    {
        shipments: ShipmentListData[];
        dateType: CalendarDateType;
        isStopTypePickup: boolean;
        isStopTypeDelivery: boolean;
    }> => {
    return {
        type: CALENDAR_SHIPMENT_LIST_SUCCESS,
        payload: { shipments, dateType, isStopTypePickup, isStopTypeDelivery }
    };
};

const requestCalendarShipmentsFailed = (): Action<typeof CALENDAR_SHIPMENT_LIST_FAILURE> => {
    return {
        type: CALENDAR_SHIPMENT_LIST_FAILURE
    };
};

export const updateCalendarFilters = ({
    filters = [],
    filtersName = '',
    dateType = CalendarDateType.Scheduled,
    isStopTypePickup = true,
    isStopTypeDelivery = true
}: UpdateCalendarFiltersParams): GenericAction<typeof CALENDAR_SHIPMENT_LIST_UPDATE_FILTERS,
    {
        filters: Filter[];
        filtersName: string;
        dateType: CalendarDateType;
        isStopTypePickup: boolean;
        isStopTypeDelivery: boolean;
    }> => {
    return {
        type: CALENDAR_SHIPMENT_LIST_UPDATE_FILTERS,
        payload: {
            filters,
            filtersName,
            dateType,
            isStopTypePickup,
            isStopTypeDelivery
        }
    };
};

export const fetchCalendarShipments = ({
    dateType,
    searchTerm = '',
    filters,
    startDate,
    endDate,
    isStopTypePickup,
    isStopTypeDelivery
}: {
    dateType: CalendarDateType;
    searchTerm?: string;
    filters: Filter[];
    startDate: string;
    endDate: string;
    isStopTypePickup: boolean;
    isStopTypeDelivery: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestCalendarShipments({
            searchTerm,
            startDate,
            endDate
        }));

        const newFilters = [...filters];

        if (dateType === CalendarDateType.Scheduled) {
            if (isStopTypePickup && isStopTypeDelivery) {
                newFilters.push({
                    propertyName: AllowedFilterPropertyName.ScheduleDateRange,
                    propertyValues: [`${startDate} - ${endDate}`]
                });
            } else {
                if (isStopTypePickup) {
                    newFilters.push({
                        propertyName: AllowedFilterPropertyName.OriginScheduleDateRange,
                        propertyValues: [`${startDate} - ${endDate}`]
                    });
                }
                if (isStopTypeDelivery) {
                    newFilters.push({
                        propertyName: AllowedFilterPropertyName.DestinationScheduleDateRange,
                        propertyValues: [`${startDate} - ${endDate}`]
                    });
                }
            }
        }

        if (dateType === CalendarDateType.Appointment) {
            if (isStopTypePickup && isStopTypeDelivery) {
                newFilters.push({
                    propertyName: AllowedFilterPropertyName.AppointmentDateRange,
                    propertyValues: [`${startDate} - ${endDate}`]
                });
            } else {
                if (isStopTypePickup) {
                    newFilters.push({
                        propertyName: AllowedFilterPropertyName.OriginAppointmentDateRange,
                        propertyValues: [`${startDate} - ${endDate}`]
                    });
                }
                if (isStopTypeDelivery) {
                    newFilters.push({
                        propertyName: AllowedFilterPropertyName.DestinationAppointmentDateRange,
                        propertyValues: [`${startDate} - ${endDate}`]
                    });
                }
            }
        }

        try {
            const requestBody: ShipmentRequestPayload = {
                activeShipments: true,
                skip: 0,
                take: 1000,
                searchTerm,
                sortColumn: AllowedFilterPropertyName.OriginScheduleStartDateTime,
                sortDirection: SortDirection.Descending,
                filters: newFilters
            };

            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.headers}`,
                body: requestBody
            }) as ShipmentService;

            if (json.additionalRecords) {
                toast.info('More shipments available');
            }

            dispatch(receiveCalendarShipments({
                shipments: json.data,
                dateType,
                isStopTypePickup,
                isStopTypeDelivery
            }));
        } catch (error) {
            dispatch(requestCalendarShipmentsFailed());
            toast.error('Error occurred while fetching calendar data.');
        }
    };
};

type CalendarShipmentsActionTypes =
    ReturnType<typeof requestCalendarShipments> |
    ReturnType<typeof receiveCalendarShipments> |
    ReturnType<typeof requestCalendarShipmentsFailed> |
    ReturnType<typeof updateCalendarFilters>;

export const calendarReducer = (data: CalendarShipments = {
    shipmentListStatus: ApiStatus.Idle,
    shipmentList: [],
    searchTerm: '',
    filters: [],
    filtersName: '',
    startDate: '',
    endDate: '',
    dateType: CalendarDateType.Scheduled,
    isStopTypePickup: true,
    isStopTypeDelivery: true
}, action: CalendarShipmentsActionTypes): CalendarShipments => {
    switch (action.type) {
        case CALENDAR_SHIPMENT_LIST_REQUEST: {
            return {
                ...data,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
                shipmentListStatus: ApiStatus.Loading,
                searchTerm: action.payload.searchTerm
            };
        }
        case CALENDAR_SHIPMENT_LIST_SUCCESS: {
            const allShipments: CalendarShipment[] = [];

            if (action.payload.dateType === CalendarDateType.Scheduled) {
                action.payload.shipments.forEach((shipment): void => {
                    if (action.payload.isStopTypePickup && shipment.originScheduleStartDateTime && shipment.originScheduleEndDateTime) {
                        const startDateTime = formatDateForCalendar(shipment.originScheduleStartDateTime);
                        const endDateTime = formatDateForCalendar(shipment.originScheduleEndDateTime);

                        allShipments.push({
                            shipmentUniqueName: shipment.shipmentUniqueName,
                            deliveryStatus: shipment.deliveryStatus,
                            modeType: shipment.modeType,
                            title: shipment.freightProviderReferenceNumber,
                            calendarStopType: CalendarStopType.Pickup,
                            startDateTime,
                            endDateTime
                        });
                    }

                    if (action.payload.isStopTypeDelivery && shipment.destinationScheduleStartDateTime && shipment.destinationScheduleEndDateTime) {
                        const startDateTime = formatDateForCalendar(shipment.destinationScheduleStartDateTime);
                        const endDateTime = formatDateForCalendar(shipment.destinationScheduleEndDateTime);

                        allShipments.push({
                            shipmentUniqueName: shipment.shipmentUniqueName,
                            deliveryStatus: shipment.deliveryStatus,
                            modeType: shipment.modeType,
                            title: shipment.freightProviderReferenceNumber,
                            calendarStopType: CalendarStopType.Delivery,
                            startDateTime,
                            endDateTime
                        });
                    }
                });
            }

            if (action.payload.dateType === CalendarDateType.Appointment) {
                action.payload.shipments.forEach((shipment): void => {
                    if (action.payload.isStopTypePickup && shipment.originAppointmentStartDateTime && shipment.originAppointmentEndDateTime) {
                        const startDateTime = formatDateForCalendar(shipment.originAppointmentStartDateTime);
                        const endDateTime = formatDateForCalendar(shipment.originAppointmentEndDateTime);

                        allShipments.push({
                            shipmentUniqueName: shipment.shipmentUniqueName,
                            deliveryStatus: shipment.deliveryStatus,
                            modeType: shipment.modeType,
                            title: shipment.freightProviderReferenceNumber,
                            calendarStopType: CalendarStopType.Pickup,
                            startDateTime,
                            endDateTime
                        });
                    }

                    if (action.payload.isStopTypeDelivery && shipment.destinationAppointmentStartDateTime && shipment.destinationAppointmentEndDateTime) {
                        const startDateTime = formatDateForCalendar(shipment.destinationAppointmentStartDateTime);
                        const endDateTime = formatDateForCalendar(shipment.destinationAppointmentEndDateTime);

                        allShipments.push({
                            shipmentUniqueName: shipment.shipmentUniqueName,
                            deliveryStatus: shipment.deliveryStatus,
                            modeType: shipment.modeType,
                            title: shipment.freightProviderReferenceNumber,
                            calendarStopType: CalendarStopType.Delivery,
                            startDateTime,
                            endDateTime
                        });
                    }
                });
            }

            return {
                ...data,
                shipmentListStatus: ApiStatus.Success,
                shipmentList: allShipments
            };
        }
        case CALENDAR_SHIPMENT_LIST_FAILURE: {
            return {
                ...data,
                shipmentListStatus: ApiStatus.Failure,
                shipmentList: []
            };
        }
        case CALENDAR_SHIPMENT_LIST_UPDATE_FILTERS: {
            return {
                ...data,
                shipmentListStatus: ApiStatus.Idle,
                filters: action.payload.filters,
                filtersName: action.payload.filtersName,
                dateType: action.payload.dateType,
                isStopTypePickup: action.payload.isStopTypePickup,
                isStopTypeDelivery: action.payload.isStopTypeDelivery
            };
        }
        default:
            return data;
    }
};
