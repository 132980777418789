import React, { Fragment, useMemo } from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { NotificationData } from '../../interfaces/services/notifications';
import { ApiStatus } from '../../helpers/enums';
import NotificationCard from '../cards/notificationCard';
import ErrorPage from '../errors/errorPage';

const classesPrefix = 'notificationCardList';

const classes = {
    cardContainer: `${classesPrefix}-cardContainer`,
    deleteWrapper: `${classesPrefix}-deleteWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.cardContainer}`]: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-of-type': {
                borderBottom: 0
            },
            padding: '8px'
        },
        [`& .${classes.deleteWrapper}`]: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    };
});

const NotificationCardList = ({
    isFormDirty,
    handleCardClick
}: {
    isFormDirty: boolean;
    handleCardClick: () => void;
}): JSX.Element => {
    const notificationsListStatus = useTypedSelector((state) => { return state.notifications.notificationsListStatus; });
    const notificationsList = useTypedSelector((state) => { return state.notifications.notificationsList; });

    const isFetchingData = notificationsListStatus === ApiStatus.Idle || notificationsListStatus === ApiStatus.Loading;

    const memoizedNotificationCardList = useMemo((): JSX.Element[] => {
        return notificationsList.map((notification: NotificationData): JSX.Element => {
            return (
                <NotificationCard
                    notification={notification}
                    isFormDirty={isFormDirty}
                    key={notification.notificationGuid}
                    handleCardClick={handleCardClick}
                />
            );
        });
    }, [notificationsList, isFormDirty, handleCardClick]);

    if (isFetchingData) {
        return (
            <Fragment>
                {
                    Array.from(new Array(7)).map((item, index): JSX.Element => {
                        return (
                            <StyledGrid
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                container
                                className={classes.cardContainer}
                                alignItems='center'
                            >
                                <Grid item xs={8}>
                                    <Skeleton variant='text' width='150px' height='30px' />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton variant='text' height='30px' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant='text' width='100px' height='50px' />
                                </Grid>
                                <Grid item xs={8}>
                                    <Skeleton variant='text' width='150px' />
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.deleteWrapper}>
                                        <Skeleton variant='circular' height='30px' width='30px' />
                                    </div>
                                </Grid>
                            </StyledGrid>
                        );
                    })
                }
            </Fragment>
        );
    }

    if (notificationsList.length === 0 || notificationsListStatus === ApiStatus.Failure) {
        return (
            <ErrorPage errorHeaderText=''>
                <Typography variant='body1' data-qa='noNotifications'>No notifications available.</Typography>
            </ErrorPage>
        );
    }

    return (
        <Fragment>
            {memoizedNotificationCardList}
        </Fragment>
    );
};

export default NotificationCardList;
