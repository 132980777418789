import React from 'react';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import RoadSurfaceLegend from './roadSurfaceLegend';
import DeliveryStatusLegendLoading from '../loaders/mapLegends/deliveryStatusLegendLoading';
import DeliveryStatusLegend from './deliveryStatusLegend';

const classesPrefix = 'mapLegendWrapper';

const classes = {
    legendsWrapper: `${classesPrefix}-legendsWrapper`,
    legendPaperWrapper: `${classesPrefix}-legendPaperWrapper`,
    roadSurfaceLegendWrapper: `${classesPrefix}-roadSurfaceLegendWrapper`,
    deliveryStatusWrapper: `${classesPrefix}-deliveryStatusWrapper`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.legendsWrapper}`]: {
            position: 'absolute',
            right: '52px',
            top: '8px',
            zIndex: 40
        },
        [`& .${classes.legendPaperWrapper}`]: {
            padding: '8px',
            opacity: '0.8'
        },
        [`& .${classes.roadSurfaceLegendWrapper}`]: {
            width: '150px'
        },
        [`& .${classes.deliveryStatusWrapper}`]: {
            width: '260px'
        }
    };
});

const MapLegendWrapper = ({
    isWeatherRoadSurfaceActive,
    isMapLegendEnabled,
    mapLegendType
}: {
    /** Indicator for if the weather road surface layer is active. */
    isWeatherRoadSurfaceActive: boolean;

    /** Indicator for if the map legend is enabled. */
    isMapLegendEnabled: boolean;

    /** Indicator for if the legend should use shipments or orders data. */
    mapLegendType: 'shipments' | 'orders' | null;
}): JSX.Element => {
    const mapLegendDisplay = useTypedSelector((state) => { return state.user.userSettings.mapLegendDisplay; });

    const shipmentMapLegendStatus = useTypedSelector((state) => { return state.dimensions.shipmentMapLegendStatus; });
    const shipmentMapLegend = useTypedSelector((state) => { return state.dimensions.shipmentMapLegend; });
    const shipmentMapLegendFilteredStatus = useTypedSelector((state) => { return state.dimensions.shipmentMapLegendFilteredStatus; });
    const shipmentMapLegendFiltered = useTypedSelector((state) => { return state.dimensions.shipmentMapLegendFiltered; });

    const orderMapLegendStatus = useTypedSelector((state) => { return state.dimensions.orderMapLegendStatus; });
    const orderMapLegend = useTypedSelector((state) => { return state.dimensions.orderMapLegend; });
    const orderMapLegendFilteredStatus = useTypedSelector((state) => { return state.dimensions.orderMapLegendFilteredStatus; });
    const orderMapLegendFiltered = useTypedSelector((state) => { return state.dimensions.orderMapLegendFiltered; });

    const isFetchingShipmentDeliveryStatusList = shipmentMapLegendStatus === ApiStatus.Idle || shipmentMapLegendStatus === ApiStatus.Loading ||
        shipmentMapLegendFilteredStatus === ApiStatus.Idle || shipmentMapLegendFilteredStatus === ApiStatus.Loading;

    const isFetchingOrderDeliveryStatusList = orderMapLegendStatus === ApiStatus.Idle || orderMapLegendStatus === ApiStatus.Loading ||
        orderMapLegendFilteredStatus === ApiStatus.Idle || orderMapLegendFilteredStatus === ApiStatus.Loading;

    const isFetching = mapLegendType === 'orders' ? isFetchingOrderDeliveryStatusList : isFetchingShipmentDeliveryStatusList;

    return (
        <StyledDiv className={classes.legendsWrapper} data-qa='mapLegends-container'>
            <Grid container spacing={1}>
                {
                    isWeatherRoadSurfaceActive &&
                    <Grid item data-qa='roadSurfaceLegend-container'>
                        <Paper className={`${classes.legendPaperWrapper} ${classes.roadSurfaceLegendWrapper}`}>
                            <RoadSurfaceLegend />
                        </Paper>
                    </Grid>
                }
                {
                    isMapLegendEnabled && mapLegendDisplay && mapLegendType &&
                    <Grid item data-qa='deliveryStatusLegend-container'>
                        <Paper className={`${classes.legendPaperWrapper} ${classes.deliveryStatusWrapper}`}>
                            {
                                isFetching ?
                                    (
                                        <DeliveryStatusLegendLoading />
                                    ) : (
                                        <DeliveryStatusLegend
                                            mapLegendStatus={mapLegendType === 'orders' ? orderMapLegendStatus : shipmentMapLegendStatus}
                                            mapLegend={mapLegendType === 'orders' ? orderMapLegend : shipmentMapLegend}
                                            mapLegendFilteredStatus={mapLegendType === 'orders' ? orderMapLegendFilteredStatus : shipmentMapLegendFilteredStatus}
                                            mapLegendFiltered={mapLegendType === 'orders' ? orderMapLegendFiltered : shipmentMapLegendFiltered}
                                        />
                                    )
                            }
                        </Paper>
                    </Grid>
                }
            </Grid>
        </StyledDiv>
    );
};

export default MapLegendWrapper;
