import { FixedLocationData } from '../../interfaces/services/locationManagement';
import { FixedLocationTypes, FixedLocationAuthorityType } from '../../helpers/enums';

export const initialLocationDetailsState: FixedLocationData = {
    fixedLocationType: FixedLocationTypes.Generic,
    fixedLocationName: '',
    streetAddressLine1: null,
    streetAddressLine2: null,
    cityName: null,
    stateCode: null,
    postalCode: null,
    countryCode: null,
    fixedLocationPoint: {
        latitude: 0,
        longitude: 0
    },
    fixedLocationCode: '',
    organizationReferenceCode: null,
    overrideDeliveryStatusFlag: false,
    geofenceRadiusInFeet: null,
    earlyShipmentStatusMinutes: null,
    onTimeShipmentStatusMinutes: null,
    inJeopardyShipmentStatusMinutes: null,
    lateShipmentStatusMinutes: null,
    hasEarlyStatusDefined: false,
    hasInJeopardyStatusDefined: false,
    dwellTimeMinutes: null,
    isActiveFlag: false,
    lastUpdatedDate: null,
    geofencePolygon: null,
    allowGeofenceRewind: false,
    dwellTimeBufferMinutes: null,
    operatingHours: [],
    loadTimes: [],
    fixedLocationAuthorityType: FixedLocationAuthorityType.TrimbleVisibility,
    contactInfoPhoneNumber: null,
    contactInfoUrl: null,
    categoryName: null,
    gates: []
};
