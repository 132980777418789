import React, { useCallback, useMemo } from 'react';
import { useDropzone, FileRejection, FileWithPath } from 'react-dropzone';
import { useTheme } from '@mui/material/styles';

import { AcceptedFile } from '../../interfaces/componentInterfaces';

const SingleDocumentUploader = ({
    handleDocumentsToUpload,
    handleUploaderErrors,
    disabled = false
}: {
    handleDocumentsToUpload: (acceptedFileWithBinary: AcceptedFile | null) => void;
    handleUploaderErrors: (uploaderErrors: string[]) => void;
    disabled: boolean;
}): JSX.Element => {
    const theme = useTheme();

    const readUploadedFileAsBinary = (inputFile: File): Promise<any> => {
        const reader = new FileReader();

        return new Promise((resolve, reject): void => {
            reader.onabort = (): void => {
                reject(new DOMException('File reading was aborted.'));
            };

            reader.onerror = (): void => {
                reader.abort();
                reject(new DOMException('File reading has failed.'));
            };

            reader.onload = (): void => {
                const binaryStr = reader.result;
                if (binaryStr) {
                    resolve(binaryStr);
                } else {
                    reject(new DOMException('Unable to determine file contents to upload.'));
                }
            };

            reader.readAsDataURL(inputFile);
        });
    };

    const onDrop = useCallback(async (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]): Promise<void> => {
        const uploaderErrors: string[] = [];
        // push to uploaderErrors array if multiple files are being uploaded
        if (acceptedFiles.length + fileRejections.length > 1) {
            uploaderErrors.push('Please only upload 1 file at a time.');
        }
        // push to uploaderErrors array if a file is rejected by dropzone (wrong file type)
        if (fileRejections.length > 0) {
            // dropzone is only rejecting files based on file types, so we only need the first one.
            // in the future we may need to validate against dropzones error codes
            uploaderErrors.push('Only certain file types are allowed: jpg, jpeg, png, tiff, tif, gif, doc, docx, and pdf.');
        }
        // if we don't have any errors up to this point attempt to upload
        if (uploaderErrors.length === 0) {
            // this logic (and others) will need to be adjusted if/when we decide to allow multiple files to be uploaded
            const file = acceptedFiles[0];
            try {
                const fileContent = await readUploadedFileAsBinary(file as File);
                const base64ContentArray = fileContent.split(',');
                const acceptedFileWithBinary: AcceptedFile = {
                    name: file.name,
                    fileContent: base64ContentArray[1]
                };
                handleDocumentsToUpload(acceptedFileWithBinary);
            } catch (error) {
                uploaderErrors.push(error.message);
                // clear out file selected
                handleDocumentsToUpload(null);
            }
        }
        // if anything errored clear out the file selected
        if (uploaderErrors.length > 0) {
            handleDocumentsToUpload(null);
        }
        // pass the error messages to the caller. This has to be always be called to clear out error messages.
        handleUploaderErrors(uploaderErrors);
    }, [handleDocumentsToUpload, handleUploaderErrors]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        // allowable file types: jpg, jpeg, png, tiff, tif, gif, doc, docx, pdf
        // MUST use MIME types below: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/tiff': [],
            'image/gif': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/pdf': []
        },
        disabled,
        onDrop
    });

    const style = useMemo((): {} => {
        const baseStyle = {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: theme.palette.action.selected,
            borderStyle: 'dashed',
            backgroundColor: theme.palette.background.default,
            outline: 'none',
            transition: 'border .24s ease-in-out'
        };

        const activeStyle = {
            borderColor: theme.palette.action.active
        };

        const acceptStyle = {
            borderColor: theme.palette.success.main
        };

        const rejectStyle = {
            borderColor: theme.palette.error.main
        };

        return ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        });
    }, [
        theme.palette.action.selected,
        theme.palette.background.default,
        theme.palette.action.active,
        theme.palette.success.main,
        theme.palette.error.main,
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div {...getRootProps({ style })} data-qa='singleDocument-uploader'>
            <input {...getInputProps()} />
            <p>Drag and drop a file here, or click to select a file</p>
        </div>
    );
};

export default SingleDocumentUploader;
