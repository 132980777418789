import React from 'react';
import { useParams } from 'react-router-dom';
import { Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import TripDetails from '../sections/tripDetails';

const classesPrefix = 'tripDetailsPage';

const classes = {
    wrapper: `${classesPrefix}-wrapper`,
    toolbar: `${classesPrefix}-toolbar`,
    title: `${classesPrefix}-title`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.wrapper}`]: {
            backgroundColor: theme.appColors.pageBackground,
            paddingBottom: '16px'
        },
        [`& .${classes.toolbar}`]: {
            top: '48px',
            right: 0,
            left: 'auto',
            position: 'sticky',
            zIndex: 11
        },
        [`& .${classes.title}`]: {
            marginRight: '16px'
        }
    };
});

const TripDetailsPage = (): JSX.Element => {
    const { tripIdentifier } = useParams<{ tripIdentifier: string; }>();

    return (
        <StyledDiv className={classes.wrapper} data-qa='tripDetailsPage-container'>
            <Toolbar classes={{ root: classes.toolbar }}>
                <Typography variant='h6' className={classes.title} data-qa='tripDetails-header'>{`Trip ${tripIdentifier}`}</Typography>
            </Toolbar>
            <TripDetails
                tripIdentifier={tripIdentifier}
            />
        </StyledDiv>
    );
};

export default TripDetailsPage;
