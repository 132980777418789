import React from 'react';
import { Typography, Button } from '@mui/material';
import AuthService from '../../services/authService';
import ErrorPage from './errorPage';

const NotAuthorized = (): JSX.Element => {
    return (
        <ErrorPage errorHeaderText='Authorization Error'>
            <Typography data-qa='authorizationError'>
                Your session may have expired or you may not be authorized for this application. Please log out and log back in.
            </Typography>
            <Button
                variant='outlined'
                color='primary'
                onClick={(): void => {
                    AuthService.logout();
                }}
                data-qa='error-action-logout'
            >
                Log Out
            </Button>
        </ErrorPage>
    );
};

export default NotAuthorized;
