import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    FormGroup,
    FormControlLabel,
    Switch,
    Typography,
    TextField,
    Divider,
    FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { editPreferencesOnTimeRules } from '../../../redux/organization';
import { PreferencesOnTimeRules } from '../../../interfaces/services/organization';
import { calculateHoursAndMinutes, calculateMinutes, validateHoursAndMinutes } from '../../../helpers/dataUtils';
import { getDeliveryStatusColor } from '../../../helpers/styleHelpers';
import { DeliveryStatus, ModeType, PreferencesOnTimeRulesFieldNames } from '../../../helpers/enums';

const classesPrefix = 'deliveryStatusRules';

const classes = {
    gridWrapper: `${classesPrefix}-gridWrapper`,
    textFieldWrapper: `${classesPrefix}-textFieldWrapper`,
    textField: `${classesPrefix}-textField`,
    earlyDescription: `${classesPrefix}-earlyDescription`,
    onTimeDescription: `${classesPrefix}-onTimeDescription`,
    inJeopardyDescription: `${classesPrefix}-inJeopardyDescription`,
    lateDescription: `${classesPrefix}-lateDescription`,
    divider: `${classesPrefix}-divider`
};

const StyledDiv = styled('div')(() => {
    return {
        [`& .${classes.gridWrapper}`]: {
            marginTop: '16px'
        },
        [`& .${classes.textFieldWrapper}`]: {
            marginLeft: '16px',
            marginRight: '16px'
        },
        [`& .${classes.textField}`]: {
            width: '50px'
        },
        [`& .${classes.earlyDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.Early),
            fontWeight: 600
        },
        [`& .${classes.onTimeDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.OnTime),
            fontWeight: 600
        },
        [`& .${classes.inJeopardyDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.InJeopardy),
            fontWeight: 600
        },
        [`& .${classes.lateDescription}`]: {
            color: getDeliveryStatusColor(DeliveryStatus.Late),
            fontWeight: 600
        },
        [`& .${classes.divider}`]: {
            marginTop: '16px',
            marginBottom: '16px'
        }
    };
});

const DeliveryStatusRules = ({
    selectedModeType,
    handleIsRuleValidChange,
    currentOnTimeRules
}: {
    selectedModeType: ModeType;
    currentOnTimeRules: PreferencesOnTimeRules;
    handleIsRuleValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [isEarlyShipmentStatusMinutesValid, setIsEarlyShipmentStatusMinutesValid] = useState(true);

    const [isOnTimeShipmentStatusMinutesValid, setIsOnTimeShipmentStatusMinutesValid] = useState(true);
    const [onTimeShipmentStatusMinutesError, setOnTimeShipmentStatusMinutesError] = useState('');

    const [isInJeopardyShipmentStatusMinutesValid, setIsInJeopardyShipmentStatusMinutesValid] = useState(true);
    const [inJeopardyShipmentStatusMinutesError, setInJeopardyShipmentStatusMinutesError] = useState('');

    const {
        hasEarlyStatusDefined,
        earlyShipmentStatusMinutes,
        onTimeShipmentStatusMinutes,
        hasInJeopardyStatusDefined,
        inJeopardyShipmentStatusMinutes
    } = currentOnTimeRules;

    useEffect((): void => {
        const isValid = validateHoursAndMinutes(earlyShipmentStatusMinutes, 4320);
        setIsEarlyShipmentStatusMinutesValid(isValid);
    }, [earlyShipmentStatusMinutes]);

    useEffect((): void => {
        // first validate that they are both valid independently of each other
        const isValidOnTimeHoursAndMinutes = validateHoursAndMinutes(onTimeShipmentStatusMinutes, 4320);
        const isValidInJeopardyHoursAndMinutes = validateHoursAndMinutes(inJeopardyShipmentStatusMinutes, 4320);

        // then check that they are valid against each other
        const isValidOnTimeVsInJeopardy = hasInJeopardyStatusDefined === true
            ? onTimeShipmentStatusMinutes <= inJeopardyShipmentStatusMinutes
            : true;

        // combine the validation above to see if each field is valid
        const isValidOnTime = isValidOnTimeHoursAndMinutes && isValidOnTimeVsInJeopardy;
        const isValidInJeopardy = isValidInJeopardyHoursAndMinutes && isValidOnTimeVsInJeopardy;

        let onTimeError = '';
        if (!isValidOnTimeHoursAndMinutes) {
            onTimeError = 'Total time cannot exceed 72 hours and 0 minutes.';
        } else if (!isValidOnTimeVsInJeopardy) {
            onTimeError = 'On Time hours and minutes must be the same or less than In Jeopardy.';
        }

        let inJeopardyError = '';
        if (!isValidInJeopardyHoursAndMinutes) {
            inJeopardyError = 'Total time cannot exceed 72 hours and 0 minutes.';
        } else if (!isValidOnTimeVsInJeopardy) {
            inJeopardyError = 'On Time hours and minutes must be the same or less than In Jeopardy.';
        }

        setIsOnTimeShipmentStatusMinutesValid(isValidOnTime);
        setOnTimeShipmentStatusMinutesError(onTimeError);

        setIsInJeopardyShipmentStatusMinutesValid(isValidInJeopardy);
        setInJeopardyShipmentStatusMinutesError(inJeopardyError);
    }, [onTimeShipmentStatusMinutes, inJeopardyShipmentStatusMinutes, hasInJeopardyStatusDefined]);

    useEffect((): void => {
        // only check isEarly and isInJeopardy minutes are valid if the options are defined and visible. Otherwise, default to true.
        const isEarlyStatusValid = hasEarlyStatusDefined ? isEarlyShipmentStatusMinutesValid && isOnTimeShipmentStatusMinutesValid : true;
        const isInJeopardyStatusValid = hasInJeopardyStatusDefined ? isInJeopardyShipmentStatusMinutesValid : true;
        const isRuleValid = isEarlyStatusValid && isInJeopardyStatusValid;
        handleIsRuleValidChange(isRuleValid);
    }, [
        handleIsRuleValidChange,
        hasEarlyStatusDefined,
        isEarlyShipmentStatusMinutesValid,
        isOnTimeShipmentStatusMinutesValid,
        hasInJeopardyStatusDefined,
        isInJeopardyShipmentStatusMinutesValid
    ]);

    const handleTrackEarlyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.hasEarlyStatusDefined, event.target.checked));

        if (event.target.checked === false) {
            // if turning hasEarlyStatusDefined off, set the earlyShipmentStatusMinutes to null (because the api requires it that way)
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.earlyShipmentStatusMinutes, null));
        } else {
            // if turning the option on, either set the value to what's in redux (if available) or default back to 0 minutes
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.earlyShipmentStatusMinutes, earlyShipmentStatusMinutes || 0));
        }
    };

    const handleEarlyShipmentStatusHoursChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(Number(event.currentTarget.value), calculateHoursAndMinutes(earlyShipmentStatusMinutes).minutes);
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.earlyShipmentStatusMinutes, updatedMinutes));
    };

    const handleEarlyShipmentStatusMinutesChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(calculateHoursAndMinutes(earlyShipmentStatusMinutes).hours, Number(event.currentTarget.value));
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.earlyShipmentStatusMinutes, updatedMinutes));
    };

    const handleOnTimeShipmentStatusHoursChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(Number(event.currentTarget.value), calculateHoursAndMinutes(onTimeShipmentStatusMinutes).minutes);
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.onTimeShipmentStatusMinutes, updatedMinutes));

        // if hasInJeopardyStatusDefined is false, the lateShipmentStatusMinutes need to match the onTimeShipmentStatusMinutes
        if (hasInJeopardyStatusDefined === false) {
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.lateShipmentStatusMinutes, updatedMinutes));
        }
    };

    const handleOnTimeShipmentStatusMinutesChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(calculateHoursAndMinutes(onTimeShipmentStatusMinutes).hours, Number(event.currentTarget.value));
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.onTimeShipmentStatusMinutes, updatedMinutes));

        // if hasInJeopardyStatusDefined is false, the lateShipmentStatusMinutes need to match the onTimeShipmentStatusMinutes
        if (hasInJeopardyStatusDefined === false) {
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.lateShipmentStatusMinutes, updatedMinutes));
        }
    };

    const handleTrackInJeopardyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.hasInJeopardyStatusDefined, event.target.checked));

        if (event.target.checked === false) {
            // if turning hasInJeopardyStatusDefined off, set the inJeopardyShipmentStatusMinutes to null (because the api requires it that way)
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.inJeopardyShipmentStatusMinutes, null));
            // if hasInJeopardyStatusDefined is turned off, the late minutes now need to match the onTimeShipmentStatusMinutes instead (because the api requires it that way)
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.lateShipmentStatusMinutes, onTimeShipmentStatusMinutes));
        } else {
            // if turning the option on, either set the value (and the late value) to what's in redux (if available) or default back to 0 minutes
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.inJeopardyShipmentStatusMinutes, inJeopardyShipmentStatusMinutes || 0));
            dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.lateShipmentStatusMinutes, inJeopardyShipmentStatusMinutes || 0));
        }
    };

    const handleInJeopardyShipmentStatusHoursChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(Number(event.currentTarget.value), calculateHoursAndMinutes(inJeopardyShipmentStatusMinutes).minutes);
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.inJeopardyShipmentStatusMinutes, updatedMinutes));
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.lateShipmentStatusMinutes, updatedMinutes));
    };

    const handleInJeopardyShipmentStatusMinutesChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(calculateHoursAndMinutes(inJeopardyShipmentStatusMinutes).hours, Number(event.currentTarget.value));
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.inJeopardyShipmentStatusMinutes, updatedMinutes));
        dispatch(editPreferencesOnTimeRules(selectedModeType, PreferencesOnTimeRulesFieldNames.lateShipmentStatusMinutes, updatedMinutes));
    };

    return (
        <StyledDiv>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasEarlyStatusDefined}
                            onChange={handleTrackEarlyChange}
                            color='primary'
                        />
                    }
                    label='Track EARLY'
                    data-qa='hasEarlyStatusDefined-switch'
                />
            </FormGroup>

            {
                hasEarlyStatusDefined === true &&
                <Typography component='p' variant='body2'>
                    {'Shipment ETAs before '}
                    <span className={classes.onTimeDescription}>ON TIME</span>
                    {' will be tagged '}
                    <span className={classes.earlyDescription}>EARLY</span>
                </Typography>
            }

            <Divider className={classes.divider} />

            <Typography component='p' variant='body2'>
                {'Shipments are '}
                <span className={classes.onTimeDescription}>ON TIME</span>
                {' when ETAs are '}
                {hasEarlyStatusDefined === true ? 'between' : ''}
            </Typography>

            {
                hasEarlyStatusDefined === true &&
                <Fragment>
                    <Grid className={classes.gridWrapper} container alignItems='flex-end'>
                        <Grid item>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Hours'
                                value={calculateHoursAndMinutes(earlyShipmentStatusMinutes).hours}
                                onChange={handleEarlyShipmentStatusHoursChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 72 } }}
                                error={!isEarlyShipmentStatusMinutesValid}
                                data-qa='earlyShipmentStatusMinutes-hours-input'
                            />
                        </Grid>
                        <Grid item className={classes.textFieldWrapper}>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Minutes'
                                value={calculateHoursAndMinutes(earlyShipmentStatusMinutes).minutes}
                                onChange={handleEarlyShipmentStatusMinutesChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 59 } }}
                                error={!isEarlyShipmentStatusMinutesValid}
                                data-qa='earlyShipmentStatusMinutes-minutes-input'
                            />
                        </Grid>
                        <Grid item>
                            <Typography component='p' variant='body2'>BEFORE, and</Typography>
                        </Grid>
                    </Grid>
                    {
                        !isEarlyShipmentStatusMinutesValid &&
                        <FormHelperText error>Total time cannot exceed 72 hours and 0 minutes.</FormHelperText>
                    }
                </Fragment>
            }

            <Grid className={classes.gridWrapper} container alignItems='flex-end'>
                <Grid item>
                    <TextField
                        className={classes.textField}
                        variant='standard'
                        label='Hours'
                        value={calculateHoursAndMinutes(onTimeShipmentStatusMinutes).hours}
                        onChange={handleOnTimeShipmentStatusHoursChange}
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 72 } }}
                        error={!isOnTimeShipmentStatusMinutesValid}
                        data-qa='onTimeShipmentStatusMinutes-hours-input'
                    />
                </Grid>
                <Grid item className={classes.textFieldWrapper}>
                    <TextField
                        className={classes.textField}
                        variant='standard'
                        label='Minutes'
                        value={calculateHoursAndMinutes(onTimeShipmentStatusMinutes).minutes}
                        onChange={handleOnTimeShipmentStatusMinutesChange}
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 59 } }}
                        error={!isOnTimeShipmentStatusMinutesValid}
                        data-qa='onTimeShipmentStatusMinutes-minutes-input'
                    />
                </Grid>
                <Grid item>
                    <Typography component='p' variant='body2'>AFTER Scheduled Time</Typography>
                </Grid>
            </Grid>
            <FormHelperText error>{onTimeShipmentStatusMinutesError}</FormHelperText>

            <Divider className={classes.divider} />

            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasInJeopardyStatusDefined}
                            onChange={handleTrackInJeopardyChange}
                            color='primary'
                        />
                    }
                    label='Track IN JEOPARDY'
                    data-qa='hasInJeopardyStatusDefined-switch'
                />
            </FormGroup>

            {
                hasInJeopardyStatusDefined === true &&
                <Fragment>
                    <Typography component='p' variant='body2'>
                        {'Shipments are '}
                        <span className={classes.inJeopardyDescription}>IN JEOPARDY</span>
                        {' when ETAs are '}
                    </Typography>

                    <Grid className={classes.gridWrapper} container alignItems='flex-end'>
                        <Grid item>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Hours'
                                value={calculateHoursAndMinutes(inJeopardyShipmentStatusMinutes).hours}
                                onChange={handleInJeopardyShipmentStatusHoursChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 72 } }}
                                error={!isInJeopardyShipmentStatusMinutesValid}
                                data-qa='inJeopardyShipmentStatusMinutes-hours-input'
                            />
                        </Grid>
                        <Grid item className={classes.textFieldWrapper}>
                            <TextField
                                className={classes.textField}
                                variant='standard'
                                label='Minutes'
                                value={calculateHoursAndMinutes(inJeopardyShipmentStatusMinutes).minutes}
                                onChange={handleInJeopardyShipmentStatusMinutesChange}
                                type='number'
                                InputProps={{ inputProps: { min: 0, max: 59 } }}
                                error={!isInJeopardyShipmentStatusMinutesValid}
                                data-qa='inJeopardyShipmentStatusMinutes-minutes-input'
                            />
                        </Grid>
                        <Grid item>
                            <Typography component='p' variant='body2'>AFTER Scheduled Time</Typography>
                        </Grid>
                    </Grid>
                    <FormHelperText error>{inJeopardyShipmentStatusMinutesError}</FormHelperText>
                </Fragment>
            }

            <Divider className={classes.divider} />

            <Typography component='p' variant='body2'>
                {'Shipment ETAs after '}
                {
                    hasInJeopardyStatusDefined === true ? (
                        <span className={classes.inJeopardyDescription}>IN JEOPARDY</span>
                    ) : (
                        <span className={classes.onTimeDescription}>ON TIME</span>
                    )
                }
                {' will be tagged '}
                <span className={classes.lateDescription}>LATE</span>
            </Typography>
        </StyledDiv>
    );
};

export default DeliveryStatusRules;
