import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import {
    ApiStatus,
    PublicDocumentType,
    DocumentType,
    FreightHaulerIdentifierTypeName,
    SavedViewType,
    SortDirection,
    ShipmentStatus
} from '../../helpers/enums';
import { simpleJavascriptDateTimeFormat } from '../../helpers/dateUtils';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { fetchViewsList } from '../views';
import {
    MobileTrackingUpdateResponse,
    AddShipmentNoteResponse,
    AddShipmentStopNoteResponse,
    UploadDocumentResponse,
    UpdateStopTimesRequest,
    ShipmentMovementResponse
} from '../../interfaces/services/shipmentUpdates';
import { GenericApiResponse } from '../../interfaces/services';
import { Note, DocumentData, StopNote } from '../../interfaces/services/shipmentDetails';
import { ViewSortColumns, ViewUser } from '../../interfaces/services/views';
import { Filter } from '../../interfaces/services/shipment';

const UPDATE_PAGE_RESET = 'UPDATE_PAGE_RESET';
const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
const UPDATE_PAGE_FAILURE = 'UPDATE_PAGE_FAILURE';
export const UPDATE_TRACTOR_TRAILER_SUCCESS = 'UPDATE_TRACTOR_TRAILER_SUCCESS';
export const UPDATE_MOBILE_TRACKING_SUCCESS = 'UPDATE_MOBILE_TRACKING_SUCCESS';
export const ADD_SHIPMENT_NOTE_SUCCESS = 'ADD_SHIPMENT_NOTE_SUCCESS';
export const ADD_SHIPMENT_STOP_NOTE_SUCCESS = 'ADD_SHIPMENT_STOP_NOTE_SUCCESS';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPDATE_STOP_TIMES_SUCCESS = 'UPDATE_STOP_TIMES_SUCCESS';
export const UPDATE_SHIPMENT_PRIORITY = 'UPDATE_SHIPMENT_PRIORITY';
export const UPDATE_SHIPMENT_STATUS_SUCCESS = 'UPDATE_SHIPMENT_STATUS_SUCCESS';

const initialState = {
    status: ApiStatus.Idle,
    errorMessage: ''
};

interface DialogUpdates {
    status: ApiStatus;
    errorMessage: string;
}

export const updatePageReset = (): Action<typeof UPDATE_PAGE_RESET> => {
    return {
        type: UPDATE_PAGE_RESET
    };
};

const updatePageRequest = (): Action<typeof UPDATE_PAGE_REQUEST> => {
    return {
        type: UPDATE_PAGE_REQUEST
    };
};

const updatePageSuccess = (): Action<typeof UPDATE_PAGE_SUCCESS> => {
    return {
        type: UPDATE_PAGE_SUCCESS
    };
};

const updatePageFailed = (error: string): GenericAction<typeof UPDATE_PAGE_FAILURE, string> => {
    return {
        type: UPDATE_PAGE_FAILURE,
        payload: error
    };
};

export const updateTractorTrailerSuccess = (json: ShipmentMovementResponse): GenericAction<typeof UPDATE_TRACTOR_TRAILER_SUCCESS, ShipmentMovementResponse> => {
    return {
        type: UPDATE_TRACTOR_TRAILER_SUCCESS,
        payload: json
    };
};

export const updateMobileTrackingSuccess = (json: MobileTrackingUpdateResponse): GenericAction<typeof UPDATE_MOBILE_TRACKING_SUCCESS, MobileTrackingUpdateResponse> => {
    return {
        type: UPDATE_MOBILE_TRACKING_SUCCESS,
        payload: json
    };
};

export const addShipmentNoteSuccess = (json: AddShipmentNoteResponse): GenericAction<typeof ADD_SHIPMENT_NOTE_SUCCESS, AddShipmentNoteResponse> => {
    return {
        type: ADD_SHIPMENT_NOTE_SUCCESS,
        payload: json
    };
};

export const addShipmentStopNoteSuccess = (json: AddShipmentStopNoteResponse): GenericAction<typeof ADD_SHIPMENT_STOP_NOTE_SUCCESS, AddShipmentStopNoteResponse> => {
    return {
        type: ADD_SHIPMENT_STOP_NOTE_SUCCESS,
        payload: json
    };
};

export const uploadDocumentSuccess = (json: UploadDocumentResponse): GenericAction<typeof UPLOAD_DOCUMENT_SUCCESS, UploadDocumentResponse> => {
    return {
        type: UPLOAD_DOCUMENT_SUCCESS,
        payload: json
    };
};

export const updateStopTimesSuccess = (json: ShipmentMovementResponse): GenericAction<typeof UPDATE_STOP_TIMES_SUCCESS, ShipmentMovementResponse> => {
    return {
        type: UPDATE_STOP_TIMES_SUCCESS,
        payload: json
    };
};

export const updateShipmentPrioritySuccess = (json: { shipmentUniqueName: string; isPriorityShipment: boolean; }): GenericAction<typeof UPDATE_SHIPMENT_PRIORITY,
    {
        shipmentUniqueName: string;
        isPriorityShipment: boolean;
    }> => {
    return {
        type: UPDATE_SHIPMENT_PRIORITY,
        payload: json
    };
};

export const updateShipmentStatusSuccess = (shipmentUniqueName: string, shipmentStatus: ShipmentStatus): GenericAction<typeof UPDATE_SHIPMENT_STATUS_SUCCESS,
    {
        shipmentUniqueName: string;
        shipmentStatus: ShipmentStatus;
    }> => {
    return {
        type: UPDATE_SHIPMENT_STATUS_SUCCESS,
        payload: {
            shipmentUniqueName,
            shipmentStatus
        }
    };
};

const determineFreightHaulerIdentifier = (freightHaulerIdentifierTypeName: FreightHaulerIdentifierTypeName | null): string => {
    const freightHaulerLookup = {
        [FreightHaulerIdentifierTypeName.MCNumber]: 'CarrierMc',
        [FreightHaulerIdentifierTypeName.USDotNumber]: 'CarrierDOT',
        [FreightHaulerIdentifierTypeName.SCACCode]: 'CarrierSCAC',
        [FreightHaulerIdentifierTypeName.PNetCID]: 'CarrierInvalid' // use bad value here
    };

    if (!freightHaulerIdentifierTypeName || freightHaulerLookup[freightHaulerIdentifierTypeName] === 'CarrierInvalid') {
        // if it's not one of the above values, intentionally return a bad value to fail the API
        console.error('Carrier Identifier could not be determined from the freightHaulerIdentifierTypeName of', freightHaulerIdentifierTypeName);
        return 'CarrierInvalid';
    }
    return freightHaulerLookup[freightHaulerIdentifierTypeName];
};

export const updateTractorTrailer = ({
    shipmentUniqueName,
    tractorId,
    trailerId
}: {
    shipmentUniqueName: string;
    tractorId: string | null;
    trailerId: string | null;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const body = {
            tenFourLicensePlate: shipmentUniqueName,
            assets: {
                tractorReferenceNumber: tractorId,
                trailerReferenceNumber: trailerId
            }
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.shipmentMovement}`,
                body
            }) as GenericApiResponse<ShipmentMovementResponse>;
            dispatch(updateTractorTrailerSuccess(json.data[0]));
            dispatch(updatePageSuccess());
            toast.success('Tractor and Trailer ID\'s updated successfully.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating Tractor and Trailer ID\'s.'));
            }
        }
    };
};

export const updateMobileTracking = ({
    shipmentUniqueName,
    freightHaulerIdentifierTypeName,
    freightHaulerIdentifierName,
    driverPhoneNumber
}: {
    shipmentUniqueName: string;
    freightHaulerIdentifierTypeName: FreightHaulerIdentifierTypeName | null;
    freightHaulerIdentifierName: string | null;
    driverPhoneNumber: string;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const freightHaulerIdentifier = determineFreightHaulerIdentifier(freightHaulerIdentifierTypeName);

        const body = {
            TenFourLicensePlate: shipmentUniqueName,
            [freightHaulerIdentifier]: freightHaulerIdentifierName,
            DriverPhoneNumber: driverPhoneNumber,
            ShipmentTrackingType: 4
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.PublicApi}${Endpoints.freightPortalApi.mobileTrackingUpdate}`,
                body
            }) as { status: number; };

            if (json.status === 200) {
                dispatch(updateMobileTrackingSuccess({
                    shipmentUniqueName,
                    driverPhoneNumber
                }));
                dispatch(updatePageSuccess());
                toast.success('Mobile tracking number updated successfully.');
            } else {
                throw new Error('Phone Number did not update');
            }
        } catch (error) {
            if (error?.response?.data?.Errors?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.Errors.forEach(({ ErrorMessage }: { ErrorMessage: string; }): void => {
                    dispatch(updatePageFailed(ErrorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating mobile tracking.'));
            }
        }
    };
};

export const addShipmentNote = ({
    shipmentUniqueName,
    text,
    isShared,
    textDriver
}: {
    shipmentUniqueName: string;
    text: string;
    isShared: boolean;
    textDriver: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const body = {
            text,
            isShared,
            noteSentToDriver: textDriver
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.shipmentNotes}/${shipmentUniqueName}/notes`,
                body
            }) as GenericApiResponse<Note>;
            dispatch(addShipmentNoteSuccess({
                shipmentUniqueName,
                note: json.data[0]
            }));
            dispatch(updatePageSuccess());
            toast.success('Shipment note added successfully.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while adding shipment note.'));
            }
        }
    };
};

export const addShipmentStopNote = ({
    shipmentUniqueName,
    shipmentStopGUID,
    text,
    isShared,
    textDriver
}: {
    shipmentUniqueName: string;
    shipmentStopGUID: string;
    text: string;
    isShared: boolean;
    textDriver: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const body = {
            text,
            isShared,
            noteSentToDriver: textDriver
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.shipmentStopNotes}/${shipmentStopGUID}/notes`,
                body
            }) as GenericApiResponse<StopNote>;
            dispatch(addShipmentStopNoteSuccess({
                shipmentUniqueName,
                shipmentStopGUID,
                note: json.data[0]
            }));
            dispatch(updatePageSuccess());
            toast.success('Shipment stop note added successfully.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while adding shipment stop note.'));
            }
        }
    };
};

export const uploadDocument = ({
    shipmentUniqueName,
    freightHaulerIdentifierTypeName,
    freightHaulerIdentifierName,
    documentType,
    fileName,
    fileContent
}: {
    shipmentUniqueName: string;
    freightHaulerIdentifierTypeName: FreightHaulerIdentifierTypeName | null;
    freightHaulerIdentifierName: string | null;
    documentType: string;
    fileName: string;
    fileContent: string;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());
        const freightHaulerIdentifier = determineFreightHaulerIdentifier(freightHaulerIdentifierTypeName);

        const body = {
            TenFourLicensePlate: shipmentUniqueName,
            [freightHaulerIdentifier]: freightHaulerIdentifierName,
            DocumentType: documentType,
            FileName: fileName,
            FileContent: fileContent
        };

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.PublicApi}${Endpoints.freightPortalApi.addDocument}`,
                body
            }) as DocumentData;
            dispatch(uploadDocumentSuccess({
                shipmentUniqueName,
                document: {
                    documentID: json.DocumentId,
                    documentLink: json.DocumentRetrievalToken,
                    documentTypeName: json.DocumentTypeName,
                    documentType: PublicDocumentType[json.DocumentTypeId] as DocumentType,
                    uploadDate: json.UploadDate,
                    userName: json.UploadUserName
                }
            }));
            dispatch(updatePageSuccess());
            toast.success('Document successfully uploaded.');
        } catch (error) {
            if (error?.response?.data?.Errors?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.Errors.forEach(({ ErrorMessage }: { ErrorMessage: string; }): void => {
                    console.error('Document upload error: ', ErrorMessage);
                });
            }
            dispatch(updatePageFailed('Document failed to upload.'));
        }

    };
};

export const updateStopTimes = (body: UpdateStopTimesRequest): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        try {
            // TODO: remove once movement api can handle arrival and departure stop times correctly.
            // if it is a departure or arrival it must have a reportTime, positionEventTypeId, freightHaulerIdentifier, and freightHaulerIdentifierType.
            // This will make a patch request to the public api.
            if (body.reportTime && body.positionEventTypeId && body.freightHaulerIdentifier && body.freightHaulerIdentifierType) {
                const freightHaulerIdentifier = determineFreightHaulerIdentifier(body.freightHaulerIdentifierType);
                await ApiService.patch({
                    url: `${getState().availableServices.endpoints.PublicApi}${Endpoints.freightPortalApi.stopStatusUpdate}`,
                    useBearerToken: false,
                    body: {
                        tenFourLicensePlate: body.tenFourLicensePlate,
                        stopSequence: body.stopSequence,
                        reportTime: body.reportTime,
                        positionEventTypeId: body.positionEventTypeId,
                        [freightHaulerIdentifier]: body.freightHaulerIdentifier
                    }
                });
                dispatch(updateStopTimesSuccess({
                    tenFourLicensePlate: body.tenFourLicensePlate,
                    reportedDate: simpleJavascriptDateTimeFormat(body.reportTime),
                    positionEventType: body.positionEventTypeId === 1 ? 'Arrived' : 'Departed',
                    stopSequence: body.stopSequence
                }));
            } else {
                const json = await ApiService.post({
                    url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.shipmentMovement}`,
                    body
                }) as GenericApiResponse<ShipmentMovementResponse>;
                dispatch(updateStopTimesSuccess({
                    stopSequence: body.stopSequence,
                    ...json.data[0]
                }));
            }
            dispatch(updatePageSuccess());
            toast.success('Stop times updated successfully.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating stop times.'));
            }
        }
    };
};

export const updateShipmentPriority = ({
    shipmentUniqueName,
    isPriorityShipment
}: {
    shipmentUniqueName: string;
    isPriorityShipment: boolean;
    dispatchDialogActions?: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        try {
            await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.priority}/${shipmentUniqueName}/priority`,
                body: { isPriorityShipment }
            });
            dispatch(updateShipmentPrioritySuccess({
                shipmentUniqueName,
                isPriorityShipment
            }));
            dispatch(updatePageSuccess());
            toast.success(`Shipment ${isPriorityShipment ? 'set' : 'removed'} as priority.`);

        } catch (error) {
            dispatch(updatePageFailed('Error occurred while updating shipment priority.'));
        }
    };
};

export const addView = ({
    name,
    savedViewType,
    isDefault,
    filters,
    assignedViewUsers,
    // below params are needed to make the request to update the view list
    skip,
    take,
    searchTerm,
    sortColumn,
    sortDirection,
    viewFilters
}: {
    name: string;
    savedViewType: SavedViewType;
    isDefault: boolean;
    filters: Filter[];
    assignedViewUsers: ViewUser[];
    skip: number;
    take: number;
    searchTerm: string;
    sortColumn: ViewSortColumns;
    sortDirection: SortDirection;
    viewFilters: SavedViewType[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const body = {
            savedView: {
                viewName: name,
                savedViewType,
                isDefaultFlag: isDefault
            },
            savedViewProperty: filters,
            assignedViewUsers
        };

        try {
            await ApiService.post({
                url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.addView}`,
                body
            });
            dispatch(updatePageSuccess());
            dispatch(fetchViewsList({
                skip,
                take,
                searchTerm,
                sortColumn,
                sortDirection,
                viewFilters
            }));
            toast.success('View successfully added.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while adding view.'));
            }
        }
    };
};

export const updateView = ({
    customViewGuid,
    name,
    savedViewType,
    isDefault,
    filters,
    assignedViewUsers,
    // below params are needed to make the request to update the view list
    skip,
    take,
    searchTerm,
    sortColumn,
    sortDirection,
    viewFilters
}: {
    customViewGuid: string;
    name: string;
    savedViewType: SavedViewType;
    isDefault: boolean;
    filters: Filter[];
    assignedViewUsers: ViewUser[];
    skip: number;
    take: number;
    searchTerm: string;
    sortColumn: ViewSortColumns;
    sortDirection: SortDirection;
    viewFilters: SavedViewType[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const body = {
            savedView: {
                viewName: name,
                savedViewType,
                isDefaultFlag: isDefault
            },
            savedViewProperty: filters,
            assignedViewUsers
        };

        try {
            await ApiService.put({
                url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.editView}/${customViewGuid}`,
                body
            });
            dispatch(updatePageSuccess());
            dispatch(fetchViewsList({
                skip,
                take,
                searchTerm,
                sortColumn,
                sortDirection,
                viewFilters
            }));
            toast.success('View successfully updated.');
        } catch (error) {
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    dispatch(updatePageFailed(errorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating view.'));
            }
        }
    };
};

export const updateShipmentStatus = ({
    shipmentUniqueName,
    freightHaulerIdentifierTypeName,
    freightHaulerIdentifierName,
    shipmentStatus
}: {
    shipmentUniqueName: string;
    freightHaulerIdentifierTypeName: FreightHaulerIdentifierTypeName | null;
    freightHaulerIdentifierName: string | null;
    shipmentStatus: ShipmentStatus;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(updatePageRequest());

        const freightHaulerIdentifier = determineFreightHaulerIdentifier(freightHaulerIdentifierTypeName);

        const body = {
            TenFourLicensePlate: shipmentUniqueName,
            [freightHaulerIdentifier]: freightHaulerIdentifierName,
            ShipmentStatusType: shipmentStatus,
            OverrideShipmentStatusTypeConstraints: true
        };

        try {
            const json = await ApiService.patch({
                url: `${getState().availableServices.endpoints.PublicApi}${Endpoints.freightPortalApi.shipmentStatusUpdate}`,
                body
            }) as { status: number; };

            if (json.status === 200) {
                dispatch(updateShipmentStatusSuccess(shipmentUniqueName, shipmentStatus));
                dispatch(updatePageSuccess());
                toast.success('Shipment Status updated successfully.');
            } else {
                throw new Error('Shipment Status did not update');
            }
        } catch (error) {
            if (error?.response?.data?.Errors?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.Errors.forEach(({ ErrorMessage }: { ErrorMessage: string; }): void => {
                    dispatch(updatePageFailed(ErrorMessage));
                });
            } else {
                dispatch(updatePageFailed('Error occurred while updating shipment status.'));
            }
        }
    };
};

type DialogUpdatesActionTypes = ReturnType<typeof updatePageReset> | ReturnType<typeof updatePageRequest> | ReturnType<typeof updatePageSuccess> | ReturnType<typeof updatePageFailed>;

export const dialogUpdatesReducer = (
    state: DialogUpdates = initialState,
    action: DialogUpdatesActionTypes
): DialogUpdates => {
    switch (action.type) {
        case UPDATE_PAGE_RESET: {
            return {
                ...initialState
            };
        }
        case UPDATE_PAGE_REQUEST: {
            return {
                status: ApiStatus.Loading,
                errorMessage: ''
            };
        }
        case UPDATE_PAGE_FAILURE: {
            return {
                status: ApiStatus.Failure,
                errorMessage: action.payload
            };
        }
        case UPDATE_PAGE_SUCCESS: {
            return {
                status: ApiStatus.Success,
                errorMessage: ''
            };
        }
        default:
            return state;
    }
};
