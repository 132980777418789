import { UserManager, UserManagerSettings, User } from 'oidc-client';

const AuthService: {
    userManager: UserManager | null;
    user: User | null;
    baseUrl: string;
    sharingToken: string;
    handleError: (error: unknown) => void;
    sharingTokenAuthorization: (token: string) => void;
    initialize: (authorityUri: string) => void;
    isAuthenticated: () => boolean;
    getUser: () => Promise<User | null>;
    login: () => void;
    loginCallback: () => Promise<User | null>;
    logout: () => void;
    completeLogout: () => Promise<void>;
} = {
    userManager: null,
    user: null,
    baseUrl: window.location.origin,
    sharingToken: '',
    handleError: (error: unknown): void => {
        console.error('Problem with authentication endpoint: ', error);
    },
    sharingTokenAuthorization: (token): void => {
        AuthService.sharingToken = token;
    },
    initialize: (authorityUri): void => {
        const settings: UserManagerSettings = {
            authority: `${authorityUri}/.well-known/openid-configuration`,
            client_id: '3C4A13F0-B959-4D6F-97E5-25802B3B78D7',
            post_logout_redirect_uri: `${AuthService.baseUrl}/revoke`,
            redirect_uri: `${AuthService.baseUrl}/authorize`,
            response_type: 'code',
            scope: 'openid email profile',
            revokeAccessTokenOnSignout: true,
            automaticSilentRenew: true
        };

        AuthService.userManager = new UserManager(settings);
        AuthService.userManager.events.removeAccessTokenExpired((): void => { AuthService.logout(); });
    },
    isAuthenticated: (): boolean => {
        return AuthService.user !== null && AuthService.user.access_token !== '' && !AuthService.user.expired;
    },
    getUser: (): Promise<User | null> => {
        if (AuthService.isAuthenticated()) {
            return new Promise((resolve): void => {
                resolve(AuthService.user);
            });
        }
        if (AuthService.userManager !== null) {
            return AuthService.userManager.getUser()
                .then((response): User | null => {
                    AuthService.user = response;
                    return AuthService.user;
                })
                .catch((error): null => {
                    AuthService.handleError(error);
                    return null;
                });
        }
        return Promise.resolve(null);
    },
    login: (): void => {
        if (AuthService.userManager !== null) {
            AuthService.userManager.signinRedirect()
                .catch((error): void => { AuthService.handleError(error); });
        }
    },
    loginCallback: (): Promise<User | null> => {
        if (AuthService.userManager !== null) {
            return AuthService.userManager.signinRedirectCallback()
                .then((response): User => {
                    AuthService.user = response;
                    return AuthService.user;
                })
                .catch((error): null => {
                    AuthService.handleError(error);
                    return null;
                });
        }
        return Promise.resolve(null);
    },
    logout: (): void => {
        if (AuthService.userManager !== null) {
            AuthService.userManager.signoutRedirect();
        }
    },
    completeLogout: (): Promise<void> => {
        if (AuthService.userManager !== null) {
            return AuthService.userManager.signoutRedirectCallback()
                .then(() => {
                    if (AuthService.userManager !== null) {
                        AuthService.userManager.removeUser();
                        AuthService.userManager.clearStaleState();
                        AuthService.user = null;
                        // redirect to login
                        window.location.href = AuthService.baseUrl;
                    } else if (AuthService.sharingToken) {
                        AuthService.sharingToken = '';
                        // redirect to login
                        window.location.href = AuthService.baseUrl;
                    }
                })
                .catch((error): void => { AuthService.handleError(error); });
        }
        return Promise.resolve();
    }
};

export default AuthService;
