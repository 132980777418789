import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress,
    Tabs,
    Tab,
    Autocomplete,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiFileDocument } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { updatePageReset, uploadDocument } from '../../redux/dialogUpdates';
import { fetchShipmentDetails } from '../../redux/shipmentDetails';
import { ApiStatus } from '../../helpers/enums';
import { documentTypeOptions } from '../../helpers/hardcodedOptionLists';
import { AcceptedFile } from '../../interfaces/componentInterfaces';
import { Stop } from '../../interfaces/services/shipmentDetails';
import LabelValuePair from '../labels/labelValuePair';
import CommonDialog from './common/commonDialog';
import SingleDocumentUploader from '../fileUploaders/singleDocumentUploader';
import DocumentList from '../lists/documentList';
import ShipmentStopSelect from '../selects/shipmentStopSelect';
import StopContactLoading from '../loaders/labels/stopContactLoading';
import StopContact from '../labels/stopContact';

const classesPrefix = 'documentsDialog';

const classes = {
    documentUploadContainer: `${classesPrefix}-documentUploadContainer`,
    fileSelectedContainer: `${classesPrefix}-fileSelectedContainer`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`&.${classes.documentUploadContainer}`]: {
            marginTop: '12px'
        },
        [`& .${classes.fileSelectedContainer}`]: {
            margin: '16px 0'
        }
    };
});

enum DocumentsTab {
    Shipment,
    Stops
}

const DocumentsDialog = ({
    shipmentUniqueName,
    initialStopSequence,
    isOpen,
    closeDialog
}: {
    shipmentUniqueName: string;
    initialStopSequence?: number;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState(initialStopSequence !== undefined ? DocumentsTab.Stops : DocumentsTab.Shipment);
    const [currentStop, setCurrentStop] = useState<Stop | null>(null);
    const [fileToUpload, setFileToUpload] = useState<AcceptedFile | null>(null);
    const [selectedDocumentType, setSelectedDocumentType] = useState<{ value: string; label: string; } | null>(null);
    const [documentUploadErrors, setDocumentUploadErrors] = useState<string[]>([]);

    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    useEffect((): void => {
        // only fetch if we don't already have the details in redux
        if (shipmentUniqueName !== shipment.shipmentUniqueName) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipment.shipmentUniqueName, dispatch]);

    useEffect((): void => {
        // only fetch if we refreshed and cleared our shipment status back to idle
        if (shipmentStatus === ApiStatus.Idle) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipmentStatus, dispatch]);

    // Once the shipment details api succeeds, update the currentStop to be the initialStopSequence.
    useEffect((): void => {
        if (shipmentStatus === ApiStatus.Success) {
            setCurrentStop(shipment.stops[initialStopSequence || 0]);
        }
    }, [shipmentStatus, shipment.stops, initialStopSequence]);

    // Validate the document upload values and determine if form can be submitted
    useEffect((): void => {
        setCanSubmitForm(
            currentTab === DocumentsTab.Shipment &&
            documentUploadErrors.length === 0 &&
            fileToUpload !== null &&
            selectedDocumentType !== null &&
            shipment.freightHauler.identifiers[0]?.identifier !== null
        );
    }, [currentTab, documentUploadErrors, fileToUpload, selectedDocumentType, shipment.freightHauler.identifiers]);

    // If the API update is successful, trigger the dialog to reset
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());
            setFileToUpload(null);
            setSelectedDocumentType(null);
        }
    }, [status, dispatch]);

    // Don't allow the dialog to close if the API update is still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleUpload = (): void => {
        if (fileToUpload && selectedDocumentType) {
            const body = {
                shipmentUniqueName,
                freightHaulerIdentifierTypeName: shipment.freightHauler.identifiers[0]?.freightHaulerIdentifierType,
                freightHaulerIdentifierName: shipment.freightHauler.identifiers[0]?.identifier,
                documentType: selectedDocumentType.value,
                fileName: fileToUpload.name,
                fileContent: fileToUpload.fileContent
            };
            dispatch(uploadDocument(body));
        }
    };

    const handleDocumentTypeChange = (selectedOption: { value: string; label: string; } | null): void => {
        setSelectedDocumentType(selectedOption);
    };

    const renderFileSelected = (): JSX.Element => {
        if (!fileToUpload) {
            return <LabelValuePair label='No File Selected' value='' data-qa='noFileSelected' />;
        }
        return <LabelValuePair label='File Selected' value={fileToUpload.name} data-qa='fileSelected' />;
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <Fragment>
                <Tabs
                    value={currentTab}
                    onChange={(event, value: number): void => {
                        setCurrentTab(value);
                    }}
                    disabled={canSubmitForm}
                    centered
                    indicatorColor='primary'
                    textColor='primary'
                    data-qa='documents-tabList'
                >
                    <Tab
                        label='Shipment Documents'
                        value={DocumentsTab.Shipment}
                        data-qa='shipmentDocuments-tab'
                    />

                    <Tab
                        label='Stop Documents'
                        value={DocumentsTab.Stops}
                        data-qa='stopDocuments-tab'
                    />
                </Tabs>
                {
                    currentTab === DocumentsTab.Shipment &&
                    <DocumentList shipmentUniqueName={shipmentUniqueName} documents={shipment.documents} />
                }

                {
                    currentTab === DocumentsTab.Stops &&
                    <Fragment>
                        {
                            shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading ?
                                (
                                    <Fragment>
                                        <Skeleton variant='rectangular' width='100%' height='48px' />
                                        <StopContactLoading />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <ShipmentStopSelect
                                            isDisabled={false}
                                            stops={shipment.stops}
                                            currentStop={currentStop}
                                            handleChange={(value: Stop | null): void => {
                                                setCurrentStop(value);
                                            }}
                                        />
                                        <StopContact
                                            street1={currentStop?.address.streetAddress1 || null}
                                            street2={currentStop?.address.streetAddress2 || null}
                                            city={currentStop?.address.city || null}
                                            region={currentStop?.address.state || null}
                                            postal={currentStop?.address.postalCode || null}
                                            country={currentStop?.address.countryCode || null}
                                            contactName={currentStop?.contact.name || null}
                                            phoneNumber={currentStop?.contact.phoneNumber || null}
                                            phoneExtension={currentStop?.contact.phoneExtension || null}
                                        />
                                    </Fragment>
                                )
                        }
                        <DocumentList shipmentUniqueName={shipmentUniqueName} documents={currentStop?.documents || []} />
                    </Fragment>
                }

                { /* If the shipment or stop is not shared, allow documents to be uploaded. Right now we will only support shipment documents being uploaded. */
                    currentTab === DocumentsTab.Shipment && shareType === null &&
                    <StyledGrid container className={classes.documentUploadContainer}>
                        <Grid item xs={12}>
                            <SingleDocumentUploader
                                disabled={status === ApiStatus.Loading}
                                handleDocumentsToUpload={(file: AcceptedFile | null): void => {
                                    setFileToUpload(file);
                                }}
                                handleUploaderErrors={(errors: string[]): void => {
                                    setDocumentUploadErrors(errors);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.fileSelectedContainer}>
                            {renderFileSelected()}
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                size='small'
                                disabled={!fileToUpload || status === ApiStatus.Loading}
                                options={documentTypeOptions}
                                getOptionLabel={(option): string => { return option.label; }}
                                onChange={(event, newValue): void => {
                                    handleDocumentTypeChange(newValue);
                                }}
                                value={selectedDocumentType}
                                renderInput={(params): JSX.Element => {
                                    return (
                                        <TextField
                                            {...params}
                                            label='Document Type'
                                            placeholder={selectedDocumentType?.value ? '' : 'Select...'}
                                            fullWidth={true}
                                            margin='normal'
                                            variant='filled'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off',
                                                'data-qa': 'documentType-input'
                                            }}
                                        />
                                    );
                                }}
                                data-qa='documentType-select'
                            />
                        </Grid>
                    </StyledGrid>
                }

                {
                    documentUploadErrors.map((error: string): JSX.Element => {
                        return (
                            <Grid item xs={12} key={error}>
                                <Typography variant='caption' color='error'>{error}</Typography>
                            </Grid>
                        );
                    })
                }

                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>{errorMessage}</Typography>
                    </Grid>
                }

                {
                    !shipment.freightHauler.identifiers[0]?.identifier &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                    </Grid>
                }
            </Fragment>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            headerIcon={mdiFileDocument}
            headerText='Documents'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='close-button'
                    >
                        Close
                    </Button>
                    {
                        shareType === null &&
                        <Button
                            color='primary'
                            variant='contained'
                            disabled={status === ApiStatus.Loading || !canSubmitForm}
                            onClick={handleUpload}
                            startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                            data-qa='upload-button'
                        >
                            {
                                status === ApiStatus.Loading ? 'Uploading' : 'Upload'
                            }
                        </Button>
                    }
                </Fragment>
            )}
        />
    );
};

export default DocumentsDialog;
