import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, TextField, FormHelperText, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { getGeofenceRadiusInMiles, getGeofenceRadiusInFeet } from '../../../helpers/maps/mapUtils';
import { ApiStatus, PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';
import LocationGeofenceMap from '../../maps/locationGeofenceMap';

import mapMarker from '../../../assets/images/maps/markers_location.png';

const classesPrefix = 'deliveryStatusGeofenceCard';

const classes = {
    radiusControlRoot: `${classesPrefix}-radiusControlRoot`,
    sliderWrapperRoot: `${classesPrefix}-sliderWrapperRoot`,
    radiusTextInputWrapper: `${classesPrefix}-radiusTextInputWrapper`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.radiusControlRoot}`]: {
            margin: '16px 0'
        },
        [`& .${classes.sliderWrapperRoot}`]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        [`& .${classes.radiusTextInputWrapper}`]: {
            width: '70px',
            marginLeft: '16px'
        }
    };
});

const DeliveryStatusGeofenceCard = ({
    handleIsCardValidChange
}: {
    handleIsCardValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [isGeofenceRadiusInFeetValid, setIsGeofenceRadiusInFeetValid] = useState(true);

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const organizationInformationStatus = useTypedSelector((state) => { return state.organization.organizationInformationStatus; });
    const organizationInformation = useTypedSelector((state) => { return state.organization.organizationInformation; });

    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const { geofenceRadiusInFeet } = organizationEditablePreferences;

    useEffect((): void => {
        const isValid = geofenceRadiusInFeet >= 52 && geofenceRadiusInFeet <= 26400;
        setIsGeofenceRadiusInFeetValid(isValid);
    }, [geofenceRadiusInFeet]);

    useEffect((): void => {
        handleIsCardValidChange(isGeofenceRadiusInFeetValid);
    }, [handleIsCardValidChange, isGeofenceRadiusInFeetValid]);

    const handleGeofenceRadiusSliderChange = (event: Event, geofenceRadiusInMiles: number | number[]): void => {
        if (typeof geofenceRadiusInMiles === 'number') {
            dispatch(editPreferences(PreferencesFieldNames.geofenceRadiusInFeet, getGeofenceRadiusInFeet(geofenceRadiusInMiles)));
        }
    };

    const handleGeofenceRadiusTextFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.geofenceRadiusInFeet, getGeofenceRadiusInFeet(Number(event.currentTarget.value))));
    };

    return (
        <PreferenceCard
            header='Delivery Status Geofence (Org Default)'
            informationText='Set your geofence radius'
        >
            <Typography component='p' variant='body2'>
                How many miles from your location do you want your geofence radius to trigger a delivery status update? From 0.01 to 5 miles.
            </Typography>

            <StyledDiv className={classes.radiusControlRoot}>
                <div className={classes.sliderWrapperRoot}>
                    <Slider
                        min={0.01}
                        max={5}
                        step={0.01}
                        marks={[
                            { value: 0, label: '0' },
                            { value: 1, label: '1' },
                            { value: 2, label: '2' },
                            { value: 3, label: '3' },
                            { value: 4, label: '4' },
                            { value: 5, label: '5' }
                        ]}
                        valueLabelDisplay='auto'
                        value={getGeofenceRadiusInMiles(geofenceRadiusInFeet)}
                        onChange={handleGeofenceRadiusSliderChange}
                        data-qa='geofenceRadiusInFeet-slider'
                    />

                    <div className={classes.radiusTextInputWrapper}>
                        <TextField
                            variant='standard'
                            label='Miles'
                            value={getGeofenceRadiusInMiles(geofenceRadiusInFeet)}
                            onChange={handleGeofenceRadiusTextFieldChange}
                            type='number'
                            InputProps={{ inputProps: { min: 0.01, max: 5 } }}
                            error={!isGeofenceRadiusInFeetValid}
                            data-qa='geofenceRadiusInFeet-input'
                        />
                    </div>
                </div>

                {
                    !isGeofenceRadiusInFeetValid &&
                    <FormHelperText error>Radius must be between 0.01 and 5 miles.</FormHelperText>
                }
            </StyledDiv>

            {
                organizationInformation === null &&
                <FormHelperText error>Sorry, we were unable to retrieve your company location.</FormHelperText>
            }

            <LocationGeofenceMap
                isFetchingData={organizationInformationStatus === ApiStatus.Idle || organizationInformationStatus === ApiStatus.Loading}
                fixedLocationLongLat={
                    organizationInformation?.geocodedMailingAddress?.position?.latitude && organizationInformation?.geocodedMailingAddress?.position?.longitude
                        ? [organizationInformation.geocodedMailingAddress.position.longitude, organizationInformation.geocodedMailingAddress.position.latitude]
                        : [0.0, 0.0]
                }
                geofencePolygon={null}
                geofenceRadiusInFeet={geofenceRadiusInFeet}
                markerImage={mapMarker}
                paletteMode={paletteMode}
            />
        </PreferenceCard>
    );
};

export default DeliveryStatusGeofenceCard;
