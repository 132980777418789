import React from 'react';
import {
    Button,
    IconButton,
    Dialog,
    Toolbar,
    Typography,
    Slide
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import Hidden from '../../layouts/hidden';

const classesPrefix = 'transparentFullScreenDialog';

const classes = {
    toolbar: `${classesPrefix}-toolbar`,
    paper: `${classesPrefix}-paper`,
    title: `${classesPrefix}-title`,
    additionalActions: `${classesPrefix}-additionalActions`,
    close: `${classesPrefix}-close`
};

const StyledDialog = styled(Dialog)(({ theme }) => {
    return {
        [`& .${classes.paper}`]: {
            backgroundColor: 'transparent'
        },
        [`& .${classes.toolbar}`]: {
            top: 0,
            right: 0,
            left: 'auto',
            position: 'sticky',
            zIndex: 11
        },
        [`& .${classes.title}`]: {
            marginRight: '16px'
        },
        [`& .${classes.additionalActions}`]: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end'
        },
        [`& .${classes.close}`]: {
            marginLeft: '16px'
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.toolbar}`]: {
                paddingLeft: '8px',
                paddingRight: '8px'
            },
            [`& .${classes.close}`]: {
                padding: '4px',
                marginLeft: '0px'
            }
        }
    };
});

const Transition = React.forwardRef((
    transitionProps: TransitionProps & { children?: React.ReactElement<any, any>; },
    ref: React.Ref<unknown>
): JSX.Element => {
    // the latest version of MUI caused me to do this, i'm sure they'll fix it eventually...
    // @ts-ignore
    return <Slide direction='up' ref={ref} {...transitionProps} />;
});
Transition.displayName = 'Transition';

const TransparentFullScreenDialog = ({
    headerText,
    handleDialogClose,
    additionalActions,
    children
}: {
    /** Header text for the dialog. */
    headerText: string;
    /** Function triggered by the close action */
    handleDialogClose: () => void;
    /** Addtional actions to render in the dialog toolbar. */
    additionalActions?: React.ReactNode;
    /** Children to render in the dialog. */
    children?: React.ReactNode;
}): JSX.Element => {
    return (
        <StyledDialog fullScreen open={true} TransitionComponent={Transition} classes={{ paper: classes.paper }} data-qa='dialog-container'>
            <Toolbar classes={{ root: classes.toolbar }}>
                <Typography variant='h6' className={classes.title} data-qa='dialog-header'>{headerText}</Typography>
                <div className={classes.additionalActions}>
                    {additionalActions}
                    <Hidden breakpoint='xs'>
                        <Button
                            variant='outlined'
                            aria-label='close dialog'
                            className={classes.close}
                            onClick={(): void => { handleDialogClose(); }}
                            data-qa='dialog-action-close'
                        >
                            Close
                        </Button>
                    </Hidden>
                    <Hidden breakpoint='sm' direction='up'>
                        <IconButton
                            aria-label='close dialog'
                            className={classes.close}
                            onClick={(): void => { handleDialogClose(); }}
                            data-qa='dialog-action-close-mobile'
                        >
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    </Hidden>
                </div>
            </Toolbar>
            {
                children &&
                <section data-qa='dialog-content'>
                    {children}
                </section>
            }
        </StyledDialog>
    );
};

export default TransparentFullScreenDialog;
