import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ApplicationResource } from '../../interfaces/services/organization';
import { ApplicationResourceNames } from '../../helpers/enums';
import { useAccessTokenUrl } from '../../hooks/useAccessTokenUrl';

const classesPrefix = 'pageViewButton';

const classes = {
    navigationButton: `${classesPrefix}-navigationButton`
};

const StyledButton = styled(Button)(({ theme }) => {
    return {
        [`&.${classes.navigationButton}`]: {
            marginLeft: '16px',
            color: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                border: `1px solid ${theme.palette.primary.contrastText}`,
                opacity: 0.9
            },
            '&.Mui-disabled': {
                color: theme.palette.primary.contrastText,
                borderColor: 'transparent'
            }
        }
    };
}) as typeof Button;

const PageViewButton = ({ app }: { app: ApplicationResource; }): JSX.Element => {

    const location = useLocation();
    const url = useAccessTokenUrl(app.resourcePath);

    const views: { [k in ApplicationResourceNames]?: string; } = {
        [ApplicationResourceNames.ShipmentsOverview]: 'Cards',
        [ApplicationResourceNames.ShipmentListActive]: 'List',
        [ApplicationResourceNames.ShipmentsCalendar]: 'Calendar',
        [ApplicationResourceNames.ShipmentListHistory]: 'History',
        [ApplicationResourceNames.OrdersOverview]: 'Cards',
        [ApplicationResourceNames.OrdersListActive]: 'List'
    };

    const label = views[app.resourceKey];

    if (label) {
        return (
            <StyledButton
                classes={{ root: classes.navigationButton }}
                aria-label={label}
                variant='outlined'
                disabled={location.pathname === app.resourcePath}
                color='primary'
                size='medium'
                component={Link}
                to={url}
                data-qa={`${label.toLowerCase()}View-button`}
            >
                {label}
            </StyledButton>
        );
    }

    return <Fragment key={app.resourceKey} />;
};

export default PageViewButton;
