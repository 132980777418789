import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
    Tooltip,
    Skeleton
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import {
    mdiDotsVertical,
    mdiAlarm,
    mdiAttachment,
    mdiMessageBulleted,
    mdiNumeric,
    mdiCellphone,
    mdiShareVariant,
    mdiRefresh,
    mdiFire,
    mdiBarcodeScan,
    mdiTruck
} from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { reopenShipment, updateShipmentPriority } from '../../redux/shipments';
import { ApiStatus, ShareType } from '../../helpers/enums';
import { handleShareUrlCopy } from '../../helpers/navigationUtils';
import Hidden from '../layouts/hidden';
import ShipmentActionButton from '../buttons/shipmentActionButton';
import ShipmentDetailsIconButton from '../links/shipmentDetailsIconButton';
import ShipmentDetailsMenuItem from '../links/shipmentDetailsMenuItem';
import EditTimesDialog from '../dialogs/editTimesDialog';
import AddReferenceNumbersDialog from '../dialogs/addReferenceNumbersDialog';
import ManageDriverDialog from '../dialogs/manageDriverDialog';
import NotesDialog from '../dialogs/notesDialog';
import DocumentsDialog from '../dialogs/documentsDialog';
import OrderExceptionsDialog from '../dialogs/orderExceptionsDialog';
import ShipmentStatusDialog from '../dialogs/shipmentStatusDialog';

const classesPrefix = 'shipmentDetailsActions';

const classes = {
    actions: `${classesPrefix}-actions`,
    loadingIcons: `${classesPrefix}-loadingIcons`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.actions}`]: {
            justifyContent: 'space-around',
            display: 'flex'
        },
        [`& .${classes.loadingIcons}`]: {
            margin: '6px'
        }
    };
});

const ShipmentDetailsActions = ({
    showShipmentDetailsNavigation = false
}: {
    showShipmentDetailsNavigation?: boolean;
}): JSX.Element => {

    const theme = useTheme();
    const dispatch = useDispatch();

    const UrlShortenerApi = useTypedSelector((state) => { return state.availableServices.endpoints.UrlShortenerApi; });
    const shortUrlExpiryDays = useTypedSelector((state) => { return state.organization.organizationPreferences?.shortUrlExpiryDays; });

    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });

    const skip = useTypedSelector((state) => { return state.shipments.skip; });
    const take = useTypedSelector((state) => { return state.shipments.take; });
    const searchTerm = useTypedSelector((state) => { return state.shipments.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.shipments.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.shipments.sortDirection; });
    const filters = useTypedSelector((state) => { return state.shipments.filters; });

    const shareType = useTypedSelector((state) => { return state.user.shareType; });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editTimesDialogIsOpen, setEditTimesDialogIsOpen] = useState(false);
    const [addReferenceNumbersDialogIsOpen, setAddReferenceNumbersDialogIsOpen] = useState(false);
    const [manageDriverDialogIsOpen, setManageDriverDialogIsOpen] = useState(false);
    const [notesDialogIsOpen, setNotesDialogIsOpen] = useState(false);
    const [documentsDialogIsOpen, setDocumentsDialogIsOpen] = useState(false);
    const [orderExceptionsDialogIsOpen, setOrderExceptionsDialogIsOpen] = useState(false);
    const [shipmentStatusDialogIsOpen, setShipmentStatusDialogIsOpen] = useState(false);

    if (shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading) {
        return (
            <StyledDiv className={classes.actions}>
                <Hidden breakpoint='md' direction='up'>
                    <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                </Hidden>
                <Hidden breakpoint='md' direction='down'>
                    {
                        shareType === null &&
                        <Fragment>
                            <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                            <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                            <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                            <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                            <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                            <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                            <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                        </Fragment>
                    }
                    <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                    {
                        showShipmentDetailsNavigation &&
                        <Skeleton className={classes.loadingIcons} variant='circular' width='24px' height='24px' />
                    }
                </Hidden>
            </StyledDiv>
        );
    }

    if (shareType === ShareType.CustomerReadOnly) {
        return (
            <Fragment>
                <ShipmentActionButton
                    title='Share Shipment'
                    handleClick={(): void => {
                        handleShareUrlCopy({
                            urlShortenerApi: UrlShortenerApi,
                            pageUrl: `/shipmentDetails/${shipment.shipmentUniqueName}`,
                            token: shipment.singleShipmentSharingToken,
                            successMessage: 'Shipment share url copied to clipboard.',
                            shortenedUrlName: `Shipment ${shipment.shipmentUniqueName}`,
                            expiryDays: shortUrlExpiryDays
                        });
                    }}
                    data-qa='shareShipment-action-open'
                    mdiIcon={mdiShareVariant}
                    iconColor={theme.palette.text.primary}
                />

                {
                    showShipmentDetailsNavigation &&
                    <ShipmentDetailsIconButton
                        shipmentUniqueName={shipment.shipmentUniqueName}
                        modeType={shipment.modeType}
                        alwaysOpenInNewTab={true}
                        iconColor={theme.palette.text.primary}
                    />
                }
            </Fragment>
        );
    }

    return (
        <Fragment>
            <StyledDiv className={classes.actions} data-qa='shipmentDetailsActions-container'>
                <Hidden breakpoint='md' direction='up'>
                    <Tooltip title='More Actions'>
                        <IconButton
                            onClick={(event): void => {
                                setAnchorEl(event.currentTarget);
                            }}
                            data-qa='moreActions-action'
                        >
                            <Icon
                                path={mdiDotsVertical}
                                size={1}
                                color={theme.palette.text.primary}
                            />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={(): void => {
                            setAnchorEl(null);
                        }}
                        data-qa='moreActions-menu'
                    >
                        {
                            shipment.isActive &&
                            <MenuItem
                                onClick={(): void => {
                                    setAnchorEl(null);
                                    setManageDriverDialogIsOpen(true);
                                }}
                                data-qa='manageDriver-action'
                            >
                                <ListItemIcon>
                                    <Icon
                                        path={mdiCellphone}
                                        size={1}
                                        color={theme.palette.text.primary}
                                    />
                                </ListItemIcon>
                                <Typography variant='inherit'>Manage Driver</Typography>
                            </MenuItem>
                        }

                        {
                            shipment.isActive &&
                            <MenuItem
                                onClick={(): void => {
                                    setAnchorEl(null);
                                    setEditTimesDialogIsOpen(true);
                                }}
                                data-qa='editTimes-action'
                            >
                                <ListItemIcon>
                                    <Icon
                                        path={mdiAlarm}
                                        size={1}
                                        color={theme.palette.text.primary}
                                    />
                                </ListItemIcon>
                                <Typography variant='inherit'>Edit Times</Typography>
                            </MenuItem>
                        }

                        {
                            shipment.isActive &&
                            <MenuItem
                                onClick={(): void => {
                                    setAnchorEl(null);
                                    setAddReferenceNumbersDialogIsOpen(true);
                                }}
                                data-qa='addReferenceNumbers-action'
                            >
                                <ListItemIcon>
                                    <Icon
                                        path={mdiNumeric}
                                        size={1}
                                        color={theme.palette.text.primary}
                                    />
                                </ListItemIcon>
                                <Typography variant='inherit'>Add Reference Numbers</Typography>
                            </MenuItem>
                        }

                        {
                            shipment.isActive &&
                            <MenuItem
                                onClick={(): void => {
                                    setAnchorEl(null);
                                    setShipmentStatusDialogIsOpen(true);
                                }}
                                data-qa='updateShipmentStatus-action'
                            >
                                <ListItemIcon>
                                    <Icon
                                        path={mdiTruck}
                                        size={1}
                                        color={theme.palette.text.primary}
                                    />
                                </ListItemIcon>
                                <Typography variant='inherit'>Update Shipment Status</Typography>
                            </MenuItem>
                        }

                        {
                            shipment.isActive === false &&
                            <MenuItem
                                onClick={(): void => {
                                    dispatch(reopenShipment({
                                        shipmentUniqueName: shipment.shipmentUniqueName,
                                        skip,
                                        take,
                                        searchTerm,
                                        sortColumn,
                                        sortDirection,
                                        filters
                                    }));
                                }}
                                data-qa='reopen-shipment'
                            >
                                <ListItemIcon>
                                    <Icon
                                        path={mdiRefresh}
                                        size={1}
                                        color={theme.palette.text.primary}
                                    />
                                </ListItemIcon>
                                <Typography variant='inherit'>Reopen</Typography>
                            </MenuItem>
                        }

                        <MenuItem
                            onClick={(): void => {
                                setAnchorEl(null);
                                handleShareUrlCopy({
                                    urlShortenerApi: UrlShortenerApi,
                                    pageUrl: `/shipmentDetails/${shipment.shipmentUniqueName}`,
                                    token: shipment.singleShipmentSharingToken,
                                    successMessage: 'Shipment share url copied to clipboard.',
                                    shortenedUrlName: `Shipment ${shipment.shipmentUniqueName}`,
                                    expiryDays: shortUrlExpiryDays
                                });
                            }}
                            data-qa='shareShipment-action'
                        >
                            <ListItemIcon>
                                <Icon
                                    path={mdiShareVariant}
                                    size={1}
                                    color={theme.palette.text.primary}
                                />
                            </ListItemIcon>
                            <Typography variant='inherit'>Share Shipment</Typography>
                        </MenuItem>

                        <MenuItem
                            onClick={(): void => {
                                setAnchorEl(null);
                                setNotesDialogIsOpen(true);
                            }}
                            data-qa='addNotes-action'
                        >
                            <ListItemIcon>
                                <Icon
                                    path={mdiMessageBulleted}
                                    size={1}
                                    color={theme.palette.text.primary}
                                />
                            </ListItemIcon>
                            <Typography variant='inherit'>View and Add Notes</Typography>
                        </MenuItem>

                        <MenuItem
                            onClick={(): void => {
                                setAnchorEl(null);
                                setDocumentsDialogIsOpen(true);
                            }}
                            data-qa='addDocuments-action'
                        >
                            <ListItemIcon>
                                <Icon
                                    path={mdiAttachment}
                                    size={1}
                                    color={theme.palette.text.primary}
                                />
                            </ListItemIcon>
                            <Typography variant='inherit'>View and Attach Documents</Typography>
                        </MenuItem>

                        <MenuItem
                            onClick={(): void => {
                                setAnchorEl(null);
                                dispatch(updateShipmentPriority({
                                    shipmentUniqueName: shipment.shipmentUniqueName,
                                    isPriorityShipment: !shipment.isPriorityShipment
                                }));
                            }}
                            data-qa='shipmentPriority-action'
                        >
                            <ListItemIcon>
                                <Icon
                                    path={mdiFire}
                                    size={1}
                                    color={theme.palette.text.primary}
                                />
                            </ListItemIcon>
                            <Typography variant='inherit'>{`${shipment.isPriorityShipment ? 'Remove' : 'Add'} Priority Shipment Flag`}</Typography>
                        </MenuItem>

                        {
                            shipment.totalShipmentAndOrderExceptions > 0 &&
                            <MenuItem
                                onClick={(): void => {
                                    setAnchorEl(null);
                                    setOrderExceptionsDialogIsOpen(true);
                                }}
                                data-qa='orderExceptions-action'
                            >
                                <ListItemIcon>
                                    <Icon
                                        path={mdiBarcodeScan}
                                        size={1}
                                        color={theme.palette.text.primary}
                                    />
                                </ListItemIcon>
                                <Typography variant='inherit'>Order Exceptions</Typography>
                            </MenuItem>
                        }

                        {
                            showShipmentDetailsNavigation &&
                            <ShipmentDetailsMenuItem
                                shipmentUniqueName={shipment.shipmentUniqueName}
                                iconColor={theme.palette.text.primary}
                                handleClick={(): void => {
                                    setAnchorEl(null);
                                }}
                            />
                        }
                    </Menu>
                </Hidden>
                <Hidden breakpoint='md' direction='down'>
                    {
                        shipment.isActive &&
                        <Fragment>
                            <ShipmentActionButton
                                title='Manage Driver'
                                handleClick={(): void => {
                                    setManageDriverDialogIsOpen(true);
                                }}
                                data-qa='manageDriver-action-open'
                                mdiIcon={mdiCellphone}
                                iconColor={theme.palette.text.primary}
                            />
                            <ShipmentActionButton
                                title='Edit Times'
                                handleClick={(): void => {
                                    setEditTimesDialogIsOpen(true);
                                }}
                                data-qa='editTimes-action'
                                mdiIcon={mdiAlarm}
                                iconColor={theme.palette.text.primary}
                            />
                            <ShipmentActionButton
                                title='Add Reference Numbers'
                                handleClick={(): void => {
                                    setAddReferenceNumbersDialogIsOpen(true);
                                }}
                                data-qa='addReferenceNumbers-action-open'
                                mdiIcon={mdiNumeric}
                                iconColor={theme.palette.text.primary}
                            />
                            <ShipmentActionButton
                                title='Update Shipment Status'
                                handleClick={(): void => {
                                    setShipmentStatusDialogIsOpen(true);
                                }}
                                data-qa='updateShipmentStatus-action-open'
                                mdiIcon={mdiTruck}
                                iconColor={theme.palette.text.primary}
                            />
                        </Fragment>
                    }
                    {
                        shipment.isActive === false &&
                        <ShipmentActionButton
                            title='Reopen'
                            handleClick={(): void => {
                                dispatch(reopenShipment({
                                    shipmentUniqueName: shipment.shipmentUniqueName,
                                    skip,
                                    take,
                                    searchTerm,
                                    sortColumn,
                                    sortDirection,
                                    filters
                                }));
                            }}
                            data-qa='reopen-shipment-action'
                            mdiIcon={mdiRefresh}
                            iconColor={theme.palette.text.primary}
                        />
                    }

                    <ShipmentActionButton
                        title='Share Shipment'
                        handleClick={(): void => {
                            handleShareUrlCopy({
                                urlShortenerApi: UrlShortenerApi,
                                pageUrl: `/shipmentDetails/${shipment.shipmentUniqueName}`,
                                token: shipment.singleShipmentSharingToken,
                                successMessage: 'Shipment share url copied to clipboard.',
                                shortenedUrlName: `Shipment ${shipment.shipmentUniqueName}`,
                                expiryDays: shortUrlExpiryDays
                            });
                        }}
                        data-qa='shareShipment-action-open'
                        mdiIcon={mdiShareVariant}
                        iconColor={theme.palette.text.primary}
                    />
                    <ShipmentActionButton
                        title='View and Add Notes'
                        handleClick={(): void => {
                            setNotesDialogIsOpen(true);
                        }}
                        data-qa='addNotes-action'
                        mdiIcon={mdiMessageBulleted}
                        iconColor={theme.palette.text.primary}
                    />
                    <ShipmentActionButton
                        title='View and Attach Documents'
                        handleClick={(): void => {
                            setDocumentsDialogIsOpen(true);
                        }}
                        data-qa='addDocuments-action'
                        mdiIcon={mdiAttachment}
                        iconColor={theme.palette.text.primary}
                    />
                    <ShipmentActionButton
                        title={`${shipment.isPriorityShipment ? 'Remove' : 'Add'} Priority Shipment Flag`}
                        handleClick={(): void => {
                            dispatch(updateShipmentPriority({
                                shipmentUniqueName: shipment.shipmentUniqueName,
                                isPriorityShipment: !shipment.isPriorityShipment
                            }));
                        }}
                        data-qa='shipmentPriority-action'
                        mdiIcon={mdiFire}
                        iconColor={theme.palette.text.primary}
                    />
                    {
                        shipment.totalShipmentAndOrderExceptions > 0 &&
                        <ShipmentActionButton
                            title='Order Exceptions'
                            handleClick={(): void => {
                                setOrderExceptionsDialogIsOpen(true);
                            }}
                            data-qa='orderExceptions-action'
                            mdiIcon={mdiBarcodeScan}
                            iconColor={theme.palette.text.primary}
                        />
                    }

                    {
                        showShipmentDetailsNavigation &&
                        <ShipmentDetailsIconButton
                            shipmentUniqueName={shipment.shipmentUniqueName}
                            modeType={shipment.modeType}
                            alwaysOpenInNewTab={true}
                            iconColor={theme.palette.text.primary}
                        />
                    }
                </Hidden>
            </StyledDiv>

            {
                editTimesDialogIsOpen &&
                <EditTimesDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={editTimesDialogIsOpen}
                    closeDialog={(): void => {
                        setEditTimesDialogIsOpen(false);
                    }}
                />
            }

            {
                addReferenceNumbersDialogIsOpen &&
                <AddReferenceNumbersDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    initialTractorId={shipment.tractorReferenceNumber}
                    initialTrailerId={shipment.trailerReferenceNumber}
                    isOpen={addReferenceNumbersDialogIsOpen}
                    closeDialog={(): void => {
                        setAddReferenceNumbersDialogIsOpen(false);
                    }}
                />
            }

            {
                manageDriverDialogIsOpen &&
                <ManageDriverDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    freightHaulerIdentifierTypeName={shipment.freightHauler.identifiers[0]?.freightHaulerIdentifierType}
                    freightHaulerIdentifierName={shipment.freightHauler.identifiers[0]?.identifier}
                    initialPhoneNumber={shipment.mobileTrackingNumber}
                    isOpen={manageDriverDialogIsOpen}
                    closeDialog={(): void => {
                        setManageDriverDialogIsOpen(false);
                    }}
                />
            }

            {
                notesDialogIsOpen &&
                <NotesDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={notesDialogIsOpen}
                    closeDialog={(): void => {
                        setNotesDialogIsOpen(false);
                    }}
                />
            }

            {
                documentsDialogIsOpen &&
                <DocumentsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={documentsDialogIsOpen}
                    closeDialog={(): void => {
                        setDocumentsDialogIsOpen(false);
                    }}
                />
            }

            {
                orderExceptionsDialogIsOpen &&
                <OrderExceptionsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={orderExceptionsDialogIsOpen}
                    closeDialog={(): void => {
                        setOrderExceptionsDialogIsOpen(false);
                    }}
                />
            }

            {
                shipmentStatusDialogIsOpen &&
                <ShipmentStatusDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    freightHaulerIdentifierTypeName={shipment.freightHauler.identifiers[0]?.freightHaulerIdentifierType}
                    freightHaulerIdentifierName={shipment.freightHauler.identifiers[0]?.identifier}
                    shipmentStatus={shipment.shipmentStatus}
                    isOpen={shipmentStatusDialogIsOpen}
                    closeDialog={(): void => {
                        setShipmentStatusDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentDetailsActions;
