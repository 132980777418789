import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'homeButton';

const classes = {
    button: `${classesPrefix}-button`
};

const StyledButton = styled(Button)(() => {
    return {
        [`&.${classes.button}`]: {
            padding: 0,
            justifyContent: 'start',
            minWidth: 0
        }
    };
}) as typeof Button;

const HomeButton = ({
    tooltipTitle,
    homeRoute,
    children
}: {
    /** Text for the home button tooltip. */
    tooltipTitle: string;
    /** The route the home button will route to when clicked. */
    homeRoute: string;
    /** Children items to render in the button. */
    children: React.ReactNode;
}): JSX.Element => {
    return (
        <Tooltip title={tooltipTitle}>
            <StyledButton
                component={Link}
                to={homeRoute}
                aria-label='Back to Home'
                classes={{ root: classes.button }}
                data-qa='home-button'
            >
                {children}
            </StyledButton>
        </Tooltip>
    );
};

export default HomeButton;
