import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import {
    ApiStatus,
    SortDirection
} from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { Filter, ShipmentListData, ShipmentRequestPayload, ShipmentService } from '../../interfaces/services/shipment';
import { ShipmentSortOption } from '../../interfaces/componentInterfaces';
import { shipmentSortOrderList } from '../../helpers/hardcodedOptionLists';
import {
    UPDATE_TRACTOR_TRAILER_SUCCESS,
    UPDATE_MOBILE_TRACKING_SUCCESS,
    ADD_SHIPMENT_NOTE_SUCCESS,
    ADD_SHIPMENT_STOP_NOTE_SUCCESS,
    UPLOAD_DOCUMENT_SUCCESS,
    UPDATE_STOP_TIMES_SUCCESS,
    UPDATE_SHIPMENT_PRIORITY,
    UPDATE_SHIPMENT_STATUS_SUCCESS,
    updateTractorTrailerSuccess,
    updateMobileTrackingSuccess,
    addShipmentNoteSuccess,
    addShipmentStopNoteSuccess,
    uploadDocumentSuccess,
    updateStopTimesSuccess,
    updateShipmentPrioritySuccess,
    updateShipmentStatusSuccess
} from '../dialogUpdates';
import { USER_SETTINGS_SUCCESS, receiveUserSettings } from '../user';

const SHIPMENT_LIST_UPDATE_FILTERS = 'SHIPMENT_LIST_UPDATE_FILTERS';
const SHIPMENT_LIST_REQUEST = 'SHIPMENT_LIST_REQUEST';
const SHIPMENT_LIST_SUCCESS = 'SHIPMENT_LIST_SUCCESS';
const SHIPMENT_LIST_FAILURE = 'SHIPMENT_LIST_FAILURE';

const SET_SHIPMENT_LIST_EXPORT_STATUS = 'SET_SHIPMENT_LIST_EXPORT_STATUS';

const REOPEN_SHIPMENT_REQUEST = 'REOPEN_SHIPMENT_REQUEST';
const REOPEN_SHIPMENT_FAILURE = 'REOPEN_SHIPMENT_FAILURE';

interface Shipments {
    isExporting: boolean;
    shipmentListStatus: ApiStatus;
    shipmentList: ShipmentListData[];
    totalRecords: number;
    activeShipments: boolean;
    skip: number;
    take: number;
    sortColumn: keyof ShipmentListData;
    sortDirection: SortDirection;
    searchTerm: string;
    filters: Filter[];
    filtersName: string;
}

const requestShipments = (json: ShipmentRequestPayload): GenericAction<typeof SHIPMENT_LIST_REQUEST, ShipmentRequestPayload> => {
    return {
        type: SHIPMENT_LIST_REQUEST,
        payload: json
    };
};

const receiveShipments = (json: ShipmentService): GenericAction<typeof SHIPMENT_LIST_SUCCESS, ShipmentService> => {
    return {
        type: SHIPMENT_LIST_SUCCESS,
        payload: json
    };
};

const requestShipmentsFailed = (): Action<typeof SHIPMENT_LIST_FAILURE> => {
    return {
        type: SHIPMENT_LIST_FAILURE
    };
};

export const setShipmentListExportStatus = (isExporting: boolean): GenericAction<typeof SET_SHIPMENT_LIST_EXPORT_STATUS, { isExporting: boolean; }> => {
    return {
        type: SET_SHIPMENT_LIST_EXPORT_STATUS,
        payload: {
            isExporting
        }
    };
};

export const updateFilters = (filters: Filter[], filtersName: string = ''): GenericAction<typeof SHIPMENT_LIST_UPDATE_FILTERS,
    {
        filters: Filter[];
        filtersName: string;
    }> => {
    return {
        type: SHIPMENT_LIST_UPDATE_FILTERS,
        payload: {
            filters,
            filtersName
        }
    };
};

export const fetchShipments = ({
    activeShipments,
    skip = 0,
    take = 100,
    searchTerm = '',
    sortColumn,
    sortDirection,
    filters = [],
    isAutoRefreshRequest = false
}: {
    activeShipments: boolean;
    skip?: number;
    take?: number;
    searchTerm?: string;
    sortColumn?: keyof ShipmentListData;
    sortDirection?: SortDirection;
    filters?: Filter[];
    /** dispatch the shipment request to set request payload and update the status if normal request */
    isAutoRefreshRequest?: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const sortPreference = (): ShipmentSortOption | undefined => {
            return shipmentSortOrderList.find((sortItem): boolean => {
                return sortItem.value === getState().organization.organizationPreferences?.sortOptionFieldType;
            });
        };

        const requestBody: ShipmentRequestPayload = {
            activeShipments,
            skip,
            take,
            searchTerm,
            sortColumn: sortColumn || sortPreference()?.sortColumn || 'originScheduleStartDateTime',
            sortDirection: sortDirection || sortPreference()?.sortDirection || SortDirection.Descending,
            filters
        };

        // do not dispatch requestShipments when doing an auto refresh
        if (isAutoRefreshRequest === false) {
            dispatch(requestShipments(requestBody));
        }

        try {
            const json = await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.headers}`,
                body: requestBody
            }) as ShipmentService;
            dispatch(receiveShipments(json));

            if (isAutoRefreshRequest) {
                toast.success('Shipments successfully auto-refreshed.', {
                    toastId: 'shipmentRefreshSuccess',
                    autoClose: 5000,
                    hideProgressBar: true,
                    pauseOnFocusLoss: false,
                    closeOnClick: true
                });
            }
        } catch (error) {
            dispatch(requestShipmentsFailed());
            if (error?.messages?.length > 0) {
                error.messages.forEach((message: string): void => {
                    toast.error(message);
                });
            } else if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((message: string): void => {
                    toast.error(message);
                });
            } else {
                toast.error('Error occurred while fetching shipment list.');
            }
        }
    };
};

const requestReopenShipment = (): Action<typeof REOPEN_SHIPMENT_REQUEST> => {
    return {
        type: REOPEN_SHIPMENT_REQUEST
    };
};

const requestReopenFailed = (): Action<typeof REOPEN_SHIPMENT_FAILURE> => {
    return {
        type: REOPEN_SHIPMENT_FAILURE
    };
};

export const reopenShipment = ({
    shipmentUniqueName,
    skip,
    take,
    searchTerm,
    sortColumn,
    sortDirection,
    filters
}: {
    shipmentUniqueName: string;
    skip: number;
    take: number;
    sortColumn: keyof ShipmentListData;
    sortDirection: SortDirection;
    searchTerm: string;
    filters: Filter[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestReopenShipment());

        try {
            await ApiService.post({ url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.reopen}/${shipmentUniqueName}/reopen` });
            dispatch(fetchShipments({
                activeShipments: false,
                skip,
                take,
                sortColumn,
                sortDirection,
                searchTerm,
                filters
            }));
            toast.success('Shipment successfully reopened.');
        } catch (error) {
            dispatch(requestReopenFailed());
            toast.error('Failed to reopen shipment.');
        }
    };
};

export const updateShipmentPriority = ({
    shipmentUniqueName,
    isPriorityShipment
}: {
    shipmentUniqueName: string;
    isPriorityShipment: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {

        try {
            await ApiService.post({
                url: `${getState().availableServices.endpoints.ShipmentsApi}${Endpoints.shipmentApi.priority}/${shipmentUniqueName}/priority`,
                body: { isPriorityShipment }
            });
            dispatch(updateShipmentPrioritySuccess({
                shipmentUniqueName,
                isPriorityShipment
            }));
            toast.success(`Shipment ${isPriorityShipment ? 'set' : 'removed'} as priority.`);
        } catch (error) {
            toast.error(`Failed to ${isPriorityShipment ? 'set' : 'remove'} Shipment as priority.`);
        }
    };
};

type ShipmentsActionTypes =
    ReturnType<typeof updateFilters> |
    ReturnType<typeof requestShipments> | ReturnType<typeof receiveShipments> | ReturnType<typeof requestShipmentsFailed> | ReturnType<typeof setShipmentListExportStatus> |
    ReturnType<typeof requestReopenShipment> | ReturnType<typeof requestReopenFailed> |
    ReturnType<typeof updateTractorTrailerSuccess> | ReturnType<typeof updateMobileTrackingSuccess> |
    ReturnType<typeof addShipmentNoteSuccess> | ReturnType<typeof addShipmentStopNoteSuccess> |
    ReturnType<typeof uploadDocumentSuccess> | ReturnType<typeof updateStopTimesSuccess> | ReturnType<typeof updateShipmentPrioritySuccess> | ReturnType<typeof updateShipmentStatusSuccess> |
    ReturnType<typeof receiveUserSettings>;

export const shipmentListReducer = (shipmentData: Shipments = {
    isExporting: false,
    shipmentListStatus: ApiStatus.Idle,
    shipmentList: [],
    totalRecords: 0,
    activeShipments: true,
    skip: 0,
    take: 100,
    sortColumn: 'originScheduleStartDateTime',
    sortDirection: SortDirection.Descending,
    searchTerm: '',
    filters: [],
    filtersName: ''
}, action: ShipmentsActionTypes): Shipments => {
    switch (action.type) {
        case SHIPMENT_LIST_REQUEST: {
            return {
                ...shipmentData,
                shipmentListStatus: ApiStatus.Loading,
                totalRecords: 0,
                activeShipments: action.payload.activeShipments,
                skip: action.payload.skip,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection,
                searchTerm: action.payload.searchTerm
            };
        }
        case SHIPMENT_LIST_SUCCESS: {
            return {
                ...shipmentData,
                shipmentListStatus: ApiStatus.Success,
                shipmentList: action.payload.data,
                totalRecords: action.payload.totalPossibleRecords
            };
        }
        case SHIPMENT_LIST_FAILURE: {
            return {
                ...shipmentData,
                shipmentListStatus: ApiStatus.Failure,
                shipmentList: [],
                totalRecords: 0
            };
        }
        case SET_SHIPMENT_LIST_EXPORT_STATUS: {
            return {
                ...shipmentData,
                isExporting: action.payload.isExporting
            };
        }
        case SHIPMENT_LIST_UPDATE_FILTERS: {
            return {
                ...shipmentData,
                shipmentListStatus: ApiStatus.Idle,
                filters: action.payload.filters,
                filtersName: action.payload.filtersName
            };
        }
        case REOPEN_SHIPMENT_REQUEST: {
            return {
                ...shipmentData,
                shipmentListStatus: ApiStatus.Idle
            };
        }
        case REOPEN_SHIPMENT_FAILURE: {
            // If reopen fails a snackbar is shown but the loader state needs to end to redisplay the shipment list
            return {
                ...shipmentData,
                shipmentListStatus: ApiStatus.Success
            };
        }
        case UPDATE_TRACTOR_TRAILER_SUCCESS: {
            return {
                ...shipmentData,
                shipmentList: shipmentData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                        return {
                            ...shipment,
                            ...action.payload.assets?.tractorReferenceNumber !== undefined && {
                                tractorReferenceNumber: action.payload.assets.tractorReferenceNumber
                            },
                            ...action.payload.assets?.trailerReferenceNumber !== undefined && {
                                trailerReferenceNumber: action.payload.assets.trailerReferenceNumber
                            }
                        };
                    }
                    return shipment;
                })
            };
        }
        case UPDATE_MOBILE_TRACKING_SUCCESS: {
            return {
                ...shipmentData,
                shipmentList: shipmentData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                        return {
                            ...shipment,
                            mobileTrackingPhoneNumber: action.payload.driverPhoneNumber
                        };
                    }
                    return shipment;
                })
            };
        }
        case ADD_SHIPMENT_NOTE_SUCCESS:
        case ADD_SHIPMENT_STOP_NOTE_SUCCESS: {
            return {
                ...shipmentData,
                shipmentList: shipmentData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                        return {
                            ...shipment,
                            hasShipmentNotes: true
                        };
                    }
                    return shipment;
                })
            };
        }
        case UPLOAD_DOCUMENT_SUCCESS: {
            return {
                ...shipmentData,
                shipmentList: shipmentData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                        return {
                            ...shipment,
                            hasShipmentDocuments: true
                        };
                    }
                    return shipment;
                })
            };
        }
        case UPDATE_STOP_TIMES_SUCCESS: {
            return {
                ...shipmentData,
                shipmentList: shipmentData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.tenFourLicensePlate) {
                        // Update origin dates
                        if (action.payload.stopSequence === 1) {
                            return {
                                ...shipment,
                                ...action.payload.appointmentDate && {
                                    originAppointmentStartDateTime: action.payload.appointmentDate
                                },
                                ...action.payload.appointmentEndDate && {
                                    originAppointmentEndDateTime: action.payload.appointmentEndDate
                                },
                                ...action.payload.estimatedDeliveryDate && {
                                    originStopETA: action.payload.estimatedDeliveryDate
                                },
                                // Update active stop ETA
                                ...action.payload.stopSequence === shipment.activeStopSequence && action.payload.estimatedDeliveryDate && {
                                    activeStopETA: action.payload.estimatedDeliveryDate
                                }
                            };
                        }
                        // Update destination dates
                        if (action.payload.stopSequence === shipment.numberOfStops) {
                            return {
                                ...shipment,
                                ...action.payload.appointmentDate && {
                                    destinationAppointmentStartDateTime: action.payload.appointmentDate
                                },
                                ...action.payload.appointmentEndDate && {
                                    destinationAppointmentEndDateTime: action.payload.appointmentEndDate
                                },
                                ...action.payload.estimatedDeliveryDate && {
                                    destinationStopETA: action.payload.estimatedDeliveryDate
                                },
                                // Update active stop ETA
                                ...action.payload.stopSequence === shipment.activeStopSequence && action.payload.estimatedDeliveryDate && {
                                    activeStopETA: action.payload.estimatedDeliveryDate
                                }
                            };
                        }
                        return shipment;
                    }
                    return shipment;
                })
            };
        }
        case UPDATE_SHIPMENT_PRIORITY: {
            return {
                ...shipmentData,
                shipmentList: shipmentData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                        return {
                            ...shipment,
                            isPriorityShipment: action.payload.isPriorityShipment
                        };
                    }
                    return shipment;
                })
            };
        }
        case UPDATE_SHIPMENT_STATUS_SUCCESS: {
            return {
                ...shipmentData,
                shipmentList: shipmentData.shipmentList.map((shipment) => {
                    if (shipment.shipmentUniqueName === action.payload.shipmentUniqueName) {
                        return {
                            ...shipment,
                            shipmentStatus: action.payload.shipmentStatus
                        };
                    }
                    return shipment;
                })
            };
        }
        case USER_SETTINGS_SUCCESS: {
            if (action.payload.defaultView?.savedViewProperty && action.payload.defaultView.savedView.viewName) {
                return {
                    ...shipmentData,
                    shipmentListStatus: ApiStatus.Idle,
                    filters: action.payload.defaultView.savedViewProperty,
                    filtersName: action.payload.defaultView.savedView.viewName
                };
            }
            return shipmentData;
        }
        default:
            return shipmentData;
    }
};
