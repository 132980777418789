import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Typography,
    Tooltip
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'autoSetShipmentsToDeliveredCard';

const classes = {
    informationIcon: `${classesPrefix}-informationIcon`
};

const StyledFormGroup = styled(FormGroup)(() => {
    return {
        [`& .${classes.informationIcon}`]: {
            verticalAlign: 'middle',
            marginLeft: '4px'
        }
    };
});

const AutoSetShipmentsToDeliveredCard = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleAutoSetToDeliveredChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.autoSetAtConsigneeToDeliveredFlag, event.target.checked));
    };

    return (
        <PreferenceCard header='Auto Set Shipments to Delivered'>
            <Typography component='p' variant='body2'>
                Auto set shipments to delivered when arrives at the Consignee?
            </Typography>

            <StyledFormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organizationEditablePreferences.autoSetAtConsigneeToDeliveredFlag}
                            onChange={handleAutoSetToDeliveredChange}
                            color='primary'
                        />
                    }
                    label={
                        <Fragment>
                            Auto Set to Delivered
                            <Tooltip title='Checking this box will automatically change the shipment status to Delivered when it reaches At Consignee.' aria-label='info'>
                                <Icon
                                    className={classes.informationIcon}
                                    path={mdiInformation}
                                    size={1}
                                    color={theme.palette.text.secondary}
                                />
                            </Tooltip>
                        </Fragment>
                    }
                    data-qa='autoSetAtConsigneeToDeliveredFlag-switch'
                />
            </StyledFormGroup>
        </PreferenceCard>
    );
};

export default AutoSetShipmentsToDeliveredCard;
