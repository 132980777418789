import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';

const classesPrefix = 'mapButton';

const classes = {
    smallControlButton: `${classesPrefix}-smallControlButton`
};

const StyledIconButton = styled(IconButton)(() => {
    return {
        [`&.${classes.smallControlButton}`]: {
            padding: '4px'
        }
    };
});

const MapButton = ({
    title,
    isActive,
    mdiIcon,
    handleMapButtonClick,
    isDisabled = false,
    'data-qa': dataQa
}: {
    /** The tooltip text for the button. */
    title: string;
    /** Indicator whether the button is active or not. */
    isActive: boolean;
    /** MDI Icon to display. */
    mdiIcon: string;
    /** Function to handle the click of the button. */
    handleMapButtonClick: () => void;
    /** Indicator whether the button is disabled or not. */
    isDisabled?: boolean;
    /** data attribute for automation testing. */
    'data-qa': string;
}): JSX.Element => {

    const theme = useTheme();

    return (
        <Tooltip title={title} placement='left'>
            <span>
                <StyledIconButton
                    data-qa={dataQa}
                    className={classes.smallControlButton}
                    disabled={isDisabled}
                    onClick={(e): void => {
                        e.currentTarget.blur();
                        handleMapButtonClick();
                    }}
                >
                    <Icon
                        path={mdiIcon}
                        size={1}
                        color={isActive ? theme.appColors.action : undefined}
                    />
                </StyledIconButton>
            </span>
        </Tooltip>
    );
};

export default MapButton;
