import React, {
    Fragment, useEffect, useReducer
} from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import {
    Button,
    Grid,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { GenericAction, useTypedSelector } from '../../redux';
import { updateOrdersFilters } from '../../redux/orders';
import { Filter } from '../../interfaces/services/shipment';
import {
    INITIALIZE_FILTERS,
    UPDATE_FILTER,
    FilterState,
    initialState
} from '../../interfaces/filterInterfaces';
import { AllowedFilterPropertyName } from '../../helpers/enums';
import {
    deliveryStatusOptions,
    shipmentStatusOptions
} from '../../helpers/hardcodedOptionLists';
import FilterDateRangePicker from '../pickers/filterDateRangePicker';
import AsyncCreatable from '../selects/asyncCreatable';
import MultiSelect from '../selects/multiSelect';

const classesPrefix = 'filters';

const classes = {
    filtersContainer: `${classesPrefix}-filtersContainer`,
    actionsContainer: `${classesPrefix}-actionsContainer`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.filtersContainer}`]: {
            flexGrow: 1,
            overflowY: 'auto',
            padding: '8px 8px 8px'
        }
    };
});

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.actionsContainer}`]: {
            padding: '8px 16px',
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper
        }
    };
});

const OrderFilters = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const filters = useTypedSelector((state) => { return state.orders.filters; });

    const reducer = (
        state: FilterState,
        action: Action<typeof INITIALIZE_FILTERS> | GenericAction<typeof UPDATE_FILTER,
            {
                filterId: AllowedFilterPropertyName;
                filterValues: string[];
            }>
    ): FilterState => {
        switch (action.type) {
            case UPDATE_FILTER: {
                return {
                    ...state,
                    [action.payload.filterId]: action.payload.filterValues
                };
            }
            case INITIALIZE_FILTERS: {
                return initialState;
            }
            default: {
                return state;
            }
        }
    };

    const [filterState, localDispatch] = useReducer(reducer, initialState);

    const updateFilterState = (filterId: string, filterValues: string[]): void => {
        localDispatch({
            type: UPDATE_FILTER,
            payload: {
                filterId: filterId as AllowedFilterPropertyName,
                filterValues
            }
        });
    };

    useEffect((): void => {
        // if the redux filters are empty, empty the local filter state
        if (filters.length === 0) {
            localDispatch({
                type: INITIALIZE_FILTERS
            });
        } else {
            filters.forEach((filter): void => {
                updateFilterState(filter.propertyName, filter.propertyValues);
            });
        }
    }, [filters]);

    const createFilterList = (): Filter[] => {
        const filterList = Object.entries(filterState).reduce((accumulator: Filter[], [filterName, filterValues]): Filter[] => {
            if (filterValues.length > 0) {
                accumulator.push({
                    propertyName: filterName as AllowedFilterPropertyName,
                    propertyValues: filterValues
                });
            }
            return accumulator;
        }, []);

        return filterList;
    };

    const handleResetClick = (): void => {
        localDispatch({
            type: INITIALIZE_FILTERS
        });
    };

    const handleApplyClick = (): void => {
        const newFilters = createFilterList();
        const filtersName = newFilters.length > 0 ? 'Filtered' : '';
        dispatch(updateOrdersFilters(newFilters, filtersName));
    };

    return (
        <Fragment>
            <StyledDiv className={classes.filtersContainer} data-qa='filters-container'>
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.PickupScheduleStartDateTime}
                    label={isMobile ? 'Pickup Sched. Start' : 'Pickup Scheduled Start'}
                    currentValue={filterState[AllowedFilterPropertyName.PickupScheduleStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.PickupScheduleEndDateTime}
                    label={isMobile ? 'Pickup Sched. End' : 'Pickup Scheduled End'}
                    currentValue={filterState[AllowedFilterPropertyName.PickupScheduleEndDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.PickupAppointmentStartDateTime}
                    label={isMobile ? 'Pickup Appt. Start' : 'Pickup Appointment Start'}
                    currentValue={filterState[AllowedFilterPropertyName.PickupAppointmentStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.PickupAppointmentEndDateTime}
                    label={isMobile ? 'Pickup Appt. End' : 'Pickup Appointment End'}
                    currentValue={filterState[AllowedFilterPropertyName.PickupAppointmentEndDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DeliveryScheduleStartDateTime}
                    label={isMobile ? 'Delivery Sched. Start' : 'Delivery Scheduled Start'}
                    currentValue={filterState[AllowedFilterPropertyName.DeliveryScheduleStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DeliveryScheduleEndDateTime}
                    label={isMobile ? 'Delivery Sched. End' : 'Delivery Scheduled End'}
                    currentValue={filterState[AllowedFilterPropertyName.DeliveryScheduleEndDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DeliveryAppointmentStartDateTime}
                    label={isMobile ? 'Delivery Appt. Start' : 'Delivery Appointment Start'}
                    currentValue={filterState[AllowedFilterPropertyName.DeliveryAppointmentStartDateTime]}
                    handleChange={updateFilterState}
                />
                <FilterDateRangePicker
                    id={AllowedFilterPropertyName.DeliveryAppointmentEndDateTime}
                    label={isMobile ? 'Delivery Appt. End' : 'Delivery Appointment End'}
                    currentValue={filterState[AllowedFilterPropertyName.DeliveryAppointmentEndDateTime]}
                    handleChange={updateFilterState}
                />

                <AsyncCreatable
                    id={AllowedFilterPropertyName.CustomerName}
                    label='Customers'
                    filterName='CustomerName'
                    values={filterState[AllowedFilterPropertyName.CustomerName]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.CarrierName}
                    label='Carrier'
                    filterName='CarrierName'
                    values={filterState[AllowedFilterPropertyName.CarrierName]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.PickupCity}
                    label='Pickup City'
                    filterName='PickupCityName'
                    values={filterState[AllowedFilterPropertyName.PickupCity]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.DeliveryCity}
                    label='Delivery City'
                    filterName='DeliveryCityName'
                    values={filterState[AllowedFilterPropertyName.DeliveryCity]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.PickupState}
                    label='Pickup State'
                    filterName='PickupStateCode'
                    values={filterState[AllowedFilterPropertyName.PickupState]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.DeliveryState}
                    label='Delivery State'
                    filterName='DeliveryStateCode'
                    values={filterState[AllowedFilterPropertyName.DeliveryState]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.PickupLocationCode}
                    label='Pickup Location Code'
                    filterName='PickupFixedLocationCode'
                    values={filterState[AllowedFilterPropertyName.PickupLocationCode]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <AsyncCreatable
                    id={AllowedFilterPropertyName.DeliveryLocationCode}
                    label='Delivery Location Code'
                    filterName='DeliveryFixedLocationCode'
                    values={filterState[AllowedFilterPropertyName.DeliveryLocationCode]}
                    handleChange={updateFilterState}
                    isOrder={true}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.OrderPickupStatus}
                    label='Pickup Status'
                    values={filterState[AllowedFilterPropertyName.OrderPickupStatus]}
                    availableOptions={deliveryStatusOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.OrderDeliveryStatus}
                    label='Delivery Status'
                    values={filterState[AllowedFilterPropertyName.OrderDeliveryStatus]}
                    availableOptions={deliveryStatusOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
                <MultiSelect
                    id={AllowedFilterPropertyName.ShipmentStatus}
                    label='Shipment Status'
                    values={filterState[AllowedFilterPropertyName.ShipmentStatus]}
                    availableOptions={shipmentStatusOptions}
                    isLoading={false}
                    handleChange={updateFilterState}
                />
            </StyledDiv>

            <StyledGrid
                container
                justifyContent='space-between'
                className={classes.actionsContainer}
            >
                <Grid item>
                    <Button size='small' onClick={handleResetClick} data-qa='resetFilters-button'>Reset</Button>
                </Grid>
                <Grid item>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        onClick={handleApplyClick}
                        data-qa='applyFilters-button'
                    >
                        Apply
                    </Button>
                </Grid>
            </StyledGrid>
        </Fragment>
    );
};

export default OrderFilters;
