import React, { Fragment, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';

import { View } from '../../interfaces/services/views';
import ViewDialog from '../dialogs/viewDialog';

const ViewCloneButton = ({
    view
}: {
    view: View;
}): JSX.Element => {
    const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);

    return (
        <Fragment>
            <Tooltip title='Clone'>
                <IconButton
                    size='small'
                    onClick={(): void => {
                        setViewDialogIsOpen(true);
                    }}
                    data-qa='cloneView-action'
                >
                    <Icon path={mdiContentCopy} size={1} />
                </IconButton>
            </Tooltip>

            {
                viewDialogIsOpen &&
                <ViewDialog
                    createViewType={view.savedView.savedViewType}
                    view={{
                        // when cloning a view, we'll set the view name to blank for them to fill in on the dialog.
                        ...view,
                        savedView: {
                            ...view.savedView,
                            savedViewGUID: '',
                            viewName: ''
                        }
                    }}
                    isOpen={viewDialogIsOpen}
                    closeDialog={(): void => {
                        setViewDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ViewCloneButton;
