import React, { Fragment } from 'react';
import LabelValuePairLoading from './labelValuePairLoading';

const StopContactLoading = (): JSX.Element => {
    return (
        <Fragment>
            <LabelValuePairLoading label='Address' width='200px' fontSize='12px' />
            <LabelValuePairLoading label='Contact Name' width='100px' fontSize='12px' />
            <LabelValuePairLoading label='Phone' width='80px' fontSize='12px' />
        </Fragment>
    );
};

export default StopContactLoading;
