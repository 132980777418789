import React, { Fragment } from 'react';
import { Tooltip, TooltipProps, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';

import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { formatCityRegionPostalCountry } from '../../helpers/addressUtils';

const classesPrefix = 'deliveryStatusTooltip';

const classes = {
    tooltipNote: `${classesPrefix}-tooltipNote`,
    tooltipEvent: `${classesPrefix}-tooltipEvent`,
    currentPositionInfoIcon: `${classesPrefix}-currentPositionInfoIcon`
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => {
    return <Tooltip {...props} classes={{ popper: className }} />;
})(() => {
    return {
        [`& .${classes.tooltipNote}`]: {
            fontSize: '10px',
            paddingTop: '8px'
        },
        [`& .${classes.tooltipEvent}`]: {
            fontSize: '12px'
        }
    };
});

const StyledIcon = styled(Icon)(() => {
    return {
        [`&.${classes.currentPositionInfoIcon}`]: {
            marginLeft: '4px',
            verticalAlign: 'sub'
        }
    };
});

const DeliveryStatusTooltip = ({
    timestamp,
    currentCity,
    currentState
}: {
    timestamp: string;
    currentCity: string | null;
    currentState: string | null;
}): JSX.Element => {
    const theme = useTheme();

    return (
        <StyledTooltip
            title={
                <Fragment>
                    <Typography className={classes.tooltipEvent}>
                        {`Last Position Event Received: ${zonedDateTimeToDisplay(timestamp)}`}
                    </Typography>
                    <Typography className={classes.tooltipEvent}>
                        {`Last Position City & State: ${formatCityRegionPostalCountry({ city: currentCity, region: currentState })}`}
                    </Typography>
                    <Typography className={classes.tooltipNote}>
                        * Note: Last position timestamp is local to the position event location
                    </Typography>
                </Fragment>
            }
        >
            <StyledIcon
                path={mdiInformationOutline}
                size='20px'
                color={theme.palette.text.secondary}
                className={classes.currentPositionInfoIcon}
            />
        </StyledTooltip>
    );
};

export default DeliveryStatusTooltip;
