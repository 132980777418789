import React from 'react';
import { styled } from '@mui/material/styles';

interface LogOutButtonProps {
    /** id for the log out button. */
    id: string;
    /** The onClick handler that is defined by by the caller and passed in. */
    onClick: (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void;
    /** data attribute for automation testing. */
    'data-qa': string;
    /** Children items to render in the button. */
    children: React.ReactNode;
}

const classesPrefix = 'logOutButton';

const classes = {
    button: `${classesPrefix}-button`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.button}`]: {
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            padding: '8px 24px 8px 16px'
        }
    };
});

const LogOutButton = React.forwardRef<HTMLDivElement, LogOutButtonProps>(({
    id,
    onClick,
    children,
    'data-qa': dataQa
}: LogOutButtonProps, ref) => {
    return (
        <StyledDiv
            ref={ref}
            role='button'
            tabIndex={-1}
            id={id}
            className={classes.button}
            data-qa={dataQa}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>): void => {
                onClick(event);
            }}
            onClick={(event: React.MouseEvent<HTMLDivElement>): void => {
                onClick(event);
            }}
        >
            {children}
        </StyledDiv>
    );
});
LogOutButton.displayName = 'LogOutButton';

export default LogOutButton;
