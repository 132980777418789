import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { ModeType } from '../../helpers/enums';

const ModeTypeTabs = ({
    currentTab,
    handleTabChange
}: {
    currentTab: ModeType;
    handleTabChange: (newTab: ModeType) => void;
}): JSX.Element => {
    const handleModeTypeTabChange = (event: React.SyntheticEvent, newValue: ModeType): void => {
        handleTabChange(newValue);
    };

    return (
        <Tabs
            value={currentTab}
            onChange={handleModeTypeTabChange}
            variant='scrollable'
            indicatorColor='primary'
            textColor='primary'
        >
            <Tab
                label={ModeType.Truckload}
                value={ModeType.Truckload}
                data-qa={`preferences-${ModeType.Truckload}`}
            />
            <Tab
                label={ModeType.LTL}
                value={ModeType.LTL}
                data-qa={`preferences-${ModeType.LTL}`}
            />
            <Tab
                label={ModeType.Parcel}
                value={ModeType.Parcel}
                data-qa={`preferences-${ModeType.Parcel}`}
            />
            <Tab
                label={ModeType.Rail}
                value={ModeType.Rail}
                data-qa={`preferences-${ModeType.Rail}`}
            />
            <Tab
                label={ModeType.Ocean}
                value={ModeType.Ocean}
                data-qa={`preferences-${ModeType.Ocean}`}
            />
            <Tab
                label={ModeType.Unknown}
                value={ModeType.Unknown}
                data-qa={`preferences-${ModeType.Unknown}`}
            />
        </Tabs>
    );
};

export default ModeTypeTabs;
