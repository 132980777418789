import React, { Fragment } from 'react';

import {
    Fade,
    IconButton,
    Link,
    Paper,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { ApiStatus, ShareType } from '../../helpers/enums';
import { useTypedSelector } from '../../redux';

const classesPrefix = 'endOfLifeHeader';

const classes = {
    notificationBanner: `${classesPrefix}-notificationBanner`,
    bannerLink: `${classesPrefix}-bannerLink`,
    bannerIcon: `${classesPrefix}-bannerIcon`,
    bannerText: `${classesPrefix}-bannerText`
};
const StyledPaper = styled(Paper)(() => {
    return {
        [`&.${classes.notificationBanner}`]: {
            backgroundColor: '#fff681',
            padding: '12px',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            borderWidth: 0,
            borderTopWidth: 1,
            zIndex: 10
        },
        [`& .${classes.bannerLink}`]: {
            display: 'block',
            textAlign: 'center'
        },
        [`& .${classes.bannerIcon}`]: {
            color: 'black',
            position: 'absolute',
            right: 8,
            top: 8
        },
        [`& .${classes.bannerText}`]: {
            color: 'black',
            fontWeight: 650,
            fontSize: '18px'
        }
    };
});
const EndOfLifeHeader = (): JSX.Element => {
    const userLoginStatus = useTypedSelector((state) => { return state.user.userLoginStatus; });
    const organizationInformationStatus = useTypedSelector((state) => { return state.organization.organizationInformationStatus; });
    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const organizationInformation = useTypedSelector((state) => { return state.organization.organizationInformation; });
    const isSharing = shareType === ShareType.Shipment || shareType === ShareType.Stop || shareType === ShareType.Unknown;
    const [bannerOpen, setBannerOpen] = React.useState(true);
    const lazyBoyRID = '9CF3B082-03B1-4B30-9346-7DCDA34830A8'; // this is used to hide the banner for la-z-boy.
    const fillmoreRID = '6f7c9e50-8936-4bed-9299-4bbc51750cba'; // this is a test account for staging tests.

    const closeBanner = (): void => {
        setBannerOpen(false);
    };

    if (userLoginStatus !== ApiStatus.Idle && userLoginStatus !== ApiStatus.Loading &&
        organizationInformationStatus !== ApiStatus.Idle && organizationInformationStatus !== ApiStatus.Loading && !isSharing) {

        // we dont want to show the banner for La-z-boy or Fillmore
        if (organizationInformation?.rid?.toLowerCase() === lazyBoyRID.toLowerCase() || organizationInformation?.rid?.toLowerCase() === fillmoreRID.toLowerCase()) {
            return <Fragment />;
        }

        return (
            <Fade appear={false} in={bannerOpen}>
                <StyledPaper
                    className={classes.notificationBanner}
                    role='dialog'
                    aria-modal='false'
                    aria-label='end of life banner'
                    square
                    variant='outlined'
                    tabIndex={-1}
                >
                    <Typography align='center' className={classes.bannerText}>
                        Trimble&apos;s Visibility service will be discontinued for Basic Visibility users on January 31, 2025.
                        <br />
                        <Link
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://www.transporeon.com/discover-transporeon-visibility-hub'
                            data-qa='eofBannerLink'
                        >
                            Transporeon Visibility&apos;s
                        </Link>
                        &nbsp; paid solution has migration options available. Please contact your CSM or Account Manager to learn more.
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={closeBanner}
                        className={classes.bannerIcon}
                    >
                        <Icon path={mdiClose} size={1} />
                    </IconButton>
                </StyledPaper>
            </Fade>
        );
    }
    return <Fragment />;
};

export default EndOfLifeHeader;
