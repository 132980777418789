import React from 'react';
import {
    TableContainer,
    TableRow,
    TableCell,
    Typography,
    Table,
    TableHead,
    TableBody,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Commodity } from '../../interfaces/services/shipmentDetails';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';

const classesPrefix = 'orderCommoditiesTable';

const classes = {
    commodityTableWrapper: `${classesPrefix}-commodityTableWrapper`,
    tableHeader: `${classesPrefix}-tableHeader`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.commodityTableWrapper}`]: {
            margin: '8px'
        },
        [`& .${classes.tableHeader}`]: {
            fontWeight: 600
        }
    };
});

const OrderCommoditiesTable = ({
    commodities = [],
    isFetching = false
}: {
    commodities?: Commodity[];
    isFetching?: boolean;
}): JSX.Element => {
    const renderSkeletonRow = (): JSX.Element[] => {
        return (
            // Create a row with 14 table cells, each containing a skeleton
            [...Array(14)].map((value: undefined, index: number) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell key={index}>
                        <Skeleton />
                    </TableCell>
                );
            })
        );
    };

    const renderSkeletonLoader = (): JSX.Element[] => {
        return (
            // Create 3 rows of skeletons
            [...Array(3)].map((value: undefined, index: number) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                        {renderSkeletonRow()}
                    </TableRow>
                );
            })
        );
    };

    const renderCommodityTableBody = (): JSX.Element | JSX.Element[] => {
        if (isFetching) { return renderSkeletonLoader(); }

        if (commodities.length === 0) {
            return (
                <TableRow>
                    <TableCell align='center' colSpan={15} data-qa='commodities-noResults'>No Commodities Found</TableCell>
                </TableRow>
            );
        }

        return commodities.map((commodity) => {
            return (
                <TableRow key={commodity.orderCommodityGuid}>
                    <TableCell component='th' scope='row' data-qa='commodityName'>{commodity.commodityName}</TableCell>
                    <TableCell data-qa='pieceDescription'>{commodity.pieceDescription}</TableCell>
                    <TableCell data-qa='pieceCount'>{commodity.pieceCount !== null ? `${commodity.pieceCount} ${commodity.pieceUnit}` : ''}</TableCell>
                    <TableCell data-qa='weightAmount'>{commodity.weightAmount !== null ? `${commodity.weightAmount} ${addSpaceBeforeUppercaseCharacter(commodity.weightUnit)}` : ''}</TableCell>
                    <TableCell data-qa='length'>{commodity.length !== null ? `${commodity.length} ${commodity.linearUnit}` : ''}</TableCell>
                    <TableCell data-qa='width'>{commodity.width !== null ? `${commodity.width} ${commodity.linearUnit}` : ''}</TableCell>
                    <TableCell data-qa='height'>{commodity.height !== null ? `${commodity.height} ${commodity.linearUnit}` : ''}</TableCell>
                    <TableCell data-qa='volume'>{commodity.volume !== null ? `${commodity.volume} ${addSpaceBeforeUppercaseCharacter(commodity.volumeUnit)}` : ''}</TableCell>
                    <TableCell data-qa='isHazardous'>{commodity.isHazardous ? 'Yes' : 'No'}</TableCell>
                    <TableCell data-qa='isStackable'>{commodity.isStackable ? 'Yes' : 'No'}</TableCell>
                    <TableCell data-qa='unNumber'>{commodity.unNumber}</TableCell>
                    <TableCell data-qa='purchaseOrderNumber'>{commodity.purchaseOrderNumber}</TableCell>
                    <TableCell data-qa='productLine'>{commodity.productLine}</TableCell>
                    <TableCell data-qa='productBrand'>{commodity.productBrand}</TableCell>
                </TableRow>
            );
        });
    };

    return (
        <StyledDiv className={classes.commodityTableWrapper}>
            <Typography variant='h6' gutterBottom component='div'>
                Commodities
            </Typography>
            <TableContainer>
                <Table size='small' aria-label='commodities'>
                    <TableHead>
                        <TableRow>
                            <TableCell classes={{ head: classes.tableHeader }}>Commodity Name</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Piece Description</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Piece Count</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Weight</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Length</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Width</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Height</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Volume</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Hazardous</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Stackable</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>UN Number</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Purchase Order Number</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Product Line</TableCell>
                            <TableCell classes={{ head: classes.tableHeader }}>Product Brand</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderCommodityTableBody()}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledDiv>
    );
};

export default OrderCommoditiesTable;
