import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'dataListNoResults';

const classes = {
    noDataMessage: `${classesPrefix}-noDataMessage`
};

const StyledTableRow = styled(TableRow)(() => {
    return {
        [`&.${classes.noDataMessage}`]: {
            height: 200
        }
    };
});

const DataListNoResults = ({
    message,
    colSpan
}: {
    /** the text to display when no results are found */
    message: string;
    /** the number of columns in the table so the table cell spans the full width of the table */
    colSpan: number;
}): JSX.Element => {
    return (
        <StyledTableRow className={classes.noDataMessage}>
            <TableCell colSpan={colSpan} data-qa='dataList-noResults'>{message}</TableCell>
        </StyledTableRow>
    );
};

export default DataListNoResults;
