import React from 'react';
import { PaletteMode } from '@mui/material';
import { styled } from '@mui/material/styles';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Feature, Point } from 'geojson';
import { bbox, Polygon } from '@turf/turf';

import { DeliveryStatus } from '../../helpers/enums';
import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import {
    addOrderDetailsSource,
    OrderDetailsMapSourceProperties
} from '../../helpers/maps/sources/orderDetails';
import {
    addOrderDetailsStopsLayer,
    addOrderDetailsGeofenceLayers,
    addOrderDetailsCurrentPositionLayer,
    addOrderDetailsCurrentPositionPulseLayer
} from '../../helpers/maps/layers/orderDetails';
import { addTrafficLayers } from '../../helpers/maps/layers/traffic';
import {
    toggleLayerVisibility,
    fitMapToBounds,
    trimbleMapsPopup,
    handleMouseEnter,
    handleMouseLeave,
    handlePopupMouseEnter,
    handlePopupMouseLeave
} from '../../helpers/maps/mapUtils';
import {
    MapSources,
    MapLayers,
    MapImages
} from '../../helpers/maps/enums';
import { OrderDetailsData } from '../../interfaces/services/orderDetails';
import MapSpinner from '../loaders/mapSpinner';
import MapControlBar from '../actionBars/mapControlBar';
import MapLegendWrapper from '../mapLegends/mapLegendWrapper';

import '../../assets/CSS/trimbleMaps.css';

const classesPrefix = 'orderDetailsMap';

const classes = {
    map: `${classesPrefix}-map`
};

const StyledDiv = styled('div')(() => {
    return {
        [`&.${classes.map}`]: {
            height: 'calc(100vh - 485px)',
            minHeight: '380px'
        }
    };
});

interface OrderDetailsMapProps {
    /** Indicator to show loading spinner while data is fetching. */
    isFetchingData: boolean;
    /** Selected Order to show on the map. */
    order: OrderDetailsData;
    /** Image to show on the map for the origin and destination stops. */
    originAndDestinationImage: string;
    /** Palette Mode used to determine map style. */
    paletteMode: PaletteMode;
    /** Indicator for if the screen is a mobile size. */
    isMobile: boolean;
    /** Indicator to trigger a map resize function. */
    resize?: boolean;
}

interface OrderDetailsMapState {
    isWeatherRadarActive: boolean;
    isWeatherRoadSurfaceActive: boolean;
    isTrafficActive: boolean;
    isTrafficIncidentActive: boolean;
    is3dBuildingsLayerDisabled: boolean;
    is3dBuildingsActive: boolean;
    isMapScrollZoomActive: boolean;
    mapTileStyle: string;
    mapStyleLoaded: boolean;
    flyToOn: boolean;
    flyToLocation: string | null;
    zoomMap: boolean;
}

let map: any = null;
const mapContainer = React.createRef<HTMLDivElement>();

class OrderDetailsMap extends React.Component<OrderDetailsMapProps, OrderDetailsMapState> {

    constructor(props: OrderDetailsMapProps) {
        super(props);

        TrimbleMaps.APIKey = 'A413EAA4767EC44E94A2360AE03B8689';

        this.state = {
            isWeatherRadarActive: false,
            isWeatherRoadSurfaceActive: false,
            isTrafficActive: false,
            isTrafficIncidentActive: false,
            is3dBuildingsLayerDisabled: false,
            is3dBuildingsActive: false,
            isMapScrollZoomActive: false,
            mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
            mapStyleLoaded: false,
            flyToOn: false,
            flyToLocation: null,
            zoomMap: true
        };
    }

    componentDidMount(): void {
        // get current location to use for map intialization, default to center of USA
        let latitude = 39.828347;
        let longitude = -98.579488;
        if (this.props.order.currentPosition !== null) {
            latitude = this.props.order.currentPosition.latitude;
            longitude = this.props.order.currentPosition.longitude;
        }

        map = new TrimbleMaps.Map({
            container: mapContainer.current, // Ties to an HTML DOM element
            center: [longitude, latitude], // Sets initial center of map to the current location
            style: this.state.mapTileStyle, // Sets initial map style
            minZoom: 1.55, // To stop the zoom from showing multiple worlds
            zoom: 4, // Sets initial zoom level
            attributionControl: false, // Removes default attribution control, so we can customize it below
            scrollZoom: false, // disables scrollZoom interaction by default
            dragPan: false // disables dragPan interaction by default
        }).addControl(
            new TrimbleMaps.AttributionControl({
                compact: true // Minimizes the attribution to a compact version
            })
        ).addControl(
            // Adds the zoom in/out and compass buttons
            new TrimbleMaps.NavigationControl({
                showCompass: true,
                showZoom: true
            }),
            'top-right'
        ).addControl(
            new TrimbleMaps.FullscreenControl()
        );

        map.setRegion(TrimbleMaps.Common.Region.WW);

        // README: The event `style.load` is no longer a public event of mapbox. We will need to use some combination of using `styledata` and `map.isStyleLoaded()` at some point.
        // However, these do not work the same and there are several open issues with mapbox that have yet to be resolved, such as this one:
        // https://github.com/mapbox/mapbox-gl-js/issues/9779
        // Once a map style is loaded, add any layers that were on back to the map
        map.on('style.load', (): void => {
            // The style.load event can fire after the component has been unmounted.
            if (map === null) {
                return;
            }

            this.removeOrderDetailsEventHandlers();
            this.addOrderDetailsToMap();
            addTrafficLayers(map, TrimbleMaps.APIKey, this.state.isTrafficActive);
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);
            // HACK: trigger a resize function to make map take up full width
            map.resize();
            this.setState({ mapStyleLoaded: true });
        });

        map.on('load', () => {
            const trafficIncident = new TrimbleMaps.TrafficIncident();
            trafficIncident.addTo(map);

            const handleTrafficIncidentClick = new TrimbleMaps.TrafficIncidentClickControl();
            map.addControl(handleTrafficIncidentClick);
        });

        map.on('trafficincident', () => {
            toggleLayerVisibility(map, MapLayers.trafficIncidents, this.state.isTrafficIncidentActive);
        });
    }

    componentDidUpdate(prevProps: OrderDetailsMapProps, prevState: OrderDetailsMapState): void {
        // if the map is null, hold any CDU updates
        if (map === null) {
            return;
        }

        // if the paletteMode prop has changed, set the map style in state accordingly to trigger a re-render and the next CDU will update the map style to match.
        if (prevProps.paletteMode !== this.props.paletteMode) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                mapTileStyle: this.props.paletteMode === 'dark' ? TrimbleMaps.Common.Style.TRANSPORTATION_DARK : TrimbleMaps.Common.Style.TRANSPORTATION,
                mapStyleLoaded: false,
                zoomMap: false
            });
        }

        // if the map style state var has changed and the map style hasn't been loaded, set the style on the map itself
        if (prevState.mapTileStyle !== this.state.mapTileStyle && this.state.mapStyleLoaded === false) {
            map.setStyle(this.state.mapTileStyle);
        }

        if (this.state.mapStyleLoaded === false) {
            return;
        }

        // toggle weather radar
        if (prevState.isWeatherRadarActive !== this.state.isWeatherRadarActive) {
            map.setWeatherRadarVisibility(this.state.isWeatherRadarActive);
        }

        // toggle weather road surface and traffic
        if (prevState.isWeatherRoadSurfaceActive !== this.state.isWeatherRoadSurfaceActive || prevState.isTrafficActive !== this.state.isTrafficActive) {
            map.setRoadSurfaceVisibility(this.state.isWeatherRoadSurfaceActive);
            toggleLayerVisibility(map, MapLayers.europeTraffic, this.state.isTrafficActive);
            toggleLayerVisibility(map, MapLayers.northAmericaTraffic, this.state.isTrafficActive);
        }

        // toggle traffic incidents
        if (prevState.isTrafficIncidentActive !== this.state.isTrafficIncidentActive && map.getLayer(MapLayers.trafficIncidents) !== undefined) {
            toggleLayerVisibility(map, MapLayers.trafficIncidents, this.state.isTrafficIncidentActive);
        }

        // toggle 3D buildings
        if (prevState.is3dBuildingsActive !== this.state.is3dBuildingsActive) {
            map.set3dBuildingVisibility(this.state.is3dBuildingsActive);
        }

        // toggle scroll zoom
        if (prevState.isMapScrollZoomActive !== this.state.isMapScrollZoomActive) {
            if (this.state.isMapScrollZoomActive) {
                map.scrollZoom.enable();
            } else {
                map.scrollZoom.disable();
            }
        }

        // toggle drag pan
        if (this.props.isMobile || prevState.isMapScrollZoomActive !== this.state.isMapScrollZoomActive) {
            if (this.state.isMapScrollZoomActive) {
                map.dragPan.enable();
            } else {
                map.dragPan.disable();
            }
        } else if (!this.props.isMobile) {
            map.dragPan.enable();
        }

        // add order details to map if props have updated
        if (prevProps.order !== this.props.order) {
            this.removeOrderDetailsEventHandlers();
            this.addOrderDetailsToMap();
        }

        // if resize prop is passed and is true force map to resize.
        if (this.props.resize === true) {
            map.resize();
        }
    }

    componentWillUnmount(): void {
        this.removeOrderDetailsEventHandlers();
    }

    determineMapBounds = (): { bounds: any; } => {
        const bounds = new TrimbleMaps.LngLatBounds();

        const detailsSource = map.getSource(MapSources.orderDetailsSource);
        if (detailsSource !== undefined) {
            const { _data: data } = detailsSource;
            if (data.features.length > 0) {
                const detailsBoundingBox = bbox(data);
                const detailsBounds = new TrimbleMaps.LngLatBounds(detailsBoundingBox);
                bounds.extend(detailsBounds);
            }
        }
        return { bounds };
    };

    handlePointClick = (featureId: string, featureBounds: any, maxZoom?: number): void => {
        const mapBounds = this.determineMapBounds();
        // Center and zoom in the map to the coordinates of any clicked symbol from the layer.
        if (this.state.flyToOn === true && this.state.flyToLocation === featureId && Object.keys(mapBounds.bounds).length > 0) {
            fitMapToBounds({
                map,
                bounds: mapBounds.bounds,
                zoomMap: this.state.zoomMap
            });

            this.setState({
                flyToOn: false,
                flyToLocation: null
            });
        } else {
            fitMapToBounds({
                map,
                bounds: featureBounds,
                zoomMap: this.state.zoomMap,
                maxZoom
            });

            this.setState({
                flyToOn: true,
                flyToLocation: featureId
            });
        }
    };

    handleOrderDetailsCurrentLocationClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.orderDetailsCurrentPositionLayer || currentFeature.layer.id === MapLayers.orderDetailsCurrentPositionPulseLayer) {
            const { geometry, properties }: { geometry: Point; properties: OrderDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);
            this.handlePointClick(properties.id, bounds, 13);
        }
    };

    handleOrderDetailsStopClick = (e: any): void => {
        // Get the layer that was clicked
        const features = map.queryRenderedFeatures(e.point);
        const currentFeature = features[0];
        // If it was this layer, fire the click function
        if (currentFeature.layer.id === MapLayers.orderDetailsStopsLayer) {
            let maxZoom: number | undefined;
            const { geometry, properties }: { geometry: Point; properties: OrderDetailsMapSourceProperties; } = currentFeature;
            const bounds = new TrimbleMaps.LngLatBounds();
            bounds.extend(geometry.coordinates);

            const { geofenceFeaturePoints } = properties;
            if (geofenceFeaturePoints) {
                // because the geojson properties get converted to strings by mapbox, we first must re-type this var as unknown
                const unknownGeofence = geofenceFeaturePoints as unknown;
                // then we can properly re-type it as a string, which can then be JSON parsed back to the original type of a Polygon Feature
                const geofenceFeature: Feature<Polygon, OrderDetailsMapSourceProperties> = JSON.parse(unknownGeofence as string);

                geofenceFeature.geometry.coordinates.forEach((segment: number[][]): void => {
                    segment.forEach((coord: number[]): void => {
                        bounds.extend(coord);
                    });
                });
            } else {
                // if there is no geofence on the stop, we want to stop the map from trying to zoom way too far into the point, so we set a max zoom here only.
                maxZoom = 13;
            }
            this.handlePointClick(properties.id, bounds, maxZoom);
        }
    };

    addOrderDetailsToMap = (): void => {
        // add images to map for stops
        map.loadImage(this.props.originAndDestinationImage, (error: unknown, originDestinationImage: any): void => {
            if (map.hasImage(MapImages.originDestinationMapMarker) === false) {
                map.addImage(MapImages.originDestinationMapMarker, originDestinationImage);
            }
        });

        // add map source
        addOrderDetailsSource(map, this.props.order);

        // add geofence layers
        addOrderDetailsGeofenceLayers(map, MapSources.orderDetailsSource);

        const size = 120;
        const pulseColor = getDeliveryStatusColor(this.props.order.stops[0]?.deliveryStatus || DeliveryStatus.TenderAccepted);

        // This implements `StyleImageInterface` to draw a pulsing dot icon on the map.
        const pulsingDot: {
            width: number;
            height: number;
            context: CanvasRenderingContext2D | null;
            data: Uint8ClampedArray | Uint8Array;
            onAdd: () => void;
            onRemove: () => void;
            render: () => boolean;
        } = {
            width: size,
            height: size,
            context: null,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map, get the rendering context for the map canvas.
            onAdd(): void {
                const canvas = document.createElement('canvas');
                canvas.width = pulsingDot.width;
                canvas.height = pulsingDot.height;
                pulsingDot.context = canvas.getContext('2d');
            },

            onRemove(): void {
                pulsingDot.context = null;
            },

            // Call once before every frame where the icon will be used.
            render(): boolean {
                const duration = 2000;
                const time = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * time + radius;

                if (pulsingDot.context !== null) {
                    // Draw the outer circle.
                    pulsingDot.context.clearRect(0, 0, pulsingDot.width, pulsingDot.height);
                    pulsingDot.context.beginPath();
                    pulsingDot.context.arc(
                        pulsingDot.width / 2,
                        pulsingDot.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    pulsingDot.context.globalAlpha = 1 - time;
                    pulsingDot.context.fillStyle = pulseColor;
                    pulsingDot.context.fill();

                    // Draw the inner circle.
                    pulsingDot.context.beginPath();
                    pulsingDot.context.arc(
                        pulsingDot.width / 2,
                        pulsingDot.height / 2,
                        radius,
                        0,
                        Math.PI * 2
                    );
                    pulsingDot.context.fillStyle = pulseColor;
                    pulsingDot.context.globalAlpha = 1;
                    pulsingDot.context.strokeStyle = '#fff';
                    pulsingDot.context.lineWidth = 4;
                    pulsingDot.context.fill();
                    pulsingDot.context.stroke();

                    // Update this image's data with data from the canvas.
                    pulsingDot.data = pulsingDot.context.getImageData(
                        0,
                        0,
                        pulsingDot.width,
                        pulsingDot.height
                    ).data;
                }

                // Continuously repaint the map, resulting in the smooth animation of the dot.
                map.triggerRepaint();

                // Return `true` to let the map know that the image was updated.
                return true;
            }
        };

        // add pulsing current location image
        if (map.hasImage(MapImages.currentLocationMapMarker) === false) {
            map.addImage(MapImages.currentLocationMapMarker, pulsingDot, { pixelRatio: 2 });
        }

        // add current position pulse and mouse events
        addOrderDetailsCurrentPositionPulseLayer(map, MapSources.orderDetailsSource);
        map.on('click', MapLayers.orderDetailsCurrentPositionPulseLayer, this.handleOrderDetailsCurrentLocationClick);
        map.on('mouseenter', MapLayers.orderDetailsCurrentPositionPulseLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.orderDetailsCurrentPositionPulseLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        addOrderDetailsCurrentPositionLayer(map, MapSources.orderDetailsSource);
        map.on('click', MapLayers.orderDetailsCurrentPositionLayer, this.handleOrderDetailsCurrentLocationClick);
        map.on('mouseenter', MapLayers.orderDetailsCurrentPositionLayer, (e: any): void => { handlePopupMouseEnter(map, e, trimbleMapsPopup); });
        map.on('mouseleave', MapLayers.orderDetailsCurrentPositionLayer, (): void => { handlePopupMouseLeave(map, trimbleMapsPopup); });

        // add stops and mouse events
        addOrderDetailsStopsLayer(map, MapSources.orderDetailsSource);
        map.on('click', MapLayers.orderDetailsStopsLayer, this.handleOrderDetailsStopClick);
        map.on('mouseenter', MapLayers.orderDetailsStopsLayer, (): void => { handleMouseEnter(map); });
        map.on('mouseleave', MapLayers.orderDetailsStopsLayer, (): void => { handleMouseLeave(map); });

        const mapBounds = this.determineMapBounds();
        if (Object.keys(mapBounds.bounds).length > 0) {
            fitMapToBounds({
                map,
                bounds: mapBounds.bounds,
                zoomMap: this.state.zoomMap
            });
        }
    };

    removeOrderDetailsEventHandlers = (): void => {
        if (map.getLayer(MapLayers.orderDetailsCurrentPositionPulseLayer) !== undefined) {
            map.off('click', MapLayers.orderDetailsCurrentPositionPulseLayer, this.handleOrderDetailsCurrentLocationClick);
            map.off('mouseenter', MapLayers.orderDetailsCurrentPositionPulseLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.shipmentDetailsCurrentPositionPulseLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.orderDetailsCurrentPositionLayer) !== undefined) {
            map.off('click', MapLayers.orderDetailsCurrentPositionLayer, this.handleOrderDetailsCurrentLocationClick);
            map.off('mouseenter', MapLayers.orderDetailsCurrentPositionLayer, handlePopupMouseEnter);
            map.off('mouseleave', MapLayers.orderDetailsCurrentPositionLayer, handlePopupMouseLeave);
        }

        if (map.getLayer(MapLayers.orderDetailsStopsLayer) !== undefined) {
            map.off('click', MapLayers.orderDetailsStopsLayer, this.handleOrderDetailsStopClick);
            map.off('mouseenter', MapLayers.orderDetailsStopsLayer, handleMouseEnter);
            map.off('mouseleave', MapLayers.orderDetailsStopsLayer, handleMouseLeave);
        }

        if (map.hasImage(MapImages.currentLocationMapMarker) === true) {
            map.removeImage(MapImages.currentLocationMapMarker);
        }
    };

    render(): JSX.Element {
        return (
            <StyledDiv
                id='trimble-map-container'
                ref={mapContainer}
                className={classes.map}
                data-qa='orderDetailsMap-container'
            >
                {
                    this.props.isFetchingData &&
                    <MapSpinner />
                }
                <MapControlBar
                    isWeatherRadarActive={this.state.isWeatherRadarActive}
                    handleWeatherRadarClick={(): void => {
                        this.setState((state) => {
                            return {
                                isWeatherRadarActive: !state.isWeatherRadarActive
                            };
                        });
                    }}
                    isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                    handleWeatherRoadSurfaceClick={(): void => {
                        this.setState((state) => {
                            return {
                                isWeatherRoadSurfaceActive: !state.isWeatherRoadSurfaceActive,
                                isTrafficActive: !state.isWeatherRoadSurfaceActive === true ? false : state.isTrafficActive
                            };
                        });
                    }}
                    isTrafficActive={this.state.isTrafficActive}
                    handleTrafficClick={(): void => {
                        this.setState((state) => {
                            return {
                                isTrafficActive: !state.isTrafficActive,
                                isWeatherRoadSurfaceActive: !state.isTrafficActive === true ? false : state.isWeatherRoadSurfaceActive
                            };
                        });
                    }}
                    isTrafficIncidentActive={this.state.isTrafficIncidentActive}
                    handleTrafficIncidentClick={(): void => {
                        this.setState((state) => {
                            return {
                                isTrafficIncidentActive: !state.isTrafficIncidentActive
                            };
                        });
                    }}
                    is3dBuildingsLayerDisabled={this.state.is3dBuildingsLayerDisabled}
                    is3dBuildingsActive={this.state.is3dBuildingsActive}
                    handle3dBuildingClick={(): void => {
                        this.setState((state) => {
                            return {
                                is3dBuildingsActive: !state.is3dBuildingsActive
                            };
                        });
                    }}
                    mapTileStyle={this.state.mapTileStyle}
                    handleMapStyleMenuItemClick={(mapStyle: string): void => {
                        let is3dBuildingsLayerDisabled = false;
                        let { is3dBuildingsActive } = this.state;
                        if (mapStyle === TrimbleMaps.Common.Style.SATELLITE) {
                            is3dBuildingsLayerDisabled = true; // disable 3d buildings button when in satellite mode
                            is3dBuildingsActive = false; // turn 3d building layer off when in satellite mode
                        }

                        this.setState({
                            is3dBuildingsLayerDisabled,
                            is3dBuildingsActive,
                            mapTileStyle: mapStyle,
                            mapStyleLoaded: false,
                            zoomMap: false
                        });
                    }}
                    isMapScrollZoomActive={!this.state.isMapScrollZoomActive}
                    handleMapScrollZoomClick={(): void => {
                        this.setState((state) => {
                            return {
                                isMapScrollZoomActive: !state.isMapScrollZoomActive
                            };
                        });
                    }}
                />

                <MapLegendWrapper
                    isWeatherRoadSurfaceActive={this.state.isWeatherRoadSurfaceActive}
                    isMapLegendEnabled={false}
                    mapLegendType={null}
                />
            </StyledDiv>
        );
    }

}

export default OrderDetailsMap;
