import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Typography,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const EtaDisplayCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const determineShowEtaValue = (): 'carrierEta' | boolean => {
        if (organizationEditablePreferences.displayCarrierProvidedActualsOnly === true) {
            return 'carrierEta';
        }
        return organizationEditablePreferences.showEta;
    };

    const handleShowEtaChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.currentTarget.value === 'carrierEta') {
            dispatch(editPreferences(PreferencesFieldNames.displayCarrierProvidedActualsOnly, true));
            dispatch(editPreferences(PreferencesFieldNames.showEta, false));
        } else {
            dispatch(editPreferences(PreferencesFieldNames.displayCarrierProvidedActualsOnly, false));
            dispatch(editPreferences(PreferencesFieldNames.showEta, event.currentTarget.value === 'true'));
        }
    };

    return (
        <PreferenceCard
            header='ETA Display'
            informationText='The ETA will be hidden in all places where the ETA is displayed. The ontime status will still be displayed.'
        >
            <Typography component='p' variant='body2'>
                How do you want to display ETA?
            </Typography>

            <RadioGroup
                aria-label='showEta'
                name='showEta'
                value={String(determineShowEtaValue())}
                onChange={handleShowEtaChange}
            >
                <FormControlLabel
                    value='true'
                    control={<Radio color='primary' />}
                    label='Show ETA'
                    data-qa='showEta-radio'
                />
                <FormControlLabel
                    value='carrierEta'
                    disabled={organizationEditablePreferences.preferredStopTimeSourceType !== 1}
                    control={<Radio color='primary' />}
                    label='Show Carrier provided ETA'
                    data-qa='showCarrierEta-radio'
                />
                <FormControlLabel
                    value='false'
                    control={<Radio color='primary' />}
                    label='Hide ETA'
                    data-qa='hideEta-radio'
                />
            </RadioGroup>
        </PreferenceCard>
    );
};

export default EtaDisplayCard;
