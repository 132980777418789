import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { fetchShipments, updateFilters } from '../../redux/shipments';
import { ApplicationResourceNames, SideSheetType } from '../../helpers/enums';
import HeaderSearchInput from '../inputs/headerSearchInput';
import PageViewButtons from '../actionBars/pageViewButtons';
import FilterNameChip from '../chips/filterNameChip';
import SideSheetButtons from '../actionBars/sideSheetButtons';

const classesPrefix = 'shipmentHeader';

const classes = {
    wrapper: `${classesPrefix}-wrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.wrapper}`]: {
            backgroundColor: theme.palette.primary.main,
            minHeight: '68px',
            padding: '0 24px'
        },
        [theme.breakpoints.only('md')]: {
            [`&.${classes.wrapper}`]: {
                justifyContent: 'space-between'
            }
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.wrapper}`]: {
                padding: '16px 16px 0 16px'
            }
        }
    };
});

const ShipmentHeader = ({
    currentSideSheet,
    handleCurrentSideSheetChange
}: {
    /** Current selected side sheet */
    currentSideSheet: SideSheetType | null;
    /** Function to handle the side sheet change. */
    handleCurrentSideSheetChange: (currentSideSheet: SideSheetType) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const activeShipments = useTypedSelector((state) => { return state.shipments.activeShipments; });
    const searchTerm = useTypedSelector((state) => { return state.shipments.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.shipments.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.shipments.sortDirection; });
    const filters = useTypedSelector((state) => { return state.shipments.filters; });
    const filtersName = useTypedSelector((state) => { return state.shipments.filtersName; });

    const userInformation = useTypedSelector((state) => { return state.user.userInformation; });

    const pageViewList = userInformation.userApplications.transportationUi.filter((app) => {
        return app.resourceKey === ApplicationResourceNames.ShipmentsOverview ||
            app.resourceKey === ApplicationResourceNames.ShipmentListActive ||
            app.resourceKey === ApplicationResourceNames.ShipmentsCalendar ||
            app.resourceKey === ApplicationResourceNames.ShipmentListHistory;
    });

    return (
        <StyledGrid container alignItems='center' className={classes.wrapper} data-qa='shipmentHeader-container'>
            <HeaderSearchInput
                id='shipmentSearch'
                inputLabel='Search Shipments'
                searchTerm={searchTerm}
                handleSearchTermSubmit={(submittedSearchTerm): void => {
                    dispatch(fetchShipments({
                        activeShipments,
                        searchTerm: submittedSearchTerm,
                        sortColumn,
                        sortDirection,
                        filters
                    }));
                }}
            />

            <PageViewButtons pageViewList={pageViewList} />

            {
                filtersName &&
                <FilterNameChip
                    filterName={filtersName}
                    handleChipDelete={(): void => {
                        // reset the filters in redux
                        dispatch(updateFilters([]));
                    }}
                />
            }

            <SideSheetButtons
                currentSideSheet={currentSideSheet}
                handleCurrentSideSheetChange={handleCurrentSideSheetChange}
            />
        </StyledGrid>
    );
};

export default ShipmentHeader;
