import { shipmentSortOrderList } from '../../helpers/hardcodedOptionLists';
import { OrganizationInformation, OrganizationPreferences } from '../../interfaces/services/organization';

export const initialOrganizationInformationState: OrganizationInformation = {
    organizationName: null,
    createdDate: null,
    mcNumber: null,
    billingAddressLine1: null,
    billingAddressLine2: null,
    billingCityName: null,
    billingStateCode: null,
    billingPostalCode: null,
    billingCountryCode: null,
    tmsSystemName: null,
    localTimeZoneName: null,
    isCompliant: null,
    hasBusinessHoursSet: null,
    rid: null,
    isOnProbation: null,
    standardCarrierAlphaCode: null,
    referralType: null,
    referralTypeDescription: null,
    logoFileRetrievalToken: null,
    isAdminEligibleFlag: null,
    mailingAddressLine1: null,
    mailingAddressLine2: null,
    mailingCityName: null,
    mailingStateCode: null,
    mailingPostalCode: null,
    mailingCountryCode: null,
    geocodedMailingAddress: {
        position: {
            latitude: 0.0,
            longitude: 0.0,
            locationResolution: null
        },
        location: {
            streetAddress1: null,
            streetAddress2: null,
            cityName: null,
            stateCode: null,
            postalCode: null,
            countryCode: null
        },
        timeZoneInfo: {
            timeZoneInfoKey: null,
            timeZoneCode: null,
            utcOffset: null,
            localizedDateTimeOffset: null
        },
        dataSourceType: null,
        matchConfidenceType: null
    },
    billingProviderName: null,
    autoPurgeManualDataFlag: null,
    registeredUnderPromotionID: null,
    billingPhoneNumber: null,
    billingPhoneNumberExtension: null,
    mailingPhoneNumber: null,
    mailingPhoneNumberExtension: null,
    timeZoneID: null,
    isOptedOutOfMatchingFlag: null,
    isRegisteredFlag: null,
    tenderedDealCount: null,
    isTestOrgFlag: null,
    initialOfferAdjustmentPercentage: null,
    isNameManuallyEditedFlag: null,
    isAddressManuallyEditedFlag: null,
    updatedDate: null,
    useICInvoiceItem: null,
    divisionName: null,
    divisionDescription: null,
    billingEmailAddress: null,
    optInToTeaserEmailFlag: null,
    assetNameReservationNumber: null,
    carrierTrackingGoal: null,
    isDocXCarrier: null
};

export const initialPreferencesState: OrganizationPreferences = {
    autoRefreshMinutes: 15,
    lastEditDate: new Date().toDateString(),
    lastEditedEmailAddress: null,
    defaultSortFieldID: null,
    isManualShipmentEnabledFlag: true,
    showPCMilerRouteByDefaultFlag: true,
    showGoogleRouteByDefaultFlag: false,
    geofenceRadiusInFeet: 5280,
    earlyIsAGoodThing: true,
    showPendingShipmentsByDefaultFlag: null,
    sortOptionFieldType: shipmentSortOrderList[9].value,
    shipmentsToHistoryPageTimeframeInHours: 72,
    autoSetAtConsigneeToDeliveredFlag: false,
    displayDeliveryStatusToDestination: true,
    customLogoToken: null,
    showEta: true,
    shipmentExpirationTimeFrameHours: 72,
    temperatureAlertMinutes: 0,
    temperatureAlertBelowMin: 0,
    temperatureAlertAboveMax: 0,
    allowCarrierReportedStopDatesOverride: false,
    displayCarrierProvidedActualsOnly: false,
    dropTrailerPickupRulesEnabled: false,
    dropTrailerDeliveryRulesEnabled: false,
    isDropTrailerSameDayPickup: false,
    isDropTrailerSameDayDelivery: false,
    dropTrailerWindowPickupTimeFrom: null,
    dropTrailerWindowPickupTimeTo: null,
    dropTrailerWindowDeliveryTimeFrom: null,
    dropTrailerWindowDeliveryTimeTo: null,
    hasRequiredAppointmentEarlyRules: false,
    etaBaselineIncreaseOrDecrease: 0,
    etaBaselineEnabled: false,
    etaBaselineMinutes: 30,
    etaHotLoadBaselineIncreaseOrDecrease: 0,
    etaHotloadBaselineEnabled: false,
    etaHotloadBaselineMinutes: 30,
    enablePessimisticFilterFlag: false,
    mobileNotificationDispatchMinutesPriorToScheduledPickup: 0,
    displayNewShipmentsUIFlag: false,
    preferredStopTimeSourceType: 0,
    useTrimblePlacesFlag: false,
    alwaysShowMobileQueue: false,
    freightPortalPreferencesOnTimeRules: [],
    freightPortalColorPreference: {
        primaryNavBackground: null,
        primaryMain: null,
        secondaryMain: null
    },
    shortUrlExpiryDays: null
};
