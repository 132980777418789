import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { Document } from '../../interfaces/services/shipmentDetails';
import { ApiStatus } from '../../helpers/enums';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import DocumentDownloadLink from '../links/documentDownloadLink';

const classesPrefix = 'documentList';

const classes = {
    noDocumentsError: `${classesPrefix}-noDocumentsError`,
    documentContainer: `${classesPrefix}-documentContainer`,
    documentDate: `${classesPrefix}-documentDate`
};

const StyledGrid = styled(Grid)(() => {
    return {
        [`& .${classes.documentContainer}`]: {
            marginTop: '8px',
            marginBottom: '4px'
        },
        [`& .${classes.documentDate}`]: {
            display: 'block'
        }
    };
});

const StyledTypography = styled(Typography)(() => {
    return {
        [`&.${classes.noDocumentsError}`]: {
            margin: '8px 0'
        }
    };
}) as typeof Typography;

const DocumentList = ({
    shipmentUniqueName,
    documents
}: {
    shipmentUniqueName: string;
    documents: Document[];
}): JSX.Element => {
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });

    if (shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading) {
        return (
            <StyledGrid container>
                {
                    Array.from(new Array(3)).map((item, index): JSX.Element => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid key={index} item xs={12} md={4} className={classes.documentContainer}>
                                <Skeleton variant='text' width='60%' height='24px' />
                                <Skeleton variant='text' width='75%' height='16px' />
                            </Grid>
                        );
                    })
                }
            </StyledGrid>
        );
    }

    if (documents.length === 0) {
        return (
            <StyledTypography variant='body2' component='p' className={classes.noDocumentsError} data-qa='noDocuments'>
                No Documents Found
            </StyledTypography>
        );
    }

    return (
        <StyledGrid container data-qa='documentList-container'>
            {
                documents.map((document) => {
                    return (
                        <Grid key={document.documentID} item xs={12} md={4} className={classes.documentContainer} data-qa='document-container'>
                            <DocumentDownloadLink
                                documentName={document.documentTypeName}
                                documentID={document.documentID}
                                shipmentUniqueName={shipmentUniqueName}
                            />
                            <Typography
                                className={classes.documentDate}
                                variant='caption'
                                noWrap
                                color='textSecondary'
                                data-qa='document-date'
                            >
                                {zonedDateTimeToDisplay(document.uploadDate)}
                            </Typography>
                        </Grid>
                    );
                })
            }
        </StyledGrid>
    );
};

export default DocumentList;
