import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, startOfMonth, endOfMonth, getDay, endOfWeek } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { simpleServerDateFormat } from '../../helpers/dateUtils';

import { useTypedSelector } from '../../redux';
import { fetchCalendarShipments } from '../../redux/calendar';
import { CalendarStopType, CalendarView } from '../../helpers/enums';
import { getDeliveryStatusColor, getDeliveryStatusLightColor } from '../../helpers/styleHelpers';
import { CalendarShipment } from '../../interfaces/services/shipmentCalendar';
import CalendarToolbar from './calendarToolbar';
import CustomEvent from './customEvent';
import ShipmentDetailsDialog from '../dialogs/shipmentDetailsDialog';

import '../../assets/CSS/reactBigCalendar.css';

const locales = {
    'en-US': enUS
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

const CustomCalendar = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const [range, setRange] = useState<{ startDate: string; endDate: string; }>();
    const [showShipmentDetailsDialog, setShowShipmentDetailsDialog] = useState<{ isOpen: boolean; selctedShipment: CalendarShipment | null; }>({
        isOpen: false,
        selctedShipment: null
    });

    const shipmentList = useTypedSelector((state) => { return state.calendar.shipmentList; });
    const calendarFilters = useTypedSelector((state) => { return state.calendar.filters; });
    const dateType = useTypedSelector((state) => { return state.calendar.dateType; });
    const isStopTypeDelivery = useTypedSelector((state) => { return state.calendar.isStopTypeDelivery; });
    const isStopTypePickup = useTypedSelector((state) => { return state.calendar.isStopTypePickup; });

    const dateFormatter = (date: Date): string => {
        return format(date, simpleServerDateFormat);
    };

    useEffect(() => {
        const date = new Date();
        const firstDay = startOfMonth(date);
        const lastDay = endOfMonth(date);
        setRange({ startDate: dateFormatter(startOfWeek(firstDay)), endDate: dateFormatter(endOfWeek(lastDay)) });
    }, []);

    useEffect(() => {
        if (range) {
            dispatch(fetchCalendarShipments({
                dateType,
                filters: calendarFilters,
                startDate: range.startDate,
                endDate: range.endDate,
                isStopTypeDelivery,
                isStopTypePickup
            }));
        }
    }, [dispatch, range, calendarFilters, dateType, isStopTypeDelivery, isStopTypePickup]);

    return (
        <Fragment>
            <Calendar
                localizer={localizer}
                components={{
                    toolbar: CalendarToolbar,
                    month: {
                        event: CustomEvent
                    },
                    week: {
                        event: CustomEvent
                    },
                    day: {
                        event: CustomEvent
                    }
                }}
                views={[CalendarView.Month, CalendarView.Week, CalendarView.Day]}
                startAccessor='startDateTime' // change what field is used for the start time of the event
                endAccessor='endDateTime' // change what field is used for the end time of the event
                events={shipmentList}
                showMultiDayTimes={true} // Support to show multi-day events with specific start and end times in the main time grid (rather than in the all day header)
                popup={true} // Show truncated events in an overlay when you click the "+_x_ more" link.
                onRangeChange={(newRange): void => {
                    // Day and week views are arrays whereas month is an object
                    if (Array.isArray(newRange) && newRange.length > 0) {
                        if (newRange.length === 1) {
                            // day
                            setRange({ startDate: dateFormatter(newRange[0]), endDate: dateFormatter(newRange[0]) });
                        } else {
                            // week
                            setRange({ startDate: dateFormatter(newRange[0]), endDate: dateFormatter(newRange[newRange.length - 1]) });
                        }
                    } else if ('start' in newRange && 'end' in newRange) {
                        // month
                        setRange({ startDate: dateFormatter(new Date(newRange.start)), endDate: dateFormatter(new Date(newRange.end)) });
                    }
                }}
                eventPropGetter={(shipment, start, end, isSelected): any => { // use to override styles for the events based on type and status
                    const commonStyles = {
                        color: theme.palette.common.black,
                        fontWeight: isSelected ? 600 : 400
                    };

                    if (shipment.calendarStopType === CalendarStopType.Pickup) {
                        return {
                            style: {
                                ...commonStyles,
                                backgroundColor: theme.palette.common.white,
                                // TODO - this needs to use the origin delivery status once it's added to headers
                                border: `1px solid ${getDeliveryStatusColor(shipment.deliveryStatus)}`
                            }
                        };
                    }

                    if (shipment.calendarStopType === CalendarStopType.Delivery) {
                        return {
                            style: {
                                ...commonStyles,
                                // TODO - this needs to use the destination delivery status once it's added to headers
                                backgroundColor: getDeliveryStatusLightColor(shipment.deliveryStatus)
                            }
                        };
                    }

                    return {};
                }}
                onSelectEvent={(shipment): void => { // use to show a details popup for the shipment
                    setShowShipmentDetailsDialog({
                        isOpen: true,
                        selctedShipment: shipment
                    });
                }}
            />

            {
                showShipmentDetailsDialog.isOpen && showShipmentDetailsDialog.selctedShipment &&
                <ShipmentDetailsDialog
                    shipmentUniqueName={showShipmentDetailsDialog.selctedShipment.shipmentUniqueName}
                    modeType={showShipmentDetailsDialog.selctedShipment.modeType}
                    handleDialogClose={(): void => {
                        setShowShipmentDetailsDialog({ isOpen: false, selctedShipment: null });
                    }}
                />
            }
        </Fragment>
    );
};

export default CustomCalendar;
