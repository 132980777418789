import { toDate, format, utcToZonedTime } from 'date-fns-tz';
import { isAfter, isValid, parseISO } from 'date-fns';

/** Standard 24-hour time format. */
export const time24HourFormat = 'HH:mm';

/** Simple date only format to send to the server. DO NOT internationalize. */
export const simpleServerDateFormat = 'MM/dd/yyyy';

/** Simple date and time only format to send to the server. DO NOT internationalize. */
export const simpleServerDateTimeFormat = `yyyy-MM-dd ${time24HourFormat}`;

/** Using the browser language, it will determine if the language is English US or not. */
const isBrowserLangUS = navigator.language === 'en-US' || navigator.language === 'en' || navigator.language === 'en-us';

/** Using the browser language, it will determine the internationalized format for a date. */
export const simpleInternationalizedDateFormat = isBrowserLangUS ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

/** A date time format for displaying on the UI without a timezone. */
export const displayDateTimeFormat = `${simpleInternationalizedDateFormat} ${time24HourFormat}`;

/** A date time format for displaying on the UI. */
const displayDateTimeFormatWithShortTimeZone = `${displayDateTimeFormat} zzz`;

/**
 * This function will look at the date and attempt to pull off the timezone name contained within the parentheses,
 * otherwise it will return null. This can be used in the time formatting functions.
 * @param inputDate A date & time in one of the following formats:
 * 1. 2019-04-24T04:30:00-06:00 (America/Denver)
 * 2. 2019-04-24 04:30:00-06:00 (America/Denver)
 * 3. 2019-04-24T04:30:00-06:00
 * 4. 2019-04-24 04:30:00-06:00
 * @returns An object containing the date part only and the timezone string contained in the date or null
 */
const getTimeZoneNameFromDateTimeOffset = (inputDate: string): { dateTimeOffset: string; timeZoneName: string | null; } => {
    let dateTimeOffset = inputDate;

    // To split out the DateTimeOffset from the Timezone name
    const dateParsed = inputDate.split(' ');

    let timeZoneName = null;
    // Remove parentheses from the timezone name, so that the formatter can actually use it.
    if (dateParsed.length > 1) {
        // If the "timezone" has a parentheses then strip the off to use in timezone formatting, otherwise just pass it back as a blank TZ.
        if (dateParsed[1].includes('(')) {
            [dateTimeOffset] = dateParsed;
            timeZoneName = dateParsed[1].replace(/[()]/g, '');
        }
    }

    // If there is a space between the date and the time, the timezone will be the third item in the array.
    if (dateParsed.length > 2) {
        dateTimeOffset = `${dateParsed[0]} ${dateParsed[1]}`;
        timeZoneName = dateParsed[2].replace(/[()]/g, '');
    }

    return { dateTimeOffset, timeZoneName };
};

const getDateFromIso = (inputDate: string): Date => {
    // strip off the first 16 characters which will end after the minutes, e.g. 2019-04-24T04:30
    const dateTimeOnly = inputDate.substring(0, 16);

    // string must be a JS Date to be validated or formatted
    const date = parseISO(dateTimeOnly);

    return date;
};

/**
 * Using the input date, it will return a formatted and internationalized date to display on the UI.
 * This will accept a custom format, if the display format is the not the one needed.
 * @param inputDate A date & time in one of the following formats:
 * 1. 2019-04-24T04:30:00-06:00 (America/Denver)
 * 2. 2019-04-24 04:30:00-06:00 (America/Denver)
 * 3. 2019-04-24T04:30:00-06:00
 * 4. 2019-04-24 04:30:00-06:00
 * @param dateFormat An optional custom format for the date to be returned - defaults to the international date format described below.
 * @returns A formatted date to display in either the custom format or 'MM/dd/yyyy HH:mm zzz' for en-US language or 'dd/MM/yyyy HH:mm zzz' for international.
 * If there is not IANA time zone available on the date, it will instead return in the custom format or 'MM/dd/yyyy HH:mm' for en-US language or 'dd/MM/yyyy HH:mm' for international.
 */
export const zonedDateTimeToDisplay = (inputDate: string | null, dateFormat: string = displayDateTimeFormatWithShortTimeZone): string => {
    if (!inputDate) {
        return '';
    }

    const { dateTimeOffset, timeZoneName } = getTimeZoneNameFromDateTimeOffset(inputDate);

    // HACK: ignore UTC time zones for now because it is not part of the IANA timezone names database so date-fns-tz throws an error instead
    if (timeZoneName && !timeZoneName.startsWith('UTC')) {
        const date = toDate(dateTimeOffset, { timeZone: timeZoneName });
        const zonedDate = utcToZonedTime(date, timeZoneName);
        const formattedDate = format(zonedDate, dateFormat, { timeZone: timeZoneName });
        return formattedDate;
    }

    const date = getDateFromIso(inputDate);

    // if date is valid, return the formatted date without a timezone on it
    if (isValid(date)) {
        const formattedDate = format(date, displayDateTimeFormat);
        return formattedDate;
    }

    // otherwise, return an Invalid Date string to display
    return 'Invalid Date';
};

/**
 * Using the input date, it will return a formatted date and time to send to the server for updates.
 * @param inputDate Date to be formatted
 * @returns A formatted date and time to send to the server for updates.
 */
export const formatDateForServer = (inputDate: Date | null): string => {
    if (!inputDate) {
        return '';
    }

    return format(inputDate, simpleServerDateTimeFormat);
};

/**
 * Using the date returned from the server, it will return a date object that can be rendered in the date pickers.
 * @param inputDate String date to be formatted
 * @returns A Date object to be displayed by the picker.
 */
export const formatDateForPicker = (inputDate: string): Date => {
    const date = getDateFromIso(inputDate);
    return date;
};

/**
 * Using the date returned from the server, it will return a date object that can be rendered in the calendar.
 * @param inputDate String date to be formatted
 * @returns A Date object to be displayed by the calendar.
 */
export const formatDateForCalendar = (inputDate: string): Date => {
    const { dateTimeOffset, timeZoneName } = getTimeZoneNameFromDateTimeOffset(inputDate);
    if (timeZoneName) {
        const date = toDate(dateTimeOffset, { timeZone: timeZoneName });
        return date;
    }

    const date = getDateFromIso(inputDate);
    return date;
};

/**
 * Takes two string values representing time and returns a boolean signifying if the range is valid, from-time < to-time.
 * @param fromTime String reperesenting from time
 * @param toTime String representing to time
 * @returns A boolean representing if time range is valid
 */
export const isTimeRangeValid = (fromTime: string | null, toTime: string | null): boolean => {
    if (fromTime && toTime) {
        const fromDateObject = new Date(`1970-01-01T${fromTime}`);
        const toDateObject = new Date(`1970-01-01T${toTime}`);
        return isAfter(toDateObject, fromDateObject);
    }
    return false;
};

/**
 * Using the date formatted with simpleServerDateTimeFormat, it will return a js friendly dateTime.
 * @param inputDate simpleServerDateTimeFormat string to be formatted
 * @returns String date in the format of yyyy-MM-ddTHH:mm.
 */
export const simpleJavascriptDateTimeFormat = (simpleServerDateTime: string): string => {
    return simpleServerDateTime.split(' ').join('T');
};
