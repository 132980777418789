import React, { Fragment, useState } from 'react';
import {
    TableRow,
    TableCell,
    IconButton,
    Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import { Order } from '../../interfaces/services/shipmentDetails';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import OrderCommoditiesTable from '../tables/orderCommoditiesTable';

const classesPrefix = 'stopOrdersTableRow';

const classes = {
    expansionTableCell: `${classesPrefix}-expansionTableCell`
};

const StyledTableRow = styled(TableRow)(() => {
    return {
        [`& .${classes.expansionTableCell}`]: {
            paddingBottom: 0,
            paddingTop: 0
        }
    };
});

const StopOrdersTableRow = ({
    stopReference,
    order
}: {
    stopReference: string;
    order: Order;
}): JSX.Element => {
    const [isRowOpen, setIsRowOpen] = useState(false);

    const stopAction = (): string | null => {
        if (stopReference === order.pickupStopReference) {
            return 'Pickup';
        }
        if (stopReference === order.deliveryStopReference) {
            return 'Delivery';
        }
        return null;
    };

    return (
        <Fragment>
            <StyledTableRow key={order.orderNumber} data-qa={`orders-row-${order.orderNumber}`}>
                <TableCell>
                    <IconButton aria-label='expand row' size='small' onClick={(): void => { setIsRowOpen(!isRowOpen); }}>
                        {isRowOpen ? <Icon path={mdiChevronUp} size={1} /> : <Icon path={mdiChevronDown} size={1} />}
                    </IconButton>
                </TableCell>
                <TableCell component='th' scope='row' data-qa='orderId'>{order.orderNumber}</TableCell>
                <TableCell data-qa='stopAction'>{stopAction()}</TableCell>
                <TableCell data-qa='customerReference'>{order.customerReference}</TableCell>
                <TableCell data-qa='orderDateTime'>{zonedDateTimeToDisplay(order.orderDateTime)}</TableCell>
                <TableCell data-qa='orderTypeName'>{order.orderTypeName}</TableCell>
                <TableCell data-qa='serviceLevel'>{order.serviceLevel}</TableCell>
                <TableCell data-qa='orderReference'>{order.orderReference}</TableCell>
                <TableCell data-qa='customerName'>{order.customerName}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell className={classes.expansionTableCell} colSpan={9}>
                    <Collapse in={isRowOpen} timeout='auto' unmountOnExit>
                        <OrderCommoditiesTable commodities={order.commodities} />
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </Fragment>
    );
};

export default StopOrdersTableRow;
