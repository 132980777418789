import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    Link,
    Tooltip,
    Typography,
    Skeleton
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Icon from '@mdi/react';
import { mdiOfficeBuildingMarker, mdiInformationOutline } from '@mdi/js';

import { useTypedSelector } from '../../redux';
import { fetchLocationDetails } from '../../redux/locationDetails';
import { ApiStatus, ApplicationResourceNames, ModeType } from '../../helpers/enums';
import { formatCityRegionPostalCountry } from '../../helpers/addressUtils';
import { calculateHoursAndMinutes } from '../../helpers/dataUtils';
import { getGeofenceRadiusInMiles } from '../../helpers/maps/mapUtils';
import mapMarker from '../../assets/images/maps/markers_location.png';
import CommonDialog from './common/commonDialog';
import LabelValuePairLoading from '../loaders/labels/labelValuePairLoading';
import LabelValuePair from '../labels/labelValuePair';
import OnTimeRulesList from '../lists/onTimeRulesList';
import LocationGeofenceMap from '../maps/locationGeofenceMap';

const classesPrefix = 'locationDetailsDialog';

const classes = {
    infoIcon: `${classesPrefix}-infoIcon`,
    flexWrapper: `${classesPrefix}-flexWrapper`,
    listWrapper: `${classesPrefix}-listWrapper`,
    sectionTitle: `${classesPrefix}-sectionTitle`,
    sectionWrapper: `${classesPrefix}-sectionWrapper`,
    address: `${classesPrefix}-address`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`& .${classes.infoIcon}`]: {
            verticalAlign: 'middle',
            marginLeft: '4px'
        },
        [`& .${classes.flexWrapper}`]: {
            display: 'flex'
        },
        [`& .${classes.listWrapper}`]: {
            paddingInlineStart: '16px',
            margin: 0
        },
        [`& .${classes.sectionTitle}`]: {
            fontSize: '20px',
            fontWeight: 600
        },
        [`& .${classes.sectionWrapper}`]: {
            marginTop: '8px'
        },
        [`& .${classes.address}`]: {
            color: theme.palette.text.secondary,
            fontSize: '14px',
            fontStyle: 'normal'
        }
    };
});

const LocationDetailsDialog = ({
    locationCode,
    modeType,
    isOpen,
    closeDialog
}: {
    locationCode: string;
    modeType: ModeType;
    isOpen: boolean;
    closeDialog: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const freightPortalUiUrl = useTypedSelector((state) => { return state.availableServices.endpoints.FreightPortalUI; });
    const freightPortalApplications = useTypedSelector((state) => { return state.user.userInformation.userApplications.freightPortal; });
    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });
    const status = useTypedSelector((state) => { return state.locationDetails.fixedLocationDetailsStatus; });
    const locationDetails = useTypedSelector((state) => { return state.locationDetails.fixedLocationDetails; });

    useEffect(() => {
        dispatch(fetchLocationDetails(locationCode));
    }, [dispatch, locationCode]);

    const isFetching = status === ApiStatus.Idle || status === ApiStatus.Loading;
    const hasLocationManagementAccess = freightPortalApplications.find((app) => {
        return app.resourceKey === ApplicationResourceNames.Locations;
    })?.isAllowed;

    const modeTypeOnTimeRules = organizationPreferences?.freightPortalPreferencesOnTimeRules.find((rules) => {
        return rules.modeType === modeType;
    });

    const renderOrgPreferencesTooltip = (): JSX.Element => {
        return (
            <Tooltip title='This setting is inherited from the Organization Preferences'>
                <Icon
                    className={classes.infoIcon}
                    path={mdiInformationOutline}
                    size='20px'
                    color={theme.palette.text.primary}
                />
            </Tooltip>
        );
    };

    const renderOperatingHours = (): JSX.Element[] => {
        if (isFetching) {
            return Array.from(new Array(7)).map((item, index): JSX.Element => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Skeleton key={index} variant='text' width='200px' />
                );
            });
        }

        return locationDetails.operatingHours.map((metadata) => {
            return (
                <LabelValuePair
                    key={metadata.dayOfWeek}
                    label={metadata.dayOfWeek}
                    value={`${metadata.startTime} - ${metadata.endTime}`}
                    data-qa={`operatingHours-${metadata.dayOfWeek}`}
                />
            );
        });
    };

    const renderOnTimeRules = (): JSX.Element => {
        if (isFetching) {
            return (
                <Fragment>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>On Time Rules</Typography>
                    <ul className={classes.listWrapper}>
                        <li><Skeleton variant='text' width='100%' /></li>
                        <li><Skeleton variant='text' width='100%' /></li>
                        <li><Skeleton variant='text' width='100%' /></li>
                        <li><Skeleton variant='text' width='100%' /></li>
                    </ul>
                </Fragment>
            );
        }

        if (locationDetails.overrideDeliveryStatusFlag) {
            return (
                <Fragment>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>On Time Rules</Typography>
                    <OnTimeRulesList
                        hasEarlyStatusDefined={locationDetails.hasEarlyStatusDefined}
                        hasInJeopardyStatusDefined={locationDetails.hasInJeopardyStatusDefined}
                        earlyShipmentStatusMinutes={locationDetails.earlyShipmentStatusMinutes || 0}
                        onTimeShipmentStatusMinutes={locationDetails.onTimeShipmentStatusMinutes || 0}
                        inJeopardyShipmentStatusMinutes={locationDetails.inJeopardyShipmentStatusMinutes || 0}
                    />
                </Fragment>
            );
        }

        if (modeTypeOnTimeRules) {
            return (
                <Fragment>
                    <div className={classes.flexWrapper}>
                        <Typography className={classes.sectionTitle} variant='h2' gutterBottom>On Time Rules</Typography>
                        {renderOrgPreferencesTooltip()}
                    </div>
                    <OnTimeRulesList
                        hasEarlyStatusDefined={modeTypeOnTimeRules.hasEarlyStatusDefined}
                        hasInJeopardyStatusDefined={modeTypeOnTimeRules.hasInJeopardyStatusDefined}
                        earlyShipmentStatusMinutes={modeTypeOnTimeRules.earlyShipmentStatusMinutes}
                        onTimeShipmentStatusMinutes={modeTypeOnTimeRules.onTimeShipmentStatusMinutes}
                        inJeopardyShipmentStatusMinutes={modeTypeOnTimeRules.inJeopardyShipmentStatusMinutes}
                    />
                </Fragment>
            );
        }

        return <Typography data-qa='onTimeRulesError'>Unable to locate on time rules for mode type</Typography>;
    };

    const renderDwellTimes = (): JSX.Element => {
        if (isFetching) {
            return (
                <Fragment>
                    <Grid item xs={12} md={6}><LabelValuePairLoading label='Dwell Time' width='100px' /></Grid>
                    <Grid item xs={12} md={6}><LabelValuePairLoading label='Dwell Time Buffer' width='100px' /></Grid>
                </Fragment>
            );
        }

        const dwellTimeValue = (): JSX.Element => {
            if (locationDetails.dwellTimeMinutes !== null && locationDetails.dwellTimeMinutes !== 0) {
                const dwellTimeHoursAndMinutes = calculateHoursAndMinutes(locationDetails.dwellTimeMinutes);
                return (
                    <Fragment>
                        <span>{`${dwellTimeHoursAndMinutes.hours} hours`}</span>
                        <span>{` ${dwellTimeHoursAndMinutes.minutes} minutes`}</span>
                    </Fragment>
                );
            }

            if (modeTypeOnTimeRules) {
                const dwellTimeHoursAndMinutes = calculateHoursAndMinutes(modeTypeOnTimeRules.dwellTimeMinutes);
                return (
                    <Fragment>
                        <span>{`${dwellTimeHoursAndMinutes.hours} hours`}</span>
                        <span>{` ${dwellTimeHoursAndMinutes.minutes} minutes`}</span>
                        {renderOrgPreferencesTooltip()}
                    </Fragment>
                );
            }

            return <Typography data-qa='dwellTimeError'>Unable to locate dwell time for mode type</Typography>;
        };

        return (
            <Fragment>
                <Grid item xs={12} md={6}>
                    <LabelValuePair
                        label='Dwell Time'
                        value={dwellTimeValue()}
                        data-qa='dwellTimeMinutes'
                    />
                </Grid>

                {
                    locationDetails.dwellTimeBufferMinutes !== null && locationDetails.dwellTimeBufferMinutes !== 0 &&
                    <Grid item xs={12} md={6}>
                        <LabelValuePair
                            label='Dwell Time Buffer'
                            value={
                                <Fragment>
                                    <span>{`${calculateHoursAndMinutes(locationDetails.dwellTimeBufferMinutes).hours} hours`}</span>
                                    <span>{` ${calculateHoursAndMinutes(locationDetails.dwellTimeBufferMinutes).minutes} minutes`}</span>
                                </Fragment>
                            }
                            data-qa='dwellTimeBufferMinutes'
                        />
                    </Grid>
                }
            </Fragment>
        );
    };

    const renderVariableDwellTimes = (): JSX.Element => {
        if (isFetching) {
            return (
                <Grid item xs={12} className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>Variable Dwell Times</Typography>
                    <Grid container>
                        {
                            Array.from(new Array(6)).map((item, index): JSX.Element => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid key={index} item xs={12} md={6}><Skeleton variant='text' width='200px' /></Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            );
        }

        if (locationDetails.loadTimes.length > 0) {
            return (
                <Grid item xs={12} className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>Variable Dwell Times</Typography>
                    <Grid container>
                        {
                            locationDetails.loadTimes.map((metadata) => {
                                return (
                                    <Grid key={`${metadata.pieceUnit}-${metadata.isLoadFlag}`} item xs={12} md={6}>
                                        <LabelValuePair
                                            label={`${metadata.pieceUnit} ${metadata.isLoadFlag ? 'Load' : 'Unload'}`}
                                            value={
                                                <Fragment>
                                                    <span>{`${calculateHoursAndMinutes(metadata.loadTimeMinutes).hours} hours`}</span>
                                                    <span>{` ${calculateHoursAndMinutes(metadata.loadTimeMinutes).minutes} minutes`}</span>
                                                </Fragment>
                                            }
                                            data-qa={`loadTimes-${metadata.pieceUnit}-${metadata.isLoadFlag ? 'load' : 'unload'}`}
                                        />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            );
        }

        return <Fragment />;
    };

    const renderGeofence = (): JSX.Element => {
        if (isFetching) {
            return (
                <Fragment>
                    <Grid item xs={12} md={6}>
                        <LabelValuePairLoading label='Geofence Style' width='80px' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabelValuePairLoading label='Radius' width='80px' />
                    </Grid>
                </Fragment>
            );
        }

        if (locationDetails.geofencePolygon) {
            return (
                <Grid item xs={12}>
                    <LabelValuePair label='Geofence Style' value='Custom' data-qa='geofenceStyle' />
                </Grid>
            );
        }

        if (locationDetails.geofenceRadiusInFeet) {
            return (
                <Fragment>
                    <Grid item xs={12} md={6}>
                        <LabelValuePair label='Geofence Style' value='Radius' data-qa='geofenceStyle' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabelValuePair label='Radius' value={getGeofenceRadiusInMiles(locationDetails.geofenceRadiusInFeet)} data-qa='geofenceRadius' />
                    </Grid>
                </Fragment>
            );
        }

        if (organizationPreferences?.geofenceRadiusInFeet) {
            return (
                <Fragment>
                    <Grid item xs={12} md={6}>
                        <LabelValuePair label='Geofence Style' value='Radius' data-qa='geofenceStyle' />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.flexWrapper}>
                        <LabelValuePair
                            label='Radius'
                            value={
                                <Fragment>
                                    <span>{getGeofenceRadiusInMiles(organizationPreferences.geofenceRadiusInFeet)}</span>
                                    {renderOrgPreferencesTooltip()}
                                </Fragment>
                            }
                            data-qa='geofenceRadius'
                        />
                    </Grid>
                </Fragment>
            );
        }

        return <Typography data-qa='geofenceError'>Unable to locate geofence information</Typography>;
    };

    const renderDialogContent = (): JSX.Element => {
        return (
            <StyledGrid container>
                <Grid item xs={12} md={6} className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>Overview</Typography>
                    {
                        isFetching ?
                            (
                                <Fragment>
                                    <LabelValuePairLoading label='Name' width='150px' />
                                    <LabelValuePairLoading label='Code' width='80px' />
                                    <LabelValuePairLoading label='Type' width='100px' />
                                    <LabelValuePairLoading label='Latitude' width='80px' />
                                    <LabelValuePairLoading label='Longitude' width='80px' />
                                    <Skeleton variant='text' width='150px' />
                                    <Skeleton variant='text' width='150px' />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <LabelValuePair label='Name' value={locationDetails.fixedLocationName} data-qa='fixedLocationName' />
                                    <LabelValuePair label='Code' value={locationDetails.fixedLocationCode} data-qa='fixedLocationCode' />
                                    <LabelValuePair label='Type' value={locationDetails.fixedLocationType} data-qa='fixedLocationType' />
                                    <LabelValuePair label='Latitude' value={locationDetails.fixedLocationPoint.latitude} data-qa='fixedLocationLatitude' />
                                    <LabelValuePair label='Longitude' value={locationDetails.fixedLocationPoint.longitude} data-qa='fixedLocationLongitude' />

                                    <Typography className={classes.address} component='address' data-qa='street'>
                                        {`${locationDetails.streetAddressLine1 || ''} ${locationDetails.streetAddressLine2 || ''}`}
                                    </Typography>
                                    <Typography className={classes.address} component='address' data-qa='cityRegionPostalCountry'>
                                        {
                                            formatCityRegionPostalCountry({
                                                city: locationDetails.cityName,
                                                region: locationDetails.stateCode,
                                                postal: locationDetails.postalCode,
                                                country: locationDetails.countryCode
                                            })
                                        }
                                    </Typography>
                                </Fragment>
                            )
                    }
                </Grid>

                <Grid item xs={12} md={6} className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>Hours</Typography>
                    {renderOperatingHours()}
                </Grid>

                <Grid item xs={12} className={classes.sectionWrapper}>
                    {renderOnTimeRules()}
                </Grid>

                <Grid item xs={12} className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>Dwell Time</Typography>
                    <Grid container>
                        {renderDwellTimes()}
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.sectionWrapper}>
                    {renderVariableDwellTimes()}
                </Grid>

                <Grid item xs={12} className={classes.sectionWrapper}>
                    <Typography className={classes.sectionTitle} variant='h2' gutterBottom>Geofence</Typography>
                    <Grid container>
                        {renderGeofence()}
                    </Grid>
                    <LocationGeofenceMap
                        isFetchingData={isFetching}
                        fixedLocationLongLat={[locationDetails.fixedLocationPoint.longitude, locationDetails.fixedLocationPoint.latitude]}
                        geofencePolygon={locationDetails.geofencePolygon}
                        geofenceRadiusInFeet={locationDetails.geofenceRadiusInFeet || organizationPreferences?.geofenceRadiusInFeet || 0}
                        markerImage={mapMarker}
                        paletteMode={paletteMode}
                    />
                </Grid>
            </StyledGrid>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth='lg'
            headerIcon={mdiOfficeBuildingMarker}
            headerText='Location Details'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading}
                        onClick={closeDialog}
                        data-qa='close-button'
                    >
                        Close
                    </Button>
                    {
                        hasLocationManagementAccess &&
                        <Link
                            component={Button}
                            color='primary'
                            target='_blank'
                            rel='noopener noreferrer'
                            href={`${freightPortalUiUrl}/locations/?fixedLocationCode=${locationCode}`}
                            disabled={status === ApiStatus.Loading}
                            data-qa='editLocation-button'
                        >
                            Edit Location
                        </Link>
                    }
                </Fragment>
            )}
        />
    );
};

export default LocationDetailsDialog;
