import React from 'react';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPickerSkeleton, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { displayDateTimeFormat } from '../../helpers/dateUtils';

const CustomDateTimePicker = ({
    isFetchingData,
    label,
    value,
    disabled,
    handleDateChange,
    dataQa
}: {
    isFetchingData: boolean;
    label: string;
    value: Date | null;
    disabled: boolean;
    handleDateChange: (date: Date | null) => void;
    dataQa: string;
}): JSX.Element => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label={label}
                value={value}
                onChange={(date: Date | null): void => {
                    handleDateChange(date);
                }}
                showToolbar
                ampm={false}
                disabled={disabled}
                inputFormat={displayDateTimeFormat}
                loading={isFetchingData}
                renderInput={(params): JSX.Element => {
                    return <TextField {...params} />;
                }}
                renderLoading={(): JSX.Element => {
                    return <CalendarPickerSkeleton />;
                }}
                componentsProps={{
                    actionBar: {
                        // The actions will be different between desktop and mobile
                        actions: (variant): PickersActionBarAction[] => {
                            return variant === 'desktop' ? ['today'] : ['today', 'cancel', 'accept'];
                        }
                    }
                }}
                data-qa={dataQa}
            />
        </LocalizationProvider>
    );
};

export default CustomDateTimePicker;
