import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Card,
    CardContent,
    Grid,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import {
    fetchShipmentDetailsAndRoute,
    fetchShipmentTemperatures,
    fetchShipmentWeather,
    fetchShipmentHistory
} from '../../redux/shipmentDetails';
import { ApiStatus, ModeType, ShareType, StopType } from '../../helpers/enums';
import { Stop } from '../../interfaces/services/shipmentDetails';
import { isShipmentLtl } from '../../helpers/shipmentUtils';
import OriginAndDestinationImage from '../../assets/images/maps/markers_origindestination.png';
import IntermediateStopImage from '../../assets/images/maps/markers_intermittentstops.png';
import HorizontallyCenteredSpinner from '../loaders/horizontallyCenteredSpinner';
import NoShipmentDetails from '../errors/noShipmentDetails';
import ModeBasedProgressBar from '../progressBars/modeBasedProgressBar';
import CarrierContactsTable from '../tables/carrierContactsTable';
import ShipmentAdditionalDetails from '../cards/shipmentAdditionalDetails';
import TemperatureHistoryLineChart from '../charts/temperatureHistoryLineChart';
import ShipmentDetailsCard from '../cards/shipmentDetailsCard';
import ShipmentDetailsCardLoading from '../loaders/cards/shipmentDetailsCardLoading';
import ProgressHistoryTable from '../tables/progressHistoryTable';
import ProgressHistoryTableLoading from '../loaders/tables/progressHistoryTableLoading';
import ShipmentDetailsMap from '../maps/shipmentDetailsMap';
import StopCard from '../cards/stopCard';
import StopCardLoading from '../loaders/cards/stopCardLoading';
import ShipmentHistoryTabs from '../tabs/shipmentHistoryTabs';
import NotesDialog from '../dialogs/notesDialog';
import DocumentsDialog from '../dialogs/documentsDialog';
import ShipmentPriorityDialog from '../dialogs/shipmentPriorityDialog';
import OrderExceptionsDialog from '../dialogs/orderExceptionsDialog';
import ShipmentSummaryCard from '../cards/shipmentSummaryCard';

const classesPrefix = 'shipmentDetails';

const classes = {
    gridContainer: `${classesPrefix}-gridContainer`,
    fullPageCardWrapper: `${classesPrefix}-fullPageCardWrapper`,
    displayData: `${classesPrefix}-displayData`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.gridContainer}`]: {
            marginTop: 0,
            marginBottom: '16px'
        },
        [`& .${classes.fullPageCardWrapper}`]: {
            height: 'calc(100vh - 144px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        [`& .${classes.displayData}`]: {
            fontWeight: 400,
            marginLeft: '4px',
            marginRight: '4px'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.gridContainer}`]: {
                marginLeft: 0,
                marginRight: '8px',
                maxWidth: 'calc(100vw - 16px)'
            }
        }
    };
});

const ShipmentDetails = ({
    shipmentUniqueName,
    modeType
}: {
    /** unique identifier for the shipment */
    shipmentUniqueName: string;
    /** optional mode type for the shipment */
    modeType?: ModeType;
}): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showWeatherAlerts, setShowWeatherAlerts] = useState(false);
    const [notesDialogIsOpen, setNotesDialogIsOpen] = useState(false);
    const [documentsDialogIsOpen, setDocumentsDialogIsOpen] = useState(false);
    const [shipmentPriorityDialogIsOpen, setShipmentPriorityDialogIsOpen] = useState(false);
    const [orderExceptionsDialogIsOpen, setOrderExceptionsDialogIsOpen] = useState(false);

    const shareType = useTypedSelector((state) => { return state.user.shareType; });
    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });

    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });
    const temperatures = useTypedSelector((state) => { return state.shipmentDetails.temperatures; });

    const weatherAlerts = useTypedSelector((state) => { return state.shipmentDetails.weatherAlerts; });
    const weatherPolygons = useTypedSelector((state) => { return state.shipmentDetails.weatherPolygons; });

    const historyStatus = useTypedSelector((state) => { return state.shipmentDetails.historyStatus; });
    const history = useTypedSelector((state) => { return state.shipmentDetails.history; });

    const organizationPreferencesStatus = useTypedSelector((state) => { return state.organization.organizationPreferencesStatus; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    useEffect((): void => {
        dispatch(fetchShipmentDetailsAndRoute(shipmentUniqueName));
        dispatch(fetchShipmentTemperatures(shipmentUniqueName));
        dispatch(fetchShipmentWeather(shipmentUniqueName));
        if (shareType !== ShareType.Stop) {
            dispatch(fetchShipmentHistory(shipmentUniqueName));
        }
    }, [dispatch, shipmentUniqueName, shareType]);

    const isFetchingShipment =
        organizationPreferencesStatus === ApiStatus.Idle || organizationPreferencesStatus === ApiStatus.Loading ||
        shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading;

    const renderStopCards = (): React.ReactNode => {
        if (isFetchingShipment) {
            return (
                <Fragment>
                    <Grid item xs={12} md={10}>
                        <StopCardLoading />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <StopCardLoading />
                    </Grid>
                </Fragment>
            );
        }

        return shipment.stops.map((stop: Stop): React.ReactNode => {
            const createStopTypeLabel = (): React.ReactNode => {
                if (stop.stopType === StopType.Intermediate) {
                    return (
                        <Fragment>
                            Stop
                            <span className={classes.displayData} data-qa='stopSequence'>{stop.stopSequence - 1}</span>
                            of
                            <span className={classes.displayData} data-qa='totalStopCount'>{shipment.totalStopCount - 1}</span>
                        </Fragment>
                    );
                }

                return stop.stopType;
            };

            return (
                <Grid key={stop.shipmentStopGUID} item xs={12} md={10} data-qa={`${stop.stopReference}-container`}>
                    <StopCard
                        shipmentUniqueName={shipment.shipmentUniqueName}
                        modeType={shipment.modeType}
                        stop={stop}
                        orders={shipment.orders}
                        stopTypeLabel={createStopTypeLabel()}
                    />
                </Grid>
            );
        });
    };

    // if the mode type passed in doesn't exist, we want to just show a full screen spinner instead of the skeleton loaders
    if (isFetchingShipment && !modeType) {
        return (
            <StyledGrid container justifyContent='center' alignItems='center' spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={10}>
                    <Card className={classes.fullPageCardWrapper}>
                        <CardContent>
                            <HorizontallyCenteredSpinner />
                        </CardContent>
                    </Card>
                </Grid>
            </StyledGrid>
        );
    }

    if ((!isFetchingShipment && shipment.shipmentUniqueName !== shipmentUniqueName) || shipmentStatus === ApiStatus.Failure) {
        return (
            <StyledGrid container justifyContent='center' alignItems='center' spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={10}>
                    <Card className={classes.fullPageCardWrapper}>
                        <CardContent>
                            <NoShipmentDetails />
                        </CardContent>
                    </Card>
                </Grid>
            </StyledGrid>
        );
    }

    if (shareType === ShareType.Stop) {
        return (
            <StyledGrid container justifyContent='center' spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={10}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <ShipmentSummaryCard
                                isFetching={isFetchingShipment}
                                freightProviderReferenceNumber={shipment.freightProviderReferenceNumber}
                                shipmentStatus={shipment.shipmentStatus}
                                deliveryStatus={shipment.deliveryStatus}
                                modeType={shipment.modeType}
                                estimatedDeliveryDateTime={shipment.stops[0].estimatedDeliveryDateTime}
                                mobileTrackingNumber={shipment.mobileTrackingNumber}
                                freightHauler={shipment.freightHauler}
                                organizationContact={shipment.organizationContact}
                                driver={shipment.driver}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Card>
                                <CardContent>
                                    <ShipmentDetailsMap
                                        showMapControlBar={false}
                                        isFetchingData={isFetchingShipment}
                                        isRouteLineVisible={false}
                                        shipment={{
                                            ...shipment,
                                            breadcrumbPositions: [],
                                            plannedRoute: [],
                                            ...shipment.stops[0]?.hasStopBeenVisited && { currentPosition: null }
                                        }}
                                        isWeatherAlertsVisible={showWeatherAlerts}
                                        weatherAlerts={weatherAlerts}
                                        weatherPolygons={weatherPolygons}
                                        handleWeatherAlertsClick={(): void => {
                                            setShowWeatherAlerts(!showWeatherAlerts);
                                        }}
                                        originAndDestinationImage={OriginAndDestinationImage}
                                        intermediateStopImage={IntermediateStopImage}
                                        paletteMode={paletteMode}
                                        isMobile={isMobile}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                {renderStopCards()}
            </StyledGrid>
        );
    }

    return (
        <Fragment>
            <StyledGrid container justifyContent='center' spacing={2} className={classes.gridContainer} data-qa='shipmentDetails-content'>
                <Grid item xs={12} md={10} data-qa='detailsCard-container'>
                    <Card>
                        <CardContent>
                            {
                                isFetchingShipment
                                    ? (
                                        <Fragment>
                                            <ShipmentDetailsCardLoading />

                                            <ModeBasedProgressBar
                                                modeType={modeType}
                                                shipment={null}
                                            />

                                            {
                                                modeType && isShipmentLtl(modeType) &&
                                                <ProgressHistoryTableLoading numberOfRows={1} />
                                            }
                                        </Fragment>
                                    )
                                    : (
                                        <Fragment>
                                            <ShipmentDetailsCard
                                                shipment={shipment}
                                                handleWeatherSeverityClick={(): void => {
                                                    setShowWeatherAlerts(true);
                                                    document.getElementById('mapCard')?.scrollIntoView({ block: 'center' });
                                                }}
                                                handleTemperatureExceededBoundsClick={(): void => {
                                                    document.getElementById('temperatureHistoryCard')?.scrollIntoView({ block: 'center' });
                                                }}
                                                handleShipmentNotesClick={(): void => {
                                                    setNotesDialogIsOpen(true);
                                                }}
                                                handleShipmentDocumentsClick={(): void => {
                                                    setDocumentsDialogIsOpen(true);
                                                }}
                                                handleShipmentPriorityClick={(): void => {
                                                    // only enable removing the shipment priority if the shipment is not shared
                                                    if (shareType === null) {
                                                        setShipmentPriorityDialogIsOpen(true);
                                                    }
                                                }}
                                                handleShipmentExceptionsClick={(): void => {
                                                    setOrderExceptionsDialogIsOpen(true);
                                                }}
                                            />

                                            <ModeBasedProgressBar
                                                modeType={shipment.modeType}
                                                shipment={shipment}
                                            />

                                            {
                                                isShipmentLtl(shipment.modeType) &&
                                                <ProgressHistoryTable
                                                    limitDisplayedRowsCount={1}
                                                    shipmentMilestones={shipment.shipmentMilestones}
                                                />
                                            }
                                        </Fragment>
                                    )
                            }
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={10} data-qa='carrierContactsCard-container'>
                    <Card>
                        <CardContent>
                            <CarrierContactsTable limitDisplayedRowsCount={1} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={10} data-qa='additionalDetailsCard-container'>
                    <ShipmentAdditionalDetails />
                </Grid>

                {
                    temperatures.temperatureHistory.length > 0 &&
                    <Grid id='temperatureHistoryCard' item xs={12} md={10} data-qa='temperatureHistoryCard-container'>
                        <Card>
                            <CardContent>
                                <TemperatureHistoryLineChart />
                            </CardContent>
                        </Card>
                    </Grid>
                }

                <Grid id='mapCard' item xs={12} md={10} data-qa='mapCard-container'>
                    <Card>
                        <CardContent>
                            <ShipmentDetailsMap
                                showMapControlBar={true}
                                isFetchingData={isFetchingShipment}
                                isRouteLineVisible={organizationPreferences ? organizationPreferences.showPCMilerRouteByDefaultFlag : true}
                                shipment={shipment}
                                isWeatherAlertsVisible={showWeatherAlerts}
                                weatherAlerts={weatherAlerts}
                                weatherPolygons={weatherPolygons}
                                handleWeatherAlertsClick={(): void => {
                                    setShowWeatherAlerts(!showWeatherAlerts);
                                }}
                                originAndDestinationImage={OriginAndDestinationImage}
                                intermediateStopImage={IntermediateStopImage}
                                paletteMode={paletteMode}
                                isMobile={isMobile}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {renderStopCards()}

                <Grid item xs={12} md={10} data-qa='shipmentHistory-container'>
                    <Card>
                        <CardContent>
                            <ShipmentHistoryTabs
                                isFetching={historyStatus === ApiStatus.Idle || historyStatus === ApiStatus.Loading}
                                history={history}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </StyledGrid>

            {
                notesDialogIsOpen &&
                <NotesDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={notesDialogIsOpen}
                    closeDialog={(): void => {
                        setNotesDialogIsOpen(false);
                    }}
                />
            }

            {
                documentsDialogIsOpen &&
                <DocumentsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={documentsDialogIsOpen}
                    closeDialog={(): void => {
                        setDocumentsDialogIsOpen(false);
                    }}
                />
            }

            {
                shareType === null && shipmentPriorityDialogIsOpen &&
                <ShipmentPriorityDialog
                    freightProviderReferenceNumber={shipment.freightProviderReferenceNumber}
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={shipmentPriorityDialogIsOpen}
                    closeDialog={(): void => {
                        setShipmentPriorityDialogIsOpen(false);
                    }}
                />
            }

            {
                orderExceptionsDialogIsOpen &&
                <OrderExceptionsDialog
                    shipmentUniqueName={shipment.shipmentUniqueName}
                    isOpen={orderExceptionsDialogIsOpen}
                    closeDialog={(): void => {
                        setOrderExceptionsDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default ShipmentDetails;
