import React, { Fragment } from 'react';
import { Breakpoint, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Hidden = ({
    breakpoint,
    direction = 'only',
    children
}: {
    /** Hide the given breakpoint. */
    breakpoint: Breakpoint;
    /**
     * Direction to check the breakpoint. If 'only', it will hide only for the given breakpoint.
     * @default only
     */
    direction?: 'up' | 'down' | 'only';
    /** The content of the component. */
    children?: React.ReactNode;
}): JSX.Element => {
    const theme = useTheme();
    const isHidden = useMediaQuery(theme.breakpoints[direction](breakpoint));

    if (!isHidden) {
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }

    return <Fragment />;
};

export default Hidden;
