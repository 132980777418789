import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';

import { useTypedSelector } from '../../redux';
import { fetchLocationDetails, deleteLocation } from '../../redux/locations';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { formatCityRegionPostalCountry } from '../../helpers/addressUtils';
import UnsavedChangesDialog from '../dialogs/unsavedChangesDialog';
import DeleteConfirmationDialog from '../dialogs/deleteConfirmationDialog';

const classesPrefix = 'locationCard';

const classes = {
    cardContainer: `${classesPrefix}-cardContainer`,
    cardWrapper: `${classesPrefix}-cardWrapper`,
    cardSelected: `${classesPrefix}-cardSelected`,
    updateWrapper: `${classesPrefix}-updateWrapper`,
    updateContainer: `${classesPrefix}-updateContainer`,
    rightSideWrapper: `${classesPrefix}-rightSideWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`&.${classes.cardContainer}`]: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-of-type': {
                borderBottom: 0
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.action.hover
            }
        },
        [`&.${classes.cardSelected}`]: {
            backgroundColor: theme.palette.action.selected
        },
        [`& .${classes.cardWrapper}`]: {
            padding: '8px 16px'
        },
        [`& .${classes.updateWrapper}`]: {
            color: theme.palette.text.secondary,
            fontSize: '12px'
        },
        [`& .${classes.updateContainer}`]: {
            alignSelf: 'center'
        },
        [`& .${classes.rightSideWrapper}`]: {
            textAlign: 'right'
        }
    };
});

const LocationCard = ({
    location: {
        fixedLocationCode,
        fixedLocationName,
        cityName,
        stateCode,
        countryCode,
        lastUpdatedDate
    },
    isFormDirty,
    handleCardClick
}: {
    // FIXME: this should be a real type
    location: any;
    isFormDirty: boolean;
    handleCardClick: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const [unsavedChangesDialogIsOpen, setUnsavedChangesDialogIsOpen] = useState(false);
    const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] = useState(false);

    const skip = useTypedSelector((state) => { return state.locations.skip; });
    const searchTerm = useTypedSelector((state) => { return state.locations.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.locations.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.locations.sortDirection; });
    const locationDetails = useTypedSelector((state) => { return state.locations.locationDetails; });

    const handleLocationCardClick = (): void => {
        if (isFormDirty) {
            setUnsavedChangesDialogIsOpen(true);
        } else if (fixedLocationCode !== locationDetails?.fixedLocationCode) {
            handleCardClick();
            dispatch(fetchLocationDetails(fixedLocationCode));
        }
    };

    const handleDeleteClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        setDeleteConfirmationDialogIsOpen(true);
    };

    return (
        <Fragment>
            <StyledGrid
                container
                data-qa='locationSummaryCard-container'
                className={`${classes.cardContainer} ${fixedLocationCode === locationDetails?.fixedLocationCode ? classes.cardSelected : ''}`}
                alignItems='center'
                onClick={handleLocationCardClick}
            >
                <Grid item xs={12} className={classes.cardWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Typography data-qa='locationName' noWrap={true}>
                                {fixedLocationName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.rightSideWrapper}>
                            <Typography data-qa='locationCode'>
                                {fixedLocationCode}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} data-qa='locationType'>
                            {
                                formatCityRegionPostalCountry({
                                    city: cityName,
                                    region: stateCode,
                                    country: countryCode
                                })
                            }
                        </Grid>
                        <Grid item xs={8} className={classes.updateContainer}>
                            <Typography className={classes.updateWrapper} data-qa='locationLastUpdated'>
                                {`Last updated ${zonedDateTimeToDisplay(lastUpdatedDate)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.rightSideWrapper}>
                            <Tooltip title='Delete'>
                                <IconButton
                                    size='small'
                                    onClick={handleDeleteClick}
                                    data-qa='deleteLocation-action'
                                >
                                    <Icon path={mdiDelete} size={1} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledGrid>
            {
                unsavedChangesDialogIsOpen &&
                <UnsavedChangesDialog
                    isOpen={unsavedChangesDialogIsOpen}
                    handleClose={(): void => {
                        setUnsavedChangesDialogIsOpen(false);
                    }}
                    handleContinue={(): void => {
                        dispatch(fetchLocationDetails(fixedLocationCode));
                        setUnsavedChangesDialogIsOpen(false);
                    }}
                />
            }
            {
                deleteConfirmationDialogIsOpen &&
                <DeleteConfirmationDialog
                    isOpen={deleteConfirmationDialogIsOpen}
                    handleClose={(): void => {
                        setDeleteConfirmationDialogIsOpen(false);
                    }}
                    handleContinue={(): void => {
                        dispatch(deleteLocation({
                            fixedLocationCode,
                            skip,
                            searchTerm,
                            sortColumn,
                            sortDirection
                        }));
                        setDeleteConfirmationDialogIsOpen(false);
                    }}
                />
            }
        </Fragment>
    );
};

export default LocationCard;
