import React from 'react';
import { useDispatch } from 'react-redux';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { autoRefreshOptions } from '../../../helpers/hardcodedOptionLists';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'autoRefreshPreferencesCard';

const classes = {
    selectField: `${classesPrefix}-selectField`
};

const StyledSelect = styled(Select)(() => {
    return {
        [`&.${classes.selectField}`]: {
            marginTop: '16px',
            maxWidth: '320px'
        }
    };
});

const AutoRefreshPreferencesCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleAutoRefreshMinutesChange = (event: SelectChangeEvent<unknown>): void => {
        dispatch(editPreferences(PreferencesFieldNames.autoRefreshMinutes, Number(event.target.value)));
    };

    return (
        <PreferenceCard header='Auto Refresh Preferences'>
            <Typography component='p' variant='body2'>
                How often do you want your freight portal data to refresh?
            </Typography>
            <StyledSelect
                className={classes.selectField}
                variant='standard'
                value={organizationEditablePreferences.autoRefreshMinutes}
                onChange={handleAutoRefreshMinutesChange}
                inputProps={{
                    'data-qa': 'autoRefresh-input'
                }}
            >
                {
                    autoRefreshOptions.map((option) => {
                        return (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                data-qa={`autoRefresh-item-${option.label}`}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })
                }
            </StyledSelect>
        </PreferenceCard>
    );
};

export default AutoRefreshPreferencesCard;
