import React from 'react';
import { useParams } from 'react-router-dom';
import { Toolbar, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import OrderDetails from '../sections/orderDetails';

const classesPrefix = 'orderDetailsPage';

const classes = {
    wrapper: `${classesPrefix}-wrapper`,
    toolbar: `${classesPrefix}-toolbar`,
    title: `${classesPrefix}-title`,
    inlineSkeleton: `${classesPrefix}-inlineSkeleton`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.wrapper}`]: {
            backgroundColor: theme.appColors.pageBackground,
            paddingBottom: '16px'
        },
        [`& .${classes.toolbar}`]: {
            top: '48px',
            right: 0,
            left: 'auto',
            position: 'sticky',
            zIndex: 11
        },
        [`& .${classes.title}`]: {
            marginRight: '16px'
        },
        [`& .${classes.inlineSkeleton}`]: {
            display: 'inline-flex',
            marginLeft: '4px'
        }
    };
});

const OrderDetailsPage = (): JSX.Element => {
    const { orderGuid } = useParams<{ orderGuid: string; }>();
    const orderDetailsStatus = useTypedSelector((state) => { return state.orderDetails.orderDetailsStatus; });
    const order = useTypedSelector((state) => { return state.orderDetails.order; });

    return (
        <StyledDiv className={classes.wrapper} data-qa='orderDetailsPage-container'>
            <Toolbar classes={{ root: classes.toolbar }}>
                <Typography variant='h6' className={classes.title} data-qa='orderDetails-header'>
                    Order
                    {
                        orderDetailsStatus === ApiStatus.Idle || orderDetailsStatus === ApiStatus.Loading
                            ? <Skeleton className={classes.inlineSkeleton} variant='text' width='100px' />
                            : ` ${order.orders[0]?.orderNumber || ''}`
                    }
                </Typography>
            </Toolbar>
            <OrderDetails orderGuid={orderGuid} />
        </StyledDiv>
    );
};

export default OrderDetailsPage;
