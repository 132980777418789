import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Grid,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { fetchNotificationsList, resetNotificationDetails } from '../../redux/notifications';
import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { notificationSortOptions } from '../../helpers/hardcodedOptionLists';
import { NotificationsSortOption } from '../../interfaces/componentInterfaces';
import SearchInput from '../inputs/searchInput';
import SortSelect from '../selects/sortSelect';
import NotificationCardList from '../lists/notificationCardList';
import NotificationsForm from '../forms/notificationsForm';
import ListPagination from '../paginations/listPagination';

const classesPrefix = 'notificationsPage';

const classes = {
    gridItemWrapper: `${classesPrefix}-gridItemWrapper`,
    searchWrapper: `${classesPrefix}-searchWrapper`,
    cardListWrapper: `${classesPrefix}-cardListWrapper`,
    paginationWrapper: `${classesPrefix}-paginationWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`& .${classes.gridItemWrapper}`]: {
            height: 'calc(100vh - 48px)',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        [`& .${classes.searchWrapper}`]: {
            margin: '8px'
        },
        [`& .${classes.cardListWrapper}`]: {
            overflowY: 'auto',
            flexGrow: 1,
            borderTop: `2px solid ${theme.palette.divider}`,
            borderBottom: `2px solid ${theme.palette.divider}`
        },
        [`& .${classes.paginationWrapper}`]: {
            display: 'flex'
        },
        [theme.breakpoints.down('md')]: {
            [`& .${classes.paginationWrapper}`]: {
                padding: '6px 8px'
            }
        }
    };
});

const NotificationsPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(isMobile);
    const [isUserEditing, setIsUserEditing] = useState(false);

    const notificationsList = useTypedSelector((state) => { return state.notifications.notificationsList; });
    const notificationsListStatus = useTypedSelector((state) => { return state.notifications.notificationsListStatus; });
    const notificationDetails = useTypedSelector((state) => { return state.notifications.notificationDetails; });
    const totalRecords = useTypedSelector((state) => { return state.notifications.totalPossibleCount; });
    const skip = useTypedSelector((state) => { return state.notifications.skip; });
    const take = useTypedSelector((state) => { return state.notifications.take; });
    const searchTerm = useTypedSelector((state) => { return state.notifications.searchTerm; });
    const sortColumn = useTypedSelector((state) => { return state.notifications.sortColumn; });
    const sortDirection = useTypedSelector((state) => { return state.notifications.sortDirection; });
    const filters = useTypedSelector((state) => { return state.notifications.filters; });

    const selectedSort = useMemo((): NotificationsSortOption => {
        const sortOption = notificationSortOptions.find((sortItem): boolean => {
            return sortItem.sortDirection === sortDirection && sortItem.sortColumn === sortColumn;
        });
        return sortOption || notificationSortOptions[0];
    }, [sortDirection, sortColumn]);

    useEffect(() => {
        dispatch(resetNotificationDetails());
        dispatch(fetchNotificationsList({}));
    }, [dispatch]);

    // Ensure we are tagging edit mode when a user has altered the form or is viewing a notification, so it will open when switching to mobile
    useEffect(() => {
        if (notificationDetails.notificationGuid !== '' || isFormDirty) {
            setIsUserEditing(true);
        } else if (!isFormDirty && !isMobile) {
            setIsUserEditing(false);
        }
    }, [isFormDirty, isMobile, notificationDetails.notificationGuid]);

    useEffect(() => {
        setIsMobileDialogOpen(isMobile);
    }, [isMobile]);

    const handleSearchTermSubmit = (submittedSearchTerm: string): void => {
        dispatch(fetchNotificationsList({
            searchTerm: submittedSearchTerm,
            sortColumn,
            sortDirection,
            filters
        }));
    };

    const renderPagination = (): JSX.Element => {
        return (
            <ListPagination
                isFetchingData={notificationsListStatus === ApiStatus.Idle || notificationsListStatus === ApiStatus.Loading}
                skip={skip}
                take={take}
                showPaginationTotals={!isMobile}
                loadedRecordsCount={notificationsList.length}
                totalRecordsCount={totalRecords}
                siblingCount={0}
                handlePaginationChange={(newSkip: number): void => {
                    dispatch(fetchNotificationsList({
                        skip: newSkip
                    }));
                }}
            />
        );
    };

    return (
        <StyledGrid container>
            <Grid item xs={12} md={5} lg={4} xl={3} className={classes.gridItemWrapper}>
                <div className={classes.searchWrapper}>
                    <SearchInput
                        id='notificationSearch'
                        inputLabel='Search'
                        searchTerm={searchTerm}
                        handleSearchTermSubmit={handleSearchTermSubmit}
                    />
                    <SortSelect
                        id='notificationsSort'
                        selectedValue={selectedSort}
                        availableOptions={notificationSortOptions}
                        handleChange={(newValue): void => {
                            dispatch(fetchNotificationsList({
                                skip,
                                take,
                                searchTerm,
                                sortColumn: newValue.sortColumn,
                                sortDirection: newValue.sortDirection,
                                filters
                            }));
                        }}
                    />
                </div>

                <div className={classes.cardListWrapper} data-qa='notificationCards-container'>
                    <NotificationCardList
                        isFormDirty={isFormDirty}
                        handleCardClick={(): void => {
                            setIsUserEditing(true);
                            if (isMobile) {
                                setIsMobileDialogOpen(true);
                            }
                        }}
                    />
                </div>
                <div className={classes.paginationWrapper}>
                    {
                        isMobile &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={(): void => {
                                setIsMobileDialogOpen(true);
                                setIsUserEditing(true);
                            }}
                        >
                            Create
                        </Button>
                    }
                    {renderPagination()}
                </div>
            </Grid>
            <Grid item xs className={classes.gridItemWrapper}>
                <NotificationsForm
                    handleFormDirtyChange={(isDirty): void => { setIsFormDirty(isDirty); }}
                    isFormDirty={isFormDirty}
                    handleCancelClick={(): void => {
                        setIsUserEditing(false);
                        if (isMobile) {
                            setIsMobileDialogOpen(false);
                        }
                    }}
                    isDialogOpen={isMobileDialogOpen && isUserEditing}
                />
            </Grid>
        </StyledGrid>
    );
};

export default NotificationsPage;
