import { AllowedFilterPropertyName, CalendarStopType, DeliveryStatus } from '../helpers/enums';
import { initialState as moreFiltersInitialState } from './filterInterfaces';

export const UPDATE_DELIVERY_STATUS_FILTERS = 'UPDATE_DELIVERY_STATUS_FILTERS';
export const UPDATE_STOP_TYPE_FILTERS = 'UPDATE_STOP_TYPE_FILTERS';

export type CalendarFilterState = {
    calendarStopTypes: {
        [K in CalendarStopType]: boolean;
    };
    deliveryStatuses: {
        [K in DeliveryStatus]: boolean;
    };
    moreFilters: {
        [K in AllowedFilterPropertyName]: string[];
    };
}

export const calendarFilterInitialState: CalendarFilterState = {
    calendarStopTypes: {
        [CalendarStopType.Delivery]: true,
        [CalendarStopType.Pickup]: true
    },
    deliveryStatuses: {
        [DeliveryStatus.Early]: true,
        [DeliveryStatus.InJeopardy]: true,
        [DeliveryStatus.Late]: true,
        [DeliveryStatus.OnTime]: true,
        [DeliveryStatus.TenderAccepted]: true,
        [DeliveryStatus.TrackingLost]: true
    },
    moreFilters: moreFiltersInitialState
};
