import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import MilestoneProgressBarStep from './milestoneProgressBarStep';
import { MilestoneProgressData } from '../../interfaces/services/shipmentDetails';
import { MilestoneType } from '../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../helpers/dataUtils';

const classesPrefix = 'milestoneProgressBar';

const classes = {
    milestone: `${classesPrefix}-milestone`,
    milestoneReceivedText: `${classesPrefix}-milestoneReceivedText`
};

const StyledSection = styled('section')(() => {
    return {
        [`&.${classes.milestone}`]: {
            width: '100%',
            marginTop: '16px'
        },
        [`& .${classes.milestoneReceivedText}`]: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '8px'
        }
    };
});

const MilestoneProgressBar = ({
    milestoneProgress,
    color
}: {
    milestoneProgress: MilestoneProgressData | null;
    color: string;
}): JSX.Element => {
    const milestoneLookup = {
        [MilestoneType.Pending]: 1,
        [MilestoneType.PickedUp]: 2,
        [MilestoneType.InTransit]: 3,
        [MilestoneType.OutForDelivery]: 4,
        [MilestoneType.Delivered]: 5
    };

    let progress = 0;
    if (milestoneProgress !== null) {
        progress = milestoneLookup[milestoneProgress.furthest] || 0;
    }

    return (
        <StyledSection className={classes.milestone} data-qa='milestoneProgressBar-container'>
            {
                Object.keys(MilestoneType).map((milestone: string, index: number): JSX.Element => {
                    const currentStep = index + 1;
                    return (
                        <MilestoneProgressBarStep
                            key={milestone}
                            label={addSpaceBeforeUppercaseCharacter(milestone)}
                            milestoneProgress={progress}
                            currentStep={currentStep}
                            color={color}
                        />
                    );
                })
            }
            <Grid container justifyContent='flex-end'>
                <Grid item xs={6} className={classes.milestoneReceivedText}>
                    {
                        milestoneProgress?.receivedDate &&
                        <Typography data-qa='milestoneReceived'>{`Received at ${milestoneProgress.receivedDate}`}</Typography>
                    }
                </Grid>
            </Grid>
        </StyledSection>
    );
};

export default MilestoneProgressBar;
