import React from 'react';
import { styled } from '@mui/material/styles';

import { getDeliveryStatusColor } from '../../helpers/styleHelpers';
import { CalendarShipment } from '../../interfaces/services/shipmentCalendar';
import { DeliveryStatus } from '../../helpers/enums';

interface StyleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    deliveryStatus: DeliveryStatus;
}

const classesPrefix = 'customEvent';

const classes = {
    eventWrapper: `${classesPrefix}-eventWrapper`,
    indicator: `${classesPrefix}-indicator`
};

const StyledDiv = styled(
    'div',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'deliveryStatus'; }
    }
)<StyleProps>(({ deliveryStatus, theme }) => {
    return {
        [`&.${classes.eventWrapper}`]: {
            display: 'inline-flex',
            fontSize: '14px'
        },
        [`& .${classes.indicator}`]: {
            marginRight: '4px',
            backgroundColor: getDeliveryStatusColor(deliveryStatus),
            color: theme.palette.common.white
        }
    };
});

const CustomEvent = ({ event: shipment }: { event: CalendarShipment; }): JSX.Element => {
    // TODO - the background color will need to use the origin or destination delivery status accordingly once these are added to the headers api
    return (
        <StyledDiv deliveryStatus={shipment.deliveryStatus} className={classes.eventWrapper}>
            <div className={classes.indicator}>
                <span className='indicator-label'>{shipment.calendarStopType.charAt(0)}</span>
            </div>
            {shipment.title}
        </StyledDiv>
    );
};

export default CustomEvent;
