import React from 'react';
import {
    Grid,
    Typography,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MilestoneType } from '../../../helpers/enums';
import { addSpaceBeforeUppercaseCharacter } from '../../../helpers/dataUtils';

interface StyleProps {
    totalSteps: number;
}

const classesPrefix = 'milestoneProgressBarLoading';

const classes = {
    milestone: `${classesPrefix}-milestone`,
    milestoneItem: `${classesPrefix}-milestoneItem`,
    milestoneLabel: `${classesPrefix}-milestoneLabel`,
    milestoneReceivedText: `${classesPrefix}-milestoneReceivedText`
};

const StyledSection = styled(
    'section',
    {
        // This prevents passing the StyleProps down to the component
        shouldForwardProp: (prop) => { return prop !== 'totalSteps'; }
    }
)<StyleProps>(({ totalSteps }) => {
    return {
        [`&.${classes.milestone}`]: {
            width: '100%',
            marginTop: '16px'
        },
        [`& .${classes.milestoneItem}`]: {
            width: `calc((100% / ${totalSteps}) - 8px)`,
            display: 'inline-block',
            marginRight: '8px'
        },
        [`& .${classes.milestoneLabel}`]: {
            textAlign: 'center',
            fontSize: '16px'
        },
        [`& .${classes.milestoneReceivedText}`]: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '8px'
        }
    };
});

const MilestoneProgressBarLoading = (): JSX.Element => {
    const totalSteps = Object.keys(MilestoneType).length;

    return (
        <StyledSection className={classes.milestone} totalSteps={totalSteps}>
            {
                Object.keys(MilestoneType).map((milestone: string): JSX.Element => {
                    return (
                        <div key={milestone} className={classes.milestoneItem}>
                            <Typography variant='h6' className={classes.milestoneLabel}>{addSpaceBeforeUppercaseCharacter(milestone)}</Typography>
                            <Skeleton variant='rectangular' width='100%' height='24px' />
                        </div>
                    );
                })
            }
            <Grid container justifyContent='flex-end'>
                <Grid item xs={6} className={classes.milestoneReceivedText}>
                    <Skeleton variant='text' width='120px' />
                </Grid>
            </Grid>
        </StyledSection>
    );
};

export default MilestoneProgressBarLoading;
