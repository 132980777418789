import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, ModeType, PreferencesFieldNames, PreferencesOnTimeRulesFieldNames, PreferencesThemeColors } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { initialOrganizationInformationState, initialPreferencesState } from './initialState';
import { GenericApiResponse } from '../../interfaces/services';
import { OrganizationInformation, OrganizationPreferences, EditableOrganizationPreferences } from '../../interfaces/services/organization';

const ORGANIZATION_INFORMATION_REQUEST = 'ORGANIZATION_INFORMATION_REQUEST';
const ORGANIZATION_INFORMATION_SUCCESS = 'ORGANIZATION_INFORMATION_SUCCESS';
const ORGANIZATION_INFORMATION_FAILURE = 'ORGANIZATION_INFORMATION_FAILURE';

const ORGANIZATION_PREFERENCES_REQUEST = 'ORGANIZATION_PREFERENCES_REQUEST';
const ORGANIZATION_PREFERENCES_SUCCESS = 'ORGANIZATION_PREFERENCES_SUCCESS';
const ORGANIZATION_PREFERENCES_FAILURE = 'ORGANIZATION_PREFERENCES_FAILURE';

const ORGANIZATION_PREFERENCES_UPDATE_REQUEST = 'ORGANIZATION_PREFERENCES_UPDATE_REQUEST';
const ORGANIZATION_PREFERENCES_UPDATE_SUCCESS = 'ORGANIZATION_PREFERENCES_UPDATE_SUCCESS';
const ORGANIZATION_PREFERENCES_UPDATE_FAILURE = 'ORGANIZATION_PREFERENCES_UPDATE_FAILURE';

const RESET_ORGANIZATION_PREFERENCES = 'RESET_ORGANIZATION_PREFERENCES';
const EDIT_ORGANIZATION_PREFERENCES = 'EDIT_ORGANIZATION_PREFERENCES';
const EDIT_ORGANIZATION_PREFERENCES_ON_TIME_RULES = 'EDIT_ORGANIZATION_PREFERENCES_ON_TIME_RULES';
const EDIT_ORGANIZATION_PREFERENCES_COLORS = 'EDIT_ORGANIZATION_PREFERENCES_COLORS';

interface OrganizationData {
    organizationInformationStatus: ApiStatus;
    organizationInformation: OrganizationInformation;
    organizationPreferencesStatus: ApiStatus;
    organizationPreferences: OrganizationPreferences | null;
    organizationPreferencesUpdateStatus: ApiStatus;
    organizationEditablePreferences: EditableOrganizationPreferences;
}

const requestOrganizationInformation = (): Action<typeof ORGANIZATION_INFORMATION_REQUEST> => {
    return {
        type: ORGANIZATION_INFORMATION_REQUEST
    };
};

const receiveOrganizationInformation = (json: OrganizationInformation): GenericAction<typeof ORGANIZATION_INFORMATION_SUCCESS, OrganizationInformation> => {
    return {
        type: ORGANIZATION_INFORMATION_SUCCESS,
        payload: json
    };
};

const requestOrganizationInformationFailed = (): Action<typeof ORGANIZATION_INFORMATION_FAILURE> => {
    return {
        type: ORGANIZATION_INFORMATION_FAILURE
    };
};

const requestOrganizationPreferences = (): Action<typeof ORGANIZATION_PREFERENCES_REQUEST> => {
    return {
        type: ORGANIZATION_PREFERENCES_REQUEST
    };
};

const receiveOrganizationPreferences = (json: OrganizationPreferences): GenericAction<typeof ORGANIZATION_PREFERENCES_SUCCESS, OrganizationPreferences> => {
    return {
        type: ORGANIZATION_PREFERENCES_SUCCESS,
        payload: json
    };
};

const requestOrganizationPreferencesFailed = (): Action<typeof ORGANIZATION_PREFERENCES_FAILURE> => {
    return {
        type: ORGANIZATION_PREFERENCES_FAILURE
    };
};

const requestOrganizationPreferencesUpdate = (): Action<typeof ORGANIZATION_PREFERENCES_UPDATE_REQUEST> => {
    return {
        type: ORGANIZATION_PREFERENCES_UPDATE_REQUEST
    };
};

const receiveOrganizationPreferencesUpdate = (json: OrganizationPreferences): GenericAction<typeof ORGANIZATION_PREFERENCES_UPDATE_SUCCESS, OrganizationPreferences> => {
    return {
        type: ORGANIZATION_PREFERENCES_UPDATE_SUCCESS,
        payload: json
    };
};

const requestOrganizationPreferencesUpdateFailed = (): Action<typeof ORGANIZATION_PREFERENCES_UPDATE_FAILURE> => {
    return {
        type: ORGANIZATION_PREFERENCES_UPDATE_FAILURE
    };
};

export const fetchOrganizationInformation = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestOrganizationInformation());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.organizationInformation}` }) as GenericApiResponse<OrganizationInformation>;
            const { data } = json;

            dispatch(receiveOrganizationInformation(data[0]));
        } catch (error) {
            dispatch(requestOrganizationInformationFailed());
            toast.error('Error occurred while fetching organization information.');
        }
    };
};

export const fetchOrganizationPreferences = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestOrganizationPreferences());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.organizationPreferences}` }) as GenericApiResponse<OrganizationPreferences>;
            const { data } = json;

            dispatch(receiveOrganizationPreferences(data[0]));
        } catch (error) {
            dispatch(requestOrganizationPreferencesFailed());
            toast.error('Error occurred while fetching organization preferences.');
        }
    };
};

export const resetPreferences = (): Action<typeof RESET_ORGANIZATION_PREFERENCES> => {
    return {
        type: RESET_ORGANIZATION_PREFERENCES
    };
};

export const editPreferences = (fieldName: PreferencesFieldNames, updatedValue: boolean | number | string | File | null): GenericAction<typeof EDIT_ORGANIZATION_PREFERENCES,
    {
        name: PreferencesFieldNames;
        value: boolean | number | string | File | null;
    }> => {
    return {
        type: EDIT_ORGANIZATION_PREFERENCES,
        payload: { name: fieldName, value: updatedValue }
    };
};

export const editPreferencesOnTimeRules = (modeType: ModeType, fieldName: PreferencesOnTimeRulesFieldNames, updatedValue: boolean | number | null): GenericAction<typeof EDIT_ORGANIZATION_PREFERENCES_ON_TIME_RULES,
    {
        modeType: ModeType;
        name: PreferencesOnTimeRulesFieldNames;
        value: boolean | number | null;
    }> => {
    return {
        type: EDIT_ORGANIZATION_PREFERENCES_ON_TIME_RULES,
        payload: { modeType, name: fieldName, value: updatedValue }
    };
};

export const editPreferencesColors = (fieldName: PreferencesThemeColors, updatedValue: string | null): GenericAction<typeof EDIT_ORGANIZATION_PREFERENCES_COLORS,
    {
        name: PreferencesThemeColors;
        value: string | null;
    }> => {
    return {
        type: EDIT_ORGANIZATION_PREFERENCES_COLORS,
        payload: { name: fieldName, value: updatedValue }
    };
};

export const updatePreferences = (originalPreferences: OrganizationPreferences, updatedPreferences: EditableOrganizationPreferences): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestOrganizationPreferencesUpdate());

        const updatedPreferencesList: {
            op: string;
            path: string;
            value: unknown;
        }[] = [];
        const logoToUpload = updatedPreferences.customLogoToken;
        const uploadLogo = logoToUpload !== null && originalPreferences.customLogoToken !== logoToUpload;

        Object.entries(updatedPreferences).forEach(([fieldName, value]) => {
            const preferenceFieldName = fieldName as keyof OrganizationPreferences;
            // the preference is not a logo upload and is one of the allowed preference fields and the value is different from the original
            if (!uploadLogo && PreferencesFieldNames[fieldName as PreferencesFieldNames] !== undefined && originalPreferences[preferenceFieldName] !== value) {
                if (preferenceFieldName === PreferencesFieldNames.freightPortalPreferencesOnTimeRules) {
                    updatedPreferencesList.push({ op: 'replace', path: `/${preferenceFieldName}/`, value });
                } else if (preferenceFieldName === PreferencesFieldNames.freightPortalColorPreference) {
                    updatedPreferencesList.push({ op: 'replace', path: `/${preferenceFieldName}`, value });
                } else {
                    updatedPreferencesList.push({ op: 'replace', path: preferenceFieldName, value });
                }
            }
        });

        if (uploadLogo && logoToUpload) {
            const formData = new FormData();
            formData.append('imageFile', logoToUpload);

            try {
                await ApiService.put({
                    url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.updateLogo}`,
                    body: formData,
                    customHeaders: { 'Content-Type': 'multipart/form-data' }
                });
                toast.success('Custom Logo updated successfully.');
            } catch (error) {
                if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                    error.response.data.messages.forEach((errorMessage: string): void => {
                        toast.error(errorMessage);
                    });
                } else {
                    toast.error('Error occurred while updating the logo.');
                }
            }
        }

        if (updatedPreferencesList.length > 0 || uploadLogo) {
            try {
                const json = await ApiService.patch({
                    url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.organizationPreferences}`,
                    body: updatedPreferencesList
                }) as GenericApiResponse<OrganizationPreferences>;
                const { data } = json;

                dispatch(receiveOrganizationPreferencesUpdate(data[0]));
                toast.success('Preferences updated successfully.');
            } catch (error) {
                dispatch(requestOrganizationPreferencesUpdateFailed());
                if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                    error.response.data.messages.forEach((errorMessage: string): void => {
                        toast.error(errorMessage);
                    });
                } else {
                    toast.error('Error occurred while updating preferences.');
                }
            }
        }
    };
};

type OrganizationActionTypes =
    ReturnType<typeof requestOrganizationInformation> | ReturnType<typeof receiveOrganizationInformation> | ReturnType<typeof requestOrganizationInformationFailed> |
    ReturnType<typeof requestOrganizationPreferences> | ReturnType<typeof receiveOrganizationPreferences> | ReturnType<typeof requestOrganizationPreferencesFailed> |
    ReturnType<typeof requestOrganizationPreferencesUpdate> | ReturnType<typeof receiveOrganizationPreferencesUpdate> | ReturnType<typeof requestOrganizationPreferencesUpdateFailed> |
    ReturnType<typeof resetPreferences> | ReturnType<typeof editPreferences> | ReturnType<typeof editPreferencesOnTimeRules> | ReturnType<typeof editPreferencesColors>;

export const organizationReducer = (organization: OrganizationData = {
    organizationInformationStatus: ApiStatus.Idle,
    organizationInformation: initialOrganizationInformationState,
    organizationPreferencesStatus: ApiStatus.Idle,
    organizationPreferences: null,
    organizationPreferencesUpdateStatus: ApiStatus.Idle,
    organizationEditablePreferences: initialPreferencesState
}, action: OrganizationActionTypes): OrganizationData => {
    switch (action.type) {
        case ORGANIZATION_INFORMATION_REQUEST: {
            return {
                ...organization,
                organizationInformationStatus: ApiStatus.Loading
            };
        }
        case ORGANIZATION_INFORMATION_SUCCESS: {
            return {
                ...organization,
                organizationInformationStatus: ApiStatus.Success,
                organizationInformation: action.payload
            };
        }
        case ORGANIZATION_INFORMATION_FAILURE: {
            return {
                ...organization,
                organizationInformationStatus: ApiStatus.Failure,
                organizationInformation: initialOrganizationInformationState
            };
        }
        case ORGANIZATION_PREFERENCES_REQUEST: {
            return {
                ...organization,
                organizationPreferencesStatus: ApiStatus.Loading
            };
        }
        case ORGANIZATION_PREFERENCES_SUCCESS: {
            return {
                ...organization,
                organizationPreferencesStatus: ApiStatus.Success,
                organizationPreferences: action.payload,
                organizationEditablePreferences: action.payload
            };
        }
        case ORGANIZATION_PREFERENCES_FAILURE: {
            return {
                ...organization,
                organizationPreferencesStatus: ApiStatus.Failure,
                organizationPreferences: null,
                organizationEditablePreferences: initialPreferencesState
            };
        }
        case ORGANIZATION_PREFERENCES_UPDATE_REQUEST: {
            return {
                ...organization,
                organizationPreferencesUpdateStatus: ApiStatus.Loading
            };
        }
        case ORGANIZATION_PREFERENCES_UPDATE_SUCCESS: {
            return {
                ...organization,
                organizationPreferencesUpdateStatus: ApiStatus.Success,
                organizationPreferences: action.payload,
                organizationEditablePreferences: action.payload
            };
        }
        case ORGANIZATION_PREFERENCES_UPDATE_FAILURE: {
            return {
                ...organization,
                organizationPreferencesUpdateStatus: ApiStatus.Failure
            };
        }
        case RESET_ORGANIZATION_PREFERENCES: {
            return {
                ...organization,
                ...organization.organizationPreferences && {
                    organizationEditablePreferences: organization.organizationPreferences
                }
            };
        }
        case EDIT_ORGANIZATION_PREFERENCES: {
            return {
                ...organization,
                organizationEditablePreferences: {
                    ...organization.organizationEditablePreferences,
                    [action.payload.name]: action.payload.value
                }
            };
        }
        case EDIT_ORGANIZATION_PREFERENCES_ON_TIME_RULES: {
            const newOnTimeRules = organization.organizationEditablePreferences?.freightPortalPreferencesOnTimeRules.map((item) => {
                if (item.modeType === action.payload.modeType) {
                    return {
                        ...item,
                        [action.payload.name]: action.payload.value
                    };
                }

                return item;
            });

            return {
                ...organization,
                organizationEditablePreferences: {
                    ...organization.organizationEditablePreferences,
                    freightPortalPreferencesOnTimeRules: newOnTimeRules
                }
            };
        }
        case EDIT_ORGANIZATION_PREFERENCES_COLORS: {
            return {
                ...organization,
                organizationEditablePreferences: {
                    ...organization.organizationEditablePreferences,
                    freightPortalColorPreference: {
                        ...organization.organizationEditablePreferences.freightPortalColorPreference,
                        [action.payload.name]: action.payload.value
                    }
                }
            };
        }
        default:
            return organization;
    }
};
