import { Action } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus } from '../../helpers/enums';
import Endpoints from '../../services/endpoints';
import { ServiceLocatorEndpoints } from '../../interfaces/services/serviceLocator';

const SERVICE_LOCATOR_REQUEST = 'SERVICE_LOCATOR_REQUEST';
const SERVICE_LOCATOR_SUCCESS = 'SERVICE_LOCATOR_SUCCESS';
const SERVICE_LOCATOR_FAILURE = 'SERVICE_LOCATOR_FAILURE';

const initialEndpointsState: ServiceLocatorEndpoints = {
    AuthService: '',
    DashboardsApi: '',
    DataViewApi: '',
    FreightPortalUI: '',
    LocationManagementApi: '',
    MasterData: '',
    OrganizationsApi: '',
    PublicApi: '',
    ShipmentsApi: '',
    UrlShortenerApi: ''
};

interface Services {
    status: ApiStatus;
    endpoints: ServiceLocatorEndpoints;
}

const requestServiceLocatorEndpoints = (): Action<typeof SERVICE_LOCATOR_REQUEST> => {
    return {
        type: SERVICE_LOCATOR_REQUEST
    };
};

const receiveServiceLocatorEndpoints = (json: ServiceLocatorEndpoints): GenericAction<typeof SERVICE_LOCATOR_SUCCESS, ServiceLocatorEndpoints> => {
    return {
        type: SERVICE_LOCATOR_SUCCESS,
        payload: json
    };
};

const requestServiceLocatorEndpointsFailed = (): Action<typeof SERVICE_LOCATOR_FAILURE> => {
    return {
        type: SERVICE_LOCATOR_FAILURE
    };
};

export const fetchServiceLocatorEndpoints = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(requestServiceLocatorEndpoints());

        try {
            const json = await axios.get(Endpoints.serviceLocator) as AxiosResponse<ServiceLocatorEndpoints>;

            // Make sure we get something back from service locator and that AuthService is one of those since the app won't work without it
            if (Object.keys(json.data).length > 0 && json.data.AuthService) {
                dispatch(receiveServiceLocatorEndpoints(json.data));
            } else {
                throw new Error('Missing Service Locator Config');
            }
        } catch (err) {
            dispatch(requestServiceLocatorEndpointsFailed());
            toast.error('Error occurred while fetching Service Locator endpoints.');
        }
    };

};

type ServiceLocatorActionTypes = ReturnType<typeof requestServiceLocatorEndpoints> | ReturnType<typeof receiveServiceLocatorEndpoints> | ReturnType<typeof requestServiceLocatorEndpointsFailed>;

export const availableServicesReducer = (servicesData: Services = {
    status: ApiStatus.Idle,
    endpoints: initialEndpointsState
}, action: ServiceLocatorActionTypes): Services => {
    switch (action.type) {
        case SERVICE_LOCATOR_REQUEST: {
            return {
                ...servicesData,
                status: ApiStatus.Loading
            };
        }
        case SERVICE_LOCATOR_SUCCESS: {
            return {
                status: ApiStatus.Success,
                endpoints: {
                    AuthService: action.payload.AuthService,
                    DashboardsApi: action.payload.DashboardsApi,
                    DataViewApi: action.payload.DataViewApi,
                    FreightPortalUI: action.payload.FreightPortalUI,
                    LocationManagementApi: action.payload.LocationManagementApi,
                    MasterData: action.payload.MasterData,
                    OrganizationsApi: action.payload.OrganizationsApi,
                    PublicApi: action.payload.PublicApi,
                    ShipmentsApi: action.payload.ShipmentsApi,
                    UrlShortenerApi: action.payload.UrlShortenerApi
                }
            };
        }
        case SERVICE_LOCATOR_FAILURE: {
            return {
                status: ApiStatus.Failure,
                endpoints: initialEndpointsState
            };
        }
        default:
            return servicesData;
    }
};
