import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus } from '../../helpers/enums';
import { Stop } from '../../interfaces/services/shipmentDetails';
import NoteCardLoading from '../loaders/cards/noteCardLoading';
import NoteCard from '../cards/noteCard';

const classesPrefix = 'shipmentStopNoteList';

const classes = {
    noteListContainer: `${classesPrefix}-noteListContainer`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.noteListContainer}`]: {
            width: '100%',
            maxHeight: 'calc(100vh - 517px)',
            minHeight: '102px',
            overflowY: 'auto',
            margin: '16px 0'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.noteListContainer}`]: {
                maxHeight: 'unset'
            }
        }
    };
});

const ShipmentStopNoteList = ({
    currentStop
}: {
    currentStop: Stop | null;
}): JSX.Element => {
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });

    if (shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading) {
        return (
            <StyledDiv className={classes.noteListContainer}>
                {
                    Array.from(new Array(3)).map((item, index): JSX.Element => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <NoteCardLoading key={index} />
                        );
                    })
                }
            </StyledDiv>
        );
    }

    return (
        <StyledDiv className={classes.noteListContainer} data-qa='shipmentStopNoteList-container'>
            {
                (!currentStop || currentStop.stopNotes.length === 0) &&
                <Typography variant='body2' component='p' data-qa='noShipmentNotes'>
                    No Stop Notes Found
                </Typography>
            }
            {
                currentStop?.stopNotes.map((note) => {
                    return (
                        <NoteCard key={note.noteGuid} note={note} />
                    );
                })
            }
        </StyledDiv>
    );

};

export default ShipmentStopNoteList;
