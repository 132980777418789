import React, { Fragment, useMemo } from 'react';
import {
    Tabs,
    Tab,
    Typography,
    Tooltip,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { CounterTabItem } from '../../interfaces/componentInterfaces';
import { ApiStatus } from '../../helpers/enums';

const classesPrefix = 'counterTabs';

const classes = {
    tabs: `${classesPrefix}-tabs`,
    tabRoot: `${classesPrefix}-tabRoot`,
    tabLabel: `${classesPrefix}-tabLabel`,
    skeletonTabLabel: `${classesPrefix}-skeletonTabLabel`,
    tabCount: `${classesPrefix}-tabCount`
};

const StyledTabs = styled(Tabs)(({ theme }) => {
    return {
        [`&.${classes.tabs}`]: {
            backgroundColor: theme.appColors.appToolbarBackground
        },
        [`& .${classes.tabRoot}`]: {
            maxWidth: '172px',
            minWidth: '172px',
            borderLeft: '1px solid #ccc',
            padding: '6px 12px'
        },
        [`& .${classes.tabLabel}`]: {
            maxWidth: '172px',
            minWidth: '172px',
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 'inherit'
        },
        [`& .${classes.skeletonTabLabel}`]: {
            margin: '0 auto'
        },
        [`& .${classes.tabCount}`]: {
            fontSize: '21px',
            fontWeight: 'inherit'
        }
    };
});

const CounterTabs = ({
    currentTab,
    handleTabChange
}: {
    /** The current tab that is selected. */
    currentTab: string;
    /** Function that will return the new value of the selected tab. */
    handleTabChange: (newTabValue: string) => void;
}): JSX.Element => {
    const filters = useTypedSelector((state) => { return state.shipments.filters; });

    const userSettingsStatus = useTypedSelector((state) => { return state.user.userSettingsStatus; });
    const userSettingsUpdateStatus = useTypedSelector((state) => { return state.user.userSettingsUpdateStatus; });

    const summaryCountsStatus = useTypedSelector((state) => { return state.dimensions.summaryCountsStatus; });
    const summaryCounts = useTypedSelector((state) => { return state.dimensions.summaryCounts; });
    const summaryCountsTotal = useTypedSelector((state) => { return state.dimensions.summaryCountsTotal; });

    const memoizedCountersTabList = useMemo((): CounterTabItem[] => {
        if (summaryCountsStatus !== ApiStatus.Idle && summaryCountsStatus !== ApiStatus.Loading) {
            const list = [
                {
                    key: 'AllShipments',
                    label: filters.length > 0 ? 'Filtered Shipments' : 'All Shipments',
                    count: summaryCountsTotal
                },
                ...summaryCounts
            ];
            return list;
        }
        return [];
    }, [summaryCountsStatus, filters, summaryCountsTotal, summaryCounts]);

    const isFetchingData = userSettingsStatus === ApiStatus.Idle || userSettingsStatus === ApiStatus.Loading ||
        userSettingsUpdateStatus === ApiStatus.Loading ||
        summaryCountsStatus === ApiStatus.Idle || summaryCountsStatus === ApiStatus.Loading;

    const handleCounterTabChange = (event: React.SyntheticEvent, newValue: string): void => {
        handleTabChange(newValue);
    };

    const renderTabs = (): JSX.Element | JSX.Element[] => {
        if (isFetchingData) {
            return Array.from(new Array(10)).map((item, index): JSX.Element => {
                return (
                    <Tab
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        classes={{ root: classes.tabRoot }}
                        label={
                            <Fragment>
                                <Typography className={classes.tabLabel}><Skeleton className={classes.skeletonTabLabel} variant='text' width='130px' /></Typography>
                                <Typography className={classes.tabCount}><Skeleton variant='text' width='20px' /></Typography>
                            </Fragment>
                        }
                        value={item}
                    />
                );
            });
        }

        return memoizedCountersTabList.map((counterItem): JSX.Element => {
            return (
                <Tab
                    key={counterItem.key}
                    classes={{ root: classes.tabRoot }}
                    label={
                        <Fragment>
                            <Tooltip title={counterItem.label}>
                                <Typography className={classes.tabLabel} noWrap>{counterItem.label}</Typography>
                            </Tooltip>
                            <Typography className={classes.tabCount}>{counterItem.count}</Typography>
                        </Fragment>
                    }
                    value={counterItem.key}
                    disabled={counterItem.count === 0}
                    data-qa={`counters-${counterItem.key}`}
                />
            );
        });
    };

    return (
        <StyledTabs
            className={classes.tabs}
            value={isFetchingData ? 0 : currentTab}
            onChange={handleCounterTabChange}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            indicatorColor='primary'
            textColor='primary'
            data-qa='counter-tabList'
        >
            {renderTabs()}
        </StyledTabs>
    );
};

export default CounterTabs;
