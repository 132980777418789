import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Link,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Driver, FreightHauler, OrganizationContact } from '../../interfaces/services/shipmentDetails';
import { zonedDateTimeToDisplay } from '../../helpers/dateUtils';
import { addSpaceBeforeUppercaseCharacter, formatPhoneNumber } from '../../helpers/dataUtils';
import { DeliveryStatus, ModeType, ShipmentStatus } from '../../helpers/enums';
import DeliveryStatusIndicator from '../labels/deliveryStatusIndicator';
import LabelValuePair from '../labels/labelValuePair';
import DriverDetails from '../labels/driverDetails';

const classesPrefix = 'shipmentSummaryCard';

const classes = {
    summaryCardWrapper: `${classesPrefix}-summaryCardWrapper`,
    statusLabel: `${classesPrefix}-statusLabel`,
    sectionHeader: `${classesPrefix}-sectionHeader`
};

const StyledCard = styled(Card)(() => {
    return {
        [`&.${classes.summaryCardWrapper}`]: {
            height: '100%'
        },
        [`& .${classes.statusLabel}`]: {
            margin: '8px 0',
            fontSize: '16px',
            fontWeight: 600
        },
        [`& .${classes.sectionHeader}`]: {
            fontSize: '12px'
        }
    };
});

const ShipmentSummaryCard = ({
    isFetching,
    freightProviderReferenceNumber,
    shipmentStatus,
    deliveryStatus,
    modeType,
    estimatedDeliveryDateTime,
    mobileTrackingNumber,
    freightHauler,
    organizationContact,
    driver
}: {
    isFetching: boolean;
    freightProviderReferenceNumber: string;
    shipmentStatus: ShipmentStatus | null;
    deliveryStatus: DeliveryStatus;
    modeType: ModeType;
    estimatedDeliveryDateTime: string | null;
    mobileTrackingNumber: string | null;
    freightHauler: FreightHauler;
    organizationContact: OrganizationContact | null;
    driver: Driver | null;
}): JSX.Element => {
    return (
        <StyledCard className={classes.summaryCardWrapper}>
            <CardContent>
                <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                    <Grid item xs={4}>
                        {
                            shipmentStatus &&
                            <Typography className={classes.statusLabel}>
                                {addSpaceBeforeUppercaseCharacter(shipmentStatus)}
                            </Typography>
                        }
                    </Grid>
                    <Grid item>
                        <DeliveryStatusIndicator
                            deliveryStatus={deliveryStatus}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            estimatedDeliveryDateTime &&
                            <LabelValuePair label='ETA' value={zonedDateTimeToDisplay(estimatedDeliveryDateTime)} data-qa='eta' />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <LabelValuePair label='Shipment ID' value={freightProviderReferenceNumber} data-qa='freightProviderReferenceNumber' />
                        <LabelValuePair label='Mode' value={modeType} data-qa='modeType' />
                        <LabelValuePair label='Carrier' value={freightHauler.name} data-qa='carrier' />
                    </Grid>
                    <Grid item xs={12}>
                        <DriverDetails
                            isFetching={isFetching}
                            driver={driver}
                            mobileTrackingNumber={mobileTrackingNumber}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            (organizationContact?.firstName || organizationContact?.lastName || organizationContact?.phone || organizationContact?.email) &&
                            <Typography className={classes.sectionHeader} color='textSecondary'>
                                Freight Provider
                            </Typography>
                        }
                        {
                            (organizationContact?.firstName || organizationContact?.lastName) &&
                            <LabelValuePair label='Name' value={`${organizationContact.firstName} ${organizationContact.lastName}`} data-qa='freightProviderName' />
                        }
                        {
                            organizationContact?.phone &&
                            <LabelValuePair
                                label='Phone'
                                value={
                                    <Link href={`tel:${organizationContact.phone}`}>
                                        {formatPhoneNumber(organizationContact.phone)}
                                    </Link>
                                }
                                data-qa='freightProviderPhone'
                            />
                        }
                        {
                            organizationContact?.extension &&
                            <LabelValuePair label='Ext.' value={organizationContact.extension} data-qa='freightProviderPhoneExtension' />
                        }
                        {
                            organizationContact?.email &&
                            <LabelValuePair
                                label='Email'
                                value={
                                    <Link href={`mailto:${organizationContact.email}`}>
                                        {organizationContact.email}
                                    </Link>
                                }
                                data-qa='freightProviderEmail'
                            />
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    );
};

export default ShipmentSummaryCard;
