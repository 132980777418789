import React, { useEffect, useMemo } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import { useTypedSelector } from './redux';
import { setPaletteMode } from './redux/user';
import httpService from './services/httpService';
import { createApplicationTheme } from './assets/theme';
import AppHeader from './components/headers/appHeader';
import AllRoutes from './routes/allRoutes';

import './assets/CSS/reactToastify.css';

// README: Important! React-router uses history v4.10.1 under the hood. Updating the history package will break things.
const history = createBrowserHistory();
httpService.setupInterceptors(history);

const App = (): JSX.Element => {
    const dispatch = useDispatch();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const paletteMode = useTypedSelector((state) => { return state.user.paletteMode; });
    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });

    // When the OS color scheme changes, update the mode in redux
    useEffect((): void => {
        dispatch(setPaletteMode(prefersDarkMode ? 'dark' : 'light'));
    }, [dispatch, prefersDarkMode]);

    // When the redux paletteMode changes, update the theme accordingly
    const theme = useMemo(() => {
        return createApplicationTheme({
            paletteMode,
            primaryNavBackground: organizationPreferences?.freightPortalColorPreference.primaryNavBackground || null,
            primaryMain: organizationPreferences?.freightPortalColorPreference.primaryMain || null,
            secondaryMain: organizationPreferences?.freightPortalColorPreference.secondaryMain || null
        });
    }, [paletteMode, organizationPreferences]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <ToastContainer position='bottom-center' theme='colored' />
            <Router history={history}>
                <AppHeader />
                <AllRoutes />
            </Router>
        </ThemeProvider>
    );
};

export default App;
