import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const classesPrefix = 'fullScreenOverlaySpinner';

const classes = {
    backdrop: `${classesPrefix}-backdrop`
};

const StyledBackdrop = styled(Backdrop)(() => {
    return {
        [`&.${classes.backdrop}`]: {
            zIndex: 1000
        }
    };
});

const FullScreenOverlaySpinner = ({
    open
}: {
    open: boolean;
}): JSX.Element => {
    return (
        <StyledBackdrop className={classes.backdrop} open={open}>
            <CircularProgress />
        </StyledBackdrop>
    );
};

export default FullScreenOverlaySpinner;
