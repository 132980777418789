import { Action } from 'redux';
import { toast } from 'react-toastify';

import { AppThunk, GenericAction } from '..';
import { ApiStatus, NotificationsSortColumns, SortDirection } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { GenericApiResponse } from '../../interfaces/services';
import { NotificationData, NotificationDetails } from '../../interfaces/services/notifications';
import { Filter } from '../../interfaces/services/shipment';
import { notificationDetailsInitialState } from './initialState';

const NOTIFICATIONS_LIST_REQUEST = 'NOTIFICATIONS_LIST_REQUEST';
const NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_LIST_SUCCESS';
const NOTIFICATIONS_LIST_FAILURE = 'NOTIFICATIONS_LIST_FAILURE';

const NOTIFICATION_DETAILS_REQUEST = 'NOTIFICATION_DETAILS_REQUEST';
const NOTIFICATION_DETAILS_SUCCESS = 'NOTIFICATION_DETAILS_SUCCESS';
const NOTIFICATION_DETAILS_FAILURE = 'NOTIFICATION_DETAILS_FAILURE';

const UPDATE_NOTIFICATION_DETAILS_REQUEST = 'UPDATE_NOTIFICATION_DETAILS_REQUEST';
const UPDATE_NOTIFICATION_DETAILS_SUCCESS = 'UPDATE_NOTIFICATION_DETAILS_SUCCESS';
const UPDATE_NOTIFICATION_DETAILS_FAILURE = 'UPDATE_NOTIFICATION_DETAILS_FAILURE';

const CREATE_NOTIFICATION_REQUEST = 'CREATE_NOTIFICATION_REQUEST';
const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';

const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

const RESET_NOTIFICATION_DETAILS = 'RESET_NOTIFICATION_DETAILS';

interface NotificationsListRequestPayload {
    skip: number;
    take: number;
    searchTerm: string;
    sortColumn: NotificationsSortColumns;
    sortDirection: SortDirection;
    filters: Filter[];
}

interface NotificationsData extends NotificationsListRequestPayload {
    notificationsListStatus: ApiStatus;
    notificationsList: NotificationData[];
    notificationDetailsStatus: ApiStatus;
    notificationDetailsUpdateStatus: ApiStatus;
    notificationDetails: NotificationDetails;
    totalPossibleCount: number;
}

const customHeaderObject = {
    'x-showHumanFriendlyValidationMessages': true
};

const requestNotificationsList = (json: NotificationsListRequestPayload): GenericAction<typeof NOTIFICATIONS_LIST_REQUEST, NotificationsListRequestPayload> => {
    return {
        type: NOTIFICATIONS_LIST_REQUEST,
        payload: json
    };
};

const receiveNotificationsList = (json: GenericApiResponse<NotificationData>): GenericAction<typeof NOTIFICATIONS_LIST_SUCCESS, GenericApiResponse<NotificationData>> => {
    return {
        type: NOTIFICATIONS_LIST_SUCCESS,
        payload: json
    };
};

const requestNotificationsListFailed = (): Action<typeof NOTIFICATIONS_LIST_FAILURE> => {
    return {
        type: NOTIFICATIONS_LIST_FAILURE
    };
};

export const fetchNotificationsList = ({
    skip = 0,
    take = 100,
    searchTerm = '',
    sortColumn = NotificationsSortColumns.lastUpdateDate,
    sortDirection = SortDirection.Descending,
    filters = []
}: {
    skip?: number;
    take?: number;
    searchTerm?: string;
    sortColumn?: NotificationsSortColumns;
    sortDirection?: SortDirection;
    filters?: Filter[];
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const requestBody: NotificationsListRequestPayload = {
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection,
            filters
        };
        dispatch(requestNotificationsList(requestBody));

        try {
            const queryString = new URLSearchParams({
                skip: skip.toString(),
                take: take.toString(),
                searchTerm,
                sortColumn,
                sortDirection
            });
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.notifications}?${queryString}` }) as GenericApiResponse<NotificationData>;
            dispatch(receiveNotificationsList(json));

        } catch (error) {
            dispatch(requestNotificationsListFailed());
            toast.error('Error occurred while fetching notifications.');
        }
    };
};

const requestNotificationDetails = (): Action<typeof NOTIFICATION_DETAILS_REQUEST> => {
    return {
        type: NOTIFICATION_DETAILS_REQUEST
    };
};

const receiveNotificationDetails = (json: NotificationDetails): GenericAction<typeof NOTIFICATION_DETAILS_SUCCESS, NotificationDetails> => {
    return {
        type: NOTIFICATION_DETAILS_SUCCESS,
        payload: json
    };
};

const requestNotificationDetailsFailed = (): Action<typeof NOTIFICATION_DETAILS_FAILURE> => {
    return {
        type: NOTIFICATION_DETAILS_FAILURE
    };
};

export const fetchNotificationDetails = (notificationGuid: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestNotificationDetails());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.notificationDetails}/${notificationGuid}` }) as GenericApiResponse<NotificationDetails>;
            dispatch(receiveNotificationDetails(json.data[0]));

        } catch (error) {
            dispatch(requestNotificationDetailsFailed());
            toast.error('Error occurred while fetching notification details.');
        }
    };
};

const requestUpdateNotificationDetails = (): Action<typeof UPDATE_NOTIFICATION_DETAILS_REQUEST> => {
    return {
        type: UPDATE_NOTIFICATION_DETAILS_REQUEST
    };
};

const receiveNotificationDetailsUpdate = (): Action<typeof UPDATE_NOTIFICATION_DETAILS_SUCCESS> => {
    return {
        type: UPDATE_NOTIFICATION_DETAILS_SUCCESS
    };
};

const requestNotificationDetailsUpdateFailed = (): Action<typeof UPDATE_NOTIFICATION_DETAILS_FAILURE> => {
    return {
        type: UPDATE_NOTIFICATION_DETAILS_FAILURE
    };
};

export const updateNotificationDetails = ({
    notificationDetails,
    searchTerm,
    sortColumn,
    sortDirection,
    skip
}: {
    notificationDetails: NotificationDetails;
    searchTerm: string;
    sortColumn: NotificationsSortColumns;
    sortDirection: SortDirection;
    skip: number;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestUpdateNotificationDetails());

        try {
            await ApiService.put({
                url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.updateNotification}`,
                body: notificationDetails,
                customHeaders: customHeaderObject
            }) as GenericApiResponse<NotificationDetails>;

            dispatch(receiveNotificationDetailsUpdate());
            dispatch(fetchNotificationsList({
                searchTerm,
                sortColumn,
                sortDirection,
                skip
            }));
            toast.success('Notification updated successfully.');

        } catch (error) {
            dispatch(requestNotificationDetailsUpdateFailed());
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    toast.error(errorMessage);
                });
            } else {
                toast.error('Error occurred while updating notification details.');
            }
        }
    };
};

export const requestCreateNotification = (): Action<typeof CREATE_NOTIFICATION_REQUEST> => {
    return {
        type: CREATE_NOTIFICATION_REQUEST
    };
};

const requestCreateNotificationSuccess = (): Action<typeof CREATE_NOTIFICATION_SUCCESS> => {
    return {
        type: CREATE_NOTIFICATION_SUCCESS
    };
};

const requestCreateNotificationFailed = (): Action<typeof CREATE_NOTIFICATION_FAILURE> => {
    return {
        type: CREATE_NOTIFICATION_FAILURE
    };
};

export const createNotification = ({
    notificationDetails,
    skip,
    searchTerm,
    sortColumn,
    sortDirection
}: {
    notificationDetails: NotificationDetails;
    skip: number;
    searchTerm: string;
    sortColumn: NotificationsSortColumns;
    sortDirection: SortDirection;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestCreateNotification());

        const body = {
            notificationName: notificationDetails.notificationName,
            notificationRecipientType: notificationDetails.notificationRecipientType,
            notificationTypeLabel: notificationDetails.notificationTypeLabel,
            notifyStopContactsByEmail: notificationDetails.notifyStopContactsByEmail,
            notifyStopContactsBySms: notificationDetails.notifyStopContactsBySms,
            notificationMetaData: notificationDetails.notificationMetaData,
            notificationTypes: notificationDetails.notificationTypes,
            emailList: notificationDetails.emailList,
            smsList: notificationDetails.smsList,
            filters: notificationDetails.filters,
            minutes: notificationDetails.minutes,
            timePriorityType: notificationDetails.timePriorityType
        };

        try {
            await ApiService.post({
                url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.notificationDetails}`,
                body,
                customHeaders: customHeaderObject
            }) as GenericApiResponse<NotificationDetails>;

            dispatch(requestCreateNotificationSuccess());
            dispatch(fetchNotificationsList({
                searchTerm,
                sortColumn,
                sortDirection,
                skip
            }));

            toast.success('Notification created successfully.');
        } catch (error) {
            dispatch(requestCreateNotificationFailed());
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    toast.error(errorMessage);
                });
            } else {
                toast.error('Error occurred while creating notification.');
            }
        }
    };
};

const requestDeleteNotification = (): Action<typeof DELETE_NOTIFICATION_REQUEST> => {
    return {
        type: DELETE_NOTIFICATION_REQUEST
    };
};

const requestDeleteNotificationFailed = (): Action<typeof DELETE_NOTIFICATION_FAILURE> => {
    return {
        type: DELETE_NOTIFICATION_FAILURE
    };
};

export const deleteNotification = ({
    notificationGuid,
    skip,
    searchTerm,
    sortColumn,
    sortDirection
}: {
    notificationGuid: string;
    skip: number;
    searchTerm: string;
    sortColumn: NotificationsSortColumns;
    sortDirection: SortDirection;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestDeleteNotification());

        try {
            await ApiService.delete({
                url: `${getState().availableServices.endpoints.DataViewApi}${Endpoints.dataViewApi.deleteNotification}/${notificationGuid}`,
                customHeaders: customHeaderObject
            });
            dispatch(fetchNotificationsList({
                skip,
                searchTerm,
                sortColumn,
                sortDirection
            }));
            toast.success('Notification successfully deleted.');

        } catch (error) {
            dispatch(requestDeleteNotificationFailed());
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    toast.error(errorMessage);
                });
            } else {
                toast.error('Error occurred while deleting notification.');
            }
        }
    };
};

export const resetNotificationDetails = (): Action<typeof RESET_NOTIFICATION_DETAILS> => {
    return {
        type: RESET_NOTIFICATION_DETAILS
    };
};

type NotificationActionTypes =
    ReturnType<typeof requestNotificationsList> | ReturnType<typeof receiveNotificationsList> | ReturnType<typeof requestNotificationsListFailed> |
    ReturnType<typeof requestNotificationDetails> | ReturnType<typeof receiveNotificationDetails> | ReturnType<typeof requestNotificationDetailsFailed> |
    ReturnType<typeof requestUpdateNotificationDetails> | ReturnType<typeof receiveNotificationDetailsUpdate> | ReturnType<typeof requestNotificationDetailsUpdateFailed> |
    ReturnType<typeof requestCreateNotification> | ReturnType<typeof requestCreateNotificationSuccess> | ReturnType<typeof requestCreateNotificationFailed> |
    ReturnType<typeof requestDeleteNotification> | ReturnType<typeof requestDeleteNotificationFailed> |
    ReturnType<typeof resetNotificationDetails>

export const notificationReducer = (notificationData: NotificationsData = {
    notificationsListStatus: ApiStatus.Idle,
    notificationsList: [],
    notificationDetailsStatus: ApiStatus.Idle,
    notificationDetailsUpdateStatus: ApiStatus.Idle,
    notificationDetails: notificationDetailsInitialState,
    skip: 0,
    take: 100,
    searchTerm: '',
    sortColumn: NotificationsSortColumns.lastUpdateDate,
    sortDirection: SortDirection.Descending,
    totalPossibleCount: 0,
    filters: []
}, action: NotificationActionTypes): NotificationsData => {
    switch (action.type) {
        case NOTIFICATIONS_LIST_REQUEST: {
            return {
                ...notificationData,
                notificationsListStatus: ApiStatus.Loading,
                skip: action.payload.skip,
                searchTerm: action.payload.searchTerm,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection,
                totalPossibleCount: 0,
                filters: action.payload.filters
            };
        }
        case NOTIFICATIONS_LIST_SUCCESS: {
            return {
                ...notificationData,
                notificationsListStatus: ApiStatus.Success,
                notificationsList: action.payload.data,
                totalPossibleCount: action.payload.totalPossibleCount
            };
        }
        case NOTIFICATIONS_LIST_FAILURE: {
            return {
                ...notificationData,
                notificationsListStatus: ApiStatus.Failure,
                notificationsList: [],
                totalPossibleCount: 0
            };
        }
        case NOTIFICATION_DETAILS_REQUEST: {
            return {
                ...notificationData,
                notificationDetailsStatus: ApiStatus.Loading
            };
        }
        case NOTIFICATION_DETAILS_SUCCESS: {
            return {
                ...notificationData,
                notificationDetailsStatus: ApiStatus.Success,
                notificationDetails: action.payload
            };
        }
        case NOTIFICATION_DETAILS_FAILURE: {
            return {
                ...notificationData,
                notificationDetailsStatus: ApiStatus.Failure,
                notificationDetails: notificationDetailsInitialState
            };
        }
        case UPDATE_NOTIFICATION_DETAILS_REQUEST: {
            return {
                ...notificationData,
                notificationDetailsUpdateStatus: ApiStatus.Loading
            };
        }
        case UPDATE_NOTIFICATION_DETAILS_SUCCESS: {
            return {
                ...notificationData,
                notificationDetailsUpdateStatus: ApiStatus.Success,
                notificationDetails: notificationDetailsInitialState
            };
        }
        case UPDATE_NOTIFICATION_DETAILS_FAILURE: {
            return {
                ...notificationData,
                notificationDetailsUpdateStatus: ApiStatus.Failure
            };
        }
        case CREATE_NOTIFICATION_REQUEST: {
            return {
                ...notificationData,
                notificationDetailsUpdateStatus: ApiStatus.Loading
            };
        }
        case CREATE_NOTIFICATION_SUCCESS: {
            return {
                ...notificationData,
                notificationDetailsUpdateStatus: ApiStatus.Success
            };
        }
        case CREATE_NOTIFICATION_FAILURE: {
            return {
                ...notificationData,
                notificationDetailsUpdateStatus: ApiStatus.Failure
            };
        }
        case DELETE_NOTIFICATION_REQUEST: {
            return {
                ...notificationData,
                notificationDetails: notificationDetailsInitialState,
                notificationsListStatus: ApiStatus.Idle
            };
        }
        case DELETE_NOTIFICATION_FAILURE: {
            // If delete fails a snackbar is shown but the loader state needs to end to redisplay the shipment list
            return {
                ...notificationData,
                notificationsListStatus: ApiStatus.Success
            };
        }
        case RESET_NOTIFICATION_DETAILS: {
            return {
                ...notificationData,
                notificationDetailsStatus: ApiStatus.Idle,
                notificationDetails: notificationDetailsInitialState
            };
        }
        default:
            return notificationData;
    }
};
