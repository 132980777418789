export const initialUserInformationState = {
    userName: '',
    emailAddress: '',
    isAdmin: false,
    isOwner: false,
    hasManualShipmentCreationAccess: false,
    operatorReadOnly: false,
    customerReadOnly: false,
    userApplications: {
        transportationUi: [],
        freightPortal: [],
        account: []
    }
};

export const initialUserSettingsState = {
    userSettingsColumns: [],
    userSettingsCounters: [],
    mapLegendDisplay: true,
    defaultView: null
};

/**
 * This data should only be used when in a shared view to default the columns shown in the tables
 */
export const defaultColumns = [
    {
        key: 'FreightProviderReferenceNumber',
        displayName: 'Freight Provider Reference Number',
        fieldName: 'freightProviderReferenceNumber',
        fieldValue: null,
        order: 1,
        visible: true
    },
    {
        key: 'ShipmentStatus',
        displayName: 'Shipment Status',
        fieldName: 'shipmentStatus',
        fieldValue: null,
        order: 2,
        visible: true
    },
    {
        key: 'DeliveryStatus',
        displayName: 'Delivery Status',
        fieldName: 'deliveryStatus',
        fieldValue: null,
        order: 3,
        visible: true
    },
    {
        key: 'CarrierName',
        displayName: 'Carrier Name',
        fieldName: 'carrierName',
        fieldValue: null,
        order: 4,
        visible: true
    },
    {
        key: 'FreightHaulerIdentifierTypeName',
        displayName: 'Carrier Identifier Type',
        fieldName: 'freightHaulerIdentifierTypeName',
        fieldValue: null,
        order: 5,
        visible: false
    },
    {
        key: 'FreightHaulerIdentifierName',
        displayName: 'Carrier Identifier',
        fieldName: 'freightHaulerIdentifierName',
        fieldValue: null,
        order: 6,
        visible: true
    },
    {
        key: 'ModeType',
        displayName: 'Mode Type',
        fieldName: 'modeType',
        fieldValue: null,
        order: 7,
        visible: true
    },
    {
        key: 'OriginETA',
        displayName: 'Origin ETA',
        fieldName: 'originStopETA',
        fieldValue: null,
        order: 8,
        visible: true
    },
    {
        key: 'ActiveStopETA',
        displayName: 'Active Stop ETA',
        fieldName: 'activeStopETA',
        fieldValue: null,
        order: 9,
        visible: true
    },
    {
        key: 'DestinationETA',
        displayName: 'Destination ETA',
        fieldName: 'destinationStopETA',
        fieldValue: null,
        order: 10,
        visible: true
    },
    {
        key: 'OriginName',
        displayName: 'Origin Name',
        fieldName: 'originName',
        fieldValue: null,
        order: 11,
        visible: true
    },
    {
        key: 'OriginStreet1',
        displayName: 'Origin Street Address 1',
        fieldName: 'originStreet1',
        fieldValue: null,
        order: 12,
        visible: true
    },
    {
        key: 'OriginStreet2',
        displayName: 'Origin Street Address 2',
        fieldName: 'originStreet2',
        fieldValue: null,
        order: 13,
        visible: true
    },
    {
        key: 'OriginCity',
        displayName: 'Origin City',
        fieldName: 'originCity',
        fieldValue: null,
        order: 14,
        visible: true
    },
    {
        key: 'OriginState',
        displayName: 'Origin State',
        fieldName: 'originState',
        fieldValue: null,
        order: 15,
        visible: true
    },
    {
        key: 'OriginZip',
        displayName: 'Origin Zip',
        fieldName: 'originZip',
        fieldValue: null,
        order: 16,
        visible: true
    },
    {
        key: 'ActiveStopName',
        displayName: 'Active Stop Name',
        fieldName: 'activeStopName',
        fieldValue: null,
        order: 17,
        visible: true
    },
    {
        key: 'ActiveStopStreet1',
        displayName: 'Active Stop Street Address 1',
        fieldName: 'activeStopAddress1',
        fieldValue: null,
        order: 18,
        visible: true
    },
    {
        key: 'ActiveStopStreet2',
        displayName: 'Active Stop Street Address 2',
        fieldName: 'activeStopAddress2',
        fieldValue: null,
        order: 19,
        visible: true
    },
    {
        key: 'ActiveStopCity',
        displayName: 'Active Stop City',
        fieldName: 'activeStopCity',
        fieldValue: null,
        order: 20,
        visible: true
    },
    {
        key: 'ActiveStopState',
        displayName: 'Active Stop State',
        fieldName: 'activeStopState',
        fieldValue: null,
        order: 21,
        visible: true
    },
    {
        key: 'ActiveStopZip',
        displayName: 'Active Stop Zip',
        fieldName: 'activeStopZip',
        fieldValue: null,
        order: 22,
        visible: true
    },
    {
        key: 'DestinationName',
        displayName: 'Destination Name',
        fieldName: 'destinationName',
        fieldValue: null,
        order: 23,
        visible: true
    },
    {
        key: 'DestinationStreet1',
        displayName: 'Destination Street Address 1',
        fieldName: 'destinationAddress1',
        fieldValue: null,
        order: 24,
        visible: true
    },
    {
        key: 'DestinationStreet2',
        displayName: 'Destination Street Address 2',
        fieldName: 'destinationAddress2',
        fieldValue: null,
        order: 25,
        visible: true
    },
    {
        key: 'DestinationCity',
        displayName: 'Destination City',
        fieldName: 'destinationCity',
        fieldValue: null,
        order: 26,
        visible: true
    },
    {
        key: 'DestinationState',
        displayName: 'Destination State',
        fieldName: 'destinationState',
        fieldValue: null,
        order: 27,
        visible: true
    },
    {
        key: 'DestinationZip',
        displayName: 'Destination Zip',
        fieldName: 'destinationZip',
        fieldValue: null,
        order: 28,
        visible: true
    },
    {
        key: 'CurrentPositionCity',
        displayName: 'Current Position City',
        fieldName: 'currentPositionCity',
        fieldValue: null,
        order: 29,
        visible: true
    },
    {
        key: 'CurrentPositionState',
        displayName: 'Current Position State',
        fieldName: 'currentPositionState',
        fieldValue: null,
        order: 30,
        visible: true
    },
    {
        key: 'NumberOfStops',
        displayName: 'Number of Stops',
        fieldName: 'numberOfStops',
        fieldValue: null,
        order: 31,
        visible: true
    },
    {
        key: 'OriginScheduleStartDateTime',
        displayName: 'Origin Scheduled Time Start',
        fieldName: 'originScheduleStartDateTime',
        fieldValue: null,
        order: 32,
        visible: true
    },
    {
        key: 'OriginScheduleEndDateTime',
        displayName: 'Origin Scheduled Time End',
        fieldName: 'originScheduleEndDateTime',
        fieldValue: null,
        order: 33,
        visible: true
    },
    {
        key: 'OriginAppointmentStartDateTime',
        displayName: 'Origin Appointment Time Start',
        fieldName: 'originAppointmentStartDateTime',
        fieldValue: null,
        order: 34,
        visible: true
    },
    {
        key: 'OriginAppointmentEndDateTime',
        displayName: 'Origin Appointment Time End',
        fieldName: 'originAppointmentEndDateTime',
        fieldValue: null,
        order: 35,
        visible: true
    },
    {
        key: 'OriginGeofenceArrivalDateTime',
        displayName: 'Origin Geofence Arrival Time',
        fieldName: 'originGeofenceArrivalDateTime',
        fieldValue: null,
        order: 36,
        visible: true
    },
    {
        key: 'OriginGeofenceDepartureDateTime',
        displayName: 'Origin Geofence Departure Time',
        fieldName: 'originGeofenceDepartureDateTime',
        fieldValue: null,
        order: 37,
        visible: true
    },
    {
        key: 'OriginCarrierArrivalDateTime',
        displayName: 'Origin Carrier Arrival Time',
        fieldName: 'originCarrierArrivalDateTime',
        fieldValue: null,
        order: 38,
        visible: true
    },
    {
        key: 'OriginCarrierDepartureDateTime',
        displayName: 'Origin Carrier Departure Time',
        fieldName: 'originCarrierDepartureDateTime',
        fieldValue: null,
        order: 39,
        visible: true
    },
    {
        key: 'DestinationScheduleStartDateTime',
        displayName: 'Destination Scheduled Time Start',
        fieldName: 'destinationScheduleStartDateTime',
        fieldValue: null,
        order: 40,
        visible: true
    },
    {
        key: 'DestinationScheduleEndDateTime',
        displayName: 'Destination Scheduled Time End',
        fieldName: 'destinationScheduleEndDateTime',
        fieldValue: null,
        order: 41,
        visible: true
    },
    {
        key: 'DestinationAppointmentStartDateTime',
        displayName: 'Destination Appointment Time Start',
        fieldName: 'destinationAppointmentStartDateTime',
        fieldValue: null,
        order: 42,
        visible: true
    },
    {
        key: 'DestinationAppointmentEndDateTime',
        displayName: 'Destination Appointment Time End',
        fieldName: 'destinationAppointmentEndDateTime',
        fieldValue: null,
        order: 43,
        visible: true
    },
    {
        key: 'DestinationGeofenceArrivalDateTime',
        displayName: 'Destination Geofence Arrival Time',
        fieldName: 'destinationGeofenceArrivalDateTime',
        fieldValue: null,
        order: 44,
        visible: true
    },
    {
        key: 'DestinationGeofenceDepartureDateTime',
        displayName: 'Destination Geofence Departure Time',
        fieldName: 'destinationGeofenceDepartureDateTime',
        fieldValue: null,
        order: 45,
        visible: true
    },
    {
        key: 'DestinationCarrierArrivalDateTime',
        displayName: 'Destination Carrier Arrival Time',
        fieldName: 'destinationCarrierArrivalDateTime',
        fieldValue: null,
        order: 46,
        visible: true
    },
    {
        key: 'DestinationCarrierDepartureDateTime',
        displayName: 'Destination Carrier Departure Time',
        fieldName: 'destinationCarrierDepartureDateTime',
        fieldValue: null,
        order: 47,
        visible: true
    },
    {
        key: 'HaulLength',
        displayName: 'Length of Haul',
        fieldName: 'haulLength',
        fieldValue: null,
        order: 48,
        visible: true
    },
    {
        key: 'HaulLinearUnitType',
        displayName: 'Haul Linear Unit',
        fieldName: 'haulLinearUnitType',
        fieldValue: null,
        order: 49,
        visible: false
    },
    {
        key: 'WeatherSeverity',
        displayName: 'Weather Severity',
        fieldName: 'weatherSeverity',
        fieldValue: null,
        order: 50,
        visible: false
    },
    {
        key: 'TractorReferenceNumber',
        displayName: 'Tractor #',
        fieldName: 'tractorReferenceNumber',
        fieldValue: null,
        order: 51,
        visible: true
    },
    {
        key: 'TrailerReferenceNumber',
        displayName: 'Trailer #',
        fieldName: 'trailerReferenceNumber',
        fieldValue: null,
        order: 52,
        visible: true
    },
    {
        key: 'TotalShipmentExceptions',
        displayName: 'Total Shipment Exceptions',
        fieldName: 'totalShipmentExceptions',
        fieldValue: null,
        order: 53,
        visible: false
    },
    {
        key: 'MobileTrackingPhoneNumber',
        displayName: 'Mobile Tracking Number',
        fieldName: 'mobileTrackingPhoneNumber',
        fieldValue: null,
        order: 54,
        visible: true
    },
    {
        key: 'MobileTrackingStatus',
        displayName: 'Mobile Tracking Status',
        fieldName: 'mobileTrackingStatus',
        fieldValue: null,
        order: 55,
        visible: false
    },
    {
        key: 'ShipmentTrackingType',
        displayName: 'Shipment Tracking Type',
        fieldName: 'shipmentTrackingType',
        fieldValue: null,
        order: 56,
        visible: false
    },
    {
        key: 'ShipmentUniqueName',
        displayName: 'System ID',
        fieldName: 'shipmentUniqueName',
        fieldValue: null,
        order: 57,
        visible: false
    },
    {
        key: 'HasTemperatureExceededBoundsFlag',
        displayName: 'Temperature Breech',
        fieldName: 'hasTemperatureExceededBoundsFlag',
        fieldValue: null,
        order: 58,
        visible: false
    },
    {
        key: 'HasShipmentNotes',
        displayName: 'Has Shipment Notes',
        fieldName: 'hasShipmentNotes',
        fieldValue: null,
        order: 59,
        visible: false
    },
    {
        key: 'HasShipmentDocuments',
        displayName: 'Has Shipment Documents',
        fieldName: 'hasShipmentDocuments',
        fieldValue: null,
        order: 60,
        visible: false
    },
    {
        key: 'TotalActualDwellTimeMinutes',
        displayName: 'Actual Dwell',
        fieldName: 'totalActualDwellTimeMinutes',
        fieldValue: null,
        order: 61,
        visible: true
    },
    {
        key: 'ActiveStopDwellTimeMinutes',
        displayName: 'Active Stop Dwell',
        fieldName: 'activeStopDwellTimeMinutes',
        fieldValue: null,
        order: 62,
        visible: false
    },
    {
        key: 'TotalActiveDwellTimeMinutes',
        displayName: 'Active Dwell',
        fieldName: 'totalActiveDwellTimeMinutes',
        fieldValue: null,
        order: 63,
        visible: false
    },
    {
        key: 'TripID',
        displayName: 'Trip ID',
        fieldName: 'tripId',
        fieldValue: null,
        order: 64,
        visible: false
    },
    {
        key: 'IsPriorityShipment',
        displayName: 'Priority Shipment',
        fieldName: 'isPriorityShipment',
        fieldValue: null,
        order: 65,
        visible: false
    },
    {
        key: 'ExpirationDate',
        displayName: 'Expiration Date',
        fieldName: 'expirationDate',
        fieldValue: null,
        order: 66,
        visible: false
    },
    {
        key: 'ShipperName',
        displayName: 'Shipper Name',
        fieldName: 'shipperName',
        fieldValue: null,
        order: 67,
        visible: true
    },
    {
        key: 'OrderStatus',
        displayName: 'Order Status',
        fieldName: 'orderStatusDisplay',
        fieldValue: null,
        order: 68,
        visible: true
    },
    {
        key: 'OriginLatitude',
        displayName: 'Origin Latitude',
        fieldName: 'originLatitude',
        fieldValue: null,
        order: 69,
        visible: false
    },
    {
        key: 'OriginLongitude',
        displayName: 'Origin Longitude',
        fieldName: 'originLongitude',
        fieldValue: null,
        order: 70,
        visible: false
    },
    {
        key: 'DestinationStopName',
        displayName: 'Destination Stop',
        fieldName: 'destinationStopName',
        fieldValue: null,
        order: 71,
        visible: false
    },
    {
        key: 'DestinationLocationName',
        displayName: 'Destination Location',
        fieldName: 'destinationLocationName',
        fieldValue: null,
        order: 72,
        visible: false
    },
    {
        key: 'CurrentPositionDateTime',
        displayName: 'Current Position Date',
        fieldName: 'currentPositionDateTime',
        fieldValue: null,
        order: 73,
        visible: true
    },
    {
        key: 'CurrentPositionLatitude',
        displayName: 'Current Position Latitude',
        fieldName: 'currentPositionLatitude',
        fieldValue: null,
        order: 74,
        visible: true
    },
    {
        key: 'CurrentPositionLongitude',
        displayName: 'Current Position Longitude',
        fieldName: 'currentPositionLongitude',
        fieldValue: null,
        order: 75,
        visible: true
    },
    {
        key: 'OriginStopName',
        displayName: 'Origin Stop',
        fieldName: 'originStopName',
        fieldValue: null,
        order: 76,
        visible: false
    },
    {
        key: 'OriginLocationName',
        displayName: 'Origin Location',
        fieldName: 'originLocationName',
        fieldValue: null,
        order: 77,
        visible: false
    },
    {
        key: 'ActiveStopSequence',
        displayName: 'Active Stop Sequence',
        fieldName: 'activeStopSequence',
        fieldValue: null,
        order: 78,
        visible: false
    },
    {
        key: 'ActiveStopLocationName',
        displayName: 'Active Stop Location',
        fieldName: 'activeStopLocationName',
        fieldValue: null,
        order: 79,
        visible: false
    }
];
