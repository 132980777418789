import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    Typography,
    CircularProgress,
    Tabs,
    Tab,
    FormHelperText,
    Skeleton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { mdiAlarm } from '@mdi/js';
import { isAfter, isEqual, isValid } from 'date-fns';

import { useTypedSelector } from '../../redux';
import { updatePageReset, updateStopTimes } from '../../redux/dialogUpdates';
import { fetchShipmentDetails } from '../../redux/shipmentDetails';
import { ApiStatus } from '../../helpers/enums';
import { Stop } from '../../interfaces/services/shipmentDetails';
import { UpdateStopTimesRequest } from '../../interfaces/services/shipmentUpdates';
import { formatDateForServer, formatDateForPicker } from '../../helpers/dateUtils';
import CommonDialog from './common/commonDialog';
import ShipmentStopSelect from '../selects/shipmentStopSelect';
import StopContact from '../labels/stopContact';
import StopContactLoading from '../loaders/labels/stopContactLoading';
import CustomDateTimePicker from '../pickers/customDateTimePicker';

const classesPrefix = 'editTimesDialog';

const classes = {
    datesWrapper: `${classesPrefix}-datesWrapper`
};

const StyledGrid = styled(Grid)(({ theme }) => {
    return {
        [`& .${classes.datesWrapper}`]: {
            paddingTop: '16px'
        },
        [theme.breakpoints.only('xs')]: {
            [`& .${classes.datesWrapper}`]: {
                paddingTop: '8px'
            }
        }
    };
});

enum DateTab {
    Appointment,
    Eta,
    Arrival,
    Departure
}

const EditTimesDialog = ({
    shipmentUniqueName,
    isOpen,
    closeDialog,
    initialStopSequence = 0
}: {
    shipmentUniqueName: string;
    isOpen: boolean;
    closeDialog: () => void;
    initialStopSequence?: number;
}): JSX.Element => {
    const dispatch = useDispatch();

    const organizationPreferences = useTypedSelector((state) => { return state.organization.organizationPreferences; });
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });
    const status = useTypedSelector((state) => { return state.dialogUpdates.status; });
    const errorMessage = useTypedSelector((state) => { return state.dialogUpdates.errorMessage; });

    const [currentStop, setCurrentStop] = useState<Stop | null>(null);
    const [currentTab, setCurrentTab] = useState(0);

    const [appointmentStartDate, setAppointmentStartDate] = useState<Date | null>(null);
    const [appointmentEndDate, setAppointmentEndDate] = useState<Date | null>(null);
    const [etaDate, setEtaDate] = useState<Date | null>(null);
    const [arrivalDate, setArrivalDate] = useState<Date | null>(null);
    const [departureDate, setDepartureDate] = useState<Date | null>(null);

    const [appointmentDateError, setAppointmentDateError] = useState('');
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [canSubmitForm, setCanSubmitForm] = useState(false);
    const [shouldDialogClose, setShouldDialogClose] = useState(true);

    const isFetchingData = shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading;

    // Arrival and Depature tabs use the public api and therefore require a freightHauler identifier
    const isFreightHaulerInfoValid = useCallback((): boolean => {
        if (currentTab === DateTab.Arrival || currentTab === DateTab.Departure) {
            return shipment.freightHauler.identifiers.length > 0 && shipment.freightHauler.identifiers[0].identifier !== null;
        }
        return true;
    }, [currentTab, shipment.freightHauler.identifiers]);

    useEffect((): void => {
        // only fetch if we don't already have the details in redux
        if (shipmentUniqueName !== shipment.shipmentUniqueName) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipment.shipmentUniqueName, dispatch]);

    useEffect((): void => {
        // only fetch if we refreshed and cleared our shipment status back to idle
        if (shipmentStatus === ApiStatus.Idle) {
            dispatch(fetchShipmentDetails(shipmentUniqueName));
        }
    }, [shipmentUniqueName, shipmentStatus, dispatch]);

    // If the API update is successful, trigger the dialog to reset and close
    useEffect((): void => {
        if (status === ApiStatus.Success) {
            dispatch(updatePageReset());

            if (shouldDialogClose) {
                closeDialog();
            } else {
                // update state back to defaults here, if we are just saving and not closing the dialog
                setIsFormDirty(false);
                setCanSubmitForm(false);
            }
        }
    }, [status, shouldDialogClose, dispatch, closeDialog]);

    // Once the shipment details api succeeds, update the currentStop to be the initialStopSequence.
    useEffect((): void => {
        if (shipmentStatus === ApiStatus.Success) {
            setCurrentStop(shipment.stops[initialStopSequence]);
        }
    }, [shipmentStatus, shipment.stops, initialStopSequence]);

    /** When the selected stop changes, update all of the dates stored in local state in the correct format */
    useEffect((): void => {
        setAppointmentStartDate(currentStop?.appointment?.startTime ? formatDateForPicker(currentStop.appointment.startTime) : null);
        setAppointmentEndDate(currentStop?.appointment?.endTime ? formatDateForPicker(currentStop.appointment.endTime) : null);
        setEtaDate(currentStop?.estimatedDeliveryDateTime ? formatDateForPicker(currentStop.estimatedDeliveryDateTime) : null);
        setArrivalDate(currentStop?.actualArrivalDateTime ? formatDateForPicker(currentStop.actualArrivalDateTime) : null);
        setDepartureDate(currentStop?.actualDepartureDateTime ? formatDateForPicker(currentStop.actualDepartureDateTime) : null);
    }, [currentStop]);

    /** When the current tab or any of the dates change, run validation on all of the tabs to see if the form can be sumbitted. */
    useEffect((): void => {
        // do special validation on the appointment dates since it's a range
        const isAppointmentDateRangeValid = appointmentStartDate !== null && appointmentEndDate !== null && (isEqual(appointmentStartDate, appointmentEndDate) || isAfter(appointmentEndDate, appointmentStartDate));
        const isAppointmentTabValid = currentTab === DateTab.Appointment ?
            isValid(appointmentStartDate) && isValid(appointmentEndDate) && isAppointmentDateRangeValid :
            true;

        const isEtaTabValid = currentTab === DateTab.Eta ? isValid(etaDate) : true;
        const isArrivalTabValid = currentTab === DateTab.Arrival ? isValid(arrivalDate) : true;
        const isDepartureTabValid = currentTab === DateTab.Departure ? isValid(departureDate) : true;

        const isFormValid = isFormDirty && currentStop !== null && isAppointmentTabValid && isEtaTabValid && isArrivalTabValid && isDepartureTabValid && isFreightHaulerInfoValid();
        setCanSubmitForm(isFormValid);

        // if the form has changed check the extra date range validation on the appointment tab
        if (isFormDirty) {
            setAppointmentDateError(isAppointmentDateRangeValid ? '' : 'End date must be after start date');
        }
    }, [
        currentStop,
        currentTab,
        isFormDirty,
        appointmentStartDate,
        appointmentEndDate,
        etaDate,
        arrivalDate,
        departureDate,
        isFreightHaulerInfoValid
    ]);

    // Don't allow the dialog to close if the API's are still running
    const handleClose = (): void => {
        if (status !== ApiStatus.Loading && shipmentStatus !== ApiStatus.Idle && shipmentStatus !== ApiStatus.Loading) {
            dispatch(updatePageReset());
            closeDialog();
        }
    };

    const handleSave = (): void => {
        if (currentStop) {
            const formattedAppointmentStartDate = formatDateForServer(appointmentStartDate);
            const formattedAppointmentEndDate = formatDateForServer(appointmentEndDate);
            const formattedEtaDate = formatDateForServer(etaDate);
            const formattedArrivalDate = formatDateForServer(arrivalDate);
            const formattedDepartureDate = formatDateForServer(departureDate);

            // NOTE: this service CAN'T handle all of these dates to be sent at the same time,
            // so we disable the tabs and stop dropdown to make the user save their changes on their selected tab first.
            const data: UpdateStopTimesRequest = {
                tenFourLicensePlate: shipmentUniqueName,
                stopSequence: currentStop.stopSequence,
                ...(currentTab === DateTab.Appointment && (formattedAppointmentStartDate || formattedAppointmentEndDate)) && {
                    appointment: {
                        appointmentType: 'Required',
                        appointmentStartDate: formattedAppointmentStartDate,
                        appointmentEndDate: formattedAppointmentEndDate
                    }
                },
                ...(currentTab === DateTab.Eta && formattedEtaDate) && {
                    retainEstimatedDeliveryDate: true,
                    estimatedDeliveryDate: formattedEtaDate
                },
                ...(currentTab === DateTab.Arrival && formattedArrivalDate && shipment.freightHauler.identifiers[0]?.identifier) && {
                    // TODO: once the movement api is fixed we can remove positionEventTypeId and use positionEventType
                    // positionEventType: 'Arrived',
                    freightHaulerIdentifierType: shipment.freightHauler.identifiers[0].freightHaulerIdentifierType,
                    freightHaulerIdentifier: shipment.freightHauler.identifiers[0].identifier,
                    positionEventTypeId: 1,
                    reportTime: formattedArrivalDate
                },
                ...(currentTab === DateTab.Departure && formattedDepartureDate && shipment.freightHauler.identifiers[0]?.identifier) && {
                    // TODO: once the movement api is fixed we can remove positionEventTypeId and use positionEventType
                    // positionEventType: 'Departed',
                    freightHaulerIdentifierType: shipment.freightHauler.identifiers[0].freightHaulerIdentifierType,
                    freightHaulerIdentifier: shipment.freightHauler.identifiers[0].identifier,
                    positionEventTypeId: 2,
                    reportTime: formattedDepartureDate
                }
            };
            dispatch(updateStopTimes(data));
        }
    };

    const renderDialogContent = (): JSX.Element => {
        if (shipmentStatus === ApiStatus.Failure) {
            return (
                <Typography variant='caption' color='error' data-qa='shipmentDetailsError'>Error occurred while fetching shipment details.</Typography>
            );
        }

        return (
            <StyledGrid container spacing={2}>
                <Grid item xs={12}>
                    {
                        isFetchingData ? (
                            <Skeleton variant='rectangular' width='100%' height='48px' />
                        ) : (
                            <ShipmentStopSelect
                                isDisabled={isFormDirty}
                                stops={shipment.stops}
                                currentStop={currentStop}
                                handleChange={(value: Stop | null): void => {
                                    setCurrentStop(value);
                                }}
                            />
                        )
                    }
                    {
                        !currentStop &&
                        <Typography variant='caption' color='error' data-qa='currentStopError'>A stop must be selected before continuing.</Typography>
                    }
                    {
                        isFormDirty &&
                        <FormHelperText>Changes must be saved before selecting another Stop</FormHelperText>
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        isFetchingData ? (
                            <StopContactLoading />
                        ) : (
                            <StopContact
                                street1={currentStop?.address.streetAddress1 || null}
                                street2={currentStop?.address.streetAddress2 || null}
                                city={currentStop?.address.city || null}
                                region={currentStop?.address.state || null}
                                postal={currentStop?.address.postalCode || null}
                                country={currentStop?.address.countryCode || null}
                                contactName={currentStop?.contact.name || null}
                                phoneNumber={currentStop?.contact.phoneNumber || null}
                                phoneExtension={currentStop?.contact.phoneExtension || null}
                            />
                        )
                    }
                </Grid>
                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        onChange={(event, value: number): void => {
                            setCurrentTab(value);
                        }}
                        variant='scrollable'
                        scrollButtons
                        allowScrollButtonsMobile
                        indicatorColor='primary'
                        textColor='primary'
                        data-qa='dateType-tabList'
                    >
                        <Tab disabled={isFormDirty || !currentStop} value={DateTab.Appointment} label='Appointment' data-qa='appointment-tab' />
                        <Tab disabled={isFormDirty || !currentStop || organizationPreferences?.showEta === false} value={DateTab.Eta} label='ETA' data-qa='eta-tab' />
                        <Tab disabled={isFormDirty || !currentStop} value={DateTab.Arrival} label='Arrival' data-qa='arrival-tab' />
                        <Tab disabled={isFormDirty || !currentStop} value={DateTab.Departure} label='Departure' data-qa='departure-tab' />
                    </Tabs>
                    <Grid className={classes.datesWrapper} container spacing={2} data-qa='dates-container'>
                        <Grid item xs={12}>
                            <FormHelperText>PLEASE NOTE: All times are local to the Stop</FormHelperText>
                        </Grid>
                        {
                            currentTab === DateTab.Appointment &&
                            <Fragment>
                                <Grid item xs={12} sm={6}>
                                    <CustomDateTimePicker
                                        isFetchingData={isFetchingData}
                                        label='Appointment Start'
                                        value={appointmentStartDate}
                                        disabled={!currentStop}
                                        handleDateChange={(date: Date | null): void => {
                                            setIsFormDirty(true);
                                            setAppointmentStartDate(date);

                                            // When we ONLY send a start appointment date to the server, it will auto-update the end date to match.
                                            // So instead of relying on that, we will update the UI here so the user can see this being done...
                                            // If an end date doesn't exist yet, we will update it to match the start date.
                                            setAppointmentEndDate(appointmentEndDate || date);
                                        }}
                                        dataQa='appointmentStart-dateTimePicker'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomDateTimePicker
                                        isFetchingData={isFetchingData}
                                        label='Appointment End'
                                        value={appointmentEndDate}
                                        disabled={!currentStop}
                                        handleDateChange={(date: Date | null): void => {
                                            setIsFormDirty(true);
                                            setAppointmentEndDate(date);
                                        }}
                                        dataQa='appointmentEnd-dateTimePicker'
                                    />
                                </Grid>

                                {
                                    appointmentDateError &&
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='caption' color='error' data-qa='appointmentErrorMessage'>{appointmentDateError}</Typography>
                                    </Grid>
                                }
                            </Fragment>
                        }

                        {
                            currentTab === DateTab.Eta &&
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimePicker
                                    isFetchingData={isFetchingData}
                                    label='Current ETA'
                                    value={etaDate}
                                    disabled={!currentStop}
                                    handleDateChange={(date: Date | null): void => {
                                        setIsFormDirty(true);
                                        setEtaDate(date);
                                    }}
                                    dataQa='Eta-dateTimePicker'
                                />
                            </Grid>
                        }

                        {
                            currentTab === DateTab.Arrival &&
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimePicker
                                    isFetchingData={isFetchingData}
                                    label='Arrival'
                                    value={arrivalDate}
                                    disabled={currentStop?.actualArrivalDateTime !== null || !currentStop}
                                    handleDateChange={(date: Date | null): void => {
                                        setIsFormDirty(true);
                                        setArrivalDate(date);
                                    }}
                                    dataQa='arrival-dateTimePicker'
                                />
                            </Grid>
                        }

                        {
                            currentTab === DateTab.Departure &&
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimePicker
                                    isFetchingData={isFetchingData}
                                    label='Departure'
                                    value={departureDate}
                                    disabled={currentStop?.actualDepartureDateTime !== null || !currentStop}
                                    handleDateChange={(date: Date | null): void => {
                                        setIsFormDirty(true);
                                        setDepartureDate(date);
                                    }}
                                    dataQa='departure-dateTimePicker'
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {
                    !isFreightHaulerInfoValid() &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error'>Carrier Identifier could not be determined</Typography>
                    </Grid>
                }

                {
                    errorMessage &&
                    <Grid item xs={12}>
                        <Typography variant='caption' color='error' data-qa='errorMessage'>{errorMessage}</Typography>
                    </Grid>
                }
            </StyledGrid>
        );
    };

    return (
        <CommonDialog
            open={isOpen}
            onClose={handleClose}
            headerIcon={mdiAlarm}
            headerText='Edit Times'
            content={renderDialogContent()}
            actions={(
                <Fragment>
                    <Button
                        disabled={status === ApiStatus.Loading || shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading}
                        onClick={handleClose}
                        data-qa='cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading || !canSubmitForm}
                        onClick={(): void => {
                            setShouldDialogClose(false);
                            handleSave();
                        }}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='save-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save'
                        }
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={status === ApiStatus.Loading || shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading || !canSubmitForm}
                        onClick={(): void => {
                            setShouldDialogClose(true);
                            handleSave();
                        }}
                        startIcon={status === ApiStatus.Loading ? <CircularProgress size={14} /> : undefined}
                        data-qa='saveAndClose-button'
                    >
                        {
                            status === ApiStatus.Loading ? 'Saving' : 'Save & Close'
                        }
                    </Button>
                </Fragment>
            )}
        />
    );
};

export default EditTimesDialog;
