import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { format, isValid } from 'date-fns';

import { time24HourFormat } from '../../helpers/dateUtils';

const classesPrefix = 'customTimePicker';

const classes = {
    timePicker: `${classesPrefix}-timePicker`
};

const StyledTextField = styled(TextField)(() => {
    return {
        [`&.${classes.timePicker}`]: {
            width: '120px',
            marginRight: '16px'
        }
    };
});

const CustomTimePicker = ({
    label,
    incomingTime,
    handleTimeChange,
    handleIsValidChange,
    dataQa
}: {
    /** Label to show on the time picker. */
    label: string;
    /** Time value or null. */
    incomingTime: string | null;
    /** Handles the time change provided that the selected date is valid. */
    handleTimeChange: (date: string | null) => void;
    /** Handles the change for isValid. */
    handleIsValidChange: (isValid: boolean) => void;
    /** data-qa description */
    dataQa: string;
}): JSX.Element => {
    // TimePicker needs a JS Date, so we'll start with a new date and then adjust the hours, minutes and seconds based on the incoming data.
    const fullDate = new Date();
    if (incomingTime) {
        fullDate.setHours(Number(incomingTime.substring(0, 2)), Number(incomingTime.substring(3, 5)));
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                label={label}
                value={incomingTime ? fullDate : null}
                onChange={(date: Date | null): void => {
                    if (date && isValid(date)) {
                        // format the JS Date back to just the time to pass to the parent
                        const newTime = format(date, time24HourFormat);
                        handleTimeChange(newTime);
                    } else {
                        // sends null to the parent because the date either was cleared or it wasn't valid
                        handleTimeChange(null);
                    }
                }}
                ampm={false}
                inputFormat={time24HourFormat}
                onError={(error): void => {
                    handleIsValidChange(!error);
                }}
                renderInput={(params): JSX.Element => {
                    return <StyledTextField {...params} className={classes.timePicker} variant='standard' />;
                }}
                componentsProps={{
                    actionBar: {
                        // The actions will be different between desktop and mobile
                        actions: (variant): PickersActionBarAction[] => {
                            return variant === 'desktop' ? ['today'] : ['today', 'cancel', 'accept'];
                        }
                    }
                }}
                data-qa={dataQa}
            />
        </LocalizationProvider>
    );
};

export default CustomTimePicker;
