import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Grid, Typography, Button, FormHelperText } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import trimbleLogo from '../../assets/images/trimble_logo.png';

const classesPrefix = 'logoUploader';

const classes = {
    sectionWrapper: `${classesPrefix}-sectionWrapper`,
    customLogoImage: `${classesPrefix}-customLogoImage`
};

const StyledSection = styled('section')(() => {
    return {
        [`&.${classes.sectionWrapper}`]: {
            paddingTop: '16px'
        },
        [`& .${classes.customLogoImage}`]: {
            maxHeight: '70px',
            maxWidth: '210px'
        }
    };
});

const LogoUploader = ({
    preferencesCustomLogo,
    hasCustomLogo,
    handleRemoveCustomLogo,
    handleCustomLogoToUpload
}: {
    hasCustomLogo: boolean;
    preferencesCustomLogo: File | string | null;
    handleRemoveCustomLogo: () => void;
    handleCustomLogoToUpload: (logoToUpload: File) => void;
}): JSX.Element => {
    const theme = useTheme();

    const [draftAcceptedImage, setDraftAcceptedImage] = useState('');

    useEffect(() => {
        // only update the local state if the logo is a string (and not a blob)
        if (typeof preferencesCustomLogo === 'string') {
            setDraftAcceptedImage(preferencesCustomLogo);
        }
    }, [preferencesCustomLogo]);

    const readUploadedFileAsBinary = (inputFile: Blob): Promise<any> => {
        const reader = new FileReader();

        return new Promise((resolve, reject): void => {
            reader.onabort = (): void => {
                reject(new DOMException('File reading was aborted.'));
            };

            reader.onerror = (): void => {
                reader.abort();
                reject(new DOMException('File reading has failed.'));
            };

            reader.onload = (): void => {
                const binaryStr = reader.result;
                if (binaryStr) {
                    resolve(binaryStr);
                } else {
                    reject(new DOMException('Unable to determine file contents to upload.'));
                }
            };

            reader.readAsDataURL(inputFile);
        });
    };

    const onDrop = useCallback(async (acceptedFiles: FileWithPath[]): Promise<void> => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0]; // file blob

            const img = document.createElement('img');
            img.src = URL.createObjectURL(file);

            img.onload = async (): Promise<void> => {
                const canvas = document.createElement('canvas');
                const maxWidth = 210;
                const maxHeight = 70;

                let { width, height } = img;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d')?.drawImage(img, 0, 0, width, height);

                let dataUrl;
                if (file.type.includes('gif')) {
                    dataUrl = await readUploadedFileAsBinary(file); // base64 string for gif image
                } else {
                    dataUrl = canvas.toDataURL(file.type); // base64 string for png and jpg images
                }

                // convert the resized image back to a file blob
                const resizedImgFile = await fetch(dataUrl)
                    .then((res) => {
                        return res.arrayBuffer();
                    }).then((buffer) => {
                        return new File([buffer], file.name, { type: file.type });
                    });

                setDraftAcceptedImage(dataUrl);
                handleCustomLogoToUpload(resizedImgFile); // Resized file blob
            };
        }
    }, [handleCustomLogoToUpload]);

    const {
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        // allowable file types: jpg, jpeg, png, gif
        // MUST use MIME types below: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/gif': []
        },
        maxSize: 2000000,
        multiple: false,
        onDrop
    });

    const style = useMemo((): {} => {

        const baseStyle = {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: theme.palette.action.selected,
            borderStyle: 'dashed',
            backgroundColor: theme.palette.background.default,
            outline: 'none',
            transition: 'border .24s ease-in-out'
        };

        const activeStyle = {
            borderColor: theme.palette.action.active
        };

        const acceptStyle = {
            borderColor: theme.palette.success.main
        };

        const rejectStyle = {
            borderColor: theme.palette.error.main
        };

        return ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        });
    }, [
        theme.palette.action.selected,
        theme.palette.background.default,
        theme.palette.action.active,
        theme.palette.success.main,
        theme.palette.error.main,
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const handleRemoveClick = (): void => {
        handleRemoveCustomLogo();
        setDraftAcceptedImage('');
    };

    const renderImage = (): JSX.Element => {
        if (hasCustomLogo === true && draftAcceptedImage) {
            return (
                <img alt='custom logo' className={classes.customLogoImage} src={draftAcceptedImage} />
            );
        }
        if (preferencesCustomLogo === null) {
            return (
                <img alt='default Trimble logo' className={classes.customLogoImage} src={trimbleLogo} />
            );
        }

        return <Fragment />;
    };

    return (
        <Fragment>
            <section>
                <div {...getRootProps({ style })} data-qa='logoUploader-upload'>
                    <input {...getInputProps()} />
                    <p>Drag and drop a file here, or click to select a file</p>
                </div>
            </section>

            <StyledSection className={classes.sectionWrapper}>
                <Typography component='h6' variant='body2'>Current Selected Logo</Typography>
                {renderImage()}
            </StyledSection>

            {
                fileRejections.length > 0 &&
                <StyledSection className={classes.sectionWrapper}>
                    <Typography variant='subtitle2'>Error</Typography>
                    {
                        fileRejections[0].errors.map((error) => {
                            return (
                                <FormHelperText key={error.message} error>{error.message}</FormHelperText>
                            );
                        })
                    }
                </StyledSection>
            }

            <Grid container spacing={1} justifyContent='flex-end'>
                {
                    hasCustomLogo &&
                    <Grid item>
                        <Button
                            onClick={handleRemoveClick}
                            data-da='logoUploader-remove'
                        >
                            Remove
                        </Button>
                    </Grid>
                }
                <Grid item>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={open}
                        data-qa='logoUploader-browse'
                    >
                        Browse
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default LogoUploader;
