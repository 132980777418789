import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../redux';
import { ApiStatus, StopType } from '../../helpers/enums';
import NoteCardLoading from '../loaders/cards/noteCardLoading';
import NoteCard from '../cards/noteCard';

const classesPrefix = 'shipmentNoteList';

const classes = {
    noteListContainer: `${classesPrefix}-noteListContainer`
};

const StyledDiv = styled('div')(({ theme }) => {
    return {
        [`&.${classes.noteListContainer}`]: {
            width: '100%',
            maxHeight: 'calc(100vh - 396px)',
            minHeight: '102px',
            overflowY: 'auto',
            margin: '16px 0'
        },
        [theme.breakpoints.down('md')]: {
            [`&.${classes.noteListContainer}`]: {
                maxHeight: 'unset'
            }
        }
    };
});

const DriverNoteList = (): JSX.Element => {
    const shipmentStatus = useTypedSelector((state) => { return state.shipmentDetails.shipmentStatus; });
    const shipment = useTypedSelector((state) => { return state.shipmentDetails.shipment; });

    const stopsWithNotes = shipment.stops.some((stop) => {
        return stop.stopNotes.some((stopNote) => {
            return stopNote.driverAdded;
        });
    });

    const renderNoNotesMessage = (): JSX.Element => {
        return (
            <Typography variant='body2' component='p' data-qa='noDriverNotes'>
                Send a message below
            </Typography>
        );
    };

    const renderShipmentNotes = (): JSX.Element | JSX.Element[] => {
        if (shipment.notes.length === 0) {
            return (
                <Typography variant='body2' component='p' data-qa='noShipmentNotes'>
                    No Shipment Notes Found
                </Typography>
            );
        }
        return shipment.notes.map((note): JSX.Element => {
            if (note.noteSentToDriver) {
                return <NoteCard key={note.noteGuid} note={note} />;
            }
            return (
                <Fragment key={note.noteGuid} />
            );
        });
    };

    const renderStopNotes = (): JSX.Element | JSX.Element[][] => {
        if (!stopsWithNotes) {
            return (
                <Typography variant='body2' component='p' data-qa='noStopNotes'>
                    No Stop Notes Found
                </Typography>
            );
        }
        return shipment.stops.map((stop) => {
            return stop.stopNotes.map((note) => {
                if (note.driverAdded) {
                    const stopLabel = stop.stopType === StopType.Intermediate
                        ?
                        `Stop ${(stop.stopSequence - 1)}`
                        :
                        stop.stopType;
                    return (
                        <NoteCard
                            key={note.noteGuid}
                            note={note}
                            stopLabel={stopLabel}
                        />
                    );
                }
                return <Fragment key={note.noteGuid} />;
            });
        });
    };

    if (shipmentStatus === ApiStatus.Idle || shipmentStatus === ApiStatus.Loading) {
        return (
            <StyledDiv className={classes.noteListContainer}>
                {
                    Array.from(new Array(3)).map((item, index): JSX.Element => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <NoteCardLoading key={index} />
                        );
                    })
                }
            </StyledDiv>
        );
    }

    return (
        <StyledDiv className={classes.noteListContainer} data-qa='driverNotesList-container'>
            {
                shipment.notes.length === 0 && !stopsWithNotes
                    ?
                    renderNoNotesMessage()
                    :
                    <Fragment>
                        <Typography variant='h6' data-qa='shipmentNotes-listHeader'>
                            Shipment Notes
                        </Typography>
                        {renderShipmentNotes()}
                        <Typography variant='h6' data-qa='stopNotes-listHeader'>
                            Stop Notes
                        </Typography>
                        {renderStopNotes()}
                    </Fragment>
            }
        </StyledDiv>
    );
};

export default DriverNoteList;
