import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Typography,
    FormControlLabel,
    FormGroup,
    Switch
} from '@mui/material';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const AppointmentTimeAsEtaCard = (): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const handleHasRequiredAppointmentEarlyRulesChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.hasRequiredAppointmentEarlyRules, event.target.checked));
    };

    return (
        <PreferenceCard header='Appointment Time as ETA'>
            <Typography component='p' variant='body2'>
                If shipment is Early, show Appointment Time as ETA?
            </Typography>

            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organizationEditablePreferences.hasRequiredAppointmentEarlyRules}
                            onChange={handleHasRequiredAppointmentEarlyRulesChange}
                            color='primary'
                        />
                    }
                    label='Appointment Time as ETA'
                    data-qa='hasRequiredAppointmentEarlyRules-switch'
                />
            </FormGroup>

            <Typography component='p' variant='body2'>
                {
                    organizationEditablePreferences.hasRequiredAppointmentEarlyRules === true
                        ? 'When a shipment with an Appointment Time has an ETA of Early, Required Appointment will be considered the ETA'
                        : 'Normal ETA Rules will apply for Early ETA'
                }
            </Typography>
        </PreferenceCard>
    );

};

export default AppointmentTimeAsEtaCard;
