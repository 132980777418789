import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, TextField, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTypedSelector } from '../../../redux';
import { editPreferences } from '../../../redux/organization';
import { calculateHoursAndMinutes, calculateMinutes, isPositiveInt, validateHoursAndMinutes } from '../../../helpers/dataUtils';
import { PreferencesFieldNames } from '../../../helpers/enums';
import PreferenceCard from '../preferenceCard';

const classesPrefix = 'temperatureAlertsCard';

const classes = {
    textFieldDegrees: `${classesPrefix}-textFieldDegrees`,
    textField: `${classesPrefix}-textField`,
    textWrapper: `${classesPrefix}-textWrapper`
};

const StyledDiv = styled('div')(() => {
    return {
        [`& .${classes.textFieldDegrees}`]: {
            width: '175px',
            marginRight: '16px'
        },
        [`& .${classes.textField}`]: {
            width: '50px',
            marginRight: '16px'
        },
        [`& .${classes.textWrapper}`]: {
            marginTop: '16px'
        }
    };
});

const TemperatureAlertsCard = ({
    handleIsCardValidChange
}: {
    handleIsCardValidChange: (isValid: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const organizationEditablePreferences = useTypedSelector((state) => { return state.organization.organizationEditablePreferences; });

    const { temperatureAlertBelowMin, temperatureAlertAboveMax, temperatureAlertMinutes } = organizationEditablePreferences;

    const [isTemperatureAlertBelowMinValid, setIsTemperatureAlertBelowMinValid] = useState(true);
    const [isTemperatureAlertAboveMaxValid, setIsTemperatureAlertAboveMaxValid] = useState(true);
    const [isTemperatureAlertMinutesValid, setIsTemperatureAlertMinutesValid] = useState(true);

    useEffect((): void => {
        const isValid = isPositiveInt(temperatureAlertBelowMin);
        setIsTemperatureAlertBelowMinValid(isValid);
    }, [temperatureAlertBelowMin]);

    useEffect((): void => {
        const isValid = isPositiveInt(temperatureAlertAboveMax);
        setIsTemperatureAlertAboveMaxValid(isValid);
    }, [temperatureAlertAboveMax]);

    useEffect((): void => {
        const isValid = validateHoursAndMinutes(temperatureAlertMinutes, 1440);
        setIsTemperatureAlertMinutesValid(isValid);
    }, [temperatureAlertMinutes]);

    useEffect((): void => {
        const isCardValid = isTemperatureAlertBelowMinValid && isTemperatureAlertAboveMaxValid && isTemperatureAlertMinutesValid;
        handleIsCardValidChange(isCardValid);
    }, [handleIsCardValidChange, isTemperatureAlertBelowMinValid, isTemperatureAlertAboveMaxValid, isTemperatureAlertMinutesValid]);

    const handleTemperatureAlertBelowMinChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.temperatureAlertBelowMin, Number(event.currentTarget.value)));
    };

    const handleTemperatureAlertAboveMaxChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        dispatch(editPreferences(PreferencesFieldNames.temperatureAlertAboveMax, Number(event.currentTarget.value)));
    };

    const handleTemperatureAlertHoursChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(Number(event.currentTarget.value), calculateHoursAndMinutes(temperatureAlertMinutes).minutes);
        dispatch(editPreferences(PreferencesFieldNames.temperatureAlertMinutes, updatedMinutes));
    };

    const handleTemperatureAlertMinutesChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const updatedMinutes = calculateMinutes(calculateHoursAndMinutes(temperatureAlertMinutes).hours, Number(event.currentTarget.value));
        dispatch(editPreferences(PreferencesFieldNames.temperatureAlertMinutes, updatedMinutes));
    };

    return (
        <PreferenceCard header='Temperature Alerts'>
            <StyledDiv>
                <Typography component='p' variant='body2'>
                    How many degrees outside of your required temp range is acceptable?  Example: Your range is 28ºF min - 34ºF max.
                </Typography>

                <TextField
                    className={classes.textFieldDegrees}
                    variant='standard'
                    label='Degrees (F) Below Min.'
                    value={temperatureAlertBelowMin}
                    onChange={handleTemperatureAlertBelowMinChange}
                    type='number'
                    InputProps={{ inputProps: { min: 0 } }}
                    error={!isTemperatureAlertBelowMinValid}
                    helperText={isTemperatureAlertBelowMinValid ? '' : 'Temperature must be 0 or above.'}
                    data-qa='temperatureAlertBelowMin-input'
                />
                <TextField
                    className={classes.textFieldDegrees}
                    variant='standard'
                    label='Degrees (F) Above Max.'
                    value={temperatureAlertAboveMax}
                    onChange={handleTemperatureAlertAboveMaxChange}
                    type='number'
                    InputProps={{ inputProps: { min: 0 } }}
                    error={!isTemperatureAlertAboveMaxValid}
                    helperText={isTemperatureAlertAboveMaxValid ? '' : 'Temperature must be 0 or above.'}
                    data-qa='temperatureAlertAboveMax-input'
                />

                <Typography component='p' className={classes.textWrapper} variant='body2'>
                    After what period of time outside of your required temperature range should an alert be triggered?
                </Typography>

                <TextField
                    className={classes.textField}
                    variant='standard'
                    label='Hours'
                    value={calculateHoursAndMinutes(temperatureAlertMinutes).hours}
                    onChange={handleTemperatureAlertHoursChange}
                    type='number'
                    InputProps={{ inputProps: { min: 0, max: 24 } }}
                    error={!isTemperatureAlertMinutesValid}
                    data-qa='temperatureAlertMinutes-hours-input'
                />
                <TextField
                    className={classes.textField}
                    variant='standard'
                    label='Minutes'
                    value={calculateHoursAndMinutes(temperatureAlertMinutes).minutes}
                    onChange={handleTemperatureAlertMinutesChange}
                    type='number'
                    InputProps={{ inputProps: { min: 0, max: 59 } }}
                    error={!isTemperatureAlertMinutesValid}
                    data-qa='temperatureAlertMinutes-minutes-input'
                />
                {
                    !isTemperatureAlertMinutesValid &&
                    <FormHelperText error>Total time cannot exceed 24 hours and 0 minutes.</FormHelperText>
                }
            </StyledDiv>
        </PreferenceCard>
    );
};

export default TemperatureAlertsCard;
