import { Action } from 'redux';
import { toast } from 'react-toastify';
import { PaletteMode } from '@mui/material';

import { AppThunk, GenericAction } from '..';
import { GenericApiResponse } from '../../interfaces/services';
import {
    ApplicationResource,
    UserInformation,
    UserInformationWithApps,
    UserSettingAttribute,
    OrganizationUserSettingsData
} from '../../interfaces/services/organization';
import { View } from '../../interfaces/services/views';
import { ApiStatus, ApplicationResourceNames, ShareType } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { publicRoutes } from '../../routes/appRouteList';
import { initialUserInformationState, initialUserSettingsState, defaultColumns } from './initialState';

const USER_INFORMATION_REQUEST = 'USER_INFORMATION_REQUEST';
const USER_INFORMATION_SUCCESS = 'USER_INFORMATION_SUCCESS';
const USER_INFORMATION_FAILURE = 'USER_INFORMATION_FAILURE';
const USER_SETTINGS_REQUEST = 'USER_SETTINGS_REQUEST';
export const USER_SETTINGS_SUCCESS = 'USER_SETTINGS_SUCCESS';
const USER_SETTINGS_FAILURE = 'USER_SETTINGS_FAILURE';
const USER_SETTINGS_UPDATE_REQUEST = 'USER_SETTINGS_UPDATE_REQUEST';
const USER_SETTINGS_UPDATE_SUCCESS = 'USER_SETTINGS_UPDATE_SUCCESS';
const USER_SETTINGS_UPDATE_FAILURE = 'USER_SETTINGS_UPDATE_FAILURE';
const SET_USER_LOGIN_STATUS = 'SET_USER_LOGIN_STATUS';
const SET_LOGIN_REDIRECT_URL = 'SET_LOGIN_REDIRECT_URL';
const SET_SHARE_TYPE = 'SET_SHARE_TYPE';
const SET_PALETTE_MODE = 'SET_PALETTE_MODE';

interface UserInformationData extends UserInformation {
    userApplications: {
        transportationUi: ApplicationResource[];
        freightPortal: ApplicationResource[];
        account: ApplicationResource[];
    };
}

interface UserData {
    loginRedirectUrl: string;
    userLoginStatus: ApiStatus;
    shareType: ShareType | null;
    accessToken: string;
    paletteMode: PaletteMode;
    userInformationStatus: ApiStatus;
    userInformation: UserInformationData;
    userSettings: {
        userSettingsColumns: UserSettingAttribute[];
        userSettingsCounters: UserSettingAttribute[];
        mapLegendDisplay: boolean;
        defaultView: View | null;
    };
    userSettingsStatus: ApiStatus;
    userSettingsUpdateStatus: ApiStatus;
}

export const setUserLoginStatus = (status: ApiStatus): GenericAction<typeof SET_USER_LOGIN_STATUS, ApiStatus> => {
    return {
        type: SET_USER_LOGIN_STATUS,
        payload: status
    };
};

export const setLoginRedirectUrl = (url: string): GenericAction<typeof SET_LOGIN_REDIRECT_URL, string> => {
    return {
        type: SET_LOGIN_REDIRECT_URL,
        payload: url
    };
};

export const setShareType = (type: ShareType, token: string): GenericAction<typeof SET_SHARE_TYPE,
    {
        type: ShareType;
        token: string;
    }> => {
    return {
        type: SET_SHARE_TYPE,
        payload: {
            type,
            token
        }
    };
};

export const setPaletteMode = (mode: PaletteMode): GenericAction<typeof SET_PALETTE_MODE, { mode: PaletteMode; }> => {
    return {
        type: SET_PALETTE_MODE,
        payload: {
            mode
        }
    };
};

const requestUserInformation = (): Action<typeof USER_INFORMATION_REQUEST> => {
    return {
        type: USER_INFORMATION_REQUEST
    };
};

const receiveUserInformation = (json: UserInformationWithApps): GenericAction<typeof USER_INFORMATION_SUCCESS, UserInformationWithApps> => {
    return {
        type: USER_INFORMATION_SUCCESS,
        payload: json
    };
};

const requestUserInformationFailed = (): Action<typeof USER_INFORMATION_FAILURE> => {
    return {
        type: USER_INFORMATION_FAILURE
    };
};

const requestUserSettings = (): Action<typeof USER_SETTINGS_REQUEST> => {
    return {
        type: USER_SETTINGS_REQUEST
    };
};

export const receiveUserSettings = (json: OrganizationUserSettingsData): GenericAction<typeof USER_SETTINGS_SUCCESS, OrganizationUserSettingsData> => {
    return {
        type: USER_SETTINGS_SUCCESS,
        payload: json
    };
};

const requestUserSettingsFailed = (): Action<typeof USER_SETTINGS_FAILURE> => {
    return {
        type: USER_SETTINGS_FAILURE
    };
};

const requestUserSettingsUpdate = (): Action<typeof USER_SETTINGS_UPDATE_REQUEST> => {
    return {
        type: USER_SETTINGS_UPDATE_REQUEST
    };
};

const receiveUserSettingsUpdate = (json: OrganizationUserSettingsData): GenericAction<typeof USER_SETTINGS_UPDATE_SUCCESS, OrganizationUserSettingsData> => {
    return {
        type: USER_SETTINGS_UPDATE_SUCCESS,
        payload: json
    };
};

const requestUserSettingsUpdateFailed = (): Action<typeof USER_SETTINGS_UPDATE_FAILURE> => {
    return {
        type: USER_SETTINGS_UPDATE_FAILURE
    };
};

export const fetchUserInformation = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestUserInformation());

        try {
            const json = await ApiService.get({ url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.userInformation}` }) as GenericApiResponse<UserInformationWithApps>;
            const { data } = json;

            dispatch(receiveUserInformation(data[0]));
        } catch (error) {
            dispatch(requestUserInformationFailed());
            toast.error('Error occurred while fetching user applications.');
        }
    };
};

export const fetchUserSettings = (shareType: ShareType | null): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        if (shareType !== ShareType.View) {
            dispatch(requestUserSettings());

            try {
                const json = await ApiService.get({ url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.userSettings}` }) as GenericApiResponse<OrganizationUserSettingsData>;
                const { data } = json;

                dispatch(receiveUserSettings(data[0]));
            } catch (error) {
                dispatch(requestUserSettingsFailed());
                toast.error('Error occurred while fetching user settings.');
            }
        } else {
            // if in a Shared View, default the columns to a hardcoded list
            dispatch(receiveUserSettings({
                ...initialUserSettingsState,
                userSettingsColumns: defaultColumns
            }));
        }
    };
};

export const updateUserSettings = ({
    userSettingsColumns,
    userSettingsCounters,
    mapLegendDisplay
}: {
    userSettingsColumns: UserSettingAttribute[];
    userSettingsCounters: UserSettingAttribute[];
    mapLegendDisplay: boolean;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestUserSettingsUpdate());

        const requestBody = {
            userSettingsColumns,
            userSettingsCounters,
            mapLegendDisplay
        };

        try {
            const json = await ApiService.put({
                url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.userSettings}`,
                body: requestBody
            }) as GenericApiResponse<OrganizationUserSettingsData>;
            const { data } = json;

            dispatch(receiveUserSettingsUpdate(data[0]));
        } catch (error) {
            dispatch(requestUserSettingsUpdateFailed());
            if (error?.response?.data?.messages?.length > 0) { // picks up any HTTP 4xx response errors
                error.response.data.messages.forEach((errorMessage: string): void => {
                    toast.error(errorMessage);
                });
            } else {
                toast.error('Error occurred while updating user settings.');
            }
        }
    };
};

type UserInformationActionTypes =
    ReturnType<typeof requestUserInformation> | ReturnType<typeof receiveUserInformation> | ReturnType<typeof requestUserInformationFailed> |
    ReturnType<typeof requestUserSettings> | ReturnType<typeof receiveUserSettings> | ReturnType<typeof requestUserSettingsFailed> |
    ReturnType<typeof requestUserSettingsUpdate> | ReturnType<typeof receiveUserSettingsUpdate> | ReturnType<typeof requestUserSettingsUpdateFailed> |
    ReturnType<typeof setUserLoginStatus> | ReturnType<typeof setLoginRedirectUrl> | ReturnType<typeof setShareType> | ReturnType<typeof setPaletteMode>;

export const userReducer = (user: UserData = {
    loginRedirectUrl: publicRoutes.root,
    userLoginStatus: ApiStatus.Idle,
    shareType: null,
    accessToken: '',
    paletteMode: 'light',
    userInformationStatus: ApiStatus.Idle,
    userInformation: initialUserInformationState,
    userSettingsStatus: ApiStatus.Idle,
    userSettings: initialUserSettingsState,
    userSettingsUpdateStatus: ApiStatus.Idle
}, action: UserInformationActionTypes): UserData => {
    switch (action.type) {
        case SET_USER_LOGIN_STATUS: {
            return {
                ...user,
                userLoginStatus: action.payload
            };
        }
        case SET_LOGIN_REDIRECT_URL: {
            return {
                ...user,
                loginRedirectUrl: action.payload
            };
        }
        case SET_SHARE_TYPE: {
            return {
                ...user,
                shareType: action.payload.type,
                accessToken: action.payload.token
            };
        }
        case SET_PALETTE_MODE: {
            return {
                ...user,
                paletteMode: action.payload.mode
            };
        }
        case USER_INFORMATION_REQUEST: {
            return {
                ...user,
                userInformationStatus: ApiStatus.Loading
            };
        }
        case USER_INFORMATION_SUCCESS: {
            const freightPortalAppList = [
                ApplicationResourceNames.Shipments,
                ApplicationResourceNames.CustomViews
            ];

            const accountAppList = [
                ApplicationResourceNames.Settings,
                ApplicationResourceNames.ContactSupport,
                ApplicationResourceNames.FAQs,
                ApplicationResourceNames.LogOut
            ];

            const allowedTransportationUiApps: ApplicationResource[] = [];
            const allowedFreightPortalApps: ApplicationResource[] = [];
            const allowedAccountApps: ApplicationResource[] = [];

            action.payload.userApplicationModels.forEach((app): void => {
                if (app.isAllowed) {
                    if (freightPortalAppList.includes(app.resourceKey) === true
                    ) {
                        allowedFreightPortalApps.push(app);
                    } else if (accountAppList.includes(app.resourceKey) === true) {
                        allowedAccountApps.push(app);
                    } else {
                        allowedTransportationUiApps.push(app);
                    }
                }
            });

            let { shareType } = user;
            if (action.payload.customerReadOnly) {
                shareType = ShareType.CustomerReadOnly;
            }
            if (action.payload.operatorReadOnly) {
                shareType = ShareType.OperatorReadOnly;
            }

            return {
                ...user,
                shareType,
                userInformationStatus: ApiStatus.Success,
                userInformation: {
                    userName: action.payload.userName,
                    emailAddress: action.payload.emailAddress,
                    isAdmin: action.payload.isAdmin,
                    isOwner: action.payload.isOwner,
                    hasManualShipmentCreationAccess: action.payload.hasManualShipmentCreationAccess,
                    operatorReadOnly: action.payload.operatorReadOnly,
                    customerReadOnly: action.payload.customerReadOnly,
                    userApplications: {
                        transportationUi: allowedTransportationUiApps,
                        freightPortal: allowedFreightPortalApps,
                        account: allowedAccountApps
                    }
                }
            };
        }
        case USER_INFORMATION_FAILURE: {
            return {
                ...user,
                userInformationStatus: ApiStatus.Failure,
                userInformation: initialUserInformationState
            };
        }
        case USER_SETTINGS_REQUEST: {
            return {
                ...user,
                userSettingsStatus: ApiStatus.Loading
            };
        }
        case USER_SETTINGS_SUCCESS: {
            return {
                ...user,
                userSettingsStatus: ApiStatus.Success,
                userSettings: {
                    userSettingsColumns: action.payload.userSettingsColumns,
                    userSettingsCounters: action.payload.userSettingsCounters,
                    mapLegendDisplay: action.payload.mapLegendDisplay,
                    defaultView: action.payload.defaultView
                }
            };
        }
        case USER_SETTINGS_FAILURE: {
            return {
                ...user,
                userSettingsStatus: ApiStatus.Failure,
                userSettings: {
                    ...user.userSettings,
                    userSettingsColumns: [],
                    userSettingsCounters: []
                }
            };
        }
        case USER_SETTINGS_UPDATE_REQUEST: {
            return {
                ...user,
                userSettingsUpdateStatus: ApiStatus.Loading
            };
        }
        case USER_SETTINGS_UPDATE_SUCCESS: {
            return {
                ...user,
                userSettingsUpdateStatus: ApiStatus.Success,
                userSettings: {
                    ...user.userSettings,
                    userSettingsColumns: action.payload.userSettingsColumns,
                    userSettingsCounters: action.payload.userSettingsCounters,
                    mapLegendDisplay: action.payload.mapLegendDisplay
                }
            };
        }
        case USER_SETTINGS_UPDATE_FAILURE: {
            return {
                ...user,
                userSettingsUpdateStatus: ApiStatus.Failure
            };
        }
        default:
            return user;
    }
};
