import { Action } from 'redux';

import { AppThunk, GenericAction } from '..';
import { ApiStatus } from '../../helpers/enums';
import ApiService from '../../services/apiService';
import Endpoints from '../../services/endpoints';
import { GenericApiResponse } from '../../interfaces/services';
import { DriverDetails } from '../../interfaces/services/drivers';

const DRIVER_DETAILS_REQUEST = 'DRIVER_DETAILS_REQUEST';
const DRIVER_DETAILS_SUCCESS = 'DRIVER_DETAILS_SUCCESS';
const DRIVER_DETAILS_FAILURE = 'DRIVER_DETAILS_FAILURE';

interface DriversData {
    driverDetailsStatus: ApiStatus;
    driverDetails: DriverDetails;
}

const initialDriverDetailsData = {
    driverGuid: '',
    lastName: '',
    firstName: '',
    phoneNumber: '',
    emailAddress: null,
    note: null,
    organizationGuid: '',
    driverImage: null,
    createdDate: '',
    updatedDate: ''
};

const requestDriverDetails = (): Action<typeof DRIVER_DETAILS_REQUEST> => {
    return {
        type: DRIVER_DETAILS_REQUEST
    };
};

export const receiveDriverDetails = (json: DriverDetails): GenericAction<typeof DRIVER_DETAILS_SUCCESS, DriverDetails> => {
    return {
        type: DRIVER_DETAILS_SUCCESS,
        payload: json
    };
};

const requestDriverDetailsFailed = (): Action<typeof DRIVER_DETAILS_FAILURE> => {
    return {
        type: DRIVER_DETAILS_FAILURE
    };
};

export const fetchDriverDetails = ({
    mobileTrackingNumber
}: {
    mobileTrackingNumber: string | null;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(requestDriverDetails());

        // if there isn't a mobile tracking number, we will just reset the state to be successful with the initial data
        // and let the component handle rendering the correct messaging.
        if (mobileTrackingNumber === null) {
            dispatch(receiveDriverDetails(initialDriverDetailsData));
        } else {
            try {
                const json = await ApiService.get({
                    url: `${getState().availableServices.endpoints.OrganizationsApi}${Endpoints.organizationApi.driverDetails}?phoneNumber=${encodeURIComponent(mobileTrackingNumber)}`
                }) as GenericApiResponse<DriverDetails>;

                const { data } = json;
                if (data.length > 0) {
                    dispatch(receiveDriverDetails(data[0]));
                } else {
                    throw new Error(`Unable to retrieve driver details for ${mobileTrackingNumber}.`);
                }
            } catch (err) {
                dispatch(requestDriverDetailsFailed());
                // NOTE: normally we would show an error toast here, but we want to swallow this error since our users are more likely to get this than not.
            }
        }
    };
};

type DriversActionTypes =
    ReturnType<typeof requestDriverDetails> | ReturnType<typeof receiveDriverDetails> | ReturnType<typeof requestDriverDetailsFailed>;

export const driversReducer = (driversData: DriversData = {
    driverDetailsStatus: ApiStatus.Idle,
    driverDetails: initialDriverDetailsData
}, action: DriversActionTypes): DriversData => {
    switch (action.type) {
        case DRIVER_DETAILS_REQUEST: {
            return {
                ...driversData,
                driverDetailsStatus: ApiStatus.Loading
            };
        }
        case DRIVER_DETAILS_SUCCESS: {
            return {
                ...driversData,
                driverDetailsStatus: ApiStatus.Success,
                driverDetails: action.payload
            };
        }
        case DRIVER_DETAILS_FAILURE: {
            return {
                ...driversData,
                driverDetailsStatus: ApiStatus.Failure,
                driverDetails: initialDriverDetailsData
            };
        }
        default:
            return driversData;
    }
};
